import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { MappedVfc } from '@bluefox/models/Mappings';
import {
  InsertVfcMappingMutation,
  UpdateVfcMappingMutation,
} from '@graphql/mappings';
import { Button, Form } from 'semantic-ui-react';
import { VfcCriteriaQuery } from '@bluefox/graphql/insurances';
import { humanizeText } from '@bluefox/lib/humanize';

interface VfcMappingFormProps {
  close: () => void;
  practiceId?: string;
  data: MappedVfc | null;
  refetchMappings: () => void;
}

interface VfcCriterias {
  value: string;
}

const VfcMappingForm = ({
  close,
  practiceId,
  data,
  refetchMappings,
}: VfcMappingFormProps) => {
  const [emr, setEmr] = useState<string>(data?.emr || '');
  const [vfcCriteria, setVfcCriteria] = useState<string>(
    data?.vfcCriteria || ''
  );
  const [vfcEligible, setVfcEligible] = useState<boolean>(
    data?.vfcEligible || false
  );

  const [saveVfcMapping] = useMutation(
    data?.id ? UpdateVfcMappingMutation : InsertVfcMappingMutation
  );

  const handleSubmit = () => {
    saveVfcMapping({
      variables: {
        id: data?.id,
        practiceId,
        emr,
        vfcEligible,
        vfcCriteria: vfcCriteria === '' ? null : vfcCriteria,
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const { data: vfcCriteriaData } = useQuery(VfcCriteriaQuery);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={emr}
            onChange={(_, { value }) => setEmr(value)}
            fluid
            label="EMR"
            placeholder="EMR"
            required
          />
        </Form.Field>
        <Form.Field required>
          <Form.Dropdown
            fluid
            selection
            value={vfcEligible}
            onChange={(_, { value }) => {
              setVfcEligible(value as boolean);
            }}
            label="VFC Eligible"
            options={[
              { text: 'True', value: true },
              { text: 'False', value: false },
            ]}
            required
          />
        </Form.Field>
        <Form.Field required>
          <Form.Dropdown
            fluid
            selection
            value={vfcCriteria}
            onChange={(_, { value }) => {
              setVfcCriteria(value as string);
            }}
            label="VFC Criteria"
            options={vfcCriteriaData?.vfcCriterias?.map((c: VfcCriterias) => {
              return {
                text: humanizeText(c.value, {
                  capitalize: 'all',
                  delimiter: '_',
                }),
                value: c.value,
              };
            })}
            required={vfcEligible}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          disabled={(vfcEligible && !vfcCriteria) || !emr}
        />
      </Form.Field>
    </Form>
  );
};

export default VfcMappingForm;
