import { MappingTypes } from '@bluefox/models/integrations/Develo';
import MappingTable from './MappingTable';

const InsuranceCompanyMapping: React.FC = () => {
  return (
    <MappingTable
      mappingTitle="Insurance Company"
      mappingType={MappingTypes.insuranceCompany}
      enablePagination={true}
      hasPractice={false}
    />
  );
};

export default InsuranceCompanyMapping;
