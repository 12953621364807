import { ReactNode } from "react";

import { Modal, Button } from "semantic-ui-react";

interface CustomModalProps {
  open?: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
  centered?: boolean;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  children,
  title,
  centered = true,
  size
}) => {
  return (
    <Modal
      closeIcon
      open={open}
      onClose={onClose}
      centered={centered}
      size={size}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CustomModal;
