import { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { DropdonwOptionsValuesType } from '@bluefox/models/Dashboard';

const dropdonwOptions: {
  key: DropdonwOptionsValuesType;
  text: string;
  value: DropdonwOptionsValuesType;
}[] = [
  {
    key: 'all',
    text: 'All claims',
    value: 'all',
  },
  {
    key: 'private',
    text: 'Private',
    value: 'private',
  },
  {
    key: 'vfc',
    text: 'VFC',
    value: 'vfc',
  },
];

export const DropdownOptionsToStatusQuery: Record<
  DropdonwOptionsValuesType,
  boolean[]
> = {
  all: [true, false],
  vfc: [true],
  private: [false],
};

type Props = {
  onChange?: (value: DropdonwOptionsValuesType) => void;
};

const ClaimDropdown = ({ onChange }: Props) => {
  useEffect(() => {
    onChange && onChange(dropdonwOptions[0].value);
  }, []);
  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Form.Dropdown
        style={{ width: 'inherit' }}
        selection
        compact
        defaultValue={dropdonwOptions[0].value}
        options={dropdonwOptions}
        onChange={(_, { value }) => {
          onChange && onChange(value as DropdonwOptionsValuesType);
        }}
      />
    </Form>
  );
};

export default ClaimDropdown;
