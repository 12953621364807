import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  InsertVacciationForClaimMutation,
  InsertManualClaimMutation,
} from '@graphql/billing';

import { BillingClaimStatus } from '@bluefox/models/Billing';
import { toast } from 'react-semantic-toasts';
import { Button, Modal, Tab } from 'semantic-ui-react';
import VaccinationForm from '@ui/ClaimModal/VaccinationForm';
import ClaimForm from '@ui/ClaimModal/ClaimForm';

interface SavedVaccination {
  insertVaccination: {
    id: string;
  };
}

interface ClaimVaccinationData {
  practiceId: string;
  patientId: string;
  vaccineId: string;
  givenAt: string;
}

interface CptCode {
  cptCode: string;
  claimAmount: number;
  paidAmount: number;
}

interface ClaimData {
  cptCodes: CptCode[];
  status: BillingClaimStatus;
  totalClaimAmount: number;
}

interface PracticeOption {
  text: string;
  value: string;
}

interface ClaimModalProps {
  trigger: React.ReactNode;
  practices: PracticeOption[];
}

const ClaimModal = ({ trigger, practices }: ClaimModalProps) => {
  const [open, setOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>();
  const [activeIndex, setActiveIndex] = useState(0);
  const [vaccinationData, setVaccinationData] = useState<ClaimVaccinationData>({
    practiceId: '',
    patientId: '',
    vaccineId: '',
    givenAt: '',
  });
  const [vaccinationId, setVaccinationId] = useState('');
  const [claimData, setClaimData] = useState<ClaimData>();

  const cleanAndClose = () => {
    setOpen(false);
    setVaccinationData({
      practiceId: '',
      patientId: '',
      vaccineId: '',
      givenAt: '',
    });
  };

  const handleVaccination = (
    practiceId: string,
    patientId: string,
    vaccineId: string,
    givenAt: string
  ) => {
    setVaccinationData({
      practiceId,
      patientId,
      vaccineId,
      givenAt,
    });
  };

  const handleClaim = (claim: ClaimData) => {
    setClaimData(claim);
  };

  const [saveVaccination] = useMutation<SavedVaccination>(
    InsertVacciationForClaimMutation
  );

  const [saveClaim] = useMutation(InsertManualClaimMutation);

  const handleSaveVaccination = () => {
    saveVaccination({
      variables: {
        practiceId: vaccinationData.practiceId,
        patientId: vaccinationData.patientId,
        vaccineId: vaccinationData.vaccineId,
        givenAt: vaccinationData.givenAt,
      },
    })
      .then((r) => {
        setVaccinationId(r.data?.insertVaccination.id || '');
        toast({
          title: 'Vaccination has been successfully saved',
          type: 'success',
          time: 1000,
        });
        setActiveIndex(1);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleSaveClaim = () => {
    saveClaim({
      variables: {
        vaccinationId: vaccinationId,
        status: claimData?.status,
        totalClaimAmount: claimData?.totalClaimAmount,
        cptCodes: claimData?.cptCodes,
      },
    })
      .then((r) => {
        toast({
          title: 'Claim has been successfully saved',
          type: 'success',
          time: 1000,
        });
        setActiveIndex(0);
        cleanAndClose();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!vaccinationData) return;
    if (
      !vaccinationData.practiceId ||
      !vaccinationData.patientId ||
      !vaccinationData.vaccineId ||
      !vaccinationData.givenAt
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [vaccinationData]);

  return (
    <Modal
      closeIcon
      onClose={() => {
        cleanAndClose();
        setActiveIndex(0);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      size="small"
    >
      <Modal.Header>Claim Creation</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Tab
            activeIndex={activeIndex}
            panes={[
              {
                menuItem: 'Vaccination',
                render: () => (
                  <Tab.Pane>
                    <VaccinationForm
                      practices={practices}
                      handleVaccination={handleVaccination}
                      vaccinationData={vaccinationData}
                    />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: 'Claim',
                render: () => (
                  <Tab.Pane>
                    <ClaimForm handleClaim={handleClaim} />
                  </Tab.Pane>
                ),
              },
            ]}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={activeIndex === 0 ? 'Cancel' : 'Back'}
          onClick={() => {
            activeIndex === 0 ? cleanAndClose() : setActiveIndex(0);
            setActiveIndex(0);
          }}
        />
        <Button
          content={activeIndex === 0 ? 'Next' : 'Save'}
          primary
          onClick={() =>
            activeIndex === 0 ? handleSaveVaccination() : handleSaveClaim()
          }
          disabled={activeIndex === 0 ? disableSubmit : !claimData}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ClaimModal;
