import { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/client';
import { OrdersToTrackQuery } from '@bluefox/graphql/inventoryOrders';
import {
  InventoryOrders,
  OrderStatuses,
} from '@bluefox/models/InventoryOrders';
import { Vaccine } from '@bluefox/models/Vaccine';
import {
  Segment,
  Menu,
  Icon,
  Table,
  Placeholder,
  Modal,
  Message,
  Label,
  Button,
  Popup,
  Form,
} from 'semantic-ui-react';
import InventoryOrderForm from '@bluefox/ui/InventoryOrderForm';
import { DateFormats } from '@bluefox/models/Dates';
import { toast } from 'react-semantic-toasts';
import { humanizeText } from '@bluefox/lib/humanize';
import styled from 'styled-components';
import Pagination from '@bluefox/ui/Pagination';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import RowsPerPage from '@ui/Filters/RowsPerPage';
import { debounce } from '@bluefox/lib/debounce';
import { whereLikeInput } from '@bluefox/graphql/utils';

const statusOptions = [
  { key: 'all', text: 'All', value: OrderStatuses.ALL },
  { key: 'ordered', text: 'Ordered', value: OrderStatuses.ORDERED },
  { key: 'received', text: 'Received', value: OrderStatuses.RECEIVED },
  { key: 'cancelled', text: 'Cancelled', value: OrderStatuses.CANCELLED },
];

const paidOptions = [
  { key: 'paid', text: 'Paid', value: true },
  { key: 'notPaid', text: 'Not Paid', value: false },
];

type InventoryOrderVaccineType = Pick<
  Vaccine,
  'saleNdc' | 'manufacturer' | 'name' | 'id'
>;

type InventoryOrderType = Omit<InventoryOrders, 'vaccine' | 'vaccineId'> & {
  vaccine: InventoryOrderVaccineType;
};

interface InventoryOrderDataType {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  orders: InventoryOrderType[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const OrdersToTrack = () => {
  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState<InventoryOrderType>();
  const [statusSearch, setStatusSearch] = useState<OrderStatuses>(
    OrderStatuses.ALL
  );
  const [paidSearch, setPaidSearch] = useState(false);
  const [orderNumberSearch, setOrderNumberSearch] = useState('');
  const [orderNumberSearchQueryValue, setOrderNumberSearchQueryValue] =
    useState('');
  const [criteria, setCriteria] = useState<object>({});
  const [searchPractice, setSearchPractice] = useState<string>('all');
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);
  const [page, setPage] = useState(0);

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const { data, loading, refetch } = useQuery<InventoryOrderDataType>(
    OrdersToTrackQuery,
    {
      variables: {
        criteria,
        limit: rowsPerPage,
        offset: !!page ? rowsPerPage * (page - 1) : 0,
      },
      skip: rowsPerPage === 0,
      fetchPolicy: 'network-only',
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / rowsPerPage);

  const closeModal = () => {
    setOpen(false);
    setOrderData({
      id: '',
      practiceId: '',
      date: undefined,
      purchasePlatform: '',
      lot: '',
      estimatedShippingDate: undefined,
      inventoryExpiration: undefined,
      doses: 0,
      amount: 0,
      invoiceNumber: '',
      invoiceDue: undefined,
      invoiceLink: '',
      orderNumber: '',
      paidAt: undefined,
      paid: false,
      status: OrderStatuses.ORDERED,
      statusLog: undefined,
      inventoryId: '',
      inventory: undefined,
      packagesAmount: 0,
      vaccine: {
        id: '',
        saleNdc: '',
        name: '',
        manufacturer: '',
      },
      notes: '',
      trackingNumber: '',
      creditCardId: '',
    });
  };

  const handleCopyText = (str: string | undefined) => {
    navigator.clipboard.writeText(str || '');

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  useEffect(() => {
    if (statusSearch === OrderStatuses.ALL) {
      setCriteria({
        practiceId: searchPractice !== 'all' ? { _eq: searchPractice } : {},
        paid: { _eq: paidSearch },
        _or: [
          { status: { _eq: OrderStatuses.ORDERED } },
          { status: { _eq: OrderStatuses.RECEIVED } },
          { status: { _eq: OrderStatuses.CLOSED } },
        ],
        ...(orderNumberSearchQueryValue
          ? {
              orderNumber: {
                _ilike: whereLikeInput(orderNumberSearchQueryValue),
              },
            }
          : {}),
      });
    } else {
      setCriteria({
        practiceId: searchPractice !== 'all' ? { _eq: searchPractice } : {},
        paid: { _eq: paidSearch },
        status: { _eq: statusSearch },
        ...(orderNumberSearchQueryValue
          ? { orderNumber: { _eq: orderNumberSearchQueryValue } }
          : {}),
      });
    }

    setPage(1);
  }, [statusSearch, paidSearch, orderNumberSearchQueryValue, searchPractice]);

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  useEffect(() => {
    const rows = Number(localStorage.getItem('ordersToTrackRowConfig'));
    setRowsPerPage(rows >= 0 ? rows : 15);
  }, []);

  const daysLeftTo = (date: Date | undefined) => {
    const dueDate = moment(date);
    return dueDate.diff(moment(), 'days');
  };

  return (
    <>
      <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item>
            <RowsPerPage
              rows={rowsPerPage}
              setRows={setRowsPerPage}
              localStorageItem="ordersToTrackRowConfig"
            />
          </Menu.Item>
          {rowsPerPage > 0 && (
            <>
              <Menu.Item>
                <PracticeFilter
                  practiceSearch={searchPractice}
                  setPracticeSearch={setSearchPractice}
                  suspended="notSuspended"
                />
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <Form.Input
                    label="Search by Order #"
                    placeholder="Search Order #..."
                    value={orderNumberSearch}
                    onChange={(_, { value }) => {
                      setOrderNumberSearch(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setOrderNumberSearchQueryValue(value);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Form>
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <Form.Dropdown
                    label="Search by Paid Status"
                    onChange={(_, { value }) => {
                      setPaidSearch(value as boolean);
                    }}
                    placeholder="Select Paid Status"
                    selection
                    value={paidSearch}
                    options={paidOptions}
                  />
                </Form>
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <Form.Dropdown
                    label="Search by Status"
                    onChange={(_, { value }) => {
                      setStatusSearch(value as OrderStatuses);
                    }}
                    placeholder="Select Status"
                    selection
                    value={statusSearch}
                    options={statusOptions}
                  />
                </Form>
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      {rowsPerPage > 0 ? (
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Practice</Table.HeaderCell>
              <Table.HeaderCell>Order #</Table.HeaderCell>
              <Table.HeaderCell>Purchase date</Table.HeaderCell>
              <Table.HeaderCell>Estimated Shipping date</Table.HeaderCell>
              <Table.HeaderCell>Vaccine info</Table.HeaderCell>
              <Table.HeaderCell>
                Purchase platform / Tracking Number
              </Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
              <Table.HeaderCell>Amount </Table.HeaderCell>
              <Table.HeaderCell>Invoice due</Table.HeaderCell>
              <Table.HeaderCell>Order status</Table.HeaderCell>
              <Table.HeaderCell>Payment day</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell colSpan={11}>
                  <Segment basic>
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            ) : !!data ? (
              data.orders.map((o, key) => {
                return (
                  <Table.Row
                    key={key}
                    warning={daysLeftTo(o.estimatedShippingDate) === 0}
                    negative={daysLeftTo(o.estimatedShippingDate) < 0}
                  >
                    <Table.Cell>{o.practice?.name}</Table.Cell>
                    <Table.Cell collapsing>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {o.orderNumber}
                        <Label
                          size="tiny"
                          color={o.vfc ? 'orange' : 'teal'}
                          content={o.vfc ? 'VFC' : 'Not-VFC'}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.date).format(DateFormats.DATE)}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.estimatedShippingDate).format(DateFormats.DATE)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      <b>{o.vaccine.name}</b> | {o.vaccine.manufacturer} |{' '}
                      {o.vaccine.saleNdc}
                    </Table.Cell>
                    <Table.Cell>
                      {o.purchasePlatform
                        ? humanizeText(o.purchasePlatform, {
                            capitalize: 'all',
                            delimiter: '_',
                          })
                        : '-'}
                      {o.trackingNumber && (
                        <>
                          <br />
                          {o.trackingNumber}
                          <StyledIcon
                            name="copy"
                            color="grey"
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              handleCopyText(o.trackingNumber);
                            }}
                          />
                        </>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        {o.doses}{' '}
                        <Label size="tiny">{o.packagesAmount} vials</Label>
                      </>
                    </Table.Cell>
                    <Table.Cell>${o.amount}</Table.Cell>
                    <Table.Cell>
                      {o.invoiceNumber ? o.invoiceNumber : '-'}
                      <hr />
                      {o.invoiceDue && daysLeftTo(o.invoiceDue) <= 7 ? (
                        <>
                          {moment(o.invoiceDue).format(DateFormats.DATE)}{' '}
                          <Label size="tiny" color="red" basic>
                            {daysLeftTo(o.invoiceDue) < 0
                              ? 'Expired'
                              : `${daysLeftTo(o.invoiceDue)} days left`}
                          </Label>
                        </>
                      ) : (
                        <>{moment(o.invoiceDue).format(DateFormats.DATE)}</>
                      )}

                      <hr />
                      {o.invoiceLink ? (
                        <a
                          href={o.invoiceLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Invoice Link
                        </a>
                      ) : (
                        '-'
                      )}
                    </Table.Cell>
                    <Table.Cell>{o.status}</Table.Cell>
                    <Table.Cell>
                      {o.paidAt
                        ? moment(o.paidAt).format(DateFormats.DATE)
                        : null}
                    </Table.Cell>
                    <Table.Cell singleLine>
                      <Popup
                        content="Edit"
                        size="small"
                        trigger={
                          <Button
                            primary
                            icon="edit"
                            size="mini"
                            onClick={() => {
                              setOpen(true);
                              setOrderData({
                                id: o.id,
                                practiceId: o.practiceId,
                                date: o.date,
                                estimatedShippingDate: o.estimatedShippingDate,
                                purchasePlatform: o.purchasePlatform,
                                lot: o.lot,
                                inventoryExpiration: o.inventoryExpiration,
                                doses: o.doses,
                                amount: o.amount,
                                invoiceNumber: o.invoiceNumber,
                                invoiceDue: o.invoiceDue,
                                invoiceLink: o.invoiceLink,
                                orderNumber: o.orderNumber,
                                paidAt: o.paidAt,
                                paid: o.paid,
                                status: o.status,
                                statusLog: o.statusLog,
                                inventoryId: o.inventoryId,
                                inventory: o.inventory,
                                packagesAmount: o.packagesAmount,
                                vfc: o.vfc,
                                vaccine: {
                                  id: o.vaccine.id,
                                  saleNdc: o.vaccine.saleNdc,
                                  name: o.vaccine.name,
                                  manufacturer: o.vaccine.manufacturer,
                                },
                                practice: o.practice,
                                notes: o.notes,
                                trackingNumber: o.trackingNumber,
                                statementDate: o.statementDate,
                                creditCardId: o.creditCardId,
                              });
                            }}
                          />
                        }
                      />
                      {o.notes && (
                        <Popup
                          content={o.notes}
                          trigger={
                            <Icon
                              name="info circle"
                              size="large"
                              color="teal"
                            />
                          }
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={11}>
                  <Message>No orders found.</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Pagination
            total={total}
            colSpan={11}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
      ) : null}

      <Modal
        size="large"
        onClose={() => closeModal()}
        onOpen={() => setOpen(open)}
        open={open}
        closeIcon
      >
        <Modal.Header>Edit Order</Modal.Header>
        <Modal.Content>
          <InventoryOrderForm
            close={() => closeModal()}
            orderData={orderData}
            refetchOrders={refetch}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export default OrdersToTrack;
