export type DropdonwOptionsValuesType = 'all' | 'private' | 'vfc';

export enum ClaimStatusEnum {
  PAID = 'paid',
  IN_PROGRESS = 'in_progress',
  DENIED = 'denied',
}

export type InvoiceInfoType = {
  amount: number;
  date: Date;
};

export const enum BillingDashboardClaimStatusEnum {
  PAID = 'paid',
  PENDING_APPEALS = 'pending_appeals',
  IN_PROGRESS = 'in_progress',
  IN_MEDIATION = 'in_mediation',
  ERROR_AND_REJECTED = 'error_and_rejected',
  RESUBMITION = 'resubmition',
  UNCLAIMED = 'unclaimed',
  BORROWING = 'borrowing',
}
