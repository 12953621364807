import { BillingClaim } from '@bluefox/models/Billing';
import { Table, Message, Segment, Placeholder } from 'semantic-ui-react';
import BillingReportRow from './BillingReportRow';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import BillingReportFooter from './BillingReportFooter';
import { useState } from 'react';
import { CSSProperties } from 'styled-components';
import UpdateBillingClaimForm from '@ui/Billing/UpdateBillingClaimForm';

interface Cpt {
  cpt: string;
}

export interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

type Props = {
  billingReport?: BillingClaim[];
  totalBilliableSelectedAmount?: number;
  totalReviewedSelectedAmount?: number;
  totalAmount?: number;
  loading?: boolean;
  style?: CSSProperties;
  procedureAmountsData?: ProcedureAmountsData;
  onSave?: () => void;
};

const BillingReportList = ({
  billingReport,
  totalBilliableSelectedAmount,
  totalReviewedSelectedAmount,
  totalAmount,
  loading,
  style,
  procedureAmountsData,
  onSave,
}: Props) => {
  const [selectedClaimId, setSelectedClaimId] = useState<string | undefined>();

  return (
    <>
      <Table selectable style={{ marginBottom: 0, ...style }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Practice</Table.HeaderCell>
            <Table.HeaderCell width={3}>Patient</Table.HeaderCell>
            <Table.HeaderCell>Insurance</Table.HeaderCell>
            <Table.HeaderCell width={2}>Date of Service</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              CPT Codes
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Paid Amount
            </Table.HeaderCell>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Status
            </Table.HeaderCell>

            <Table.HeaderCell>Edit</Table.HeaderCell>

            <Table.HeaderCell>Reviewed / Billable</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell colSpan={10}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          )}
          {billingReport && billingReport.length > 0 ? (
            billingReport.map((br) => {
              const insurance = br.updates?.find((u: any) =>
                isStringNotEmpty(u.metadata?.insurance?.insuranceName)
              )?.metadata?.insurance;
              return (
                <BillingReportRow
                  key={br.id}
                  id={br.id}
                  practice={br.practice}
                  patientData={br?.practicePatient?.patientData}
                  insurance={
                    insurance /* br?.practicePatient?.insurances?.[0]*/
                  }
                  selfPay={br.selfPay}
                  vaccinations={br.vaccinations}
                  cptCodes={br.cptCodes}
                  checkNumber={br.checkNumber}
                  note={br.note}
                  status={br.status}
                  revisionStatus={br.revisionStatus}
                  reviewedAt={br.reviewedAt}
                  metadata={br.updates?.[0]?.metadata}
                  onSelectClaimToEdit={(claimId) => setSelectedClaimId(claimId)}
                  onSave={onSave}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={10}>
                <Message>No claims found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <BillingReportFooter
        totalAmount={totalAmount}
        billableAmount={totalBilliableSelectedAmount}
        reviewedAmount={totalReviewedSelectedAmount}
      />
      {selectedClaimId && (
        <UpdateBillingClaimForm
          claimId={selectedClaimId}
          adminCptCodes={procedureAmountsData}
          onClose={() => setSelectedClaimId(undefined)}
          onSave={() => onSave?.()}
        />
      )}
    </>
  );
};

export default BillingReportList;
