import React, { CSSProperties, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { Button, Form, Popup } from 'semantic-ui-react';
import { AddVaccinationNoteMutation } from '@bluefox/graphql/vaccinations';

type VaccinationNotesPopupProps = {
  style?: CSSProperties;
  vaccinationId: string;
  vaccinationNote?: string;
  onSave: () => void;
};

const VaccinationNotesPopup = ({
  style,
  vaccinationId,
  vaccinationNote,
  onSave,
}: VaccinationNotesPopupProps) => {
  const [vaccNote, setVaccNote] = useState(vaccinationNote || '');
  const [popupOpen, setPopupOpen] = useState(false);

  const [saveVaccinatioNote] = useMutation(AddVaccinationNoteMutation);

  const handleOnSubmit = async () => {
    try {
      await saveVaccinatioNote({
        variables: {
          id: vaccinationId,
          noteText: vaccNote,
        },
      });

      toast({
        title: 'Note saved successfully',
        type: 'success',
        time: 1000,
      });

      onSave();
      setPopupOpen(false);
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  return (
    <Popup
      style={{ width: '18rem' }}
      open={popupOpen}
      on="click"
      position="top right"
      trigger={
        <Button
          primary
          size="mini"
          icon="sticky note"
          style={style}
          onClick={() => setPopupOpen(true)}
        />
      }
      content={
        <Form id="vaccination-notes-form" onSubmit={handleOnSubmit}>
          <Form.Field>
            <Form.TextArea
              rows={4}
              placeholder="Enter note here..."
              value={vaccNote}
              label="Notes"
              width={16}
              onChange={(event, data) => {
                setVaccNote(data.value as string);
              }}
            />
          </Form.Field>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              primary
              size="tiny"
              type="submit"
              content="Save"
              form="vaccination-notes-form"
            />
          </div>
        </Form>
      }
      onClose={() => {
        if (vaccinationNote) {
          setVaccNote(vaccinationNote);
        } else {
          setVaccNote('');
        }
        setPopupOpen(false);
      }}
    />
  );
};

export default VaccinationNotesPopup;
