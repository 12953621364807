import React, { useState, useEffect } from 'react';
import { useApplicationState } from '@bluefox/contexts/ApplicationState';
import { useMutation } from '@apollo/client';
import {
  InsertUserMutation,
  UpdateUserMutation,
  UpdateUserPasswordMutation,
  UsersQuery,
} from '@bluefox/graphql/users';

import { BillingStrategyMappingsQuery } from '@bluefox/graphql/mappings';

import {
  Button,
  Modal,
  Form,
  Container,
  Checkbox,
  Message,
  List,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { generatePassword } from '@bluefox/lib/validations/passwordValidations';
import { isValidEmail } from '@bluefox/lib/validations/emailValidation';
import { User, UserTitles } from '@bluefox/models/Users';
import { usePractice } from '@bluefox/contexts';

const optionsTitle = [
  { key: 'md', text: 'MD', value: 'MD' },
  { key: 'do', text: 'DO', value: 'DO' },
  { key: 'pa', text: 'PA', value: 'PA' },
  { key: 'ma', text: 'MA', value: 'MA' },
  { key: 'np', text: 'NP', value: 'NP' },
  { key: 'nurse', text: 'Nurse', value: 'Nurse' },
  { key: 'other', text: 'Other', value: 'Other' },
];
const optionsRole = [
  { key: 'pm', text: 'Practice Manager', value: 'practiceManager' },
  { key: 'user', text: 'User', value: 'user' },
  { key: 'biller', text: 'Biller', value: 'biller' },
];

interface UserFormProps {
  close: () => void;
  userData?: User | undefined;
  onSave: () => void;
}

const UserForm = ({ close, userData, onSave }: UserFormProps) => {
  const { session } = useApplicationState();
  const practiceId = usePractice().id;

  const [practiceAccountId, setPracticeAccountId] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [title, setTitle] = useState<UserTitles>();
  const [npi, setNpi] = useState<string>('');
  const [password, setPassword] = useState<string>(generatePassword());
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('user');
  const [isPcp, setIsPcp] = useState<boolean>(false);
  const [isSuspended, setIsSuspended] = useState<boolean>(false);
  const [searchIsSuspended, setSearchIsSuspended] = useState<string>('active');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [taxonomyCode, setTaxonomyCode] = useState<string>('');
  const [profile, setProfile] = useState<any>({});
  const [federalTaxId, setFederalTaxId] = useState<string>('');
  const [showPermissionAs, setShowPermissionAs] = useState<boolean>();

  const [openChangePassModal, setOpenChangePassModal] = useState(false);

  const [saveProvider] = useMutation(
    practiceAccountId ? UpdateUserMutation : InsertUserMutation
  );

  const [updatePassword] = useMutation(UpdateUserPasswordMutation);

  const handleCopyPass = (e: any) => {
    //TODO: refactor this with time XD
    const inputPass =
      e.target.nodeName === 'I'
        ? e.target.parentElement.parentElement.firstChild.value
        : e.target.parentElement.firstChild.value;
    navigator.clipboard.writeText(inputPass);
  };

  const handleSubmitChangePassword = () => {
    updatePassword({
      variables: {
        accountId,
        password,
      },
    })
      .then((r) => {
        toast({
          title: `Password has been changed successfully`,
          type: 'success',
          time: 1000,
        });
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
    setOpenChangePassModal(false);
  };

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      setErrorEmail(true);
      toast({
        title: `Email should be like: some@canid.io`,
        type: 'error',
        time: 5000,
      });
      return;
    }

    setErrorEmail(false);
    saveProvider({
      variables: {
        ...(!!practiceAccountId ? { practiceAccountId } : { practiceId }),
        accountId,
        firstName,
        lastName,
        title,
        npi,
        taxId: federalTaxId,
        role,
        isPcp,
        isSuspended,
        email,
        password,
        searchIsSuspended,
        taxonomyCode,
        profile,
      },
      refetchQueries: [
        {
          query: BillingStrategyMappingsQuery,
          variables: {
            practiceId: practiceId,
            strategy: 'provider',
            limit: 15,
            offset: 0,
          },
        },
        {
          query: UsersQuery,
          variables: {
            criteria: {
              practiceId: { _eq: practiceId },
              suspended: { _eq: false },
            },
          },
        },
      ],
    })
      .then((r) => {
        if (practiceAccountId) {
          toast({
            title: `Member has been updated successfully`,
            type: 'success',
            time: 1000,
          });
        } else {
          toast({
            title: `Member has been saved successfully`,
            type: 'success',
            time: 1000,
          });
        }
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
    close();
  };

  useEffect(() => {
    setPracticeAccountId(userData?.id || '');
    setAccountId(userData?.account.id || '');
    setFirstName(userData?.account.firstName || '');
    setLastName(userData?.account.lastName || '');
    setTitle(userData?.title || UserTitles.Other);
    setNpi(userData?.account.npi || '');
    setIsPcp(userData?.pcp || false);
    setIsSuspended(userData?.suspended || false);
    setEmail(userData?.account.email || '');
    setPassword(generatePassword());
    setRole(userData?.role || 'user');
    setTaxonomyCode(userData?.account.taxonomyCode || '');
    setProfile(userData?.profile || {});
    setFederalTaxId(userData?.profile?.federalTaxId || '');
    setShowPermissionAs(!!userData?.profile?.permissionAs);
  }, [userData]);

  return (
    <>
      <Form onSubmit={handleSubmit} data-automation-id="providers-modal">
        <Form.Group widths="equal">
          <Form.Input
            value={firstName}
            onChange={(_, { value }) => setFirstName(value)}
            fluid
            label="First name"
            placeholder="First name"
            required
            data-automation-id="providers-modal-firstname"
          />
          <Form.Input
            value={lastName}
            onChange={(_, { value }) => setLastName(value)}
            fluid
            label="Last name"
            placeholder="Last name"
            required
            data-automation-id="providers-modal-lastname"
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Select
            required
            fluid
            label="Title"
            options={optionsTitle}
            placeholder="Title"
            value={title}
            onChange={(_, { value }) =>
              setTitle((value as UserTitles) ?? UserTitles.Other)
            }
          />
          <Container fluid>
            <label style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
              PCP
            </label>
            <Form.Checkbox
              style={{ margin: '1rem 1rem 0 1rem' }}
              label="PCP"
              value="pcp"
              checked={isPcp}
              onChange={(_, { checked }) => setIsPcp(!!checked)}
            />
          </Container>
          <Form.Input
            value={taxonomyCode}
            onChange={(_, { value }) => setTaxonomyCode(value)}
            fluid
            label="Taxonomy Code"
            placeholder="Taxonomy Code"
            data-automation-id="providers-modal-taxonomy-code"
          />
          <Form.Input
            value={npi}
            onChange={(_, { value }) => setNpi(value)}
            fluid
            label="NPI"
            placeholder="NPI"
            required={isPcp || profile?.permissionAs?.prescriber}
            data-automation-id="providers-modal-npi"
          />
          <Form.Input
            value={federalTaxId}
            onChange={(_, { value }) => {
              setFederalTaxId(value);
              let newProfile = { ...profile };
              newProfile.federalTaxId = value;
              setProfile(newProfile);
            }}
            fluid
            label="Tax Id"
            placeholder="Tax Id"
            required={isPcp || profile?.permissionAs?.prescriber}
            data-automation-id="providers-modal-tax-id"
          />
          <Form.Select
            disabled={session?.account?.id === practiceAccountId}
            onChange={(_, { value }) => setRole(value ? value.toString() : '')}
            fluid
            label="Role"
            options={optionsRole}
            placeholder="Role"
            value={role}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            value={email}
            error={errorEmail}
            onChange={(_, { value }) => setEmail(value)}
            fluid
            type="email"
            label="Email"
            placeholder="email"
            required
            data-automation-id="providers-modal-email"
          />
          {!practiceAccountId && (
            <Form.Input
              className="passwordInputContainer"
              value={password}
              onChange={(_, { value }) => setPassword(value)}
              fluid
              label="Password"
              placeholder="password"
              required
              data-automation-id="providers-modal-password"
              action={{
                type: 'button',
                color: 'teal',
                labelPosition: 'right',
                icon: 'copy',
                content: 'Copy',
                onClick: (e: React.MouseEvent<HTMLElement>) => {
                  handleCopyPass(e);
                },
              }}
            />
          )}
          {practiceAccountId && (
            <Button
              type="button"
              onClick={() => setOpenChangePassModal(true)}
              style={{
                width: '21rem',
                margin: '1.5rem 0 0 0',
                padding: '0',
                height: '2.8rem',
              }}
            >
              Change Password
            </Button>
          )}
        </Form.Group>
        <Form.Group>
          <Message>
            <Message.Header>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 'bold' }}>
                  Permissions selection (Provider | Prescriber)
                </label>

                <Checkbox
                  toggle
                  checked={profile?.permissionAs}
                  onChange={(_, { checked }) => {
                    setShowPermissionAs(!!checked);
                    let newProfile = { ...profile };

                    if (checked) {
                      newProfile.permissionAs = {
                        provider: false,
                        prescriber: false,
                      };
                    } else {
                      if (profile?.permissionAs) {
                        delete newProfile.permissionAs;
                      }
                    }

                    setProfile(newProfile);
                  }}
                />
                {showPermissionAs && (
                  <div style={{ display: 'flex', margin: '1rem 0rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ fontWeight: 'bold' }}>Provider</label>
                      <Form.Checkbox
                        style={{ margin: '0 1.2rem 0 0.6rem' }}
                        value="isProvider"
                        checked={profile?.permissionAs?.provider}
                        onChange={(_, { checked }) =>
                          setProfile({
                            ...profile,
                            permissionAs: {
                              provider: !!checked,
                              prescriber: !!profile?.permissionAs?.prescriber,
                            },
                          })
                        }
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ fontWeight: 'bold' }}>Prescriber</label>
                      <Form.Checkbox
                        style={{ margin: '0 1.2rem 0 0.6rem' }}
                        value="isPrescriber"
                        checked={profile?.permissionAs?.prescriber}
                        onChange={(_, { checked }) =>
                          setProfile({
                            ...profile,
                            permissionAs: {
                              prescriber: !!checked,
                              provider: !!profile?.permissionAs?.provider,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </Message.Header>
            <div style={{ marginTop: '0.5rem' }}>
              In case permissions are not set, default configuration will be:
            </div>

            <b style={{ marginRight: '0.5rem' }}>Prescriber:</b>
            <List horizontal>
              <List.Item>DO</List.Item>
              <List.Item>MD</List.Item>
              <List.Item>PA</List.Item>
              <List.Item>NP</List.Item>
            </List>
            <br></br>
            <b style={{ marginRight: '0.5rem' }}>Provider:</b>
            <List horizontal>
              <List.Item>DO</List.Item>
              <List.Item>MD</List.Item>
              <List.Item>PA</List.Item>
              <List.Item>NP</List.Item>
              <List.Item>MA</List.Item>
              <List.Item>Nurse</List.Item>
            </List>
          </Message>
        </Form.Group>
        <Form.Group>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ fontWeight: 'bold' }}>Suspended</label>
            <Form.Checkbox
              disabled={session?.account?.id === practiceAccountId}
              style={{ margin: '0 1.2rem 0 0.6rem' }}
              label="Suspend User"
              value="suspended"
              checked={isSuspended}
              onChange={(_, { checked }) => setIsSuspended(!!checked)}
            />
          </div>
        </Form.Group>
        <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={close}
            type="button"
            data-automation-id="providers-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            primary
            type="submit"
            content="Save"
            icon="save"
            data-automation-id="providers-modal-save-button"
          />
        </Form.Field>
      </Form>
      <Modal
        onClose={() => setOpenChangePassModal(false)}
        open={openChangePassModal}
        size="small"
      >
        <Modal.Header>
          Change {firstName} {lastName}'s Password
        </Modal.Header>
        <Modal.Content>
          <Form data-automation-id="providers-modal-change-password">
            <Form.Input
              className="passwordInputContainer"
              value={password}
              onChange={(_, { value }) => setPassword(value)}
              fluid
              label="Password"
              placeholder="password"
              required
              data-automation-id="providers-modal-change-password-input"
              action={{
                type: 'button',
                color: 'teal',
                labelPosition: 'right',
                icon: 'copy',
                content: 'Copy',
                onClick: (e: React.MouseEvent<HTMLElement>) => {
                  handleCopyPass(e);
                },
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => setOpenChangePassModal(false)}
            type="button"
            data-automation-id="providers-modal-change-password-cancel-button"
          >
            Cancel
          </Button>
          <Button
            primary
            type="button"
            content="Save"
            icon="save"
            onClick={handleSubmitChangePassword}
            data-automation-id="providers-modal-change-password-save-button"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default UserForm;
