import { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';

import {
  BillingClaimsView,
  BillingProcedureAmountsQuery,
  BillingClaimsCount,
} from '@graphql/billing';

import { BillablePracticesQuery } from '@graphql/practices';

import {
  BillingClaimView,
  billingClaimStatusMenuOptions,
  BillingClaimStatus,
  UnclaimedReasons,
  claimDismissedOptions,
  eobStatusFilterOptions,
  BillingClaimStatusMenuOption,
  toAppealOptions,
  toCorrectOptions,
  borrowingStatusOptions,
  directChargeStatusOptions,
} from '@bluefox/models/Billing';

import { Practice } from '@bluefox/models/Practice';

import {
  Card,
  Icon,
  Menu,
  Message,
  Table,
  Button,
  Container,
  Dropdown,
  Input,
  Grid,
  Radio,
  Modal,
  DropdownProps,
  Segment,
  Placeholder,
} from 'semantic-ui-react';

import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import ClaimModal from '@ui/ClaimModal/ClaimModal';
import UpdateBillingClaimForm from '@ui/Billing/UpdateBillingClaimForm';
import BillingExcelExportModal from '@ui/Billing/BillingExcelExportModal';
import PatientFormModal from '@ui/PatientFormModal';
import InsurancesList, { InsuranceListProp } from '@bluefox/ui/InsurancesList';
import ClaimsTable from './generalClaimsTable';
import { TagsSelector } from '@ui/Tags';
import { DateFormats } from '@bluefox/models/Dates';
import { addDays } from '@bluefox/lib/formatters/dates';
import ClaimStatuses from '@ui/Billing/dashboard/cards/ClaimsStatutes';

const vfcEligibilityOptions = [
  { text: 'VFC eligible', value: 'vfcEligible' },
  { text: 'Not eligible', value: 'notEligible' },
];

const inventoryUsedOptions = [
  { text: 'VFC', value: 'vfc' },
  { text: 'Private', value: 'private' },
];

interface PracticeOption {
  text: string;
  value: string;
}
enum SortableColumns {
  PRACTICE = 'practiceName',
  PATIENT = 'fullName',

  INSURANCE_COMPANY = 'insuranceName',
  SERVICE_DATE = 'givenAt',
  STATUS = 'status',
  AMOUNT = 'totalClaimAmount',
}

interface Cpt {
  cpt: string;
}

interface ClaimsCountData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface BillingProcedureAmountsData {
  cptCodes: Cpt[];
  cptCodesInventory?: Cpt[];
}

interface practicesData {
  practices: Practice[];
}

interface BillingClaimViewData {
  billingClaims: BillingClaimView[];
}

interface Option {
  text: string;
  value: string;
}

type InventoryFilterCriteria = 'all' | 'vfc' | 'private';

const ENTRIES_PER_PAGE = 15;

const GeneralScreen = () => {
  const { claimId } = useParams<{ claimId: string }>();
  const history = useHistory();
  const billingClaimsRef = useRef(null);

  const [practiceId, setPracticeId] = useState<string>();
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [criteria, setCriteria] = useState<object>({});
  const [searchPractice, setSearchPractice] = useState<[string]>();
  const [searchPatient, setSearchPatient] = useState<string>();
  const [searchInsurance, setSearchInsurance] = useState<string>();
  const [searchProvider, setSearchProvider] = useState<string>();
  const [searchRhcNumber, setSearchRhcNumber] = useState<string>();
  const [searchControlNumber, setSearchControlNumber] = useState<string>();
  const [searchMemberId, setSearchMemberId] = useState<string>();
  const [searchVfcEligibility, setSearchVfcEligibility] = useState<boolean>();
  const [searchInventoryUsed, setSearchInventoryUsed] = useState<
    InventoryFilterCriteria | undefined
  >();
  const [searchStatus, setSearchStatus] = useState<[string]>();

  const [searchUnclaimedReason, setSearchUnclaimedReason] = useState<
    string | undefined
  >();
  const [searchDismissedReason, setSearchDismissedReason] = useState<
    string | undefined
  >();
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<
    string | undefined
  >();
  const [searchEobStatus, setSearchEobStatus] = useState<
    [string] | undefined
  >();
  const [searchAppealMethod, setSearchAppealMethod] = useState<
    string | undefined
  >();
  const [searchFieldToCorrect, setSearchFieldToCorrect] = useState<
    string | undefined
  >();
  const [searchBilled, setSearchBilled] = useState<string | undefined>();
  const [searchBorrowingStatus, setSearchBorrowingStatus] = useState<
    string | undefined
  >();
  const [searchDirectChargeStatus, setSearchDirectChargeStatus] = useState<
    string | undefined
  >();

  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [updatedAtFromDate, setUpdatedAtFromDate] = useState<
    Date | null | undefined
  >(null);
  const [updatedAtToDate, setUpdatedAtToDate] = useState<
    Date | null | undefined
  >(null);

  const [searchInvoiceFromDate, setSearchInvoiceFromDate] = useState<
    Date | null | undefined
  >(null);
  const [searchInvoiceToDate, setSearchInvoiceToDate] = useState<
    Date | null | undefined
  >(null);
  const [searchApiStatusUpdateFromDate, setSearchApiStatusUpdateFromDate] =
    useState<Date | null | undefined>(null);
  const [searchApiStatusUpdateToDate, setSearchApiStatusUpdateToDate] =
    useState<Date | null | undefined>(null);

  const [searchByTag, setSearchByTag] = useState<string[]>([]);
  const [exactTagList, setExactTagList] = useState(false);
  const [exactCptList, setExactCptList] = useState(false);

  const [highlightPracticeDropdown, setHighlightPracticeDropdown] =
    useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [isQuerying, setIsQuerying] = useState(false);

  const [totalClaimsLoading, setTotalClaimsLoading] = useState(false);

  const [showInfoMsg, setShowInfoMsg] = useState(true);

  const [shouldGetOverdueClaims, setShouldGetOverdueClaims] = useState(false);
  const [shouldGetSelfPay, setShouldGetSelfPay] = useState(false);

  const [selectedPracticePatientId, setSelectedPracticePatientId] =
    useState('');

  const [isEditInsuranceModalOpened, setIsEditInsuranceModalOpened] =
    useState(false);
  const [editInsuranceModalData, setEditInsuranceModalData] =
    useState<InsuranceListProp>({});

  const [searchCpts, setSearchCpts] = useState<string[]>([]);
  const [cptOptions, setCptOptions] = useState<Option[]>([]);

  const [sortingColumn, setSortingColumn] = useState<
    SortableColumns | undefined
  >(undefined);
  const [sortDirection, setSortDirection] = useState<
    'ascending' | 'descending' | undefined
  >('descending');

  const { data: practicesData } = useQuery<practicesData>(
    BillablePracticesQuery
  );

  const [
    getClaims,
    {
      data: viewData,
      loading: viewLoading,
      error: viewError,
      refetch: viewRefetch,
    },
  ] = useLazyQuery<BillingClaimViewData>(BillingClaimsView);

  const { data: procedureAmountsData } = useQuery<BillingProcedureAmountsData>(
    BillingProcedureAmountsQuery,
    {
      onCompleted(data) {
        const a = data.cptCodes || [];
        const b = data.cptCodesInventory || [];
        const c = a.concat(b);
        setCptOptions(
          c
            .map((cpt) => ({ text: cpt.cpt, value: cpt.cpt }))
            .sort(function (a, b) {
              var x = a['value'];
              var y = b['value'];
              return x < y ? -1 : x > y ? 1 : 0;
            })
        );
      },
    }
  );

  const handleSearchOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      runQuery();
    }
  };

  const runQuery = () => {
    if (!sortingColumn) {
      setSortingColumn(SortableColumns.SERVICE_DATE);
      return;
    }
    getClaims({
      variables: {
        orderBy: {
          [sortingColumn]: sortDirection === 'ascending' ? 'asc' : 'desc',
        },
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!pageCount ? ENTRIES_PER_PAGE * pageCount : null,
      },
    });
  };

  const [getClaimsCount, { data: claimsCount, loading: claimsCountLoading }] =
    useLazyQuery<ClaimsCountData>(BillingClaimsCount);

  const cleanSubStatus = () => {
    setSearchDismissedReason(undefined);
    setSearchAppealMethod(undefined);
    setSearchUnclaimedReason(undefined);
    setSearchBilled(undefined);
    setSearchEobStatus(undefined);
    setSearchFieldToCorrect(undefined);
    setSearchBorrowingStatus(undefined);
    setSearchDirectChargeStatus(undefined);
  };

  const handlePracticeValue = (value: [string]) => {
    const practiceValue =
      !value?.length || value.includes('allPractices') ? undefined : value;
    setSearchPractice(practiceValue);

    if (practiceValue) {
      practiceValue[0] && setPracticeId(practiceValue[0]);
    }
    setShowInfoMsg(true);
  };

  const handleStatusValue = (value: [string]) => {
    const statusValue =
      !value?.length || value.includes('allStatus') ? undefined : value;
    setSearchStatus(statusValue);
    setShowInfoMsg(true);
    cleanSubStatus();
  };

  const handleVfcEligibleValue = (value: string) => {
    const vfcEligibleValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchVfcEligibility(
      vfcEligibleValue !== undefined
        ? vfcEligibleValue === 'vfcEligible'
        : undefined
    );
    setShowInfoMsg(true);
  };

  const handleInventoryUsedValue = (value: string) => {
    const invetoryUsedValue =
      !!value && value !== 'all'
        ? (value as InventoryFilterCriteria)
        : undefined;
    setSearchInventoryUsed(invetoryUsedValue);
    setShowInfoMsg(true);
  };

  const handleUnclaimedReasonValue = (value: string) => {
    const unclaimedReasonValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchUnclaimedReason(unclaimedReasonValue);
    setShowInfoMsg(true);
  };

  const handleDismissedReasonValue = (value: string) => {
    const dismissedReasonValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchDismissedReason(dismissedReasonValue);
    setShowInfoMsg(true);
  };

  const handleEobStatusValue = (value: [string]) => {
    const eobStatusValue =
      !value?.length || value.includes('all') ? undefined : value;
    setSearchEobStatus(eobStatusValue);
    setShowInfoMsg(true);
  };

  const handleAppealMethodValue = (value: string) => {
    const appealMethodValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchAppealMethod(appealMethodValue);
    setShowInfoMsg(true);
  };

  const handleFieldToCorrectValue = (value: string) => {
    const FieldToCorrectValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchFieldToCorrect(FieldToCorrectValue);
    setShowInfoMsg(true);
  };

  const handleBilledValue = (value: string) => {
    const billedValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchBilled(billedValue);
    setShowInfoMsg(true);
  };

  const handleBorrowingStatus = (value: string) => {
    const borrowingStatusValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchBorrowingStatus(borrowingStatusValue);
    setShowInfoMsg(true);
  };

  const handleDirectChargeStatus = (value: string) => {
    const directChargeStatusValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchDirectChargeStatus(directChargeStatusValue);
    setShowInfoMsg(true);
  };

  const handleCptsValue = (value: [string]) => {
    const cptsValue = !value?.length || value.includes('allCpts') ? [] : value;
    setSearchCpts(cptsValue);
    setShowInfoMsg(true);
  };

  useEffect(() => {
    let _criteria = {};
    const buildOverdueClaimCriteria = () => {
      const today = moment().utc().format(DateFormats.DATE);
      return {
        _or: [
          { inMediationDueDate: { _lte: today } },
          { inMediationDueDateTo: { _lte: today } },
          { inMediationDueDateFrom: { _lte: today } },
        ],
      };
    };

    const dismissedReasonHandler = () => {
      if (searchDismissedReason === 'unspecified_reason') {
        return {
          _or: [
            {
              dismissedReason: {
                _is_null: true,
              },
            },
            {
              dismissedReason: {
                _eq: '',
              },
            },
            {
              dismissedReason: {
                _eq: 'unspecified_reason',
              },
            },
          ],
        };
      }
      return { dismissedReason: { _eq: searchDismissedReason } };
    };

    const buildTagFilterCriteria = () => {
      return {
        [exactTagList ? '_has_keys_all' : '_has_keys_any']: searchByTag,
      };
    };

    const buildCptFilterCriteria = () => {
      return {
        [exactCptList ? '_contains' : '_has_keys_any']: searchCpts,
      };
    };

    _criteria = {
      _and: [
        {
          ...(searchDismissedReason ? dismissedReasonHandler() : {}),
        },
        {
          ...(searchByTag.length > 0 ? { tags: buildTagFilterCriteria() } : {}),
        },
        { ...(shouldGetOverdueClaims ? buildOverdueClaimCriteria() : {}) },
        { ...(shouldGetSelfPay ? { selfPay: { _eq: true } } : {}) },
        {
          ...(searchVfcEligibility !== undefined
            ? { insuranceVfcEligible: { _eq: searchVfcEligibility } }
            : {}),
        },
        {
          ...(searchStatus ? { status: { _in: searchStatus } } : {}),
        },
        {
          ...(searchCpts?.length > 0 ? { cpts: buildCptFilterCriteria() } : {}),
        },
        {
          ...(searchUnclaimedReason
            ? { unclaimedReason: { _eq: searchUnclaimedReason } }
            : {}),
        },
        {
          ...(searchEobStatus ? { eobStatus: { _in: searchEobStatus } } : {}),
        },
        {
          ...(searchAppealMethod
            ? { appealMethod: { _eq: searchAppealMethod } }
            : {}),
        },
        {
          ...(searchFieldToCorrect
            ? { fieldToCorrect: { _eq: searchFieldToCorrect } }
            : {}),
        },
        {
          ...(searchPatient
            ? {
                fullName: {
                  _ilike: `%${searchPatient}%`,
                },
              }
            : {}),
        },
        {
          ...(searchProvider
            ? {
                _or: [
                  { prescriberFirstName: { _ilike: `%${searchProvider}%` } },
                  { prescriberLastName: { _ilike: `%${searchProvider}%` } },
                ],
              }
            : {}),
        },
        {
          ...(searchPractice ? { practiceId: { _in: searchPractice } } : {}),
        },
        {
          ...(searchFromDate
            ? {
                givenAt: {
                  _gt: searchFromDate
                    ? addDays(searchFromDate, -1)
                    : searchFromDate,
                },
              }
            : {}),
        },
        {
          ...(searchToDate
            ? {
                givenAt: {
                  _lte: searchToDate ?? new Date(),
                },
              }
            : {}),
        },
        {
          ...(searchInventoryUsed === 'vfc'
            ? { inventoryVfcCount: { _gt: 0 } }
            : {}),
        },
        {
          ...(searchInventoryUsed === 'private'
            ? { inventoryPrivateCount: { _gt: 0 } }
            : {}),
        },
        {
          ...(updatedAtFromDate
            ? {
                updatedAt: {
                  _gte: updatedAtFromDate,
                },
              }
            : {}),
        },
        {
          ...(updatedAtToDate
            ? {
                updatedAt: {
                  _lte: updatedAtToDate ?? new Date(),
                },
              }
            : {}),
        },
        {
          ...(searchInsurance
            ? { insuranceName: { _ilike: `%${searchInsurance}%` } }
            : {}),
        },
        {
          ...(searchBilled ? { countAdminCptCodesUnpaid: { _gt: 0 } } : {}),
        },
        {
          ...(searchBorrowingStatus
            ? { borrowingStatus: { _eq: searchBorrowingStatus } }
            : {}),
        },
        {
          ...(searchDirectChargeStatus
            ? { directChargeStatus: { _eq: searchDirectChargeStatus } }
            : {}),
        },
        {
          ...(searchRhcNumber
            ? { rhcClaimNumber: { _ilike: `%${searchRhcNumber}%` } }
            : {}),
        },
        {
          ...(searchControlNumber
            ? { controlNumber: { _eq: searchControlNumber } }
            : {}),
        },
        {
          ...(searchMemberId
            ? { insuranceMemberId: { _ilike: `%${searchMemberId}%` } }
            : {}),
        },
        {
          ...(searchInvoiceFromDate
            ? {
                invoiceDateFrom: {
                  _gte: searchInvoiceFromDate,
                },
              }
            : {}),
        },
        {
          ...(searchInvoiceToDate
            ? {
                invoiceDateTo: {
                  _lte: searchInvoiceToDate,
                },
              }
            : {}),
        },
        {
          ...(searchApiStatusUpdateFromDate
            ? {
                updatedStatusApiAt: {
                  _gte: searchApiStatusUpdateFromDate,
                },
              }
            : {}),
        },
        {
          ...(searchApiStatusUpdateToDate
            ? {
                updatedStatusApiAt: {
                  _lte: searchApiStatusUpdateToDate,
                },
              }
            : {}),
        },
      ],
    };

    setTotalClaimsLoading(false);
    setPageCount(0);
    setCriteria(_criteria);
  }, [
    searchPractice,
    searchPatient,
    searchVfcEligibility,
    searchStatus,
    searchInsurance,
    searchFromDate,
    searchToDate,
    updatedAtFromDate,
    updatedAtToDate,
    searchInventoryUsed,
    searchUnclaimedReason,
    searchProvider,
    searchDismissedReason,
    insuranceCompanyId,
    searchEobStatus,
    searchAppealMethod,
    searchBilled,
    searchInvoiceFromDate,
    searchInvoiceToDate,
    shouldGetOverdueClaims,
    searchRhcNumber,
    searchControlNumber,
    searchMemberId,
    searchFieldToCorrect,
    shouldGetSelfPay,
    searchByTag,
    exactTagList,
    searchApiStatusUpdateFromDate,
    searchApiStatusUpdateToDate,
    searchBorrowingStatus,
    searchDirectChargeStatus,
    searchCpts,
    exactCptList,
  ]);

  useEffect(() => {
    if (
      !practicesData ||
      !practicesData.practices ||
      practicesData.practices.length < 1
    )
      return;

    setPracticeOptions(
      practicesData.practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [practicesData]);

  const onCloseInsuranceListModalHandler = () => {
    setEditInsuranceModalData({});
    setIsEditInsuranceModalOpened(false);
  };

  useEffect(() => {
    if (pageCount < 0 || !isQuerying) return;
    runQuery();
  }, [pageCount]);

  const sortStatuses = (
    statuses: BillingClaimStatusMenuOption[]
  ): BillingClaimStatusMenuOption[] => {
    return statuses.sort((a, b) =>
      a.text > b.text ? 1 : b.text > a.text ? -1 : 0
    );
  };

  useEffect(() => {
    if (Object.keys(editInsuranceModalData).length === 0) return;
    setIsEditInsuranceModalOpened(true);
  }, [editInsuranceModalData]);

  const sortingHandler = (column: SortableColumns) => {
    if (!viewData) {
      return;
    }
    if (sortingColumn !== column) {
      setSortingColumn(column);
      setSortDirection('descending');
      return;
    }
    if (sortDirection === 'descending') {
      setSortDirection('ascending');
      return;
    }
    if (sortDirection === 'ascending') {
      setSortingColumn(SortableColumns.SERVICE_DATE);
      setSortDirection('descending');
    }
  };

  const firstLoad = useRef(0);
  useEffect(() => {
    if (firstLoad.current === 0) {
      firstLoad.current++;
      return;
    }
    runQuery();
  }, [sortingColumn, sortDirection]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <>
      <MainLayout
        path={[{ text: 'Billing', to: '/billing' }, { text: 'General' }]}
      >
        <Container fluid>
          <Card fluid style={{ marginTop: '1rem' }}>
            <Card.Content>
              <Card.Header as={'h3'}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Icon name="dollar" style={{ marginRight: '0.6rem' }} />
                    Billing
                  </div>
                  <div>
                    <Dropdown text="Actions">
                      <Dropdown.Menu>
                        <ClaimModal
                          practices={practiceOptions}
                          trigger={
                            <Dropdown.Item
                              content="Create Claim"
                              icon="plus"
                              disabled
                            />
                          }
                        />
                        <Dropdown.Item
                          content="Analytics"
                          icon="chart line"
                          as={Link}
                          to={`${history.location.pathname}/analytics`}
                        />
                        <Dropdown.Item
                          content="Vaccines Price List"
                          icon="dollar"
                          as={Link}
                          to={`${history.location.pathname}/vaccines-price-list`}
                        />
                        <Dropdown.Item
                          content="Self Pay List"
                          icon="file alternate outline"
                          as={Link}
                          to={`${history.location.pathname}/self-pay-list`}
                        />
                        <Dropdown.Item
                          content="Review Vaccinations"
                          icon="search"
                          as={Link}
                          to={`${history.location.pathname}/review`}
                        />

                        <Dropdown.Item
                          content="Borrowing"
                          icon="info circle"
                          as={Link}
                          to={`${history.location.pathname}/borrowing`}
                        />

                        {!searchPractice || searchPractice?.length !== 1 ? (
                          <Dropdown.Item
                            content="Billing Report"
                            icon="file alternate outline"
                            onClick={() => {
                              setHighlightPracticeDropdown(true);
                              toast({
                                title: 'Please select just one Practice',
                                type: 'warning',
                                time: 1000,
                              });
                            }}
                          />
                        ) : (
                          <Dropdown.Item
                            content="Billing Report"
                            icon="file alternate outline"
                            as={Link}
                            to={`${history.location.pathname}/practice/${practiceId}/report`}
                          />
                        )}
                        <Dropdown.Item
                          content="Invoices"
                          icon="file alternate outline"
                          as={Link}
                          to={`/new-billing/invoices`}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Card.Header>
              <BillingExcelExportModal criteria={criteria} />
              <Card.Description>
                {showInfoMsg && (
                  <Message
                    warning
                    header="Important information!"
                    content="Please click the green Search button to run the query with the selected filters."
                  />
                )}
                <Menu
                  borderless
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <Input
                              style={{ maxWidth: '16rem' }}
                              icon="search"
                              placeholder="Search patient..."
                              value={searchPatient}
                              onChange={(_, { value }) => {
                                setSearchPatient(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Input
                              style={{ maxWidth: '16rem' }}
                              icon="search"
                              placeholder="Search insurance..."
                              value={searchInsurance}
                              onChange={(_, { value }) => {
                                setSearchInsurance(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Input
                              style={{ maxWidth: '16rem' }}
                              icon="search"
                              placeholder="Search provider..."
                              value={searchProvider}
                              onChange={(_, { value }) => {
                                setSearchProvider(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <StyledDropdown
                              highlight={
                                highlightPracticeDropdown
                                  ? 'highlight 0.5s linear 10'
                                  : null
                              }
                              onAnimationEnd={() =>
                                setHighlightPracticeDropdown(false)
                              }
                              placeholder="Filter by practice"
                              search
                              fluid
                              selection
                              multiple
                              onChange={(
                                e: HTMLElement,
                                data: DropdownProps
                              ) => {
                                setHighlightPracticeDropdown(false);
                                handlePracticeValue(data.value as [string]);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                              options={[
                                {
                                  text: 'All practices',
                                  value: 'allPractices',
                                },
                                ...practiceOptions,
                              ]}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Dropdown
                              style={{ minWidth: '16rem' }}
                              placeholder="Filter by status"
                              search
                              fluid
                              multiple
                              selection
                              onChange={(e, data) => {
                                handleStatusValue(data.value as [string]);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                              options={[
                                { text: 'All status', value: 'allStatus' },
                                ...sortStatuses(billingClaimStatusMenuOptions),
                              ]}
                            />
                          </Menu.Item>
                          {searchStatus?.includes(
                            BillingClaimStatus.DISMISSED
                          ) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by dismissed reason"
                                fluid
                                selection
                                onChange={(e, data) => {
                                  handleDismissedReasonValue(
                                    data.value?.toString() || ''
                                  );
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={claimDismissedOptions}
                              />
                            </Menu.Item>
                          )}
                          {searchStatus?.includes(
                            BillingClaimStatus.UNCLAIMED
                          ) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by unclaimed reason"
                                fluid
                                selection
                                onChange={(e, data) => {
                                  handleUnclaimedReasonValue(
                                    data.value?.toString() || ''
                                  );
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={[
                                  { text: 'All Reasons', value: 'all' },
                                  {
                                    text: 'Insurance Error',
                                    value: UnclaimedReasons.INSURANCE_ERROR,
                                  },
                                  {
                                    text: 'Missing Vaccination',
                                    value: UnclaimedReasons.MISSING_VACCINATION,
                                  },
                                ]}
                              />
                            </Menu.Item>
                          )}
                          {(searchStatus?.includes(
                            BillingClaimStatus.ACCEPTED
                          ) ||
                            searchStatus?.includes(
                              BillingClaimStatus.ACKNOWLEDGED
                            ) ||
                            searchStatus?.includes(
                              BillingClaimStatus.READY_TO_REPORT
                            )) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by EOB status"
                                fluid
                                selection
                                multiple
                                onChange={(e, data) => {
                                  handleEobStatusValue(data.value as [string]);
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={eobStatusFilterOptions}
                              />
                            </Menu.Item>
                          )}
                          {searchStatus?.includes(
                            BillingClaimStatus.TO_APPEAL
                          ) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by appeal method"
                                fluid
                                selection
                                onChange={(e, data) => {
                                  handleAppealMethodValue(
                                    data.value?.toString() || ''
                                  );
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={[
                                  {
                                    key: 'all',
                                    text: 'All',
                                    value: 'all',
                                  },
                                  ...toAppealOptions,
                                ]}
                              />
                            </Menu.Item>
                          )}

                          {searchStatus?.includes(
                            BillingClaimStatus.BILLED
                          ) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by billed criteria"
                                fluid
                                selection
                                onChange={(e, data) => {
                                  handleBilledValue(
                                    data.value?.toString() || ''
                                  );
                                }}
                                options={[
                                  {
                                    key: 'All billed',
                                    text: 'All billed',
                                    value: 'all',
                                  },
                                  {
                                    key: 'admin_cpt_codes_unpaid',
                                    text: 'With Unpaid Administration cpt codes',
                                    value: 'admin_cpt_codes_unpaid',
                                  },
                                ]}
                              />
                            </Menu.Item>
                          )}

                          {searchStatus?.includes(
                            BillingClaimStatus.BORROWING
                          ) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by borrowing status"
                                fluid
                                selection
                                onChange={(e, data) => {
                                  handleBorrowingStatus(
                                    data.value?.toString() || ''
                                  );
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={borrowingStatusOptions}
                              />
                            </Menu.Item>
                          )}

                          {searchStatus?.includes(
                            BillingClaimStatus.DIRECT_CHARGE
                          ) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by direct charge status"
                                fluid
                                selection
                                onChange={(e, data) => {
                                  handleDirectChargeStatus(
                                    data.value?.toString() || ''
                                  );
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={directChargeStatusOptions}
                              />
                            </Menu.Item>
                          )}

                          {searchStatus?.includes(
                            BillingClaimStatus.TO_CORRECT
                          ) && (
                            <Menu.Item>
                              <Dropdown
                                style={{ minWidth: '16rem' }}
                                placeholder="Filter by field to correct"
                                fluid
                                selection
                                onChange={(e, data) => {
                                  handleFieldToCorrectValue(
                                    data.value?.toString() || ''
                                  );
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={[
                                  {
                                    key: 'all',
                                    text: 'All',
                                    value: 'all',
                                  },
                                  ...toCorrectOptions,
                                ]}
                              />
                            </Menu.Item>
                          )}
                          <Menu.Item>
                            <Input
                              style={{ maxWidth: '16rem' }}
                              icon="search"
                              placeholder="Search by member ID"
                              value={searchMemberId}
                              onChange={(_, { value }) => {
                                setSearchMemberId(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <Dropdown
                              style={{ minWidth: '16rem' }}
                              placeholder="Filter by VFC eligibility"
                              fluid
                              selection
                              onKeyDown={handleSearchOnKeyDown}
                              onChange={(e, data) => {
                                handleVfcEligibleValue(
                                  data.value?.toString() || ''
                                );
                              }}
                              options={[
                                { text: 'All', value: 'all' },
                                ...vfcEligibilityOptions,
                              ]}
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Dropdown
                              style={{ minWidth: '16rem' }}
                              placeholder="Filter by inventory used"
                              fluid
                              selection
                              onKeyDown={handleSearchOnKeyDown}
                              onChange={(e, data) => {
                                handleInventoryUsedValue(
                                  data.value?.toString() || ''
                                );
                              }}
                              options={[
                                { text: 'All', value: 'all' },
                                ...inventoryUsedOptions,
                              ]}
                              disabled={
                                searchStatus?.includes(
                                  BillingClaimStatus.UNCLAIMED
                                ) && searchUnclaimedReason === 'allReasons'
                              }
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Input
                              style={{ maxWidth: '16rem' }}
                              icon="search"
                              placeholder="Search by RHC number"
                              value={searchRhcNumber}
                              onChange={(_, { value }) => {
                                setSearchRhcNumber(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu>
                          <Menu.Item>
                            <Input
                              style={{ maxWidth: '16rem' }}
                              icon="search"
                              placeholder="Search by Control number"
                              value={searchControlNumber}
                              onChange={(_, { value }) => {
                                setSearchControlNumber(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <label
                              style={{ marginRight: '0.5rem', width: '8rem' }}
                            >
                              <b>Service Date</b>
                            </label>
                            <DateTimePicker
                              data-automation-id="billing-from-date"
                              placeholderText="From"
                              selected={searchFromDate}
                              onChange={(d) => {
                                setSearchFromDate(d ? (d as Date) : undefined);
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchFromDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setSearchFromDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <DateTimePicker
                              data-automation-id="billing-to-date"
                              placeholderText="To"
                              selected={searchToDate}
                              onChange={(d) => {
                                setSearchToDate(d ? (d as Date) : undefined);
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchToDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setSearchToDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <label
                              style={{ marginRight: '0.5rem', width: '9rem' }}
                            >
                              <b>Updated At</b>
                            </label>
                            <DateTimePicker
                              data-automation-id="billing-from-date"
                              placeholderText="From"
                              selected={updatedAtFromDate}
                              onChange={(d) => {
                                setUpdatedAtFromDate(
                                  d ? (d as Date) : undefined
                                );
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setUpdatedAtFromDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setUpdatedAtFromDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <DateTimePicker
                              data-automation-id="billing-to-date"
                              placeholderText="To"
                              selected={updatedAtToDate}
                              onChange={(d) => {
                                setUpdatedAtToDate(d ? (d as Date) : undefined);
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setUpdatedAtToDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setUpdatedAtToDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                        </Menu.Menu>

                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <label
                              style={{ marginRight: '0.5rem', width: '8rem' }}
                            >
                              <b>Invoice Date</b>
                            </label>
                            <DateTimePicker
                              data-automation-id="billing-invoice-from-date"
                              placeholderText="From"
                              selected={searchInvoiceFromDate}
                              onChange={(d) => {
                                setSearchInvoiceFromDate(
                                  d ? (d as Date) : undefined
                                );
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchInvoiceFromDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() =>
                                setSearchInvoiceFromDate(undefined)
                              }
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <DateTimePicker
                              data-automation-id="billing-invoice-to-date"
                              placeholderText="To"
                              selected={searchInvoiceToDate}
                              onChange={(d) => {
                                setSearchInvoiceToDate(
                                  d ? (d as Date) : undefined
                                );
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchInvoiceToDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setSearchInvoiceToDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <label
                              style={{ marginRight: '0.5rem', width: '8rem' }}
                            >
                              <b>API Status Update</b>
                            </label>
                            <DateTimePicker
                              data-automation-id="billing-api-status-update-from-date"
                              placeholderText="From"
                              selected={searchApiStatusUpdateFromDate}
                              onChange={(d) => {
                                setSearchApiStatusUpdateFromDate(
                                  d ? (d as Date) : undefined
                                );
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchApiStatusUpdateFromDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() =>
                                setSearchApiStatusUpdateFromDate(undefined)
                              }
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <DateTimePicker
                              data-automation-id="billing-api-status-update-to-date"
                              placeholderText="To"
                              selected={searchApiStatusUpdateToDate}
                              onChange={(d) => {
                                setSearchApiStatusUpdateToDate(
                                  d ? (d as Date) : undefined
                                );
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchApiStatusUpdateToDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() =>
                                setSearchApiStatusUpdateToDate(undefined)
                              }
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Grid verticalAlign="middle" padded>
                          <Grid.Row>
                            <Grid.Column width={15}>
                              <Dropdown
                                style={{ minWidth: '15rem' }}
                                placeholder="Filter by CPT Code"
                                search
                                fluid
                                multiple
                                selection
                                onChange={(e, data) => {
                                  handleCptsValue(data.value as [string]);
                                }}
                                onKeyDown={handleSearchOnKeyDown}
                                options={[
                                  { text: 'All CPTs', value: 'allCpts' },
                                  ...cptOptions,
                                ]}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Radio
                                toggle
                                checked={exactCptList}
                                onChange={() => setExactCptList(!exactCptList)}
                                label="Strict"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid verticalAlign="middle" padded>
                          <Grid.Row>
                            <Grid.Column width={15}>
                              <TagsSelector
                                onChange={(tagsSelected) =>
                                  setSearchByTag(tagsSelected)
                                }
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Radio
                                toggle
                                checked={exactTagList}
                                onChange={() => setExactTagList(!exactTagList)}
                                label="Strict"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column width={4} textAlign="right">
                        <Grid.Row>
                          <Menu.Menu>
                            <Menu.Item>
                              <Button
                                fluid
                                content="Search"
                                icon="search"
                                color="olive"
                                onClick={() => {
                                  setIsQuerying(true);
                                  runQuery();
                                  setShowInfoMsg(false);
                                }}
                              />
                            </Menu.Item>
                          </Menu.Menu>
                        </Grid.Row>
                        <Grid.Row>
                          <Menu.Menu>
                            <Menu.Item>
                              <Radio
                                toggle
                                checked={shouldGetOverdueClaims}
                                onChange={() =>
                                  setShouldGetOverdueClaims(
                                    !shouldGetOverdueClaims
                                  )
                                }
                                label="Mediation Overdue"
                              />
                            </Menu.Item>
                            <Menu.Item>
                              <Radio
                                toggle
                                checked={shouldGetSelfPay}
                                onChange={() =>
                                  setShouldGetSelfPay(!shouldGetSelfPay)
                                }
                                label="Self Pay"
                              />
                            </Menu.Item>
                          </Menu.Menu>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Menu>
                {viewError ? (
                  <Message error>{viewError.message}</Message>
                ) : (
                  <div ref={billingClaimsRef}>
                    <Table selectable sortable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.PRACTICE
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.PRACTICE)
                            }
                            content={
                              <>
                                Practice
                                {sortingColumn !== SortableColumns.PRACTICE && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.PATIENT
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.PATIENT)
                            }
                            content={
                              <>
                                Patient
                                {sortingColumn !== SortableColumns.PATIENT && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                            width={3}
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn ===
                              SortableColumns.INSURANCE_COMPANY
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.INSURANCE_COMPANY)
                            }
                            content={
                              <>
                                Insurance Company
                                {sortingColumn !==
                                  SortableColumns.INSURANCE_COMPANY && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.SERVICE_DATE
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.SERVICE_DATE)
                            }
                            content={
                              <>
                                Service Date
                                {sortingColumn !==
                                  SortableColumns.SERVICE_DATE && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                            width={2}
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.AMOUNT
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.AMOUNT)
                            }
                            content={
                              <>
                                Amount
                                {sortingColumn !== SortableColumns.AMOUNT && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />
                          <Table.HeaderCell content="Inventory Used" />

                          <Table.HeaderCell
                            textAlign="center"
                            sorted={
                              sortingColumn === SortableColumns.STATUS
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.STATUS)
                            }
                            content={
                              <>
                                Status
                                {sortingColumn !== SortableColumns.STATUS && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />
                        </Table.Row>
                      </Table.Header>

                      {viewLoading ? (
                        <Table.Row>
                          <Table.Cell colSpan={7}>
                            <Segment basic>
                              <Placeholder fluid>
                                <Placeholder.Header>
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                </Placeholder.Header>
                              </Placeholder>
                            </Segment>
                          </Table.Cell>
                        </Table.Row>
                      ) : viewData?.billingClaims ? (
                        <ClaimsTable
                          claims={viewData.billingClaims}
                          onClaimUpdate={viewRefetch}
                          onPatientSelection={() =>
                            console.log('onPatientSelected')
                          }
                          onEditInsuranceClick={() =>
                            console.log('OnEditInsuranceClick')
                          }
                          refetch={viewRefetch}
                          procedureAmountsData={procedureAmountsData}
                        />
                      ) : (
                        <Table.Body>
                          <Table.Cell colSpan={7}>
                            <Message>
                              Click the green "Search" button to start searching
                              claims.
                            </Message>
                          </Table.Cell>
                        </Table.Body>
                      )}
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Button
                              basic
                              content={
                                !totalClaimsLoading
                                  ? 'Show total'
                                  : `Total: ${claimsCount?.aggregating.aggregate.count}`
                              }
                              loading={claimsCountLoading}
                              onClick={() => {
                                getClaimsCount({
                                  variables: {
                                    criteria,
                                  },
                                });
                                setTotalClaimsLoading(true);
                              }}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan={7} textAlign="right">
                            <Button
                              basic
                              icon="angle left"
                              onClick={() => setPageCount(pageCount - 1)}
                              disabled={pageCount === 0}
                            />
                            <Button
                              basic
                              icon="angle right"
                              onClick={() => setPageCount(pageCount + 1)}
                              disabled={
                                !viewData?.billingClaims.length ||
                                viewData.billingClaims.length < ENTRIES_PER_PAGE
                              }
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </div>
                )}
              </Card.Description>
            </Card.Content>
          </Card>
        </Container>
        <PatientFormModal
          practPatientId={selectedPracticePatientId}
          onClose={() => setSelectedPracticePatientId('')}
          onCancel={() => setSelectedPracticePatientId('')}
          onSave={() => {
            setSelectedPracticePatientId('');
            viewRefetch();
          }}
        />

        <Modal
          closeIcon
          onClose={onCloseInsuranceListModalHandler}
          open={isEditInsuranceModalOpened}
        >
          <InsurancesList
            propPracticePatientId={editInsuranceModalData.propPracticePatientId}
          />
        </Modal>
      </MainLayout>
      {!!claimId && (
        <UpdateBillingClaimForm
          claimId={claimId}
          adminCptCodes={procedureAmountsData}
          onClose={() => {
            history.push('/billing/general');
          }}
          onSave={() => {
            viewRefetch();
            history.push('/billing/general');
          }}
        />
      )}
    </>
  );
};

export default GeneralScreen;

const StyledDropdown = styled(Dropdown)`
  min-width: 16rem !important;

  animation: ${(props) => props.highlight};

  @keyframes highlight {
    0% {
      box-shadow: 0 0 0px red;
    }

    25% {
      box-shadow: 0 0 2px red;
    }

    50% {
      box-shadow: 0 0 6px red;
    }

    75% {
      box-shadow: 0 0 2px red;
    }

    100% {
      box-shadow: 0 0 0px red;
    }
  }
`;
