import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import {
  Button,
  Modal,
  Table,
  Segment,
  Placeholder,
  Message,
  Label,
} from 'semantic-ui-react';
import { AdminVfcCptClaimsQuery } from '@graphql/billing';
import { VfcClaim, BillingClaimStatus } from '@bluefox/models/Billing';
import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';

interface VfcClaimsData {
  vfcAdminCptCodesClaims: VfcClaim[];
}

type VfcClaimsListProps = {
  adminCptCodeIds?: string[];
};

const VfcClaimsList = ({ adminCptCodeIds }: VfcClaimsListProps) => {
  const [open, setOpen] = useState(false);
  const [cptIds, setCptIds] = useState<string[] | undefined>([]);

  const [getVfcClaims, { data, loading }] = useLazyQuery<VfcClaimsData>(
    AdminVfcCptClaimsQuery
  );

  useEffect(() => {
    setCptIds(adminCptCodeIds);
  }, [adminCptCodeIds]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          color="orange"
          onClick={() =>
            getVfcClaims({
              variables: {
                adminCptCodeIds: cptIds,
              },
            })
          }
        >
          VFC Claims
        </Button>
      }
      size="large"
    >
      <Modal.Header>VFC Claims</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Table selectable style={{ marginBottom: 0 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Practice</Table.HeaderCell>
                <Table.HeaderCell width={3}>Patient</Table.HeaderCell>
                <Table.HeaderCell>Insurance</Table.HeaderCell>
                <Table.HeaderCell width={2}>Date of Service</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  CPT Codes
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  Paid Amount
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={7}>
                    <Segment basic>
                      <Placeholder fluid>
                        <Placeholder.Header>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                      </Placeholder>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              ) : !!data?.vfcAdminCptCodesClaims.length ? (
                data?.vfcAdminCptCodesClaims.map((vfcClaim) => {
                  return <VfcClaimsListRow data={vfcClaim} />;
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={7}>
                    <Message>No claims found.</Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

//-----------------------VFC Claims List Row-----------------------//

interface VfcClaimsRowListProps {
  data: VfcClaim;
}

const VfcClaimsListRow = ({ data }: VfcClaimsRowListProps) => {
  return (
    <Table.Row>
      <Table.Cell>{data.claim.practicePatient.practice.name}</Table.Cell>
      <Table.Cell>{`${data.claim.practicePatient.patientData.firstName} ${data.claim.practicePatient.patientData.lastName} (${data.claim.practicePatient.patientData.birthdate})`}</Table.Cell>
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {data.claim.updates[0]?.metadata?.insuranceForPracticePortal
            ? data.claim.updates[0]?.metadata?.insuranceForPracticePortal
                .insuranceName
            : data.claim.updates[0]?.metadata?.insurance?.insuranceName}
          <div>
            <Label
              basic
              content={
                !data.claim.selfPay
                  ? `Member ID: ${data.claim.updates[0]?.metadata?.insurance?.memberId}`
                  : `Self Pay`
              }
              size="tiny"
            />
            {data.claim.updates[0]?.metadata?.insurance?.vfcEligible ? (
              <Label
                content="VFC Eligible"
                size="tiny"
                color="orange"
                style={{ marginTop: '0.2rem' }}
              />
            ) : null}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {data.claim.vaccinations && !!data.claim.vaccinations.length
          ? moment(data.claim.vaccinations[0].givenAt).format(DateFormats.DATE)
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <Label style={{ marginBottom: '0.2rem' }} content={data.cptCode} />
      </Table.Cell>
      <Table.Cell>
        {data.paidAmount ? `$${data.paidAmount.toFixed(2)}` : '-'}
      </Table.Cell>
      <Table.Cell>
        <Label
          basic
          content={humanizeText(data.claim.status, {
            capitalize: 'first',
            delimiter: '_',
          })}
          size="tiny"
          color={
            `${data.claim.status}` === BillingClaimStatus.PAID
              ? 'olive'
              : 'grey'
          }
          style={{ marginTop: '0.2rem' }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default VfcClaimsList;
