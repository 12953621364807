import {
  BillingNotificationFirstOptionsEnum,
  BillingNotificationFirstOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  CheckboxWrapperStyle,
  DropdownWrapperStyle,
  MainContextHightlitedStyle,
  MainContextParagraphStyle,
} from '../SharedStyles';
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';
import { BillingClaim } from '@bluefox/models/Billing';
import { useMemo } from 'react';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { renderGivenAt } from '@bluefox/ui/Notifications/common';
import { renderBillingMessageMultipleCompleteAndSignDoc } from '@bluefox/ui/Notifications/NotificationMessage/BillingNotificationMessage';

function buildDropdownData(optionEnum: BillingNotificationFirstOptionsEnum) {
  return {
    key: optionEnum,
    text: BillingNotificationFirstOptionsEnumToText[optionEnum],
    value: optionEnum,
  };
}

const firstOptionsDropdownDataV1 = [
  buildDropdownData(BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION),
  buildDropdownData(
    BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY
  ),
  buildDropdownData(
    BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM
  ),
  buildDropdownData(
    BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY
  ),
];

const firstOptionsDropdownDataV2 = [
  buildDropdownData(
    BillingNotificationFirstOptionsEnum.THE_PROCEDURE_CODE_IS_INCONSISTENT_PATIENT_AGE
  ),
  buildDropdownData(
    BillingNotificationFirstOptionsEnum.COORDINATION_OF_BENEFITS
  ),
  buildDropdownData(
    BillingNotificationFirstOptionsEnum.NO_ENROLLMENT_PATIENT_OVER_19
  ),
  buildDropdownData(BillingNotificationFirstOptionsEnum.MEDICAL_NECESSITY),
  buildDropdownData(
    BillingNotificationFirstOptionsEnum.ADULT_PATIENT_WITHOUT_INSURANCE
  ),
  buildDropdownData(BillingNotificationFirstOptionsEnum.DUPLICATE_CLAIM),
];

export type FormValues = {
  firstOptionSelection?: BillingNotificationFirstOptionsEnum;
  selectedCptCodes?: string[];
  freeText?: string;
  version?: number;
};

export function isFormValid(formValues: FormValues) {
  if (!formValues.firstOptionSelection) return false;
  if (
    formValues.firstOptionSelection ===
      BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION &&
    (!formValues.selectedCptCodes || formValues.selectedCptCodes.length === 0)
  )
    return false;
  if (
    formValues.firstOptionSelection ===
      BillingNotificationFirstOptionsEnum.FREE_TEXT &&
    !isStringNotEmpty(formValues.freeText)
  )
    return false;

  return true;
}

type Props = {
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  claim?: BillingClaim;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
};

const BillingNotificationForm = ({
  fullPatienName,
  givenAt,
  mrn,
  claim,
  formValues,
  setFormValues,
}: Props) => {
  const cptCodesDropdownOptions = useMemo(
    () =>
      claim?.cptCodes?.map((claim) => ({
        key: claim.cptCode,
        text: claim.cptCode,
        value: claim.cptCode,
      })),
    [claim?.cptCodes]
  );

  return (
    <>
      <MainContextParagraphStyle>
        For the patient{' '}
        <MainContextHightlitedStyle>
          {fullPatienName}, MRN {mrn ?? '-'}
        </MainContextHightlitedStyle>
        {`, DOS ${renderGivenAt(givenAt)}, `}
        {formValues.firstOptionSelection !==
          BillingNotificationFirstOptionsEnum.FREE_TEXT && (
          <>
            <DropdownWrapperStyle>
              <Dropdown
                clearable
                options={
                  formValues?.version === 2
                    ? firstOptionsDropdownDataV2
                    : firstOptionsDropdownDataV1
                }
                selection
                value={formValues?.firstOptionSelection}
                onChange={(_, { value }) => {
                  setFormValues((prevVal) => ({
                    ...prevVal,
                    firstOptionSelection:
                      value as BillingNotificationFirstOptionsEnum,
                    selectedCptCodes: [],
                  }));
                }}
              />
            </DropdownWrapperStyle>
            {(formValues?.firstOptionSelection ===
              BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION ||
              formValues?.firstOptionSelection ===
                BillingNotificationFirstOptionsEnum.THE_PROCEDURE_CODE_IS_INCONSISTENT_PATIENT_AGE) && (
              <DropdownWrapperStyle>
                <Dropdown
                  clearable
                  multiple
                  options={cptCodesDropdownOptions}
                  selection
                  value={formValues?.selectedCptCodes}
                  onChange={(_, { value }) => {
                    setFormValues((prevVal) => ({
                      ...prevVal,
                      selectedCptCodes:
                        value as BillingNotificationFirstOptionsEnum[],
                    }));
                  }}
                />
              </DropdownWrapperStyle>
            )}
            {formValues?.firstOptionSelection ===
              BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY && (
              <>
                Please confirm if the patient have an active policy and update
                the patient insurance information.
              </>
            )}
            {renderBillingMessageMultipleCompleteAndSignDoc(
              formValues?.firstOptionSelection
            )}
          </>
        )}
        {formValues.firstOptionSelection ===
          BillingNotificationFirstOptionsEnum.FREE_TEXT && (
          <>
            {' '}
            <Input
              fluid
              type="text"
              value={formValues?.freeText}
              onChange={(_, { value }) => {
                setFormValues((prevVal) => ({
                  ...prevVal,
                  freeText: value as string,
                }));
              }}
            />
          </>
        )}
      </MainContextParagraphStyle>
      <MainContextParagraphStyle>
        Free Text:{' '}
        <CheckboxWrapperStyle>
          <Checkbox
            checked={
              formValues?.firstOptionSelection ===
              BillingNotificationFirstOptionsEnum.FREE_TEXT
            }
            onChange={(_, { checked }) =>
              setFormValues((prevVal) => ({
                ...prevVal,
                firstOptionSelection: checked
                  ? BillingNotificationFirstOptionsEnum.FREE_TEXT
                  : undefined,
                freeText: undefined,
              }))
            }
          />
        </CheckboxWrapperStyle>
      </MainContextParagraphStyle>
    </>
  );
};

export default BillingNotificationForm;
