import React from 'react';
import { Select } from 'semantic-ui-react';

import { InventoryStatus } from '@bluefox/models/Inventory';

interface InventoryStatusPickerProps {
  value?: InventoryStatus | InventoryStatus[];
  onChange?: (value: {
    status: InventoryStatus;
    statuses: InventoryStatus[];
  }) => void;
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  [key: string]: any;
}

const InventoryStatusPicker = ({
  value,
  onChange,
  placeholder,
  multiple,
  ...selectProps
}: InventoryStatusPickerProps) => {
  const options = [
    {
      text: 'Received',
      value: InventoryStatus.received,
    },
    {
      text: 'Retired',
      value: InventoryStatus.retired,
    },
    {
      text: 'Expired',
      value: InventoryStatus.expired,
    },
    {
      text: 'Returned',
      value: InventoryStatus.returned,
    },
  ];

  return (
    <Select
      value={value}
      onChange={(_, { value }) =>
        onChange
          ? onChange({
              status: value as InventoryStatus,
              statuses: value as InventoryStatus[],
            })
          : null
      }
      placeholder={placeholder || 'Pick Status'}
      options={options}
      multiple={multiple}
      {...selectProps}
    />
  );
};

export default InventoryStatusPicker;
