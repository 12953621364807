import { CSSProperties, useMemo } from 'react';
import { Organization } from '@bluefox/models/Organization';
import { Container, Form } from 'semantic-ui-react';

type Props = {
  loading: boolean;
  organizations: Organization[];
  disabled?: boolean;
  onChange?: (organization: Organization) => void;
  style?: CSSProperties;
};

const OrganizationSelector = ({
  loading,
  organizations,
  disabled,
  onChange,
  style,
}: Props) => {
  const checkboxOptions = useMemo(
    () =>
      organizations.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id,
      })),
    [organizations]
  );

  return (
    <Container style={style}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Dropdown
              label="Organization"
              search
              selection
              loading={loading}
              disabled={disabled}
              options={checkboxOptions}
              onChange={(_, { value }) => {
                const selectedOrganization = organizations.find(
                  (org) => org.id === value
                );
                onChange?.(selectedOrganization as Organization);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default OrganizationSelector;
