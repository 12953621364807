import React, { useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ProcedureAmountCptQuery } from '@bluefox/graphql/procedureAmounts';
import {
  GetVisPublication,
  InsertVaccine,
  InsertVisPublication,
  UpdateVisPublication,
} from '@bluefox/graphql/vaccines';
import { useHistory } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Vaccine, VisPublication } from '@bluefox/models/Vaccine';
import { toast } from 'react-semantic-toasts';
import { useInformationModalAwait } from '@bluefox/ui/InformationModal';
import VaccineForm from './VaccineForm';

const VaccineNew = () => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [vaccine, setVaccine] = useState({
    fallback: false,
  } as unknown as Vaccine);
  const [visPublication, setVisPublication] = useState<VisPublication>(
    undefined as unknown as VisPublication
  );
  const {
    loading: getProcedureAmountsLoading,
    error: getProcedureAmountsError,
    data: getProcedureAmountsData,
  } = useQuery(ProcedureAmountCptQuery);
  const [insertVaccine, { loading: insertVaccineLoading }] =
    useMutation(InsertVaccine);
  const { render: renderInformationModal, open: openInformationModal } =
    useInformationModalAwait();
  const loading = getProcedureAmountsLoading || insertVaccineLoading;
  const error = getProcedureAmountsError;
  const covidString = 'COVID-19';
  const visPublicationStatuses = {
    HISTORIC: 'historic',
    CURRENT: 'current',
  };
  const stringEncodedStartNumber = '253';
  const stringEncodedSeparatorNumber = '11';

  const [insertVisPublication] = useMutation(InsertVisPublication);
  const [updateVisPublication] = useMutation(UpdateVisPublication);

  const [getVisPublication] = useLazyQuery(GetVisPublication, {
    onCompleted(data) {
      if (
        data.visPublications.length &&
        visPublication.publicationDate !==
          data.visPublications[0].publicationDate
      ) {
        updateVisPublication({
          variables: {
            updateId: visPublication.id,
            updateObject: {
              status: visPublicationStatuses.HISTORIC,
            },
            insertObject: makeVisPublicationInsertObject(),
          },
        });
      } else if (!data.visPublications.length) {
        insertVisPublication({
          variables: {
            insertObject: makeVisPublicationInsertObject(),
          },
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const makeVisPublicationInsertObject = useCallback(() => {
    const stringEncondedDate =
      visPublication.publicationDate &&
      visPublication.publicationDate.getFullYear().toString().slice(-2) +
        ('0' + (visPublication.publicationDate.getMonth() + 1)).slice(-2) +
        ('0' + visPublication.publicationDate.getDate()).slice(-2);
    const stringEncoded =
      stringEncodedStartNumber +
      visPublication.conceptCode +
      stringEncodedSeparatorNumber +
      stringEncondedDate;
    return {
      conceptCode: visPublication.conceptCode,
      conceptName: visPublication.conceptName,
      publicationDate: visPublication.publicationDate,
      stringEncoded,
      status: visPublicationStatuses.CURRENT,
      vaccineTypes: vaccine.types,
      cvx: vaccine?.types?.includes(covidString) ? vaccine.cvx : null,
    };
  }, [visPublication]);

  const handleSubmit = async () => {
    setDisabled(true);
    const useNdc = String(vaccine?.useNdc)
      .split(',')
      .map((use) => use.trim());

    const useNdc10 = String(vaccine?.useNdc10)
      .split(',')
      .map((use10) => use10.trim());

    try {
      const retData = await insertVaccine({
        variables: {
          ...vaccine,
          useNdc,
          useNdc10,
        },
      });

      await getVisPublication({
        variables: {
          where: {
            status: { _eq: 'current' },
            vaccineTypes: { _has_keys_all: vaccine.types },
            cvx: vaccine?.types?.includes('COVID-19')
              ? { _has_keys_any: [vaccine.cvx] }
              : {},
          },
        },
      });

      toast({
        title: 'Vaccine saved',
        type: 'success',
        time: 1000,
      });

      const id = retData?.data?.insert_vaccines?.returning[0]?.id;
      if (id) history.push(`/vaccines/${id}`);
    } catch (error) {
      const errMessage = (error as Error)?.message;
      switch (errMessage) {
        case 'Uniqueness violation. duplicate key value violates unique constraint "vaccines_code_key"':
          await openInformationModal(
            `Vaccine save failed. Error: 'Sale NDC11' value duplicated`,
            'Error'
          );
          break;
        case 'Uniqueness violation. duplicate key value violates unique constraint "vaccines_ndc10_key"':
          await openInformationModal(
            `Vaccine save failed. Error: 'Sale NDC10' value duplicated`,
            'Error'
          );
          break;
        default:
          await openInformationModal(
            `Vaccine save failed. Error: ${errMessage}`,
            'Error'
          );
      }
    } finally {
      setDisabled(false);
    }
  };

  const cptCodes = useMemo(
    () =>
      getProcedureAmountsData?.procedure_amounts.map(
        (pa: Partial<{ cpt: string }>) => pa.cpt
      ),
    [getProcedureAmountsData?.procedure_amounts]
  );

  return (
    <>
      {renderInformationModal()}
      {loading && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
      {error && <p>Error : {error.message}</p>}
      {!error && cptCodes && (
        <VaccineForm
          disabled={disabled}
          submitButtonLable="Save"
          vaccine={vaccine}
          setVaccine={setVaccine}
          visPublication={visPublication}
          setVisPublication={setVisPublication}
          onSubmit={handleSubmit}
          onCancel={() => history.push('/vaccines/')}
          cptCodes={cptCodes}
        />
      )}
    </>
  );
};

export default VaccineNew;
