import React from 'react';
import { Button, Icon, Menu } from 'semantic-ui-react';
import GS1GunScanner from '@bluefox/ui/GS1GunScanner';
import GS1 from '@bluefox/lib/gs1';
import { ActionsMenuProps, STEPS } from '@bluefox/models/models';

const ActionsMenu = (props: ActionsMenuProps) => {
  const { onScan, onClickNextButton, onClickCancelButton, currentStep } = props;

  const handleOnScan = (gs1: GS1, code: string) => {
    const lot = gs1.getLot();
    const expDate = gs1.getExp();
    const ndc = gs1.getNdc();
    if (!lot || !expDate || !ndc) return;
    const [parsedDate, tail] = expDate?.toISOString().split('T');
    onScan({ lot, parsedDate, ndc });
  };

  const getButtonLabel = () => {
    if (currentStep === STEPS.BORROWING) {
      return 'Apply';
    }
    if (currentStep === STEPS.VFC) {
      return 'Borrowing';
    }
    return 'Next';
  };

  if (currentStep === undefined) {
    return null;
  }

  return (
    <Menu borderless>
      {(currentStep === STEPS.VFC || currentStep === STEPS.PRIVATE) && (
        <Menu.Item position="left">
          <GS1GunScanner
            data-automation-id={`scan-button`}
            component={Button}
            renderOn={
              <>
                <Icon name="stop" />
                Scanning
              </>
            }
            renderOff={
              <>
                <Icon name="qrcode" />
                Scan
              </>
            }
            onGS1Scan={handleOnScan}
            onError={() => {}}
            icon
            primary
            size="big"
            labelPosition="left"
          />
        </Menu.Item>
      )}
      <Menu.Item position="right">
        <Button
          content={getButtonLabel()}
          color="olive"
          icon="check"
          onClick={onClickNextButton}
        />
      </Menu.Item>
      <Menu.Menu>
        <Menu.Item position="right">
          <Button negative onClick={onClickCancelButton}>
            {' '}
            Cancel
          </Button>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default ActionsMenu;
