import { CSSProperties, useCallback, useState } from 'react';
import OrganizationSettingsForm from './OrganizationSettings/OrganizationSettingsForm';
import {
  Button,
  Card,
  Container,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import styled from 'styled-components';
import OrganizationSelector from './OrganizationSelector';
import { useMutation, useQuery } from '@apollo/client';
import {
  UpdateOrganizationSettings,
  Organizations as organizationSettingsQuery,
} from '@graphql/organizations';
import {
  InvoicingStrategy,
  Organization,
  OrganizationSettings as OrganizationSettingsModel,
} from '@bluefox/models/Organization';
import { toast } from 'react-semantic-toasts';
import { Practice } from '@bluefox/models/Practice';
import Invoicing from '@ui/Billing/dashboard/Invoicing';
import { addTypenameToDocument } from '@apollo/client/utilities';

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

type Props = { style?: CSSProperties };

const OrganizationSettings = ({ style }: Props) => {
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>();
  const [enableEdition, setEnableEdition] = useState(false);
  const [formValues, setFormValues] = useState<OrganizationSettingsModel>({});
  const [practices, setPractices] = useState<Practice[]>([]);
  const {
    loading: organizationSettingsQueryLoading,
    error: organizationSettingsQueryError,
    data: organizationSettingsQueryData,
    refetch: organizationSettingsQueryRefetch,
  } = useQuery<{ organizations: Organization[] }>(organizationSettingsQuery, {
    fetchPolicy: 'network-only',
  });

  const [
    updateOrganizationSetting,
    { loading: updateOrganizationSettingLoading },
  ] = useMutation(UpdateOrganizationSettings);

  const cancelEdition = useCallback(() => {
    setEnableEdition(false);

    setFormValues(
      selectedOrganization?.settings
        ? { ...selectedOrganization?.settings }
        : {}
    );
  }, [selectedOrganization?.settings]);

  const handleFormSubmit = useCallback(
    async (
      organizationSettings: OrganizationSettingsModel,
      practices?: Practice[]
    ) => {
      try {
        await updateOrganizationSetting({
          variables: {
            organizationId: selectedOrganization?.id,
            settings: organizationSettings,
            practices,
          },
        });
        const data = await organizationSettingsQueryRefetch();
        if (selectedOrganization) {
          setSelectedOrganization(
            data.data.organizations.find(
              (org) => org.id === selectedOrganization?.id
            )
          );
        }
        setEnableEdition(false);
      } catch (e) {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [
      organizationSettingsQueryRefetch,
      selectedOrganization,
      updateOrganizationSetting,
    ]
  );

  return (
    <Container data-automation-id="it-organization" style={style}>
      <Segment>
        {organizationSettingsQueryError && (
          <Message error>
            Failed to get Organizations and their Practices. Error:{' '}
            {organizationSettingsQueryError.message}
          </Message>
        )}
        <Dimmer
          active={
            organizationSettingsQueryLoading || updateOrganizationSettingLoading
          }
        >
          <Loader />
        </Dimmer>

        <Card fluid>
          <Card.Content>
            <StyledHeader>
              <Card.Header as={'h3'} style={{ margin: 0 }}>
                <Icon name="hospital" style={{ marginRight: '0.6rem' }} />
                Organization Setup
              </Card.Header>
            </StyledHeader>
            <Card.Description>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <OrganizationSelector
                      loading={organizationSettingsQueryLoading}
                      disabled={enableEdition}
                      organizations={
                        organizationSettingsQueryData?.organizations || []
                      }
                      onChange={(organization) => {
                        setSelectedOrganization(organization);
                        setFormValues(
                          organization.settings
                            ? {
                                ...organization.settings,
                                invoicing: {
                                  ...organization.settings.invoicing,
                                  strategy:
                                    organization.settings?.invoicing
                                      ?.strategy ??
                                    InvoicingStrategy.BY_ORGANIZATION,
                                },
                              }
                            : {}
                        );
                        setPractices(
                          organization.practices.map(
                            //@ts-ignore
                            ({ __typename, ...rest }) => rest
                          )
                        );
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                    <Button
                      primary
                      disabled={!!!selectedOrganization}
                      onClick={() => {
                        if (enableEdition) {
                          cancelEdition();
                        } else {
                          setEnableEdition(true);
                        }
                      }}
                    >
                      {enableEdition ? 'Cancel Edition' : 'Enable Edition'}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <OrganizationSettingsForm
                enableEdition={enableEdition}
                setPractices={setPractices}
                practices={practices}
                formValues={formValues}
                setFormValues={setFormValues}
                onCancel={cancelEdition}
                onSubmit={handleFormSubmit}
                organizationId={selectedOrganization?.id}
              />
            </Card.Description>
          </Card.Content>
        </Card>
      </Segment>
    </Container>
  );
};

export default OrganizationSettings;
