import { useState, useEffect } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import PatientPicker from '@bluefox/ui/PatientPicker';
import VaccineCptCodePicker from '@ui/ClaimModal/VaccineCptCodePicker';

interface PracticeOption {
  text: string;
  value: string;
}

interface PickerData {
  key: string;
  value: string;
  text: string;
}

interface VaccinationFormProps {
  practices: PracticeOption[];
  handleVaccination: (
    practiceId: string,
    patientId: string,
    vaccineId: string,
    givenAt: string
  ) => void;
  vaccinationData?: {
    practiceId: string;
    patientId: string;
    vaccineId: string;
    givenAt: string;
  };
}

const VaccinationForm = ({
  practices,
  handleVaccination,
  vaccinationData,
}: VaccinationFormProps) => {
  const [practiceId, setPracticeId] = useState<string>();
  const [patient, setPatient] = useState<PickerData>();
  const [vaccine, setVaccine] = useState<PickerData>();
  const [givenAt, setGivenAt] = useState<Date | undefined>(undefined);

  const handlePracticeValue = (value: string) => {
    setPracticeId(value);
  };

  const handlePatient = (patient: PickerData) => {
    setPatient(patient);
  };

  const handleVaccine = (vaccine: PickerData) => {
    setVaccine(vaccine);
  };

  useEffect(() => {
    if (!practiceId || !patient || !vaccine || !givenAt) return;
    handleVaccination(
      practiceId,
      patient.value,
      vaccine.value,
      givenAt.toISOString()
    );
  }, [practiceId, patient, vaccine, givenAt]);

  useEffect(() => {
    if (!vaccinationData) return;
    setPracticeId(vaccinationData.practiceId);
  }, [vaccinationData]);

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field required style={{ zIndex: '99' }}>
          <label>Practice</label>
          <Dropdown
            style={{ minWidth: '15rem' }}
            placeholder="Select practice"
            fluid
            selection
            value={practiceId}
            onChange={(e, data) => {
              handlePracticeValue(data.value?.toString() || '');
            }}
            options={practices}
          />
        </Form.Field>
        <Form.Field required>
          <label>Given At</label>
          <DateTimePicker
            onChange={(value) =>
              setGivenAt(value ? (value as Date) : undefined)
            }
            selected={givenAt}
            scrollableYearDropdown
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            maxDate={new Date()}
            required
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required>
          <label>Patient</label>
          <PatientPicker
            handlePatient={handlePatient}
            practiceId={practiceId ? practiceId : ''}
            disabled={!practiceId}
          />
        </Form.Field>
        <Form.Field required>
          <label>Vaccine by Inventory CPT Code</label>
          <VaccineCptCodePicker handleVaccine={handleVaccine} />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default VaccinationForm;
