import { AdministrationCptCodes, Dosage } from '@bluefox/models/Vaccine';
import { isArrayNotEmpty } from './array';
import { isStringNotEmpty } from './string';

/**
 * Check if Dosage is valid. Only one dose is supported. Only unit and dosage is checked
 * @param dosage
 * @returns true if dosage is valid
 */
export function isDosageValid(
  dosage: Dosage[] | undefined
): dosage is Dosage[] {
  return (
    isArrayNotEmpty(dosage) &&
    typeof dosage[0]?.dose === 'number' &&
    !isNaN(dosage[0]?.dose) &&
    isStringNotEmpty(dosage[0]?.unit)
  );
}

/**
 * Checks if adminCptCode is a valir AdministrationCptCodes. Only defaul property is supported
 * @param adminCptCode
 * @returns
 */
// Only one dose is supported.
export function isAdministrationCPTCodeValid(
  adminCptCode: AdministrationCptCodes | undefined
): adminCptCode is AdministrationCptCodes {
  return isArrayNotEmpty(adminCptCode?.default);
}
