import moment from 'moment';
import { DATE_MONTHS } from '@bluefox/constants';

export const getDiffInDays = (date: string) => {
  const dateToCheck = moment(date).format('MM-DD-YYYY');
  return moment().diff(dateToCheck, 'days');
};

export const formatDateForDatePicker = (date: Date | undefined | null) => {
  return date
    ? moment(date)
        .add(new Date().getTimezoneOffset() + 60, 'minutes')
        .toDate()
    : null;
};

export function buildMonthYearDropDownItem(month: number, year: number) {
  const value = `${year}/${month}`;
  return {
    key: value,
    text: `${DATE_MONTHS[month]} ${year}`,
    value,
  };
}

type DropDownOptions = ReturnType<typeof buildMonthYearDropDownItem>;

export function buildMonthYearDropDownOptions(
  currentMonth: number,
  currentYear: number
) {
  const options: DropDownOptions[] = [];
  for (let offset = 0; offset < 12; offset++) {
    const monthOffset = currentMonth - offset;
    if (monthOffset >= 0) {
      options.push(buildMonthYearDropDownItem(monthOffset, currentYear));
    } else {
      options.push(
        buildMonthYearDropDownItem((12 + monthOffset) % 12, currentYear - 1)
      );
    }
  }
  return options;
}

export function buildMonthYearDropDownOptionsFromNow() {
  const d = new Date();
  const [currentMonth, currentYear] = [d.getMonth(), d.getFullYear()];
  return buildMonthYearDropDownOptions(currentMonth, currentYear);
}

export function monthYearDropDownOptionsToMonthYear(value: string) {
  return value.split('/').map((item) => parseInt(item));
}

export function differenceInDaysBetweenDates(date1: Date, date2: Date) {
  const diff = date1.getTime() - date2.getTime();

  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  return Math.round(diff / millisecondsPerDay);
}

export const generateRangeByDate = (date: Date) => {
  const [from, to] = [new Date(date), new Date(date)];
  from.setUTCHours(0, 0, 0, 0);
  to.setUTCHours(23, 59, 59, 59);
  return { from: from.toISOString(), to: to.toISOString() };
};
