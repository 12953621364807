import moment from 'moment-timezone';
import { BillingClaimStatus, ClaimUpdate } from '@bluefox/models/Billing';
import {
  Popup,
  List,
  Card,
  Icon,
  Button,
  Placeholder,
  Segment,
  PopupProps,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';

interface ClaimUpdates {
  claimUpdates: ClaimUpdate[];
}

interface ClaimStatusLogProps {
  data: ClaimUpdates | undefined;
  onClick?: () => void;
  loading: boolean;
  position: PopupProps['position'];
}

const handleCopyPass = (str: string | undefined) => {
  navigator.clipboard.writeText(str || '');

  toast({
    title: 'Copied!',
    type: 'success',
    time: 1000,
  });
};

const ClaimStatusLog = ({
  data,
  onClick,
  loading,
  position,
}: ClaimStatusLogProps) => {
  return (
    <Popup
      size="small"
      position={position}
      on="click"
      content={
        <List
          style={{
            maxHeight: '50rem',
            overflowY: 'auto',
            padding: '1rem',
          }}
        >
          <List.Item>
            <List.Header>{`Logs (${
              loading ? '-' : data?.claimUpdates.length
            })`}</List.Header>
          </List.Item>
          {loading ? (
            <Card>
              <Segment basic>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Segment>
            </Card>
          ) : (
            data?.claimUpdates?.map((c) => {
              return (
                <Card>
                  <List>
                    <List.Item>
                      <List.Header>Date</List.Header>
                      {moment.utc(c.createdAt).format(DateFormats.DATE)}
                    </List.Item>
                    <List.Item>
                      <List.Header>Status</List.Header>
                      {humanizeText(c.status, {
                        capitalize: 'first',
                        delimiter: '_',
                      })}
                    </List.Item>
                    {c.status === BillingClaimStatus.DISMISSED && (
                      <List.Item>
                        <List.Header>Dismissed Reason</List.Header>
                        {c.metadata?.dismissedReason
                          ? humanizeText(c.metadata?.dismissedReason, {
                              capitalize: 'all',
                              delimiter: '_',
                            })
                          : '-'}
                      </List.Item>
                    )}
                    <List.Item>
                      <List.Header>Insurance</List.Header>
                      {c.metadata?.insurance?.insuranceName || '-'}
                    </List.Item>
                    {c.metadata?.alreadySubmittedData && (
                      <List.Item>
                        <List.Header>Already submitted data</List.Header>

                        {c.metadata.alreadySubmittedData.length > 1 ? (
                          <Popup
                            trigger={<Button content="Show" size="mini" />}
                            content={c.metadata?.alreadySubmittedData.map(
                              (item) => (
                                <List bulleted>
                                  <List.Item>{item}</List.Item>
                                </List>
                              )
                            )}
                          />
                        ) : (
                          c.metadata?.alreadySubmittedData.map((item) => (
                            <List bulleted>
                              <List.Item>{item}</List.Item>
                            </List>
                          ))
                        )}
                      </List.Item>
                    )}

                    {c.metadata?.note?.url && (
                      <List.Item>
                        <List.Header>EOB Link</List.Header>
                        <div className="ui left aligned grid">
                          <div className="middle align five wide column">
                            <Popup
                              size="small"
                              trigger={
                                <a href={c.metadata?.note?.url} target="_blank">
                                  EOB Link
                                </a>
                              }
                              on="hover"
                              content={c.metadata?.note?.url}
                            ></Popup>
                          </div>
                          <div className="left aligned one column">
                            <Button
                              circular
                              icon="copy"
                              size="tiny"
                              onClick={(e: React.MouseEvent<HTMLElement>) => {
                                handleCopyPass(c.metadata?.note?.url);
                              }}
                            />
                          </div>
                        </div>
                      </List.Item>
                    )}

                    <List.Item>
                      <List.Header>Source</List.Header>
                      {humanizeText(c.source, { capitalize: 'first' })}
                    </List.Item>
                    {c.source === 'manual' && (
                      <List.Item>
                        <List.Header>Changed by</List.Header>
                        {c.practiceAccount
                          ? `${c.practiceAccount?.account.firstName} ${c.practiceAccount?.account.lastName} (${c.practiceAccount?.account.email})`
                          : '-'}
                      </List.Item>
                    )}
                    {c.status === 'processed' &&
                      c.response?.claims?.length > 0 &&
                      c.response?.claims[0]?.claimStatus && (
                        <List.Item>
                          <List.Header>Status category code</List.Header>
                          {
                            c.response?.claims[0]?.claimStatus
                              .statusCategoryCode
                          }
                          <List.Header>Status category code value</List.Header>
                          {
                            c.response?.claims[0]?.claimStatus
                              .statusCategoryCodeValue
                          }
                          <List.Header>Status code</List.Header>
                          {c.response?.claims[0]?.claimStatus.statusCode}
                          <List.Header>Status code value</List.Header>
                          {c.response?.claims[0]?.claimStatus.statusCodeValue}
                        </List.Item>
                      )}
                  </List>
                </Card>
              );
            })
          )}
        </List>
      }
      trigger={
        <Button
          className="min-component-vertical-margin"
          color="teal"
          size="tiny"
          icon="eye"
          onClick={onClick}
        />
      }
    />
  );
};

export default ClaimStatusLog;
