import { Address } from './Address';
import { Sex } from './Patient';

export enum GuardianRelationships {
  FATHER = 'father',
  MOTHER = 'mother',
  PARENT = 'parent',
  GUARDIAN = 'guardian',
  SELF = 'self',
  OTHER = 'other',
}

export interface Guardian {
  id: string;
  firstName: string;
  lastName: string;
  birthdate: Date;
  sex: Sex;
  phone: string;
  email: string;
  address?: Address;
}

export interface GuardianPatient {
  id: string;
  guardianId: string;
  patientId: string;
  relationship: GuardianRelationships;
  guardian: Guardian;
  active: boolean;
}
