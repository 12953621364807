import { notificationStatusEnumToText } from '@bluefox/constants/notifications';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import { Notification } from '@bluefox/models/Notification';
import OrderNotificationMessage from '@bluefox/ui/Notifications/NotificationMessage/OrderNotificationMessage';

type Props = {
  notification: Notification;
};

const OrderNotificationModalRenderMessage = ({ notification }: Props) => {
  return (
    <>
      <p>
        Notification sent on:{' '}
        <b>
          {formatDateToYYYYhMMhDD(new Date(notification.createdAt as string))}
        </b>
        . Message:
      </p>
      <OrderNotificationMessage notification={notification} />
      <p>
        Status: <b>{notificationStatusEnumToText[notification.status]}</b>
      </p>
    </>
  );
};

export default OrderNotificationModalRenderMessage;
