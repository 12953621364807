import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, Button, Loader, Dimmer } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { debounce } from '@bluefox/lib/debounce';
import { GetVisPublication, getVaccine } from '@bluefox/graphql/vaccines';
import { Vaccine as VaccineType } from '@bluefox/models/Vaccine';
import {
  formatAdministrationCptCodes,
  formatDosages,
  formatRoutes,
  formatVaccineInventoryCptCodes,
  formatVaccineTypes,
} from '@bluefox/lib/formatters';

const col1Width = 2;
const col2Width = 6;

const BottomGrid = styled(Grid)({
  margin: '1rem 0',
});

const BottomButton = styled(Button)({
  margin: '0 1rem !important',
});

const StyledGrid = styled(Grid)({
  border: '1px solid rgba(34,36,38,.15);',
  borderRadius: '8px',
  margin: '0rem !important',
});

const Vaccine = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const debouncedRef = useRef<ReturnType<typeof debounce>>();
  const [iscopied, setIsCopied] = useState({} as { [key: string]: any });
  const { loading, error, data } = useQuery(getVaccine, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      getVisPublication({
        variables: {
          where: {
            status: { _eq: 'current' },
            vaccineTypes: { _has_keys_all: data.vaccines[0].types },
          },
        },
      });
    },
  });

  const vac = data?.vaccines[0] as VaccineType;

  const [getVisPublication, { data: visPublicationData }] =
    useLazyQuery(GetVisPublication);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  const handleCopy = useCallback(
    (key: string, value: string | undefined) => {
      navigator.clipboard.writeText(value || '');
      setIsCopied({ [key]: true });
      debouncedRef.current?.cancel();
      debouncedRef.current = debounce(() => {
        setIsCopied({});
      }, 3000);
      debouncedRef.current();
    },
    [setIsCopied, debouncedRef]
  );

  const renderCopyableField = useCallback(
    (key: string, value: string | undefined) => (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column floated="left">{value}</Grid.Column>
          <Grid.Column floated="right">
            <Button
              icon="copy"
              primary
              size="mini"
              inverted={iscopied?.[key]}
              onClick={() => {
                handleCopy(key, value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ),
    [handleCopy, iscopied]
  );

  return (
    <>
      <section>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {error && <p>Error : {error.message}</p>}
        {!loading && !error && data && !vac && <p>Vaccine not found</p>}
        {!loading && !error && vac && (
          <>
            <StyledGrid columns={4}>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Name:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>{vac?.name}</Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Manufacturer:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>{vac?.manufacturer}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Fallback</b>:
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {vac?.fallback ? 'true' : 'false'}
                </Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>MVX:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>{vac?.mvx}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>CVX:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>{vac?.cvx}</Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>CVX Group:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>{vac?.cvxGroup}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>AKA:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>{vac?.aka}</Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Self Pay Price:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>{vac?.selfPayPrice}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Sale NDC11:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {renderCopyableField('sale_ndc', vac?.saleNdc)}
                </Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Sale NDC10:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {renderCopyableField('sale_ndc10', vac?.saleNdc10)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Use NDC11:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {vac?.useNdc?.join(',')}
                </Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Use NDC10:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {vac?.useNdc10?.join(',')}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Type:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {formatVaccineTypes(vac?.types)}
                </Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Routes:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {formatRoutes(vac?.routes)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Dosage:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {formatDosages(vac?.dosage)}
                </Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Inventory CPT Codes:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {formatVaccineInventoryCptCodes(vac?.inventoryCptCodes)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Administration CPT codes WD:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {formatAdministrationCptCodes(vac?.administrationCptCodesWd)}
                </Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Administration CPT codes WOD:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {formatAdministrationCptCodes(vac?.administrationCptCodesWod)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={col1Width}>
                  <b>Vis Publication Date:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {visPublicationData?.visPublications.length
                    ? visPublicationData?.visPublications[0]?.publicationDate
                    : '-'}
                </Grid.Column>
                <Grid.Column width={col1Width}>
                  <b>Active:</b>
                </Grid.Column>
                <Grid.Column width={col2Width}>
                  {vac?.active ? 'true' : 'false'}
                </Grid.Column>
              </Grid.Row>
            </StyledGrid>
            <BottomGrid>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <BottomButton
                    primary
                    type="button"
                    onClick={() => history.push(`/vaccines/${id}/edit`)}
                  >
                    Edit
                  </BottomButton>
                  <BottomButton
                    secondary
                    type="button"
                    onClick={() => history.push('/vaccines/')}
                  >
                    Cancel
                  </BottomButton>
                </Grid.Column>
              </Grid.Row>
            </BottomGrid>
          </>
        )}
      </section>
    </>
  );
};

export default Vaccine;
