import { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { useReactToPrint } from 'react-to-print';
import { GenerateInvoiceMutation, BillingReportQuery } from '@graphql/billing';
import {
  ClaimsForInvoice,
  CptCode,
  BillingClaim,
  BillingInvoice,
} from '@bluefox/models/Billing';
import { PracticeProfile, PracticeSettings } from '@bluefox/models/Practice';
import {
  Button,
  Table,
  Header,
  Image,
  Modal,
  List,
  Confirm,
  Segment,
  Message,
  Placeholder,
} from 'semantic-ui-react';
import { generateInvoiceNumber } from '@bluefox/lib/invoice-number-generator';
import BillingReportList from '@ui/BillingReport/BillingReportList';
import ContactFooter from '@ui/ContactFooter';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';

interface CanidProfile {
  profile: PracticeProfile;
}

interface LastInvoiceData {
  invoices: BillingInvoice[];
}

interface BillingReportData {
  billingReport: BillingClaim[];
  totalAmountCalculated: {
    aggregate: {
      sum: {
        totalAmount: number;
      };
    };
  };
  canidProfile: CanidProfile[];
}

interface InvoiceModalProps {
  invoiceId?: string;
  invoiceDate?: string;
  lastInvoice?: LastInvoiceData;
  trigger?: React.ReactNode;
  claimsData?: ClaimsForInvoice;
  claimIds?: string[];
  cptCodes?: CptCode[];
  privateAdminCptIds?: string[];
  vfcAdminCptIds?: string[];
  totalPrivateAdminAmount?: number;
  totalVfcAdminAmount?: number;
  practiceId?: string | undefined;
  practiceSettings?: PracticeSettings;
  onClose: () => void;
  pdfActive?: boolean;
  openWithoutTrigger?: boolean;
  loadingPrivateAdminAmount?: boolean;
  loadingVfcAdminAmount?: boolean;
  onSave?: () => void;
}

const InvoiceModal = ({
  invoiceId,
  invoiceDate,
  lastInvoice,
  trigger,
  claimsData,
  claimIds,
  cptCodes,
  privateAdminCptIds,
  vfcAdminCptIds,
  totalPrivateAdminAmount,
  totalVfcAdminAmount,
  practiceId,
  practiceSettings,
  onClose,
  pdfActive,
  openWithoutTrigger,
  loadingPrivateAdminAmount,
  loadingVfcAdminAmount,
  onSave,
}: InvoiceModalProps) => {
  const [open, setOpen] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [isScrollable, setIsScrollable] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [invoiceNumber, setInvoiceNumber] = useState('-');
  const [paymentDate, setPaymentDate] = useState<Date | null | undefined>(null);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [privateAdminCharge, setPrivateAdminCharge] = useState(
    totalPrivateAdminAmount ?? 0
  );
  const [vfcAdminCharge, setVfcAdminCharge] = useState(
    totalVfcAdminAmount ?? 0
  );
  const [totalAdminAmount, setTotalAdminAmount] = useState<number>(
    totalPrivateAdminAmount && totalVfcAdminAmount
      ? totalPrivateAdminAmount + totalVfcAdminAmount
      : 0
  );

  const { data, loading, refetch } = useQuery<BillingReportData>(
    BillingReportQuery,
    {
      variables: {
        criteria: {
          id: { _in: claimIds },
        },
        criteriaCptCodes: {
          claimId: { _in: claimIds },
          cptCode: { _in: cptCodes?.map((c) => c.cptCode) },
          paidAmount: { _gt: 0 },
          ...(!invoiceId ? { invoice: { _is_null: true } } : {}),
        },
      },
      skip: !pdfActive,
    }
  );

  const [saveInvoice] = useMutation(GenerateInvoiceMutation);

  const lastInvoiceDate = moment(lastInvoice?.invoices[0]?.date).format(
    DateFormats.DATE
  );

  const lastInvoicePaymentDate = moment(
    lastInvoice?.invoices[0]?.paymentDate
  ).format(DateFormats.DATE);

  const diffInDays = moment(lastInvoicePaymentDate).diff(
    lastInvoiceDate,
    'days'
  );

  const handleInvoiceSubmit = () => {
    setIsScrollable(false);

    if (invoiceId) {
      //print historic invoice
      toast({
        title: 'Printing historic invoice ...',
        type: 'success',
        time: 1000,
      });
      setTimeout(() => {
        handlePdf();
      }, 1000);
    } else {
      //print current invoice
      const inventoryCptIds = cptCodes ? cptCodes.map((c) => c.id) : [];

      const cptIds = [
        ...inventoryCptIds,
        ...(privateAdminCptIds || []),
        ...(vfcAdminCptIds || []),
      ];

      saveInvoice({
        variables: {
          practiceId: claimsData?.practices[0].id,
          invoiceDate: new Date(),
          invoiceAmount: claimsData?.claims
            .reduce((accum, cpt) => accum + cpt.amount, 0)
            .toFixed(2),
          cptCodes: cptIds,
          claims: claimIds,
          paymentDate: paymentDate
            ? moment(paymentDate).format(DateFormats.DATE)
            : null,
        },
      })
        .then((r) => {
          if (r.data.invoice.code !== 200) {
            toast({
              title: `Error generating invoice: ${r.data.invoice.body.error}`,
              type: 'error',
              time: 5000,
            });
          } else {
            setInvoiceNumber(r.data.invoice.body.id);
            toast({
              title: 'Invoice has been saved successfully',
              type: 'success',
              time: 1000,
            });
            setSaveDisabled(true);
            if (onSave) onSave();
            refetch();
          }
        })
        .catch((e) => {
          toast({
            title: `Callback error: ${e}`,
            type: 'error',
            time: 5000,
          });
        });
    }
  };

  useEffect(() => {
    if (openWithoutTrigger) {
      setOpen(openWithoutTrigger);
    } else {
      setOpen(false);
    }
  }, [openWithoutTrigger]);

  useEffect(() => {
    if (openConfirm) {
      if (lastInvoice?.invoices[0]?.paymentDate) {
        setPaymentDate(
          new Date(moment().add(diffInDays, 'days').format(DateFormats.DATE))
        );
      } else {
        setPaymentDate(null);
      }
    }
  }, [openConfirm]);

  useEffect(() => {
    if (invoiceNumber !== '-') handlePdf();
  }, [invoiceNumber]);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePdf = useReactToPrint({
    documentTitle: `${claimsData?.practices[0].name} - Invoice`,
    content: () => componentRef.current,
    onAfterPrint: () => setIsScrollable(true),
    //onBeforePrint: () => setIsScrollable(false),
  });

  useEffect(() => {
    if (!pdfActive) return;
    setIsPdf(pdfActive);
  }, [pdfActive]);

  useEffect(() => {
    if (!data) return;
    refetch();
  }, [data]);

  useEffect(() => {
    if (loadingPrivateAdminAmount || !totalPrivateAdminAmount) return;

    setPrivateAdminCharge(totalPrivateAdminAmount as number);
  }, [loadingPrivateAdminAmount, totalPrivateAdminAmount]);

  useEffect(() => {
    if (loadingVfcAdminAmount || !totalVfcAdminAmount) return;

    setVfcAdminCharge(totalVfcAdminAmount as number);
  }, [loadingVfcAdminAmount, totalVfcAdminAmount]);

  useEffect(() => {
    setTotalAdminAmount(
      (privateAdminCharge as number) + (vfcAdminCharge as number)
    );
  }, [privateAdminCharge, vfcAdminCharge]);

  const showAdministrationAmounts = () => {
    return (
      !practiceSettings?.billingReport ||
      practiceSettings?.billingReport?.showAdministrationAmounts
    );
  };

  // const administrationAmountsGreaterZero = () => {
  //   return (
  //     (totalPrivateAdminAmount && totalPrivateAdminAmount > 0) ||
  //     (totalVfcAdminAmount && totalVfcAdminAmount > 0)
  //   );
  // };

  return (
    <Modal
      closeIcon
      onClose={() => {
        setOpen(false);
        onClose();
        setIsPdf(false);
        setSaveDisabled(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      size="large"
    >
      <Modal.Header>Invoice</Modal.Header>
      <div ref={componentRef}>
        <Modal.Content>
          <Modal.Description>
            <StyledInvoicePage style={isScrollable ? { overflow: 'auto' } : {}}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '1rem 1rem 0 0',
                  }}
                >
                  <Image src="/canid-dark-logo.svg" size="small" />
                </div>
                <StyledInvoiceHeader as="h2">
                  INVOICE
                  <List style={{ fontSize: '12px', fontWeight: '400' }}>
                    <List.Item>
                      <b>PRACTICE NAME: </b>
                      {claimsData?.practices[0].name}
                    </List.Item>
                    <List.Item>
                      <b>DATE: </b>
                      {invoiceDate
                        ? invoiceDate
                        : moment().format(DateFormats.DATE)}
                    </List.Item>
                  </List>
                </StyledInvoiceHeader>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1rem 1rem',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Billed to: </b>
                      {`Dr. ${claimsData?.practices[0].profile.providerFirstName} ${claimsData?.practices[0].profile.providerLastName}`}
                    </div>
                    <div>
                      <b>From: </b> Canid
                    </div>
                  </div>
                </div>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>Quantity</Table.HeaderCell>
                      <Table.HeaderCell width={11}>
                        Vaccine Name
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        CPT Code
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Amount
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {claimsData?.claims.map((c, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>{c.quantity}</Table.Cell>
                          <Table.Cell>{c.vaccineName}</Table.Cell>
                          <Table.Cell textAlign="right">{c.cptCode}</Table.Cell>
                          <Table.Cell textAlign="right">{`$${
                            c.amount ? c.amount.toFixed(2) : 0
                          }`}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan={4} textAlign="right">
                        <b>{`Total: $${claimsData?.claims
                          .reduce((accum, cpt) => accum + cpt.amount, 0)
                          .toFixed(2)}`}</b>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
                <div className="pagebreak"> </div>
                <StyledInvoiceHeader as="h4">
                  Payment Information
                </StyledInvoiceHeader>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <List
                      style={{ paddingLeft: '0.9rem', marginBottom: '1rem' }}
                    >
                      <List.Item>
                        <b>Beneficiary name: </b>
                        {
                          data?.canidProfile[0].profile.paymentInformation
                            ?.beneficiaryName
                        }
                      </List.Item>
                      <List.Item>
                        <b>Bank Name: </b>
                        {
                          data?.canidProfile[0].profile.paymentInformation
                            ?.bankName
                        }
                      </List.Item>
                      <List.Item>
                        <b>ABA Routing Number: </b>
                        {
                          data?.canidProfile[0].profile.paymentInformation
                            ?.abaRoutingNumber
                        }
                      </List.Item>
                      <List.Item>
                        <b>Type of Account: </b>
                        {
                          data?.canidProfile[0].profile.paymentInformation
                            ?.accountType
                        }
                      </List.Item>
                    </List>
                  </div>
                  <div>
                    <List style={{ paddingRight: '0.9rem' }}>
                      <List.Item>
                        <b>Beneficiary Address: </b>
                        {
                          data?.canidProfile[0].profile.paymentInformation
                            ?.beneficiaryAddress
                        }
                      </List.Item>
                      <List.Item>
                        <b>Bank Address: </b>
                        {
                          data?.canidProfile[0].profile.paymentInformation
                            ?.bankAddress
                        }
                      </List.Item>
                      <List.Item>
                        <b>Account Number: </b>
                        {
                          data?.canidProfile[0].profile.paymentInformation
                            ?.accountNumber
                        }
                      </List.Item>
                    </List>
                  </div>
                </div>
              </div>

              <ContactFooter
                email={data?.canidProfile[0].profile.paymentInformation?.email}
                websiteLink={
                  data?.canidProfile[0].profile.paymentInformation?.webSite
                }
                phone={data?.canidProfile[0].profile.paymentInformation?.phone}
              />
            </StyledInvoicePage>

            <StyledInvoicePage style={isScrollable ? { overflow: 'auto' } : {}}>
              <div>
                <div className="pagebreak"> </div>
                <StyledInvoiceHeader as="h4">
                  Billing Report
                </StyledInvoiceHeader>
                <BillingReportList
                  invoiceId={invoiceId}
                  data={data}
                  loading={loading}
                  pdfActive={isPdf}
                  scrollable={isScrollable}
                  onSave={refetch}
                />

                {!!claimIds?.length && showAdministrationAmounts() ? (
                  <>
                    <div
                      style={{
                        marginTop: '2rem',
                        display: 'flex',
                        backgroundColor: '#16324f',
                      }}
                    >
                      <StyledInvoiceHeader as="h4">
                        Recovered administration CPT codes amounts
                      </StyledInvoiceHeader>
                    </div>
                    <Segment
                      style={{
                        marginTop: '0',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                      }}
                    >
                      <StyledAdminAmountsSubheader>
                        We’ve successfully recovered the following amounts on
                        your behalf:
                      </StyledAdminAmountsSubheader>
                      <StyledAdminAmountsText>
                        {loadingPrivateAdminAmount ? (
                          <Placeholder>
                            <StyledPlaceholderLoading>
                              Loading private charges...
                              <Placeholder.Line length="full" />
                            </StyledPlaceholderLoading>
                          </Placeholder>
                        ) : (
                          <>Private Charges: ${privateAdminCharge.toFixed(2)}</>
                        )}
                      </StyledAdminAmountsText>
                      <StyledAdminAmountsText>
                        {loadingVfcAdminAmount ? (
                          <Placeholder>
                            <StyledPlaceholderLoading>
                              Loading vfc charges...
                              <Placeholder.Line length="full" />
                            </StyledPlaceholderLoading>
                          </Placeholder>
                        ) : (
                          <>VFC Charges: ${vfcAdminCharge.toFixed(2)}</>
                        )}
                      </StyledAdminAmountsText>
                      <StyledAdminAmountsSubheader>
                        {loadingPrivateAdminAmount || loadingVfcAdminAmount ? (
                          <Placeholder>
                            <StyledPlaceholderLoading>
                              Loading total recovered...
                              <Placeholder.Line length="full" />
                            </StyledPlaceholderLoading>
                          </Placeholder>
                        ) : (
                          <>Total recovered: ${totalAdminAmount.toFixed(2)}</>
                        )}
                      </StyledAdminAmountsSubheader>
                      <Message style={{ fontStyle: 'italic' }}>
                        *Please note that these recovered amounts are not
                        additional charges to you; they represent funds that we
                        have worked diligently to secure in your favor.
                      </Message>
                    </Segment>
                  </>
                ) : null}
              </div>
              <ContactFooter
                email={
                  claimsData?.practices[0].profile.paymentInformation?.email
                }
                websiteLink={
                  claimsData?.practices[0].profile.paymentInformation?.webSite
                }
                phone={
                  claimsData?.practices[0].profile.paymentInformation?.phone
                }
              />
            </StyledInvoicePage>
          </Modal.Description>
        </Modal.Content>
      </div>
      <Modal.Actions
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
      >
        {claimsData?.practices[0].settings?.stripe?.paymentMethod !==
          'manual' && (
          <Button
            inverted
            color="blue"
            content="Set suggested payment date"
            style={{ marginBottom: '0.5rem', width: '19rem' }}
            onClick={() => setOpenConfirm(true)}
          />
        )}
        <div>
          <Button
            content="Close"
            onClick={() => {
              setOpen(false);
              onClose();
              setIsPdf(false);
            }}
          />
          <Button
            icon="save"
            content="Save / Export PDF"
            onClick={handleInvoiceSubmit}
            primary
            disabled={saveDisabled}
          />
        </div>
      </Modal.Actions>
      <Confirm
        open={openConfirm}
        onCancel={() => {
          setPaymentDate(null);
          setOpenConfirm(false);
        }}
        onConfirm={() => {
          setPaymentDate(
            paymentDate
              ? new Date(moment(paymentDate).format(DateFormats.DATE))
              : null
          );
          setOpenConfirm(false);
        }}
        size="tiny"
        header="Set Invoice Payment Date"
        content={
          <div style={{ margin: '1.5rem' }}>
            <StyledText>
              This is a suggested payment date based on the previous invoice.
              You can use the suggested payment date, choose a new one, or leave
              the payment date without data for now.
            </StyledText>
            <DateTimePicker
              forFilter
              placeholderText="Payment Date"
              selected={paymentDate}
              onChange={(d) => {
                setPaymentDate(d ? (d as Date) : null);
              }}
              onClear={() => setPaymentDate(null)}
              minDate={moment().toDate()}
              dateFormat={DateFormats.DATE}
              showYearDropdown
              showMonthDropdown
              scrollableYearDropdown
              dropdownMode="select"
              isClearable
            />
          </div>
        }
        confirmButton="Yes"
      />
    </Modal>
  );
};

const StyledInvoicePage = styled.div`
  border: 1px solid lightgrey;
  padding: 0.5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledInvoiceHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  background-color: #16324f;
  color: #ffffff;
  padding: 1rem 1rem;
`;

const StyledText = styled.p`
  font-size: 16px;
`;

const StyledAdminAmountsSubheader = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 1rem;
`;

const StyledAdminAmountsText = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 1rem;
`;

const StyledPlaceholderLoading = styled(Placeholder.Header)`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 14px;
`;

export default InvoiceModal;
