import { Table, Segment, Placeholder, Message } from 'semantic-ui-react';
import Pagination from '@bluefox/ui/Pagination';
import { VaccinationView } from '@bluefox/models/Vaccination';
import VaxSyncRow from './vaxSyncRow';
import { useCallback, useState } from 'react';
import {
  Notification,
  NotificationType,
  VaxSyncIntegrationNotificationContent,
} from '@bluefox/models/Notification';
import VaxSyncIntegrationNotificationModal, {
  VaxSyncIntegrationNotificationModalData,
} from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationModal';
import { FormValues } from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationForm';
import { useQuery } from '@apollo/client';
import { GetNotificationsByEntityRefIdQuery } from '@graphql/communications';
import { useNotifications } from '@ui/Notifications/useNotifications';
import { toast } from 'react-semantic-toasts';

interface VaxSyncData {
  vaccinations: VaccinationView[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface VaxSyncTableProps {
  data: VaxSyncData | undefined;
  loading: boolean;
  refetch: () => void;
  total: number;
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
}

const VaxSyncTable = ({
  data,
  loading,
  refetch,
  total,
  totalPages,
  page,
  setPage,
}: VaxSyncTableProps) => {
  const [sendNotificationModalData, setSendNotificationModalData] =
    useState<VaxSyncIntegrationNotificationModalData>();
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues);

  const { sendNotificationHandler, editNotificationHandler } =
    useNotifications();

  const {
    data: notificationsInScreenData,
    loading: notificationsInScreenLoading,
    refetch: notificationsInScreenRefetch,
  } = useQuery<{
    communicationNotifications: Notification[];
  }>(GetNotificationsByEntityRefIdQuery, {
    variables: {
      entityRefIds: data?.vaccinations.map((v) => v.id),
    },
    skip: !data?.vaccinations || !data?.vaccinations.length,
  });

  const refetchNotificationInScreen = useCallback(async () => {
    try {
      await notificationsInScreenRefetch?.();
    } catch (error) {
      toast({
        title: `Failed to refetch notification list. Error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [notificationsInScreenRefetch]);

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Created At</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Date Of Service
            </Table.HeaderCell>
            <Table.HeaderCell>Patient</Table.HeaderCell>
            <Table.HeaderCell>Insurance</Table.HeaderCell>
            <Table.HeaderCell>Vaccine</Table.HeaderCell>
            <Table.HeaderCell>Prescriber</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Given By</Table.HeaderCell>
            <Table.HeaderCell>Route - Site</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">EMR</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Registry</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">IIS Consent</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">More Options</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={9}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data && data.vaccinations.length ? (
            data.vaccinations.map((v) => {
              return (
                <VaxSyncRow
                  key={v.id}
                  data={v}
                  onSave={refetch}
                  onSendNotification={() => {
                    setFormValues({
                      version: 2,
                    });
                    setSendNotificationModalData({
                      fullPatienName: `${v.patientLastName} ${v.patientFirstName}`,
                      givenAt: v.givenAt as unknown as string,
                      mrn: v.mrn,
                      practiceId: v.practiceId,
                      practicePatientId: v.practicePatientId,
                      entityRefId: v.id,
                      vaccinationId: v.id,
                      vaccineName: v.vaccineName,
                      inventoryVfc: v.inventoryVfc,
                      lot: v.lot,
                    });
                  }}
                  notificationsLoading={notificationsInScreenLoading}
                  notifications={
                    notificationsInScreenData?.communicationNotifications
                  }
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={9}>
                <Message>No synchronizations found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Pagination
          total={total}
          colSpan={11}
          position="right"
          activePage={page}
          totalPages={totalPages}
          onPageChange={(newActivePage) => setPage(newActivePage)}
        />
      </Table>
      <VaxSyncIntegrationNotificationModal
        open={!!sendNotificationModalData}
        fullPatienName={sendNotificationModalData?.fullPatienName}
        givenAt={sendNotificationModalData?.givenAt}
        mrn={sendNotificationModalData?.mrn}
        practiceId={sendNotificationModalData?.practiceId}
        practicePatientId={sendNotificationModalData?.practicePatientId}
        notificationType={NotificationType.vax_sync}
        entityRefId={sendNotificationModalData?.entityRefId}
        vaccinationId={sendNotificationModalData?.vaccinationId}
        vaccineName={sendNotificationModalData?.vaccineName}
        inventoryVfc={sendNotificationModalData?.inventoryVfc}
        lot={sendNotificationModalData?.lot}
        onClose={() => {
          setFormValues({});
          setSendNotificationModalData(undefined);
        }}
        formValues={formValues}
        setFormValues={setFormValues}
        onCreate={async (notification: Notification) => {
          setFormValues({});
          setSendNotificationModalData(undefined);
          await sendNotificationHandler(notification);
          await refetchNotificationInScreen();
        }}
        onEdit={async (
          notification: Notification,
          content: VaxSyncIntegrationNotificationContent,
          newStatusLogItem: any
        ) => {
          await editNotificationHandler(
            notification,
            content,
            newStatusLogItem
          );
          await refetchNotificationInScreen();
        }}
      />
    </>
  );
};

export default VaxSyncTable;
