import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import moment from 'moment-timezone';
import { Table } from 'semantic-ui-react';
import { DateFormats } from '@bluefox/models/Dates';
import { CreditCard, formatCreditCard } from '@bluefox/models/CreditCard';
import { CreditCardForm } from './CreditCardForm';

interface CreditCardRowProps {
  data: CreditCard;
}

const CreditCardsRow = ({ data }: CreditCardRowProps) => {
  const expired = new Date(data.expiration).getTime() < new Date().getTime();

  return (
    <Table.Row negative={expired}>
      <Table.Cell textAlign="center">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {formatCreditCard(data, false)}
        </div>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {data.type}
        </div>
      </Table.Cell>
      <Table.Cell>{data.userName}</Table.Cell>
      <Table.Cell>
        {moment(data.expiration).format(DateFormats.DATE)}
        <br />
        {expired ? 'EXPIRED' : 'VALID'}
      </Table.Cell>
      <Table.Cell>{data.default ? `Yes` : `No`}</Table.Cell>
      <Table.Cell>
        <CreditCardForm creditCard={data} />
      </Table.Cell>
    </Table.Row>
  );
};

export default CreditCardsRow;
