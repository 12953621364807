import { usDollarCurrency } from '@bluefox/lib/formatters';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const tableStyles = StyleSheet.create({
  bodyRow: {
    padding: '0 8',
    flexDirection: 'row',
    borderBottom: '1 solid #EBEFF5',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  bodyRowHighlight: {
    fontSize: 12,
    lineHeight: 16 / 12,
    fontWeight: 900,
    letterSpacing: 1,
  },
  nameCol: {
    width: '70%',
    textAlign: 'left',
  },
  valueCol: {
    width: '30%',
    textAlign: 'right',
  },
  cell: {
    padding: 8,
  },
  cellIndented: {
    paddingLeft: 15,
  },
});

export type SummaryItemProp = {
  key: string;
  name: string;
  value: number;
};

export type SummaryTableItemProps = {
  item: SummaryItemProp;
  highlighted?: boolean;
  indented?: boolean;
};

const SummaryTableItem = ({
  item,
  highlighted,
  indented,
  style,
}: SummaryTableItemProps & { style?: Style | Style[] }) => (
  <View
    style={{
      ...tableStyles.bodyRow,
      ...(highlighted ? tableStyles.bodyRowHighlight : {}),
      ...style,
    }}
    key={item.key}
    wrap={false}
  >
    <View style={tableStyles.nameCol}>
      <Text
        style={{
          ...tableStyles.cell,
          ...(indented ? tableStyles.cellIndented : {}),
        }}
      >
        {item.name}
      </Text>
    </View>
    <View style={tableStyles.valueCol}>
      <Text style={tableStyles.cell}>{usDollarCurrency(item.value)}</Text>
    </View>
  </View>
);

export default SummaryTableItem;
