import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, Card, Form, Icon } from 'semantic-ui-react';
import moment from 'moment-timezone';
import {
  AdministrationCptCodes,
  Dosage,
  Vaccine,
  VisPublication,
} from '@bluefox/models/Vaccine';
import { VaccinationRoutes } from '@bluefox/models/Vaccination';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { isArrayNotEmpty } from '@bluefox/lib/validations/array';
import {
  isAdministrationCPTCodeValid,
  isDosageValid,
} from '@bluefox/lib/validations/vaccine';
import styled from 'styled-components';
import { formatVaccineTypes } from '@bluefox/lib/formatters';
import { VACCINE_TYPES } from '@bluefox/store/vaccines';
import { parseFloatOrZero } from '@bluefox/lib/maths';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';

export const StyledFormDiv = styled.div({
  border: '1px solid rgba(34,36,38,.15);',
  borderRadius: '8px',
  margin: '0rem !important',
  padding: '1rem 1rem 0',
});

const BottomDiv = styled.div({
  margin: '1rem 0',
  textAlign: 'center',
});

const BottomButton = styled(Button)({
  margin: '0 1rem !important',
});

const FieldLikeDiv = styled.div({
  width: '50%',
  paddingLeft: '0.5em',
  paddingRight: '0.5em',
});

const LabelLikeDiv = styled.div({
  display: 'block',
  margin: '0em 0em 0.28571429rem 0em',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '0.92857143em',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:after': {
    margin: '-0.2em 0em 0em 0.2em',
    content: `'*'`,
    color: '#DB2828',
  },
});

const RequiredLabelLikeDiv = styled(LabelLikeDiv)`
:after: {
  margin: -0.2em 0em 0em 0.2em,
  content: '*',
  color: #DB2828,
},
`;

const InputLikeDiv = styled.div({
  padding: '0.67857143em 1em',
});

type Props = {
  edit?: boolean;
  disabled?: boolean;
  vaccine: Vaccine;
  setVaccine: Dispatch<SetStateAction<Vaccine>>;
  visPublication: VisPublication;
  setVisPublication: Dispatch<SetStateAction<VisPublication>>;
  submitButtonLable?: string;
  onSubmit: () => void;
  onCancel: () => void;
  cptCodes: string[];
};

const VaccineForm = ({
  edit = false,
  disabled = false,
  vaccine,
  submitButtonLable = 'Submit',
  setVaccine,
  visPublication,
  setVisPublication,
  onSubmit,
  onCancel,
  cptCodes,
}: Props) => {
  const disableSubmitButton = edit
    ? !(
        isArrayNotEmpty(vaccine?.routes) &&
        isDosageValid(vaccine?.dosage) &&
        isArrayNotEmpty(vaccine?.inventoryCptCodes) &&
        isAdministrationCPTCodeValid(vaccine?.administrationCptCodesWd) &&
        isAdministrationCPTCodeValid(vaccine?.administrationCptCodesWod) &&
        visPublication?.conceptCode &&
        visPublication?.conceptName &&
        visPublication?.publicationDate &&
        isStringNotEmpty(vaccine.cvxGroup)
      )
    : !(
        isStringNotEmpty(vaccine?.name) &&
        isStringNotEmpty(vaccine?.manufacturer) &&
        isStringNotEmpty(vaccine?.cvx) &&
        isStringNotEmpty(vaccine?.mvx) &&
        isStringNotEmpty(vaccine?.aka) &&
        isStringNotEmpty(vaccine?.saleNdc) &&
        isStringNotEmpty(vaccine?.saleNdc10) &&
        isStringNotEmpty(String(vaccine?.useNdc)) &&
        isStringNotEmpty(String(vaccine?.useNdc10)) &&
        isArrayNotEmpty(vaccine?.types) &&
        isArrayNotEmpty(vaccine?.routes) &&
        isDosageValid(vaccine?.dosage) &&
        isArrayNotEmpty(vaccine?.inventoryCptCodes) &&
        isAdministrationCPTCodeValid(vaccine?.administrationCptCodesWd) &&
        isAdministrationCPTCodeValid(vaccine?.administrationCptCodesWod) &&
        visPublication?.conceptCode &&
        visPublication?.conceptName &&
        visPublication?.publicationDate &&
        isStringNotEmpty(vaccine.cvxGroup)
      );

  const vaccineTypes = useMemo(() => (VACCINE_TYPES as string[]).sort(), []);

  const vaccinationRoutes = useMemo(
    () => Object.values(VaccinationRoutes).sort(),
    []
  );
  const [_isDirty, setIsDirty] = useState({} as { [key: string]: boolean });
  const isDirty = useCallback(
    (key: string) => _isDirty[key] || false,
    [_isDirty]
  );
  const setDirty = useCallback((key: string, value: boolean = true) => {
    setIsDirty((preIsDirty) => ({ ...preIsDirty, [key]: value }));
  }, []);

  const renderStringInput = useCallback(
    (
      label: string,
      objKey: keyof Vaccine,
      required: boolean = false,
      isString = true
    ) => {
      const val = vaccine[objKey] as string | undefined;
      const err = required && isDirty(objKey) && !isStringNotEmpty(val);

      return (
        <Form.Input
          label={label}
          required={required}
          type="text"
          value={val || ''}
          onChange={(_, { value }) =>
            setVaccine((preVaccine) => ({
              ...preVaccine,
              [objKey]: value,
            }))
          }
          onBlur={() => {
            if (val) {
              setVaccine((preVaccine) => ({
                ...preVaccine,
                [objKey]: isString
                  ? (preVaccine[objKey] as string).trim()
                  : preVaccine[objKey],
              }));
            }
            setDirty(objKey);
          }}
          error={
            err && {
              content: `Please enter a valid ${label}`,
              pointing: 'below',
            }
          }
        />
      );
    },
    [isDirty, vaccine, setVaccine, setDirty]
  );

  const renderNumberInput = useCallback(
    (label: string, objKey: keyof Vaccine) => (
      <Form.Input
        label={label}
        type="number"
        step=".01"
        value={vaccine[objKey] || ''}
        onChange={(_, { value }) => {
          setVaccine((preVaccine) => ({
            ...preVaccine,
            [objKey]: parseFloatOrZero(value),
          }));
        }}
      />
    ),
    [vaccine, setVaccine]
  );

  const renderBooleanInput = useCallback(
    (label: string, objKey: keyof Vaccine, required: boolean = false) => (
      <Form.Checkbox
        label={label}
        required={required}
        checked={(vaccine[objKey] as boolean) || false}
        onChange={() =>
          setVaccine((preVaccine) => ({
            ...preVaccine,
            [objKey]: !preVaccine[objKey],
          }))
        }
      />
    ),
    [vaccine, setVaccine]
  );

  const renderMultiSelectStringArray = useCallback(
    (
      label: string,
      objKey: keyof Vaccine,
      source: string[],
      required: boolean = false
    ) => {
      const val = vaccine[objKey] as string[] | undefined;
      const err = required && isDirty(objKey) && !isArrayNotEmpty(val);

      return (
        <Form.Dropdown
          label={label}
          fluid
          multiple
          search
          selection
          clearable
          required={required}
          defaultValue={val}
          options={source.map((val) => ({
            key: val,
            text: val,
            value: val,
          }))}
          onChange={(_, { value }) =>
            setVaccine((preVaccine) => ({
              ...preVaccine,
              [objKey]: value as string[],
            }))
          }
          onBlur={() => setDirty(objKey)}
          error={
            err && {
              content: `Please enter a valid ${label}`,
              pointing: 'below',
            }
          }
        />
      );
    },
    [isDirty, vaccine, setVaccine, setDirty]
  );

  const renderDosageInput = useCallback(
    (label: string, required: boolean = false) => {
      const err =
        required && isDirty('dosage') && !isDosageValid(vaccine.dosage);

      return (
        <Form.Field>
          <label>{label}</label>
          <Form.Group widths={2}>
            <Form.Input
              label="Dose"
              type="number"
              step=".01"
              value={
                isArrayNotEmpty(vaccine.dosage) ? vaccine.dosage[0].dose : 0
              }
              onChange={(_, { value }) => {
                setVaccine((preVaccine) => ({
                  ...preVaccine,
                  dosage: [
                    {
                      dose: parseFloatOrZero(value),
                      unit: isArrayNotEmpty(preVaccine.dosage)
                        ? preVaccine.dosage[0].unit
                        : '',
                    } as unknown as Dosage,
                  ],
                }));
              }}
              onBlur={() => setDirty('dosage')}
            />
            <Form.Input
              label="Unit"
              type="text"
              value={
                isArrayNotEmpty(vaccine.dosage) ? vaccine.dosage[0].unit : ''
              }
              onChange={(_, { value }) =>
                setVaccine((preVaccine) => ({
                  ...preVaccine,
                  dosage: [
                    {
                      dose: isArrayNotEmpty(preVaccine.dosage)
                        ? preVaccine.dosage[0].dose
                        : 0,
                      unit: value,
                    } as unknown as Dosage,
                  ],
                }))
              }
              onBlur={() => {
                if (vaccine.dosage?.[0].unit) {
                  setVaccine((preVaccine) => ({
                    ...preVaccine,
                    dosage: [
                      {
                        dose: isArrayNotEmpty(preVaccine.dosage)
                          ? preVaccine.dosage[0].dose
                          : 0,
                        unit: isArrayNotEmpty(preVaccine.dosage)
                          ? preVaccine.dosage[0].unit.trim()
                          : '',
                      } as unknown as Dosage,
                    ],
                  }));
                }
                setDirty('dosage');
              }}
              error={
                err && {
                  content: `Please enter a valid unit`,
                  pointing: 'below',
                }
              }
            />
          </Form.Group>
        </Form.Field>
      );
    },
    [isDirty, setDirty, setVaccine, vaccine]
  );

  const renderAdminCPTCodesInput = useCallback(
    (
      label: string,
      objKey: keyof Vaccine,
      cptCodes: string[],
      required: boolean = false
    ) => {
      const val = vaccine[objKey] as AdministrationCptCodes | undefined;
      const selectedCPTCodes = val?.default || [];

      const err =
        required && isDirty(objKey) && !isAdministrationCPTCodeValid(val);

      return (
        <Form.Field>
          <Form.Dropdown
            label={label}
            fluid
            multiple
            search
            selection
            clearable
            required={required}
            defaultValue={selectedCPTCodes}
            options={cptCodes.map((cptCode) => ({
              key: cptCode,
              text: cptCode,
              value: cptCode,
            }))}
            onChange={(_, { value }) =>
              setVaccine((preVaccine) => ({
                ...preVaccine,
                [objKey]: {
                  default: value as string[],
                },
              }))
            }
            onBlur={() => setDirty(objKey)}
            error={
              err && {
                content: `Please enter a valid ${label}`,
                pointing: 'below',
              }
            }
          />
        </Form.Field>
      );
    },
    [vaccine, isDirty, setVaccine, setDirty]
  );

  const renderStringInputLike = useCallback(
    (label: string, value: string | undefined, required: boolean = false) => {
      return (
        <FieldLikeDiv>
          {required ? (
            <RequiredLabelLikeDiv>{label}</RequiredLabelLikeDiv>
          ) : (
            <LabelLikeDiv>{label}</LabelLikeDiv>
          )}
          <InputLikeDiv>{value}</InputLikeDiv>
        </FieldLikeDiv>
      );
    },
    []
  );

  const renderBooleanInputLike = useCallback(
    (label: string, value: boolean | undefined, required: boolean = false) => {
      return (
        <FieldLikeDiv>
          <span style={{ verticalAlign: 'middle' }}>{label}</span>
          <Icon
            name={`${value === true ? 'check ' : ''}square outline`}
            style={{ fontSize: '1.33em', margin: '0', verticalAlign: 'middle' }}
          />{' '}
          {required && (
            <span style={{ margin: '-0.2em 0em 0em 0.2em', color: '#DB2828' }}>
              *
            </span>
          )}
        </FieldLikeDiv>
      );
    },
    []
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <StyledFormDiv>
        <Form.Group widths={2}>
          {edit
            ? renderStringInputLike('Name', vaccine?.name, true)
            : renderStringInput('Name', 'name', true)}
          {edit
            ? renderStringInputLike('Manufacturer', vaccine?.manufacturer, true)
            : renderStringInput('Manufacturer', 'manufacturer', true)}
        </Form.Group>
        <Form.Group widths={2}>
          {edit
            ? renderBooleanInputLike('Fallback', vaccine?.fallback)
            : renderBooleanInput('Fallback', 'fallback')}
          {edit
            ? renderStringInputLike('MVX', vaccine?.mvx, true)
            : renderStringInput('MVX', 'mvx', true)}
        </Form.Group>
        <Form.Group widths={2}>
          {edit
            ? renderStringInputLike('CVX', vaccine?.cvx, true)
            : renderStringInput('CVX', 'cvx', true)}
          {edit
            ? renderStringInput('CVX Group', 'cvxGroup', true)
            : renderStringInput('CVX Group', 'cvxGroup', true)}
        </Form.Group>
        <Form.Group widths={2}>
          {edit
            ? renderStringInputLike('AKA', vaccine?.aka, true)
            : renderStringInput('AKA', 'aka', true)}
          {renderNumberInput('Self Pay Price', 'selfPayPrice')}
        </Form.Group>
        <Form.Group widths={2}>
          {edit
            ? renderStringInputLike('Sale NDC11', vaccine?.saleNdc, true)
            : renderStringInput('Sale NDC11', 'saleNdc', true)}
          {edit
            ? renderStringInputLike('Sale NDC10', vaccine?.saleNdc10, true)
            : renderStringInput('Sale NDC10', 'saleNdc10', true)}
        </Form.Group>
        <Form.Group widths={2}>
          {edit
            ? renderStringInput('Use NDC11', 'useNdc', true, false)
            : renderStringInput('Use NDC11', 'useNdc', true)}
          {edit
            ? renderStringInput('Use NDC10', 'useNdc10', true, false)
            : renderStringInput('Use NDC10', 'useNdc10', true)}
        </Form.Group>
        <Form.Group widths={2}>
          {edit
            ? renderStringInputLike(
                'Types',
                formatVaccineTypes(vaccine?.types),
                true
              )
            : renderMultiSelectStringArray(
                'Types',
                'types',
                vaccineTypes,
                true
              )}
          {renderMultiSelectStringArray(
            'Routes',
            'routes',
            vaccinationRoutes,
            true
          )}
        </Form.Group>
        <Form.Group widths={2}>
          {renderDosageInput('Dosage (only one dose is supported)', true)}
          {renderMultiSelectStringArray(
            'Inventory CPT Codes',
            'inventoryCptCodes',
            cptCodes,
            true
          )}
        </Form.Group>
        <Form.Group widths={2}>
          {renderAdminCPTCodesInput(
            'Administration CPT Codes WD',
            'administrationCptCodesWd',
            cptCodes,
            true
          )}
          {renderAdminCPTCodesInput(
            'Administration CPT Codes WOD',
            'administrationCptCodesWod',
            cptCodes,
            true
          )}
        </Form.Group>
        {edit && (
          <Form.Group widths={1}>
            {renderBooleanInput('Active', 'active')}
          </Form.Group>
        )}

        <Card fluid>
          <Card.Content>
            <Card.Header>Vis Publication</Card.Header>
            <Card.Description>
              <Form.Group>
                {!visPublication?.id && (
                  <>
                    <Form.Input
                      label="Concept Name"
                      required={true}
                      type="text"
                      value={
                        visPublication?.conceptName
                          ? visPublication.conceptName
                          : ''
                      }
                      onChange={(_, { value }) =>
                        setVisPublication(
                          (previVisPublication) =>
                            ({
                              ...previVisPublication,
                              conceptName: value,
                            }) as VisPublication
                        )
                      }
                    />
                    <Form.Input
                      label="Concept Code"
                      required={true}
                      type="text"
                      value={
                        visPublication?.conceptCode
                          ? visPublication.conceptCode
                          : ''
                      }
                      onChange={(_, { value }) =>
                        !visPublication?.id &&
                        setVisPublication(
                          (previVisPublication) =>
                            ({
                              ...previVisPublication,
                              conceptCode: value,
                            }) as VisPublication
                        )
                      }
                    />
                  </>
                )}
                <div className="required field">
                  <label style={{ marginBottom: '0.1rem' }}>
                    Publication Date
                  </label>
                  <DateTimePicker
                    placeholderText="Select Date"
                    selected={
                      visPublication?.publicationDate
                        ? moment(visPublication?.publicationDate).toDate()
                        : undefined
                    }
                    onChange={(d) =>
                      setVisPublication({
                        ...visPublication,
                        publicationDate: d ? (d as Date) : undefined,
                      } as VisPublication)
                    }
                    onSelect={(value) =>
                      setVisPublication({
                        ...visPublication,
                        publicationDate: value ? (value as Date) : undefined,
                      } as VisPublication)
                    }
                    onClear={() =>
                      setVisPublication({
                        ...visPublication,
                        publicationDate: undefined,
                      } as VisPublication)
                    }
                    maxDate={new Date()}
                    dateFormat={DateFormats.DATE}
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                    dropdownMode="select"
                    isClearable
                  />
                </div>
              </Form.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      </StyledFormDiv>
      <BottomDiv>
        <BottomButton
          primary
          type="submit"
          disabled={disabled || disableSubmitButton}
        >
          {submitButtonLable}
        </BottomButton>
        <BottomButton
          secondary
          type="button"
          onClick={onCancel}
          disabled={disabled}
        >
          Cancel
        </BottomButton>
      </BottomDiv>
    </Form>
  );
};

export default VaccineForm;
