export enum UserTitles {
  MD = 'MD',
  DO = 'DO',
  PA = 'PA',
  NP = 'NP',
  MA = 'MA',
  Nurse = 'Nurse',
  Other = 'Other',
}
export interface User {
  account: UserAccount;
  id: string;
  title: UserTitles;
  pcp: boolean;
  role: string;
  suspended: boolean;
  profile?: any;
}

export interface UserAccount {
  id: string;
  firstName: string;
  lastName: string;
  npi: string;
  email: string;
  password?: string;
  taxonomyCode?: string;
}

export interface UsersData {
  practice_accounts: User[];
}

export interface UserForLocalStorage {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profile?: any;
  title: UserTitles;
  role: string;
  position: string;
}
