import { gql } from '@apollo/client';

export const VaccinePricingListQuery = gql`
  query VaccinePricingListQuery(
    $limit: Int
    $offset: Int
    $criteria: vaccines_bool_exp
    $vaccinePricingCriteria: [vaccine_pricing_bool_exp!]
  ) {
    vaccines(
      limit: $limit
      offset: $offset
      order_by: { name: asc }
      where: $criteria
    ) {
      id
      name
      manufacturer
      saleNdc
      inventoryCptCodes
      federalExciseTaxes
      packageType
      packageDoses
      vaccinePricingDefault: vaccinePricings(
        distinct_on: [vaccineId, contractPlanId, createdAt]
        order_by: {
          vaccineId: asc
          contractPlanId: asc
          date: desc
          createdAt: desc
        }
        where: { contractPlanId: { _is_null: true }, status: { _eq: "active" } }
        limit: 1
      ) {
        id
        price
        priceCents
        pricePerDose
        priceCentsPerDose
        date
      }
      vaccinePricingPlans: vaccinePricings(
        distinct_on: [contractPlanId, vaccineId, date]
        order_by: { contractPlanId: asc, vaccineId: asc, date: desc }
        where: { _or: $vaccinePricingCriteria, status: { _eq: "active" } }
      ) {
        id
        status
        price
        priceCents
        pricePerDose
        priceCentsPerDose
        date
        contractPlan {
          id
          name
          type
          startDate
          endDate
          discounts
        }
      }
    }
    aggregating: vaccines_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const InsertVaccinePricingMutation = gql`
  mutation InsertVaccinePricingMutation(
    $object: [vaccine_pricing_insert_input!]!
  ) {
    insert_vaccine_pricing(objects: $object) {
      affected_rows
    }
  }
`;

export const CatalogueVaccinePricingFromInventories = gql`
  query CatalogueVaccinePricingFromInventories {
    vaccines(
      where: { inventories: { status: { _eq: received }, vfc: { _eq: false } } }
    ) {
      id
      name
      manufacturer
      saleNdc
      packageType
      packageDoses
      inventoryCptCodes
      vaccinePricings(
        where: { contractPlanId: { _is_null: true } }
        distinct_on: [contractPlanId, vaccineId, createdAt]
        order_by: { contractPlanId: asc, vaccineId: asc, createdAt: desc }
        limit: 1
      ) {
        id
        priceCents
        status
      }
    }
  }
`;
