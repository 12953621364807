import styled from 'styled-components';

export const StyledRoute = styled.div`
  margin: 0;
  padding: 0.3rem 0.2rem 0.3rem 0.2rem;
  border: 1px solid #5dbcd1;
  background-color: #ffffff;
  color: grey;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const StyledEmptyRoute = styled.div`
  margin: 0;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  border: 1px solid #5dbcd1;
  background-color: #ffffff;
  color: grey;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const StyledSite = styled.div`
  margin: 0;
  padding: 0.2rem 0.3rem 0.3rem 0.2rem;
`;

export const StyledEmptySite = styled.div`
  margin: 0;
  padding: 0.2rem 0.5rem 0.3rem 0.5rem;
`;

export const StyledText = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

export const StyledDivCol = styled.div`
  display: flex;
  flex-direction: column;
`;
