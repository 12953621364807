export enum AlertType {
  INVENTORY = 'inventory',
  THRESHOLD = 'threshold',
  INVOICE = 'invoice',
  VACCINE = 'vaccine',
}

export enum AlertSeverity {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum AlertStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
}

interface Practice {
  id: string;
  name: string;
}

export interface Alert {
  entityRef?: string;
  id?: string;
  practice?: Practice;
  practiceId?: string;
  type: string;
  message: string;
  severity: string;
  status?: string;
  note?: string;
}
