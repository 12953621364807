import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const LoadingBox = () => {
  return (
    <Segment placeholder basic>
      <Dimmer active inverted>
        <Loader inverted size="massive">
          Loading
        </Loader>
      </Dimmer>
    </Segment>
  );
};

export default LoadingBox;
