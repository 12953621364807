import { gql } from '@apollo/client';

export const PracticeSettingsQuery = gql`
  query PracticeSettingsQuery($id: uuid!) {
    practice: practices_by_pk(id: $id) {
      settings
    }
  }
`;

export const PracticeSettingsByHandlerQuery = gql`
  query PracticeSettingsByHandlerQuery($handler: String) {
    practices: practices(where: { handler: { _eq: $handler } }) {
      settings
    }
  }
`;

export const PracticeTimezoneQuery = gql`
  query PracticeTimezoneQuery($practiceId: uuid!) {
    practice: practices_by_pk(id: $practiceId) {
      timezone
    }
  }
`;

export const PracticeProfileQuery = gql`
  query PracticeProfileQuery($id: uuid!) {
    practice: practices_by_pk(id: $id) {
      profile
    }
  }
`;
