import React from 'react';
import { toast } from 'react-semantic-toasts';
import { Table, Message, Popup, Button } from 'semantic-ui-react';
import { BillingClaimReportingView } from '@bluefox/models/Billing';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { DateFormats } from '@bluefox/models/Dates';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import { pixelToRem } from '@bluefox/constants';

interface ClaimTableProps {
  claims: BillingClaimReportingView[];
  onClaimUpdate: () => void;
  refetch: () => void;
  patientClaimsOpened?: boolean;
}

const Reporting2ClaimsTable = (props: ClaimTableProps) => {
  const { claims } = props;

  const handleCopyText = (str: string | undefined) => {
    navigator.clipboard.writeText(str || '');

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  const getTableCells = (claim: BillingClaimReportingView) => {
    return (
      <>
        <Table.Cell textAlign="center">
          <b>{claim.name}</b>
        </Table.Cell>

        <Table.Cell textAlign="center">
          {`${claim.firstName} ${claim.lastName}`}
          <br />
          {`(${moment(claim.birthdate).format(DateFormats.DATE)})`}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {claim.givenAt ? formatDatetimeToMMDDYYY(claim.givenAt) : '-'}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {claim.totalClaimAmount !== null
            ? `$${claim.totalClaimAmount.toFixed(2)}`
            : '-'}
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Popup
            content="Edit Claim"
            size="tiny"
            trigger={
              <Button
                primary
                className="min-component-vertical-margin"
                icon="pencil"
                size="tiny"
                as={Link}
                to={`/billing/reporting/${claim.claimId}`}
              />
            }
          />
          <Popup
            position="top right"
            content={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {claim.claimId}
                <Button
                  icon="copy"
                  size="tiny"
                  onClick={() => {
                    handleCopyText(claim.claimId);
                  }}
                />
              </div>
            }
            on="click"
            trigger={
              <Button
                className="min-component-vertical-margin"
                style={{
                  padding: `${pixelToRem(9)}rem ${pixelToRem(11)}rem`,
                }}
                size="tiny"
                content={<b>Id</b>}
                color="teal"
              />
            }
          />
        </Table.Cell>
      </>
    );
  };

  return (
    <Table.Body>
      {!!claims.length ? (
        claims.map((claim, index) => {
          return (
            <Table.Row key={`row-data-${index}`}>
              {getTableCells(claim)}
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan={5}>
            <Message>No Claims Found.</Message>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
};

export default Reporting2ClaimsTable;
