import { useMemo } from 'react';
import { Page, StyleSheet } from '@react-pdf/renderer';
import Header from '../InvoicePage/Header';
import Body from './Body';
import Footer from '../InvoicePage/Footer';
import { InvoicePageProps } from '../InvoicePage';
import { SummaryItemProp, SummaryTableProps } from './SummaryTable';

const pageStyles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'black',
    padding: '40 0',
  },
});

const SummaryPage = ({ header, body, footer }: InvoicePageProps) => {
  const summary: SummaryTableProps = useMemo(() => {
    const practices: SummaryItemProp[] | undefined = body?.claimTables.map(
      (ct) => ({
        key: ct.key,
        name: ct.practice,
        value: ct.totalAmount,
      })
    );
    const discount: SummaryItemProp | undefined = body?.discount && {
      key: 'discount',
      name: `REBATE (${body.discount.discountPercentage} %)`,
      value: -body.discount.discountAmount,
    };
    const totalExtraItems: SummaryItemProp | undefined = body?.extraTable && {
      key: 'extraItems',
      name: 'Total Extra Items',
      value: body.extraTable.totalAmount,
    };
    const fee: SummaryItemProp | undefined = body?.fee && {
      key: 'fee',
      name: 'Fee',
      value: body.fee.feeAmount,
    };

    return {
      practices,
      ...(practices ? { practices } : {}),
      ...(discount ? { discount } : {}),
      ...(totalExtraItems ? { totalExtraItems } : {}),
      ...(fee ? { fee } : {}),
    };
  }, [body?.claimTables, body?.discount, body?.extraTable, body?.fee]);

  return (
    <Page size="A4" style={pageStyles.page} wrap>
      <Header {...header} />
      <Body summary={summary} />
      {footer && <Footer {...footer} />}
    </Page>
  );
};

export default SummaryPage;
