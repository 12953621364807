import React from 'react';
import MappingTable from './MappingTable';
import { MappingTypes } from '@bluefox/models/integrations/Develo';

const VfcCriteriaMapping: React.FC = () => {
  return (
    <MappingTable
      enablePagination={true}
      mappingTitle="VFC Criteria"
      mappingType={MappingTypes.vfcCriteria}
      hasPractice={true}
    />
  );
};

export default VfcCriteriaMapping;
