import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  AlertsQuery,
  UpdateAlertStatusMutation,
  SaveNotesMutation,
  DeleteAlertMutation,
} from '@bluefox/graphql/alerts';
import {
  Alert,
  AlertStatus,
  AlertSeverity,
  AlertType,
} from '@bluefox/models/Alert';
import {
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Label,
  Dropdown,
  Modal,
  Button,
  Form,
  Header,
  Confirm,
  Popup,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import Pagination from '@bluefox/ui/Pagination';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import RowsPerPage from '@ui/Filters/RowsPerPage';

const alertSeverityOptions = [
  {
    key: 'high',
    text: 'High',
    value: AlertSeverity.HIGH,
  },
  {
    key: 'medium',
    text: 'Medium',
    value: AlertSeverity.MEDIUM,
  },
  {
    key: 'low',
    text: 'Low',
    value: AlertSeverity.LOW,
  },
];

const alertTypeOptions = [
  {
    key: 'inventory',
    text: 'Inventory',
    value: AlertType.INVENTORY,
  },
  {
    key: 'threshold',
    text: 'Threshold',
    value: AlertType.THRESHOLD,
  },
  {
    key: 'invoice',
    text: 'Invoice',
    value: AlertType.INVOICE,
  },
  {
    key: 'vaccine',
    text: 'Vaccine',
    value: AlertType.VACCINE,
  },
];

const alertStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: AlertStatus.PENDING,
  },
  {
    key: 'complete',
    text: 'Complete',
    value: AlertStatus.COMPLETE,
  },
];

interface Alerts {
  alerts: Alert[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const HomeAlerts = () => {
  const [criteria, setCriteria] = useState<object>({});
  const [searchPractice, setSearchPractice] = useState<string>('all');
  const [searchType, setSearchType] = useState<string>('all');
  const [searchSeverity, setSearchSeverity] = useState<string>('all');
  const [searchStatus, setSearchStatus] = useState<string>(AlertStatus.PENDING);
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);

  const [page, setPage] = useState(1);

  const { data, loading, error, refetch } = useQuery<Alerts>(AlertsQuery, {
    variables: {
      criteria,
      limit: rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
    },
    skip: rowsPerPage === 0,
    fetchPolicy: 'network-only',
  });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / rowsPerPage);

  useEffect(() => {
    let _criteria = {};

    if (searchPractice !== 'all') {
      _criteria = {
        ..._criteria,
        practice: { id: { _eq: searchPractice } },
      };
    }

    if (searchType !== 'all') {
      _criteria = { ..._criteria, type: { _eq: searchType } };
    }

    if (searchSeverity !== 'all') {
      _criteria = {
        ..._criteria,
        severity: { _eq: searchSeverity },
      };
    }

    if (searchStatus !== 'all') {
      _criteria = {
        ..._criteria,
        status: { _eq: searchStatus },
      };
    }

    setCriteria(_criteria);
    setPage(1);
  }, [searchPractice, searchType, searchSeverity, searchStatus, rowsPerPage]);

  useEffect(() => {
    const rows = Number(localStorage.getItem('AlertsRowConfig'));
    setRowsPerPage(rows >= 0 ? rows : 15);
  }, []);

  return (
    <>
      <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item>
            <RowsPerPage
              rows={rowsPerPage}
              setRows={setRowsPerPage}
              localStorageItem="AlertsRowConfig"
            />
          </Menu.Item>
          {rowsPerPage > 0 && (
            <>
              <Menu.Item>
                <PracticeFilter
                  practiceSearch={searchPractice}
                  setPracticeSearch={setSearchPractice}
                  suspended="notSuspended"
                />
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <Form.Dropdown
                    label="Filter by Type"
                    placeholder="Filter by type"
                    value={searchType}
                    fluid
                    selection
                    onChange={(e, { value }) => {
                      setSearchType(value as string);
                    }}
                    options={[
                      { text: 'All types', value: 'all' },
                      ...alertTypeOptions,
                    ]}
                  />
                </Form>
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <Form.Dropdown
                    label="Filter by Severity"
                    placeholder="Filter by severity"
                    value={searchSeverity}
                    fluid
                    selection
                    onChange={(e, { value }) => {
                      setSearchSeverity(value as string);
                    }}
                    options={[
                      { text: 'All severities', value: 'all' },
                      ...alertSeverityOptions,
                    ]}
                  />
                </Form>
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <Form.Dropdown
                    label="Filter by Status"
                    placeholder="Filter by status"
                    value={searchStatus}
                    fluid
                    selection
                    onChange={(e, { value }) => {
                      setSearchStatus(value as string);
                    }}
                    options={[
                      { text: 'All status', value: 'all' },
                      ...alertStatusOptions,
                    ]}
                  />
                </Form>
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>

      {rowsPerPage > 0 ? (
        error ? (
          <Message error>{error.message}</Message>
        ) : (
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Practice</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Severity</Table.HeaderCell>
                <Table.HeaderCell>Message</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <Segment basic>
                      <Placeholder fluid>
                        <Placeholder.Header>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                      </Placeholder>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              ) : !!data?.alerts.length ? (
                data?.alerts.map((a, idx) => {
                  return <AlertRow alertData={a} onSave={refetch} key={idx} />;
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <Message>No alerts found.</Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Pagination
              total={total}
              colSpan={5}
              position="right"
              activePage={page}
              totalPages={totalPages}
              onPageChange={(newActivePage) => setPage(newActivePage)}
            />
          </Table>
        )
      ) : null}
    </>
  );
};

interface AlertRowProps {
  alertData: Alert;
  onSave: () => void;
}

const AlertRow = ({ alertData, onSave }: AlertRowProps) => {
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState(alertData.note || '');

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [saveStatus] = useMutation(UpdateAlertStatusMutation);
  const [saveNotes] = useMutation(SaveNotesMutation);
  const [deleteAlert] = useMutation(DeleteAlertMutation);

  const handleDeleteAlert = () => {
    deleteAlert({
      variables: {
        id: alertData.id,
      },
    })
      .then(() => {
        toast({
          title: 'Alert deleted successfully',
          type: 'success',
          time: 1000,
        });
        setOpenConfirmDelete(false);
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleNotesSubmit = () => {
    saveNotes({
      variables: {
        id: alertData.id,
        note: notes,
      },
    })
      .then(() => {
        toast({
          title: 'Notes saved successfully',
          type: 'success',
          time: 1000,
        });
        setOpenNotes(false);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleSaveStatus = (alertStatus: string) => {
    saveStatus({
      variables: {
        id: alertData.id,
        status: alertStatus,
        completedAt: alertStatus === 'complete' ? new Date() : null,
      },
    })
      .then((r) => {
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row key={alertData.id}>
      <Table.Cell>{alertData.practice?.name}</Table.Cell>
      <Table.Cell>
        <Label
          content={humanizeText(alertData.type, {
            capitalize: 'first',
          })}
          size="small"
        />
      </Table.Cell>
      <Table.Cell>
        {' '}
        <Label
          size="small"
          content={humanizeText(alertData.severity, {
            capitalize: 'first',
          })}
          color={
            alertData.severity === 'high'
              ? 'red'
              : alertData.severity === 'medium'
              ? 'orange'
              : 'teal'
          }
        />
      </Table.Cell>
      <Table.Cell
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {alertData.message}
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Modal
            closeIcon
            onClose={() => setOpenNotes(false)}
            onOpen={() => setOpenNotes(true)}
            open={openNotes}
            trigger={<Button primary content="Notes" size="mini" />}
          >
            <Modal.Content>
              <Header>Notes</Header>
              <Form id="notes-form" onSubmit={handleNotesSubmit}>
                <Form.TextArea
                  placeholder="There are no notes for this alert. You can add one and save it."
                  value={notes}
                  onChange={(_, { value }) => {
                    setNotes(value as string);
                  }}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                type="button"
                content="Close"
                onClick={() => setOpenNotes(false)}
              />
              <Button
                primary
                type="submit"
                icon="save"
                content="Save note"
                form="notes-form"
              />
            </Modal.Actions>
          </Modal>
          {alertData.note && (
            <Popup
              content={alertData.note}
              trigger={<Icon name="info circle" size="large" color="teal" />}
            />
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {alertData.type === AlertType.THRESHOLD ? (
          <div>
            <Button
              icon="trash"
              size="mini"
              onClick={() => setOpenConfirmDelete(true)}
            />
            <Confirm
              open={openConfirmDelete}
              onCancel={() => setOpenConfirmDelete(false)}
              onConfirm={handleDeleteAlert}
              content="Are you sure you want to delete this alert?"
              confirmButton="Delete"
              size="tiny"
              className="btn-delete"
            />
          </div>
        ) : (
          <Dropdown
            fluid
            selection
            options={alertStatusOptions}
            value={alertData.status}
            onChange={(e, data) => {
              handleSaveStatus(data.value?.toString() || '');
            }}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default HomeAlerts;
