import { gql } from '@apollo/client';

export const UsersQuery = gql`
  query UsersQuery($criteria: [practice_accounts_bool_exp!]) {
    practice_accounts(
      where: { _or: $criteria }
      order_by: { account: { firstName: asc } }
    ) {
      account {
        id
        firstName
        lastName
        npi
        email
        taxonomyCode
      }
      id
      pcp
      role
      title
      suspended
      profile
    }
  }
`;

export const InsertUserMutation = gql`
  mutation InsertUserMutation(
    $practiceId: uuid!
    $title: titles_enum
    $isPcp: Boolean
    $isSuspended: Boolean
    $role: roles_enum! = user
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $npi: String
    $taxonomyCode: String
    $profile: jsonb
  ) {
    insertedPraccticeAccount: insert_practice_accounts_one(
      object: {
        practiceId: $practiceId
        title: $title
        pcp: $isPcp
        suspended: $isSuspended
        role: $role
        profile: $profile
        account: {
          data: {
            email: $email
            password: $password
            firstName: $firstName
            lastName: $lastName
            npi: $npi
            taxonomyCode: $taxonomyCode
          }
        }
      }
    ) {
      id
    }
  }
`;

export const UpdateUserMutation = gql`
  mutation UpdateUserMutation(
    $practiceAccountId: uuid!
    $accountId: uuid!
    $title: titles_enum
    $isPcp: Boolean
    $isSuspended: Boolean
    $role: roles_enum!
    $email: String
    $firstName: String
    $lastName: String
    $npi: String
    $taxId: String
    $taxonomyCode: String
    $profile: jsonb
  ) {
    update_practice_accounts_by_pk(
      pk_columns: { id: $practiceAccountId }
      _set: {
        title: $title
        pcp: $isPcp
        suspended: $isSuspended
        role: $role
        profile: $profile
      }
    ) {
      id
    }

    update_accounts_by_pk(
      pk_columns: { id: $accountId }
      _set: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        npi: $npi
        taxonomyCode: $taxonomyCode
      }
    ) {
      id
    }
  }
`;

export const UpdateUserMutation2 = gql`
  mutation UpdateUserMutation(
    $practiceAccountId: uuid!
    $accountId: uuid!
    $title: titles_enum
    $isPcp: Boolean
    $isSuspended: Boolean
    $role: roles_enum!
    $email: String
    $firstName: String
    $lastName: String
    $npi: String
    $taxId: String
    $taxonomyCode: String
    $profile: jsonb
    $mappingId: uuid!
  ) {
    update_practice_accounts_by_pk(
      pk_columns: { id: $practiceAccountId }
      _set: {
        title: $title
        pcp: $isPcp
        suspended: $isSuspended
        role: $role
        profile: $profile
      }
    ) {
      id
    }

    update_accounts_by_pk(
      pk_columns: { id: $accountId }
      _set: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        npi: $npi
        taxonomyCode: $taxonomyCode
      }
    ) {
      id
    }
    update_billing_claim_mappings_by_pk(
      pk_columns: { id: $mappingId }
      _set: { npi: $npi, taxId: $taxId }
    ) {
      id
    }
  }
`;

export const UpdateUserPasswordMutation = gql`
  mutation UpdateUserPasswordMutation($accountId: uuid!, $password: String!) {
    update_accounts_by_pk(
      pk_columns: { id: $accountId }
      _set: { password: $password, passwordEncrypted: false }
    ) {
      id
    }
  }
`;

export const updatePracticeAccountPasswordMutation = gql`
  mutation updatePracticeAccountPasswordMutation($password: String!) {
    update_accounts(where: {}, _set: { password: $password }) {
      affected_rows
    }
  }
`;

export const BillingClaimMappingByProvider = gql`
  query BillingClaimMappingByProvider($practiceAccountId: uuid!) {
    mapping: billing_claim_mappings(
      where: {
        providerId: { _eq: $practiceAccountId }
        insuranceCompanyId: { _is_null: true }
        type: { _eq: npi_taxid }
      }
    ) {
      id
    }
  }
`;

export const GetExistingAccounts = gql`
  query GetExistingAccounts(
    $_eq: uuid
    $opsAdminAccount: String
    $_nin: [String!]
  ) {
    practices(where: { id: { _eq: $_eq } }) {
      organization {
        practices(where: { id: { _neq: $_eq } }) {
          id
          handler
          accounts(
            where: {
              _and: {
                practiceId: { _neq: $_eq }
                account: { email: { _nin: $_nin } }
              }
            }
          ) {
            id
            pcp
            role
            position
            title
            suspended
            account {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
    }
    accounts(where: { email: { _eq: $opsAdminAccount } }) {
      id
    }
  }
`;

export const InsertExistingAccount = gql`
  mutation InsertExistingAccount($obj: practice_accounts_insert_input!) {
    insert_practice_accounts_one(object: $obj) {
      id
    }
  }
`;
