import { CSSProperties, useEffect } from 'react';
import {
  buildMonthYearDropDownOptionsFromNow,
  monthYearDropDownOptionsToMonthYear,
} from '@bluefox/lib/dates';
import { useMemo } from 'react';
import { Form } from 'semantic-ui-react';

type Props = {
  label?: string;
  onChange: (value: { month: number; year: number }) => void;
  style?: CSSProperties;
};

const MonthYearDropdown = ({ label, onChange, style }: Props) => {
  const dropdownOptions = useMemo(() => {
    return buildMonthYearDropDownOptionsFromNow();
  }, []);
  useEffect(() => {
    if (dropdownOptions) {
      const [year, month] = dropdownOptions[0].value
        .split('/')
        .map((item) => parseInt(item));
      onChange({ year, month });
    }
  }, [dropdownOptions]);

  return (
    <Form onSubmit={(e) => e.preventDefault()} style={style}>
      <Form.Field>
        <Form.Dropdown
          label={label}
          selection
          defaultValue={dropdownOptions[0].value}
          options={dropdownOptions}
          style={{ width: 'inherit' }}
          onChange={(_, { value }) => {
            const [year, month] = monthYearDropDownOptionsToMonthYear(
              value as string
            );
            onChange({ month, year });
          }}
        />
      </Form.Field>
    </Form>
  );
};

export default MonthYearDropdown;
