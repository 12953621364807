import { notificationStatusEnumToText } from '@bluefox/constants/notifications';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import { Notification } from '@bluefox/models/Notification';
import BillingNotificationMessage from '@bluefox/ui/Notifications/NotificationMessage/BillingNotificationMessage';

type Props = {
  notification: Notification;
};

const BillingNotificationModalMessage = ({ notification }: Props) => {
  return (
    <>
      <p>
        Notification sent on:{' '}
        <b>
          {formatDateToYYYYhMMhDD(new Date(notification?.createdAt as string))}
        </b>
        . Message:
      </p>
      <BillingNotificationMessage notification={notification as Notification} />
      <p>
        Status: <b>{notificationStatusEnumToText[notification.status]}</b>
      </p>
    </>
  );
};

export default BillingNotificationModalMessage;
