import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Header,
  Label,
} from 'semantic-ui-react';

import { Vaccine } from '@bluefox/models/Vaccine';
import {
  SelectedVaccinesQuery,
  SearchVaccinesQuery,
} from '@bluefox/graphql/vaccines';

interface SelectedVaccinesData {
  vaccines: Vaccine[];
}

interface VaccinesData {
  vaccines: Vaccine[];
}

interface VaccinePickerProps {
  value?: string | string[];
  valueData?: SelectedVaccinesData;
  valueLoading?: boolean;
  fallback?: boolean;
  onChange?: (v: {
    values?: string[];
    vaccines?: Vaccine[];
    value?: string;
    vaccine?: Vaccine;
  }) => void;
  disabled?: boolean;
  multiple?: boolean;
  placeholder?: string;
  dropdownProps?: DropdownProps;
  extraFilters?: Record<string, any>;
}

const getDropdownItemProps = (v: Vaccine): DropdownItemProps => ({
  text: `${v.name} (${v.cvx}) - ${v.saleNdc} - ${v.manufacturer}`,
  content: (
    <Header as="h4">
      <Header.Content>
        {v.name}
        {v.types?.map((t) => (
          <Label key={t} color="orange" size="mini">
            {t}
          </Label>
        ))}
      </Header.Content>
      <Header.Subheader>
        CVX: {v.cvx} - {v.manufacturer} - {v.saleNdc}
      </Header.Subheader>
    </Header>
  ),
});

const VaccinePicker = ({
  value,
  fallback = false,
  onChange,
  disabled,
  multiple,
  placeholder,
  dropdownProps,
  extraFilters = {},
}: VaccinePickerProps) => {
  const [input, setInput] = useState('');
  const [options, setOptions] = useState<DropdownItemProps[]>([]);

  const [selectedValues, setSelectedValues] = useState<string[] | undefined>(
    multiple ? [] : undefined
  );

  const { data: valueData, loading: valueLoading } =
    useQuery<SelectedVaccinesData>(SelectedVaccinesQuery, {
      variables: {
        ids: Array.isArray(value) ? value : [value],
      },
      skip: !value?.length,
    });

  const { data, loading } = useQuery<VaccinesData>(SearchVaccinesQuery, {
    variables: {
      fallback,
      queryInput: `%${input}%`,
      extraFilters,
    },
    skip: input.length < 2,
  });

  useEffect(() => {
    if (data) {
      setOptions([
        ...(valueData
          ? valueData.vaccines.map((vaccine) => ({
              key: `v-${vaccine.id}`,
              value: vaccine.id,
              ...getDropdownItemProps(vaccine),
            }))
          : []),
        ...data.vaccines.map((vaccine) => ({
          key: vaccine.id,
          value: vaccine.id,
          ...getDropdownItemProps(vaccine),
        })),
      ]);
    } else if (valueData) {
      setOptions(
        valueData.vaccines.map((vaccine) => ({
          key: `v-${vaccine.id}`,
          value: vaccine.id,
          ...getDropdownItemProps(vaccine),
        }))
      );
    }
  }, [valueData, data]);

  useEffect(() => {
    if (!valueData) {
      return;
    }
    setSelectedValues(
      multiple
        ? valueData?.vaccines.map((vaccine) => vaccine.id) || undefined
        : [valueData?.vaccines[0].id]
    );
  }, [valueData, multiple]);

  useEffect(() => {
    if (!onChange) return;
    const _selectedItems = [
      ...(valueData?.vaccines ?? []),
      ...(data?.vaccines ?? []),
    ].filter((v) => (selectedValues ?? []).includes(v.id));

    onChange(
      multiple
        ? { values: selectedValues ?? [], vaccines: [] }
        : { value: selectedValues?.at(0), vaccine: _selectedItems[0] }
    );
    // }, [selectedValues]);
  }, [selectedValues, multiple]);

  useEffect(() => {
    if (value === undefined && !multiple) setSelectedValues(['']);
  }, [value]);

  return (
    <Dropdown
      {...dropdownProps}
      data-automation-id="vaccine-picker"
      selection
      search
      multiple={multiple}
      placeholder={placeholder || ''}
      onSearchChange={(_, { searchQuery }) => setInput(searchQuery as string)}
      value={multiple ? selectedValues : selectedValues?.at(0)}
      disabled={disabled || valueLoading || loading}
      onChange={(_, { value }) => {
        const formattedValues = Array.isArray(value)
          ? (value as string[])
          : [value as string];
        setSelectedValues(formattedValues);
      }}
      selectOnBlur={false}
      options={options}
      loading={valueLoading || loading}
    />
  );
};

export default VaccinePicker;
