import {
  ClaimReviewedStatus,
  ClaimRevisionStatus,
  claimReviewedStatusOptions,
  claimRevisionStatusOptions,
} from '@bluefox/models/Billing';
import { Dispatch, SetStateAction } from 'react';
import { Container, Form } from 'semantic-ui-react';
import { ALL, allOption } from '@bluefox/lib/options';

export type SearchValuesProps = {
  billiable?: string;
  reviewed?: boolean;
};

type Props = {
  searchValues: SearchValuesProps;
  setSearchValues: Dispatch<SetStateAction<SearchValuesProps>>;
};

const BillingReportListFilter = ({ searchValues, setSearchValues }: Props) => {
  return (
    <Container>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group widths="equal">
          <Form.Field>
            <label
              htmlFor="billiable_dropdown_filter"
              style={{ marginRight: '1rem' }}
            >
              Billable:
            </label>
            <Form.Dropdown
              id="billiable_dropdown_filter"
              search
              selection
              options={[...allOption, ...claimRevisionStatusOptions]}
              defaultValue={ALL}
              onChange={(_, { value }) => {
                setSearchValues((prevValue) => ({
                  ...prevValue,
                  reviewed: undefined, // Show ALL in Reviewed Dropdown
                  billiable: value !== ALL ? (value as string) : undefined,
                }));
              }}
            />
          </Form.Field>
          <Form.Field>
            <label
              htmlFor="reviewed_dropdown_filter"
              style={{ marginRight: '1rem' }}
            >
              Reviewed:
            </label>
            <Form.Dropdown
              id="reviewed_dropdown_filter"
              disabled={
                !(
                  searchValues.billiable === undefined || // ALL is selected in Billable dropdown
                  searchValues.billiable === ClaimRevisionStatus.BILLABLE
                )
              }
              search
              selection
              fluid
              options={[...allOption, ...claimReviewedStatusOptions]}
              value={
                searchValues.reviewed === undefined
                  ? ALL
                  : searchValues.reviewed
                  ? ClaimReviewedStatus.REVIEWED
                  : ClaimReviewedStatus.NOT_REVIEWED
              }
              onChange={(_, { value }) => {
                setSearchValues((prevValue) => ({
                  ...prevValue,
                  reviewed:
                    value !== ALL
                      ? (value as string) === ClaimReviewedStatus.REVIEWED
                      : undefined,
                }));
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default BillingReportListFilter;
