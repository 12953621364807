import { ClaimStatusAmountDataType } from './ClaimStatusAmountDataType';
import PieBarTwoSeriesChart from './PieBarTwoSeriesChart';

type Props = {
  colors?: string[];
  data: ClaimStatusAmountDataType;
};

const ClaimsStatuesPieChart = ({ colors, data }: Props) => {
  return (
    <PieBarTwoSeriesChart
      colors={colors}
      chartName="Claim Statutes"
      series={[
        {
          name: 'Paid',
          value: data.paidAmount,
        },
        {
          name: 'Resubmition',
          value: data.resubmitionAmount,
        },
        {
          name: 'In Progress',
          value: data.inProgressAmount,
        },
        {
          name: 'Pending appeals',
          value: data.pendingAppealsAmount,
        },
        {
          name: 'Errors and rejected',
          value: data.errorAndRejectedAmount,
        },
        {
          name: 'In meditation',
          value: data.inMediationAmount,
        },
        {
          name: 'Unclaimed',
          value: data.unclaimedAmount,
        },
        {
          name: 'Borrowing',
          value: data.borrowingAmount,
        },
      ]}
    />
  );
};

export default ClaimsStatuesPieChart;
