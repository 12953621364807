import { useQuery } from '@apollo/client';
import { Practice } from '@bluefox/models/Practice';
import { PracticeSettingsByHandlerQuery } from '@bluefox/graphql/practices';
import { useSession } from '@bluefox/contexts';
import { useCallback, useMemo, useState } from 'react';
import { BillingClaimStatus } from '@bluefox/models/Billing';

export interface PracticeSettingsData {
  practices: Practice[];
}

const statusesToEdit = [
  BillingClaimStatus.PAID,
  BillingClaimStatus.PARTIALLY_PAID,
  BillingClaimStatus.CLOSED,
  BillingClaimStatus.BILLED,
  BillingClaimStatus.PARTIALLY_BILLED,
];

export const useBillingClaimStatusOptions = (status?: BillingClaimStatus) => {
  const practiceAccount = useSession();

  const [
    allowedUserForClaimStatusesEdition,
    setAllowedUserForClaimStatusesEdition,
  ] = useState(false);

  const {
    data: practiceSettings,
    loading,
    error,
  } = useQuery<PracticeSettingsData>(PracticeSettingsByHandlerQuery, {
    variables: {
      handler: 'canid',
    },

    onCompleted: (data) => {
      const enableEdition =
        data?.practices[0]?.settings?.billing?.claimStatusesEdition;

      const isAllowedToEditClaimStatus = enableEdition
        ? enableEdition.includes(practiceAccount?.account?.email || '')
        : false;

      setAllowedUserForClaimStatusesEdition(isAllowedToEditClaimStatus);
    },
  });

  const isStatusEditionAllowed = useCallback(
    (status?: BillingClaimStatus): boolean => {
      return (
        status !== undefined &&
        (!statusesToEdit.includes(status) ||
          (statusesToEdit.includes(status) &&
            allowedUserForClaimStatusesEdition))
      );
    },
    [statusesToEdit, allowedUserForClaimStatusesEdition]
  );

  const options = useMemo(
    () => [
      {
        key: 'submitted',
        text: 'Submitted',
        value: BillingClaimStatus.SUBMITTED,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'error',
        text: 'Error',
        value: BillingClaimStatus.ERROR,
        disabled:
          status === BillingClaimStatus.PENDING ||
          status === BillingClaimStatus.BILLED ||
          status === BillingClaimStatus.ALREADY_SUBMITTED ||
          status === BillingClaimStatus.PARTIALLY_BILLED ||
          status === BillingClaimStatus.GENERATED_837p,
      },
      {
        key: 'toresubmit',
        text: 'To Resubmit',
        value: BillingClaimStatus.TO_RESUBMIT,
        disabled:
          status === BillingClaimStatus.PENDING ||
          status === BillingClaimStatus.UNCLAIMED ||
          status === BillingClaimStatus.BILLED ||
          status === BillingClaimStatus.PARTIALLY_BILLED ||
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p,
      },
      {
        key: 'accepted',
        text: 'Accepted CH',
        value: BillingClaimStatus.ACCEPTED,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'rejected',
        text: 'Rejected CH',
        value: BillingClaimStatus.REJECTED,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'corrected',
        text: 'Corrected CH',
        value: BillingClaimStatus.CORRECTED,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.READY_TO_SUBMIT &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'paid',
        text: 'Paid',
        value: BillingClaimStatus.PAID,
        disabled:
          status === BillingClaimStatus.PENDING ||
          status === BillingClaimStatus.UNCLAIMED ||
          status === BillingClaimStatus.ALREADY_SUBMITTED ||
          status === BillingClaimStatus.PARTIALLY_BILLED ||
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED ||
          !isStatusEditionAllowed(status),
      },
      {
        key: 'partially_paid',
        text: 'Partially Paid',
        value: BillingClaimStatus.PARTIALLY_PAID,
        disabled:
          status === BillingClaimStatus.PENDING ||
          status === BillingClaimStatus.UNCLAIMED ||
          status === BillingClaimStatus.ALREADY_SUBMITTED ||
          status === BillingClaimStatus.PARTIALLY_BILLED ||
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED ||
          !isStatusEditionAllowed(status),
      },
      {
        key: 'denied',
        text: 'Denied',
        value: BillingClaimStatus.DENIED,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.READY_TO_SUBMIT &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'inMediation',
        text: 'In mediation',
        value: BillingClaimStatus.IN_MEDIATION,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.READY_TO_SUBMIT &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'dismissed',
        text: 'Dismissed',
        value: BillingClaimStatus.DISMISSED,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.READY_TO_SUBMIT &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'billed',
        text: 'Billed',
        value: BillingClaimStatus.BILLED,
        disabled:
          status !== BillingClaimStatus.PARTIALLY_BILLED ||
          (status === BillingClaimStatus.PARTIALLY_BILLED &&
            !isStatusEditionAllowed(status)),
      },
      {
        key: 'pending',
        text: 'Pending',
        value: BillingClaimStatus.PENDING,
        disabled: status !== BillingClaimStatus.PENDING,
      },
      {
        key: 'closed',
        text: 'Closed',
        value: BillingClaimStatus.CLOSED,
        disabled:
          status === BillingClaimStatus.PENDING ||
          status === BillingClaimStatus.UNCLAIMED ||
          status === BillingClaimStatus.ALREADY_SUBMITTED ||
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          !isStatusEditionAllowed(status),
      },
      {
        key: 'acknowledged',
        text: 'Acknowledged',
        value: BillingClaimStatus.ACKNOWLEDGED,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'toAppeal',
        text: 'To Appeal',
        value: BillingClaimStatus.TO_APPEAL,
        disabled:
          status !== BillingClaimStatus.PENDING &&
          status !== BillingClaimStatus.UNCLAIMED &&
          status !== BillingClaimStatus.ALREADY_SUBMITTED &&
          status !== BillingClaimStatus.PARTIALLY_BILLED &&
          status !== BillingClaimStatus.READY_TO_SUBMIT &&
          status !== BillingClaimStatus.GENERATED_837p &&
          status !== BillingClaimStatus.BILLED
            ? false
            : true,
      },
      {
        key: 'unclaimed',
        text: 'Unclaimed',
        value: BillingClaimStatus.UNCLAIMED,
        disabled: true,
      },
      {
        key: 'to correct',
        text: 'To Correct',
        value: BillingClaimStatus.TO_CORRECT,
        disabled:
          status === BillingClaimStatus.PENDING ||
          status === BillingClaimStatus.UNCLAIMED ||
          status === BillingClaimStatus.BILLED ||
          status === BillingClaimStatus.ALREADY_SUBMITTED ||
          status === BillingClaimStatus.PARTIALLY_BILLED ||
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p,
      },
      {
        key: 'already_submitted',
        text: 'Already Submitted',
        value: BillingClaimStatus.ALREADY_SUBMITTED,
        disabled: true,
      },
      {
        key: 'partially_billed',
        text: 'Partially Billed',
        value: BillingClaimStatus.PARTIALLY_BILLED,
        disabled:
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED ||
          status === BillingClaimStatus.CLOSED,
      },
      {
        key: 'toAnalyze',
        text: 'To Analyze',
        value: BillingClaimStatus.TO_ANALYZE,
        disabled:
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED,
      },
      {
        key: 'ready_to_report',
        text: 'Ready To Report',
        value: BillingClaimStatus.READY_TO_REPORT,
        disabled:
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED,
      },
      {
        key: 'confirm_payment',
        text: 'Confirm Payment',
        value: BillingClaimStatus.CONFIRM_PAYMENT,
        disabled:
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED,
      },
      {
        key: 'borrowing',
        text: 'Borrowing',
        value: BillingClaimStatus.BORROWING,
        disabled:
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED,
      },
      {
        key: 'direct_charge',
        text: 'Direct Charge',
        value: BillingClaimStatus.DIRECT_CHARGE,
        disabled:
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED,
      },
      {
        key: 'waiting_info',
        text: 'Waiting Info',
        value: BillingClaimStatus.WAITING_INFO,
        disabled:
          status === BillingClaimStatus.READY_TO_SUBMIT ||
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED,
      },
      {
        key: 'ready_to_submit',
        text: 'Ready To Submit',
        value: BillingClaimStatus.READY_TO_SUBMIT,
        disabled:
          status === BillingClaimStatus.GENERATED_837p ||
          status === BillingClaimStatus.BILLED,
      },
      {
        key: 'generated_837p',
        text: 'Generated 837p',
        value: BillingClaimStatus.GENERATED_837p,
        disabled: true,
      },
    ],
    [status, isStatusEditionAllowed]
  );

  return {
    options,
    loading,
    error,
  };
};
