import { View, StyleSheet, Text } from '@react-pdf/renderer';
import PracticeClaimsTable, {
  PracticeClaimsTableProps,
} from './PracticeClaimsTable';
import TotalClaims from './TotalClaims';
import Discount, { DiscountProps } from './Discount';
import Fee, { FeeProps } from './Fee';
import ExtrasTable, { ExtrasTableProps } from './ExtrasTable';
import TotalInvoice from './TotalInvoice';

const bodyStyle = StyleSheet.create({
  section: {
    marginTop: 8,
    padding: 40,
  },
  bottomSection: {
    padding: 16,
  },
  bottomText: {
    fontSize: 8,
    fontWeight: 400,
    lineHeight: 12 / 8,
    letterSpacing: 0,
    textAlign: 'center',
    color: '#4E657E',
  },
  tableMarginBottom: {
    marginBottom: 48,
  },
});

export type BodyProps = {
  claimTables: PracticeClaimsTableProps[];
  totalClaims: number;
  discount?: DiscountProps;
  fee?: FeeProps;
  extraTable?: ExtrasTableProps;
  totalInvoice: number;
};

const Body = ({
  claimTables,
  totalClaims,
  discount,
  fee,
  extraTable,
  totalInvoice,
}: BodyProps) => {
  return (
    <View style={bodyStyle.section}>
      {claimTables.map((t, i) => (
        <PracticeClaimsTable {...t} hasSiblings={i < claimTables.length - 1} />
      ))}
      <View style={bodyStyle.bottomSection}>
        <Text style={bodyStyle.bottomText}>
          We invite you to explore the detailed breakdown of charges in the
          Billing Report on the following pages.
        </Text>
        <Text style={bodyStyle.bottomText}>
          Your complete understanding of the services provided is important to
          us.
        </Text>
      </View>
      <TotalClaims total={parseFloat(totalClaims).toFixed(2)} />
      {discount && <Discount {...discount} />}
      {fee && <Fee {...fee} />}
      {extraTable && <ExtrasTable {...extraTable} />}
      <TotalInvoice total={parseFloat(totalInvoice).toFixed(2)} />
    </View>
  );
};

export default Body;
