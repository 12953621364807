import { Practice } from '@bluefox/models/Practice';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Container, Form, Grid, Message } from 'semantic-ui-react';
import { parseFloatOrZero } from '@bluefox/lib/maths';
import { Inventory } from '@bluefox/models/Inventory';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import {
  BillingInvoiceExtraItem,
  OTHER_REASONS_ENUM,
} from '@bluefox/models/Billing';
import { useQuery } from '@apollo/client';
import { getInventoryVaccinesQuery } from '@bluefox/graphql/inventory';

type Props = {
  loading?: boolean;
  practices: Practice[] | undefined;
  extraItem: BillingInvoiceExtraItem;
  setExtraItem: Dispatch<SetStateAction<BillingInvoiceExtraItem>>;
  onSubmit?: (otherChange: BillingInvoiceExtraItem) => void;
  onClear?: () => void;
};

const ExtraItemsForm = ({
  loading,
  practices,
  extraItem,
  setExtraItem,
  onSubmit,
  onClear,
}: Props) => {
  const {
    loading: inventoryVaccinesLoading,
    error: inventoryVaccinesError,
    data: inventoryVaccinesData,
  } = useQuery<{ inventories: Partial<Inventory>[] }>(
    getInventoryVaccinesQuery,
    {
      variables: { practiceId: extraItem?.practice?.id },
      skip: !extraItem?.practice?.id,
    }
  );

  const disableSubmitButton = useMemo(
    () =>
      !extraItem?.practice?.id ||
      !extraItem?.reason ||
      (extraItem?.reason === OTHER_REASONS_ENUM['Missing Vaccines'] &&
        !extraItem?.inventory?.id) ||
      (extraItem?.reason === OTHER_REASONS_ENUM.Others &&
        !isStringNotEmpty(extraItem?.description)) ||
      !extraItem?.quantity ||
      !extraItem?.unitPrice ||
      loading,
    [
      extraItem?.description,
      extraItem?.inventory?.id,
      extraItem?.practice?.id,
      extraItem?.quantity,
      extraItem?.reason,
      extraItem?.unitPrice,
      loading,
    ]
  );

  const buttonLoading = useMemo(
    () => loading || inventoryVaccinesLoading,
    [inventoryVaccinesLoading, loading]
  );

  return (
    <Container>
      {inventoryVaccinesError && (
        <Message error>
          Failed to get inventory vaccines: {inventoryVaccinesError?.message}
        </Message>
      )}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit?.(extraItem);
        }}
      >
        <Grid columns={4} stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Form.Select
                label="Practice"
                search
                required
                value={extraItem?.practice?.id || ''}
                options={
                  practices?.map(({ id, name }) => ({
                    key: id,
                    text: name,
                    value: id,
                  })) || []
                }
                onChange={(_, { value }) => {
                  const selectedPractice = practices?.find(
                    (p) => value === p.id
                  ) as Practice;
                  setExtraItem((prevValues) => ({
                    ...prevValues,
                    practiceId: selectedPractice?.id,
                    practice: selectedPractice,
                  }));
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Select
                label="Reason"
                search
                required
                value={extraItem?.reason || ''}
                options={Object.entries(OTHER_REASONS_ENUM).map(
                  ([key, value]) => ({
                    key: value,
                    value,
                    text: key,
                  })
                )}
                onChange={(_, { value }) => {
                  setExtraItem((prevValues) => ({
                    ...prevValues,
                    inventory: undefined,
                    description: undefined,
                    reason: value as OTHER_REASONS_ENUM,
                  }));
                }}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              {extraItem?.reason === OTHER_REASONS_ENUM['Missing Vaccines'] && (
                <Form.Select
                  label="Inventory / Vaccine"
                  search
                  required
                  value={extraItem?.inventory?.id || ''}
                  options={
                    inventoryVaccinesData?.inventories?.map((inventory) => ({
                      key: inventory.id,
                      value: inventory.id,
                      text: `${inventory.vaccine?.name} (Lot: ${inventory.lot}) - ${inventory.doses} doses`,
                    })) || []
                  }
                  onChange={(_, { value }) => {
                    const selectedInventory =
                      inventoryVaccinesData?.inventories?.find(
                        (p) => value === p.id
                      ) as Inventory;
                    setExtraItem((prevValues) => ({
                      ...prevValues,
                      inventory: selectedInventory,
                      inventoryId: selectedInventory?.id,
                    }));
                  }}
                  loading={inventoryVaccinesLoading}
                />
              )}
              {extraItem?.reason === OTHER_REASONS_ENUM.Others && (
                <Form.Input
                  label="Description"
                  type="text"
                  required
                  value={extraItem?.description || ''}
                  onChange={(_, { value }) => {
                    setExtraItem((prevValues) => ({
                      ...prevValues,
                      description: value,
                    }));
                  }}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Form.Input
                label="Quantity"
                type="number"
                min="0"
                step="1"
                required
                value={extraItem?.quantity || 0}
                onChange={(_, { value }) => {
                  setExtraItem((prevValues) => ({
                    ...prevValues,
                    quantity: parseFloatOrZero(value),
                  }));
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Input
                label="Value"
                type="number"
                min="0"
                step=".01"
                required
                value={extraItem?.unitPrice || 0}
                onChange={(_, { value }) => {
                  setExtraItem((prevValues) => ({
                    ...prevValues,
                    unitPrice: parseFloatOrZero(value),
                  }));
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Input
                label="Subtotal"
                type="number"
                value={(
                  (extraItem.quantity || 0) * (extraItem.unitPrice || 0)
                ).toFixed(2)}
                readOnly
              />
            </Grid.Column>
            <Grid.Column width={2} verticalAlign="bottom" textAlign="right">
              <Form.Button
                type="button"
                disabled={disableSubmitButton}
                style={{ minWidth: '50%' }}
                secondary
                onClick={onClear}
              >
                Clear
              </Form.Button>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign="bottom" textAlign="right">
              <Form.Button
                type="submit"
                loading={buttonLoading}
                disabled={disableSubmitButton}
                style={{ minWidth: '50%' }}
                primary
              >
                {extraItem.id ? 'Update' : 'Add'}
              </Form.Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
};

export default ExtraItemsForm;
