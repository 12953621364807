import { StyleSheet, View, Image } from '@react-pdf/renderer';

const footerStyle = StyleSheet.create({
  section: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: 'auto',
  },
  body: {
    padding: '10 0 50',
  },
  image: { width: 146, height: 32, margin: 'auto' },
});

export type FooterProps = {
  logoImg?: string | undefined;
};

const Footer = ({ logoImg }: FooterProps) => {
  return (
    <View style={footerStyle.section} fixed>
      <View style={footerStyle.body}>
        {logoImg && <Image src={logoImg} style={footerStyle.image} />}
      </View>
    </View>
  );
};

export default Footer;
