import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { toast, ToastOptions } from 'react-semantic-toasts';
import { useQuery, useMutation } from '@apollo/client';
import {
  SaveInvoiceToSendByEmail,
  SendInvoiceMutation,
} from '@bluefox/graphql/billing';
import { BillingInvoice } from '@bluefox/models/Billing';
import { Button, Modal, Form } from 'semantic-ui-react';
import { DateFormats } from '@bluefox/models/Dates';

interface InvoicesListEditModalProps {
  onClose: () => void;
  open: boolean;
  invoice?: BillingInvoice;
  onSave: () => void;
  lastDescription?: string;
}

const InvoicesListEditModal = ({
  onClose,
  open,
  invoice,
  onSave,
  lastDescription,
}: InvoicesListEditModalProps) => {
  const onlyNumbers = /^[0-9]*$/;

  const [daysToDueDate, setDaysToDueDate] = useState<string>('');
  const [dueDate, setDueDate] = useState<Date | null | undefined>(null);
  const [description, setDescription] = useState<string>(
    lastDescription ? lastDescription : ''
  );

  const [isLoading, setIsLoading] = useState(false);

  const cleanAndClose = () => {
    setDaysToDueDate('');
    setDescription('');
    onClose();
  };

  const [saveInvoice] = useMutation(SaveInvoiceToSendByEmail);

  const [SendInvoice] = useMutation(SendInvoiceMutation);

  const handleSendInvoice = async () => {
    try {
      setIsLoading(true);
      await saveInvoice({
        variables: {
          invoiceId: invoice?.id,
          dueDate,
          description,
        },
      });

      //call action
      const response = await SendInvoice({
        variables: {
          invoiceId: invoice?.id,
        },
      });

      const toastData: ToastOptions =
        response.data?.sendInvoice.code === 200
          ? { type: 'success', title: 'Email sent successfully' }
          : { type: 'error', title: 'An error has ocurred' };

      setIsLoading(false);

      toast({
        ...toastData,
        time: toastData.type === 'success' ? 1000 : 5000,
      });
      onSave();
      cleanAndClose();
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!invoice?.dueDate) return;
    const invoiceDue = moment(invoice?.dueDate);
    const todaysDate = moment();
    const daysLeftToDue = invoiceDue.diff(todaysDate, 'days');

    setDaysToDueDate((daysLeftToDue + 1).toString());
    setDescription(invoice?.description);
  }, [invoice]);

  useEffect(() => {
    if (!daysToDueDate) return;
    let _dueDate = moment().add(daysToDueDate, 'days').format(DateFormats.DATE);

    setDueDate(new Date(_dueDate));
  }, [daysToDueDate]);

  return (
    <Modal size="tiny" closeIcon onClose={cleanAndClose} open={open}>
      <Modal.Header>Email info</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '1rem' }}>
                  <b>Days left to invoice due</b>
                </label>
                <Form.Input
                  width={2}
                  placeholder="Days"
                  onChange={(_, { value }) => setDaysToDueDate(value)}
                  value={daysToDueDate}
                  onKeyPress={(event: React.KeyboardEvent) => {
                    if (!onlyNumbers.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                value={description}
                onChange={(_, { value }) => setDescription(value as string)}
                label="Description"
                placeholder="Description..."
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={onClose} disabled={isLoading} />
        <Button
          content="Send"
          labelPosition="right"
          icon="send"
          onClick={handleSendInvoice}
          primary
          disabled={isLoading}
          loading={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default InvoicesListEditModal;
