import React, { CSSProperties, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AllPracticesQuery } from '@graphql/practices';
import { AllPracticeData, PracticeOption } from '@bluefox/models/Practice';
import { Form } from 'semantic-ui-react';

interface PracticeFilterProps {
  suspended?: 'suspended' | 'notSuspended';
  billable?: 'billable' | 'notBillable';
  integration?: 'athena' | 'ecw' | 'op' | 'develo';
  multiple?: boolean;
  fluid?: boolean;
  practiceSearch?: string | [string];
  setPracticeSearch?: any;
  style?: CSSProperties;
  filterByHandler?: boolean;
  practices?: 'all' | 'select';
}

const PracticeFilter = ({
  suspended,
  billable,
  integration,
  multiple,
  fluid,
  practiceSearch,
  setPracticeSearch,
  style,
  filterByHandler,
  practices = 'all',
}: PracticeFilterProps) => {
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);

  const createCriteria = (
    suspended: string | undefined,
    billable: string | undefined,
    integration: string | undefined
  ) => {
    let _suspended = {};
    let _billable = {};
    let _integration = {};

    if (suspended !== undefined) {
      _suspended = suspended === 'suspended' ? { _eq: true } : { _eq: false };
    }

    if (billable !== undefined) {
      _billable = billable === 'billable' ? { _eq: true } : { _eq: false };
    }

    if (integration !== undefined) {
      integration === 'ecw'
        ? (_integration = { _contains: { bot: { enabled: true } } })
        : (_integration = { _contains: { [integration]: { enabled: true } } });
    }

    return {
      suspended: _suspended,
      isBillable: _billable,
      settings: _integration,
    };
  };

  useQuery<AllPracticeData>(AllPracticesQuery, {
    variables: {
      criteria: createCriteria(suspended, billable, integration),
    },
    onCompleted(data) {
      filterByHandler
        ? setPracticeOptions(
            data.allPractices.map((p) => {
              return {
                text: p.name,
                value: p.handler,
              };
            })
          )
        : setPracticeOptions(
            data.allPractices.map((p) => {
              return {
                text: p.name,
                value: p.id,
              };
            })
          );
    },
  });

  const handleMultiplePracticeValue = (value: [string]) => {
    const practiceValue =
      !value?.length || value.includes('all') ? undefined : value;
    setPracticeSearch(practiceValue);
  };

  return (
    <Form>
      <Form.Dropdown
        label="Filter by Practice"
        style={style || { minWidth: '15rem' }}
        placeholder="Filter by practice"
        fluid={fluid}
        value={practiceSearch}
        search
        multiple={multiple}
        selection
        onChange={(e, { value }) => {
          multiple
            ? handleMultiplePracticeValue(value as [string])
            : setPracticeSearch(value as string);
        }}
        options={[
          {
            text: `${
              practices === 'all' ? 'All Practices' : 'Select Practice'
            }`,
            value: 'all',
          },
          ...practiceOptions,
        ]}
      />
    </Form>
  );
};

export default PracticeFilter;
