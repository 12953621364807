import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertAthenaMappingMutation,
  UpdateAthenaMappingMutation,
} from '@bluefox/graphql/integrations/athena';
import { MappedItem } from '@bluefox/models/integrations/Athena';
import { Vaccine } from '@bluefox/models/Vaccine';
import { Button, Form } from 'semantic-ui-react';
import VaccinePicker from '@bluefox/ui/VaccinePicker';

interface AthenaVaccineSearchCriteriaMappingFormProps {
  close: () => void;
  data: MappedItem | null;
  refetchMappings: () => void;
  mappedKeys: string[];
  practiceId: string | undefined;
}

const AthenaVaccineSearchCriteriaMappingForm = ({
  close,
  data,
  refetchMappings,
  mappedKeys,
  practiceId,
}: AthenaVaccineSearchCriteriaMappingFormProps) => {
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();
  const [athenaVaccineName, setAthenaVaccineName] = useState<string>(
    data?.value.data || ''
  );
  const [athenaKey, setAthenaKey] = useState<string>(data ? data.key : '');
  const [value, setValue] = useState({
    data: data?.value.data || '',
    vaccineId: data?.value.vaccineId || undefined,
  });

  const [saveVaccineSearchCriteriaMapping] = useMutation(
    data?.id ? UpdateAthenaMappingMutation : InsertAthenaMappingMutation
  );
  const handleSubmit = () => {
    if (mappedKeys.includes(athenaKey)) {
      toast({
        title: 'Vaccine search criteria is already mapped.',
        type: 'error',
        time: 5000,
      });
      close();
      return;
    }

    saveVaccineSearchCriteriaMapping({
      variables: {
        id: data?.id,
        practiceId: data?.practice?.id || practiceId,
        key: athenaKey.trim(),
        type: 'vaccineSearchCriteria',
        value: value,
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    const vaccineMappingValue = {
      data: athenaVaccineName,
      vaccineId: selectedVaccine?.id,
    };

    setValue(vaccineMappingValue);
  }, [athenaVaccineName, selectedVaccine]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={athenaVaccineName}
            onChange={(_, { value }) => setAthenaVaccineName(value)}
            fluid
            label="Athena vaccine name"
            placeholder="Athena vaccine name"
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Select Canid Vaccine</label>
          <VaccinePicker
            dropdownProps={{
              fluid: true,
              placeholder: 'Search Vaccine',
            }}
            onChange={({ vaccine }) => {
              setSelectedVaccine(vaccine);
              setAthenaKey(vaccine?.saleNdc || '');
            }}
            value={data?.value.vaccineId || selectedVaccine?.id}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button primary type="submit" content="Save" icon="save" />
      </Form.Field>
    </Form>
  );
};

export default AthenaVaccineSearchCriteriaMappingForm;
