import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import { Container, Form, Menu, Message, Table } from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import { GetBorrowingRequestTickets } from '@bluefox/graphql/tickets';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { Ticket, TicketStatusEnum } from '@bluefox/models/Tickets';
import { humanizeText } from '@bluefox/lib/humanize';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';
import BorrowingRequestDetails from './BorrowingRequestDetails';

interface OutputDetail {
  [key: string]: string | string[] | undefined;
  patient?: string;
  dateOfService?: string;
  issueType?: string;
  insurance?: string;
  correctedInsurance?: string;
  relevantVaccines?: string[];
  issueDescription?: string;
}

export interface OutputTicket {
  id: string;
  details: OutputDetail;
  status: TicketStatusEnum;
}

enum MagicWordsDict {
  ISSUE = 'Issue',
  ISSUE_TYPE = 'issueType',
  ISSUE_DESCRIPTION = 'issueDescription',
  BORROWING_REQUEST = 'Borrowing_request',
  ALL = 'all',
  OPEN = 'open',
  CLOSED = 'closed',
}

function transformData(tickets: Ticket[]): OutputTicket[] {
  return tickets.map((ticket) => {
    const details: OutputDetail = {};
    let issueCount = 0; // To track occurrences of "issue"

    ticket.detail.fields.forEach((field) => {
      let key = toCamelCase(field.title);

      // Check for "issue" title to differentiate between types
      if (field.title === MagicWordsDict.ISSUE) {
        issueCount++;
        key =
          issueCount === 1
            ? MagicWordsDict.ISSUE_TYPE
            : MagicWordsDict.ISSUE_DESCRIPTION;
      }

      details[key] = field.detail ? field.detail : '';
    });

    return {
      id: ticket.id || '',
      status: ticket.status,
      details,
    };
  });
}

function toCamelCase(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
}

const BorrowingRequestSolvingScreen = () => {
  const [searchPractice, setSearchPractice] = useState<string>(
    MagicWordsDict.ALL
  );
  const [ticketStatus, setTicketStatus] = useState<string>(MagicWordsDict.OPEN);
  const [tickets, setTickets] = useState<OutputTicket[]>([]);

  useQuery<{ tickets: Ticket[] }>(GetBorrowingRequestTickets, {
    variables: {
      criteria: {
        type: { _eq: MagicWordsDict.BORROWING_REQUEST },
        status:
          ticketStatus === MagicWordsDict.ALL ? {} : { _eq: ticketStatus },
        detail: {
          _cast: {
            String: {
              _ilike:
                searchPractice === MagicWordsDict.ALL
                  ? '%%'
                  : whereLikeInput(searchPractice),
            },
          },
        },
      },
    },
    onCompleted(data) {
      setTickets(transformData(data.tickets));
    },
  });

  return (
    <MainLayout
      path={[
        {
          text: 'Borrowing',
          to: '/billing/general/borrowing',
        },
        { text: 'Solve Borrowing Requests' },
      ]}
    >
      <Container fluid>
        <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
          <Menu.Menu
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Menu.Item>
              <PracticeFilter
                setPracticeSearch={setSearchPractice}
                practiceSearch={searchPractice}
                suspended="notSuspended"
                practices="select"
                filterByHandler
              />
            </Menu.Item>
            <Menu.Item>
              <Form>
                <Form.Dropdown
                  label="Filter by Status"
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by status"
                  fluid
                  value={ticketStatus}
                  selection
                  onChange={(e, { value }) => {
                    setTicketStatus(value as string);
                  }}
                  options={[
                    {
                      text: `${'All Tickets'}`,
                      value: 'all',
                    },
                    {
                      text: `${'Open Tickets'}`,
                      value: MagicWordsDict.OPEN,
                    },
                    {
                      text: `${'Closed Tickets'}`,
                      value: MagicWordsDict.CLOSED,
                    },
                  ]}
                />
              </Form>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        {tickets.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Practice</Table.HeaderCell>
                <Table.HeaderCell>Patient (DoB)</Table.HeaderCell>
                <Table.HeaderCell>Date of Service</Table.HeaderCell>
                <Table.HeaderCell>Issue Type</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tickets.map((ticket: OutputTicket) => {
                return (
                  <Table.Row key={ticket.id}>
                    <Table.Cell>{ticket.details.practice}</Table.Cell>
                    <Table.Cell>{ticket.details.patient}</Table.Cell>
                    <Table.Cell>
                      {ticket.details.dateOfService
                        ? formatDateToMMDDYYYYV2(ticket.details.dateOfService)
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {ticket.details.issueType
                        ? humanizeText(ticket.details.issueType, {
                            delimiter: '_',
                            capitalize: 'first',
                          })
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {humanizeText(ticket.status, { capitalize: 'first' })}
                    </Table.Cell>
                    <Table.Cell>
                      <BorrowingRequestDetails ticket={ticket} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        ) : (
          <Message>
            No possible borrowing cases found for theese search criteria.
          </Message>
        )}
      </Container>
    </MainLayout>
  );
};

export default BorrowingRequestSolvingScreen;
