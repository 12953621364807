import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Container, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { usePractice } from '@bluefox/contexts/Practice';
import MainLayout from '@ui/MainLayout';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import { BorrowingInventoryAdjustments } from '@graphql/inventory';

const BorrowingSummary = () => {
  const practice = usePractice();
  const [borrowingDates, setBorrowingDates] = useState([]);

  const { data } = useQuery(BorrowingInventoryAdjustments, {
    variables: {
      criteria: {
        inventoryAdjustmentDetails: { reason: { _eq: 'borrowing' } },
        status: { _eq: 'applied' },
        practice: { id: { _eq: practice.id } },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setBorrowingDates(
        Array.from(
          new Set(
            data.adjustments.map((item: { id: string; createdAt: Date }) =>
              formatDatetimeToMMDDYYY(item.createdAt)
            )
          )
        )
      );
    },
  });

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Borrowing Summary' },
      ]}
    >
      <Container fluid>
        <Table celled structured selectable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell width={1}>Date Solved</Table.HeaderCell>
              <Table.HeaderCell width={1}>Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {borrowingDates.map((a: string) => (
              <Table.Row key={a} textAlign="center">
                <Table.Cell>{a.replaceAll('-', '/')}</Table.Cell>
                <Table.Cell>
                  <Button
                    primary
                    className="min-component-vertical-margin"
                    icon="eye"
                    size="tiny"
                    as={Link}
                    to={`/practices/${practice.handler}/borrowing-summary/${a}`}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    </MainLayout>
  );
};

export default BorrowingSummary;
