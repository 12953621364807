import { tsXLXS } from 'ts-xlsx-export';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/client';
import { PracticeInventoryOrdersQuery } from '@bluefox/graphql/inventoryOrders';
import {
  InventoryOrders,
  OrderStatuses,
} from '@bluefox/models/InventoryOrders';
import { Vaccine } from '@bluefox/models/Vaccine';
import {
  Container,
  Segment,
  Menu,
  Icon,
  Table,
  Placeholder,
  Modal,
  Message,
  Label,
  Button,
  Popup,
  Dropdown,
  Input,
  Card,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import InventoryOrderForm from '@bluefox/ui/InventoryOrderForm';
import { usePractice } from '@bluefox/contexts';
import styled from 'styled-components';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { humanizeText } from '@bluefox/lib/humanize';
import { toast } from 'react-semantic-toasts';
import { DateFormats } from '@bluefox/models/Dates';
import OrderNotificationModal from '@ui/Notifications/OrderNotification/OrderNotificationModal';
import { GetNotificationsByEntityRefIdQuery } from '@graphql/communications';
import { Notification } from '@bluefox/models/Notification';
import { useNotifications } from '@ui/Notifications/useNotifications';

const statusOptions = [
  { key: 'all', text: 'All', value: OrderStatuses.ALL },
  { key: 'ordered', text: 'Ordered', value: OrderStatuses.ORDERED },
  { key: 'received', text: 'Received', value: OrderStatuses.RECEIVED },
  { key: 'cancelled', text: 'Cancelled', value: OrderStatuses.CANCELLED },
  { key: 'closed', text: 'Closed', value: OrderStatuses.CLOSED },
];

const paidOptions = [
  { key: 'paid', text: 'Paid', value: 'paid' },
  { key: 'notPaid', text: 'Not Paid', value: 'notPaid' },
];

const vfcOptions = [
  { key: 'vfc', text: 'VFC', value: 'vfcEligible' },
  { key: 'private', text: 'Private', value: 'notEligible' },
];

const dueOptions = [
  { key: 'due', text: 'Due', value: 'due' },
  { key: 'closeToDue', text: 'Close to due', value: 'closeToDue' },
];

type InventoryOrderVaccineType = Pick<
  Vaccine,
  'saleNdc' | 'manufacturer' | 'name' | 'id'
>;

type InventoryOrderType = Omit<
  InventoryOrders,
  'vaccine' | 'vaccineId' | 'practice'
> & {
  vaccine: InventoryOrderVaccineType;
  practice?: {
    timezone: string;
  };
};

interface InventoryOrderDataType {
  inventory_orders: InventoryOrderType[];
}

const InventoryOrdersScreen = () => {
  const practice = usePractice();

  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState<InventoryOrderType>();
  const [statusSearch, setStatusSearch] = useState<OrderStatuses>(
    OrderStatuses.ALL
  );
  const [paidSearch, setPaidSearch] = useState<boolean>();
  const [paidSearchValue, setPaidSearchValue] = useState<
    'paid' | 'notPaid' | 'all'
  >('all');
  const [vfcSearch, setVfcSearch] = useState<boolean>();
  const [dueSearch, setDueSearch] = useState('');
  const [orderNumberSearch, setOrderNumberSearch] = useState('');
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();
  const [criteria, setCriteria] = useState<object>({});
  const [notificationSentIds, setNotificationSentIds] = useState<string[]>([]);

  const [excelData, setExcelData] = useState<any[] | undefined>([]);

  const { data, loading, refetch } = useQuery<InventoryOrderDataType>(
    PracticeInventoryOrdersQuery(true),
    {
      variables: {
        practiceId: practice.id,
        criteria,
      },
    }
  );

  const { sendNotificationHandler } = useNotifications();

  const {
    loading: notificationSentIdsLoading,
    refetch: notificationSentIdsRefetch,
  } = useQuery<{
    communicationNotifications: Notification[];
  }>(GetNotificationsByEntityRefIdQuery, {
    variables: {
      entityRefIds: data?.inventory_orders.map((io) => io.id),
    },
    skip: !data?.inventory_orders || !data?.inventory_orders.length,
    onError: (error) => {
      setNotificationSentIds([]);
    },
    onCompleted: (data) => {
      const ids = data.communicationNotifications?.map(
        (notification) => notification.entityRefId as string
      );
      setNotificationSentIds(ids);
    },
  });

  const handleVfcEligibleValue = (value: string) => {
    const vfcEligibleValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setVfcSearch(
      vfcEligibleValue !== undefined
        ? vfcEligibleValue === 'vfcEligible'
        : undefined
    );
    setPaidSearch(vfcEligibleValue !== undefined ? undefined : undefined);
    setPaidSearchValue('all');
  };
  const handlePaidStatusValue = (value: string) => {
    const paidStatusValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setPaidSearch(
      paidStatusValue !== undefined ? paidStatusValue === 'paid' : undefined
    );
    if (value === 'all') {
      setPaidSearchValue('all');
    } else if (value === 'paid') {
      setPaidSearchValue('paid');
    } else {
      setPaidSearchValue('notPaid');
    }
  };

  const handleLoadXlsx = () => {
    if (!excelData) return;
    tsXLXS()
      .exportAsExcelFile(excelData)
      .saveAsExcelFile(
        `Canid-Orders-List-${moment().format('MM-DD-YY-H:m:s')}`
      );
  };
  const handleCopyText = (str: string | undefined) => {
    navigator.clipboard.writeText(str || '');

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setOrderData({
      id: '',
      practiceId: '',
      date: undefined,
      purchasePlatform: '',
      lot: '',
      inventoryExpiration: undefined,
      doses: 0,
      amount: 0,
      invoiceNumber: '',
      invoiceDue: undefined,
      invoiceLink: '',
      orderNumber: '',
      trackingNumber: '',
      paidAt: undefined,
      paid: false,
      status: OrderStatuses.ORDERED,
      statusLog: undefined,
      inventoryId: '',
      inventory: undefined,
      packagesAmount: 0,
      vaccine: {
        id: '',
        saleNdc: '',
        name: '',
        manufacturer: '',
      },
      statementDate: undefined,
      notes: '',
      creditCardId: '',
    });
  };

  useEffect(() => {
    if (!data) return;
    const ordersList = data.inventory_orders.map((order) => {
      return {
        'Order Number': order.orderNumber ? order.orderNumber : '-',
        'Purchase Date': order.date
          ? moment(order.date).format('MM-DD-YYYY')
          : '-',
        'Vaccine Name': order.vaccine.name ? order.vaccine.name : '-',
        'Vaccine Manufacturer': order.vaccine.manufacturer
          ? order.vaccine.manufacturer
          : '-',
        'Vaccine SaleNDC': order.vaccine.saleNdc ? order.vaccine.saleNdc : '-',
        'Purchased Platform': order.purchasePlatform
          ? order.purchasePlatform
          : '-',
        Doses: order.doses
          ? `${order.doses} (${order.packagesAmount} vials)`
          : '-',
        Amount: order.amount ? `$ ${order.amount}` : '$0',
        'Invoice Due': order.invoiceDue
          ? moment(order.invoiceDue).format('MM-DD-YYYY')
          : '-',
        'Order Status': order.status ? order.status : '-',
        'Payment Day': order.paid
          ? moment(order.paidAt).format('MM-DD-YYYY')
          : '-',
        'Statement Date': order.statementDate
          ? moment(order.statementDate).format('MM-DD-YYYY')
          : '-',
      };
    });
    setExcelData(ordersList);
  }, [data]);

  useEffect(() => {
    if (statusSearch === OrderStatuses.ALL) {
      setCriteria({
        paid: { _eq: paidSearch },
        vfc: { _eq: vfcSearch },
        vaccine: {
          name: {
            _eq: selectedVaccine?.name,
          },
        },
        ...(dueSearch !== 'all' && dueSearch === 'due'
          ? { invoiceDue: { _lte: moment() } }
          : dueSearch !== 'all' && dueSearch === 'closeToDue'
          ? {
              _and: [
                {
                  invoiceDue: { _gt: moment() },
                },
                {
                  invoiceDue: { _lte: moment().add(8, 'days') },
                },
              ],
            }
          : {}),
        _or: [
          { status: { _eq: OrderStatuses.ORDERED } },
          { status: { _eq: OrderStatuses.RECEIVED } },
          { status: { _eq: OrderStatuses.CANCELLED } },
          { status: { _eq: OrderStatuses.CLOSED } },
        ],
        ...(orderNumberSearch
          ? { orderNumber: { _eq: orderNumberSearch } }
          : {}),
      });
    } else {
      setCriteria({
        paid: { _eq: paidSearch },
        vfc: { _eq: vfcSearch },
        vaccine: {
          name: {
            _eq: selectedVaccine?.name,
          },
        },
        status: { _eq: statusSearch },
        ...(orderNumberSearch
          ? { orderNumber: { _eq: orderNumberSearch } }
          : {}),
      });
    }
  }, [
    statusSearch,
    paidSearch,
    orderNumberSearch,
    vfcSearch,
    selectedVaccine,
    dueSearch,
  ]);

  const daysLeftTo = (date: Date | undefined) => {
    const dueDate = moment(date);
    return dueDate.diff(moment(), 'days');
  };

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Orders' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Card.Header as="h3" style={{ marginBottom: '0' }}>
                <Icon name="cart" style={{ marginRight: '0.6rem' }} />
                Orders
              </Card.Header>
              <Button
                primary
                content="Add Order"
                onClick={() => setOpen(true)}
                icon="cart"
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: 'full',
                justifyContent: 'right',
                marginTop: '0.4rem',
              }}
            >
              <Button
                color="green"
                inverted
                size="tiny"
                onClick={handleLoadXlsx}
              >
                Download XLS
              </Button>
            </div>
            <Menu borderless>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Menu.Menu
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginLeft: '0 !important',
                  }}
                >
                  <StyledMenuItem>
                    <Menu.Header as="h5" style={{ marginBottom: '0.6rem' }}>
                      Search
                    </Menu.Header>
                    <Input
                      placeholder="Search Order #..."
                      value={orderNumberSearch}
                      onChange={(_, { value }) => {
                        setOrderNumberSearch(value);
                      }}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <Menu.Header as="h5" style={{ marginBottom: '0.1rem' }}>
                      Vaccine
                    </Menu.Header>
                    <VaccinePicker
                      disabled={!!orderData?.vaccine.id}
                      dropdownProps={{
                        fluid: true,
                        placeholder: 'Search Vaccine',
                        clearable: true,
                      }}
                      onChange={({ vaccine }) => setSelectedVaccine(vaccine)}
                      value={selectedVaccine?.id}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <Menu.Header as="h5" style={{ marginBottom: '0.1rem' }}>
                      Status
                    </Menu.Header>
                    <Dropdown
                      onChange={(_, { value }) => {
                        setStatusSearch(value as OrderStatuses);
                      }}
                      placeholder="Select Status"
                      selection
                      value={statusSearch}
                      options={statusOptions}
                    />
                  </StyledMenuItem>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <StyledMenuItem>
                    <Menu.Header as="h5" style={{ marginBottom: '0.1rem' }}>
                      VFC / Private
                    </Menu.Header>
                    <Dropdown
                      onChange={(e, data) => {
                        handleVfcEligibleValue(data.value?.toString() || '');
                      }}
                      placeholder="VFC / Private"
                      selection
                      options={[
                        { text: 'All', value: 'all', key: 'all' },
                        ...vfcOptions,
                      ]}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <Menu.Header as="h5" style={{ marginBottom: '0.1rem' }}>
                      Paid / Not Paid
                    </Menu.Header>
                    <Dropdown
                      onChange={(e, data) => {
                        handlePaidStatusValue(data.value?.toString() || '');
                      }}
                      placeholder="Paid / Not Paid"
                      selection
                      options={[
                        { text: 'All', value: 'all', key: 'all' },
                        ...paidOptions,
                      ]}
                      value={paidSearchValue}
                      disabled={vfcSearch || vfcSearch === undefined}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <Menu.Header as="h5" style={{ marginBottom: '0.1rem' }}>
                      Due / Close to Due
                    </Menu.Header>
                    <Dropdown
                      value={dueSearch}
                      onChange={(_, { value }) => {
                        setDueSearch(value as string);
                      }}
                      placeholder="Due / Close to due"
                      selection
                      options={[
                        { text: 'All', value: 'all', key: 'all' },
                        ...dueOptions,
                      ]}
                    />
                  </StyledMenuItem>
                </Menu.Menu>
              </div>
            </Menu>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Order #</Table.HeaderCell>
                  <Table.HeaderCell>Purchase date</Table.HeaderCell>
                  <Table.HeaderCell>Vaccine info</Table.HeaderCell>
                  <Table.HeaderCell>
                    Purchase platform / Tracking Number
                  </Table.HeaderCell>
                  <Table.HeaderCell>Doses</Table.HeaderCell>
                  <Table.HeaderCell>Amount </Table.HeaderCell>
                  <Table.HeaderCell>
                    Invoice Number / Due / Link
                  </Table.HeaderCell>
                  <Table.HeaderCell>Order status</Table.HeaderCell>
                  <Table.HeaderCell>Payment day</Table.HeaderCell>
                  <Table.HeaderCell>Statement date</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={10}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data ? (
                  data.inventory_orders.map((o, key) => {
                    return (
                      <Table.Row
                        key={key}
                        warning={daysLeftTo(o.estimatedShippingDate) === 0}
                        negative={daysLeftTo(o.estimatedShippingDate) < 0}
                      >
                        <Table.Cell collapsing>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {o.orderNumber}
                            <Label
                              size="tiny"
                              color={o.vfc ? 'orange' : 'teal'}
                              content={o.vfc ? 'VFC' : 'Private'}
                            />
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {moment(o.date).format(DateFormats.DATE)}
                        </Table.Cell>
                        <Table.Cell width={3}>
                          <b>{o.vaccine.name}</b> | {o.vaccine.manufacturer} |{' '}
                          {o.vaccine.saleNdc}
                        </Table.Cell>
                        <Table.Cell>
                          {o.purchasePlatform
                            ? humanizeText(o.purchasePlatform, {
                                capitalize: 'all',
                                delimiter: '_',
                              })
                            : '-'}
                          {o.trackingNumber && (
                            <>
                              <br />
                              {o.trackingNumber}
                              <StyledIcon
                                name="copy"
                                color="grey"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                  handleCopyText(o.trackingNumber);
                                }}
                              />
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <>
                            {o.doses}{' '}
                            <Label size="tiny">{o.packagesAmount} vials</Label>
                          </>
                        </Table.Cell>
                        <Table.Cell>${o.amount}</Table.Cell>
                        <Table.Cell>
                          {o.invoiceNumber ? o.invoiceNumber : '-'}
                          <hr />
                          {o.invoiceDue && daysLeftTo(o.invoiceDue) <= 7 ? (
                            <>
                              {moment(o.invoiceDue).format(DateFormats.DATE)}{' '}
                              <Label size="tiny" color="red" basic>
                                {daysLeftTo(o.invoiceDue) < 0
                                  ? 'Expired'
                                  : `${daysLeftTo(o.invoiceDue)} days left`}
                              </Label>
                            </>
                          ) : (
                            <>{moment(o.invoiceDue).format(DateFormats.DATE)}</>
                          )}

                          <hr />
                          {o.invoiceLink ? (
                            <a
                              href={o.invoiceLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Invoice Link
                            </a>
                          ) : (
                            '-'
                          )}
                        </Table.Cell>
                        <Table.Cell>{o.status}</Table.Cell>
                        <Table.Cell>
                          {o.paidAt
                            ? moment(o.paidAt).format(DateFormats.DATE)
                            : null}
                        </Table.Cell>
                        <Table.Cell>{o.statementDate}</Table.Cell>
                        <Table.Cell singleLine>
                          <Popup
                            content="Edit"
                            size="small"
                            trigger={
                              <Button
                                primary
                                icon="edit"
                                size="mini"
                                onClick={() => {
                                  setOpen(true);
                                  setOrderData({
                                    id: o.id,
                                    practiceId: o.practiceId,
                                    practice: {
                                      timezone:
                                        o.practice?.timezone ||
                                        practice.timezone,
                                    },
                                    date: o.date,
                                    purchasePlatform: o.purchasePlatform,
                                    lot: o.lot,
                                    inventoryExpiration: o.inventoryExpiration,
                                    doses: o.doses,
                                    amount: o.amount,
                                    invoiceNumber: o.invoiceNumber,
                                    invoiceDue: o.invoiceDue,
                                    invoiceLink: o.invoiceLink,
                                    orderNumber: o.orderNumber,
                                    trackingNumber: o.trackingNumber,
                                    paidAt: o.paidAt,
                                    paid: o.paid,
                                    status: o.status,
                                    statusLog: o.statusLog,
                                    inventoryId: o.inventoryId,
                                    inventory: o.inventory,
                                    packagesAmount: o.packagesAmount,
                                    vfc: o.vfc,
                                    statementDate: o.statementDate,
                                    vaccine: {
                                      id: o.vaccine.id,
                                      saleNdc: o.vaccine.saleNdc,
                                      name: o.vaccine.name,
                                      manufacturer: o.vaccine.manufacturer,
                                    },
                                    estimatedShippingDate:
                                      o.estimatedShippingDate,
                                    notes: o.notes,
                                    creditCardId: o.creditCardId,
                                  });
                                }}
                              />
                            }
                          />
                          {o.notes && (
                            <Popup
                              content={o.notes}
                              trigger={
                                <Icon
                                  name="info circle"
                                  size="large"
                                  color="teal"
                                />
                              }
                            />
                          )}
                          <OrderNotificationModal
                            order={o as InventoryOrders}
                            trigger={
                              <Button
                                loading={notificationSentIdsLoading}
                                color={
                                  notificationSentIds?.includes(o.id)
                                    ? 'green'
                                    : 'blue'
                                }
                                icon="send"
                                size="mini"
                              />
                            }
                            onCreate={async (notification: Notification) => {
                              await sendNotificationHandler(notification);
                              try {
                                await notificationSentIdsRefetch?.();
                              } catch (error) {}
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={10}>
                      <Message>No orders found.</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Modal
              size="large"
              onClose={() => closeModal()}
              onOpen={() => setOpen(open)}
              open={open}
              closeIcon
            >
              <Modal.Header>
                {orderData?.id ? 'Edit Order' : 'Create Order'}
              </Modal.Header>
              <Modal.Content>
                <InventoryOrderForm
                  close={() => closeModal()}
                  orderData={orderData}
                  refetchOrders={refetch}
                />
              </Modal.Content>
            </Modal>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
`;

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export default InventoryOrdersScreen;
