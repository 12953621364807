import {
  Button,
  Card,
  Checkbox,
  Dimmer,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';
import {
  Notification,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  VaxSyncIntegrationNotificationContent,
  VaxSyncIntegrationNotificationType,
} from '@bluefox/models/Notification';
import { useApplicationState } from '@bluefox/contexts';
import { useLazyQuery } from '@apollo/client';
import { NotificationsQuery } from '@bluefox/graphql/notifications';
import { useCallback, useEffect, useMemo, useState } from 'react';
import VaxSyncIntegrationNotificationForm, {
  FormValues,
  isFormValid,
} from './VaxSyncIntegrationNotificationForm';
import VaxSyncIntegrationModalMessage from './VaxSyncIntegrationModalMessage';

export type VaxSyncIntegrationNotificationModalData = {
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  practiceId?: string;
  practicePatientId?: string;
  entityRefId?: string;
  vaccinationId?: string;
  vaccineName?: string;
  inventoryVfc?: boolean;
  lot?: string;
};

type Props = VaxSyncIntegrationNotificationModalData & {
  formValues: FormValues;
  notificationType: VaxSyncIntegrationNotificationType;
  open: boolean;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  onClose?: () => void;
  onCreate?: (notification: Notification) => {};
  onEdit?: (
    notification: Notification,
    content: VaxSyncIntegrationNotificationContent,
    newStatusLogItem: any
  ) => {};
};

const VaxSyncIntegrationNotificationModal = ({
  fullPatienName,
  givenAt,
  mrn,
  formValues,
  practiceId,
  practicePatientId,
  notificationType,
  entityRefId,
  vaccinationId,
  vaccineName,
  inventoryVfc,
  lot,
  open,
  setFormValues,
  onClose,
  onCreate,
  onEdit,
}: Props) => {
  const { session } = useApplicationState();
  const [editMode, setEditMode] = useState(false);
  const [enableCreateNewNotification, setEnableCreateNewNotification] =
    useState(false);
  const [viewPreviousNotifications, setViewPreviousNotifications] =
    useState(false);
  const [getNotifications, { data, loading, error }] =
    useLazyQuery<NotificationResponseType>(NotificationsQuery);

  const onOpen = useCallback(async () => {
    setEnableCreateNewNotification(false);
    setViewPreviousNotifications(false);
    try {
      await getNotifications({
        variables: {
          where: {
            entityRefId: { _eq: entityRefId },
          },
        },
      });
    } catch (error) {}
  }, [entityRefId, getNotifications]);

  const [
    notificationExist,
    notificationDoesNotExist,
    notifications,
    areMultipleNotifications,
    notification,
  ] = useMemo(() => {
    const notificationExist =
      data &&
      data.communication_notifications_aggregate.aggregate.count > 0 &&
      !enableCreateNewNotification;
    const notificationDoesNotExist =
      (data &&
        data.communication_notifications_aggregate.aggregate.count === 0) ||
      enableCreateNewNotification;
    const notifications = [...(data?.communication_notifications || [])].sort(
      (a, b) => {
        return (
          new Date(b.createdAt || 0).getTime() -
          new Date(a.createdAt || 0).getTime()
        );
      }
    );
    const areMultipleNotifications =
      data && data.communication_notifications_aggregate.aggregate.count > 1;
    const notification = notifications?.[0];
    return [
      notificationExist,
      notificationDoesNotExist,
      notifications,
      areMultipleNotifications,
      notification,
    ];
  }, [data, enableCreateNewNotification]);

  // Due to onOpen event is not working properly in this modal, there is no other chance to use a useEffect to manage the onOpen modal event.
  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [onOpen, open]);

  return (
    <Modal
      onClose={() => {
        setEditMode(false);
        onClose?.();
      }}
      open={open}
      size="small"
      closeIcon
    >
      <Modal.Header>Data Sync Notification</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error.message}</Message>}
        {loading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        {notificationExist && !editMode && (
          <>
            {viewPreviousNotifications && (
              <div
                style={{
                  maxHeight: '60vh',
                  overflow: 'auto',
                  padding: '1rem',
                  marginBottom: '2rem',
                }}
              >
                {notifications.map((n) => (
                  <Card key={n.id} fluid>
                    <Card.Content>
                      <Card.Description>
                        <VaxSyncIntegrationModalMessage
                          notification={n as Notification}
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
              </div>
            )}
            {!viewPreviousNotifications && (
              <VaxSyncIntegrationModalMessage
                notification={notification as Notification}
              />
            )}
          </>
        )}
        {(notificationDoesNotExist || editMode) && (
          <VaxSyncIntegrationNotificationForm
            fullPatienName={fullPatienName}
            givenAt={givenAt}
            mrn={mrn}
            vaccineName={vaccineName}
            inventoryVfc={inventoryVfc}
            lot={lot}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        )}
        {areMultipleNotifications &&
          !editMode &&
          !enableCreateNewNotification && (
            <Checkbox
              label="View previous notifications"
              checked={viewPreviousNotifications}
              onChange={(_, { checked }) => {
                setViewPreviousNotifications(!!checked);
              }}
            />
          )}
      </Modal.Content>
      <Modal.Actions>
        {!editMode && notificationDoesNotExist && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              const notification: Notification = {
                practiceId,
                practicePatientId,
                createdBy: session?.account?.id,
                content: {
                  type: notificationType,
                  fullPatienName,
                  givenAt,
                  mrn,
                  firstOption: formValues.firstOptionSelection,
                  secondOption: formValues.secondOptionSelection,
                  freeText: formValues.freeText,
                  version: 2,
                  vaccineName,
                  lot,
                  inventoryVfc,
                  typedDOS: formValues.typedDOS,
                  typedVaccineName: formValues.typedVaccineName,
                  typedLot: formValues.typedLot,
                  typedVfc: formValues.typedVfc,
                },
                type: notificationType,
                status: NotificationStatus.pending_approval,
                statusLog: [
                  {
                    action: NotificationStatusLogAction.creation,
                    account: session?.account,
                    updatedAt: new Date(),
                    status: NotificationStatus.pending_approval,
                  },
                ],
                entityRefId,
                vaccinationId,
              };
              onCreate?.(notification);
              setEditMode(false);
              onClose?.();
            }}
          >
            Send
          </Button>
        )}
        {!editMode && notificationExist && (
          <Button
            type="button"
            primary
            disabled={
              !notification ||
              notification.status !== NotificationStatus.pending_approval ||
              viewPreviousNotifications
            }
            onClick={() => {
              const content =
                notification?.content as VaxSyncIntegrationNotificationContent;
              setFormValues({
                firstOptionSelection: content.firstOption,
                version: content.version,
                ...(content.secondOption
                  ? { secondOptionSelection: content.secondOption }
                  : {}),
                ...(content.freeText ? { freeText: content.freeText } : {}),
                typedDOS: content.typedDOS,
                typedVaccineName: content.typedVaccineName,
                typedLot: content.typedLot,
                typedVfc: content.typedVfc,
              });
              setEditMode(true);
            }}
          >
            Edit Notification
          </Button>
        )}
        {editMode && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              function getFormContent(): Partial<VaxSyncIntegrationNotificationContent> {
                return {
                  firstOption: formValues.firstOptionSelection,
                  secondOption: formValues.secondOptionSelection,
                  freeText: formValues.freeText,
                  typedDOS: formValues.typedDOS,
                  typedVaccineName: formValues.typedVaccineName,
                  typedLot: formValues.typedLot,
                  typedVfc: formValues.typedVfc,
                };
              }

              const oldContent =
                notification?.content as VaxSyncIntegrationNotificationContent;
              const newStatusLog = {
                action: NotificationStatusLogAction.contentEdit,
                account: session?.account,
                updatedAt: new Date(),
                status: NotificationStatus.pending_approval,
                oldContent: oldContent,
              };
              const newContent = {
                ...oldContent,
                ...getFormContent(),
              } as VaxSyncIntegrationNotificationContent;
              onEdit?.(notification as Notification, newContent, newStatusLog);
              setEditMode(false);
            }}
          >
            Save
          </Button>
        )}
        {(notification?.status === NotificationStatus.finalized ||
          notification?.status === NotificationStatus.rejected ||
          notification?.status === NotificationStatus.dismissed) &&
          !enableCreateNewNotification && (
            <Button
              secondary
              onClick={() => {
                setFormValues((preValues) => ({ ...preValues, version: 2 }));
                setEnableCreateNewNotification(true);
              }}
            >
              Send a new notification
            </Button>
          )}
        <Button
          type="button"
          secondary
          onClick={() => {
            if (editMode || enableCreateNewNotification) {
              setEditMode(false);
              setEnableCreateNewNotification(false);
            } else {
              onClose?.();
            }
          }}
        >
          {editMode || enableCreateNewNotification ? 'Cancel' : 'Close'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default VaxSyncIntegrationNotificationModal;
