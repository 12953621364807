import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Card, Container, CardContentProps } from 'semantic-ui-react';

import MainLayout from '@ui/MainLayout';
import { COLORS_BY_NAME } from '@bluefox/constants';

const Billing2Screen = () => {
  return (
    <MainLayout path={[{ text: 'Billing' }]}>
      <Container>
        <Card fluid style={{ padding: '2rem 0' }}>
          <Card.Content>
            <Link to="/billing2/control">
              <Billing2ScreenCard content="Control" />
            </Link>
            <Link to="/billing2/general">
              <Billing2ScreenCard content="General" />
            </Link>
            <Link to="/billing2/reporting">
              <Billing2ScreenCard content="Reporting" />
            </Link>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

type Billing2ScreenCardProps = {
  content: CardContentProps['content'];
};

const Billing2ScreenCard = ({ content }: Billing2ScreenCardProps) => {
  return (
    <StyledCard centered>
      <Card.Content textAlign="center">{content}</Card.Content>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  background-color: ${COLORS_BY_NAME['Pacific Blue']} !important;
  color: ${COLORS_BY_NAME['White']} !important;
  font-size: 18px;
  font-weight: 500;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  padding: 1rem !important;
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  &:hover {
    background-color: ${COLORS_BY_NAME['Sea Serpent']} !important;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

export default Billing2Screen;
