import { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  AthenaMappingsDataQuery,
  InsertAthenaMappingMutation,
  UpdateAthenaMappingMutation,
} from '@bluefox/graphql/integrations/athena';
import { PracticeProfileQuery } from '@bluefox/graphql/practices';
import { PracticeProfile } from '@bluefox/models/Practice';
import { MappedItem } from '@bluefox/models/integrations/Athena';
import { VfcCriteria, VFC_OPTIONS } from '@bluefox/models/Insurances';
import { Button, Form } from 'semantic-ui-react';

const PLAN_TYPE = [
  ...VFC_OPTIONS,
  {
    key: 'private',
    value: 'private',
    text: 'Private',
  },
];

interface PracticeProfileData {
  practice: {
    profile: PracticeProfile;
  };
}

interface AthenaVfcCriteriaMappingFormProps {
  close: () => void;
  data: MappedItem | null;
  refetchMappings: () => void;
  practiceId: string | undefined;
}
const VFC_CRITERIA_TYPE = 'vfcCriteria';
const AthenaVfcCriteriaMappingForm = ({
  close,
  data,
  refetchMappings,
  practiceId,
}: AthenaVfcCriteriaMappingFormProps) => {
  const [vfcCriteria, setVfcCriteria] = useState<string>(
    data?.value.data || ''
  );
  const [athenaKey, setAthenaKey] = useState<string>(data?.key || '');

  const { data: practiceProfile } = useQuery<PracticeProfileData>(
    PracticeProfileQuery,
    {
      variables: {
        id: practiceId,
      },
    }
  );

  const [saveInsuranceCompanyMapping] = useMutation(
    data?.id ? UpdateAthenaMappingMutation : InsertAthenaMappingMutation
  );

  const [validateExistingMapping] = useLazyQuery(AthenaMappingsDataQuery);

  const handleSubmit = async () => {
    const criteria = {
      type: {
        _eq: VFC_CRITERIA_TYPE,
      },
      key: {
        _ilike: athenaKey.trim(),
      },
      practiceId: {
        _eq: practiceId,
      },
    };

    try {
      const response = await validateExistingMapping({
        variables: {
          criteria,
        },
      });
      if (response.data.mapping.length > 0) {
        toast({
          title: 'Mapping already exists',
          type: 'error',
          time: 5000,
        });
        return;
      }

      await saveInsuranceCompanyMapping({
        variables: {
          id: data?.id,
          practiceId: data?.practice?.id || practiceId,
          key: athenaKey.trim(),
          type: 'vfcCriteria',
          value: {
            data: vfcCriteria,
          },
        },
      });
      toast({
        title: 'Mapping saved successfully',
        type: 'success',
        time: 1000,
      });
      close();
      refetchMappings();
    } catch (error) {
      console.error(error);
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={athenaKey}
            onChange={(_, { value }) => setAthenaKey(value)}
            fluid
            label="Insurance Type"
            placeholder="Insurance Type"
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Select VFC Criteria</label>
          <Form.Select
            placeholder="VFC Criteria"
            clearable
            required
            value={vfcCriteria}
            onChange={(_, { value }) => setVfcCriteria(value as VfcCriteria)}
            options={
              practiceProfile?.practice.profile.address?.state === 'NY'
                ? [
                    ...PLAN_TYPE,
                    {
                      key: 'ch_plus_b',
                      value: 'ch_plus_b',
                      text: 'CHPlusB',
                    },
                  ]
                : PLAN_TYPE
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button primary type="submit" content="Save" icon="save" />
      </Form.Field>
    </Form>
  );
};

export default AthenaVfcCriteriaMappingForm;
