import React from 'react';
import MainLayout from '@ui/MainLayout';
import { useQuery } from '@apollo/client';
import { GetVfcClaimsInconsistensiesForSummaryDetails } from '@bluefox/graphql/billing';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { Message, Table } from 'semantic-ui-react';
import BorrowingSummaryDetailClaimRow from './BorrowingSummaryDetailClaimRow';
import { useParams } from 'react-router-dom';
import { usePractice } from '@bluefox/contexts/Practice';

interface SummaryDetailsClaims {
  sourceClaims: VFCInconsistency[];
  targetClaims: VFCInconsistency[];
}

const BorrowingSummaryDetailClaims = () => {
  const { id } = useParams<{ id: string }>();
  const practice = usePractice();

  const { data } = useQuery<SummaryDetailsClaims>(
    GetVfcClaimsInconsistensiesForSummaryDetails,
    {
      variables: {
        _id: id,
      },
    }
  );

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        {
          text: 'Borrowing Summary',
          to: `/practices/${practice.handler}/borrowing-summary`,
        },
        { text: `Borrowing Summary Detail Claims` },
      ]}
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Source/Target
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Patient
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Insurance
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Service Date
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Inventory Used <hr /> NDC
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Vaccine <hr /> Lot
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Solution Type
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              rowSpan="1"
              width={1}
              textAlign="center"
            ></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {!!data?.sourceClaims.length || !!data?.targetClaims.length ? (
          <Table.Body>
            {!!data?.sourceClaims.length
              ? data?.sourceClaims.map((inconsistency) => {
                  return (
                    <BorrowingSummaryDetailClaimRow
                      inconsistency={inconsistency}
                      sourceTarget="source"
                    />
                  );
                })
              : null}
            {!!data?.targetClaims.length
              ? data?.targetClaims.map((inconsistency) => {
                  return (
                    <BorrowingSummaryDetailClaimRow
                      inconsistency={inconsistency}
                      sourceTarget="target"
                    />
                  );
                })
              : null}
          </Table.Body>
        ) : (
          <Table.Body>
            <Table.Cell colSpan={9}>
              <Message>No inconsistencies found for this detail.</Message>
            </Table.Cell>
          </Table.Body>
        )}
      </Table>
    </MainLayout>
  );
};

export default BorrowingSummaryDetailClaims;
