import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertNotificationMutation,
  UpdateNotificationContentMutation,
} from '@graphql/communications';
import {
  Notification,
  NotificationContent,
} from '@bluefox/models/Notification';

export const useNotifications = () => {
  const [sendNotification] = useMutation(InsertNotificationMutation);
  const [updateNotificationContent] = useMutation(
    UpdateNotificationContentMutation
  );

  const sendNotificationHandler = useCallback(
    async (notification: Notification) => {
      try {
        await sendNotification({
          variables: {
            notification: notification,
          },
        });
        toast({
          title: `Notification sent`,
          type: 'success',
          time: 1000,
        });
      } catch (e) {
        toast({
          title: `Send Notification Failed. Error: ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [sendNotification]
  );

  const editNotificationHandler = useCallback(
    async (
      notification: Notification,
      content: NotificationContent,
      newStatusLogItem: any
    ) => {
      try {
        await updateNotificationContent({
          variables: {
            notificationId: notification.id,
            content,
            statusLogItem: newStatusLogItem,
          },
        });
        toast({
          title: `Notification Updated`,
          type: 'success',
          time: 1000,
        });
      } catch (e) {
        toast({
          title: `Update Notification Failed. Error: ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [updateNotificationContent]
  );

  return {
    sendNotificationHandler,
    editNotificationHandler,
  };
};
