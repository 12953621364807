import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertMappingsMutation,
  UpdateMappingMutation,
  allowedVaxAmdinQuery,
} from '@graphql/mappings';
import { MappedItem } from '@bluefox/models/Mappings';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import { Button, Form, Message } from 'semantic-ui-react';

interface PracticeData {
  id: string;
  name: string;
}

interface EcwAdministratedByMappingFormProps {
  close: () => void;
  practices?: PracticeData[];
  data: MappedItem | null;
  refetchMappings: () => void;
}

const EcwAdministratedByMappingForm = ({
  close,
  practices,
  data,
  refetchMappings,
}: EcwAdministratedByMappingFormProps) => {
  const [practiceId, setPracticeId] = useState(data ? data.practice.id : '');
  const [isEmailInputFocused, setIsEmailInputFocused] = useState(true);
  const [email, setEmail] = useState<string>(data ? data.key : '');
  const [emailWarning, setEmailWarning] = useState(false);
  const [keyEmr, setKeyEmr] = useState<string>(
    data?.metadata[0].keyEmr ? data.metadata[0].keyEmr : ''
  );
  const [nameEmr, setNameEmr] = useState<string>(
    data?.metadata[0].nameEmr ? data.metadata[0].nameEmr : ''
  );
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);

  const [allowedVaxAdmin] = useLazyQuery(allowedVaxAmdinQuery, {
    variables: {
      practiceId: practiceId,
    },
  });

  const [saveVaccineMapping] = useMutation(
    data?.id ? UpdateMappingMutation : InsertMappingsMutation
  );

  const handleSubmit = () => {
    saveVaccineMapping({
      variables: {
        id: data?.id,
        practiceId,
        key: email,
        metadata: [
          {
            keyEmr,
            nameEmr,
          },
        ],
        type: 'givenby',
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const validateEmail = (emails: string[], email: string) => {
    const validEmails = emails.map((item: any) => item.email);
    if (!validEmails.includes(email)) {
      setEmailWarning(true);
    } else {
      setEmailWarning(false);
    }
  };

  useEffect(() => {
    if (!practices || practices.length < 1) return;

    setPracticeOptions(
      practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    if (isEmailInputFocused || !email) return;
    allowedVaxAdmin().then((r) => {
      validateEmail(r.data.accounts, email);
    });
  }, [isEmailInputFocused, email]);

  return (
    <Form
      onSubmit={handleSubmit}
      data-automation-id="administrated-by-mapping-form"
    >
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Dropdown
            label="Practice"
            style={{ minWidth: '15rem' }}
            placeholder="Select practice"
            fluid
            selection
            value={practiceId}
            onChange={(e, data) => {
              setPracticeId(data.value?.toString() || '');
            }}
            options={practiceOptions}
            required
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            onBlur={() => setIsEmailInputFocused(false)}
            onFocus={() => setIsEmailInputFocused(true)}
            value={email}
            onChange={(_, { value }) => setEmail(value)}
            fluid
            label="Email"
            placeholder="Email"
            required
            data-automation-id="administrated-by-mapping-form-email"
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={keyEmr}
            onChange={(_, { value }) => setKeyEmr(value)}
            fluid
            label="ID (EMR)"
            placeholder="ID (EMR)"
            required
            data-automation-id="administrated-by-mapping-form-key-emr"
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={nameEmr}
            onChange={(_, { value }) => setNameEmr(value)}
            fluid
            label="Name (EMR)"
            placeholder="Name (EMR)"
            required
            data-automation-id="administrated-by-mapping-form-name-emr"
          />
        </Form.Field>
      </Form.Group>
      {emailWarning && (
        <Message
          negative
          icon="warning sign"
          header="Email not valid"
          content="The user is not authorized to administer vaccines."
        />
      )}
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={close}
          type="button"
          data-automation-id="administrated-by-mapping-form-cancel-button"
        >
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          data-automation-id="administrated-by-mapping-form-save-button"
          disabled={emailWarning}
        />
      </Form.Field>
    </Form>
  );
};

export default EcwAdministratedByMappingForm;
