import React from 'react';
import { Pagination } from 'semantic-ui-react';

type Props = {
  activePage: number;
  totalPages: number;
  onPageChange: (newActivePage: number) => void;
};

const VaccinePagination = ({ activePage, totalPages, onPageChange }: Props) => {
  return (
    <Pagination
      activePage={activePage}
      totalPages={totalPages}
      onPageChange={(_, data) => {
        onPageChange(data.activePage as number);
      }}
    />
  );
};

export default VaccinePagination;
