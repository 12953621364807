import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  Icon,
  Input,
  Modal,
  Radio,
  Table,
  Transition,
} from 'semantic-ui-react';
import Moment from 'react-moment';
import { toast } from 'react-semantic-toasts';

import GS1 from '@bluefox/lib/gs1';

import { Inventory } from '@bluefox/models/Inventory';
import { Vaccine } from '@bluefox/models/Vaccine';
import { useInventory } from '@bluefox/store/inventory';
import { useVaccinesByNdc10s } from 'store/vaccines';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import GS1GunScanner from '@bluefox/ui/GS1GunScanner';

const SaveInventoryBatchMutation = gql`
  mutation SaveInventoryBatchMutation($entries: [inventories_insert_input!]!) {
    insert_inventories(
      objects: $entries
      on_conflict: { constraint: inventories_pkey, update_columns: [doses] }
    ) {
      affected_rows
    }
  }
`;

const SaveInventoryOrderWithInventoryBatchMutation = gql`
  mutation SaveInventoryOrderWithInventoryBatchMutation(
    $entries: [inventory_orders_insert_input!]!
  ) {
    insert_inventory_orders(objects: $entries) {
      affected_rows
    }
  }
`;

interface Entry {
  code: string;
  lot: string;
  gs1: GS1;
  vfc: boolean;
  doses: number;
  vaccine?: Vaccine;
  inventory?: Inventory;
  _animationSwitch: boolean;
  _scannedTs: number;
}

interface InventoryBatchModalProps {
  onSave?: () => void;
}

const InventoryBatchModal = ({ onSave }: InventoryBatchModalProps) => {
  const practice = usePractice();
  const { session } = useApplicationState();
  const [open, setOpen] = useState(false);
  const [jumpToEntry, setJumpToEntry] = useState(false);
  const [ready, setReady] = useState<boolean>(false);

  const [vfc, setVfc] = useState<boolean | undefined>(false);
  const [entries, setEntries] = useState<{ [lot: string]: Entry }>({});

  const [entryInventories, setEntryInventories] = useState<{
    [lot: string]: Inventory[];
  }>({});

  const [entriesList, setEntriesList] = useState<Entry[]>([]);
  const [shouldCreateOrder, setShouldCreateOrder] = useState<boolean>(true);

  const {
    vaccinesRecord,
    addEntry: addVaccineEntry,
    cleanEntries: cleanVaccineEntries,
  } = useVaccinesByNdc10s();
  const {
    entries: inventoryEntries,
    addEntry: addInventoryEntry,
    cleanEntries: cleanInventoryEntries,
  } = useInventory();

  const getMutationToUse = () => {
    return shouldCreateOrder
      ? SaveInventoryOrderWithInventoryBatchMutation
      : SaveInventoryBatchMutation;
  };

  const [saveInventoryBatch] = useMutation(getMutationToUse());

  const updateEntries = useCallback(
    (_entries) => {
      if (JSON.stringify(_entries) === JSON.stringify(entries)) return;
      setEntries(_entries);
    },
    [entries]
  );

  const handleonScan = useCallback(
    (gs1: GS1, code: string) => {
      const lot = gs1.getLot();

      if (!lot) return;

      if (entries[lot]) {
        if (jumpToEntry) {
          setEntries({
            ...entries,
            [lot]: {
              ...entries[lot],
              _animationSwitch: !entries[lot]._animationSwitch,
            },
          });
        }
        return;
      }

      addInventoryEntry(code);
      addVaccineEntry(gs1.getNdc());
      setEntries({
        ...entries,
        [lot]: {
          lot,
          code,
          gs1,
          vfc: !!vfc,
          doses: 0,
          _scannedTs: new Date().getTime(),
          _animationSwitch: true,
        },
      });
    },
    [vfc, entries, jumpToEntry, addInventoryEntry, addVaccineEntry]
  );

  const handleEntryChange = useCallback(
    (lot: string, e: Partial<Entry>) => {
      const _entries = { ...entries };
      _entries[lot] = {
        ..._entries[lot],
        ...e,
      };
      setEntries(_entries);
    },
    [entries]
  );

  const handleEntryRemove = useCallback(
    (lot: string) => {
      const _entries = { ...entries };
      delete _entries[lot];
      setEntries(_entries);
    },
    [entries]
  );

  const buildRow = (e: any) => {
    const now = new Date();

    if (shouldCreateOrder) {
      //create Inventory Orders with Inventory
      return {
        practiceId: practice.id,
        date: now,
        lot: e.gs1.getLot(),
        doses: e.doses,
        vfc: e.vfc,
        inventoryExpiration: e.gs1.getExp(),
        statusLog: [
          {
            status: 'closed',
            date: now,
            practiceAccount: session?.account,
          },
        ],
        status: 'closed',
        amount: 0,
        packagesAmount: 0,
        paid: false,
        orderNumber: 'onboarding',
        vaccineId: e.vaccine?.id,
        inventory: {
          data: {
            ...(e.inventory ? { id: e.inventory.id } : {}),
            practiceId: practice.id,
            vaccineId: e.vaccine?.id,
            lot: e.gs1.getLot(),
            expiration: e.gs1.getExp(),
            doses: e.doses,
            vfc: e.vfc,
            status: 'received',
          },
          on_conflict: {
            constraint: 'inventories_pkey',
            update_columns: ['doses'],
          },
        },
      };
    }

    //create Only Inventory
    return {
      practiceId: practice.id,
      ...(e.inventory ? { id: e.inventory.id } : {}),
      vaccineId: e.vaccine?.id,
      lot: e.gs1.getLot(),
      expiration: e.gs1.getExp(),
      doses: e.doses,
      vfc: e.vfc,
      status: 'received',
    };
  };

  const handleOnSaveClick = () => {
    const _entries = entriesList
      .filter((e) => !!e.vaccine)
      .map((e) => buildRow(e));

    saveInventoryBatch({
      variables: {
        entries: _entries,
      },
    })
      .then(() => {
        toast({
          title: 'Inventory saved',
          color: 'green',
          time: 1000,
        });
        if (onSave) onSave();
        cleanAndClose();
      })
      .catch(() => {
        toast({
          title: 'Failed saving Inventory',
          color: 'red',
          time: 1000,
        });
      });
  };

  const handleCancelOnClick = () => {
    if (entriesList.length === 0) {
      cleanAndClose();
      return;
    }

    if (window.confirm('Are you sure you want to cancel?')) {
      cleanAndClose();
    }
  };

  const cleanAndClose = () => {
    setEntries({});
    setReady(false);
    cleanVaccineEntries();
    cleanInventoryEntries();
    setOpen(false);
    setShouldCreateOrder(true);
  };

  useEffect(() => {
    if (vfc === undefined) return;
    const _entries = Object.entries(entries).reduce(
      (acc, [lot, entry]) => {
        return {
          ...acc,
          [lot]: {
            ...entry,
            vfc,
          },
        };
      },
      { ...entries }
    );
    updateEntries(_entries);
  }, [vfc]);

  useEffect(() => {
    if (!Object.keys(vaccinesRecord).length) return;

    const _entries = Object.entries(entries).reduce(
      (acc, [lot, entry]) => {
        const ndc = entry.gs1.getNdc();
        let vaccine;
        if (ndc) {
          if (vaccinesRecord[ndc]) {
            vaccine = vaccinesRecord[ndc];
          } else {
            vaccine = Object.values(vaccinesRecord).find(
              (vr) => vr.useNdc10?.includes(ndc)
            );
          }
        }

        return {
          ...acc,
          [lot]: {
            ...entry,
            vaccine,
          },
        };
      },
      { ...entries }
    );

    updateEntries(_entries);
  }, [vaccinesRecord, entries]);

  useEffect(() => {
    if (!inventoryEntries.length) return;

    const _entryInventories = inventoryEntries.reduce<{
      [lot: string]: Inventory[];
    }>((acc, ientry) => {
      const inventories =
        acc[ientry.inventory.lot] &&
        acc[ientry.inventory.lot].findIndex(
          (i) => i.id === ientry.inventory.id
        ) === -1
          ? [...acc[ientry.inventory.lot], ientry.inventory]
          : [ientry.inventory];

      return {
        ...acc,
        [ientry.inventory.lot]: inventories,
      };
    }, entryInventories);

    if (JSON.stringify(_entryInventories) === JSON.stringify(entryInventories))
      return;

    setEntryInventories(_entryInventories);
  }, [inventoryEntries, entryInventories]);

  useEffect(() => {
    const _entries = Object.values(entries);

    if (!_entries.length) {
      setEntriesList([]);
      setReady(false);
      return;
    }

    setEntriesList(_entries.sort((a, b) => a._scannedTs - b._scannedTs));
    setVfc(
      _entries.some((e) => e.vfc !== _entries[0].vfc)
        ? undefined
        : _entries[0].vfc
    );

    setReady(_entries.every((e) => !!e.vaccine && e.doses !== undefined));
  }, [entries]);

  return (
    <Modal
      size="large"
      closeOnDimmerClick={false}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleCancelOnClick}
      trigger={<Dropdown.Item content="Add Entries" icon="boxes" />}
    >
      <Modal.Header>
        <Grid columns={3} verticalAlign="middle">
          <Grid.Column>Add Inventory Entries</Grid.Column>
          <Grid.Column textAlign="right">
            <GS1GunScanner
              data-automation-id={`scan-button`}
              component={Button}
              renderOn={
                <>
                  <Icon name="stop" />
                  Scanning
                </>
              }
              renderOff={
                <>
                  <Icon name="qrcode" />
                  Scan
                </>
              }
              onGS1Scan={handleonScan}
              onError={() => {}}
              icon
              primary
              size="big"
              labelPosition="left"
            />
          </Grid.Column>

          <Grid.Column>
            <Radio
              toggle
              label={'Create Order'}
              onChange={(e, { checked }) =>
                setShouldCreateOrder(checked || false)
              }
              checked={shouldCreateOrder}
            ></Radio>

            <Radio
              toggle
              label="Jump to already scanned entries"
              checked={jumpToEntry}
              onChange={(_, { checked }) => setJumpToEntry(checked ?? false)}
            />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content scrolling>
        <Table columns={7}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Lot</Table.HeaderCell>
              <Table.HeaderCell>Expiration</Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
              <Table.HeaderCell>
                VFC{' '}
                <Checkbox
                  label="(mark all)"
                  indeterminate={vfc === undefined}
                  checked={vfc === undefined ? undefined : vfc}
                  onClick={(_, { checked }) => setVfc(checked)}
                />
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!entriesList.length ? (
              entriesList.map((entry) => (
                <EntryRow
                  key={entry.code}
                  entry={entry}
                  inventories={entryInventories[entry.lot]}
                  onEntryChange={(e) => handleEntryChange(entry.lot, e)}
                  onRemove={() => handleEntryRemove(entry.lot)}
                />
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan="7">
                  Scan to add Inventory Entries
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={!!entriesList.length ? 'Cancel' : 'Close'}
          icon="cancel"
          onClick={handleCancelOnClick}
          negative={!!entriesList.length}
        />
        <Button
          primary
          content="Save"
          icon="save"
          disabled={!ready}
          onClick={handleOnSaveClick}
        />
      </Modal.Actions>
    </Modal>
  );
};

interface EntryRowProps {
  entry: Entry;
  inventories?: Inventory[];
  onEntryChange: (entry: Partial<Entry>) => void;
  onRemove: () => void;
}

const EntryRow = ({
  entry,
  inventories,
  onEntryChange,
  onRemove,
}: EntryRowProps) => {
  const [vfc, setVfc] = useState(entry.vfc);

  const [inventory, setInventory] = useState<Inventory | undefined>(
    inventories?.find((i) => i.vfc === vfc)
  );

  const [doses, setDoses] = useState(inventory?.doses);

  useEffect(() => {
    setInventory(inventories?.find((i) => i.vfc === vfc));
  }, [inventories, vfc]);

  useEffect(() => {
    setDoses(inventory?.doses || 0);
    onEntryChange({
      inventory: inventory,
    });
  }, [inventory]);

  useEffect(() => {
    setVfc(entry.vfc);
  }, [entry.vfc]);

  useEffect(() => {
    const _entry: Partial<Entry> = {};
    if (doses !== entry.doses) _entry.doses = doses;
    if (vfc !== entry.vfc) _entry.vfc = vfc;
    onEntryChange(_entry);
  }, [doses, vfc]);

  const inputRef = useRef<Input>(null);
  const [animationSwitch, setAnimationSwitch] = useState(
    entry._animationSwitch
  );

  useEffect(() => {
    if (inputRef.current && entry._animationSwitch !== animationSwitch) {
      setAnimationSwitch(entry._animationSwitch);
      inputRef.current.focus();
    }
  }, [entry._animationSwitch]);

  const { vaccine } = entry;

  return (
    <Table.Row warning={!!inventory} disabled={!vaccine}>
      <Table.Cell>{vaccine?.name}</Table.Cell>
      <Table.Cell>{entry.gs1.getLot()}</Table.Cell>
      <Table.Cell>
        <Moment format="YYYY-MM-DD">{entry.gs1.getExp()}</Moment>
      </Table.Cell>
      <Table.Cell>
        <Transition animation="shake" duration={1000} visible={animationSwitch}>
          <Input
            ref={inputRef}
            fluid
            type="number"
            value={doses ?? ''}
            onChange={(_, { value }) =>
              setDoses(value ? parseInt(value) : undefined)
            }
            id={entry.lot}
          />
        </Transition>
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          toggle
          checked={vfc}
          onClick={(_, { checked }) => setVfc(checked || false)}
        />
      </Table.Cell>
      <Table.Cell>
        {!!inventory && (
          <>
            <Icon name="warning sign" />
            Existing inventory
          </>
        )}
      </Table.Cell>
      <Table.Cell width={'1'}>
        <Button basic color="orange" icon="trash" onClick={onRemove} />
      </Table.Cell>
    </Table.Row>
  );
};

export default InventoryBatchModal;
