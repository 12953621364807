import EchartsContainer from '@bluefox/ui/EchartsContainer';

export type SerieDataType = {
  name: string;
  value: number;
  color?: string;
};

type Props = {
  colors?: string[];
  chartName: string;
  series: SerieDataType[];
};

const PieBarTwoSeriesChart = ({ chartName, series, colors }: Props) => {
  return (
    <EchartsContainer
      options={{
        tooltip: {
          trigger: 'item',
        },
        color: colors,
        series: [
          {
            top: '-15%',
            left: '-20%',
            right: '-20%',
            bottom: '-15%',
            name: chartName,
            type: 'pie',
            radius: ['40%', '70%'],
            label: {
              show: false,
              position: 'center',
            },
            data: series.map((d) => ({
              name: d.name,
              value: d.value,
            })),
          },
        ],
      }}
    />
  );
};

export default PieBarTwoSeriesChart;
