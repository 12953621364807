import { BalanceMovements } from '@bluefox/models/BalanceMovements';
import {
  Button,
  Card,
  Dropdown,
  Placeholder,
  Popup,
  Segment,
  TableCell,
  TableRow,
} from 'semantic-ui-react';

type BalanceMovementTableProps = {
  balanceMovement: BalanceMovements;
  onShowPDF: (invoiceId: string) => void;
};

export const BalanceMovementsTableRow = ({
  balanceMovement,
  onShowPDF,
}: BalanceMovementTableProps) => {
  return (
    <TableRow>
      <TableCell>{balanceMovement.date}</TableCell>
      <TableCell>{balanceMovement.organization?.name}</TableCell>
      <TableCell>{balanceMovement.practice?.name}</TableCell>
      <TableCell>{balanceMovement.type}</TableCell>
      <TableCell>{balanceMovement.reason}</TableCell>
      <TableCell>$ {balanceMovement.amount}</TableCell>
      <TableCell>$ {balanceMovement.currentBalance}</TableCell>
      <TableCell>
        {balanceMovement.invoice?.status || 'No Invoice Related'}
      </TableCell>
      <TableCell>{`$ ${balanceMovement.amountToPay || 0}`}</TableCell>
      <TableCell>
        <Dropdown
          icon={null}
          trigger={<Button size="mini" icon="ellipsis horizontal" />}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={!balanceMovement.invoice}
              content="Show Invoice"
              icon="file pdf outline"
              onClick={() => {
                onShowPDF?.(balanceMovement.invoice?.id as string);
              }}
            />
            <Popup
              on="click"
              trigger={
                <Dropdown.Item
                  onClick={(event, data) => {
                    event.stopPropagation();
                  }}
                  disabled={
                    !balanceMovement.comment || balanceMovement.comment === ''
                  }
                  content="Comment"
                  icon="file text"
                />
              }
              content={
                <Card>
                  <Card.Content>{balanceMovement.comment}</Card.Content>
                </Card>
              }
            />
          </Dropdown.Menu>
        </Dropdown>
      </TableCell>
    </TableRow>
  );
};
