import {
  Table,
  Divider,
  Grid,
  Header,
  Segment,
  Message,
} from 'semantic-ui-react';

import { ServiceLine } from '@bluefox/models/ClaimPreview';

interface ServiceLinesProps {
  serviceLines: ServiceLine[];
}

const ServiceLines: React.FC<ServiceLinesProps> = ({ serviceLines }) => {
  return (
    <Segment>
      <Header as="h3"> Service Lines </Header>
      <Divider />
      {serviceLines?.length ? (
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Table celled striped structured selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell rowSpan="2">
                      Date of service
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">
                      Place of Service
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">
                      Diagnosis Pointer
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">Charges</Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">Units</Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">
                      Rendering provider ID
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="3" textAlign="center">
                      Procedure Codes
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Cpt Codes</Table.HeaderCell>
                    <Table.HeaderCell>Modifier</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {serviceLines.map(
                    (
                      {
                        serviceDate,
                        professionalService,
                        renderingProviderId,
                        placeOfService,
                      },
                      index
                    ) => (
                      <Table.Row key={index}>
                        <Table.Cell>{serviceDate}</Table.Cell>
                        <Table.Cell>{placeOfService}</Table.Cell>
                        <Table.Cell>
                          {professionalService.diagnosisPointer}
                        </Table.Cell>
                        <Table.Cell>
                          ${professionalService.lineItemChargeAmount}
                        </Table.Cell>
                        <Table.Cell>
                          {professionalService.serviceUnitCount}
                        </Table.Cell>
                        <Table.Cell>{renderingProviderId}</Table.Cell>
                        <Table.Cell>
                          {professionalService.procedureCode}
                        </Table.Cell>
                        <Table.Cell>
                          {professionalService.procedureModifiers?.join(',')}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Message warning>
          <Message.Header>No data to show</Message.Header>
        </Message>
      )}
    </Segment>
  );
};

export default ServiceLines;
