import { usDollarCurrency } from '@bluefox/lib/formatters';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

const totalClaimsStyle = StyleSheet.create({
  section: {
    marginTop: 40,
  },
  titleSection: {
    padding: '10 8',
    border: '1px solid #BCCBDC',
    borderLeft: 0,
    borderRight: 0,
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  textSection: {
    padding: '10 8',
    textAlign: 'right',
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  textHighlighted: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
});

type Props = {
  total: number;
};

const TotalClaims = ({ total }: Props) => (
  <View style={totalClaimsStyle.section}>
    <View style={totalClaimsStyle.titleSection}>
      <Text style={totalClaimsStyle.title}>Total Claims</Text>
    </View>
    <View style={totalClaimsStyle.textSection}>
      <Text style={totalClaimsStyle.text}>
        Total{' '}
        <Text style={totalClaimsStyle.textHighlighted}>
          {usDollarCurrency(total)}
        </Text>
      </Text>
    </View>
  </View>
);

export default TotalClaims;
