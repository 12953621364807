import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Container,
  Form,
  Menu,
  Modal,
  Popup,
  Table,
} from 'semantic-ui-react';
import {
  GetOnboardingJobs,
  GetOnboardingStatuses,
  RemoveImmunizationsAndJob,
} from '@graphql/vaccinations';
import { Link } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
import { usePractice } from '@bluefox/contexts/Practice';
import MainLayout from '@ui/MainLayout';
import Pagination from '@bluefox/ui/Pagination';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';
import { Enum } from '@bluefox/models/Generics';
import { Option } from './UpdateVaccinesCsvTableJobDetails';

export interface Job {
  id: string;
  status: string;
  type: string;
  uploadNumber: number;
  createdAt: Date;
  immunizations_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

const UpdateVaccinesCsvTable = () => {
  const practice = usePractice();
  const [page, setPage] = useState(1);
  const [jobIdToRemove, setJobIdToRemove] = useState('');
  const [open, setOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState<Enum[]>([]);
  const [statusSearch, setStatusSearch] = useState<string>('allStatus');
  const ENTRIES_PER_PAGE = 15;

  const [removeJob] = useMutation(RemoveImmunizationsAndJob);

  const { data, refetch } = useQuery(GetOnboardingJobs, {
    variables: {
      criteria: {
        practiceId: { _eq: practice.id },
        type: { _eq: 'add_immunizations' },
        status: statusSearch !== 'allStatus' ? { _eq: statusSearch } : {},
      },
      limit: ENTRIES_PER_PAGE,
      offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
    },
    fetchPolicy: 'network-only',
  });

  useQuery(GetOnboardingStatuses, {
    onCompleted(data) {
      setStatusOptions(
        data.statusEnum.map((status: Option) => {
          return {
            value: status.value,
            text: status.comment,
          };
        })
      );
    },
  });

  const handleClose = () => {
    setOpen(false);
    setJobIdToRemove('');
  };

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        {
          text: 'Vaccine Update CSV',
          to: `/practices/${practice.handler}/updateVaccinationsCsv`,
        },
        { text: 'Vaccine Update Table' },
      ]}
    >
      <Container>
        <Menu borderless style={{ display: 'flex' }}>
          <Menu.Menu>
            <Menu.Item>
              <Form>
                <Form.Dropdown
                  label="Filter by Status"
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by Status"
                  selection
                  value={statusSearch}
                  onChange={(e, { value }) => {
                    setStatusSearch(value as string);
                    setPage(1);
                  }}
                  options={[
                    { text: 'All Status', value: 'allStatus' },
                    ...statusOptions,
                  ]}
                />
              </Form>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Table celled structured selectable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Upload Number</Table.HeaderCell>
              <Table.HeaderCell>Upload Date</Table.HeaderCell>
              <Table.HeaderCell>Immunizations</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.onboardingJobs.map((j: Job) => (
              <Table.Row key={j.id} textAlign="center">
                <Table.Cell>{j.uploadNumber}</Table.Cell>
                <Table.Cell>
                  {formatDatetimeToMMDDYYY(j.createdAt).replaceAll('-', '/')}
                </Table.Cell>
                <Table.Cell>
                  {j.immunizations_aggregate.aggregate.count}
                </Table.Cell>
                <Table.Cell>
                  {humanizeText(j.status, {
                    capitalize: 'all',
                  })}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    primary
                    className="min-component-vertical-margin"
                    icon="eye"
                    size="tiny"
                    as={Link}
                    to={`/practices/${practice.handler}/updateVaccinationsCsvTable/${j.id}`}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Popup
                    content="Remove"
                    trigger={
                      <Button
                        color="red"
                        className="min-component-vertical-margin"
                        icon="remove"
                        size="tiny"
                        disabled={j.status !== 'pending'}
                        onClick={() => {
                          setJobIdToRemove(() => j.id);
                          setOpen(true);
                        }}
                      />
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Pagination
            total={total}
            colSpan={5}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
        <Modal size="mini" open={open} onClose={handleClose}>
          <Modal.Header>Job Removal</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to remove this job?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              negative
              onClick={() => {
                removeJob({
                  variables: {
                    id: jobIdToRemove,
                  },
                  onCompleted: () => refetch(),
                })
                  .then((r) => {
                    toast({
                      title: 'Job Removed Correctly',
                      type: 'success',
                      time: 1000,
                    });
                  })
                  .catch((e) => {
                    toast({
                      title: `Callback error: ${e}`,
                      type: 'error',
                      time: 5000,
                    });
                  });
                handleClose();
              }}
            >
              Remove
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    </MainLayout>
  );
};

export default UpdateVaccinesCsvTable;
