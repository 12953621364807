import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { MappedHolder } from '@bluefox/models/Mappings';
import {
  InsertHolderMappingMutation,
  UpdateHolderMappingMutation,
} from '@graphql/mappings';
import { Button, Form } from 'semantic-ui-react';
import { InsuranceRelationShips } from '@bluefox/models/Insurances';

interface HolderMappingFormProps {
  close: () => void;
  practiceId?: string;
  data: MappedHolder | null;
  refetchMappings: () => void;
}

const HolderMappingForm = ({
  close,
  practiceId,
  data,
  refetchMappings,
}: HolderMappingFormProps) => {
  const [holderEmr, setHolderEmr] = useState<string>(data?.nameEmr || '');
  const [holderCanid, setHolderCanid] = useState<string>(
    data?.holderName || ''
  );

  const [saveHolderMapping] = useMutation(
    data?.id ? UpdateHolderMappingMutation : InsertHolderMappingMutation
  );

  const handleSubmit = () => {
    saveHolderMapping({
      variables: {
        id: data?.id,
        practiceId,
        holderName: holderCanid,
        nameEmr: holderEmr,
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={holderEmr}
            onChange={(_, { value }) => setHolderEmr(value)}
            fluid
            label="Holder (EMR)"
            placeholder="Holder (EMR)"
            required
          />
        </Form.Field>
        <Form.Field required>
          <Form.Dropdown
            fluid
            selection
            value={holderCanid}
            onChange={(_, { value }) => {
              setHolderCanid(value as string);
            }}
            label="Relationship with Holder"
            options={[
              { text: 'Self', value: InsuranceRelationShips.Self },
              { text: 'Spouse', value: InsuranceRelationShips.Spouse },
              { text: 'Child', value: InsuranceRelationShips.Child },
              { text: 'Other', value: InsuranceRelationShips.Other },
            ]}
            required
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          disabled={!holderCanid}
        />
      </Form.Field>
    </Form>
  );
};

export default HolderMappingForm;
