import React, { CSSProperties } from 'react';
import { Pagination as SemanticPagination, Table } from 'semantic-ui-react';

type Props = {
  colSpan: number;
  total: number;
  position: 'center' | 'left' | 'right';
  activePage: number;
  totalPages: number;
  onPageChange: (newActivePage: number) => void;
  style?: CSSProperties;
};

const Pagination = ({
  total,
  colSpan,
  activePage,
  position,
  totalPages,
  onPageChange,
  style,
}: Props) => {
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell>Total: {total}</Table.HeaderCell>
        <Table.HeaderCell colSpan={colSpan} textAlign={position}>
          <SemanticPagination
            activePage={activePage}
            totalPages={totalPages}
            disabled={!totalPages}
            onPageChange={(_, data) => {
              onPageChange(data.activePage as number);
            }}
            style={style}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

export default Pagination;
