import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';

import { Button, Form } from 'semantic-ui-react';

import {
  InsertDeveloMappingMutation,
  UpdateDeveloMappingMutation,
} from '@bluefox/graphql/integrations/develo';
import { PracticeProfileQuery } from '@bluefox/graphql/practices';
import { PracticeProfile } from '@bluefox/models/Practice';

import { MappedItem } from '@bluefox/models/integrations/Athena';
import { VfcCriteria, VFC_OPTIONS } from '@bluefox/models/Insurances';

const PLAN_TYPE = [
  ...VFC_OPTIONS,
  {
    key: 'private',
    value: 'private',
    text: 'Private',
  },
];

interface PracticeProfileData {
  practice: {
    profile: PracticeProfile;
  };
}

interface VfcCriteriaMappingFormProps {
  close: () => void;
  data: MappedItem | null;
  refetchMappings: () => void;
  mappedKeys: string[];
  practiceId: string | undefined;
  isEdition: boolean;
}

const VfcCriteriaMappingForm = ({
  close,
  data,
  refetchMappings,
  mappedKeys,
  practiceId,
  isEdition,
}: VfcCriteriaMappingFormProps) => {
  const [vfcCriteria, setVfcCriteria] = useState<string>(
    data?.value.data || ''
  );
  const [develoKey, setDeveloKey] = useState<string>(data?.key || '');

  const { data: practiceProfile } = useQuery<PracticeProfileData>(
    PracticeProfileQuery,
    {
      variables: {
        id: practiceId,
      },
    }
  );

  const [saveInsuranceCompanyMapping] = useMutation(
    data?.id ? UpdateDeveloMappingMutation : InsertDeveloMappingMutation
  );

  const handleSubmit = () => {
    const key = develoKey.toLowerCase().trim();
    if (mappedKeys.includes(key) && !isEdition && data?.key !== key) {
      toast({
        title: 'VFC criteria already mapped.',
        type: 'error',
        time: 5000,
      });
      close();
      return;
    }

    saveInsuranceCompanyMapping({
      variables: {
        id: data?.id,
        practiceId: data?.practice?.id || practiceId,
        key: key,
        type: 'vfcCriteria',
        value: {
          data: vfcCriteria,
        },
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={develoKey}
            onChange={(_, { value }) => setDeveloKey(value)}
            fluid
            label="Insurance Type"
            placeholder="Insurance Type"
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Select VFC Criteria</label>
          <Form.Select
            placeholder="VFC Criteria"
            clearable
            required
            value={vfcCriteria}
            onChange={(_, { value }) => setVfcCriteria(value as VfcCriteria)}
            options={
              practiceProfile?.practice.profile.address?.state === 'NY'
                ? [
                    ...PLAN_TYPE,
                    {
                      key: 'ch_plus_b',
                      value: 'ch_plus_b',
                      text: 'CHPlusB',
                    },
                  ]
                : PLAN_TYPE
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button primary type="submit" content="Save" icon="save" />
      </Form.Field>
    </Form>
  );
};

export default VfcCriteriaMappingForm;
