import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Container,
  Table,
  Loader,
  Message,
  Button,
  Popup,
  Card,
  Icon,
  Menu,
  Input,
  Dropdown,
} from 'semantic-ui-react';

import { Practice } from '@bluefox/models/Practice';
import { PracticesScreenQuery } from '@graphql/practices';
import MainLayout from '@ui/MainLayout';
import PracticeFormModal from '@ui/PracticeFormModal';
import { useState } from 'react';
import styled from 'styled-components';
interface PracticeResponse extends Practice {
  inventoryAdjustments_aggregate: { aggregate: { count: number } };
}

const PracticesScreen = () => {
  const [searchPractice, setSearchPractice] = useState('');
  const [suspendedPracticesValue, setSuspendedPracticesValue] =
    useState<string>('active');

  const { data, loading, error, refetch } = useQuery<{
    practices: PracticeResponse[];
  }>(PracticesScreenQuery, {
    variables: {
      where: {
        _and: [
          {
            name: {
              _ilike: `%${searchPractice}%`,
            },
          },
          suspendedPracticesValue !== 'all'
            ? {
                suspended: {
                  _eq: suspendedPracticesValue === 'suspended' ? true : false,
                },
              }
            : {},
        ],
      },
    },
    nextFetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
    skip: !!searchPractice && searchPractice.length < 3,
  });

  return (
    <MainLayout
      path={[{ text: 'Practices', to: '/practices' }]}
      loading={loading}
    >
      <Container data-automation-id="it-practices" fluid>
        <Card fluid>
          <Card.Content>
            <StyledHeader>
              <Card.Header as={'h3'} style={{ margin: 0 }}>
                <Icon name="hospital" style={{ marginRight: '0.6rem' }} />
                Practices
              </Card.Header>
              <Dropdown
                labeled
                selection
                options={[
                  {
                    text: 'Active Practices',
                    value: 'active',
                    key: 'active',
                  },
                  {
                    text: 'Suspended Practices',
                    value: 'suspended',
                    key: 'suspended',
                  },
                  {
                    text: 'All Practices',
                    value: 'all',
                    key: 'all',
                  },
                ]}
                value={suspendedPracticesValue}
                onChange={(e, data) => {
                  setSuspendedPracticesValue(data.value as string);
                }}
              />
            </StyledHeader>
            <Card.Description>
              <Menu borderless>
                <Menu.Item>
                  <Input
                    placeholder="Search practice..."
                    icon="search"
                    value={searchPractice}
                    onChange={(_, { value }) => setSearchPractice(value)}
                  />
                </Menu.Item>
                <Menu.Menu position="right">
                  <Menu.Item>
                    <PracticeFormModal
                      trigger={
                        <Button
                          primary
                          icon="plus"
                          content="Add Practice"
                          style={{ float: 'right' }}
                        />
                      }
                      onSave={refetch}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              {loading && <Loader />}
              {error && <Message error>{error.message}</Message>}
              <Table striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Provider</Table.HeaderCell>
                    <Table.HeaderCell>Address</Table.HeaderCell>
                    <Table.HeaderCell>Phone</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data?.practices?.map((practice) => (
                    <Table.Row key={practice.id}>
                      <Table.Cell>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <b>{practice.name}</b>
                          <div>
                            URL:{' '}
                            <a
                              href={`https://app.canid.io/${practice.handler}`}
                              target="blank"
                            >
                              {`https://app.canid.io/${practice.handler}`}
                            </a>
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        {practice.profile?.provider?.organizationName} (
                        {practice.profile?.providerFirstName}{' '}
                        {practice.profile?.providerLastName})
                      </Table.Cell>
                      <Table.Cell>
                        {practice.profile?.address?.street},{' '}
                        {practice.profile?.address?.city},{' '}
                        {practice.profile?.address?.state} (
                        {practice.profile?.address?.zip})
                      </Table.Cell>
                      <Table.Cell>{practice.profile?.phone?.work}</Table.Cell>
                      <Table.Cell>
                        <Popup
                          content="Profile"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              style={{ margin: '0.1rem' }}
                              as={Link}
                              to={`/practices/${practice.handler}`}
                              icon="eye"
                              color="teal"
                            />
                          }
                        />
                        <Popup
                          content="Inventory"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              style={{ margin: '0.1rem' }}
                              as={Link}
                              to={`/practices/${practice.handler}/inventory`}
                              icon="tasks"
                              color="teal"
                            />
                          }
                        />
                        <Popup
                          content="Claims"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              style={{ margin: '0.1rem' }}
                              as={Link}
                              to={`/practices/${practice.handler}/claims`}
                              icon="money bill alternate outline"
                              color="teal"
                            />
                          }
                        />
                        <Popup
                          content="Insurances"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              style={{ margin: '0.1rem' }}
                              as={Link}
                              to={`/practices/${practice.handler}/insurances`}
                              icon="dollar"
                              color="teal"
                            />
                          }
                        />
                        <Popup
                          content="Orders"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              style={{ margin: '0.1rem' }}
                              as={Link}
                              to={`/practices/${practice.handler}/inventory-orders`}
                              icon="cart"
                              color="teal"
                            />
                          }
                        />
                        <Popup
                          content="Mappings"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              style={{ margin: '0.1rem' }}
                              as={Link}
                              to={`/practices/${practice.handler}/mappings`}
                              icon="map outline"
                              color="teal"
                            />
                          }
                        />

                        <Popup
                          content="Pending Adjustments"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              style={{ margin: '0.1rem' }}
                              as={Link}
                              to={`/practices/${practice.handler}/inventory/adjustments`}
                              negative={
                                practice.inventoryAdjustments_aggregate
                                  .aggregate.count > 0
                              }
                              color="teal"
                              icon="boxes"
                            />
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export default PracticesScreen;
