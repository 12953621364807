export interface HumanizeOptions {
  delimiter?: string;
  capitalize?: 'first' | 'all';
  camelCase?: boolean;
}

const _capitalize = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1);

export const humanizeText = (input: string, options: HumanizeOptions) => {
  const { capitalize, delimiter, camelCase } = options;
  let output = input;
  if (capitalize) {
    if (capitalize === 'all') {
      output = output
        .split(delimiter || ' ')
        .map(_capitalize)
        .join(delimiter);
    } else {
      output = _capitalize(output);
    }
  }
  if (camelCase) {
    const [first, ...segments] = output.split(delimiter || ' ');
    output = first + segments.map(_capitalize).join('');
  }
  if (delimiter) {
    output = output.replaceAll(delimiter, ' ');
  }
  return output;
};
