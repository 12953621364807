import { Card, Table } from 'semantic-ui-react';
import {
  CardContent,
  CardHeader,
  CardSubHeader,
} from '@bluefox/ui/Dashboard/CommonStyles';
import { InvoicingTablesDate, InvoicingTablesAmount } from './CommonStyles';
import styled from 'styled-components';
import {
  formatDateMonthDDcommaYear,
  usDollarNoDigitsCurrency,
} from '@bluefox/lib/formatters';
import { pixelToRem } from '@bluefox/constants';
import { InvoiceInfoType } from '@bluefox/models/Dashboard';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';

const InvoicesTableWrapper = styled.div`
  margin-top: ${pixelToRem(16)}rem;
  height: ${pixelToRem(255)}rem;
  overflow: auto;
`;

type Props = { paidInvoices: InvoiceInfoType[] };

const InvoicesCard = ({ paidInvoices }: Props) => {
  return (
    <Card fluid style={{ height: '100%' }}>
      <CardContent>
        <CardHeader>Invoices</CardHeader>
        <CardSubHeader>TO DATE</CardSubHeader>
        <InvoicesTableWrapper>
          {(!paidInvoices || paidInvoices.length === 0) && (
            <ChartErrorMessage>
              <p>There is not enough data to draw this table</p>
            </ChartErrorMessage>
          )}
          <Table basic="very">
            <Table.Body>
              {paidInvoices.map((invoice) => (
                <Table.Row key={invoice.date?.toString()}>
                  <Table.Cell>
                    <InvoicingTablesDate>
                      {formatDateMonthDDcommaYear(new Date(invoice.date))}
                    </InvoicingTablesDate>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <InvoicingTablesAmount>
                      {usDollarNoDigitsCurrency(invoice.amount)}
                    </InvoicingTablesAmount>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </InvoicesTableWrapper>
      </CardContent>
    </Card>
  );
};

export default InvoicesCard;
