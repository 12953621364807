import React, { SyntheticEvent } from 'react';
import { BorrowingCase, BorrowingSelectorProps } from '@bluefox/models/models';
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Header,
  Label,
  Table,
} from 'semantic-ui-react';
import { InventoryAdjustmentData } from '@bluefox/models/InventoryAdjustment';

const BorrowingSelector = (props: BorrowingSelectorProps) => {
  const { borrowingCases, onSelectAlternative } = props;

  const buildOptions = (currentCase: BorrowingCase): DropdownItemProps[] => {
    const dropContent = (alternative: InventoryAdjustmentData) => {
      return (
        <Header>
          {alternative.name}
          <span style={{ fontSize: '0.8rem', fontWeight: 'lighter' }}>
            {` Lot: ${alternative.lot} - Exp: ${alternative.expiration} - Doses: ${alternative.newDoses}`}
          </span>
          <Header.Subheader>
            {alternative.types?.map((type, index) => (
              <Label key={index} size="mini" color="orange" content={type} />
            ))}
          </Header.Subheader>
        </Header>
      );
    };

    return currentCase.alternatives.map((alternative) => {
      return {
        key: alternative.vaccineId,
        text: dropContent(alternative),
        value: alternative.vaccineId,
        content: dropContent(alternative),
      };
    });
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Inventory Used</Table.HeaderCell>
          <Table.HeaderCell>Alternatives</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      {borrowingCases.map((currentCase: BorrowingCase, index: number) => (
        <Table.Row key={index}>
          <Table.Cell>
            <Header>
              {currentCase.inconsistency.inventory?.vaccine.name}
              <Header.Subheader>
                {currentCase.inconsistency.inventory?.vaccine.aka}
                {` - VFC: ${currentCase.inconsistency.inventory?.vfc}`}
              </Header.Subheader>
            </Header>
          </Table.Cell>

          <Table.Cell>
            <Dropdown
              selection
              fluid
              onChange={(event: SyntheticEvent, data: DropdownProps) => {
                const alternative = currentCase.alternatives.find(
                  (alternative) => alternative.vaccineId === data.value
                );
                if (!alternative) {
                  return;
                }
                onSelectAlternative(alternative, currentCase.inconsistency);
              }}
              options={buildOptions(currentCase)}
              placeholder="Choose an option"
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table>
  );
};

export default BorrowingSelector;
