import { Text, View, StyleSheet } from '@react-pdf/renderer';

const headerSectionStyles = StyleSheet.create({
  section: {
    marginTop: '-40', // Move to the top of the page (which has a margin of 40)
  },
  body: {
    backgroundColor: '#F5FAFE',
    color: '#16324F',
    padding: '51 0 16 32',
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16 / 14,
  },
});

export type HeaderProps = {};

const Header = ({}: HeaderProps) => {
  return (
    <View style={headerSectionStyles.section} fixed>
      <View style={headerSectionStyles.body}>
        <Text style={headerSectionStyles.title}>Billing Report</Text>
      </View>
    </View>
  );
};

export default Header;
