import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import {
  Container,
  Table,
  Header,
  Loader,
  Message,
  Segment,
  Dropdown,
  Menu,
  List,
  Icon,
  Modal,
} from 'semantic-ui-react';
import Pagination from '@bluefox/ui/Pagination';
import MainLayout from '@ui/MainLayout';
import { TicketQuery, UpdateTicketStatus } from '@bluefox/graphql/tickets';
import {
  TicketsResponse,
  TicketDetailFieldType,
  TicketTypes,
  Ticket,
  ticketStatusOptions,
  TicketStatusEnum,
  ticketTypeOptions,
} from '@bluefox/models/Tickets';
import { humanizeText } from '@bluefox/lib/humanize';
import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import TicketFilesTable from './TicketFilesTable';

interface UpdateTicketStatusType {
  ticketId: string;
  optionStatus: string;
}

const ENTRIES_PER_PAGE = 15;

const TicketsScreen = () => {
  window.scrollTo(0, 0);
  const [searchPractice, setSearchPractice] = useState<string>();
  const [status, setStatus] = useState<string>('open');
  const [type, setType] = useState<string>('Bug_report');
  const [ticketStatusDropdownOptions, setTicketStatusDropdownOptions] =
    useState<object[]>(ticketStatusOptions);
  const [page, setPage] = useState(1);

  const { data, loading, error, refetch } = useQuery<TicketsResponse>(
    TicketQuery,
    {
      variables: {
        practiceFilter:
          searchPractice !== 'all'
            ? { _contains: { fields: [{ detail: searchPractice }] } }
            : {},
        status: [status],
        type: [type],
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      nextFetchPolicy: 'no-cache',
      fetchPolicy: 'no-cache',
    }
  );
  const [saveTicket] = useMutation(UpdateTicketStatus);

  const handleUpdateStatus = ({
    ticketId,
    optionStatus,
  }: UpdateTicketStatusType) => {
    saveTicket({
      variables: {
        id: ticketId,
        status: optionStatus,
      },
      refetchQueries: [
        {
          query: TicketQuery,
        },
      ],
    }).then(() => refetch());
  };

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  useEffect(() => {
    if (type === TicketTypes.VACCINE_ORDER) {
      setTicketStatusDropdownOptions([
        {
          key: TicketStatusEnum.REQUESTED,
          text: 'Requested',
          value: TicketStatusEnum.REQUESTED,
        },
        {
          key: TicketStatusEnum.CLOSED,
          text: 'Closed',
          value: TicketStatusEnum.CLOSED,
        },
      ]);
    } else {
      setTicketStatusDropdownOptions(ticketStatusOptions);
    }
  }, [type]);

  return (
    <MainLayout path={[{ text: 'Tickets' }]} loading={loading}>
      <Container fluid data-automation-id="it-tickets">
        <Segment
          basic
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Header as="h1">
            <Icon name="ticket" size="huge" />
            Ticket Reports
          </Header>
          <Menu fluid borderless>
            <Menu.Item>
              <PracticeFilter
                practiceSearch={searchPractice}
                setPracticeSearch={setSearchPractice}
                filterByHandler
                suspended="notSuspended"
              />
            </Menu.Item>
            <Menu.Menu
              position="right"
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <Menu.Item>
                <label>Status: </label>
                <Dropdown
                  labeled
                  onChange={(_, { value }) => {
                    if (!value) return;
                    setStatus(value.toString());
                  }}
                  placeholder="Select Status"
                  selection
                  defaultValue={status}
                  options={ticketStatusOptions}
                />
              </Menu.Item>
              <Menu.Item>
                <label>Ticket Type: </label>
                <Dropdown
                  labeled
                  onChange={(_, { value }) => {
                    if (!value) return;
                    setType(value.toString());
                  }}
                  placeholder="Select Ticket Type"
                  selection
                  defaultValue={type}
                  options={ticketTypeOptions}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Segment>
        {loading && <Loader />}
        {error && <Message error>{error.message}</Message>}
        <Table fluid>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Ticket Type</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Detail</Table.HeaderCell>
              <Table.HeaderCell>Attached files</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!data?.tickets.length ? (
              data?.tickets.map((ticket: Ticket, key) => (
                <Modal
                  closeIcon
                  trigger={
                    <Table.Row key={key}>
                      <Table.Cell>
                        {formatDateToMMDDYYYY(ticket.createdAt || '')}
                      </Table.Cell>
                      <Table.Cell>
                        {humanizeText(ticket.type, { delimiter: '_' })}
                      </Table.Cell>
                      <Table.Cell>
                        <Dropdown
                          onChange={(_, { value }) => {
                            if (!value) return;
                            handleUpdateStatus({
                              ticketId: ticket.id || '',
                              optionStatus: value.toString(),
                            });
                          }}
                          selection
                          value={ticket.status}
                          options={ticketStatusDropdownOptions}
                        />
                      </Table.Cell>
                      <Table.Cell width={8}>
                        <List relaxed>
                          {ticket.detail.fields?.map(
                            (ticketFields: TicketDetailFieldType) => (
                              <List.Item key={ticketFields.title}>
                                <List.Content>
                                  <StyledListHeader>
                                    {ticketFields.title}
                                  </StyledListHeader>
                                  <StyledListDescription>
                                    {ticketFields.detail}
                                  </StyledListDescription>
                                </List.Content>
                              </List.Item>
                            )
                          )}
                        </List>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <List relaxed>{ticket.ticketsFiles?.length}</List>
                      </Table.Cell>
                    </Table.Row>
                  }
                >
                  <Modal.Content>
                    <Table definition>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell />
                          <Table.HeaderCell>Description</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {ticket.detail.fields?.map((ticketFields: any) => (
                          <Table.Row>
                            <Table.Cell>{ticketFields.title}</Table.Cell>
                            <Table.Cell>{ticketFields.detail}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                    {!!ticket.ticketsFiles?.length && (
                      <TicketFilesTable ticketFiles={ticket.ticketsFiles} />
                    )}
                  </Modal.Content>
                  <Modal.Actions>
                    <Dropdown
                      onChange={(_, { value }) => {
                        if (!value) return;
                        handleUpdateStatus({
                          ticketId: ticket.id || '',
                          optionStatus: value.toString(),
                        });
                      }}
                      selection
                      defaultValue={ticket.status}
                      options={ticketStatusDropdownOptions}
                    />
                  </Modal.Actions>
                </Modal>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <Message>No Tickets Found</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Pagination
            total={total}
            colSpan={5}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
      </Container>
    </MainLayout>
  );
};

export default TicketsScreen;

const ellipsisStyle = css`
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledListHeader = styled(List.Header)`
  ${ellipsisStyle}
`;
const StyledListDescription = styled(List.Description)`
  ${ellipsisStyle}
`;
