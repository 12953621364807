import { gql } from '@apollo/client';

import { VaccineFullFragment } from './vaccines';

export const InventoryFullFragmentPP = gql`
  fragment InventoryFull on inventories {
    id
    lot
    doses
    expiration
    vfc
    status
    vaccineId
    lastAdjustmentReason
    lastAdjustmentDate
  }
`;

export const InventoryFullFragmentIT = gql`
  fragment InventoryFull on inventories {
    id
    lot
    doses
    expiration
    vfc
    status
    statusLog
    vaccineId
    lastAdjustmentReason
    lastAdjustmentDate
    vaccinationsCount
    orderedDoses
    alternativeLotNumber
  }
`;

export const ReceivedInventoryQuery = gql`
  query ReceivedInventoryQuery(
    $likeQuery: String!
    $typesFilter: jsonb_comparison_exp
  ) {
    vaccines(
      where: {
        inventories: { status: { _eq: received }, doses: { _neq: 0 } }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: { status: { _eq: received }, doses: { _neq: 0 } }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
        threshold {
          noVfc
        }
        createdAt
        vaccinationsCount(args: { since_interval: "3 months" })
      }
      expiredInventory: inventories(
        where: {
          status: { _eq: expired }
          vfc: { _eq: false }
          doses: { _neq: 0 }
        }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
        threshold {
          noVfc
        }
      }
    }
    expiredVaccines: vaccines(
      where: {
        inventories: { status: { _eq: expired }, doses: { _neq: 0 } }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: { status: { _eq: expired }, doses: { _neq: 0 } }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
      }
    }
  }

  ${InventoryFullFragmentPP}
  ${VaccineFullFragment}
`;

export const ReceivedInventoryQueryByPractice = gql`
  query ReceivedInventoryQueryByPractice(
    $likeQuery: String!
    $typesFilter: jsonb_comparison_exp
    $practiceId: uuid!
  ) {
    vaccines(
      where: {
        inventories: {
          status: { _eq: received }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: {
          status: { _eq: received }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
      }
    }
    expiredVaccines: vaccines(
      where: {
        inventories: {
          status: { _eq: expired }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: {
          status: { _eq: expired }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
      }
    }
  }

  ${InventoryFullFragmentIT}
  ${VaccineFullFragment}
`;

export const InventoryOrdersQuery = gql`
  query InventoryOrdersQuery {
    orders: inventories(
      where: { status: { _in: [ordered, confirmed, shipped] } }
      order_by: { expiration: asc }
    ) {
      ...InventoryFull
      vaccine {
        ...VaccineFull
      }
    }
  }

  ${InventoryFullFragmentIT}
  ${VaccineFullFragment}
`;

export const InventoryReceivedMutation = gql`
  mutation InventoryReceivedMutation($id: uuid!) {
    inventory: update_inventories_by_pk(
      _set: { status: received }
      pk_columns: { id: $id }
    ) {
      ...InventoryFull
    }
  }

  ${VaccineFullFragment}
  ${InventoryFullFragmentIT}
`;

const InventoryQuery = gql`
  query InventoryQuery(
    $practiceId: uuid!
    $inventoryCriterias: [inventories_bool_exp!]!
    $vaccinesCriterias: [vaccines_bool_exp!]!
  ) {
    inventories(
      where: { practiceId: { _eq: $practiceId }, _or: $inventoryCriterias }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      lot
      expiration
      doses
      vaccineId
      status
      vfc
      lastAdjustmentDate
      lastAdjustmentReason
      vaccine {
        id
        name
        saleNdc
        manufacturer
        aka
      }
    }
    vaccines(where: { _or: $vaccinesCriterias }) {
      id
      name
      saleNdc
      manufacturer
      aka
    }
  }
`;

export const InventoryListForPdfQuery = gql`
  query InventoryListForPdfQuery($practiceId: uuid!) {
    privateInventory: inventories(
      where: {
        practice: { id: { _eq: $practiceId } }
        status: { _eq: received }
        doses: { _neq: 0 }
        vfc: { _eq: false }
      }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      doses
      expiration
      lot
      vfc
      vaccine {
        id
        name
        saleNdc
      }
    }
    vfcInventory: inventories(
      where: {
        practice: { id: { _eq: $practiceId } }
        status: { _eq: received }
        doses: { _neq: 0 }
        vfc: { _eq: true }
      }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      doses
      expiration
      lot
      vfc
      vaccine {
        id
        name
        saleNdc
      }
    }
  }
`;

export const getInventoryVaccinesQuery = gql`
  query inventoryVaccinesQuery($practiceId: uuid!) {
    inventories(
      where: {
        practiceId: { _eq: $practiceId }
        status: { _eq: received }
        doses: { _neq: 0 }
      }
    ) {
      vaccine {
        name
        id
      }
      id
      lot
      doses
    }
  }
`;

export const InventoryLotsByPracticeId = gql`
  query InventoryLotsByPracticeId($practiceId: uuid!) {
    inventoryLots: inventories(
      where: { practiceId: { _eq: $practiceId } }
      distinct_on: lot
      order_by: [{ lot: asc }]
    ) {
      id
      lot
    }
  }
`;

export const GetSimpleInventoryForBorrowing = gql`
  query Inventory($criteria: inventories_bool_exp) {
    inventories(where: $criteria) {
      id
      practiceId
      status
      expiration
      vaccineId
      doses
      vfc
      lot
      vaccine {
        id
        saleNdc
        aka
        name
        types
      }
    }
  }
`;
