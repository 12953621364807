import {
  BillingNotificationFirstOptionsEnum,
  VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
  VaxSyncIntegrationFirstOptionsEnum,
  VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
} from '@bluefox/constants/notifications';
import { OutboundAction } from './integrations/Athena';
import { BillingClaim } from './Billing';
import { VaccinationsToReportInterface } from './integrations/Develo';
import { BotReportType } from './Bot';
import { InventoryOrders } from './InventoryOrders';
import { Vaccination } from './Vaccination';
import { Practice } from './Practice';

export enum NotificationType {
  claim = 'claim',
  order = 'order',
  vax_sync = 'vax_sync',
  ecw = 'ecw',
  develo = 'develo',
  athena = 'athena',
}

export enum NotificationStatus {
  pending_approval = 'pending_approval',
  dismissed = 'dismissed',
  pending = 'pending',
  resolved = 'resolved',
  rejected = 'rejected',
  finalized = 'finalized',
}

export enum NotificationStatusLogAction {
  creation = 'creation',
  changeStatus = 'change_status',
  clientStatusChanged = 'client_status_changed',
  contentEdit = 'content_edit',
}

export type BillingNotificationContent = {
  type: NotificationType.claim;
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  firstOption: BillingNotificationFirstOptionsEnum;
  selectedCptCodes?: string[];
  freeText?: string;
  version?: number;
};

export type OrderNotificationContent = {
  type: NotificationType.order;
  orderNumber?: string;
  doses?: number;
  fullVaccineName?: string;
  vfc?: boolean;
  purchaseDate?: Date | string;
};

export type VaxSyncIntegrationNotificationType =
  | NotificationType.athena
  | NotificationType.ecw
  | NotificationType.develo
  | NotificationType.vax_sync;

export type VaxSyncIntegrationNotificationContent = {
  type: VaxSyncIntegrationNotificationType;
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  firstOption?: VaxSyncIntegrationFirstOptionsEnum;
  secondOption?:
    | VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
    | VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum;
  freeText?: string;
  version?: number;
  vaccineName?: string;
  lot?: string;
  inventoryVfc?: boolean;
  typedDOS?: string;
  typedVaccineName?: string;
  typedLot?: string;
  typedVfc?: boolean;
};

export type NotificationContent =
  | BillingNotificationContent
  | OrderNotificationContent
  | VaxSyncIntegrationNotificationContent;

export type NotificationStatusLog = {
  action: NotificationStatusLogAction;
  account: any;
  updatedAt: Date | string;
} & Record<string, any>;

export type NotificationCommentSources = 'IT' | 'PP';

export type NotificationComment = {
  id?: string;
  date: string | Date;
  userId: string;
  role: string;
  name: string;
  message: string;
  origin: NotificationCommentSources;
};

export interface Notification {
  id?: string;
  practiceId?: string;
  content: NotificationContent;
  type: NotificationType;
  status: NotificationStatus;
  viewedBy?: string;
  createdBy?: string;
  statusLog: Array<any>;
  entityRefId?: string;
  note?: string;
  practicePatientId?: string;
  practice?: Practice;
  createdAt?: string | Date;
  updatedAt?: string | Date;
  athenaOutboundAction?: OutboundAction;
  claim?: BillingClaim;
  develoVaccinationToReport?: VaccinationsToReportInterface;
  ecwBotReport?: BotReportType;
  inventoryOrder?: InventoryOrders;
  vaccination?: Vaccination;
  comments?: NotificationComment[];
  pendingReadBy?: NotificationCommentSources;
  givenAt?: string | Date;
  vaccinationId?: string;
}

export type NotificationResponseType = {
  communication_notifications: Notification[];
  communication_notifications_aggregate: {
    aggregate: {
      count: number;
    };
  };
};
