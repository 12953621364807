import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Container } from 'semantic-ui-react';

import { Practice } from '@bluefox/models/Practice';
import LoadingBox from '@bluefox/ui/LoadingBox';

export const PracticeQuery = gql`
  query Practice($handler: String!) {
    practices(where: { handler: { _eq: $handler } }) {
      id
      handler
      name
      timezone
      profile
      publicSettings
      npi
      isBillable
      issName
      iisAlias
      organization {
        id
        name
        handler
        credit
        practices {
          id
          name
          handler
        }
      }
    }
  }
`;

const usePracticeData = (handler: string) => {
  const { data, loading, error } = useQuery(PracticeQuery, {
    variables: {
      handler,
    },
  });

  if (data && data.practices[0]) {
    return {
      data: data.practices[0] || undefined,
      loading,
    };
  }

  return { loading, error };
};

const practiceContext = React.createContext<Practice>({
  id: '',
  handler: '',
  name: '',
  timezone: '',
  profile: {
    email: '',
    internalEmail: '',
    extensionFlag: false,
  },
  publicSettings: {},
  npi: '',
  settings: {
    extensionFlag: false,
  },
  iisAlias: '',
  issName: '',
});

type PracticeContextProviderProps = {
  children: React.ReactNode;
  handler: string;
};

export const PracticeContextProvider = ({
  children,
  handler,
}: PracticeContextProviderProps) => {
  const { data, loading, error } = usePracticeData(handler);

  if (loading) {
    return (
      <Container>
        <LoadingBox />
      </Container>
    );
  }

  if (error) {
    console.error(error);
    return <h1>Error loading Practice Data</h1>;
  }

  return (
    <practiceContext.Provider value={data}>{children}</practiceContext.Provider>
  );
};

export const usePractice = () => {
  return useContext(practiceContext);
};
