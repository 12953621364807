import { useState } from 'react';

import {
  Segment,
  Grid,
  Header,
  Form,
  FormGroup,
  Modal,
} from 'semantic-ui-react';

import { useQuery } from '@apollo/client';

import BillingProviderInfo from './BillingProviderInfo';
import PatientInfo from './PatitentInfo';
import ServiceLines from './ServiceLines';

import CustomPlaceHolder from './components/CustomPlaceHolder';
import RenderingProvider from './RenderingProvider';
import CustomError from './components/CustomError';

import { ClaimPreview } from '@bluefox/models/ClaimPreview';

import { ClaimPreviewQuery } from '@graphql/billing';

interface ClaimPreviewResponse {
  ClaimPreview: {
    code: number;
    body?: ClaimPreview;
    message?: string;
    extensions?: string;
  };
}

const PreviewParsedClaim = ({ claimId }: { claimId: string }) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [claimData, setClaimData] = useState<ClaimPreview | null>(null);

  const { loading } = useQuery<ClaimPreviewResponse>(ClaimPreviewQuery, {
    variables: {
      claimId,
    },
    onCompleted: (data) => {
      if (data.ClaimPreview.code !== 200) {
        setErrorMessage(data.ClaimPreview.message);
        return;
      }

      if (data.ClaimPreview.body) {
        setClaimData(data.ClaimPreview.body);
      }
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  return (
    <Modal.Content>
      {!loading && claimData ? (
        <Segment raised>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2"> Health Insurance Claim Form</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as="h4"> Payer Information </Header>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Form>
                          <FormGroup widths="equal">
                            <Form.Input
                              fluid
                              label="Payer Name"
                              value={claimData.payer?.name}
                              readOnly
                            />
                            <Form.Input
                              fluid
                              label="Payer Id"
                              value={claimData.payer?.id}
                              readOnly
                            />
                            <Form.Input
                              fluid
                              label="CPID"
                              value={claimData.payer?.cpid}
                              readOnly
                            />
                          </FormGroup>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <PatientInfo patient={claimData.patient} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column columns={1}>
                <ServiceLines
                  serviceLines={claimData.claimInformation.serviceLines}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Segment>
                  <Grid>
                    <Grid.Row columns={5}>
                      <Grid.Column>
                        <Segment>
                          <Header as="h4"> Total Charge: </Header>$
                          {claimData.claimInformation.claimChargeAmount}
                        </Segment>
                      </Grid.Column>
                      <Grid.Column>
                        <Segment>
                          <Header as="h4"> Signed: </Header>
                          {claimData.claimInformation.signatureIndicator}
                        </Segment>
                      </Grid.Column>
                      <Grid.Column>
                        <Segment>
                          <Header as="h4"> Claim Filing Ind: </Header>
                          {claimData.claimInformation.claimFilingCode}
                        </Segment>
                      </Grid.Column>
                      <Grid.Column>
                        <Segment>
                          <Header as="h4"> Diagnosis Code: </Header>
                          {
                            claimData.claimInformation
                              .healthCareCodeInformation?.[0].diagnosisCode
                          }
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <BillingProviderInfo provider={claimData.provider} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <RenderingProvider provider={claimData?.rendering} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ) : !errorMessage ? (
        <CustomPlaceHolder />
      ) : (
        <CustomError message={errorMessage} />
      )}
    </Modal.Content>
  );
};

export default PreviewParsedClaim;
