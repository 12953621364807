import { Message } from './types';
import ChatMessage from './ChatMessage';

type Props = {
  messages: { message: Message; position?: 'left' | 'right' }[];
};

const ChatList = ({ messages }: Props) => {
  return (
    <>
      {messages?.map((message) => (
        <ChatMessage
          key={message.message.id}
          message={message.message}
          position={message.position}
        />
      ))}
    </>
  );
};

export default ChatList;
