import {
  Segment,
  Header,
  Divider,
  Grid,
  Form,
  FormGroup,
} from 'semantic-ui-react';

import { Gender, Patient } from '@bluefox/models/ClaimPreview';
import { InsuranceRelationShips } from '@bluefox/models/Insurances';

const formatDateString = (dateString: string) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  const fullDate = `${year}/${month}/${day}`;
  return fullDate;
};

const PatientInfo: React.FC<{ patient: Patient }> = ({
  patient: {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    relationshipToInsured,
    address,
    holder,
  },
}) => {
  return (
    <Segment>
      <Header as="h3"> Patient Information</Header>
      <Divider />
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              <Header as="h4"> Patient's Name </Header>
              <Form>
                <FormGroup widths="equal">
                  <Form.Input
                    fluid
                    label="First Name"
                    value={firstName}
                    readOnly
                  />
                  <Form.Input
                    fluid
                    label="Last Name"
                    value={lastName}
                    readOnly
                  />
                </FormGroup>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Header as="h4">
                Patient's Birth Date {'(YYYY/MM/DD)'} / Patient's Sex
              </Header>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input
                      fluid
                      label=""
                      value={dateOfBirth && formatDateString(dateOfBirth)}
                    />
                  </Grid.Column>
                  <Grid.Column
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Form>
                      <Form.Group>
                        <Form.Radio
                          label={Gender.M}
                          checked={gender === Gender.M}
                          readOnly
                        />
                        <Form.Radio
                          label={Gender.F}
                          checked={gender === Gender.F}
                          readOnly
                        />
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              <Header as="h4"> 5. Patient's Address (No., Street)</Header>
              <Form>
                <FormGroup widths="equal">
                  <Form.Input
                    fluid
                    label=""
                    value={address?.address1}
                    readOnly
                  />
                </FormGroup>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Header as="h4"> Patient Relationship To Insured </Header>
              <Form>
                <FormGroup widths="equal">
                  <Form.Radio
                    label={InsuranceRelationShips.Self}
                    checked={
                      relationshipToInsured === InsuranceRelationShips.Self
                    }
                  />
                  <Form.Radio
                    label={InsuranceRelationShips.Spouse}
                    checked={
                      relationshipToInsured === InsuranceRelationShips.Spouse
                    }
                  />
                  <Form.Radio
                    label={InsuranceRelationShips.Child}
                    checked={
                      relationshipToInsured === InsuranceRelationShips.Child
                    }
                  />
                  <Form.Radio
                    label={InsuranceRelationShips.Other}
                    checked={
                      relationshipToInsured === InsuranceRelationShips.Other
                    }
                  />
                </FormGroup>
              </Form>
              {holder && (
                <>
                  <Header as="h4"> Holder Information </Header>
                  <Form>
                    <FormGroup widths="equal">
                      <Form.Input
                        fluid
                        label="First Name"
                        value={holder.firstName}
                        readOnly
                      />
                      <Form.Input
                        fluid
                        label="Last Name"
                        value={holder.lastName}
                        readOnly
                      />
                      <Form.Input
                        fluid
                        label="Date of Birthday"
                        value={
                          holder.dateOfBirth
                            ? formatDateString(holder.dateOfBirth)
                            : ''
                        }
                        readOnly
                      />
                    </FormGroup>
                  </Form>
                </>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h4"> More info. </Header>
              <Form>
                <FormGroup widths="equal">
                  <Form.Input
                    fluid
                    label="City"
                    value={address?.city}
                    readOnly
                  />
                  <Form.Input
                    fluid
                    label="State"
                    value={address?.state}
                    readOnly
                  />
                  <Form.Input
                    fluid
                    label="Zip Code"
                    value={address?.postalCode}
                    readOnly
                  />
                </FormGroup>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default PatientInfo;
