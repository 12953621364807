import React from 'react';
import { Icon } from 'semantic-ui-react';

interface ContactFooterProps {
  email?: string;
  websiteLink?: string;
  phone?: string;
}

const ContactFooter = ({ email, websiteLink, phone }: ContactFooterProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#16324f',
        color: '#ffffff',
        padding: '1rem 2rem',
      }}
    >
      <div>
        <Icon name="mail" />
        {email}
      </div>
      <div>
        <Icon name="world" />
        {websiteLink}
      </div>
      <div>
        <Icon flipped="horizontally" name="phone" />
        {phone}
      </div>
    </div>
  );
};

export default ContactFooter;
