import { gql } from '@apollo/client';

import { PatientDataFragment } from '@bluefox/graphql/patients';

export const PatientsQuery = gql`
  query PatientsQuery(
    $practiceId: uuid!
    $criteria: [practice_patients_bool_exp!]
    $limit: Int!
    $offset: Int!
  ) {
    aggregating: practice_patients_aggregate(
      where: { practiceId: { _eq: $practiceId }, _or: $criteria }
    ) {
      aggregate {
        count
      }
    }
    patients: practice_patients(
      where: { practiceId: { _eq: $practiceId }, _or: $criteria }
      limit: $limit
      offset: $offset
      order_by: { patientData: { firstName: asc, lastName: asc } }
    ) {
      id
      mrn
      practice {
        timezone
      }
      patientData {
        id
        preferredName
        firstName
        middleName
        lastName
        sex
        birthdate
        raceAndCreed
        email
        phone
        address
      }
      insurances {
        id
      }
      notes
    }
  }
`;

export const InsertPracticePatientMutation = gql`
  mutation InsertPracticePatientMutation(
    $practiceId: uuid!
    $firstName: String
    $lastName: String
    $sex: String
    $birthdate: date
    $raceAndCreed: String
    $email: String
    $phone: String
    $address: jsonb
  ) {
    practicePatient: insert_practice_patients_one(
      object: {
        practiceId: $practiceId
        patientData: {
          data: {
            firstName: $firstName
            lastName: $lastName
            sex: $sex
            birthdate: $birthdate
            raceAndCreed: $raceAndCreed
            email: $email
            phone: $phone
            address: $address
          }
        }
      }
    ) {
      id
      patientData {
        ...patientFull
      }
    }
  }

  ${PatientDataFragment}
`;

export const SavePracticePatientNotes = gql`
  mutation SavePracticePatientNotes($practicePatientId: uuid!, $notes: jsonb) {
    update_practice_patients_by_pk(
      pk_columns: { id: $practicePatientId }
      _set: { notes: $notes }
    ) {
      id
      notes
    }
  }
`;
