import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { VaccineByInventoryCptCodeQuery } from '@graphql/billing';
import { Dropdown } from 'semantic-ui-react';

interface VaccineOption {
  key: string;
  value: string;
  text: string;
}

interface Vaccine {
  id: string;
  name: string;
  manufacturer: string;
  inventoryCptCodes: string;
}

interface VaccinesData {
  vaccines: Vaccine[];
}

interface VaccineCptCodePickerProps {
  handleVaccine: (vaccine: VaccineOption) => void;
}

const VaccineCptCodePicker = ({ handleVaccine }: VaccineCptCodePickerProps) => {
  const [cptCode, setCptCode] = useState('');
  const [input, setInput] = useState('');
  const [options, setOptions] = useState<VaccineOption[]>([]);

  const { data, loading } = useQuery<VaccinesData>(
    VaccineByInventoryCptCodeQuery,
    {
      variables: {
        cptCode,
      },
      skip: input ? input.length < 5 : true,
    }
  );

  useEffect(() => {
    if (!input) return;
    setCptCode(input);
  }, [input]);

  useEffect(() => {
    if (!data) return;
    setOptions(
      data.vaccines.map((v) => {
        return {
          key: v.id,
          value: v.id,
          text: `${v.name} [${v.inventoryCptCodes}]`,
        };
      })
    );
  }, [data]);

  return (
    <Dropdown
      placeholder="Search vaccine inventory CPT code..."
      fluid
      search
      selection
      onSearchChange={(_, { searchQuery }) => setInput(searchQuery as string)}
      onChange={(_, data) => {
        const vaccine = data.options ? data.options.find((v) => v.value) : {};

        handleVaccine({
          key: vaccine?.key,
          value: vaccine?.value as string,
          text: vaccine?.text as string,
        });
      }}
      options={options}
      loading={loading}
    />
  );
};

export default VaccineCptCodePicker;
