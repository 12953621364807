import MainLayout from '@ui/MainLayout';
import UsersList from '@ui/UsersList';
import { Container, Grid } from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts/Practice';

export const UsersScreen = () => {
  const practice = usePractice();

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Users' },
      ]}
    >
      <Container fluid>
        <Grid>
          <Grid.Column>
            <UsersList />
          </Grid.Column>
        </Grid>
      </Container>
    </MainLayout>
  );
};
