import { gql } from '@apollo/client';

export const PrivateIncomingPaymentsView = gql`
  query PrivateIncomingPaymentsView(
    $criteria: billing_incoming_payments_private_bool_exp
  ) {
    payments: billing_incoming_payments_private(where: $criteria) {
      practiceId
      patientCount
      vaccCount
      paidAmountAdm
      paidAmountInv
    }
  }
`;

export const BillingTotalAdminPaidAmountVfcPP = gql`
  query BillingTotalAdminPaidAmountVfcPP(
    $criteria: billing_total_admin_paid_amount_vfc_pp_bool_exp!
  ) {
    payments: billing_total_admin_paid_amount_vfc_pp_aggregate(
      where: $criteria
    ) {
      aggregate {
        sum {
          patientCount
          vaccinationCount
          paidAmountAdministration
          paidAmountInventory
        }
        count(distinct: true)
      }
    }
  }
`;

export const ProcedureAmountsQuery = gql`
  query ProcedureAmountsQuery($type: String!) {
    cptCodes: procedure_amounts(where: { type: { _eq: $type } }) {
      cpt
    }
  }
`;

export const BillingInvoicesQuery = gql`
  query BillingInvoicesQuery(
    $criteria: billing_invoices_bool_exp
    $limit: Int
    $offset: Int
  ) {
    invoices: billing_invoices(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { date: desc }
    ) {
      id
      invoiceNo
      date
      cptCodesCount: claimCptCodes_aggregate {
        aggregate {
          count
        }
      }
    }
    aggregating: billing_invoices_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const NewBillingInvoicesQuery = gql`
  query BillingInvoicesQuery(
    $criteria: billing_invoices_bool_exp
    $limit: Int
    $offset: Int
  ) {
    invoices: billing_invoices(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { date: desc }
    ) {
      id
      invoiceNo
      practicesData {
        name
      }
      date
      dueDate
      amount
      status
      pdfData
      cptCodesCount: claimCptCodes_aggregate {
        aggregate {
          count
        }
      }
    }
    aggregating: billing_invoices_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const BillingReportQuery = gql`
  query BillingReportQuery(
    $criteria: billing_claims_bool_exp!
    $criteriaCptCodes: billing_claim_cpt_codes_bool_exp!
  ) {
    canidProfile: practices(where: { handler: { _eq: "canid" } }) {
      profile
    }
    billingReport: billing_claims(
      where: $criteria
      order_by: { createdAt: asc }
    ) {
      id
      note
      checkNumber
      cptCodes(where: $criteriaCptCodes) {
        id
        claimId
        cptCode
        claimAmount
        paidAmount
      }
      vaccinations {
        givenAt
        practice {
          name
        }
      }
      practicePatient {
        patientData {
          firstName
          lastName
          birthdate
        }
        insurances(where: { active: { _eq: true } }) {
          memberId
          vfcEligible
          vfcCriteria
          company {
            name
          }
        }
      }
      updates(
        where: { metadata: { _has_key: "insurance" } }
        order_by: { createdAt: desc }
      ) {
        metadata
      }
    }
    totalAmountCalculated: billing_claim_cpt_codes_aggregate(
      where: $criteriaCptCodes
    ) {
      aggregate {
        sum {
          totalAmount: paidAmount
        }
      }
    }
  }
`;

export const SaveInvoiceToSendByEmail = gql`
  mutation SaveInvoiceToSendByEmail(
    $invoiceId: uuid!
    $dueDate: date
    $description: String
  ) {
    update_billing_invoices_by_pk(
      pk_columns: { id: $invoiceId }
      _set: { dueDate: $dueDate, description: $description }
    ) {
      id
      dueDate
      description
    }
  }
`;

export const SendInvoiceMutation = gql`
  mutation SendInvoiceMutation($invoiceId: uuid!) {
    sendInvoice(id: $invoiceId) {
      code
      message
    }
  }
`;

export const RegenerateInvoiceMutation = gql`
  mutation RegenerateInvoiceMutation($invoiceId: uuid!) {
    reGenerateInvoice(id: $invoiceId) {
      code
      message
    }
  }
`;
export const GetEOBLink = gql`
  query GetEOBLink($claimId: uuid!) {
    claim: billing_claims_by_pk(id: $claimId) {
      id
      EOBLink: note(path: "url")
    }
  }
`;

export const GetClaimsVFCInconsistencies = (practicePortal: boolean) => gql`
  query GetClaimsVFCInconsistencies(
    $criteria: billing_claim_vfc_inconsistencies_bool_exp
    $limit: Int
    $offset: Int
  ) {
    ${
      practicePortal
        ? `tickets: ticketing_tickets(
      where: { type: { _eq: "Borrowing_issue" }, status: { _eq: "open" } }
    ) {
      detail
    }`
        : ''
    }
    vfcInconsistencies: billing_claim_vfc_inconsistencies(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { inventory: { vfc: asc }, createdAt: desc }
    ) {
      createdAt
      id
      practiceId
      practice {
        id
        name
        profile
      }
      claimId
      borrowingReasonCode
      borrowingReasonCodeOtherDescription
      notes
      inventoryId
      vaccinationId
      vaccination {
        inventory {
          lot
          vfc
          vaccine {
            saleNdc
            name
          }
        }
      }
      inventory {
        id
        expiration
        vfc
        lot
        doses
        vaccine {
          id
          name
          aka
          types
          saleNdc
        }
      }
      updatedAt
      type
      status
      redundantWithId
      readyToBeSwapped
      redundantWith {
        id
        inventory {
          vfc
          vaccine {
            name
          }
          lot
        }
        claim {
          practicePatient {
            patientData {
              firstName
              lastName
              birthdate
            }
          }
          givenAt
          ${
            practicePortal
              ? `insurance: insuranceForPracticePortal {
                insuranceCompanyId
                insuranceCompanyName
                memberId
                payerId
                vfcEligible
                vfcCriteria
              }`
              : `insurance {
                insuranceCompanyId
                insuranceCompanyName
                memberId
                payerId
                vfcEligible
                vfcCriteria
              }`
          }
        }
      }
      claimUpdatesId
      claim {
        id
        givenAt
        practicePatient {
          patientData {
            birthdate
            firstName
            lastName
          }
        }
          ${
            practicePortal
              ? `insurance: insuranceForPracticePortal {
                insuranceCompanyId
                insuranceCompanyName
                memberId
                payerId
                vfcEligible
                vfcCriteria
              }`
              : `insurance {
                insuranceCompanyId
                insuranceCompanyName
                memberId
                payerId
                vfcEligible
                vfcCriteria
              }`
          } 
      }
    }
  }
`;

export const GetClaimsVFCInconsistenciesCount = gql`
  query GetClaimsVFCInconsistenciesCount(
    $criteria: billing_claim_vfc_inconsistencies_bool_exp
  ) {
    aggregating: billing_claim_vfc_inconsistencies_aggregate(
      distinct_on: id
      where: $criteria
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GetVFCInconsistenciesStatusesAndTypes = gql`
  query GetVFCInconsistenciesStatusesAndTypes {
    types: billing_claim_vfc_inconsistency_types {
      text: comment
      value
    }
    statuses: billing_claim_vfc_inconsistency_status {
      text: comment
      value
    }
  }
`;

export const UpdateInconsistenciesStatus = gql`
  mutation UpdateInconsistenciesStatus(
    $objects: [billing_claim_vfc_inconsistencies_insert_input!]!
  ) {
    insert_billing_claim_vfc_inconsistencies(
      objects: $objects
      on_conflict: {
        constraint: claim_vfc_inconsistencies_id_key
        update_columns: [type, status]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UpdateInconsistencyTypeAndStatus = gql`
  mutation UpdateInconsistencyTypeAndStatus(
    $id: uuid!
    $status: billing_claim_vfc_inconsistency_status_enum
    $type: billing_claim_vfc_inconsistency_types_enum
  ) {
    update_billing_claim_vfc_inconsistencies_by_pk(
      pk_columns: { id: $id }
      _set: { type: $type, status: $status }
    ) {
      id
      type
      status
    }
  }
`;

export const GetVfcInconsistenciesFromVW = gql`
  query GetVfcInconsistenciesFromVW {
    inconsistencies: billing_claim_vfc_inconsistencies_vw {
      claim_id
      claim_update_id
      claim_vfc_eligible
      inventory_id
      inventory_vfc_eligible
      practice_id
      vaccination_id
    }
  }
`;

export const UpdateBillingClaimVfcInconsistenciesByPk = gql`
  mutation UpdateBillingClaimVfcInconsistenciesByPk(
    $id: uuid!
    $notes: String
  ) {
    updateBillingClaimVfcInconsistenciesByPk: update_billing_claim_vfc_inconsistencies_by_pk(
      pk_columns: { id: $id }
      _set: { notes: $notes }
    ) {
      id
      notes
    }
  }
`;

export const SolvePendingBorrows = gql`
  mutation SolvePendingBorrows(
    $objects: [inventory_adjustments_insert_input!]!
  ) {
    insert_inventory_adjustments(objects: $objects) {
      affected_rows
    }
  }
`;
//  ------------ Merge this mutations ---------------
export const SolveRejectedBorrows = gql`
  mutation SolveRejectedBorrows($idsToReject: [uuid!]) {
    update_billing_claim_vfc_inconsistencies_many(
      updates: {
        where: { id: { _in: $idsToReject } }
        _set: { status: solved }
      }
    ) {
      affected_rows
    }
  }
`;

export const UpdateRedundantSwappedDate = gql`
  mutation UpdateRedundantSwappedDate(
    $redundantIds: [uuid!]
    $swappedDate: timestamptz
  ) {
    update_billing_claim_vfc_inconsistencies_many(
      updates: {
        where: { id: { _in: $redundantIds } }
        _set: { swappedDate: $swappedDate }
      }
    ) {
      affected_rows
    }
  }
`;
//  ------------ Merge up to here ---------------

export const RevertRedundantMutation = gql`
  mutation RevertRedundantMutation($idsArray: [uuid!]) {
    update_billing_claim_vfc_inconsistencies(
      where: { id: { _in: $idsArray } }
      _set: { status: pending, redundantWithId: null }
    ) {
      affected_rows
    }
  }
`;

export const GetVfcClaimsInconsistensiesForSummaryDetails = gql`
  query GetVfcClaimsInconsistensiesForSummaryDetails($_id: uuid) {
    sourceClaims: billing_claim_vfc_inconsistencies(
      where: { sourceInventoryAdjustmentDetail: { id: { _eq: $_id } } }
    ) {
      createdAt
      id
      practiceId
      practice {
        id
        name
      }
      claimId
      notes
      inventoryId
      vaccinationId
      inventory {
        id
        expiration
        vfc
        lot
        doses
        vaccine {
          id
          name
          aka
          types
          saleNdc
        }
      }
      updatedAt
      type
      status
      redundantWithId
      redundantWith {
        id
        inventory {
          vfc
          vaccine {
            name
          }
          lot
        }
        claim {
          insurance {
            insuranceCompanyName
            memberId
            vfcEligible
          }
          practicePatient {
            patientData {
              firstName
              lastName
              birthdate
            }
          }
          givenAt
        }
      }
      claimUpdatesId
      claim {
        id
        givenAt
        insurance {
          insuranceCompanyName
          insuranceCompanyId
          memberId
          vfcEligible
          vfcCriteria
          payerId
        }
        practicePatient {
          patientData {
            birthdate
            firstName
            lastName
          }
        }
      }
    }
    targetClaims: billing_claim_vfc_inconsistencies(
      where: { targetInventoryAdjustmentDetail: { id: { _eq: $_id } } }
    ) {
      createdAt
      id
      practiceId
      practice {
        id
        name
      }
      claimId
      notes
      inventoryId
      vaccinationId
      inventory {
        id
        expiration
        vfc
        lot
        doses
        vaccine {
          id
          name
          aka
          types
          saleNdc
        }
      }
      updatedAt
      type
      status
      redundantWithId
      redundantWith {
        id
        inventory {
          vfc
          vaccine {
            name
          }
          lot
        }
        claim {
          insurance {
            insuranceCompanyName
            memberId
            vfcEligible
          }
          practicePatient {
            patientData {
              firstName
              lastName
              birthdate
            }
          }
          givenAt
        }
      }
      claimUpdatesId
      claim {
        id
        givenAt
        insurance {
          insuranceCompanyName
          insuranceCompanyId
          memberId
          vfcEligible
          vfcCriteria
          payerId
        }
        practicePatient {
          patientData {
            birthdate
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GetVfcClaimsInconsistensiesForSummaryDetailsOnPracticePortal = gql`
  query GetVfcClaimsInconsistensiesForSummaryDetailsOnPracticePortal(
    $_id: uuid
  ) {
    sourceClaims: billing_claim_vfc_inconsistencies(
      where: { sourceInventoryAdjustmentDetail: { id: { _eq: $_id } } }
    ) {
      createdAt
      id
      practiceId
      practice {
        id
        name
      }
      claimId
      inventoryId
      vaccinationId
      inventory {
        id
        expiration
        vfc
        lot
        doses
        vaccine {
          id
          name
          aka
          types
          saleNdc
        }
      }
      type
      status
      redundantWithId
      redundantWith {
        id
        inventory {
          vfc
          vaccine {
            name
          }
          lot
        }
        claim {
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            memberId
            vfcEligible
          }
          practicePatient {
            patientData {
              firstName
              lastName
              birthdate
            }
          }
          givenAt
        }
      }
      claimUpdatesId
      claim {
        id
        givenAt
        insurance: insuranceForPracticePortal {
          insuranceCompanyName
          insuranceCompanyId
          memberId
          vfcEligible
          vfcCriteria
          payerId
        }
        practicePatient {
          patientData {
            birthdate
            firstName
            lastName
          }
        }
      }
    }
    targetClaims: billing_claim_vfc_inconsistencies(
      where: { targetInventoryAdjustmentDetail: { id: { _eq: $_id } } }
    ) {
      createdAt
      id
      practiceId
      practice {
        id
        name
      }
      claimId
      inventoryId
      vaccinationId
      inventory {
        id
        expiration
        vfc
        lot
        doses
        vaccine {
          id
          name
          aka
          types
          saleNdc
        }
      }
      type
      status
      redundantWithId
      redundantWith {
        id
        inventory {
          vfc
          vaccine {
            name
          }
          lot
        }
        claim {
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            memberId
            vfcEligible
          }
          practicePatient {
            patientData {
              firstName
              lastName
              birthdate
            }
          }
          givenAt
        }
      }
      claimUpdatesId
      claim {
        id
        givenAt
        insurance: insuranceForPracticePortal {
          insuranceCompanyName
          insuranceCompanyId
          memberId
          vfcEligible
          vfcCriteria
          payerId
        }
        practicePatient {
          patientData {
            birthdate
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const ReadyToBeSwappedMutation = gql`
  mutation ReadyToBeSwappedMutation($id: uuid!, $readyToBeSwapped: Boolean) {
    update_billing_claim_vfc_inconsistencies_by_pk(
      pk_columns: { id: $id }
      _set: { readyToBeSwapped: $readyToBeSwapped }
    ) {
      readyToBeSwapped
      id
    }
  }
`;

export const BorrowingReportCodeMutation = gql`
  mutation BorrowingReportCodeMutation(
    $id: uuid!
    $borrowingReasonCode: String
    $borrowingReasonCodeOtherDescription: String
  ) {
    update_billing_claim_vfc_inconsistencies_by_pk(
      pk_columns: { id: $id }
      _set: {
        borrowingReasonCode: $borrowingReasonCode
        borrowingReasonCodeOtherDescription: $borrowingReasonCodeOtherDescription
      }
    ) {
      id
      borrowingReasonCode
      borrowingReasonCodeOtherDescription
    }
  }
`;

export const GetInconsistenciesToSwap = gql`
  query GetInconsistenciesToSwap(
    $criteria: billing_claim_vfc_inconsistencies_bool_exp
  ) {
    vfcInconsistencies: billing_claim_vfc_inconsistencies(
      where: $criteria
      order_by: { inventory: { vfc: asc }, createdAt: desc }
    ) {
      createdAt
      id
      practiceId
      practice {
        id
        name
      }
      claimId
      inventoryId
      vaccinationId
      inventory {
        id
        expiration
        vfc
        lot
        doses
        vaccine {
          id
          name
          aka
          types
          saleNdc
        }
      }
      updatedAt
      type
      status
      claimUpdatesId
      claim {
        id
        givenAt
        insurance: insuranceForPracticePortal {
          insuranceCompanyName
          insuranceCompanyId
          memberId
          vfcEligible
          vfcCriteria
          payerId
        }
        practicePatient {
          patientData {
            birthdate
            firstName
            lastName
          }
        }
      }
    }
  }
`;
