import { Account } from './Account';
import { Practice } from './Practice';
import { Vaccine } from './Vaccine';

export enum InventoryStatus {
  ordered = 'ordered',
  confirmed = 'confirmed',
  shipped = 'shipped',
  received = 'received',
  retired = 'retired',
  expired = 'expired',
  returned = 'returned',
}

export interface StatusLog {
  lot: string;
  vfc: boolean;
  date: Date;
  doses: number;
  status: string;
  retired: boolean;
  expiration: string;
  account?: Account;
}

export interface InventoryThreshold {
  id: string;
  practiceId: string;
  vaccineId: string;
  vfc: number;
  noVfc: number;
  notes?: string;
}

export interface Inventory {
  id: string;
  lot: string;
  expiration: Date;
  doses: number;
  vaccineId: string;
  vaccine: Vaccine;
  status: InventoryStatus;
  statusLog: StatusLog[];
  vfc: boolean;
  practice?: Practice;
  practiceId?: string;
  lastAdjustmentReason?: string;
  lastAdjustmentDate?: string;
  useNdc10?: string;
  saleNdc10?: string;
  claimVFCInconsistencies?: {};
  vaccinationsCount?: number;
  orderedDoses?: number;
  threshold: InventoryThreshold;
  createdAt: Date;
  alternativeLotNumber?: string[];
}

export interface Inventories {
  inventories: Inventory[];
}

export interface VaccineWithInventory extends Vaccine {
  inventory: Inventory[];
  expiredInventory?: Inventory[];
}

export interface VaccinesStockData {
  vaccines: VaccineWithInventory[];
  expiredVaccines: VaccineWithInventory[];
}

export interface InventoryData {
  vaccine: Vaccine;
  inventory: Inventory;
}
