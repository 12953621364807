import MainLayout from '@ui/MainLayout';
import { Grid } from 'semantic-ui-react';
import OrdersToTrackList from '@ui/Home/OrdersToTrackList';
import HomeSection from '@ui/Home/HomeSection';
import HomeBotWeeklyErrors from '@ui/Home/HomeBotWeeklyErrors';
import HomeAlerts from '@ui/Home/HomeAlerts';
import HomeTickets from '@ui/Home/HomeTickets';
import HomeInconsistentVaccinations from '@ui/Home/HomeInconsistentVaccinations';
import HomeInventoryAdjustments from '@ui/Home/HomeInventoryAdjustments';
import VaccineOrdersFromPracticePortal from '@ui/Home/HomeVaccineOrdersFromPracticePortal';
import UncollectedInvoices from '@ui/Home/UncollectedInvoices';
import { useApplicationState } from '@bluefox/contexts';
const HomeScreen = () => {
  const { session } = useApplicationState();
  return (
    <MainLayout path={[]} loading={false}>
      <Grid>
        {session?.account?.email === 'juliana@canid.io' && (
          <Grid.Row>
            <Grid.Column>
              <HomeSection
                title="Uncollected Invoices"
                icon="money"
                children={<UncollectedInvoices />}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Vaccine Orders from Practice Portal"
              icon="cart"
              children={<VaccineOrdersFromPracticePortal />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Inventory Adjustments"
              icon="boxes"
              children={<HomeInventoryAdjustments />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Tickets"
              icon="ticket"
              children={<HomeTickets />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Inconsistent Vaccinations"
              icon="syringe"
              children={<HomeInconsistentVaccinations />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Bot Weekly Errors List"
              icon="wrench"
              children={<HomeBotWeeklyErrors />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Alerts"
              icon="warning sign"
              children={<HomeAlerts />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Orders to track"
              icon="cart"
              children={<OrdersToTrackList />}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </MainLayout>
  );
};

export default HomeScreen;
