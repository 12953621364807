import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  Grid,
  Label,
  List,
  Message,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { practiceProfileTicketQuery } from '@bluefox/graphql/tickets';
import {
  TicketDetailFieldType,
  TicketsResponse,
} from '@bluefox/models/Tickets';
import { usePractice } from '@bluefox/contexts';
import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters/dates';
import HomeSection from '@ui/Home/HomeSection';
import TicketFilesTable from '@screens/tickets/TicketFilesTable';

const ENTRIES_PER_PAGE = 10;

const TicketsList = () => {
  const practice = usePractice();
  const [page, setPage] = useState<number>(1);

  const { data } = useQuery<TicketsResponse>(practiceProfileTicketQuery, {
    variables: {
      practiceFilter: {
        _contains: {
          fields: [
            {
              detail: `${practice.handler}`,
            },
          ],
        },
      },
      limit: ENTRIES_PER_PAGE,
      offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
    },
    nextFetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  return (
    <>
      {!data?.tickets.length && (
        <Grid.Row>
          <Grid.Column>
            <HomeSection
              title="Tickets"
              icon="ticket"
              children={
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Date Placed</Table.HeaderCell>
                      <Table.HeaderCell>Ticket Type</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Title</Table.HeaderCell>
                      <Table.HeaderCell>Detail</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {!!data?.tickets.length ? (
                      data?.tickets.map((ticket: any) => (
                        <Table.Row key={ticket.id}>
                          <Table.Cell>
                            {formatDateToMMDDYYYY(ticket.createdAt)}
                          </Table.Cell>
                          <Table.Cell>
                            <Label
                              basic
                              content={humanizeText(ticket.type, {
                                capitalize: 'all',
                                delimiter: '_',
                              })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Label
                              basic
                              content={humanizeText(ticket.status, {
                                capitalize: 'all',
                                delimiter: '_',
                              })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <List relaxed>
                              {ticket.detail.fields
                                ?.filter((t: any) => {
                                  return (
                                    t.title === 'Feature Title' ||
                                    t.title === 'Error Short Description' ||
                                    t.title === 'Reason for Wastage' ||
                                    t.title === 'Issue ' ||
                                    t.title === 'Adjustment reason'
                                  );
                                })
                                .map((ticketFields: TicketDetailFieldType) => (
                                  <List.Item key={ticketFields.title}>
                                    <List.Content>
                                      {ticketFields.title === 'Issue '
                                        ? humanizeText(ticketFields.detail, {
                                            capitalize: 'all',
                                            delimiter: '_',
                                          })
                                        : ticketFields.detail}
                                    </List.Content>
                                  </List.Item>
                                ))}
                            </List>
                          </Table.Cell>
                          <Table.Cell>
                            <Modal
                              closeIcon
                              trigger={<Button primary icon="eye"></Button>}
                            >
                              <Modal.Content>
                                <Table definition>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell />
                                      <Table.HeaderCell>
                                        Description
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {ticket.detail.fields
                                      ?.filter((t: any) => {
                                        return (
                                          t.title !== 'Practice' &&
                                          t.title !== 'Handler'
                                        );
                                      })
                                      .map((ticketFields: any, i: any) => {
                                        if (
                                          ticketFields.title
                                            .toLowerCase()
                                            .includes('date')
                                        ) {
                                          ticketFields.detail =
                                            formatDateToMMDDYYYY(
                                              ticketFields.detail
                                            );
                                        }
                                        return (
                                          <Table.Row key={i}>
                                            <Table.Cell>
                                              {ticketFields.title}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {ticketFields.detail}
                                            </Table.Cell>
                                          </Table.Row>
                                        );
                                      })}
                                  </Table.Body>
                                </Table>
                                {!!ticket.ticketsFiles?.length && (
                                  <TicketFilesTable
                                    ticketFiles={ticket.ticketsFiles}
                                  />
                                )}
                              </Modal.Content>
                            </Modal>
                          </Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={6}>
                          <Message>No Tickets Found</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>

                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                      <Table.HeaderCell colSpan={6} textAlign="right">
                        <Pagination
                          disabled={!total || total < ENTRIES_PER_PAGE}
                          activePage={page || 1}
                          boundaryRange={0}
                          siblingRange={1}
                          onPageChange={(e, { activePage }) =>
                            setPage(activePage as number)
                          }
                          totalPages={totalPages}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              }
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export default TicketsList;
