import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Card,
  Icon,
  Menu,
  Button,
  Container,
  Dropdown,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';

const AthenaIntegrationScreen = () => {
  return (
    <MainLayout path={[{ text: 'Athena Integration' }]}>
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="exchange" style={{ marginRight: '0.6rem' }} />
              Athena Integration
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Item>
                  <StyledDropdown button text="Mappings">
                    <Dropdown.Menu>
                      <Dropdown.Header>Type</Dropdown.Header>
                      <Dropdown.Item
                        as={Link}
                        to="/integration-athena/provider-mapping"
                      >
                        Provider
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/integration-athena/vaccine-search-criteria-mapping"
                      >
                        Vaccine search criteria
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/integration-athena/insurance-company-mapping"
                      >
                        Insurance company
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/integration-athena/vfc-criteria-mapping"
                      >
                        VFC criteria
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </StyledDropdown>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    as={Link}
                    to="/integration-athena/subscriptions"
                    primary
                    content="Subscriptions"
                  />
                </Menu.Item>
                <Menu.Item>
                  <Button
                    as={Link}
                    to="/integration-athena/outbound-actions"
                    primary
                    content="Outbound Actions"
                  />
                </Menu.Item>
              </Menu>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

const StyledDropdown = styled(Dropdown)`
  background-color: #467597 !important;
  color: #ffffff !important;
  &:hover {
    background-color: #3a6889 !important;
  }
`;

export default AthenaIntegrationScreen;
