import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Container,
  Menu,
  Message,
  Modal,
  Popup,
  Table,
} from 'semantic-ui-react';
import { PracticePatient } from '@bluefox/models/Patient';
import { PracticePatientByIdQuery } from '@bluefox/graphql/patients';
import { GuardianPatient } from '@bluefox/models/Guardian';
import { dateStrToMMDDYYYY } from '@bluefox/lib/formatters';
import GuardianForm from '@bluefox/ui/GuardianForm';
import { SetActiveGuardianMutation } from '@bluefox/graphql/guardians';
import { toast } from 'react-semantic-toasts';
import { humanizeText } from '@bluefox/lib/humanize';
import { getAge } from '@bluefox/lib/getAge';

type RouterParamsType = {
  practicePatientId?: string;
};

interface PracticePatientData {
  practicePatient: PracticePatient;
}

type PatientGuardiansListProps = {
  onClose?: () => void;
};

const PatientGuardiansList = ({ onClose }: PatientGuardiansListProps) => {
  const { practicePatientId } = useParams<RouterParamsType>();

  const [openGuardianForm, setOpenGuardianForm] = useState(false);
  const [selectedGuardian, setSelectedGuardian] = useState<GuardianPatient>();

  const [getPracticePatient, { data: practicePatientData, refetch }] =
    useLazyQuery<PracticePatientData>(PracticePatientByIdQuery(false));

  const patientAge = practicePatientData?.practicePatient.patientData.birthdate
    ? getAge(
        new Date(practicePatientData.practicePatient.patientData.birthdate)
      )
    : 0;

  const handleClose = () => {
    setSelectedGuardian(undefined);
    setOpenGuardianForm(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (!practicePatientId) return;

    getPracticePatient({
      variables: {
        id: practicePatientId,
      },
    });
  }, [practicePatientId]);

  return (
    <Container fluid>
      <Menu borderless>
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              primary
              content="Add New Guardian"
              onClick={() => setOpenGuardianForm(true)}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Date of birth</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Relationship with patient</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {practicePatientData?.practicePatient.patientData.guardians
            ?.length ? (
            <>
              {practicePatientData?.practicePatient.patientData.guardians?.map(
                (guardian) => {
                  return (
                    <PatientGuardiansListRow
                      key={guardian.id}
                      guardianPatient={
                        selectedGuardian ? selectedGuardian : guardian
                      }
                      onClickSelected={() => {
                        setOpenGuardianForm(true);
                        setSelectedGuardian({
                          id: guardian.id,
                          guardianId: guardian.guardian.id,
                          patientId: guardian.patientId,
                          relationship: guardian.relationship,
                          active: guardian.active,
                          guardian: {
                            id: guardian.guardian.id,
                            firstName: guardian.guardian.firstName,
                            lastName: guardian.guardian.lastName,
                            birthdate: guardian.guardian.birthdate,
                            sex: guardian.guardian.sex,
                            email: guardian.guardian.email,
                            phone: guardian.guardian.phone,
                            address: guardian.guardian.address,
                          },
                        });
                      }}
                    />
                  );
                }
              )}
            </>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={7}>
                <Message content="This patient does not have any associated guardian." />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Modal open={openGuardianForm} onClose={handleClose} closeIcon>
        <Modal.Header>
          {selectedGuardian?.id ? 'Edit Guardian' : 'Add Guardian'}
        </Modal.Header>
        <Modal.Content>
          <GuardianForm
            guardianPatient={selectedGuardian ? selectedGuardian : null}
            practicePatientId={practicePatientId}
            practicePatientTz={
              practicePatientData?.practicePatient.practice.timezone
            }
            patientAge={patientAge}
            patientId={
              practicePatientData?.practicePatient.patientData.id as string
            }
            onSave={() => {
              handleClose();
              refetch();
            }}
            onClose={handleClose}
          />
        </Modal.Content>
      </Modal>
    </Container>
  );
};

type PatientGuardiansListRowProps = {
  guardianPatient: GuardianPatient;
  onClickSelected: (selected: boolean) => void;
};

const PatientGuardiansListRow = ({
  guardianPatient,
  onClickSelected,
}: PatientGuardiansListRowProps) => {
  const [activeGuardian, setActiveguardian] = useState(guardianPatient.active);

  const [setActiveGuardian] = useMutation(SetActiveGuardianMutation);

  const handleSetActiveGuardian = async (active: boolean) => {
    setActiveguardian(!!active);
    try {
      await setActiveGuardian({
        variables: {
          guardianPatientId: guardianPatient.id,
          active,
        },
      });

      if (active) {
        toast({
          title: 'Guardian activated successfully',
          type: 'success',
          time: 2000,
        });
      } else {
        toast({
          title: 'Guardian disabled successfully',
          type: 'success',
          time: 2000,
        });
      }
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  return (
    <Table.Row>
      <Table.Cell>{`${guardianPatient.guardian.firstName} ${guardianPatient.guardian.lastName}`}</Table.Cell>
      <Table.Cell>
        {dateStrToMMDDYYYY(String(guardianPatient.guardian.birthdate), true)}
      </Table.Cell>
      <Table.Cell>{guardianPatient.guardian.email || '-'}</Table.Cell>
      <Table.Cell>{guardianPatient.guardian.phone || '-'}</Table.Cell>
      <Table.Cell>
        {humanizeText(guardianPatient.relationship, { capitalize: 'first' })}
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          toggle
          checked={activeGuardian}
          onChange={(_, { checked }) => {
            handleSetActiveGuardian(!!checked);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <Button primary icon="edit" onClick={() => onClickSelected(true)} />
          }
          content="Edit"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default PatientGuardiansList;
