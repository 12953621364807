import { Message } from "semantic-ui-react";

interface CustomErrorProps {
  message: string;
}

const CustomError: React.FC<CustomErrorProps> = ({ message }) => {
  return (
    <Message negative>
      <Message.Header>An error occurred</Message.Header>
      {message && <p> {message} </p>}
    </Message>
  );
};

export default CustomError;
