import { ReactNode, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Form,
  Header,
  Icon,
  Label,
  Modal,
  Segment,
  Radio,
} from 'semantic-ui-react';

import { toast } from 'react-semantic-toasts';

import GS1 from '@bluefox/lib/gs1';

import { Vaccine } from '@bluefox/models/Vaccine';
import { Inventory, InventoryStatus } from '@bluefox/models/Inventory';

import { usePractice } from '@bluefox/contexts/Practice';

import GunScanner from '@bluefox/ui/GunScanner';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import InventoryStatusPicker from '@bluefox/ui/InventoryStatusPicker';

import { VaccineByNDC10Query } from '@bluefox/graphql/vaccines';
import {
  InsertInventoryMutation,
  InventoryByIdQuery,
  UpdateInventoryMutation,
} from '@graphql/inventory';

import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { InsertInventoryOrderWithInventoryMutation } from '@bluefox/graphql/inventoryOrders';
import { useApplicationState } from '@bluefox/contexts/ApplicationState';

interface InventoryData {
  inventory: Inventory;
}
interface VaccinesData {
  vaccines: Vaccine[];
}

interface InventoryFormModalProps {
  children?: ReactNode;
  onSave?: () => void;
  inventoryId?: string;
}

const InventoryFormModal = ({
  children,
  onSave,
  inventoryId,
}: InventoryFormModalProps) => {
  const practice = usePractice();
  const { session } = useApplicationState();
  const history = useHistory();
  const currentRouteArr = history.location.pathname.split('/');
  const goBackRouteArr = currentRouteArr.slice(0, -1);

  const [gs1, setGs1] = useState<GS1>();
  const [scannedNdc, setScannedNdc] = useState<string>();

  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();
  const [lot, setLot] = useState<string>();
  const [alternativeLot, setAlternativeLot] = useState<string>();
  const [expiration, setExpiration] = useState<Date>();
  const [status, setStatus] = useState<InventoryStatus>(
    InventoryStatus.received
  );
  const [doses, setDoses] = useState<number>();
  const [vfc, setVfc] = useState<boolean>(false);
  const [shouldCreateOrder, setShouldCreateOrder] = useState<boolean>(true);
  const [timezone, setTimezone] = useState<string | undefined>();

  const { data: inventoryData } = useQuery<InventoryData>(InventoryByIdQuery, {
    variables: {
      id: inventoryId,
    },
    skip: !inventoryId,
  });

  const { data: vaccineByNdcData } = useQuery<VaccinesData>(
    VaccineByNDC10Query,
    {
      variables: {
        criteria: {
          active: { _eq: true },
          _or: [
            { saleNdc10: { _eq: scannedNdc } },
            { useNdc10: { _contains: scannedNdc } },
          ],
        },
      },
      skip: !scannedNdc,
    }
  );

  const [open, setOpen] = useState(!!inventoryId);

  useEffect(() => {
    setOpen(!!inventoryId);
  }, [inventoryId]);

  useEffect(() => {
    if (!inventoryData) return;

    const { inventory } = inventoryData;
    if (!inventory) return;

    const tz = inventory.practice?.timezone || moment.tz.guess();
    const transFormedDate = inventory.expiration
      ? moment.tz(inventory.expiration, tz).toDate()
      : undefined;

    setTimezone(tz);
    setSelectedVaccine(inventory.vaccine);
    setLot(inventory.lot);
    setExpiration(transFormedDate);
    setStatus(inventory.status);
    setDoses(inventory.doses);
    setVfc(inventory.vfc);
    setAlternativeLot(inventory.alternativeLotNumber?.[0]);
  }, [inventoryData]);

  useEffect(() => {
    setSelectedVaccine(
      vaccineByNdcData?.vaccines.length
        ? vaccineByNdcData?.vaccines[0]
        : undefined
    );
  }, [vaccineByNdcData]);

  useEffect(() => {
    if (!gs1) return;

    setScannedNdc(gs1.getNdc());
    setLot(gs1.getLot());
    setExpiration(gs1.getExp());
  }, [gs1]);

  const handleonScan = (value: string) => {
    if (!value) return;
    setGs1(new GS1(value));
  };

  const getMutationToUse = () => {
    if (inventoryId) {
      return UpdateInventoryMutation;
    }
    if (shouldCreateOrder) {
      return InsertInventoryOrderWithInventoryMutation;
    }
    return InsertInventoryMutation;
  };

  const [saveInventory] = useMutation(getMutationToUse());

  const handleSubmit = () => {
    cleanAndClose();
    const now = new Date();

    const noInventoryOrderCriteria = {
      variables: {
        ...(inventoryId ? { id: inventoryId } : { practiceId: practice.id }),
        vaccineId: selectedVaccine?.id,
        lot,
        expiration,
        alternativeLotNumber: [alternativeLot],
        status,
        doses,
        vfc,
      },
    };

    const existInventoryOrderCriteria = {
      variables: {
        practiceId: practice.id,
        amount: 0,
        date: now,
        doses,
        inventoryExpiration: expiration,
        lot,
        alternativeLotNumber: [alternativeLot],
        packagesAmount: 0,
        paid: false,
        orderNumber: 'onboarding',
        statusInventory: status,
        statusOrder: 'closed',
        statusLog: [
          {
            status: 'closed',
            date: now,
            practiceAccount: session?.account,
          },
        ],
        vaccineId: selectedVaccine?.id,
        vfc,
      },
    };

    const params =
      inventoryId || !shouldCreateOrder
        ? noInventoryOrderCriteria
        : existInventoryOrderCriteria;

    saveInventory(params).then(() => {
      if (onSave) onSave();
      toast({
        title: 'Inventory saved',
        color: 'green',
        time: 1000,
      });
    });
  };

  const cleanAndClose = () => {
    setOpen(false);
    setGs1(undefined);
    setScannedNdc(undefined);
    setSelectedVaccine(undefined);
    setLot('');
    setAlternativeLot('');
    setExpiration(undefined);
    setStatus(InventoryStatus.received);
    setDoses(0);
    setVfc(false);
    setShouldCreateOrder(true);
    if (inventoryId) history.push(`${goBackRouteArr.join('/')}`);
  };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={cleanAndClose}
      trigger={children}
      closeIcon
      size="small"
    >
      <Modal.Header>
        {inventoryId ? 'Edit' : 'Add'} Inventory Entry
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header as="h5">Product</Header>
          <Segment
            basic
            style={{
              padding: '0',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '1rem',
            }}
          >
            <GunScanner
              data-automation-id={`inventory-scan-button`}
              component={Button}
              renderOn={
                <>
                  <Icon name="stop" />
                  Scanning
                </>
              }
              renderOff={
                <>
                  <Icon name="qrcode" />
                  Search
                </>
              }
              onScan={handleonScan}
              icon
              labelPosition="left"
            />
            <VaccinePicker
              dropdownProps={{
                fluid: true,
                placeholder: 'Search Vaccine',
              }}
              onChange={({ vaccine }) => setSelectedVaccine(vaccine)}
              value={selectedVaccine?.id}
            />
          </Segment>
          {!!selectedVaccine && (
            <Segment color="olive">
              <Header as="h4">
                <Header.Content>
                  {selectedVaccine.name}
                  {selectedVaccine.types?.map((t) => (
                    <Label key={t} color="orange" size="mini">
                      {t}
                    </Label>
                  ))}
                </Header.Content>
                <Header.Subheader>
                  {selectedVaccine.manufacturer} - {selectedVaccine.saleNdc}
                </Header.Subheader>
              </Header>
            </Segment>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                label="Lot #"
                placeholder="XXXXXX"
                value={lot || ''}
                onChange={(_, { value }) => setLot(value.toUpperCase())}
                required
              />
              <Form.Input
                label="Alternative Lot #"
                placeholder="XXXXXX"
                value={alternativeLot || ''}
                onChange={(_, { value }) =>
                  setAlternativeLot(value.toUpperCase())
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Doses"
                placeholder="100"
                type="number"
                value={doses ?? ''}
                onChange={(_, { value }) => setDoses(parseInt(value ?? 0))}
                required
              />
              <Form.Field required>
                <label>Expiration</label>
                <DateTimePicker
                  tz={timezone}
                  selected={expiration}
                  onChange={(value) => setExpiration(value as Date)}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label>VFC</label>
                <Form.Checkbox
                  checked={vfc}
                  label="Public funded"
                  onChange={(_, { checked }) => setVfc(checked ?? false)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Input required label="Status" width={3}>
              <InventoryStatusPicker
                value={status}
                onChange={({ status }) => setStatus(status)}
              />
            </Form.Input>

            <Form.Group>
              {!inventoryId && (
                <Form.Field>
                  <Radio
                    toggle
                    label={'Create Order'}
                    onChange={(e, { checked }) =>
                      setShouldCreateOrder(checked || false)
                    }
                    checked={shouldCreateOrder}
                  ></Radio>
                </Form.Field>
              )}
            </Form.Group>

            <Modal.Actions>
              <Button
                type="submit"
                content="Save"
                icon="save"
                primary
                style={{ float: 'right' }}
                disabled={!selectedVaccine}
              />
              <Button
                type="button"
                content="Close"
                icon="close"
                onClick={cleanAndClose}
                style={{ float: 'right', marginBottom: '1rem' }}
              />
            </Modal.Actions>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default InventoryFormModal;
