import { useState, useEffect } from 'react';
import { toast } from 'react-semantic-toasts';
import { useMutation } from '@apollo/client';

import { Button, Form } from 'semantic-ui-react';

import {
  InsertDeveloMappingMutation,
  UpdateDeveloMappingMutation,
} from '@bluefox/graphql/integrations/develo';
import { MappedItem } from '@bluefox/models/integrations/Develo';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';

interface InsuranceCompanyMappingFormProps {
  onClose: () => void;
  data: MappedItem | undefined;
  refetchMappings: () => void;
  mappedKeys: string[];
  isEdition: boolean;
}

const InsuranceCompanyMappingForm = ({
  onClose,
  data,
  refetchMappings,
  mappedKeys,
  isEdition,
}: InsuranceCompanyMappingFormProps) => {
  const [develoKey, setDeveloKey] = useState<string>(data?.key ? data.key : '');
  const [value, setValue] = useState({
    data: data?.value.data || '',
    name: data?.value.name || '',
  });

  const [saveInsuranceCompanyMapping] = useMutation(
    data?.id ? UpdateDeveloMappingMutation : InsertDeveloMappingMutation
  );

  const handleSubmit = () => {
    const key = develoKey.toLowerCase().trim();
    if (mappedKeys.includes(key) && !isEdition && data?.key !== key) {
      toast({
        title: 'Insurance company is already mapped.',
        type: 'error',
        time: 5000,
      });
      onClose();
      return;
    }

    saveInsuranceCompanyMapping({
      variables: {
        ...(data?.id ? { id: data.id } : {}),
        key: key,
        type: 'insuranceCompany',
        value: value,
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        onClose();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={develoKey}
            onChange={(_, { value }) => setDeveloKey(value)}
            fluid
            label="Develo Insurance Company"
            placeholder="Develo Insurance Company"
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Select Insurance</label>
          <InsuranceCompanyPicker
            placeholder="Insurance Name"
            value={data?.value.data || value.data}
            onCompanySelected={(company) => {
              setValue({
                data: company.id,
                name: company.name,
              });
            }}
            includePayerId
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} type="button">
          Cancel
        </Button>
        <Button primary type="submit" content="Save" icon="save" />
      </Form.Field>
    </Form>
  );
};

export default InsuranceCompanyMappingForm;
