import { COLORS_BY_NAME, pixelToRem } from '@bluefox/constants';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

type Props = {
  percentage: number;
};

const StyledSpan = styled.span<{ percentage: number }>`
  border-radius: 4px;
  padding: ${pixelToRem(4)}rem ${pixelToRem(8)}rem;
  color: white;
  font-size: ${pixelToRem(12)}rem;
  font-weight: 700;
  line-height: ${16 / 12};
  letter-spacing: 0.25px;
  vertical-align: middle;
  background-color: ${({ percentage }) => {
    return percentage === 0
      ? COLORS_BY_NAME['Carolina Blue']
      : percentage > 0
      ? COLORS_BY_NAME['Tiffany Blue']
      : COLORS_BY_NAME['Permanent Geranium Lake'];
  }};
`;

const PercentageIcon = ({ percentage }: Props) => {
  return (
    <StyledSpan percentage={percentage}>
      {percentage}%
      {percentage !== 0 && (
        <>
          {' '}
          <Icon name={percentage > 0 ? 'arrow up' : 'arrow down'} />
        </>
      )}
    </StyledSpan>
  );
};

export default PercentageIcon;
