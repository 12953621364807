import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { SaveInvoiceDescriptionMutation } from '@graphql/billing';
import { BillingInvoice } from '@bluefox/models/Billing';
import { Modal, Form, Button } from 'semantic-ui-react';

interface InvoiceDescriptionModalProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  invoice?: BillingInvoice;
  onSave: () => void;
}

const InvoiceDescriptionModal = ({
  open,
  onOpen,
  onClose,
  invoice,
  onSave,
}: InvoiceDescriptionModalProps) => {
  const [description, setDescription] = useState<string>();

  const [saveInvoiceDescription] = useMutation(SaveInvoiceDescriptionMutation);

  const handleSaveInvoiceDescription = () => {
    if (!invoice) return;

    saveInvoiceDescription({
      variables: {
        invoiceId: invoice.id,
        description,
      },
    })
      .then((r) => {
        toast({
          title: 'Memo saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
        onClose();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!invoice) return;
    setDescription(invoice?.description ?? '');
  }, [invoice]);

  return (
    <>
      <Modal
        size="tiny"
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        closeIcon
      >
        <Modal.Header>
          {invoice && !description ? 'Set Email Memo' : 'Edit Email Memo'}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.TextArea
              value={description}
              onChange={(_, { value }) => setDescription(value as string)}
              label="Email Memo"
              placeholder="Email memo..."
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            primary
            content="Save"
            labelPosition="right"
            icon="save"
            onClick={handleSaveInvoiceDescription}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default InvoiceDescriptionModal;
