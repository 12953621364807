import { usDollarNoDigitsCurrency } from '@bluefox/lib/formatters';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledTableFooter = styled(Table)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.8rem;
  margin: 0rem !important;
  background-color: #fafafa !important;
  height: 2.5rem;
  border: 1px solid lightgrey;
`;

type Props = {
  billableAmount?: number;
  reviewedAmount?: number;
  totalAmount?: number;
};

const BillingReportFooter = ({
  billableAmount,
  reviewedAmount,
  totalAmount,
}: Props) => {
  return (
    <StyledTableFooter style={{ height: '5.5rem' }}>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <b>{`Total: $${totalAmount ?? 0}`}</b>
              <b>{`Total Billable selected: ${usDollarNoDigitsCurrency(
                billableAmount ?? 0
              )}`}</b>
              <b>{`Total Reviewed selected: ${usDollarNoDigitsCurrency(
                reviewedAmount ?? 0
              )}`}</b>
            </div>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </StyledTableFooter>
  );
};

export default BillingReportFooter;
