import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';

interface TagProps {
  name?: string;
  color?: string;
  onClickDeleteTag?: () => void;
  clearable?: boolean;
}

const Tag = (props: TagProps) => {
  const { name, color, onClickDeleteTag, clearable } = props;
  return (
    <StyledLabel image size="tiny" basic={!color} color={color}>
      {name}
      {clearable && <Icon onClick={onClickDeleteTag} name="delete" />}
    </StyledLabel>
  );
};

const StyledLabel = styled(Label)`
  background-color: ${(props) => props.color} !important;
`;

export default Tag;
