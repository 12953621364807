import { InsuranceRelationShips } from './Insurances';

export interface Address {
  address1: string;
  city?: string;
  state?: string;
  postalCode: string;
}

export interface Provider {
  employerId: string;
  npi: string;
  organizationName?: string;
  firstName?: string;
  lastName?: string;
  address: Address;
  taxonomyCode?: string;
}

export interface ServiceLine {
  serviceDate: string;
  renderingProviderId?: string;
  placeOfService: string;
  professionalService: {
    lineItemChargeAmount: number;
    procedureCode: string;
    procedureModifiers?: string[];
    serviceUnitCount: string;
    diagnosisPointer: string;
  };
}
export interface Holder {
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
}

export enum Gender {
  M = 'M',
  F = 'F',
}

export interface Patient {
  memberId: string;
  firstName?: string;
  lastName?: string;
  gender?: 'F' | 'M';
  dateOfBirth?: string;
  address?: Address;
  paymentResponsibilityLevelCode?: string;
  relationshipToInsured: InsuranceRelationShips;
  holder?: Holder;
}
export interface HealthCareCodeInformation {
  diagnosisTypeCode: string;
  diagnosisCode: string;
}

export interface ClaimPreview {
  payer: {
    name: string;
    cpid: string;
    id: string;
  };
  claimInformation: {
    claimChargeAmount: number;
    signatureIndicator: string;
    claimFilingCode: string;
    serviceLines: ServiceLine[];
    healthCareCodeInformation: [HealthCareCodeInformation];
  };
  provider: Provider;
  patient: Patient;
  rendering?: Provider;
}
