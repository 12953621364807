import { PatientData, PracticePatient } from '@bluefox/models/Patient';
import React, { useContext } from 'react';

const practicePatientContext = React.createContext<PracticePatient | undefined>(
  undefined
);

type PracticePatientProviderProps = {
  children: React.ReactNode;
  practicePatient?: PracticePatient;
};

export const PracticePatientProvider = ({
  children,
  practicePatient,
}: PracticePatientProviderProps) => {
  return (
    <practicePatientContext.Provider value={practicePatient}>
      {children}
    </practicePatientContext.Provider>
  );
};

export const usePracticePatient = () => {
  return useContext(practicePatientContext);
};
