import { useEffect, useState } from 'react';
import { Button, Dropdown, Input } from 'semantic-ui-react';

export enum ThresholdOperator {
  _lte = '_lte',
  _eq = '_eq',
  _gte = '_gte',
}

export interface Threshold {
  operator: ThresholdOperator;
  operand: number;
}

interface ThresholdInputProps {
  value?: Threshold;
  defaultOperand?: number;
  defaultOperator: ThresholdOperator;
  onChange: (value?: Threshold | undefined) => void;
  clearable?: boolean;
}

const ThresholdInput = ({
  value,
  defaultOperand,
  defaultOperator,
  onChange,
  clearable,
}: ThresholdInputProps) => {
  const [operand, setOperand] = useState<number | undefined>(
    value?.operand || defaultOperand
  );
  const [operator, setOperator] = useState<ThresholdOperator>(
    value?.operator || defaultOperator || ThresholdOperator._lte
  );

  useEffect(() => {
    onChange(
      operand !== undefined
        ? {
            operand,
            operator,
          }
        : undefined
    );
  }, [operand, operator, onChange]);

  return (
    <Input
      label={
        <Dropdown
          defaultValue={operator}
          onChange={(_, { value }) => setOperator(value as ThresholdOperator)}
          options={[
            {
              key: '_lte',
              text: 'Lower than or equal to',
              value: '_lte',
            },
            { key: '_eq', text: 'Equal to', value: '_eq' },
            {
              key: '_gte',
              text: 'Greather than or equal to',
              value: '_gte',
            },
          ]}
        />
      }
      type="number"
      value={operand || ''}
      onChange={(_, { value }) =>
        setOperand(value ? parseInt(value) : undefined)
      }
      action={
        clearable ? (
          <Button icon="close" basic onClick={() => setOperand(undefined)} />
        ) : undefined
      }
    />
  );
};

export default ThresholdInput;
