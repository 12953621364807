import { SemanticColorToString } from '@bluefox/constants';
import PieBarTwoSeriesChart from './PieBarTwoSeriesChart';

type Props = {
  privateAmount: number;
  vfcAmount: number;
};

const VaccinationOverviewChart = ({ privateAmount, vfcAmount }: Props) => {
  return (
    <PieBarTwoSeriesChart
      chartName="Vaccination Overview"
      colors={[SemanticColorToString('blue'), SemanticColorToString('teal')]}
      series={[
        {
          value: privateAmount,
          name: 'Private',
        },
        {
          value: vfcAmount,
          name: 'VFC',
        },
      ]}
    />
  );
};

export default VaccinationOverviewChart;
