//------------------------------VaxSyncRow------------------------------//
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
  EmrUploadedByMutation,
  RegistryReportedByMutation,
} from '@bluefox/graphql/vaccinations';

import { Dropdown, Table, Label, Popup, Button, Icon } from 'semantic-ui-react';

import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';

import {
  emrOptions,
  cirOptions,
  VaccinationView,
  ActionType,
} from '@bluefox/models/Vaccination';
import {
  StyledDivCol,
  StyledEmptyRoute,
  StyledEmptySite,
  StyledRoute,
  StyledSite,
} from './vaxSync.style';
import { useCallback } from 'react';
import VaccineInfoBox from '@ui/Vaccines/VaccineInfoBox';
import VaccinationNotesPopup from '@ui/VaccinationNotesPopup';
import { COLORS_BY_NAME } from '@bluefox/constants/colors';
import { Notification, NotificationStatus } from '@bluefox/models/Notification';
import { notificationStatusEnumToBeShownText } from '@bluefox/constants/notifications';

const actionLevelColor = (action: ActionType, modified: boolean) => {
  return modified && action === ActionType.INSERT
    ? COLORS_BY_NAME['Very Pale Yellow']
    : action === ActionType.INSERT
    ? COLORS_BY_NAME['Tea Green']
    : COLORS_BY_NAME['Snow'];
};

const actionLevelColorHover = (action: ActionType, modified: boolean) => {
  return modified && action === ActionType.INSERT
    ? COLORS_BY_NAME['Yellow (Crayola)']
    : action === ActionType.INSERT
    ? COLORS_BY_NAME['Granny Smith Apple']
    : COLORS_BY_NAME['Light Red'];
};

interface DosageType {
  dose: number;
  unit: string;
}

interface VaxSyncRowProps {
  data: VaccinationView;
  onSave: () => void;
  onSendNotification: () => void;
  notificationsLoading: boolean;
  notifications?: Notification[];
}

const VaxSyncRow = ({
  data,
  onSave,
  onSendNotification,
  notificationsLoading,
  notifications,
}: VaxSyncRowProps) => {
  const [saveEmrUploadedBy] = useMutation(EmrUploadedByMutation);
  const [saveCirReportedBy] = useMutation(RegistryReportedByMutation);

  const handleSaveEmrUploadedBy = (option: string) => {
    saveEmrUploadedBy({
      variables: {
        id: data.id,
        option,
      },
    })
      .then((r) => {
        toast({
          title: 'Upload to EMR saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleSaveCirReportedBy = (option: string) => {
    saveCirReportedBy({
      variables: {
        id: data.id,
        option,
      },
    })
      .then((r) => {
        toast({
          title: 'Report to CIR saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const inventoryUsedLabelText = () => {
    if (data.inventoryVfc) return 'VFC';
    return 'Private';
  };

  const vfcDataToShow = useCallback(() => {
    if (data.vfcCriteria) {
      return `VFC Eligible (${humanizeText(data.vfcCriteria, {
        capitalize: 'first',
      })})`;
    }
    return 'VFC Eligible';
  }, [data.vfcCriteria]);

  const getConsentForIis = (data: VaccinationView | undefined) => {
    if (!data || data?.consentForIis === null) {
      return '-';
    }
    return data?.consentForIis ? 'Yes' : 'No';
  };

  const renderSendNotificationButton = useCallback(() => {
    const notification = notifications?.find((n) => n.entityRefId === data.id);
    return (
      <>
        <Popup
          content={`${
            notificationStatusEnumToBeShownText[
              notification?.status as NotificationStatus
            ] || 'Send a notification'
          }`}
          trigger={
            <Button
              style={{ margin: '0.1rem' }}
              loading={notificationsLoading}
              icon="send"
              size="mini"
              color={notification ? 'green' : 'blue'}
              onClick={onSendNotification}
            />
          }
        />
        {notification?.status && (
          <p style={{ marginBottom: '1rem' }}>
            <b>Notification Status:</b>
            {` ${
              notificationStatusEnumToBeShownText[
                notification?.status as NotificationStatus
              ]
            }`}
          </p>
        )}
      </>
    );
  }, [data.id, notifications, notificationsLoading, onSendNotification]);

  return (
    <StyledTableRow action={data.action} modified={data.modified}>
      <StyledTableCell>
        {moment(data.createdAt).format(DateFormats.DATE)}
      </StyledTableCell>
      <StyledTableCell>
        {moment(data.givenAt).format(DateFormats.DATE)}
        {data.visPublicationDate ? (
          <Label
            style={{ margin: '0' }}
            basic
            content={`VIS publication date: ${data.visPublicationDate}`}
            size="tiny"
          />
        ) : null}
      </StyledTableCell>
      <StyledTableCell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{`${data.patientLastName} ${data.patientFirstName} (${data.patientBirthdate})`}</div>
          <Label basic size="tiny" content={`MRN: ${data.mrn || '-'}`} />
          <div>
            <b>Address: </b>
            {`${data.patientAddress.street}, 
          ${data.patientAddress.city} - 
          ${data.patientAddress.state}, 
          ${data.patientAddress.zip}`}
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        {data.insuranceCompanyName ? (
          <div>
            <div>{data.insuranceCompanyName}</div>
            <div>
              <Label
                basic
                content={`Member ID: ${data.insuranceMemberId || '-'}`}
                size="tiny"
              />
              {data.vfcEligible ? (
                <Label
                  content={vfcDataToShow()}
                  size="tiny"
                  color="orange"
                  style={{ marginTop: '0.2rem' }}
                />
              ) : null}
            </div>
          </div>
        ) : (
          '-'
        )}
      </StyledTableCell>
      <StyledTableCell>
        <StyledDivCol>
          {data.primaryVaccinationId ? (
            <Popup
              trigger={
                <Icon
                  name="warning sign"
                  color="yellow"
                  style={{ margin: '0 auto' }}
                />
              }
              content="Associated"
              position="top center"
            />
          ) : null}
          {data.vaccineName}
          <VaccineInfoBox
            ndc={data.saleNdc || '_'}
            manufacturer={data.manufacturer || '_'}
            types={data.types?.map((t: string) => t) || '_'}
            dosage={
              data.dosage?.map((item: DosageType) => {
                return `${item.dose}${item.unit}`;
              }) || '_'
            }
            inventoryUsed={inventoryUsedLabelText() || '_'}
            lot={data.lot || '-'}
            expiration={data.inventoryExpiration || '_'}
          />
        </StyledDivCol>
      </StyledTableCell>
      <StyledTableCell>
        {data.prescriberFirstName
          ? `${data.prescriberFirstName} ${data.prescriberLastName} (${data.prescriberTitle})`
          : '-'}
      </StyledTableCell>
      <StyledTableCell>
        {data.givenByFirstName
          ? `${data.givenByFirstName} ${data.givenByLastName} (${data.givenByTitle})`
          : '-'}
      </StyledTableCell>
      <StyledTableCell>
        <Label
          color="teal"
          size="tiny"
          style={{
            padding: '0',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {data.route ? (
              <StyledRoute>
                {humanizeText(data.route.toString(), {
                  delimiter: '_',
                  capitalize: 'all',
                })}
              </StyledRoute>
            ) : (
              <StyledEmptyRoute>-</StyledEmptyRoute>
            )}
            {data.site ? (
              <StyledSite>
                {humanizeText(data.site.toString(), {
                  delimiter: '_',
                  capitalize: 'all',
                })}
              </StyledSite>
            ) : (
              <StyledEmptySite>-</StyledEmptySite>
            )}
          </div>
        </Label>
      </StyledTableCell>
      <StyledTableCell>
        <Dropdown
          style={{
            backgroundColor:
              data.emrUploadedBy !== 'pending' ? '#fcfff5' : '#fff0d8',
            borderColor:
              data.emrUploadedBy !== 'pending' ? '#558555' : '#f5714b',
            maxWidth: '6.5rem',
          }}
          fluid
          selection
          options={emrOptions}
          value={data.emrUploadedBy}
          onChange={(e, data) => {
            handleSaveEmrUploadedBy(data.value?.toString() || '');
          }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Dropdown
          style={{
            backgroundColor:
              data.registryReportedBy !== 'pending' ? '#fcfff5' : '#fff0d8',
            borderColor:
              data.registryReportedBy !== 'pending' ? '#558555' : '#f5714b',
            maxWidth: '6.5rem',
          }}
          fluid
          selection
          options={cirOptions}
          value={data.registryReportedBy}
          onChange={(e, data) => {
            handleSaveCirReportedBy(data.value?.toString() || '');
          }}
        />
      </StyledTableCell>
      <StyledTableCell textAlign="center">
        <p>{getConsentForIis(data)}</p>
      </StyledTableCell>
      <StyledTableCell textAlign="center">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{renderSendNotificationButton()}</div>
          <div>
            <VaccinationNotesPopup
              style={{ margin: '0.1rem' }}
              vaccinationId={data.id}
              vaccinationNote={data.notes?.vaxSync}
              onSave={onSave}
            />
          </div>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const StyledTableRow = styled(Table.Row)`
  background-color: ${({ action, modified }) =>
    actionLevelColor(action, modified)};

  &:hover {
    background-color: ${({ action, modified }) =>
      actionLevelColorHover(action, modified)};
  }
`;

const StyledTableCell = styled(Table.Cell)`
  padding: 0.5rem !important;
`;

export default VaxSyncRow;
