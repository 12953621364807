import React, { useState, useCallback } from 'react';
import { Button, Modal } from 'semantic-ui-react';

type Props = {
  open: boolean;
  header?: string;
  message?: string;
  onOK?: () => void;
};

const InformationModal = ({ open, header, message, onOK }: Props) => {
  return (
    <Modal onClose={onOK} open={open} size="tiny">
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>{message}</Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => onOK?.()}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const useInformationModalAwait = () => {
  const [infoModal, setInfoModal] = useState<Props>({ open: false });
  const render = useCallback(
    () => (
      <InformationModal
        open={infoModal?.open || false}
        header={infoModal?.header || ''}
        message={infoModal?.message || ''}
        onOK={() => {
          setInfoModal({ open: false });
          infoModal?.onOK?.();
        }}
      />
    ),
    [infoModal]
  );

  const open = useCallback(
    (message: string, header?: string) =>
      new Promise<void>((resolve) => {
        setInfoModal({
          open: true,
          header,
          message,
          onOK: () => resolve(),
        });
      }),
    [setInfoModal]
  );
  return {
    render,
    open,
  };
};

export default InformationModal;
