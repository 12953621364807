import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@bluefox/contexts/Auth';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
import {
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useApplicationState } from '@bluefox/contexts/ApplicationState';
import styled from 'styled-components';

interface LocationState {
  from: {
    pathname: string;
  };
}

const StyledGrid = styled(Grid)`
  height: 80vh;
`;
const StyledGridColumn = styled(Grid.Column)`
  max-width: 450px;
`;

const StyledErrorLabel = styled.label`
  color: red;
  display: block;
  margin-bottom: 1rem;
`;
const SignInScreen = () => {
  const location = useLocation<LocationState>();
  const { messages } = useApplicationState();

  const { isAuthenticated, actions } = useAuth();
  const [signIn, { loading, error, data }] = actions.signIn;
  const [signInError, setSignInError] = useState<string>('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSignIn = useCallback(
    async (email: string, password: string) => {
      //TODO: refactor any type
      const singInResponse: any = await signIn(email, password);
      if (singInResponse?.errors)
        setSignInError('Incorrect Username or password');

      return singInResponse;
    },
    [signIn]
  );

  const handleSubmit = useCallback(() => {
    handleSignIn(email, password);
  }, [email, password, handleSignIn]);

  useEffect(() => {
    if (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: 'Error signing in.',
        type: 'error',
        time: 5000,
      });
    }
  }, [error, data]);

  if (isAuthenticated) {
    return <Redirect to={location.state?.from.pathname || `/`} />;
  }

  return (
    <Container>
      <StyledGrid textAlign="center" verticalAlign="middle">
        <StyledGridColumn>
          {messages.map(({ content, ...type }) => (
            <Message {...type} content={content} />
          ))}
          <Header
            as="h2"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Image
              src="/canid-dark-logo.png"
              alt=""
              style={{ width: '140px' }}
            />{' '}
            Sign in
          </Header>
          <Form
            size="large"
            onSubmit={handleSubmit}
            error={!!signInError}
            data-automation-id="sing-in-form"
          >
            <Segment textAlign="left">
              <Form.Field>
                <label>Email</label>
                <Form.Input
                  required
                  type="email"
                  data-automation-id="sing-in-form-email"
                  onChange={(_, { value }) => setEmail(value)}
                  value={email}
                  placeholder="Your Email..."
                  disabled={loading}
                />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <Form.Input
                  required
                  data-automation-id="sing-in-form-password"
                  type="password"
                  value={password}
                  onChange={(_, { value }) => setPassword(value)}
                  placeholder="Your password..."
                  disabled={loading}
                />
              </Form.Field>

              {signInError && (
                <StyledErrorLabel>{signInError}</StyledErrorLabel>
              )}
              <Form.Button
                style={{ backgroundColor: '#16324F', color: '#ffffff' }}
                fluid
                size="large"
                icon
                labelPosition="right"
                loading={loading}
                disabled={loading}
                data-automation-id="sing-in-form-sing-button"
              >
                Sign in
                <Icon name="sign in" />
              </Form.Button>
            </Segment>
          </Form>
        </StyledGridColumn>
      </StyledGrid>
    </Container>
  );
};

export default SignInScreen;
