import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { MappingsQuery } from '@bluefox/graphql/integrations/ecw';
import { MappedItem } from '@bluefox/models/Mappings';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import {
  Segment,
  Card,
  Container,
  Table,
  Placeholder,
  Message,
  Button,
  Popup,
  Label,
  Modal,
  Menu,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import VaccineMappingForm from '@ui/EcwIntegration/mappings/EcwVaccineMappingForm';

interface EmrMappingData {
  mappings: MappedItem[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const EcwVaccineMapping = () => {
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedVaccineMapping, setSelectedVaccineMapping] =
    useState<MappedItem>();
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);
  const [criteria, setCriteria] = useState({});
  const [searchPractice, setSearchPractice] = useState<string>();
  const [practiceId, setPracticeId] = useState<string>();

  const { data, loading, refetch } = useQuery<EmrMappingData>(MappingsQuery, {
    variables: {
      criteria,
    },
  });

  const closeModal = () => {
    setIsSelected(false);
    setSelectedVaccineMapping(undefined);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  const handlePracticeValue = (value: string) => {
    const practiceValue =
      !!value && value !== 'allPractices' ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeId(practiceValue);
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  useEffect(() => {
    if (!data?.allPractices || data.allPractices.length < 1) return;

    setPracticeOptions(
      data.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;

    if (searchPractice) {
      _practiceId = { _eq: practiceId };
    }

    _criteria = {
      type: {
        _eq: 'vaccine',
      },
      practice: {
        id: _practiceId,
      },
    };

    setCriteria(_criteria);
  }, [searchPractice]);

  return (
    <MainLayout
      path={[
        { text: 'ECW Integration', to: '/integration-ecw' },
        { text: 'Vaccine Mapping' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="map outline" style={{ marginRight: '0.6rem' }} />
                Vaccine Mapping
              </Card.Header>
            </div>
            <Menu borderless>
              <Menu.Item>
                <Dropdown
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by practice"
                  fluid
                  selection
                  onChange={(e, data) => {
                    handlePracticeValue(data.value?.toString() || '');
                  }}
                  options={[
                    { text: 'All practices', value: 'allPractices' },
                    ...practiceOptions,
                  ]}
                />
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    primary
                    size="small"
                    content="Add Vaccine"
                    icon="plus"
                    onClick={() => {
                      setIsSelected(false);
                      setOpen(true);
                    }}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Practice</Table.HeaderCell>
                  <Table.HeaderCell>NDC</Table.HeaderCell>
                  <Table.HeaderCell>EMR ID</Table.HeaderCell>
                  <Table.HeaderCell>Vaccine Name (EMR)</Table.HeaderCell>
                  <Table.HeaderCell>Vaccine Name (Canid)</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data && data.mappings.length ? (
                  data.mappings.map((vm) => {
                    return (
                      <EcwVaccineMappingRow
                        key={vm.id}
                        data={vm}
                        onChangeSelected={() => {
                          handleChangeSelected(true);
                          setSelectedVaccineMapping({
                            id: vm.id,
                            key: vm.key,
                            metadata: vm.metadata,
                            type: vm.type,
                            vaccineInfo: vm.vaccineInfo,
                            practice: vm.practice,
                          });
                        }}
                      />
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      <Message>No mappings found.</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Modal
              size="small"
              onClose={closeModal}
              onOpen={() => setOpen(open)}
              open={open}
              closeIcon
            >
              <Modal.Header>
                {isSelected ? 'Edit Vaccine Mapping' : 'Add Vaccine Mapping'}
              </Modal.Header>
              <Modal.Content>
                <VaccineMappingForm
                  close={closeModal}
                  data={selectedVaccineMapping ? selectedVaccineMapping : null}
                  practices={data?.allPractices}
                  refetchMappings={refetch}
                />
              </Modal.Content>
            </Modal>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//------------------------------EcwVaccineMappingRow------------------------------//

interface EcwVaccineMappingRowProps {
  data: MappedItem;
  onChangeSelected: (selected: boolean) => void;
}

const EcwVaccineMappingRow = ({
  data,
  onChangeSelected,
}: EcwVaccineMappingRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>{data?.practice?.name}</Table.Cell>
      <Table.Cell>{data?.key}</Table.Cell>
      <Table.Cell>
        {data?.metadata?.map((item) => (
          <p style={{ margin: '0.5rem 0 0.5rem 0' }}>{item.keyEmr}</p>
        ))}
      </Table.Cell>
      <Table.Cell>
        {data.metadata.map((item) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0.3rem 0 0.3rem 0',
            }}
          >
            {item?.nameEmr}
            {item?.doseNumber ? (
              <Label
                basic
                size="small"
                content={`Dose: ${item.doseNumber}`}
                style={{ marginLeft: '0.5rem' }}
              />
            ) : null}
          </div>
        ))}
      </Table.Cell>
      <Table.Cell>
        {data?.vaccineInfo ? data?.vaccineInfo?.name : '-'}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default EcwVaccineMapping;
