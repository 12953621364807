import { PatientData } from '@bluefox/models/Patient';
import React, { useContext } from 'react';

const patientDataContext = React.createContext<PatientData | undefined>(
  undefined
);

type PatientDataProviderProps = {
  children: React.ReactNode;
  patientData?: PatientData;
};

export const PatientDataProvider = ({
  children,
  patientData,
}: PatientDataProviderProps) => {
  return (
    <patientDataContext.Provider value={patientData}>
      {children}
    </patientDataContext.Provider>
  );
};

export const usePatientData = () => {
  return useContext(patientDataContext);
};
