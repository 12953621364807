import { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';

import {
  ProcedureAmountsQuery,
  BillingClaimsCount,
  BillingControlView,
} from '@graphql/billing';

import { BillablePracticesQuery } from '@graphql/practices';

import {
  billingClaimStatusMenuOptions,
  BillingClaimStatus,
  BillingClaimStatusMenuOption,
  BillingControl,
} from '@bluefox/models/Billing';

import { Practice } from '@bluefox/models/Practice';

import {
  Card,
  Icon,
  Menu,
  Message,
  Table,
  Button,
  Container,
  Dropdown,
  Input,
  Grid,
  Radio,
  Modal,
  DropdownProps,
  Segment,
  Placeholder,
} from 'semantic-ui-react';

import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import ClaimModal from '@ui/ClaimModal/ClaimModal';
import UpdateBillingClaimForm from '@ui/Billing/UpdateBillingClaimForm';
import BillingExcelExportModal from '@ui/Billing/BillingExcelExportModal';
import PatientFormModal from '@ui/PatientFormModal';
import InsurancesList, { InsuranceListProp } from '@bluefox/ui/InsurancesList';
import Control2ClaimsTable from './control2ClaimsTable';
import { TagsSelector } from '@ui/Tags';
import { DateFormats } from '@bluefox/models/Dates';
import { addDays } from '@bluefox/lib/formatters/dates';
import { whereLikeInput } from '@bluefox/graphql/utils';

interface PracticeOption {
  text: string;
  value: string;
}
enum SortableColumns {
  PRACTICE = 'practiceName',
  PATIENT = 'fullName',

  INSURANCE_COMPANY = 'insuranceName',
  SERVICE_DATE = 'givenAt',
  STATUS = 'status',
  AMOUNT = 'totalClaimAmount',
}

interface Cpt {
  cpt: string;
}

interface ClaimsCountData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface practicesData {
  practices: Practice[];
}

interface BillingControlData {
  billingControlClaims: BillingControl[];
}

const ENTRIES_PER_PAGE = 15;

const Control2Screen = () => {
  const { claimId } = useParams<{ claimId: string }>();
  const history = useHistory();
  const billingClaimsRef = useRef(null);

  const [practiceId, setPracticeId] = useState<string>();
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [criteria, setCriteria] = useState<object>({});
  const [searchPractice, setSearchPractice] = useState<[string]>();
  const [searchPatient, setSearchPatient] = useState<string>();
  const [searchInsurance, setSearchInsurance] = useState<string>();
  const [searchProvider, setSearchProvider] = useState<string>();
  const [searchRhcNumber, setSearchRhcNumber] = useState<string>();
  const [searchMemberId, setSearchMemberId] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<[string]>();

  const [insuranceCompanyId, setInsuranceCompanyId] = useState<
    string | undefined
  >();
  const [searchFieldToCorrect, setSearchFieldToCorrect] = useState<
    string | undefined
  >();
  const [searchBilled, setSearchBilled] = useState<string | undefined>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [updatedAtFromDate, setUpdatedAtFromDate] = useState<
    Date | null | undefined
  >(null);
  const [updatedAtToDate, setUpdatedAtToDate] = useState<
    Date | null | undefined
  >(null);

  const [searchByTag, setSearchByTag] = useState<string[]>([]);
  const [exactTagList, setExactTagList] = useState(false);

  const [highlightPracticeDropdown, setHighlightPracticeDropdown] =
    useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [isQuerying, setIsQuerying] = useState(false);

  const [totalClaimsLoading, setTotalClaimsLoading] = useState(false);

  const [showInfoMsg, setShowInfoMsg] = useState(true);

  const [selectedPracticePatientId, setSelectedPracticePatientId] =
    useState('');

  const [isEditInsuranceModalOpened, setIsEditInsuranceModalOpened] =
    useState(false);
  const [editInsuranceModalData, setEditInsuranceModalData] =
    useState<InsuranceListProp>({});

  const [sortingColumn, setSortingColumn] = useState<
    SortableColumns | undefined
  >(undefined);
  const [sortDirection, setSortDirection] = useState<
    'ascending' | 'descending' | undefined
  >('descending');

  const { data: practicesData } = useQuery<practicesData>(
    BillablePracticesQuery
  );

  const [
    getClaims,
    {
      data: billingControlData,
      loading: billingControlLoading,
      error: billingControlError,
      refetch: billingControlRefetch,
    },
  ] = useLazyQuery<BillingControlData>(BillingControlView);

  const { data: procedureAmountsData } = useQuery<ProcedureAmountsData>(
    ProcedureAmountsQuery,
    {
      variables: {
        type: 'Administration',
      },
    }
  );

  const handleSearchOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      runQuery();
    }
  };

  const runQuery = () => {
    if (!sortingColumn) {
      setSortingColumn(SortableColumns.SERVICE_DATE);
      return;
    }
    getClaims({
      variables: {
        orderBy: {
          [sortingColumn]: sortDirection === 'ascending' ? 'asc' : 'desc',
        },
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!pageCount ? ENTRIES_PER_PAGE * pageCount : null,
      },
    });
  };

  const [getClaimsCount, { data: claimsCount, loading: claimsCountLoading }] =
    useLazyQuery<ClaimsCountData>(BillingClaimsCount);

  const cleanSubStatus = () => {
    setSearchBilled(undefined);
    setSearchFieldToCorrect(undefined);
  };

  const handlePracticeValue = (value: [string]) => {
    const practiceValue =
      !value?.length || value.includes('allPractices') ? undefined : value;
    setSearchPractice(practiceValue);

    if (practiceValue) {
      practiceValue[0] && setPracticeId(practiceValue[0]);
    }
    setShowInfoMsg(true);
  };

  const handleStatusValue = (value: [string]) => {
    const statusValue =
      !value?.length || value.includes('allStatus') ? undefined : value;
    setSearchStatus(statusValue);
    setShowInfoMsg(true);
    cleanSubStatus();
  };

  useEffect(() => {
    let _criteria = {};

    const buildTagFilterCriteria = () => {
      return {
        [exactTagList ? '_has_keys_all' : '_has_keys_any']: searchByTag,
      };
    };

    _criteria = {
      _and: [
        {
          ...(searchByTag.length > 0 ? { tags: buildTagFilterCriteria() } : {}),
        },
        {
          ...(searchStatus ? { status: { _in: searchStatus } } : {}),
        },
        {
          ...(searchFieldToCorrect
            ? { fieldToCorrect: { _eq: searchFieldToCorrect } }
            : {}),
        },
        {
          ...(searchPatient
            ? {
                fullName: {
                  _ilike: whereLikeInput(searchPatient),
                },
              }
            : {}),
        },
        {
          ...(searchProvider
            ? {
                _or: [
                  {
                    prescriberFirstName: {
                      _ilike: whereLikeInput(searchProvider),
                    },
                  },
                  {
                    prescriberLastName: {
                      _ilike: whereLikeInput(searchProvider),
                    },
                  },
                ],
              }
            : {}),
        },
        {
          ...(searchPractice ? { practiceId: { _in: searchPractice } } : {}),
        },
        {
          ...(searchFromDate || searchToDate
            ? {
                givenAt: {
                  _gt: searchFromDate
                    ? addDays(searchFromDate, -1)
                    : searchFromDate,
                  _lte: searchToDate ?? new Date(),
                },
              }
            : {}),
        },
        {
          ...(updatedAtFromDate || updatedAtToDate
            ? {
                updatedAt: {
                  _gte: updatedAtFromDate,
                  _lte: updatedAtToDate ?? new Date(),
                },
              }
            : {}),
        },
        {
          ...(searchInsurance
            ? { insuranceName: { _ilike: whereLikeInput(searchInsurance) } }
            : {}),
        },
        {
          ...(searchBilled ? { countAdminCptCodesUnpaid: { _gt: 0 } } : {}),
        },
        {
          ...(searchRhcNumber
            ? { rhcClaimNumber: { _ilike: whereLikeInput(searchRhcNumber) } }
            : {}),
        },
        {
          ...(searchMemberId
            ? { insuranceMemberId: { _ilike: whereLikeInput(searchMemberId) } }
            : {}),
        },
      ],
    };

    setTotalClaimsLoading(false);
    setPageCount(0);
    setCriteria(_criteria);
  }, [
    searchPractice,
    searchPatient,
    searchStatus,
    searchInsurance,
    searchFromDate,
    searchToDate,
    updatedAtFromDate,
    updatedAtToDate,
    searchProvider,
    insuranceCompanyId,
    searchBilled,
    searchRhcNumber,
    searchMemberId,
    searchFieldToCorrect,
    searchByTag,
    exactTagList,
  ]);

  useEffect(() => {
    if (
      !practicesData ||
      !practicesData.practices ||
      practicesData.practices.length < 1
    )
      return;

    setPracticeOptions(
      practicesData.practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [practicesData]);

  const onCloseInsuranceListModalHandler = () => {
    setEditInsuranceModalData({});
    setIsEditInsuranceModalOpened(false);
  };

  useEffect(() => {
    if (pageCount < 0 || !isQuerying) return;
    runQuery();
  }, [pageCount]);

  const sortStatuses = (
    statuses: BillingClaimStatusMenuOption[]
  ): BillingClaimStatusMenuOption[] => {
    return statuses.sort((a, b) =>
      a.text > b.text ? 1 : b.text > a.text ? -1 : 0
    );
  };

  useEffect(() => {
    if (Object.keys(editInsuranceModalData).length === 0) return;
    setIsEditInsuranceModalOpened(true);
  }, [editInsuranceModalData]);

  const sortingHandler = (column: SortableColumns) => {
    if (!billingControlData) {
      return;
    }
    if (sortingColumn !== column) {
      setSortingColumn(column);
      setSortDirection('descending');
      return;
    }
    if (sortDirection === 'descending') {
      setSortDirection('ascending');
      return;
    }
    if (sortDirection === 'ascending') {
      setSortingColumn(SortableColumns.SERVICE_DATE);
      setSortDirection('descending');
    }
  };

  const firstLoad = useRef(0);
  useEffect(() => {
    if (firstLoad.current === 0) {
      firstLoad.current++;
      return;
    }
    runQuery();
  }, [sortingColumn, sortDirection]);

  return (
    <>
      <MainLayout
        path={[{ text: 'Billing', to: '/billing2' }, { text: 'Control' }]}
      >
        <Container>
          <Card fluid style={{ marginTop: '1rem' }}>
            <Card.Content>
              <Card.Header as={'h3'}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Icon name="dollar" style={{ marginRight: '0.6rem' }} />
                    Billing Control
                  </div>
                  <div>
                    <Dropdown text="Actions">
                      <Dropdown.Menu>
                        <ClaimModal
                          practices={practiceOptions}
                          trigger={
                            <Dropdown.Item
                              content="Create Claim"
                              icon="plus"
                              disabled
                            />
                          }
                        />
                        <Dropdown.Item
                          content="Analytics"
                          icon="chart line"
                          as={Link}
                          to={`${history.location.pathname}/analytics`}
                        />
                        <Dropdown.Item
                          content="Vaccines Price List"
                          icon="dollar"
                          as={Link}
                          to={`${history.location.pathname}/vaccines-price-list`}
                        />
                        <Dropdown.Item
                          content="Self Pay List"
                          icon="file alternate outline"
                          as={Link}
                          to={`${history.location.pathname}/self-pay-list`}
                        />
                        <Dropdown.Item
                          content="Review Vaccinations"
                          icon="search"
                          as={Link}
                          to={`/billing2/control/review`}
                        />

                        <Dropdown.Item
                          content="Borrowing"
                          icon="info circle"
                          as={Link}
                          to={`${history.location.pathname}/borrowing`}
                        />

                        {!searchPractice || searchPractice?.length !== 1 ? (
                          <Dropdown.Item
                            content="Billing Report"
                            icon="file alternate outline"
                            onClick={() => {
                              setHighlightPracticeDropdown(true);
                              toast({
                                title: 'Please select just one Practice',
                                type: 'warning',
                                time: 1000,
                              });
                            }}
                          />
                        ) : (
                          <Dropdown.Item
                            content="Billing Report"
                            icon="file alternate outline"
                            as={Link}
                            to={`${history.location.pathname}/practice/${practiceId}/report`}
                          />
                        )}
                        <Dropdown.Item
                          content="Invoices"
                          icon="file alternate outline"
                          as={Link}
                          to={`/new-billing/invoices`}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Card.Header>
              <BillingExcelExportModal criteria={criteria} />
              <Card.Description>
                {showInfoMsg && (
                  <Message
                    warning
                    header="Important information!"
                    content="Please click the green Search button to run the query with the selected filters."
                  />
                )}
                <Menu
                  borderless
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <Input
                              placeholder="Search patient..."
                              value={searchPatient}
                              onChange={(_, { value }) => {
                                setSearchPatient(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Input
                              placeholder="Search insurance..."
                              value={searchInsurance}
                              onChange={(_, { value }) => {
                                setSearchInsurance(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Input
                              placeholder="Search provider..."
                              value={searchProvider}
                              onChange={(_, { value }) => {
                                setSearchProvider(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <StyledDropdown
                              highlight={
                                highlightPracticeDropdown
                                  ? 'highlight 0.5s linear 10'
                                  : null
                              }
                              onAnimationEnd={() =>
                                setHighlightPracticeDropdown(false)
                              }
                              placeholder="Filter by practice"
                              search
                              fluid
                              selection
                              multiple
                              onChange={(
                                e: HTMLElement,
                                data: DropdownProps
                              ) => {
                                setHighlightPracticeDropdown(false);
                                handlePracticeValue(data.value as [string]);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                              options={[
                                {
                                  text: 'All practices',
                                  value: 'allPractices',
                                },
                                ...practiceOptions,
                              ]}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Dropdown
                              style={{ minWidth: '15rem' }}
                              placeholder="Filter by status"
                              search
                              fluid
                              multiple
                              selection
                              onChange={(e, data) => {
                                handleStatusValue(data.value as [string]);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                              options={[
                                { text: 'All status', value: 'allStatus' },
                                ...sortStatuses(billingClaimStatusMenuOptions),
                              ]}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <Input
                              placeholder="Search by member ID"
                              value={searchMemberId}
                              onChange={(_, { value }) => {
                                setSearchMemberId(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Input
                              placeholder="Search by RHC number"
                              value={searchRhcNumber}
                              onChange={(_, { value }) => {
                                setSearchRhcNumber(value);
                                setShowInfoMsg(true);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <label
                              style={{ marginRight: '0.5rem', width: '8rem' }}
                            >
                              <b>Service Date</b>
                            </label>
                            <DateTimePicker
                              data-automation-id="billing-from-date"
                              placeholderText="From"
                              selected={searchFromDate}
                              onChange={(d) => {
                                setSearchFromDate(d ? (d as Date) : undefined);
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchFromDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setSearchFromDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <DateTimePicker
                              data-automation-id="billing-to-date"
                              placeholderText="To"
                              selected={searchToDate}
                              onChange={(d) => {
                                setSearchToDate(d ? (d as Date) : undefined);
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setSearchToDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setSearchToDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <label
                              style={{ marginRight: '0.5rem', width: '9rem' }}
                            >
                              <b>Updated At</b>
                            </label>
                            <DateTimePicker
                              data-automation-id="billing-from-date"
                              placeholderText="From"
                              selected={updatedAtFromDate}
                              onChange={(d) => {
                                setUpdatedAtFromDate(
                                  d ? (d as Date) : undefined
                                );
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setUpdatedAtFromDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setUpdatedAtFromDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <DateTimePicker
                              data-automation-id="billing-to-date"
                              placeholderText="To"
                              selected={updatedAtToDate}
                              onChange={(d) => {
                                setUpdatedAtToDate(d ? (d as Date) : undefined);
                                setShowInfoMsg(true);
                              }}
                              onSelect={(value) =>
                                setUpdatedAtToDate(
                                  value ? (value as Date) : undefined
                                )
                              }
                              onClear={() => setUpdatedAtToDate(undefined)}
                              maxDate={new Date()}
                              dateFormat={DateFormats.DATE}
                              showYearDropdown
                              showMonthDropdown
                              scrollableYearDropdown
                              dropdownMode="select"
                              isClearable
                              disabled={searchStatus?.includes(
                                BillingClaimStatus.UNCLAIMED
                              )}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                        <Grid verticalAlign="middle" padded>
                          <Grid.Row>
                            <Grid.Column width={15}>
                              <TagsSelector
                                onChange={(tagsSelected) =>
                                  setSearchByTag(tagsSelected)
                                }
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Radio
                                toggle
                                checked={exactTagList}
                                onChange={() => setExactTagList(!exactTagList)}
                                label="Strict"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column width={4} textAlign="right">
                        <Grid.Row>
                          <Menu.Menu>
                            <Menu.Item>
                              <Button
                                fluid
                                content="Search"
                                icon="search"
                                color="olive"
                                onClick={() => {
                                  setIsQuerying(true);
                                  runQuery();
                                  setShowInfoMsg(false);
                                }}
                              />
                            </Menu.Item>
                          </Menu.Menu>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Menu>
                {billingControlError ? (
                  <Message error>{billingControlError.message}</Message>
                ) : (
                  <div ref={billingClaimsRef}>
                    <Table selectable sortable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.PRACTICE
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.PRACTICE)
                            }
                            content={
                              <>
                                Practice
                                {sortingColumn !== SortableColumns.PRACTICE && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.PATIENT
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.PATIENT)
                            }
                            content={
                              <>
                                Patient
                                {sortingColumn !== SortableColumns.PATIENT && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                            width={3}
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn ===
                              SortableColumns.INSURANCE_COMPANY
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.INSURANCE_COMPANY)
                            }
                            content={
                              <>
                                Insurance Company
                                {sortingColumn !==
                                  SortableColumns.INSURANCE_COMPANY && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.SERVICE_DATE
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.SERVICE_DATE)
                            }
                            content={
                              <>
                                Service Date
                                {sortingColumn !==
                                  SortableColumns.SERVICE_DATE && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                            width={2}
                          />
                          <Table.HeaderCell
                            sorted={
                              sortingColumn === SortableColumns.AMOUNT
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.AMOUNT)
                            }
                            content={
                              <>
                                Amount
                                {sortingColumn !== SortableColumns.AMOUNT && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />

                          <Table.HeaderCell
                            textAlign="center"
                            sorted={
                              sortingColumn === SortableColumns.STATUS
                                ? sortDirection
                                : undefined
                            }
                            onClick={() =>
                              sortingHandler(SortableColumns.STATUS)
                            }
                            content={
                              <>
                                Status
                                {sortingColumn !== SortableColumns.STATUS && (
                                  <Icon name="sort" />
                                )}
                              </>
                            }
                          />
                        </Table.Row>
                      </Table.Header>

                      {billingControlLoading ? (
                        <Table.Row>
                          <Table.Cell colSpan={7}>
                            <Segment basic>
                              <Placeholder fluid>
                                <Placeholder.Header>
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                </Placeholder.Header>
                              </Placeholder>
                            </Segment>
                          </Table.Cell>
                        </Table.Row>
                      ) : billingControlData?.billingControlClaims ? (
                        <Control2ClaimsTable
                          claims={billingControlData.billingControlClaims}
                          onClaimUpdate={billingControlRefetch}
                          onPatientSelection={() =>
                            console.log('onPatientSelected')
                          }
                          onEditInsuranceClick={() =>
                            console.log('OnEditInsuranceClick')
                          }
                          refetch={billingControlRefetch}
                          procedureAmountsData={procedureAmountsData}
                        />
                      ) : (
                        <Table.Body>
                          <Table.Cell colSpan={7}>
                            <Message>
                              Click the green "Search" button to start searching
                              claims.
                            </Message>
                          </Table.Cell>
                        </Table.Body>
                      )}
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Button
                              basic
                              content={
                                !totalClaimsLoading
                                  ? 'Show total'
                                  : `Total: ${claimsCount?.aggregating.aggregate.count}`
                              }
                              loading={claimsCountLoading}
                              onClick={() => {
                                getClaimsCount({
                                  variables: {
                                    criteria,
                                  },
                                });
                                setTotalClaimsLoading(true);
                              }}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan={6} textAlign="right">
                            <Button
                              basic
                              icon="angle left"
                              onClick={() => setPageCount(pageCount - 1)}
                              disabled={pageCount === 0}
                            />
                            <Button
                              basic
                              icon="angle right"
                              onClick={() => setPageCount(pageCount + 1)}
                              disabled={
                                !billingControlData?.billingControlClaims
                                  ?.length ||
                                billingControlData.billingControlClaims
                                  ?.length < ENTRIES_PER_PAGE
                              }
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </div>
                )}
              </Card.Description>
            </Card.Content>
          </Card>
        </Container>
        <PatientFormModal
          practPatientId={selectedPracticePatientId}
          onClose={() => setSelectedPracticePatientId('')}
          onCancel={() => setSelectedPracticePatientId('')}
          onSave={() => {
            setSelectedPracticePatientId('');
            billingControlRefetch();
          }}
        />

        <Modal
          closeIcon
          onClose={onCloseInsuranceListModalHandler}
          open={isEditInsuranceModalOpened}
        >
          <InsurancesList
            propPracticePatientId={editInsuranceModalData.propPracticePatientId}
          />
        </Modal>
      </MainLayout>
      {!!claimId && (
        <UpdateBillingClaimForm
          claimId={claimId}
          adminCptCodes={procedureAmountsData}
          onClose={() => {
            history.push('/billing2/control');
          }}
          onSave={() => {
            billingControlRefetch();
            history.push('/billing2/control');
          }}
        />
      )}
    </>
  );
};

const StyledDropdown = styled(Dropdown)`
  min-width: 15rem !important;

  animation: ${(props) => props.highlight};

  @keyframes highlight {
    0% {
      box-shadow: 0 0 0px red;
    }

    25% {
      box-shadow: 0 0 2px red;
    }

    50% {
      box-shadow: 0 0 6px red;
    }

    75% {
      box-shadow: 0 0 2px red;
    }

    100% {
      box-shadow: 0 0 0px red;
    }
  }
`;

export default Control2Screen;
