import { useQuery } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react';
import {
  InsuranceCompaniesQuery,
  SelectedInsuranceCompaniesQuery,
} from '@bluefox/graphql/settings';
import { InsuranceCompany } from '@bluefox/models/Insurances';

interface SelectedInsuranceCompaniesData {
  companies: InsuranceCompany[];
}

interface InsuranceCompaniesData {
  companies: InsuranceCompany[];
  favoriteCompanies: InsuranceCompany[];
}

interface InsuranceCompanyPickerProps {
  value?: string | string[];
  valueData?: SelectedInsuranceCompaniesData;
  valueLoading?: boolean;
  onChange?: (value: string | string[]) => void;
  onCompanySelected?: (company: InsuranceCompany) => void;
  disabled?: boolean;
  multiple?: boolean;
  placeholder?: string;
  includePayerId?: boolean;
}

const getDropdownItemProps = (
  c: InsuranceCompany,
  icon?: boolean
): DropdownItemProps => ({
  text: `${c.name} (Payer ID: ${c.payerId}) (CPID: ${c.cpid})`,
  content: (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && <Icon name="star" />}
        <p style={{ marginBottom: '0' }}>{c.name}</p>
      </div>
      <p style={{ fontSize: '12px', fontWeight: '600' }}>
        Payer ID: {c.payerId} | CPID: {c.cpid}
      </p>
    </>
  ),
});

const InsuranceCompanyPicker = ({
  value,
  onChange = () => {},
  onCompanySelected = () => {},
  disabled,
  multiple,
  placeholder,
  includePayerId = false,
}: InsuranceCompanyPickerProps) => {
  const practice = usePractice();

  const [input, setInput] = useState('');
  const [options, setOptions] = useState<
    (DropdownItemProps & { company?: InsuranceCompany })[]
  >([]);

  const [selectedValue, setSelectedValue] = useState<
    string | string[] | undefined
  >(multiple ? [] : undefined);

  const [favoriteCompaniesIds, setFavoriteCompaniesId] = useState<
    string | string[]
  >([]);

  useEffect(() => {
    setFavoriteCompaniesId(
      practice.publicSettings?.favoriteInsuranceCompanies ?? []
    );
  }, [practice]);

  const { data: valueData, loading: valueLoading } =
    useQuery<SelectedInsuranceCompaniesData>(SelectedInsuranceCompaniesQuery, {
      variables: {
        ids: Array.isArray(value) ? value : [value],
      },
      skip: !value?.length,
    });

  const { data, loading } = useQuery<InsuranceCompaniesData>(
    InsuranceCompaniesQuery,
    {
      variables: {
        input: input.length > 2 ? `%${input}%` : '',
        favoriteInput: `%${input}%`,
        favoriteCompaniesIds,
      },
      skip: input.length < 3 && !favoriteCompaniesIds.length,
    }
  );

  useEffect(() => {
    if (data) {
      setOptions([
        ...(valueData
          ? valueData.companies.map((company) => {
              if (includePayerId) {
                return {
                  key: `v-${company.id}`,
                  value: company.id,
                  ...getDropdownItemProps(company),
                  company,
                };
              } else {
                return {
                  key: `v-${company.id}`,
                  value: company.id,
                  text: company.name,
                  company,
                };
              }
            })
          : []),
        ...(data.favoriteCompanies.length
          ? [
              ...data.favoriteCompanies.map((c) => {
                if (includePayerId) {
                  return {
                    key: `f-${c.id}`,
                    value: c.id,
                    ...getDropdownItemProps(c, true),
                    company: c,
                  };
                } else {
                  return {
                    key: `f-${c.id}`,
                    value: c.id,
                    text: c.name,
                    company: c,
                    icon: 'star',
                  };
                }
              }),
              {
                key: 'divider',
                value: 'divider',
                disabled: true,
                text: '...',
                company: undefined,
              },
            ]
          : []),
        ...data.companies.map((c) => {
          if (includePayerId) {
            return {
              key: c.id,
              value: c.id,
              ...getDropdownItemProps(c),
              company: c,
              icon: favoriteCompaniesIds.includes(c.id) ? 'star' : undefined,
            };
          } else {
            return {
              key: c.id,
              value: c.id,
              text: c.name,
              company: c,
              icon: favoriteCompaniesIds.includes(c.id) ? 'star' : undefined,
            };
          }
        }),
      ]);
    } else if (valueData) {
      setOptions(
        valueData.companies.map((company) => ({
          key: `v-${company.id}`,
          value: company.id,
          ...getDropdownItemProps(company),
          company,
        }))
      );
    }
  }, [valueData, data, favoriteCompaniesIds]);

  useEffect(() => {
    if (!valueData?.companies.length) {
      return;
    }

    setSelectedValue(
      multiple
        ? valueData?.companies?.map((company) => company.id) || undefined
        : valueData?.companies[0].id
    );
  }, [valueData, multiple]);

  useEffect(() => {
    if (!selectedValue || !options) return;
    const companyId = multiple ? selectedValue.at(-1) : selectedValue;

    const selectedCompany = options.find(({ company }) => {
      return company && company.id === companyId;
    });

    if (selectedCompany?.company) onCompanySelected(selectedCompany.company);
  }, [selectedValue, options]);

  return (
    <Dropdown
      selection
      search
      fluid
      placeholder={placeholder}
      multiple={multiple}
      onSearchChange={(_, { searchQuery }) => setInput(searchQuery as string)}
      value={selectedValue}
      disabled={disabled || valueLoading || loading}
      onChange={(_, { value }) => {
        const formattedValue = Array.isArray(value)
          ? (value as string[])
          : (value as string);
        setSelectedValue(formattedValue);
        onChange(formattedValue);
      }}
      options={options}
      loading={valueLoading || loading}
      clearable
    />
  );
};

export default InsuranceCompanyPicker;
