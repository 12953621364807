import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  Header,
  Input,
  Menu,
  Message,
  Modal,
  Placeholder,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import OutOfNetworkMappingForm from './OutOfNetworkMappingForm';
import { MappedOutOfNetwork } from '@bluefox/models/Mappings';
import { OutOfNetworkMappingsByPracticeQuery } from '@graphql/mappings';
import { usePractice } from '@bluefox/contexts';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { debounce } from '@bluefox/lib/debounce';

interface OutOfNetworkMappingsData {
  mappingOon: MappedOutOfNetwork[];
}

const OutOfNetworkMapping = () => {
  const practice = usePractice();

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<MappedOutOfNetwork>();
  const [npiSearchInput, setNpiSearchInput] = useState<string>('');
  const [npiSearchQueryValue, setNpiSearchQueryValue] = useState<string>('');
  const [insuranceSearchInput, setInsuranceSearchInput] = useState<string>('');
  const [insuranceSearchQueryValue, setInsuranceSearchQueryValue] =
    useState<string>('');
  const [criteria, setCriteria] = useState({});

  const { data, loading, refetch } = useQuery<OutOfNetworkMappingsData>(
    OutOfNetworkMappingsByPracticeQuery,
    {
      variables: {
        criteria,
      },
    }
  );

  const closeModal = () => {
    setSelectedMapping({
      id: '',
      practiceAccount: undefined,
      npi: '',
      insuranceCompany: undefined,
      notes: '',
    });
    setIsSelected(false);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  useEffect(() => {
    let _criteria = {};

    _criteria = {
      practiceId: { _eq: practice.id },
      npi: {
        _ilike: whereLikeInput(npiSearchQueryValue),
      },
      insuranceCompany: {
        name: {
          _ilike: whereLikeInput(insuranceSearchQueryValue),
        },
      },
    };

    setCriteria(_criteria);
  }, [npiSearchQueryValue, insuranceSearchQueryValue, practice.id]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <>
      <Menu borderless>
        <Menu.Item>
          <Header>Out of Network</Header>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Input
              icon="search"
              placeholder="Search NPI..."
              value={npiSearchInput}
              onChange={(_, { value }) => {
                setNpiSearchInput(value);
                debouncedRef.current?.cancel();
                debouncedRef.current = debounce(() => {
                  setNpiSearchQueryValue(value);
                }, 500);
                debouncedRef.current();
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <Input
              icon="search"
              placeholder="Search insurance..."
              value={insuranceSearchInput}
              onChange={(_, { value }) => {
                setInsuranceSearchInput(value);
                debouncedRef.current?.cancel();
                debouncedRef.current = debounce(() => {
                  setInsuranceSearchQueryValue(value);
                }, 500);
                debouncedRef.current();
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <Button
              primary
              size="small"
              content="Add OON Mapping"
              icon="plus"
              onClick={() => {
                setIsSelected(false);
                setOpen(true);
              }}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Provider</Table.HeaderCell>
            <Table.HeaderCell>NPI</Table.HeaderCell>
            <Table.HeaderCell>Inurance</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Notes</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data?.mappingOon.length ? (
            data.mappingOon.map((item) => {
              return (
                <OutOfNetworkMappingRow
                  key={item.id}
                  data={item}
                  onChangeSelected={() => {
                    handleChangeSelected(true);
                    setSelectedMapping({
                      id: item.id,
                      practiceAccount: item.practiceAccount,
                      npi: item.npi,
                      insuranceCompany: item.insuranceCompany,
                      notes: item.notes,
                    });
                  }}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Message>No mappings found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Modal size="small" onClose={closeModal} open={open} closeIcon>
        <Modal.Header>
          {isSelected ? 'Edit OON Mapping' : 'Add OON Mapping'}
        </Modal.Header>
        <Modal.Content>
          <OutOfNetworkMappingForm
            close={closeModal}
            practiceId={practice.id}
            data={selectedMapping}
            refetchMappings={refetch}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

//-------------------------OutOfNetworkMappingRow-------------------------//

interface OutOfNetworkMappingRowProps {
  data: MappedOutOfNetwork;
  onChangeSelected: (selected: boolean) => void;
}

const OutOfNetworkMappingRow = ({
  data,
  onChangeSelected,
}: OutOfNetworkMappingRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        {data.practiceAccount
          ? `${data.practiceAccount?.account.firstName} ${data.practiceAccount?.account.lastName}`
          : '-'}
      </Table.Cell>
      <Table.Cell>{data.npi || '-'}</Table.Cell>
      <Table.Cell>{data.insuranceCompany?.name || '-'}</Table.Cell>
      <Table.Cell textAlign="center">
        {data.notes ? (
          <Popup
            basic
            trigger={<Button size="tiny" content="Show" />}
            on="click"
            content={data.notes || '-'}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default OutOfNetworkMapping;
