import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import {
  Notification,
  OrderNotificationContent,
} from '@bluefox/models/Notification';
import { Label, Table } from 'semantic-ui-react';
import { NotificationListTableRowChild } from '@bluefox/ui/Notifications/NotificationListTable/common';
import OrderNotificationMessage from '@bluefox/ui/Notifications/NotificationMessage/OrderNotificationMessage';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';

type Props = {
  notification: Notification;
  children?: ReactElement<NotificationListTableRowChild>;
  internalTools?: boolean;
};

const OrderNotificationListTableRow = ({
  notification,
  children,
  internalTools,
}: Props) => {
  const content = useMemo(
    () => notification.content as OrderNotificationContent,
    [notification.content]
  );

  const renderChildren = useCallback(() => {
    return Children.map(children, (child: ReactNode) => {
      if (isValidElement<NotificationListTableRowChild>(child))
        return cloneElement<NotificationListTableRowChild>(child, {
          notification,
        });
    });
  }, [notification]);

  return (
    <Table.Row>
      {internalTools && (
        <Table.Cell> {notification?.practice?.name ?? '-'}</Table.Cell>
      )}
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {content?.orderNumber ?? '-'}
          {content?.vfc !== undefined && (
            <Label
              size="tiny"
              color={content?.vfc ? 'orange' : 'teal'}
              content={content?.vfc ? 'VFC' : 'Private'}
            />
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {(notification?.content as OrderNotificationContent)?.purchaseDate
          ? moment(content?.purchaseDate).format(DateFormats.DATE)
          : '-'}
      </Table.Cell>
      <Table.Cell>{content?.fullVaccineName ?? '-'}</Table.Cell>
      <Table.Cell> {content?.doses ?? '-'}</Table.Cell>
      <Table.Cell>
        <OrderNotificationMessage notification={notification} />
      </Table.Cell>
      <Table.Cell>{renderChildren()}</Table.Cell>
    </Table.Row>
  );
};

export default OrderNotificationListTableRow;
