import { Card } from 'semantic-ui-react';
import {
  CardContent,
  CardHeader,
  CardSubHeader,
} from '@bluefox/ui/Dashboard/CommonStyles';
import OvertimeChart, { OvertimeChartData } from '../charts/OvertimeChart';
import { pixelToRem } from '@bluefox/constants';
import { InvoiceInfoType } from '@bluefox/models/Dashboard';
import { useMemo } from 'react';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';

type Props = { paidInvoices: InvoiceInfoType[] };

const OvertimeCard = ({ paidInvoices }: Props) => {
  const overtimeData: OvertimeChartData[] = useMemo(
    () =>
      paidInvoices.map((invoice) => ({
        date: formatDateToYYYYhMMhDD(new Date(invoice.date)),
        amount: invoice.amount,
      })),
    [paidInvoices]
  );
  return (
    <Card fluid style={{ height: '100%' }}>
      <CardContent>
        <CardHeader>Overtime</CardHeader>
        <CardSubHeader>TO DATE</CardSubHeader>
        <div style={{ marginTop: `${pixelToRem(35)}rem`, height: '250px' }}>
          {(!overtimeData || overtimeData.length === 0) && (
            <ChartErrorMessage>
              <p>There is not enough data to draw this chart</p>
            </ChartErrorMessage>
          )}
          {overtimeData?.length > 0 && (
            <OvertimeChart overtimeData={overtimeData} />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default OvertimeCard;
