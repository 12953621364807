import { parseFloatOrZero } from '@bluefox/lib/maths';
import { Button, Form, Grid, Header } from 'semantic-ui-react';

export type FormValues = {
  newDoses?: number;
  reason: string;
};

type Props = {
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  onSubmit?: (formValues: FormValues) => void;
  onCancel?: () => void;
  showNewDoses?: boolean;
  title?: string;
};

const InventoryAdjustmentTableRowForm = ({
  formValues,
  setFormValues,
  onSubmit,
  onCancel,
  showNewDoses = false,
  title,
}: Props) => {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(formValues);
      }}
    >
      <Grid>
        <Grid.Column width={14}>
          <Header>{title}</Header>
          <Form.Group>
            {showNewDoses && (
              <Form.Input
                value={formValues?.newDoses}
                onChange={(_, { value }) => {
                  setFormValues((prevVal) => ({
                    ...prevVal,
                    newDoses:
                      value !== '' ? parseFloatOrZero(value) : undefined,
                  }));
                }}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                width={4}
                fluid
                label="New Doses: "
                placeholder="New Doses in the Fridge"
              />
            )}
            <Form.Input
              type="text"
              width={16}
              fluid
              value={formValues?.reason || ''}
              label="Reason: "
              onChange={(_, { value }) => {
                setFormValues((prevVal) => ({
                  ...prevVal,
                  reason: value,
                }));
              }}
            />
          </Form.Group>
        </Grid.Column>
        <Grid.Column
          width={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button type="submit" primary content="Confirm" />
          <br />
          <Button
            type="button"
            negative
            onClick={() => {
              onCancel?.();
            }}
            content="Close"
          />
        </Grid.Column>
      </Grid>
    </Form>
  );
};

export default InventoryAdjustmentTableRowForm;
