import { gql } from '@apollo/client';

export const EmrMappingsQuery = gql`
  query EmrMappingsQuery($practiceId: uuid!, $type: String!) {
    mappings: bot_mappings(
      where: { practiceId: { _eq: $practiceId }, type: { _eq: $type } }
    ) {
      id
      key
      metadata
      vaccineInfo
    }
  }
`;

export const InsertMappingsMutation = gql`
  mutation InsertMappingsMutation(
    $practiceId: uuid!
    $key: String!
    $type: String!
    $metadata: jsonb!
  ) {
    insertMappings: insert_bot_mappings(
      objects: {
        practiceId: $practiceId
        key: $key
        type: $type
        metadata: $metadata
      }
    ) {
      affected_rows
    }
  }
`;

export const UpdateMappingMutation = gql`
  mutation UpdateMappingMutation(
    $id: uuid!
    $practiceId: uuid!
    $key: String!
    $metadata: jsonb!
  ) {
    update_bot_mappings_by_pk(
      pk_columns: { id: $id }
      _set: { key: $key, metadata: $metadata, practiceId: $practiceId }
    ) {
      id
      practiceId
      key
      metadata
    }
  }
`;

export const VaccineAdministratorsQuery = gql`
  query VaccineAdministratorsQuery($emails: [String!]) {
    accounts(where: { email: { _in: $emails } }) {
      email
      firstName
      lastName
      practiceAccounts {
        title
      }
    }
  }
`;

export const allowedVaxAmdinQuery = gql`
  query allowedVaxAmdinQuery($practiceId: uuid!) {
    accounts(
      where: {
        practiceAccounts: {
          title: { _neq: Other }
          practiceId: { _eq: $practiceId }
        }
      }
    ) {
      email
      firstName
      lastName
      practiceAccounts {
        title
      }
    }
  }
`;

export const InsertBillingStrategyMutation = gql`
  mutation InsertBillingStrategyMutation(
    $practiceId: uuid!
    $providerId: uuid
    $cpid: String
    $insuranceId: uuid
    $taxId: String
    $npi: String
    $strategy: String
    $practicePofileTaxId: jsonb
    $practiceCriteria: practices_set_input
    $practiceAccountPofileTaxId: jsonb
    $accountCriteria: String
    $profile: jsonb
  ) {
    insert_billing_claim_mappings(
      objects: {
        practiceId: $practiceId
        providerId: $providerId
        cpid: $cpid
        insuranceCompanyId: $insuranceId
        taxId: $taxId
        npi: $npi
        strategy: $strategy
        type: npi_taxid
      }
    ) {
      affected_rows
    }
    update_practices_by_pk(
      pk_columns: { id: $practiceId }
      _set: $practiceCriteria
      _append: { profile: $practicePofileTaxId }
    ) {
      id
      npi
      profile
    }
  }
`;

export const UpdateBillingStrategyMappingMutation = (
  updatePractice?: boolean
) => gql`
  mutation UpdateBillingStrategyMappingMutation(
    $id: uuid!
    $practiceId: ${updatePractice ? 'uuid!' : 'uuid'}
    $providerId: uuid
    $cpid: String
    $insuranceId: uuid
    $taxId: String
    $npi: String
    $strategy: String
    $practicePofileTaxId: jsonb
    $strategyCriteria: practices_set_input
    $practiceAccountPofileTaxId: jsonb
    $accountCriteria: String
    $profile: jsonb
  ) {
    update_billing_claim_mappings_by_pk(
      pk_columns: { id: $id }
      _set: {
        providerId: $providerId
        cpid: $cpid
        taxId: $taxId
        npi: $npi
        strategy: $strategy
        insuranceCompanyId: $insuranceId
      }
    ) {
      id
    }
    ${
      updatePractice
        ? `update_practices_by_pk(
      pk_columns: { id: $practiceId }
      _set: { npi: $npi}
      _append: { profile: $practicePofileTaxId }
    ) {
      id
      npi
      profile
    }`
        : ``
    }
  }
`;

export const PracticeInsuranceMappingsQuery = gql`
  query PracticeInsuranceMappingsQuery(
    $practiceId: uuid!
    $insuranceName: String
  ) {
    insurances: mapping_insurances(
      where: {
        practiceId: { _eq: $practiceId }
        _or: [
          { insuranceCompany: { name: { _ilike: $insuranceName } } }
          { insuranceCompanyId: { _is_null: true } }
        ]
      }
      order_by: { insuranceCompany: { name: asc }, createdAt: desc }
    ) {
      id
      nameEmr
      insuranceCompany {
        id
        name
        payerId
        cpid
      }
      insuranceCompanyForBilling {
        id
        name
        payerId
        cpid
      }
      claimMappingRendering {
        type
        enabled
      }
      claimMappingRenderingId
      claimMappingTaxonomy {
        type
        enabled
      }
      claimMappingTaxonomyId
      selfPay
    }
  }
`;

export const SaveInsuranceMappingMutation = gql`
  mutation SaveInsuranceMappingMutation(
    $object: [mapping_insurances_insert_input!]!
  ) {
    insert_mapping_insurances(
      objects: $object
      on_conflict: {
        constraint: insurances_pkey
        update_columns: [
          insuranceCompanyId
          nameEmr
          insuranceCompanyIdForBilling
          claimMappingTaxonomyId
          claimMappingRenderingId
          selfPay
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const HolderMappingsByPracticeQuery = gql`
  query HolderMappingsByPracticeQuery($practiceId: uuid!) {
    holders: mapping_holders(
      where: { practiceId: { _eq: $practiceId } }
      order_by: { nameEmr: asc }
    ) {
      id
      holderName
      nameEmr
      practiceId
    }
  }
`;

export const InsertHolderMappingMutation = gql`
  mutation InsertHolderMappingMutation(
    $practiceId: uuid!
    $holderName: String
    $nameEmr: String
  ) {
    insert_mapping_holders(
      objects: {
        practiceId: $practiceId
        holderName: $holderName
        nameEmr: $nameEmr
      }
    ) {
      affected_rows
    }
  }
`;

export const UpdateHolderMappingMutation = gql`
  mutation UpdateHolderMappingMutation(
    $id: uuid!
    $holderName: String
    $nameEmr: String
  ) {
    updateHolder: update_mapping_holders_by_pk(
      pk_columns: { id: $id }
      _set: { holderName: $holderName, nameEmr: $nameEmr }
    ) {
      id
      holderName
      nameEmr
    }
  }
`;

export const VfcMappingsByPracticeQuery = gql`
  query VfcMappingsByPracticeQuery($practiceId: uuid!) {
    mappingVfc: mapping_vfc(
      where: { practiceId: { _eq: $practiceId } }
      order_by: { emr: asc }
    ) {
      id
      practiceId
      emr
      vfcEligible
      vfcCriteria
    }
  }
`;

export const InsertVfcMappingMutation = gql`
  mutation InsertVfcMappingMutation(
    $emr: String
    $practiceId: uuid
    $vfcCriteria: vfc_criterias_enum
    $vfcEligible: Boolean
  ) {
    insert_mapping_vfc(
      objects: {
        emr: $emr
        practiceId: $practiceId
        vfcCriteria: $vfcCriteria
        vfcEligible: $vfcEligible
      }
    ) {
      affected_rows
    }
  }
`;

export const UpdateVfcMappingMutation = gql`
  mutation UpdateVfcMappingMutation(
    $id: uuid!
    $emr: String
    $vfcCriteria: vfc_criterias_enum
    $vfcEligible: Boolean
  ) {
    updateMappingVfcByPk: update_mapping_vfc_by_pk(
      pk_columns: { id: $id }
      _set: { emr: $emr, vfcCriteria: $vfcCriteria, vfcEligible: $vfcEligible }
    ) {
      id
      emr
      vfcEligible
      vfcCriteria
    }
  }
`;

export const VaccineMappingsByPracticeQuery = gql`
  query VaccineMappingsByPracticeQuery($practiceId: uuid!) {
    vaccines: mapping_vaccines(
      where: { practiceId: { _eq: $practiceId } }
      order_by: { nameEmr: asc }
    ) {
      id
      practiceId
      nameEmr
      vaccine {
        id
        name
        types
      }
    }
  }
`;

export const InsertVaccineMappingMutation = gql`
  mutation InserVaccineMappingMutation(
    $practiceId: uuid!
    $vaccineId: uuid
    $nameEmr: String
  ) {
    insert_mapping_vaccines(
      objects: {
        practiceId: $practiceId
        vaccineId: $vaccineId
        nameEmr: $nameEmr
      }
    ) {
      affected_rows
    }
  }
`;

export const UpdateVaccineMappingMutation = gql`
  mutation UpdateVaccineMappingMutation(
    $id: uuid!
    $vaccineId: uuid
    $nameEmr: String
  ) {
    updateVaccine: update_mapping_vaccines_by_pk(
      pk_columns: { id: $id }
      _set: { vaccineId: $vaccineId, nameEmr: $nameEmr }
    ) {
      id
      vaccine {
        id
        name
      }
      nameEmr
    }
  }
`;

export const GetBillingClaimsMappingByPractice = gql`
  query GetBillingClaimsMappingByPractice($practiceId: uuid!) {
    mappings: billing_claim_mappings(
      where: {
        practiceId: { _eq: $practiceId }
        providerId: { _is_null: true }
        payerId: { _is_null: true }
        type: { _eq: npi_taxid }
      }
    ) {
      id
    }
  }
`;

export const GetMappedVaccines = gql`
  query getMappedVaccines {
    vaccines: mapping_vaccines {
      id
      nameEmr
      vaccine {
        name
        cvx
        types
        manufacturer
        saleNdc
        id
      }
    }
  }
`;

export const GetMappedInsurances = gql`
  query getMappedInsurances {
    insurances: mapping_insurances {
      nameEmr
      insuranceCompany {
        payerId
        name
      }
    }
  }
`;

export const OutOfNetworkMappingsByPracticeQuery = gql`
  query OutOfNetworkMappingsByPracticeQuery(
    $criteria: mapping_out_of_network_bool_exp
  ) {
    mappingOon: mapping_out_of_network(
      where: $criteria
      order_by: { practiceAccount: { account: { firstName: asc } } }
    ) {
      id
      practiceId
      practiceAccount {
        id
        account {
          id
          firstName
          lastName
        }
      }
      npi
      notes
      insuranceCompany {
        id
        name
      }
    }
  }
`;

export const InsertOutOfNetworkMappingsMutation = gql`
  mutation InsertOutOfNetworkMappingsMutation(
    $practiceId: uuid!
    $accountId: uuid!
    $providerId: uuid
    $npi: String
    $insuranceCompanyId: uuid
    $notes: String
  ) {
    insert_mapping_out_of_network(
      objects: {
        practiceId: $practiceId
        providerId: $providerId
        npi: $npi
        insuranceCompanyId: $insuranceCompanyId
        notes: $notes
      }
    ) {
      affected_rows
    }
    update_accounts_by_pk(pk_columns: { id: $accountId }, _set: { npi: $npi }) {
      id
    }
  }
`;

export const UpdateOutOfNetworkMappingsMutation = gql`
  mutation UpdateOutOfNetworkMappingsMutation(
    $id: uuid!
    $accountId: uuid!
    $providerId: uuid
    $npi: String
    $insuranceCompanyId: uuid
    $notes: String
  ) {
    update_mapping_out_of_network_by_pk(
      pk_columns: { id: $id }
      _set: {
        providerId: $providerId
        npi: $npi
        insuranceCompanyId: $insuranceCompanyId
        notes: $notes
      }
    ) {
      id
    }
    update_accounts_by_pk(pk_columns: { id: $accountId }, _set: { npi: $npi }) {
      id
    }
  }
`;
