import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Container, Icon, Card } from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import MainLayout from '@ui/MainLayout';
import styled from 'styled-components';
import CreditCardsTable from './CreditCardsTable';
import { CreditCardsQuery } from '@graphql/creditCards';
import { CreditCard } from '@bluefox/models/CreditCard';
import { CreditCardForm } from './CreditCardForm';

interface CreditCardsData {
  creditCards: CreditCard[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const ENTRIES_PER_PAGE = 15;

const CreditCards = () => {
  const practice = usePractice();

  const [criteria, setCriteria] = useState<object>({});

  const [page, setPage] = useState(0);

  const { data, loading, refetch } = useQuery<CreditCardsData>(
    CreditCardsQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Credit Cards' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="credit card" style={{ marginRight: '0.6rem' }} />
                Credit Cards
              </Card.Header>
              <CreditCardForm />
            </div>

            <CreditCardsTable
              data={data}
              loading={loading}
              refetch={refetch}
              total={total}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

const StyledfilterTitle = styled.p`
  margin-bottom: 0 !important;
`;

export default CreditCards;
