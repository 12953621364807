import { usDollarCurrency } from '@bluefox/lib/formatters';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

const discountStyle = StyleSheet.create({
  section: {
    marginTop: 40,
  },
  titleSection: {
    padding: '10 8',
    border: '1px solid #BCCBDC',
    borderLeft: 0,
    borderRight: 0,
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  textSection: {
    padding: '10 8',
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  textHighlighted: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
});

export type DiscountProps = {
  discountPercentage: number;
  discountAmount: number;
};

const Discount = ({ discountPercentage, discountAmount }: DiscountProps) => (
  <View style={discountStyle.section}>
    <View style={discountStyle.titleSection}>
      <Text style={discountStyle.title}>Rebate</Text>
    </View>
    <View style={discountStyle.textSection}>
      <Text style={discountStyle.text}>
        Canid provides a{' '}
        <Text style={discountStyle.textHighlighted}>
          {discountPercentage} %
        </Text>{' '}
        rebate, representing{' '}
        <Text style={discountStyle.textHighlighted}>
          {usDollarCurrency(discountAmount)}{' '}
        </Text>{' '}
        less in your invoice.
      </Text>
    </View>
  </View>
);

export default Discount;
