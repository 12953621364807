import MainLayout from '@ui/MainLayout';
import { Card, Container, Grid, Icon } from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts/Practice';
import InconsistentVaccinations from '@ui/InconsistentVaccinations';

export const InconsistentVaccinationsHistoryScreen = () => {
  const practice = usePractice();
  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Inconsistent Vaccinations History' },
      ]}
    >
      <Card style={{ width: '100%' }}>
        <Card.Content>
          <Card.Header
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.6rem',
              }}
            >
              <Icon name="history" />
              Inconsistent Vaccinations History
            </div>
          </Card.Header>
          <InconsistentVaccinations practiceId={practice.id} />
        </Card.Content>
      </Card>
    </MainLayout>
  );
};
