import { gql } from '@apollo/client';

export const CreditCardsQuery = gql`
  query CreditCardsQuery(
    $limit: Int!
    $offset: Int!
    $filter: credit_cards_bool_exp
  ) {
    creditCards: credit_cards(
      limit: $limit
      offset: $offset
      order_by: { default: desc }
      where: $filter
    ) {
      id
      firstDigit
      lastDigits
      type
      userName
      expiration
      default
      defaultDaysRange
    }
    aggregating: credit_cards_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
`;

export const AddCreditCardMutation = gql`
  mutation AddCreditCardMutation($object: credit_cards_insert_input!) {
    insert_credit_cards_one(object: $object) {
      id
    }
  }
`;

export const UpdateCreditCardByPK = gql`
  mutation UpdateCreditCardByPK($id: uuid!, $object: credit_cards_set_input) {
    update_credit_cards_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;
