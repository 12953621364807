import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PracticeQuery } from '@graphql/practices';
import { Practice } from '@bluefox/models/Practice';
import { User, UserTitles } from '@bluefox/models/Users';
import { usePractice } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Label,
  Modal,
  Button,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import UserForm from '@bluefox/ui/UserForm';
import {
  GetExistingAccounts,
  InsertExistingAccount,
} from '@bluefox/graphql/users';

interface optionsType {
  key: string;
  text: string;
  value: string;
}

interface account {
  id: string;
  pcp: boolean;
  position: string;
  role: string;
  account: {
    email: string;
    fistName: string;
    lastName: string;
    id: string;
  };
}

interface practice {
  id: string;
  handler: string;
  accounts: account[];
}

const UsersList = () => {
  const practice = usePractice();

  const [practiceAccountId, setPracticeAccountId] = useState<string>('');

  const [userData, setUserdata] = useState<User>();

  const [open, setOpen] = useState(false);

  const [addExistingUserModal, setAddExistingUserModal] = useState(false);

  const [search, setSearch] = useState('');

  const [selectedExistingAccount, setSelectedExistingAccount] = useState<
    string | undefined
  >(undefined);

  const [existingAccountsOptions, setExistingAccountsOptions] = useState<
    optionsType[]
  >([]);

  const [
    LazyGetExistingAccounts,
    {
      data: existingAccounts,
      loading: loadingExistingAccounts,
      refetch: refetchExistingAccounts,
    },
  ] = useLazyQuery(GetExistingAccounts, {
    onCompleted: (data) => {
      const practices = existingAccounts?.practices[0]?.organization?.practices;

      const emailMap = new Map();

      data?.accounts.length &&
        emailMap.set('ops-admin@canid.io', {
          value: 'ops-admin@canid.io',
          key: 'ops-admin@canid.io',
          handlers: ['Ops Admin'],
        });

      practices &&
        practices.forEach((practice: practice) => {
          const practiceHandler = practice.handler;

          practice.accounts.forEach((account) => {
            const email = account.account.email;

            // If the email is already in the map, append the practice handler
            if (emailMap.has(email)) {
              emailMap.get(email).handlers.push(practiceHandler);
            } else {
              // If the email is not in the map, create a new entry
              emailMap.set(email, {
                value: email,
                key: email,
                handlers: [practiceHandler],
              });
            }
          });
        });

      const _existingAccounts = Array.from(emailMap.values()).map((email) => ({
        value: email.value,
        text: `${email.value} - ${email.handlers.join(' / ')}`,
        key: email.key,
      }));

      setExistingAccountsOptions(_existingAccounts);
    },
  });

  const { data, error, loading, refetch } = useQuery<{ practices: Practice[] }>(
    PracticeQuery,
    {
      variables: {
        id: practice.id,
      },
      onCompleted: (data) => {
        const _existingAccountsExceptionArray =
          data?.practices[0].accounts?.map((a) => a.account.email);
        _existingAccountsExceptionArray?.push('ops-admin@canid.io');

        LazyGetExistingAccounts({
          variables: {
            _eq: practice.id,
            opsAdminAccount: !!data?.practices[0].accounts?.find(
              (e) => e.account.email === 'ops-admin@canid.io'
            )
              ? ''
              : 'ops-admin@canid.io',
            _nin: _existingAccountsExceptionArray,
          },
        });
      },
    }
  );

  const [existingAccountMutation] = useMutation(InsertExistingAccount, {
    onCompleted: (data) => {
      refetch();
      refetchExistingAccounts();
    },
  });

  const closeModal = () => {
    setPracticeAccountId('');
    setUserdata({
      account: {
        id: '',
        firstName: '',
        lastName: '',
        npi: '',
        email: '',
        password: '',
        taxonomyCode: '',
      },
      id: '',
      title: UserTitles.Other,
      pcp: false,
      role: '',
      suspended: false,
      profile: {},
    });

    setOpen(false);
  };
  const dataFiltered = data?.practices[0].accounts?.filter((a) => {
    return (
      a.account.firstName?.toLowerCase().includes(search.toLowerCase()) ||
      a.account.lastName?.toLowerCase().includes(search.toLowerCase()) ||
      a.account.email?.toLowerCase().includes(search.toLowerCase()) ||
      a.account.npi?.includes(search)
    );
  });

  const handleAddExistingUser = () => {
    const _variables =
      selectedExistingAccount === 'ops-admin@canid.io'
        ? [
            {
              account: {
                id: existingAccounts.accounts[0].id,
              },
              position: 'physician',
              pcp: false,
              role: 'practiceManager',
            },
          ]
        : existingAccounts.practices[0].organization.practices
            .map((a: practice) =>
              a.accounts.find(
                (acc: account) => acc.account.email === selectedExistingAccount
              )
            )
            .filter((a: practice) => a !== undefined);

    existingAccountMutation({
      variables: {
        obj: {
          accountId: _variables[0].account.id,
          practiceId: practice.id,
          position: _variables[0].position,
          pcp: _variables[0].pcp,
          role: _variables[0].role,
          title: _variables[0].title,
          suspended: _variables[0].suspended,
        },
      },
      onCompleted: (data) => {
        toast({
          title: 'Account added succesfully',
          type: 'success',
          time: 1000,
        });
      },
    });
    setAddExistingUserModal(false);
  };

  return (
    <Card style={{ width: '100%' }}>
      <Card.Content>
        <Card.Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.6rem',
            }}
          >
            <Icon name="users" />
            Users
          </div>
          <div>
            <Button name="addUser" primary onClick={() => setOpen(true)}>
              <Icon name="add user" />
              Add User
            </Button>
            <Button
              primary
              onClick={() => setAddExistingUserModal(true)}
              loading={loadingExistingAccounts}
              disabled={!existingAccountsOptions.length}
            >
              <Icon name="plus" />
              Add Existing Account
            </Button>
          </div>
        </Card.Header>
        {/* <Menu>
          <Button
            name="addUser"
            primary
            floated="right"
            onClick={() => setOpen(true)}
          >
            <Icon name="plus" />
            Add User
          </Button>
          <Menu.Menu position="right">
              <Menu.Item>
                <Input
                  data-automation-id="it-users-search"
                  // value={searchQuery}
                  // onChange={(_, { value }) => {
                  //   setSearchQuery(value);
                  // }}
                  icon="search"
                  placeholder="Search..."
                  loading={typing || loading}
                />
              </Menu.Item>
            </Menu.Menu>
        </Menu> */}
        <Menu borderless>
          <Menu.Item>
            <Input
              data-automation-id="patients-search"
              value={search}
              onChange={(_, { value }) => {
                setSearch(value);
              }}
              icon="search"
              placeholder="Search User..."
              label="Search by FN, LN, E or NPI"
            />
          </Menu.Item>
        </Menu>
        <Card.Description>
          {error ? (
            <Message error>{error.message}</Message>
          ) : (
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>First Name</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Last Name</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Email</Table.HeaderCell>
                  <Table.HeaderCell width={2}>NPI</Table.HeaderCell>
                  <Table.HeaderCell width={3}></Table.HeaderCell>
                  <Table.HeaderCell width={1}>Edit</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data?.practices.length ? (
                  dataFiltered?.map((a, key) => {
                    return (
                      <Table.Row key={key}>
                        <Table.Cell>{a.account.firstName}</Table.Cell>
                        <Table.Cell>{a.account.lastName}</Table.Cell>
                        <Table.Cell>{a.account.email}</Table.Cell>
                        <Table.Cell>{a.account.npi}</Table.Cell>
                        <Table.Cell>
                          <Label color="teal">{a.role}</Label>
                          {a.pcp && <Label color="orange">PCP</Label>}
                          {a.suspended && <Label color="red">Suspended</Label>}
                        </Table.Cell>
                        <Table.Cell>
                          <Icon
                            name="edit"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setOpen(true);
                              setPracticeAccountId(a.id);
                              setUserdata({
                                account: {
                                  id: a.account.id ?? '',
                                  firstName: a.account.firstName ?? '',
                                  lastName: a.account.lastName ?? '',
                                  npi: a.account.npi ?? '',
                                  email: a.account.email ?? '',
                                  taxonomyCode: a.account.taxonomyCode ?? '',
                                },
                                id: a.id ?? '',
                                title:
                                  (a.title as UserTitles) ?? UserTitles.Other,
                                pcp: a.pcp ?? false,
                                role: a.role ?? '',
                                suspended: a.suspended ?? false,
                                profile: a.profile ?? {},
                              });
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="4">
                      <Message>No Users found.</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          )}
        </Card.Description>
      </Card.Content>
      <Modal
        onClose={() => closeModal()}
        onOpen={() => setOpen(open)}
        open={open}
      >
        {!practiceAccountId ? (
          <Modal.Header>Add Member</Modal.Header>
        ) : (
          <Modal.Header>Edit Member</Modal.Header>
        )}
        <Modal.Content>
          <UserForm userData={userData} close={closeModal} onSave={refetch} />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setAddExistingUserModal(false)}
        // onOpen={() => setAddExistingUserModal(addExistingUserModal)}
        open={addExistingUserModal}
      >
        <Modal.Header>Add Existing User</Modal.Header>
        <Modal.Content style={{ display: 'flex', gap: '1rem' }}>
          <Dropdown
            search
            onChange={(_, { value }) => {
              setSelectedExistingAccount(value as string);
            }}
            fluid
            placeholder="Select existing account"
            selection
            options={existingAccountsOptions}
          />
          <Button
            primary
            onClick={handleAddExistingUser}
            disabled={!selectedExistingAccount}
          >
            Add
          </Button>
        </Modal.Content>
      </Modal>
    </Card>
  );
};

export default UsersList;
