import {
  Segment,
  Header,
  Divider,
  Grid,
  Form,
  FormGroup,
} from 'semantic-ui-react';

import { Provider } from '@bluefox/models/ClaimPreview';

const BillingProviderInfo: React.FC<{ provider: Provider }> = ({
  provider: {
    organizationName,
    address,
    employerId,
    taxonomyCode,
    npi,
    firstName,
    lastName,
  },
}) => {
  return (
    <Segment>
      <Header as="h3"> Billing provider info </Header>
      <Divider />
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Form>
              <FormGroup widths="equal">
                {organizationName ? (
                  <Form.Input
                    fluid
                    label="Payer Name"
                    value={organizationName}
                    readOnly
                  />
                ) : (
                  <>
                    <Form.Input
                      fluid
                      label="Payer First Name"
                      value={firstName}
                      readOnly
                    />
                    <Form.Input
                      fluid
                      label="Payer Last Name"
                      value={lastName}
                      readOnly
                    />
                  </>
                )}

                <Form.Input
                  fluid
                  label="Address Line One"
                  value={address.address1}
                  readOnly
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Form.Input
                  fluid
                  label="Taxonomy Code"
                  value={taxonomyCode}
                  readOnly
                />
                <Form.Input
                  fluid
                  label="Federal Tax I.D Number:"
                  value={employerId}
                  readOnly
                />
                <Form.Input fluid label="NPI" value={npi} readOnly />
              </FormGroup>
              <FormGroup widths="equal">
                <Form.Input fluid label="City" value={address.city} readOnly />
                <Form.Input
                  fluid
                  label="State"
                  value={address.state}
                  readOnly
                />
                <Form.Input
                  fluid
                  label="Zip"
                  value={address.postalCode}
                  readOnly
                />
              </FormGroup>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default BillingProviderInfo;
