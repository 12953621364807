import React, { useEffect, useState } from 'react';
import { usePractice } from '@bluefox/contexts';
import { toast } from 'react-semantic-toasts';
import { Button, Modal } from 'semantic-ui-react';
import InventoryAdjustmentTable from './InventoryTable';
import { Link, useHistory } from 'react-router-dom';
import {
  AdjustmentReasons,
  InventoryAdjustmentData,
} from '@bluefox/models/InventoryAdjustment';

import { InventoryAdjustmentProps, ScanData, STEPS } from './models';

const InventoryAdjustment = (props: InventoryAdjustmentProps) => {
  const {
    onStepChange,
    nextButtonClicked,
    cancelButtonClicked,
    scannedData,
    onFinishInventoryControl,
    onFinishStep,
    data,
    currentStep,
  } = props;

  const practice = usePractice();
  const history = useHistory();

  // STATES

  // State used to keep the whole process Data updated.
  const [processedData, setProcessedData] = useState<InventoryAdjustmentData[]>(
    []
  );

  // State to use on the current data processed.
  const [currentProcess, setCurrentProcess] = useState<
    InventoryAdjustmentData[]
  >([]);

  const [missedVaccines, setMissedVaccines] = useState<
    InventoryAdjustmentData[]
  >([]);
  const [showMissedVaccinesModal, setShowMissedVaccinesModal] = useState(false);
  const [showNoExistingModal, setShowNoExistingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showInventoryUpdatedModal, setShowInventoryUpdatedModal] =
    useState(false);
  const [showCancelProcessModal, setShowCancelProcessModal] = useState(false);
  const [repeatedRowScanned, setRepeatedRowScanned] = useState<
    string | undefined
  >();

  // STATES

  const buildAdjustmentData = (
    vaccines: any[] | undefined,
    isPrivate: boolean,
    isExpired: boolean
  ): InventoryAdjustmentData[] => {
    return vaccines?.reduce((adjustmentsData, vaccine) => {
      const currentInventoryAdjustmentData: InventoryAdjustmentData[] =
        vaccine.inventory.reduce(
          (adjustmentsData: InventoryAdjustmentData[], inventory: any) => {
            adjustmentsData.push({
              name: vaccine.name,
              isPrivate,
              isExpired,
              id: inventory.id,
              lot: inventory.lot,
              expiration: inventory.expiration,
              doses: inventory.doses,
              lastAdjustmentDate: inventory.lastAdjustmentDate,
              wasAdjusted: false,
              vaccineId: inventory.vaccineId,
              useNdc: vaccine.useNdc10,
              saleNdc: vaccine.saleNdc10,
              types: vaccine.types,
            });
            return adjustmentsData;
          },
          [] as InventoryAdjustmentData[]
        );
      return [...adjustmentsData, ...currentInventoryAdjustmentData];
    }, [] as InventoryAdjustmentData[]);
  };

  const privateVaccines = buildAdjustmentData(
    data?.vaccines
      .map((v) => {
        return {
          ...v,
          inventory: v.inventory.filter((i) => !i.vfc),
          stock: v.inventory
            .filter((i) => !i.vfc)
            .reduce((accum, inventory) => accum + inventory.doses, 0),
        };
      })
      .filter((v) => v.inventory.length > 0),
    true,
    false
  );

  const expiredPrivateVaccines = buildAdjustmentData(
    data?.expiredVaccines
      .map((v) => {
        return {
          ...v,
          inventory: v.inventory.filter((i) => !i.vfc),
          stock: v.inventory
            .filter((i) => !i.vfc)
            .reduce((accum, inventory) => accum + inventory.doses, 0),
        };
      })
      .filter((v) => v.inventory.length > 0),
    true,
    true
  );

  const publicVaccines = buildAdjustmentData(
    data?.vaccines
      .map((v) => {
        return {
          ...v,
          inventory: v.inventory.filter((i) => i.vfc),
          stock: v.inventory
            .filter((i) => i.vfc)
            .reduce((accum, inventory) => accum + inventory.doses, 0),
        };
      })
      .filter((v) => v.inventory.length > 0),
    false,
    false
  );

  const expiredPublicVaccines = buildAdjustmentData(
    data?.expiredVaccines
      .map((v) => {
        return {
          ...v,
          inventory: v.inventory.filter((i) => i.vfc),
          stock: v.inventory
            .filter((i) => i.vfc)
            .reduce((accum, inventory) => accum + inventory.doses, 0),
        };
      })
      .filter((v) => v.inventory.length > 0),
    false,
    true
  );

  const replaceElement = (
    inventory: InventoryAdjustmentData,
    inventories: InventoryAdjustmentData[]
  ) => {
    const index = inventories.findIndex(
      (element) => element.id === inventory.id
    );
    if (~index) {
      return;
    }
    inventories[index] = inventory;
  };
  const adjustmentHandler = (adjustmentData: InventoryAdjustmentData) => {
    replaceElement(adjustmentData, currentProcess);
    setCurrentProcess(currentProcess);
  };

  const wasFinished = (
    isFinished: boolean,
    inventoryData: InventoryAdjustmentData
  ) => {
    if (!isFinished) {
      return false;
    }
    return !!(inventoryData.wasAdjusted || inventoryData.wasConfirmed);
  };

  const noScannedVaccines = (
    rawData: InventoryAdjustmentData[]
  ): InventoryAdjustmentData[] =>
    rawData.filter(
      (rawVax) =>
        !currentProcess.some((processedVax) => processedVax.id === rawVax.id)
    );

  const validateProcess = (): boolean => {
    if (
      currentStep === STEPS.VFC_EXPIRED ||
      currentStep === STEPS.PRIVATE_EXPIRED
    ) {
      return true;
    }

    if (!currentProcess.reduce(wasFinished, true)) {
      return false;
    }

    const missingVaccines = noScannedVaccines(
      currentStep === STEPS.PRIVATE ? privateVaccines : publicVaccines
    );

    if (missingVaccines.length === 0) {
      return true;
    }
    setMissedVaccines([...missingVaccines]);
    setShowMissedVaccinesModal(true);
    return false;
  };

  const onNextButtonClicked = () => {
    if (!validateProcess()) {
      toast({
        title: 'Please check all Items',
        type: 'error',
        time: 5000,
      });
      return;
    }
    setShowConfirmationModal(true);
  };

  const onClickConfirmButton = () => {
    setShowConfirmationModal(false);

    if (currentStep === STEPS.VFC) {
      onFinishInventoryControl([...processedData, ...currentProcess]);
      return;
    }

    setProcessedData([...processedData, ...currentProcess]);
    onFinishStep();
  };

  const setAdjustmentData = () => {
    setMissedVaccines([]);

    if (currentStep === STEPS.PRIVATE_EXPIRED) {
      setCurrentProcess(expiredPrivateVaccines);
      return;
    }
    if (currentStep === STEPS.PRIVATE) {
      setCurrentProcess([]);
      return;
    }
    if (currentStep === STEPS.VFC_EXPIRED) {
      setCurrentProcess(expiredPublicVaccines);
      return;
    }
    if (currentStep === STEPS.VFC) {
      setCurrentProcess([]);
      return;
    }
  };

  const handleOnScan = (scannedData: ScanData) => {
    const { ndc, lot, parsedDate } = scannedData;
    let scannedInventory: InventoryAdjustmentData | undefined;

    if (currentStep === STEPS.PRIVATE) {
      scannedInventory = privateVaccines.find(
        (currentVaccine) =>
          currentVaccine.expiration === parsedDate &&
          (currentVaccine.saleNdc === ndc || currentVaccine.useNdc === ndc) &&
          currentVaccine.lot === lot
      );
    }

    if (currentStep === STEPS.VFC) {
      scannedInventory = publicVaccines.find(
        (currentVaccine) =>
          currentVaccine.expiration === parsedDate &&
          (currentVaccine.saleNdc === ndc || currentVaccine.useNdc === ndc) &&
          currentVaccine.lot === lot
      );
    }

    if (!scannedInventory) {
      setShowNoExistingModal(true);
      return;
    }

    const wasScanned = currentProcess.some(
      (inventory) => inventory.id === scannedInventory?.id
    );

    if (wasScanned) {
      setRepeatedRowScanned(scannedInventory.id);
      return;
    }

    setCurrentProcess((prevCurrentProcess) => [
      ...prevCurrentProcess,
      scannedInventory as InventoryAdjustmentData,
    ]);
  };

  const markAsMissedVaccines = () => {
    const currentMissedVaccines = [...missedVaccines];
    currentMissedVaccines.reduce((accum, missedVaccine) => {
      missedVaccine.newDoses = 0;
      missedVaccine.adjustmentReason = AdjustmentReasons.MISSING_VACCINE;
      missedVaccine.wasAdjusted = true;
      missedVaccine.newAdjustmentDate = new Date();
      return [...accum, missedVaccine];
    }, [] as InventoryAdjustmentData[]);

    setCurrentProcess((prevCurrentProcess) => [
      ...prevCurrentProcess,
      ...currentMissedVaccines,
    ]);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setAdjustmentData();
    onStepChange(currentStep);
  }, [data, currentStep]);

  useEffect(() => {
    if (nextButtonClicked === undefined) {
      return;
    }
    onNextButtonClicked();
  }, [nextButtonClicked]);

  useEffect(() => {
    if (cancelButtonClicked === undefined) {
      return;
    }
    setShowCancelProcessModal(true);
  }, [cancelButtonClicked]);

  useEffect(() => {
    if (!scannedData) {
      return;
    }
    handleOnScan(scannedData);
  }, [scannedData]);

  return (
    <>
      {currentProcess?.length > 0 && (
        <InventoryAdjustmentTable
          onCloseActions={() => {
            setRepeatedRowScanned(undefined);
          }}
          inventorySelected={repeatedRowScanned}
          expired={
            currentStep === STEPS.VFC_EXPIRED ||
            currentStep === STEPS.PRIVATE_EXPIRED
          }
          makeAdjustment={adjustmentHandler}
          vaccines={currentProcess}
        />
      )}

      {currentProcess?.length === 0 &&
        (currentStep === STEPS.VFC_EXPIRED ||
          currentStep === STEPS.PRIVATE_EXPIRED) && (
          <h2>NO VACCINE ON INVENTORY, CLICK NEXT BUTTON TO CONTINUE</h2>
        )}

      <Modal
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        open={showConfirmationModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>Continue with next step?</Modal.Header>

        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
          />
          <Button
            primary
            type="submit"
            content="Confirm"
            onClick={onClickConfirmButton}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => {
          setShowInventoryUpdatedModal(false);
          history.push(`/${practice.handler}/inventory`);
        }}
        open={showInventoryUpdatedModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>Inventory Updated</Modal.Header>
        <Modal.Actions>
          <Button
            type="button"
            content="Continue to Inventory Page"
            onClick={() => {
              setShowInventoryUpdatedModal(false);
              history.push(`/${practice.handler}/inventory`);
            }}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => {
          setShowCancelProcessModal(false);
        }}
        open={showCancelProcessModal}
        size="large"
        closeIcon
      >
        <Modal.Header>Cancel Inventory Adjustment?</Modal.Header>
        <Modal.Content>
          <p>
            <b>Cancel and Go Back:</b>
          </p>
          Choosing this option will cancel the adjustment process and navigate
          you back to the inventory page. Any changes you've made so far will
          not be saved.
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            as={Link}
            to={`/practices/${practice.handler}/inventory`}
            content="Cancel and continue to Inventory Page"
          />
        </Modal.Actions>
      </Modal>

      <Modal
        dimmer
        open={showNoExistingModal}
        onClose={() => setShowNoExistingModal(false)}
      >
        <Modal.Header>
          This vaccine does not belong to the current inventory
        </Modal.Header>
        <Modal.Actions>
          <Button positive onClick={() => setShowNoExistingModal(false)}>
            Continue
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        dimmer
        open={showMissedVaccinesModal}
        onClose={() => setShowMissedVaccinesModal(false)}
      >
        <Modal.Header>
          This Vaccine was not scanned. Do you want to set it as missed?
        </Modal.Header>
        <Modal.Content>
          {missedVaccines.map((vaccine, index) => {
            return <p key={index}> {vaccine.name} </p>;
          })}
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={() => {
              markAsMissedVaccines();
              setShowMissedVaccinesModal(false);
            }}
          >
            Accept
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default InventoryAdjustment;
