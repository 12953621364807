import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import moment from 'moment-timezone';
import {
  VaccinationsWithErrorsQuery,
  ReportImmunizationMutation,
} from '@graphql/vaccinations';
import {
  ImmunizationReportError,
  Vaccination,
} from '@bluefox/models/Vaccination';
import {
  Card,
  Icon,
  Message,
  Placeholder,
  Segment,
  Table,
  Button,
  Container,
  Label,
  Popup,
  SemanticCOLORS,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import { DateFormats } from '@bluefox/models/Dates';
import { useEffect, useState } from 'react';

interface VaccinationsData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  vaccinations: Vaccination[];
  vaccinationsRemoved: Vaccination[];
}

const IisErrorsScreen = () => {
  const [vaccinationsData, setVaccinationsData] = useState<Vaccination[]>([]);
  const { data, loading, error, refetch } = useQuery<VaccinationsData>(
    VaccinationsWithErrorsQuery
  );

  useEffect(() => {
    if (!data) return;

    setVaccinationsData([...data.vaccinations, ...data.vaccinationsRemoved]);
  }, [data]);

  return (
    <MainLayout path={[{ text: 'IIS Errors' }]}>
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="syringe" style={{ marginRight: '0.6rem' }} />
              IIS Errors
            </Card.Header>
            <Card.Description>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Practice</Table.HeaderCell>
                      <Table.HeaderCell>Patient</Table.HeaderCell>
                      <Table.HeaderCell>Service Date</Table.HeaderCell>
                      <Table.HeaderCell>Vaccine</Table.HeaderCell>
                      <Table.HeaderCell>Error</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={5}>
                          <Segment basic>
                            <Placeholder fluid>
                              <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Header>
                            </Placeholder>
                          </Segment>
                        </Table.Cell>
                      </Table.Row>
                    ) : !!vaccinationsData.length ? (
                      vaccinationsData.map((v) => {
                        return (
                          <VaccinationRow
                            key={v.id}
                            vaccinationData={v}
                            onSave={() => refetch()}
                          />
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={5}>
                          <Message>No vaccinations with errors found</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//------------------------------------VACCINATION ROW------------------------------------//

interface VaccinationRowProps {
  vaccinationData: Vaccination;
  onSave: () => void;
}

type ReportErrorActionType = 'insert' | 'remove' | 'update';

const obj: {
  [key: string]: {
    color: string;
    popupContent: string;
    triggerContent: string;
  };
} = {
  create: {
    color: 'green',
    popupContent: 'Insert',
    triggerContent: 'I',
  },
  remove: {
    color: 'red',
    popupContent: 'Remove',
    triggerContent: 'R',
  },
  update: {
    color: 'yellow',
    popupContent: 'Update',
    triggerContent: 'U',
  },
};

const actionLevelText = (action: ReportErrorActionType) => {
  const { color, popupContent, triggerContent } = obj[action];

  return (
    <Popup
      content={popupContent}
      trigger={
        <Label
          basic
          color={color as SemanticCOLORS}
          size="small"
          content={triggerContent}
        />
      }
    ></Popup>
  );
};

const showErrors = (immunizationReportError: ImmunizationReportError) => {
  if (immunizationReportError.data.registryValidationErrors) {
    return immunizationReportError.data.registryValidationErrors.map(
      (e: any) => {
        return <div>{e}</div>;
      }
    );
  }
  return <div>{immunizationReportError.data.msg}</div>;
};

const VaccinationRow = ({ vaccinationData, onSave }: VaccinationRowProps) => {
  const [reportImmunization] = useMutation(ReportImmunizationMutation);
  const handleReportImmunization = () => {
    reportImmunization({
      variables: {
        vaccinationId: vaccinationData.id,
        action: vaccinationData.immunizationReportError?.data?.action,
      },
    })
      .then((r) => {
        toast({
          title: 'Immunization reported successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row>
      <Table.Cell>{vaccinationData.practice?.name}</Table.Cell>
      <Table.Cell style={{ display: 'flex', flexDirection: 'column' }}>
        {`${vaccinationData.practicePatient?.patientData.firstName} ${vaccinationData.practicePatient?.patientData.lastName}`}
        <div>
          {`(${vaccinationData.practicePatient?.patientData.birthdate})`}
        </div>
        <div>
          <Label
            basic
            size="tiny"
            content={`MRN: ${vaccinationData.practicePatient?.mrn || '-'}`}
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        {moment(vaccinationData.givenAt).format(DateFormats.DATE)}
      </Table.Cell>
      <Table.Cell>
        {vaccinationData.vaccine?.name}{' '}
        <Label
          basic
          size="small"
          content={`Lot: ${vaccinationData.inventory?.lot}`}
        />
      </Table.Cell>
      <Table.Cell>
        {showErrors(
          vaccinationData.immunizationReportError as ImmunizationReportError
        )}
      </Table.Cell>
      <Table.Cell>
        <Button
          primary
          size="small"
          content="Report"
          onClick={handleReportImmunization}
        />
      </Table.Cell>
      <Table.Cell>
        <div>
          {vaccinationData.immunizationReportError?.data?.action &&
            actionLevelText(
              vaccinationData.immunizationReportError?.data
                ?.action as ReportErrorActionType
            )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default IisErrorsScreen;
