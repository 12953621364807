import { useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Container,
  Icon,
  Menu,
  Popup,
  Radio,
  Segment,
  Select,
} from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import {
  InventoryAdjustment as InventoryAdjustmentModel,
  InventoryAdjustmentDetail,
  InventoryAdjustmentDetailStatuses,
  InventoryAdjustmentStatus,
  InventoryAdjustmentStatuses,
} from '@bluefox/models/InventoryAdjustment';
import MainLayout from '@ui/MainLayout';
import {
  AdjustmentDetailStatusesQuery,
  AdjustmentStatusesQuery,
  InventoryAdjustmentsQuery,
  UpdateAdjustmentDetail,
} from '@bluefox/graphql/inventoryAdjustment';
import InventoryAdjustmentTables from '@screens/inventoryAdjustment/InventoryAdjustmentTables';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import { toast } from 'react-semantic-toasts';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

interface AdjustmentStatusesQueryInterface {
  adjustmentStatuses: InventoryAdjustmentStatus[];
}

interface AdjustmentsQueryInterface {
  adjustments: InventoryAdjustmentModel[];
}

const InventoryAdjustment = () => {
  const practice = usePractice();
  const practiceId = practice.id;
  const { session } = useApplicationState();

  // STATES
  const [activeIndex, setActiveIndex] = useState(-1);
  const [vfcFilter, setVfcFilter] = useState(false);
  const [adjustments, setAdjustments] = useState<InventoryAdjustmentModel[]>(
    []
  );
  const [selectedAdjustmentStatus, setSelectedAdjustmentStatus] =
    useState<InventoryAdjustmentStatuses>(InventoryAdjustmentStatuses.PENDING);
  // STATES

  // GQL
  const [updateAdjustmentDetail] = useMutation(UpdateAdjustmentDetail);

  const { data: adjustmentStatuses } =
    useQuery<AdjustmentStatusesQueryInterface>(AdjustmentStatusesQuery);
  const { data: detailStatuses } = useQuery<AdjustmentStatusesQueryInterface>(
    AdjustmentDetailStatusesQuery
  );
  const { refetch: adjustmentsQueryRefetch } =
    useQuery<AdjustmentsQueryInterface>(InventoryAdjustmentsQuery, {
      variables: {
        practiceId,
        status: selectedAdjustmentStatus,
        isVfc: vfcFilter,
      },
      skip: !isStringNotEmpty(practiceId),
      onCompleted: (data) => {
        const newAdjustments = data.adjustments?.map((adjustment) => ({
          ...adjustment,
          details: adjustment.details?.map((detail) => {
            const lastestStatusLog = detail?.statusLog
              ? detail?.statusLog?.[0]
              : undefined;
            const firstStatusLog =
              detail?.statusLog && detail?.statusLog.length > 0
                ? detail?.statusLog?.[detail?.statusLog.length - 1]
                : undefined;
            const originalNewDoses =
              firstStatusLog?.newDoses !== undefined
                ? firstStatusLog?.newDoses
                : detail.newDoses;
            return {
              ...detail,
              // originalNewDoses: Only to revert some amend or rejection
              originalNewDoses,
              operatorAmmend: lastestStatusLog?.operatorAmmend
                ? lastestStatusLog?.operatorAmmend
                : undefined,
              operatorRejection: lastestStatusLog?.operatorRejection
                ? lastestStatusLog?.operatorRejection
                : undefined,
              newDoses: lastestStatusLog?.operatorAmmend
                ? lastestStatusLog?.operatorAmmend.newDoses
                : lastestStatusLog?.operatorRejection
                ? lastestStatusLog?.operatorRejection?.newDoses
                : detail.newDoses,
            } as InventoryAdjustmentDetail;
          }),
        }));
        setAdjustments(newAdjustments || []);
      },
    });

  //LOGIC
  const onClickTitleHandler = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(-1);
      return;
    }
    setActiveIndex(index);
  };

  const getSelectedAdjustment = (
    adjustmentId: string
  ): InventoryAdjustmentModel | undefined => {
    return adjustments?.find((adjustment) => adjustment.id === adjustmentId);
  };

  const mapSelectedDetail = (detail: InventoryAdjustmentDetail) => {
    /**
     * Clear properties before call mutation (the mutation will fail if
     * some of the following values are defined)
     */
    const {
      inventory,
      selected,
      operatorAmmend,
      operatorRejection,
      originalNewDoses,
      __typename,
      ...rest
    } = detail;

    return rest as InventoryAdjustmentDetail;
  };

  const mapOperatorAmendedOrRejection = (detail: InventoryAdjustmentDetail) => {
    return {
      ...detail,
      inventory: {
        data: {
          ...detail.inventory,
          /**
           * Clear properties before call mutation (the mutation will fail if
           * some of the following values are defined)
           */
          __typename: undefined,
          vaccine: undefined,
          /** end */
          doses: detail.operatorAmmend
            ? detail.operatorAmmend?.newDoses
            : detail.operatorRejection?.newDoses,
        },
        on_conflict: {
          constraint: 'inventories_pkey',
          update_columns: ['doses'],
        },
      },
      /**
       * Clear properties before call mutation (the mutation will fail if
       * some of the following values are defined)
       */
      selected: undefined,
      operatorAmmend: undefined,
      operatorRejection: undefined,
      originalNewDoses: undefined,
      __typename: undefined,
      inventoryId: undefined,
    } as unknown as InventoryAdjustmentDetail;
  };

  const onSelectDetailToApply = async (
    detailsToSave: InventoryAdjustmentDetail[],
    nowDate?: Date
  ) => {
    const detailsToApply = detailsToSave.map((d) => {
      if (d.operatorAmmend || d.operatorRejection)
        return mapOperatorAmendedOrRejection(d);
      else return mapSelectedDetail(d);
    });

    if (detailsToApply.length === 0) {
      toast({
        title: 'No Adjustments selected',
        type: 'warning',
        time: 5000,
      });
      return;
    }

    const adjustment = getSelectedAdjustment(
      detailsToSave?.[0]?.inventoryAdjustmentId as string
    );
    if (!adjustment) {
      return;
    }

    const allDetailsHaveAppliedStatus = adjustment.details?.every(
      (adjustmentDetail) => {
        if (
          adjustmentDetail.status === InventoryAdjustmentDetailStatuses.APPLIED
        )
          return true;
        return detailsToSave.some(
          (detailToSave) =>
            detailToSave.id === adjustmentDetail.id &&
            detailToSave.status === InventoryAdjustmentDetailStatuses.APPLIED
        );
      }
    );

    const newAdjustmentStatus = allDetailsHaveAppliedStatus
      ? InventoryAdjustmentStatuses.APPLIED
      : InventoryAdjustmentStatuses.PARTIALLY_APPLIED;

    const adjustmentStatusLog = [
      {
        account: session?.account,
        status: newAdjustmentStatus,
        updatedAt: nowDate ? nowDate : new Date(),
      },
      ...(adjustment.statusLog ? adjustment.statusLog : []),
    ];

    try {
      await updateAdjustmentDetail({
        variables: {
          objects: detailsToApply,
          id: adjustment?.id,
          status: newAdjustmentStatus,
          adjustmentStatusLog,
        },
      });
      toast({
        title: 'Adjustment Applied',
        type: 'success',
        time: 1000,
      });
    } catch (e) {
      toast({
        title: 'Error trying to apply Adjustment',
        type: 'error',
        time: 5000,
      });
    } finally {
      adjustmentsQueryRefetch();
    }
  };
  //LOGIC

  const updateInternalAdjustmentDetails = (
    detailOrDetails: InventoryAdjustmentDetail | InventoryAdjustmentDetail[]
  ) => {
    setAdjustments((prevAdjustmentsValue) =>
      prevAdjustmentsValue.map((prevAdjustment) => {
        let detailOrDetailsInThisAdjustment:
          | InventoryAdjustmentDetail
          | InventoryAdjustmentDetail[]
          | undefined = undefined;
        if (Array.isArray(detailOrDetails)) {
          detailOrDetailsInThisAdjustment = detailOrDetails.filter(
            (d) => d.inventoryAdjustmentId === prevAdjustment.id
          );
        } else {
          detailOrDetailsInThisAdjustment =
            detailOrDetails.inventoryAdjustmentId === prevAdjustment.id
              ? detailOrDetails
              : undefined;
        }

        if (!detailOrDetailsInThisAdjustment) {
          return prevAdjustment;
        } else {
          return {
            ...prevAdjustment,
            details: prevAdjustment.details?.map((prevDetail) => {
              let newDetail: InventoryAdjustmentDetail | undefined = undefined;
              if (Array.isArray(detailOrDetailsInThisAdjustment)) {
                newDetail = detailOrDetailsInThisAdjustment.find(
                  (d) => prevDetail.id === d.id
                );
              } else {
                newDetail =
                  prevDetail.id === detailOrDetailsInThisAdjustment?.id
                    ? detailOrDetailsInThisAdjustment
                    : undefined;
              }

              return newDetail ? newDetail : prevDetail;
            }),
          } as InventoryAdjustmentModel;
        }
      })
    );
  };

  return (
    <MainLayout
      path={[{ text: 'Inventory' }, { text: 'Adjustments' }]}
      loading={false}
    >
      <Container data-automation-id="it-inventory-adjustment">
        <Card fluid>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="hospital" style={{ marginRight: '0.6rem' }} />
              Practices
            </Card.Header>
            <Card.Description>
              <Menu borderless>
                <Menu.Menu
                  position="right"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Menu.Item>
                    <Radio
                      checked={vfcFilter}
                      toggle
                      label="VFC"
                      onChange={(_, { checked }) => {
                        setAdjustments([]);
                        setVfcFilter(checked as boolean);
                      }}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Select
                      options={adjustmentStatuses?.adjustmentStatuses || []}
                      placeholder="Adjustment Status"
                      onChange={(_, { value }) => {
                        setAdjustments([]);
                        setSelectedAdjustmentStatus(
                          value as InventoryAdjustmentStatuses
                        );
                      }}
                      value={selectedAdjustmentStatus}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Card.Description>
            <Accordion fluid styled>
              {adjustments?.length > 0 &&
                adjustments.map((adjustment, index) => {
                  return (
                    <section key={index}>
                      <Accordion.Title
                        onClick={() => onClickTitleHandler(index)}
                        active={activeIndex === index}
                      >
                        <Icon name="dropdown" />
                        {`${moment(adjustment.createdAt).format(
                          DateFormats.DATE_WITH_TIME
                        )} - ${adjustment.status}`}
                        {adjustment.statusLog && (
                          <Popup
                            trigger={
                              <Button
                                size="mini"
                                color="teal"
                                icon="file code"
                                style={{ marginLeft: '1rem' }}
                                onClick={(e) => e.stopPropagation()}
                              />
                            }
                            on="click"
                            pinned
                            wide="very"
                            position="right center"
                          >
                            <Segment
                              color="teal"
                              raised
                              style={{
                                overflow: 'auto',
                                maxHeight: '40vh',
                                maxWidth: '40hh',
                              }}
                            >
                              <pre>
                                {JSON.stringify(adjustment.statusLog, null, 2)}
                              </pre>
                            </Segment>
                          </Popup>
                        )}
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === index}>
                        <InventoryAdjustmentTables
                          isApplied={
                            adjustment.status ===
                            InventoryAdjustmentStatuses.APPLIED
                          }
                          statuses={detailStatuses?.adjustmentStatuses || []}
                          updateAdjustment={onSelectDetailToApply}
                          key={`table-${index}`}
                          adjustment={adjustment}
                          updateAdjustmentDetails={
                            updateInternalAdjustmentDetails
                          }
                        />
                      </Accordion.Content>
                    </section>
                  );
                })}
            </Accordion>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default InventoryAdjustment;
