import InvoiceAndBillingReportPdf, {
  InvoiceAndBillingReportPdfProps,
} from '@bluefox/pdf/InvoiceAndBillingReportPdf';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useEffect, useRef } from 'react';
import { Modal } from 'semantic-ui-react';

const pdfStyles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '80vh',
  },
  hideViewer: {
    display: 'none',
  },
});

type Props = {
  invoiceProps?: InvoiceAndBillingReportPdfProps;
  onOpen?: () => void;
  onClose?: () => void;
  pageBlurred?: boolean;
};

const InvoicePdfModal = ({
  invoiceProps,
  onOpen,
  onClose,
  pageBlurred,
}: Props) => {
  /*
  Due to PDFViewer is an iFrame, key/mouse events are not dispatched to the main window. Due to this, once the page is blurred, the user needs to move the mouse/click outside the PDFViewer iFrame to disable the blur class. To fix this, I added one wrapper div the same size as PDFViewer iFrame. When the page is blurred (pageBlurred), I set display = none to PDFViewer iFrame, to capture the mouse / key events in the div.
  */
  return (
    <Modal
      closeIcon
      size="fullscreen"
      open={!!invoiceProps}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Modal.Header>Invoice</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {invoiceProps && (
            <div
              style={{
                ...pdfStyles.viewer,
                backgroundColor: 'grey',
              }}
            >
              <PDFViewer
                style={{
                  ...pdfStyles.viewer,
                  ...(pageBlurred ? pdfStyles.hideViewer : {}),
                }}
              >
                <InvoiceAndBillingReportPdf {...invoiceProps} />
              </PDFViewer>
            </div>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default InvoicePdfModal;
