import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  BillingInvoiceExtraItem,
  OTHER_REASONS_ENUM,
} from '@bluefox/models/Billing';
import { Practice } from '@bluefox/models/Practice';
import {
  deleteBillingInvoiceExtraItem,
  getBillingInvoiceExtraItem,
  insertBillingInvoiceExtraItem,
  setBillingInvoiceExtraItemReviewAtMutation,
  updateBillingInvoiceExtraItem,
} from '@graphql/billing';
import { toast } from 'react-semantic-toasts';
import {
  ConfirmationModalReturn,
  useConfirmationModalAwait,
} from '@bluefox/ui/ConfirmationModal';
import ExtraItemsForm from './ExtraItemsForm';
import ExtraItemsTables from './ExtraItemsTables';
import { Card, Container, Message } from 'semantic-ui-react';

function buildExtraItemDescription(extraItem: BillingInvoiceExtraItem) {
  return extraItem.reason === OTHER_REASONS_ENUM['Missing Vaccines']
    ? `Missing Vaccine: ${extraItem.inventory?.vaccine?.name} (Lot:${extraItem.inventory?.lot})`
    : extraItem.description;
}

export type ExtraItemsHandle = {
  callRefetch: () => void;
};

type Props = {
  practices: Practice[] | undefined;
};

const ExtraItems = forwardRef<ExtraItemsHandle, Props>(({ practices }, ref) => {
  const [currentExtraItem, setCurrentExtraItem] =
    useState<BillingInvoiceExtraItem>({} as BillingInvoiceExtraItem);
  const { render: renderConfirmationModal, open: openConfirmationModal } =
    useConfirmationModalAwait();

  const {
    loading: InvoiceExtraItemLoading,
    error: InvoiceExtraItemError,
    data: InvoiceExtraItemData,
    refetch: InvoiceExtraItemRefetch,
  } = useQuery<{
    billing_invoice_extra_items: BillingInvoiceExtraItem[];
  }>(getBillingInvoiceExtraItem, {
    variables: {
      practiceIds: practices?.map((p) => p.id),
    },
    skip: !practices || practices.length === 0,
    fetchPolicy: 'no-cache',
  });

  const [setExtraItemReviewed] = useMutation(
    setBillingInvoiceExtraItemReviewAtMutation
  );

  const [insertExtraItem, { loading: insertBillingInvoiceExtraItemLoading }] =
    useMutation(insertBillingInvoiceExtraItem);

  const [updateExtraItem, { loading: updateBillingInvoiceExtraItemLoading }] =
    useMutation(updateBillingInvoiceExtraItem);

  const [deleteExtraItem, { loading: deleteBillingInvoiceExtraItemLoading }] =
    useMutation(deleteBillingInvoiceExtraItem);

  const loading = useMemo(
    () =>
      insertBillingInvoiceExtraItemLoading ||
      updateBillingInvoiceExtraItemLoading ||
      deleteBillingInvoiceExtraItemLoading,
    [
      deleteBillingInvoiceExtraItemLoading,
      insertBillingInvoiceExtraItemLoading,
      updateBillingInvoiceExtraItemLoading,
    ]
  );

  const clearCurrentExtraItem = useCallback(() => {
    setCurrentExtraItem({} as BillingInvoiceExtraItem);
  }, []);

  const callRefetch = useCallback(async () => {
    try {
      await InvoiceExtraItemRefetch();
    } catch (error) {
      toast({
        title: `Failed to update Extra Items list: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [InvoiceExtraItemRefetch]);

  const handleReviewedAtChange = useCallback(
    async (extraItemId: string, reviewed: boolean) => {
      try {
        await setExtraItemReviewed({
          variables: {
            extraItemId,
            reviewed,
          },
        });
        await callRefetch();
      } catch (error) {
        toast({
          title: `Failed to update Item: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [callRefetch, setExtraItemReviewed]
  );

  const addItem = useCallback(
    async (extraItem: BillingInvoiceExtraItem) => {
      try {
        await insertExtraItem({
          variables: {
            extraItem: {
              practiceId: extraItem.practice.id,
              quantity: extraItem.quantity,
              unitPrice: extraItem.unitPrice,
              reason: extraItem.reason,
              description: buildExtraItemDescription(extraItem),
              inventoryId:
                extraItem.reason === OTHER_REASONS_ENUM['Missing Vaccines']
                  ? extraItem.inventory?.id
                  : undefined,
            },
          },
        });
        clearCurrentExtraItem();
        await callRefetch();
      } catch (error) {
        toast({
          title: `Failed to insert an Extra Items: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [callRefetch, clearCurrentExtraItem, insertExtraItem]
  );

  const updateItem = useCallback(
    async (extraItem: BillingInvoiceExtraItem) => {
      try {
        await updateExtraItem({
          variables: {
            id: extraItem.id,
            extraItem: {
              practiceId: extraItem.practice.id,
              quantity: extraItem.quantity,
              unitPrice: extraItem.unitPrice,
              reason: extraItem.reason,
              description: buildExtraItemDescription(extraItem),
              inventoryId:
                extraItem.reason === OTHER_REASONS_ENUM['Missing Vaccines']
                  ? extraItem.inventory?.id
                  : undefined,
            },
          },
        });
        clearCurrentExtraItem();
        await callRefetch();
      } catch (error) {
        toast({
          title: `Failed to update an Extra Items: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [callRefetch, clearCurrentExtraItem, updateExtraItem]
  );

  const onAddUpdate = useCallback(
    async (extraItem: BillingInvoiceExtraItem) => {
      if (!extraItem.id) {
        await addItem(extraItem);
      } else {
        await updateItem(extraItem);
      }
    },
    [addItem, updateItem]
  );

  const onExtraItemDelete = useCallback(
    async (extraItem: BillingInvoiceExtraItem) => {
      clearCurrentExtraItem();
      if (
        (await openConfirmationModal(
          `Are you sure you want to delete the extra item?`,
          'Delete'
        )) === ConfirmationModalReturn.YES
      ) {
        try {
          await deleteExtraItem({
            variables: {
              id: extraItem.id,
            },
          });
          await callRefetch();
        } catch (error) {
          toast({
            title: `Failed to delete the extra item: ${error}`,
            type: 'error',
            time: 5000,
          });
        }
      }
    },
    [callRefetch, clearCurrentExtraItem, deleteExtraItem, openConfirmationModal]
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        callRefetch,
      };
    },
    [callRefetch]
  );

  return (
    <Container fluid style={{ marginTop: '2rem' }}>
      <Card fluid>
        <Card.Content>
          <Card.Header as={'h3'}>Extra Items</Card.Header>
          <Card.Description>
            {renderConfirmationModal()}
            {InvoiceExtraItemError && (
              <Message error>
                Failed to get Extra Items list: {InvoiceExtraItemError?.message}
              </Message>
            )}
            <ExtraItemsForm
              loading={loading}
              practices={practices}
              extraItem={currentExtraItem}
              setExtraItem={setCurrentExtraItem}
              onSubmit={onAddUpdate}
              onClear={clearCurrentExtraItem}
            />
            <ExtraItemsTables
              extraItems={InvoiceExtraItemData?.billing_invoice_extra_items}
              loading={InvoiceExtraItemLoading}
              onReviewedAtChange={handleReviewedAtChange}
              onExtraItemEdit={(extraItem) => setCurrentExtraItem(extraItem)}
              onExtraItemDelete={onExtraItemDelete}
            />
          </Card.Description>
        </Card.Content>
      </Card>
    </Container>
  );
});

export default ExtraItems;
