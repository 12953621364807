import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import {
  default as ReactDatePicker,
  ReactDatePickerProps,
} from 'react-datepicker';
import { Input, InputProps, SemanticICONS } from 'semantic-ui-react';
import { DateFormats } from '@bluefox/models/Dates';

interface DateTimePickerProps extends ReactDatePickerProps {
  inputProps?: InputProps;
  onClear?: () => void;
  onChange: (value: Date | [Date | null, Date | null] | null) => void;
  icon?: SemanticICONS;
  absolute?: boolean;
  dataAutomationId?: string;
  forFilter?: boolean;
  tz?: string;
  minimalistic?: boolean;
}

const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    tz,
    isClearable,
    selected,
    onChange,
    onClear = () => {},
    inputProps = {},
    icon = 'calendar',
    dateFormat = DateFormats.DATE,
    absolute,
    dataAutomationId,
    forFilter = false,
    includeDates,
    minimalistic = false,
    ...dpProsp
  } = props;

  const [value, setValue] = useState<Date | null>(null);
  const [rawDate, setRawDate] = useState<string>();
  const [transformedIncludedDates, setTransformedIncludedDates] = useState<
    Date[] | null
  >(null);
  const currentTz = tz || moment.tz.guess();

  const parseDateFormat = (dateFormat: string): string => {
    const yearToLowerCase = dateFormat.replaceAll('Y', 'y');
    return yearToLowerCase.replaceAll('D', 'd');
  };

  const transformIncludedDates = (dates: Date[], tz: string) =>
    dates.reduce((transformedDates, currentDate) => {
      const newDate = buildDateToShow(currentDate, currentTz);
      transformedDates.push(newDate);
      return transformedDates;
    }, [] as Date[]);

  const buildDateToShow = (date: Date, tz: string) => {
    const convertedDateTime = moment.tz(date, tz).toDate();
    return convertedDateTime;
  };

  const buildDateToSend = (date: Date, tz: string): Date => {
    const year = date.getFullYear();
    const month = fillWithZero(date.getMonth() + 1);
    const day = fillWithZero(date.getDate());
    const hours = fillWithZero(date.getHours());
    const minutes = fillWithZero(date.getMinutes());
    const parsedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return moment.tz(parsedDate, tz).toDate();
  };

  const fillWithZero = (value: number) => `0${value}`.slice(-2);

  const sendDate = (date: Date | null) => {
    if (!date) {
      onChange(date);
      return;
    }
    const newDate = buildDateToSend(date, currentTz);
    if (selected?.getTime() === newDate.getTime()) {
      return;
    }
    onChange(newDate);
  };

  const validateDate = (date: string | undefined): boolean => {
    if (!date) return false;
    const parsedDate = Date.parse(date as string);
    return !(parsedDate < 0 || isNaN(parsedDate));
  };

  const parsedDateFormat = parseDateFormat(dateFormat as string);

  const userEventsHandler = () => {
    if (!validateDate(rawDate)) {
      sendDate(null);
      return;
    }

    if (props.showTimeInput) {
      sendDate(value);
      return;
    }
    sendDate(new Date(rawDate as string));
  };
  const onBlurHandler = () => userEventsHandler();

  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      userEventsHandler();
    }
  };

  useEffect(() => {
    if (!selected) {
      setValue(null);
      return;
    }
    const fakeDate = buildDateToShow(selected, currentTz);
    setValue(fakeDate);
  }, [selected]);

  const pickerRef = useRef<ReactDatePicker>(null);

  const customInput = (
    <Input
      labelPosition={minimalistic ? undefined : 'right'}
      data-automation-id={dataAutomationId}
      label={
        minimalistic
          ? undefined
          : {
              style: {
                width: '2.6rem',
              },

              ...(value && isClearable
                ? {
                    icon: 'close',
                    onClick: () => {
                      setValue(null);
                      sendDate(null);
                    },
                  }
                : {
                    icon,
                    onClick: () => {
                      pickerRef.current?.setOpen(true);
                    },
                  }),
            }
      }
      {...inputProps}
    />
  );

  useEffect(() => {
    if (!includeDates || includeDates.length === 0) return;
    const dates = transformIncludedDates(includeDates, currentTz);
    setTransformedIncludedDates(dates);
  }, [includeDates]);

  return (
    <ReactDatePicker
      includeDates={transformedIncludedDates || undefined}
      ref={pickerRef}
      placeholderText="Select date..."
      dateFormat={parsedDateFormat}
      customInput={customInput}
      selected={value}
      popperPlacement="bottom-start"
      popperModifiers={[
        {
          name: 'arrow',
          options: {
            padding: ({ popper }) => ({
              left: 0,
              right: popper.width - 25,
            }),
          },
        },
      ]}
      {...dpProsp}
      onKeyDown={onKeyDownHandler}
      onChange={(date, event) => {
        if (!props.showTimeInput) {
          return;
        }
        sendDate(date);
      }}
      onSelect={(date, event) => {
        if (props.showTimeInput) {
          return;
        }
        sendDate(date);
      }}
      onBlur={onBlurHandler}
      onChangeRaw={(event) => {
        setRawDate(event.target.value);
      }}
    />
  );
};

export default DateTimePicker;
