import { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import moment from 'moment-timezone';
import { toast } from 'react-semantic-toasts';
import { humanizeText } from '@bluefox/lib/humanize';
import {
  BotReportsQuery,
  InsertVaccinationMutation,
  JobStatusSubscription,
  RemoveVaccinationMutation,
} from '@graphql/bot';
import { VaccineAdministratorsQuery } from '@graphql/mappings';
import {
  BotReportType,
  BotReportStatus,
  BotReportFilterStatusOptions,
} from '@bluefox/models/Bot';
import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Button,
  Container,
  Label,
  Popup,
  Dropdown,
  Pagination,
  Input,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import BotReportForm from '@ui/BotReportForm';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';

import { debounce } from '@bluefox/lib/debounce';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { Dosage } from '@bluefox/models/Vaccine';
import VaccineInfoBox from '@ui/Vaccines/VaccineInfoBox';
import {
  Notification,
  NotificationStatus,
  NotificationType,
  VaxSyncIntegrationNotificationContent,
} from '@bluefox/models/Notification';
import { GetNotificationsByEntityRefIdQuery } from '@graphql/communications';
import VaxSyncIntegrationNotificationModal, {
  VaxSyncIntegrationNotificationModalData,
} from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationModal';
import { FormValues } from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationForm';
import { useNotifications } from '@ui/Notifications/useNotifications';
import { notificationStatusEnumToBeShownText } from '@bluefox/constants/notifications';
import { ALL } from '@bluefox/lib/options';
import NotificationStatusDropdown from '@bluefox/ui/Notifications/NotificationStatusDropdown';

interface PracticeAccount {
  title: string;
}

interface VaccineAdministrator {
  email: string;
  firstName: string;
  lastName: string;
  practiceAccounts: PracticeAccount[];
}

interface VaccineAdministratorsData {
  accounts: VaccineAdministrator[];
}

interface PracticeOption {
  text: string;
  value: string;
}
interface BotReportsData {
  botJobs: BotReportType[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

enum BotJobTypes {
  appointment = 'appointment',
  vaccination = 'vaccination',
}
const botTypeOptions = [
  { text: 'Appointment', value: BotJobTypes.appointment },
  { text: 'Vaccination', value: BotJobTypes.vaccination },
];

const ENTRIES_PER_PAGE = 15;

const BotReportsScreen = () => {
  const [editingRow, setEditingRow] = useState<BotReportType>();
  const [criteria, setCriteria] = useState<object>({});
  const [practiceId, setPracticeId] = useState<string>();
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [searchPractice, setSearchPractice] = useState<string>();
  const [searchPatient, setSearchPatient] = useState<string>();
  const [searchBotType, setSearchBotType] = useState<string>(
    BotJobTypes.vaccination
  );
  const [searchNotificationStatus, setSearchNotificationStatus] = useState<
    NotificationStatus | typeof ALL
  >(ALL);
  const [searchStatus, setSearchStatus] = useState<string>();
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);

  const [sendNotificationModalData, setSendNotificationModalData] =
    useState<VaxSyncIntegrationNotificationModalData>();
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues);

  const [page, setPage] = useState(0);
  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const { data, loading, error, refetch } = useQuery<BotReportsData>(
    BotReportsQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
    }
  );

  const { sendNotificationHandler, editNotificationHandler } =
    useNotifications();

  const {
    data: notificationsInScreenData,
    loading: notificationsInScreenLoading,
    refetch: notificationsInScreenRefetch,
  } = useQuery<{
    communicationNotifications: Notification[];
  }>(GetNotificationsByEntityRefIdQuery, {
    variables: {
      entityRefIds: data?.botJobs.map((bj) => bj.id),
    },
    skip: !data?.botJobs || !data?.botJobs.length,
  });

  const rawEmails = data?.botJobs.map((item) => {
    if (item.metadata.provider?.account.email) {
      return item.metadata.provider?.account.email;
    } else {
      return '';
    }
  });

  const emails = rawEmails?.filter(
    (item, idx) => rawEmails.indexOf(item) === idx
  );

  const { data: accountsData, refetch: refetchAccountsData } =
    useQuery<VaccineAdministratorsData>(VaccineAdministratorsQuery, {
      variables: {
        emails,
      },
      skip: !emails,
    });

  const { data: jobStatusData } = useSubscription<BotReportsData>(
    JobStatusSubscription,
    {
      variables: {
        criteria,
      },
      skip: !data || !accountsData,
    }
  );

  const handlePracticeValue = (value: string) => {
    const practiceValue =
      !!value && value !== 'allPractices' ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeId(practiceValue);
  };

  const handleStatusValue = (value: string) => {
    const statusValue =
      !!value && value !== 'allStatus' ? value?.toString() : undefined;
    setSearchStatus(statusValue || '');
  };

  useEffect(() => {
    if (!data?.allPractices || data.allPractices.length < 1) return;

    setPracticeOptions(
      data.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  useEffect(() => {
    let _criteria = {};

    _criteria = {
      ...(searchPractice ? { practiceId: { _eq: searchPractice } } : {}),
      ...(searchBotType ? { type: { _eq: searchBotType } } : {}),
      ...(searchStatus ? { status: { _eq: searchStatus } } : {}),
      ...(fromDate || toDate
        ? {
            createdAt: {
              _gt: fromDate
                ? moment(fromDate).startOf('day').format()
                : fromDate,
              _lte: moment(toDate).endOf('day').format() ?? new Date(),
            },
          }
        : {}),
      ...(searchPatient
        ? {
            practicePatient: {
              _or: [
                {
                  patientData: {
                    _or: [
                      {
                        firstName: {
                          _ilike: whereLikeInput(searchPatient),
                        },
                      },
                      {
                        lastName: { _ilike: whereLikeInput(searchPatient) },
                      },
                    ],
                  },
                },
                { mrn: { _ilike: whereLikeInput(searchPatient) } },
              ],
            },
          }
        : {}),
      ...(searchNotificationStatus && searchNotificationStatus !== ALL
        ? {
            notification: {
              status: { _eq: searchNotificationStatus },
            },
          }
        : {}),
    };

    setCriteria(_criteria);
  }, [
    searchPractice,
    searchStatus,
    fromDate,
    toDate,
    practiceId,
    searchPatient,
    searchBotType,
    searchNotificationStatus,
  ]);

  // ========== UNCOMMENT THIS USEEFFECT WHEN UPDATED AT COLUMNS ARE FIXED==========//

  // useEffect(() => {
  //   if (!jobStatusData) return;

  //   const jobData = jobStatusData.botJobs[0];

  //   if (!jobData) return;

  //   if (jobData.status === BotReportStatus.ERROR) {
  //     toast({
  //       title: jobData.metadata.practicePatient?.mrn
  //         ? `Job for MRN ${
  //             jobData.metadata.practicePatient.mrn
  //           } of practice ${jobData.practice.name.toUpperCase()} finished with error`
  //         : `Job for patient ${jobData.metadata.practicePatient?.patientData.firstName} ${jobData.metadata.practicePatient?.patientData.lastName} (${jobData.metadata.practicePatient?.patientData.birthdate}) finished with error`,
  //       type: "warning",
  //       time: 8000,
  //     });
  //   } else if (jobData.status === BotReportStatus.COMPLETED) {
  //     toast({
  //       title: jobData.metadata.practicePatient?.mrn
  //         ? `Job for MRN ${
  //             jobData.metadata.practicePatient.mrn
  //           } of practice ${jobData.practice.name.toUpperCase()} is completed`
  //         : `Job for patient ${jobData.metadata.practicePatient?.patientData.firstName} ${jobData.metadata.practicePatient?.patientData.lastName} (${jobData.metadata.practicePatient?.patientData.birthdate}) is completed`,
  //       type: "success",
  //       time: 8000,
  //     });
  //   }
  // }, [jobStatusData]);

  useEffect(() => {
    if (!data && !accountsData) return;
    refetch();
    refetchAccountsData();
  }, [data, accountsData]);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const refetchNotificationInScreen = useCallback(async () => {
    try {
      await notificationsInScreenRefetch?.();
    } catch (error) {
      toast({
        title: `Failed to refetch notification list. Error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [notificationsInScreenRefetch]);

  return (
    <MainLayout
      path={[
        { text: 'ECW Integration', to: '/integration-ecw' },
        { text: 'Bot Reports' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="wrench" style={{ marginRight: '0.6rem' }} />
              Bot Reports
            </Card.Header>
            <Card.Description>
              <Menu
                borderless
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by type"
                      fluid
                      selection
                      onChange={(e, data) => {
                        setSearchBotType(data.value as string);
                      }}
                      value={searchBotType}
                      defaultValue={BotJobTypes.vaccination}
                      options={[...botTypeOptions]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All practices', value: 'allPractices' },
                        ...practiceOptions,
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        ...BotReportFilterStatusOptions,
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      display: 'flex',
                    }}
                  >
                    <label
                      htmlFor="notification_status_dropdown_filter"
                      style={{ marginRight: '0.5rem' }}
                    >
                      <b>Notification Status: </b>
                    </label>
                    <NotificationStatusDropdown
                      internalTools
                      value={searchNotificationStatus}
                      onChange={(value) => {
                        setSearchNotificationStatus(
                          value as NotificationStatus | typeof ALL
                        );
                      }}
                    />
                  </Menu.Item>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginRight: '1rem',
                    flexWrap: 'wrap',
                  }}
                >
                  <Menu.Item>
                    <label style={{ marginRight: '0.4rem' }}>
                      <b>Patient:</b>
                    </label>
                    <Input
                      onChange={(_, { value }) => {
                        debouncedRef.current?.cancel();
                        debouncedRef.current = debounce(() => {
                          setSearchPatient(value);
                        }, 300);

                        debouncedRef.current();
                      }}
                      icon="search"
                      placeholder="Search patient"
                      loading={loading}
                    />
                  </Menu.Item>
                  <Menu.Item fitted>
                    <label style={{ marginRight: '0.4rem' }}>
                      <b>From:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From date"
                      selected={fromDate}
                      onChange={(d) => setFromDate(d ? (d as Date) : undefined)}
                      onSelect={(value) =>
                        setFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item fitted style={{ marginLeft: '1rem' }}>
                    <label style={{ marginRight: '0.4rem' }}>
                      <b>To:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="To date"
                      selected={toDate}
                      onChange={(d) => setToDate(d ? (d as Date) : undefined)}
                      onSelect={(value) =>
                        setToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setToDate(undefined)}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </div>
              </Menu>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Practice</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Patient</Table.HeaderCell>
                      <Table.HeaderCell>Date Of Service</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell>Vaccine</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Given By</Table.HeaderCell>
                      <Table.HeaderCell>Route/Site</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Error</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={10}>
                          <Segment basic>
                            <Placeholder fluid>
                              <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Header>
                            </Placeholder>
                          </Segment>
                        </Table.Cell>
                      </Table.Row>
                    ) : !!data?.botJobs.length ? (
                      data.botJobs.map((report) => {
                        const finalData = accountsData?.accounts.find(
                          (elem) =>
                            elem.email ===
                            report.metadata.provider?.account.email
                        );

                        const newItem = {
                          ...report,
                          firstName: finalData?.firstName,
                          lastName: finalData?.lastName,
                          title: finalData?.practiceAccounts[0].title,
                        };
                        return (
                          <BotReportRow
                            key={newItem.id}
                            data={newItem}
                            notificationsLoading={notificationsInScreenLoading}
                            notifications={
                              notificationsInScreenData?.communicationNotifications
                            }
                            openForm={() => setEditingRow(newItem)}
                            onSendNotification={() => {
                              setFormValues({
                                version: 2,
                              });
                              const patientData =
                                newItem.metadata.practicePatient?.patientData;
                              setSendNotificationModalData({
                                fullPatienName: `${patientData?.lastName} ${patientData?.firstName}`,
                                givenAt: newItem.metadata
                                  .givenAt as unknown as string,
                                mrn: newItem.metadata.practicePatient?.mrn,
                                practiceId: newItem.practiceId,
                                practicePatientId: newItem.practicePatientId,
                                entityRefId: newItem.id,
                                vaccinationId: newItem.entityRef,
                                vaccineName:
                                  report.metadata.vaccine?.name || '-',
                                inventoryVfc: report.metadata.inventory?.vfc,
                                lot: report.metadata.inventory?.lot,
                              });
                            }}
                          />
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={10}>
                          <Message>No Reports Found</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                      <Table.HeaderCell colSpan={10} textAlign="right">
                        <Pagination
                          disabled={!total || total < ENTRIES_PER_PAGE}
                          defaultActivePage={1}
                          boundaryRange={0}
                          siblingRange={1}
                          onPageChange={(e, { activePage }) =>
                            setPage(activePage as number)
                          }
                          totalPages={totalPages}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
        {!!editingRow && (
          <BotReportForm
            onClose={() => setEditingRow(undefined)}
            onSave={() => {
              refetch();
            }}
            data={editingRow}
          />
        )}
        <VaxSyncIntegrationNotificationModal
          open={!!sendNotificationModalData}
          fullPatienName={sendNotificationModalData?.fullPatienName}
          givenAt={sendNotificationModalData?.givenAt}
          mrn={sendNotificationModalData?.mrn}
          practiceId={sendNotificationModalData?.practiceId}
          practicePatientId={sendNotificationModalData?.practicePatientId}
          notificationType={NotificationType.ecw}
          entityRefId={sendNotificationModalData?.entityRefId}
          vaccinationId={sendNotificationModalData?.vaccinationId}
          vaccineName={sendNotificationModalData?.vaccineName}
          inventoryVfc={sendNotificationModalData?.inventoryVfc}
          lot={sendNotificationModalData?.lot}
          onClose={() => {
            setFormValues({});
            setSendNotificationModalData(undefined);
          }}
          formValues={formValues}
          setFormValues={setFormValues}
          onCreate={async (notification: Notification) => {
            setFormValues({});
            setSendNotificationModalData(undefined);
            await sendNotificationHandler(notification);
            await refetchNotificationInScreen();
          }}
          onEdit={async (
            notification: Notification,
            content: VaxSyncIntegrationNotificationContent,
            newStatusLogItem: any
          ) => {
            await editNotificationHandler(
              notification,
              content,
              newStatusLogItem
            );
            await refetchNotificationInScreen();
          }}
        />
      </Container>
    </MainLayout>
  );
};

//---------------------------------BOT REPORT ROW---------------------------------//

interface BotReportRowProps {
  data: BotReportType;
  openForm: () => void;
  onSendNotification?: () => void;
  notificationsLoading: boolean;
  notifications?: Notification[];
}

const BotReportRow = ({
  data,
  openForm,
  onSendNotification,
  notificationsLoading,
  notifications,
}: BotReportRowProps) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loadingJob, setLoadingJob] = useState(
    data.status === BotReportStatus.RUNNING ? true : false
  );

  const isFirstRender = useRef(true);

  const [handleVaccinationJob] = useMutation(
    data.metadata.action === 'create' || data.metadata.action === 'update'
      ? InsertVaccinationMutation
      : RemoveVaccinationMutation
  );

  const runVaccinationInsertion = () => {
    setLoadingJob(true);
    handleVaccinationJob({
      variables: {
        jobId: data.id,
      },
    })
      .then((r) => {
        if (
          r.data.TriggerVaccinationInsertJob &&
          r.data.TriggerVaccinationInsertJob.code === 400
        ) {
          toast({
            title: r.data.TriggerVaccinationInsertJob.message,
            type: 'warning',
            time: 5000,
          });
          setOpenConfirm(false);
          return;
        }

        toast({
          title: 'Vaccination job ran successfully',
          type: 'success',
          time: 1000,
        });
        setOpenConfirm(false);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const renderSendNotificationButton = useCallback(() => {
    const notification = notifications?.find((n) => n.entityRefId === data.id);
    return (
      <>
        <Popup
          content={`${
            notificationStatusEnumToBeShownText[
              notification?.status as NotificationStatus
            ] || 'Send a notification'
          }`}
          trigger={
            <Button
              style={{ margin: '0.1rem' }}
              loading={notificationsLoading}
              icon="send"
              size="mini"
              color={notification ? 'green' : 'blue'}
              onClick={onSendNotification}
            />
          }
        />
      </>
    );
  }, [data.id, notifications, notificationsLoading, onSendNotification]);

  const renderSendNotificationStatus = useCallback(() => {
    const notification = notifications?.find((n) => n.entityRefId === data.id);
    return (
      <>
        {notification?.status && (
          <p style={{ marginTop: '1rem' }}>
            <b>Notification Status:</b>
            {` ${
              notificationStatusEnumToBeShownText[
                notification?.status as NotificationStatus
              ]
            }`}
          </p>
        )}
      </>
    );
  }, [data.id, notifications]);

  useEffect(() => {
    if (!data) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (data.status === BotReportStatus.RUNNING) return;

    setLoadingJob(false);
  }, [data]);

  return (
    <Table.Row key={data.id}>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>
        <Label
          size="small"
          content={humanizeText(data.status, { capitalize: 'first' })}
          color={
            data.status === BotReportStatus.PENDING
              ? 'orange'
              : data.status === BotReportStatus.ERROR
              ? 'red'
              : data.status === BotReportStatus.RUNNING
              ? 'teal'
              : 'olive'
          }
        />
      </Table.Cell>
      <Table.Cell>
        {`${
          data.metadata.practicePatient?.patientData
            ? data.metadata.practicePatient.patientData.firstName
            : '-'
        } ${
          data.metadata.practicePatient?.patientData
            ? data.metadata.practicePatient?.patientData.lastName
            : '-'
        } (${
          data.metadata.practicePatient?.patientData
            ? data.metadata.practicePatient?.patientData.birthdate
            : '-'
        }) `}
        <Label
          basic
          size="tiny"
          content={`MRN: ${data.metadata.practicePatient?.mrn}`}
        />
      </Table.Cell>
      <Table.Cell>
        {`${moment(data.metadata.givenAt).format(DateFormats.DATE)}`}
      </Table.Cell>
      <Table.Cell>
        <Label
          size="small"
          content={humanizeText(data.type, { capitalize: 'first' })}
        />
      </Table.Cell>
      <Table.Cell>
        {data.type === 'vaccination' ? (
          <>
            {data.metadata.primaryVaccinationId ? (
              <Popup
                trigger={
                  <Icon
                    name="warning sign"
                    color="yellow"
                    style={{ margin: '0 auto' }}
                  />
                }
                content="Associated"
                position="top center"
              />
            ) : null}
            {data.metadata.vaccine?.name}
            <VaccineInfoBox
              ndc={data.metadata.vaccine?.saleNdc || '-'}
              manufacturer={data.metadata.vaccine?.manufacturer || '-'}
              types={data.metadata.vaccine?.types?.map((t: string) => t) || '-'}
              dosage={
                data.metadata.vaccine?.dosage?.map((item: Dosage) => {
                  return `${item.dose}${item.unit}`;
                }) || '_'
              }
              inventoryUsed={data.metadata.inventory?.vfc ? 'VFC' : 'Not VFC'}
              lot={data.metadata.inventory?.lot || '-'}
              expiration={data.metadata.inventory?.expiration as Date}
            />
          </>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data.firstName
          ? `${data.firstName} ${data.lastName} (${data.title})`
          : 'Missing Provider Data'}
      </Table.Cell>
      <Table.Cell>{`${data.metadata.route} / ${humanizeText(
        data.metadata.site ? data.metadata.site : '-',
        {
          delimiter: '_',
          capitalize: 'all',
        }
      )}`}</Table.Cell>
      <Table.Cell>
        {data.error?.message ? (
          <Popup
            on="click"
            trigger={<Button content="Show error" size="tiny" />}
            content={data.error ? data.error.message : '-'}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              marginBottom: '0.5rem',
            }}
          >
            <div>
              <label>
                <b>Action: </b>
              </label>
              {data.metadata.action}
            </div>
            <div>
              <label>
                <b>Added by: </b>
              </label>
              {data.source || '-'}
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Popup
              content={data.note.message ? 'Edit note' : 'Add note'}
              size="small"
              trigger={
                <Button primary icon="edit" size="mini" onClick={openForm} />
              }
            />
            {data.status === BotReportStatus.ERROR ||
            data.status === BotReportStatus.RUNNING ? (
              <Popup
                on="click"
                open={openConfirm}
                onOpen={() => setOpenConfirm(true)}
                onClose={() => setOpenConfirm(false)}
                trigger={
                  loadingJob || data.status === BotReportStatus.RUNNING ? (
                    <Button basic icon="play" size="mini" loading />
                  ) : (
                    <Button color="teal" icon="play" size="mini" />
                  )
                }
                content={
                  <div>
                    <h3>Are you sure you want to run job?</h3>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        size="tiny"
                        content="No"
                        onClick={() => setOpenConfirm(false)}
                      />
                      <Button
                        size="tiny"
                        color="olive"
                        content="Yes"
                        onClick={runVaccinationInsertion}
                      />
                    </div>
                  </div>
                }
              />
            ) : null}
            {data.note.message && (
              <Popup
                size="small"
                content={data.note.message}
                trigger={<Icon name="info circle" size="large" color="teal" />}
              />
            )}
            <Popup
              size="small"
              on="click"
              content={data.id}
              trigger={<Label style={{ cursor: 'pointer' }}>Id</Label>}
            />
            {renderSendNotificationButton()}
          </div>
          {renderSendNotificationStatus()}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default BotReportsScreen;
