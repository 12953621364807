import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertBillingStrategyMutation,
  UpdateBillingStrategyMappingMutation,
} from '@graphql/mappings';
import { BillingStrategy } from '@bluefox/models/Mappings';
import { BillingStrategyOptions } from '@bluefox/models/Mappings';
import { Button, Form } from 'semantic-ui-react';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import ProviderPicker from '@bluefox/ui/ProviderPicker';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import { PracticeQuery } from '@graphql/practices';

interface BillingStrategyMappingFormProps {
  close: () => void;
  data: BillingStrategy | null;
  billingStrategy?: string;
  onSave: () => void;
  allowedUsersToEditNpiAndTaxId?: string[];
}

const BillingStrategyMappingForm = ({
  close,
  data,
  billingStrategy,
  onSave,
  allowedUsersToEditNpiAndTaxId,
}: BillingStrategyMappingFormProps) => {
  const practice = usePractice();
  const { session } = useApplicationState();

  const [providerId, setProviderId] = useState<string>(data?.providerId || '');
  const [insuranceId, setInsuranceId] = useState<string>(
    data?.insuranceCompanyId || ''
  );
  const [cpid, setCpid] = useState<string>(data?.payerId || '');
  const [taxId, setTaxId] = useState<string>(data?.taxId || '');
  const [npi, setNpi] = useState<string>(data?.npi || '');
  const [selectedProviderProfile, setSelectedProviderProfile] = useState(null);

  const isAllowedToEditNpiAndTaxId = allowedUsersToEditNpiAndTaxId
    ? allowedUsersToEditNpiAndTaxId.includes(session?.account?.email || '')
    : false;

  const [saveBillingStrategyMapping] = useMutation(
    data?.id
      ? UpdateBillingStrategyMappingMutation(
          billingStrategy === BillingStrategyOptions.PRACTICE
        )
      : InsertBillingStrategyMutation
  );

  const handleSubmit = () => {
    saveBillingStrategyMapping({
      variables: {
        id: data?.id,
        practiceId: practice.id,
        providerId: providerId ? providerId : null,
        cpid: cpid ? cpid : null,
        insuranceId: insuranceId ? insuranceId : null,
        taxId,
        npi,
        strategy: billingStrategy,
        practicePofileTaxId:
          billingStrategy === BillingStrategyOptions.PRACTICE
            ? { federalTaxId: taxId }
            : {},
        practiceCriteria:
          billingStrategy === BillingStrategyOptions.PRACTICE ? { npi } : {},
        practiceAccountPofileTaxId:
          billingStrategy === BillingStrategyOptions.PROVIDER
            ? { federalTaxId: taxId }
            : {},
        accountCriteria:
          billingStrategy === BillingStrategyOptions.PROVIDER ? npi : {},
        profile: selectedProviderProfile
          ? selectedProviderProfile
          : {
              federalTaxId: taxId,
            },
      },
      refetchQueries: [
        {
          query: PracticeQuery,
          variables: {
            id: practice.id,
          },
        },
      ],
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
        close();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} data-automation-id="vaccine-mapping-form">
        <Form.Group widths="equal">
          {billingStrategy === BillingStrategyOptions.PROVIDER ||
          billingStrategy === BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
            <Form.Field>
              <label>Provider</label>
              <ProviderPicker
                defaultValue={providerId}
                placeholder="Select Provider"
                onChange={(providerId) => setProviderId(providerId)}
                onProviderSelected={(provider) => {
                  setSelectedProviderProfile(provider.profile);
                }}
              />
            </Form.Field>
          ) : null}
          {billingStrategy === BillingStrategyOptions.PRACTICE_AND_INSURANCE ||
          billingStrategy === BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
            <Form.Field>
              <label>Insurance Company</label>
              <InsuranceCompanyPicker
                placeholder="Insurance Name"
                value={insuranceId}
                onCompanySelected={(company) => {
                  setCpid(company.cpid || '');
                  setInsuranceId(company.id);
                }}
                includePayerId
              />
            </Form.Field>
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              value={taxId}
              onChange={(_, { value }) => setTaxId(value)}
              fluid
              label="Tax ID"
              placeholder="Tax ID"
              disabled={!isAllowedToEditNpiAndTaxId}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              value={npi}
              onChange={(_, { value }) => setNpi(value)}
              fluid
              label="NPI"
              placeholder="NPI"
              disabled={!isAllowedToEditNpiAndTaxId}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={close} type="button">
            Cancel
          </Button>
          <Button primary type="submit" content="Save" icon="save" />
        </Form.Field>
      </Form>
    </>
  );
};

export default BillingStrategyMappingForm;
