import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import * as echarts from 'echarts';

type Props = {
  options: echarts.EChartsOption;
  style?: CSSProperties;
};

export type Options = echarts.EChartsOption;

const EchartsContainer = ({ options, style, ...rest }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const echartRef = useRef<echarts.ECharts>();

  const initEchartIfNeeded = useCallback(() => {
    if (!echartRef.current) {
      echartRef.current = echarts.init(containerRef.current, null);
    }
  }, []);

  const handleResize = useCallback(() => {
    echartRef.current?.resize();
  }, []);

  useEffect(() => {
    initEchartIfNeeded();
    echartRef.current?.resize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      echartRef.current?.dispose();
      echartRef.current = undefined;
    };
  }, [handleResize, initEchartIfNeeded]);

  useEffect(() => {
    initEchartIfNeeded();
    echartRef.current?.setOption(options);
  }, [initEchartIfNeeded, options]);

  return (
    <div
      ref={containerRef}
      style={style || { width: '100%', height: '100%' }}
      {...rest}
    />
  );
};

export default EchartsContainer;
