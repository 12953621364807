import { useState } from 'react';
import { toast } from 'react-semantic-toasts';
import { useMutation } from '@apollo/client';
import { VaccineSelfPayPriceMutation } from '@bluefox/graphql/vaccines';
import { Vaccine, VaccineSelfPayPrice } from '@bluefox/models/Vaccine';
import { Form, Modal, Button } from 'semantic-ui-react';
import VaccinePicker from '@bluefox/ui/VaccinePicker';

interface SelfPayVaccineModalProps {
  onSave: () => void;
  close: () => void;
  data?: VaccineSelfPayPrice;
}

const SelfPayVaccineModal = ({
  onSave,
  close,
  data,
}: SelfPayVaccineModalProps) => {
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();
  const [selfPayPrice, setSelfPayPrice] = useState<number>();

  const [saveSlefPaidPrice] = useMutation(VaccineSelfPayPriceMutation);

  const handleSaveSlefPaidPrice = () => {
    saveSlefPaidPrice({
      variables: {
        id: selectedVaccine?.id,
        selfPayPrice,
      },
    })
      .then((r) => {
        toast({
          title: 'Vaccine saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const cleanAndClose = () => {
    setSelectedVaccine(undefined);
    setSelfPayPrice(undefined);
    close();
  };

  return (
    <>
      <Modal.Header>{data ? 'Edit Vaccine' : 'Add Vaccine'}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Vaccine</label>
              <VaccinePicker
                dropdownProps={{
                  fluid: true,
                  placeholder: 'Search Vaccine',
                }}
                onChange={({ vaccine }) => setSelectedVaccine(vaccine)}
                value={data?.id ?? selectedVaccine?.id}
                disabled={!!data}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required
                label="Self Pay Price"
                placeholder="Price"
                type="number"
                min="0"
                step=".01"
                value={selfPayPrice}
                onChange={(_, { value }) => setSelfPayPrice(parseFloat(value))}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="cancel" onClick={cleanAndClose} />
        <Button
          primary
          content="Save"
          onClick={handleSaveSlefPaidPrice}
          disabled={!selectedVaccine || !selfPayPrice}
        />
      </Modal.Actions>
    </>
  );
};

export default SelfPayVaccineModal;
