import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Container,
  Card,
  Icon,
  Table,
  Segment,
  Placeholder,
  Message,
  Label,
} from 'semantic-ui-react';
import ClaimsTable from './general/generalClaimsTable';
import {
  BillingClaimsView,
  ProcedureAmountsQuery,
  BillingClaimsCount,
} from '@graphql/billing';
import { BillingClaimView } from '@bluefox/models/Billing';
import UpdateBillingClaimForm from '@ui/Billing/UpdateBillingClaimForm';

interface ClaimsCountData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface BillingClaimViewData {
  billingClaims: BillingClaimView[];
}

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

const PatientsClaims = () => {
  const { id, claimId } = useParams<{ id: string; claimId: string }>();
  const history = useHistory();

  const {
    data: viewData,
    loading: viewLoading,
    refetch: viewRefetch,
  } = useQuery<BillingClaimViewData>(BillingClaimsView, {
    variables: {
      criteria: {
        practicePatientId: {
          _eq: id,
        },
      },
    },
  });

  const { data: procedureAmountsData } = useQuery<ProcedureAmountsData>(
    ProcedureAmountsQuery,
    {
      variables: {
        type: 'Administration',
      },
    }
  );

  const { data: totalClaims } = useQuery<ClaimsCountData>(BillingClaimsCount, {
    variables: {
      criteria: {
        practicePatientId: {
          _eq: id,
        },
      },
    },
  });

  return (
    <Container>
      <Card fluid style={{ marginTop: '1rem' }}>
        <Card.Content>
          <Card.Header as={'h3'}>
            <Icon name="user" />
            <Icon
              name="list alternate outline"
              style={{ marginRight: '0.6rem' }}
            />
            {`${viewData?.billingClaims[0].claim.practicePatient.patientData.firstName} ${viewData?.billingClaims[0].claim.practicePatient.patientData.lastName}'s Claims`}
          </Card.Header>
          <Card.Description>
            <Table selectable sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="Practice" />
                  <Table.HeaderCell content="Patient" width={3} />
                  <Table.HeaderCell content="Insurance Company" />
                  <Table.HeaderCell content="Service Date" width={2} />
                  <Table.HeaderCell content="Amount" />
                  <Table.HeaderCell content="Inventory Used" />
                  <Table.HeaderCell content="Status" />
                </Table.Row>
              </Table.Header>

              {viewLoading ? (
                <Table.Row>
                  <Table.Cell colSpan={7}>
                    <Segment basic>
                      <Placeholder fluid>
                        <Placeholder.Header>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                      </Placeholder>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              ) : viewData?.billingClaims ? (
                <ClaimsTable
                  claims={viewData.billingClaims}
                  onClaimUpdate={viewRefetch}
                  onPatientSelection={() => console.log('onPatientSelected')}
                  onEditInsuranceClick={() =>
                    console.log('OnEditInsuranceClick')
                  }
                  refetch={viewRefetch}
                  patientClaimsOpened
                />
              ) : (
                <Table.Body>
                  <Table.Cell colSpan={7}>
                    <Message>No claims found for this patient</Message>
                  </Table.Cell>
                </Table.Body>
              )}
              <Table.Footer>
                <Table.Row textAlign="right">
                  <Table.HeaderCell colSpan={7}>
                    <Label
                      basic
                      content={`Total: ${totalClaims?.aggregating.aggregate.count}`}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Card.Description>
        </Card.Content>
      </Card>
      {!!claimId && (
        <UpdateBillingClaimForm
          claimId={claimId}
          adminCptCodes={procedureAmountsData}
          onClose={() => {
            history.push(`/billing/practice-patient/${id}`);
          }}
          onSave={() => {
            viewRefetch();
            history.push(`/billing/practice-patient/${id}`);
          }}
        />
      )}
    </Container>
  );
};

export default PatientsClaims;
