import { COLORS_BY_NAME } from '@bluefox/constants';
import EchartsContainer from '@bluefox/ui/EchartsContainer';
import * as echarts from 'echarts';

export type OvertimeChartData = { date: string; amount: number };
type Props = { overtimeData: OvertimeChartData[] };

const OvertimeChart = ({ overtimeData }: Props) => {
  return (
    <EchartsContainer
      options={{
        grid: {
          top: '5%',
          bottom: '10%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: overtimeData.map((i) => i.date),
          axisLabel: {
            interval: (index: number, value: string) =>
              index === 0 || index === overtimeData.length - 1,
            formatter: (value): string => value.substring(0, 7),
          },
        },
        tooltip: {
          trigger: 'item',
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: overtimeData.map((i) => i.amount),
            type: 'line',
            lineStyle: {
              color: COLORS_BY_NAME['Metallic Seaweed'],
              width: 3,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(21,170,191,1)',
                },
                {
                  offset: 1,
                  color: 'rgba(21,170,191,0.3)',
                },
              ]),
            },
          },
        ],
      }}
    />
  );
};

export default OvertimeChart;
