import { useQuery } from '@apollo/client';
import { parseFloatOrZero } from '@bluefox/lib/maths';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import {
  InvoicingStrategy,
  OrganizationSettings,
  StripeSettings,
  StripeSettingsPaymentMethod,
} from '@bluefox/models/Organization';
import {
  Practice,
  StripeSettings as PracticeStripeSettings,
} from '@bluefox/models/Practice';
import { GetBillingInvoicingStrategies } from '@graphql/organizations';
import { CSSProperties, useMemo, useState } from 'react';
import { Card, Form } from 'semantic-ui-react';

const isStripeCustomerIdAndPaymenMethodFilled = (
  customerId: string | undefined,
  paymentMethod: StripeSettingsPaymentMethod | undefined
): boolean => isStringNotEmpty(customerId) && paymentMethod !== undefined;

const isAllPracticesStripeSettingsFilled = (practices: Practice[]): boolean => {
  return practices.every((practice) => {
    return isStripeCustomerIdAndPaymenMethodFilled(
      practice.settings?.stripe?.customerId,
      practice.settings?.stripe?.paymentMethod
    );
  });
};

const isStripeSettingDataFilled = (
  stripeSettings?: StripeSettings,
  invoicingStrategy?: InvoicingStrategy,
  practices?: Practice[]
): boolean => {
  if (invoicingStrategy === InvoicingStrategy.BY_ORGANIZATION) {
    return isStripeCustomerIdAndPaymenMethodFilled(
      stripeSettings?.customerId,
      stripeSettings?.paymentMethod
    );
  }
  return isAllPracticesStripeSettingsFilled(practices || []);
};

type Props = {
  enableEdition: boolean;
  formValues: OrganizationSettings;
  practices: Practice[];
  setPractices: React.Dispatch<React.SetStateAction<Practice[]>>;
  setFormValues: React.Dispatch<React.SetStateAction<OrganizationSettings>>;
  onSubmit?: (
    organizationSettings: OrganizationSettings,
    practices?: Practice[]
  ) => void;
  onCancel?: () => void;
  organizationId?: string;
  style?: CSSProperties;
};

interface Option {
  text: string;
  value: string;
}

interface Strategy {
  comment: string;
  value: string;
}

const OrganizationSettingsForm = ({
  enableEdition,
  formValues,
  setFormValues,
  practices,
  setPractices,
  onSubmit,
  onCancel,
  organizationId,
  style,
}: Props) => {
  const [invoicingStrategiesOptions, setInvoicingStrategiesOptions] = useState<
    Option[]
  >([]);

  const [fee, discount, stripeSettings, billingReport] = useMemo(
    () => [
      formValues?.fee,
      formValues?.discount,
      formValues.stripeSettings,
      formValues.billingReport,
    ],
    [
      formValues.billingReport,
      formValues?.discount,
      formValues?.fee,
      formValues.stripeSettings,
    ]
  );
  const disableSaveButton =
    !enableEdition ||
    (fee?.enabled && (!fee?.amount || !fee?.minimumAmountToRecover)) ||
    (discount?.enabled && !discount?.percentage) ||
    (stripeSettings?.enabled &&
      !isStripeSettingDataFilled(
        formValues.stripeSettings,
        formValues.invoicing?.strategy as InvoicingStrategy,
        practices
      ));

  useQuery(GetBillingInvoicingStrategies, {
    onCompleted(data) {
      setInvoicingStrategiesOptions(
        data.strategies.map((invoicingStrategy: Strategy) => ({
          text: invoicingStrategy.comment,
          value: invoicingStrategy.value,
        }))
      );
    },
  });

  const updatePracticeStripeCustomerId = (
    practiceId: string,
    keyToChange: keyof PracticeStripeSettings,
    newValue: any
  ) => {
    // Find the index of the practice to update
    const practiceIndex = practices.findIndex(
      (practice) => practice.id === practiceId
    );

    // If practiceIndex is -1, practice with given ID is not found
    if (practiceIndex === -1) {
      console.error('Practice not found with ID:', practiceId);
      return;
    }

    // Clone the practices array to avoid mutation
    const updatedPractices = [...practices];

    // Update the customerId of the stripe keys for the practice at practiceIndex
    updatedPractices[practiceIndex] = {
      ...updatedPractices[practiceIndex],
      settings: {
        ...updatedPractices[practiceIndex].settings,
        stripe: {
          ...updatedPractices[practiceIndex].settings?.stripe,
          [keyToChange]: newValue,
        },
      },
    };

    // Update the state with the modified practices array
    setPractices(updatedPractices);
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(formValues, practices);
      }}
      style={style}
    >
      <Card fluid>
        <Card.Content>
          <Card.Header>Invoice Configuration</Card.Header>
          <Card.Description>
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <div style={{ display: 'flex' }}>
                    <Form.Checkbox
                      id="monthly-fee-checkbox"
                      toggle
                      checked={fee?.enabled || false}
                      onChange={(_, { checked }) =>
                        setFormValues(
                          (prevVal) =>
                            ({
                              ...prevVal,
                              fee: {
                                ...prevVal.fee,
                                enabled: checked,
                              },
                            }) as OrganizationSettings
                        )
                      }
                      readOnly={!enableEdition}
                    />
                    <label
                      style={{ marginLeft: '1rem' }}
                      htmlFor="monthly-fee-checkbox"
                    >
                      Monthly Fee
                    </label>
                  </div>
                </Card.Header>
                <Card.Description>
                  <Form.Group widths="equal">
                    <Form.Input
                      label="Minimum Invoice Amount"
                      type="number"
                      min="0"
                      step="0.01"
                      disabled={!fee?.enabled}
                      required={fee?.enabled}
                      value={fee?.minimumAmountToRecover || 0}
                      onChange={(_, { value }) =>
                        setFormValues(
                          (prevVal) =>
                            ({
                              ...prevVal,
                              fee: {
                                ...prevVal.fee,
                                minimumAmountToRecover: parseFloatOrZero(value),
                              },
                            }) as OrganizationSettings
                        )
                      }
                      readOnly={!enableEdition}
                    />
                    <Form.Input
                      label="Monthly Fee Amount"
                      type="number"
                      min="0"
                      step="0.01"
                      disabled={!fee?.enabled}
                      required={fee?.enabled}
                      value={fee?.amount || 0}
                      onChange={(_, { value }) =>
                        setFormValues(
                          (prevVal) =>
                            ({
                              ...prevVal,
                              fee: {
                                ...prevVal.fee,
                                amount: parseFloatOrZero(value),
                              },
                            }) as OrganizationSettings
                        )
                      }
                      readOnly={!enableEdition}
                    />
                  </Form.Group>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <div style={{ display: 'flex' }}>
                    <Form.Checkbox
                      id="discount-enabled-checkbox"
                      toggle
                      checked={discount?.enabled || false}
                      onChange={(_, { checked }) =>
                        setFormValues(
                          (prevVal) =>
                            ({
                              ...prevVal,
                              discount: {
                                ...prevVal.discount,
                                enabled: checked,
                              },
                            }) as OrganizationSettings
                        )
                      }
                      readOnly={!enableEdition}
                    />
                    <label
                      style={{ marginLeft: '1rem' }}
                      htmlFor="discount-enabled-checkbox"
                    >
                      Discount
                    </label>
                  </div>
                </Card.Header>
                <Card.Description>
                  <Form.Input
                    label="Discount Percentage"
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    disabled={!discount?.enabled}
                    required={discount?.enabled}
                    value={discount?.percentage || 0}
                    onChange={(_, { value }) =>
                      setFormValues(
                        (prevVal) =>
                          ({
                            ...prevVal,
                            discount: {
                              ...prevVal.discount,
                              percentage: parseFloatOrZero(value),
                            },
                          }) as OrganizationSettings
                      )
                    }
                    readOnly={!enableEdition}
                  />
                </Card.Description>
              </Card.Content>
            </Card>
            <Form.Input
              fluid
              label="Emails"
              type="email"
              multiple
              value={
                (formValues.invoiceReceiverEmails &&
                  formValues.invoiceReceiverEmails.join(',')) ||
                ''
              }
              onChange={(_, { value }) => {
                setFormValues((prevVal) => ({
                  ...prevVal,
                  invoiceReceiverEmails: value
                    ? (value as string).toLocaleLowerCase().split(',')
                    : undefined,
                }));
              }}
              readOnly={!enableEdition}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div style={{ display: 'flex' }}>
              <Form.Checkbox
                id="stripe-configuration-checkbox"
                toggle
                checked={stripeSettings?.enabled || false}
                onChange={(_, { checked }) =>
                  setFormValues(
                    (prevVal) =>
                      ({
                        ...prevVal,
                        stripeSettings: {
                          ...prevVal.stripeSettings,
                          enabled: checked,
                        },
                      }) as OrganizationSettings
                  )
                }
                readOnly={!enableEdition}
              />
              <label
                style={{ marginLeft: '1rem' }}
                htmlFor="stripe-configuration-checkbox"
              >
                Stripe
              </label>
            </div>
          </Card.Header>
          <Card.Description>
            <Form.Dropdown
              label="Invoice Strategy"
              style={{ width: 'max-content' }}
              placeholder="Select Billing Strategy"
              selection
              value={
                formValues.invoicing
                  ? (formValues.invoicing.strategy as InvoicingStrategy)
                  : InvoicingStrategy.BY_ORGANIZATION
              }
              onChange={(e, { value }) => {
                setFormValues(
                  (prevVal) =>
                    ({
                      ...prevVal,
                      invoicing: {
                        strategy: value,
                      },
                    }) as OrganizationSettings
                );
              }}
              options={invoicingStrategiesOptions}
              disabled={!enableEdition || !formValues.stripeSettings?.enabled}
            />
            {!formValues.invoicing ||
            formValues.invoicing.strategy ===
              InvoicingStrategy.BY_ORGANIZATION ? (
              <Form.Group widths="equal">
                <Form.Input
                  label="Customer ID"
                  type="text"
                  disabled={!stripeSettings?.enabled}
                  required={stripeSettings?.enabled}
                  value={stripeSettings?.customerId || ''}
                  onChange={(_, { value }) =>
                    setFormValues(
                      (prevVal) =>
                        ({
                          ...prevVal,
                          stripeSettings: {
                            ...prevVal.stripeSettings,
                            customerId: value,
                          },
                        }) as OrganizationSettings
                    )
                  }
                  readOnly={
                    !enableEdition || !formValues.stripeSettings?.enabled
                  }
                />
                <Form.Input
                  label="Days Until Due"
                  type="number"
                  min="0"
                  step="1"
                  disabled={!formValues.stripeSettings?.enabled}
                  value={stripeSettings?.daysUntilDue || 0}
                  onChange={(_, { value }) =>
                    setFormValues(
                      (prevVal) =>
                        ({
                          ...prevVal,
                          stripeSettings: {
                            ...prevVal.stripeSettings,
                            daysUntilDue: parseFloatOrZero(value),
                          },
                        }) as OrganizationSettings
                    )
                  }
                  readOnly={
                    !enableEdition || !formValues.stripeSettings?.enabled
                  }
                />

                <Form.Dropdown
                  label="Payment Method"
                  value={stripeSettings?.paymentMethod || ''}
                  disabled={
                    !enableEdition || !formValues.stripeSettings?.enabled
                  }
                  required={stripeSettings?.enabled}
                  options={[
                    {
                      key: StripeSettingsPaymentMethod.AUTO,
                      value: StripeSettingsPaymentMethod.AUTO,
                      text: 'Auto',
                    },
                    {
                      key: StripeSettingsPaymentMethod.MANUAL,
                      value: StripeSettingsPaymentMethod.MANUAL,
                      text: 'Manual',
                    },
                  ]}
                  onChange={(_, { value }) =>
                    setFormValues(
                      (prevVal) =>
                        ({
                          ...prevVal,
                          stripeSettings: {
                            ...prevVal.stripeSettings,
                            paymentMethod: value as StripeSettingsPaymentMethod,
                          },
                        }) as OrganizationSettings
                    )
                  }
                  fluid
                  selection
                />
              </Form.Group>
            ) : (
              practices.map((practice) => (
                <Form.Group widths="equal" key={practice.id}>
                  <Form.Input
                    label="Practice Name"
                    type="text"
                    value={practice.name}
                    readOnly={true}
                  />
                  <Form.Input
                    label="Customer ID"
                    type="text"
                    disabled={
                      !enableEdition || !formValues.stripeSettings?.enabled
                    }
                    required={stripeSettings?.enabled}
                    value={practice.settings?.stripe?.customerId || ''}
                    onChange={(_, { value }) =>
                      updatePracticeStripeCustomerId(
                        practice.id,
                        'customerId',
                        value
                      )
                    }
                    readOnly={
                      !enableEdition || !formValues.stripeSettings?.enabled
                    }
                  />
                  <Form.Input
                    label="Days Until Due"
                    disabled={
                      !enableEdition || !formValues.stripeSettings?.enabled
                    }
                    required={stripeSettings?.enabled}
                    value={practice.settings?.stripe?.daysUntilDue || 0}
                    onChange={(_, { value }) =>
                      updatePracticeStripeCustomerId(
                        practice.id,
                        'daysUntilDue',
                        value
                      )
                    }
                    readOnly={
                      !enableEdition || !formValues.stripeSettings?.enabled
                    }
                  />

                  <Form.Dropdown
                    label="Payment Method"
                    value={practice.settings?.stripe?.paymentMethod || ''}
                    disabled={
                      !enableEdition || !formValues.stripeSettings?.enabled
                    }
                    required={stripeSettings?.enabled}
                    options={[
                      {
                        key: StripeSettingsPaymentMethod.AUTO,
                        value: StripeSettingsPaymentMethod.AUTO,
                        text: 'Auto',
                      },
                      {
                        key: StripeSettingsPaymentMethod.MANUAL,
                        value: StripeSettingsPaymentMethod.MANUAL,
                        text: 'Manual',
                      },
                    ]}
                    onChange={(_, { value }) =>
                      updatePracticeStripeCustomerId(
                        practice.id,
                        'paymentMethod',
                        value
                      )
                    }
                    fluid
                    selection
                  />
                </Form.Group>
              ))
            )}
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header style={{ marginBottom: '1.5rem' }}>
            Billing Report
          </Card.Header>
          <Card.Description>
            <Form.Group>
              <Form.Checkbox
                id="billing-report-show-administration-amounts"
                toggle
                checked={billingReport?.showAdministrationAmounts || false}
                onChange={(_, { checked }) =>
                  setFormValues(
                    (prevVal) =>
                      ({
                        ...prevVal,
                        billingReport: {
                          ...prevVal.billingReport,
                          showAdministrationAmounts: checked,
                        },
                      }) as OrganizationSettings
                  )
                }
                readOnly={!enableEdition}
              />
              <label
                style={{ marginLeft: '1rem' }}
                htmlFor="billing-report-show-administration-amounts"
              >
                <b>Show Administration Amounts</b>
              </label>
            </Form.Group>
          </Card.Description>
        </Card.Content>
      </Card>
      <Form.Group style={{ paddingTop: '1.7rem', justifyContent: 'end' }}>
        <Form.Button
          type="button"
          secondary
          disabled={!enableEdition}
          onClick={onCancel}
        >
          Cancel
        </Form.Button>
        <Form.Button type="submit" primary disabled={disableSaveButton}>
          Save
        </Form.Button>
      </Form.Group>
    </Form>
  );
};

export default OrganizationSettingsForm;
