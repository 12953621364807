import { pixelToRem } from '@bluefox/constants';
import { CSSProperties } from 'react';
import { Card, Icon, SemanticICONS, SemanticCOLORS } from 'semantic-ui-react';
import styled from 'styled-components';

export type LegendType = {
  icon: {
    name: SemanticICONS;
    color: SemanticCOLORS;
  };
  title: string;
  value: string | number;
  subValue?: string | number;
};

export type DirectionType = 'row' | 'column';

type Props = {
  legends: LegendType[];
  direction?: DirectionType;
  style?: CSSProperties;
  columns?: number;
};

const GridContainer = styled.div<{ direction: DirectionType }>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'column' ? 'column' : 'row'};
  flex-wrap: wrap;
  margin-bottom: ${({ direction }) =>
    direction === 'row' ? `-${pixelToRem(16)}rem` : null};
`;

const GridItem = styled.div<{ direction: DirectionType; columns?: number }>`
  margin-bottom: ${pixelToRem(16)}rem;
  display: flex;
  flex-grow: 1;
  &:last-child {
    margin-bottom: 0;
  }
  width: ${({ columns }) => (columns ? `${100 / columns}%` : null)};
`;

const TitleParagraph = styled.p`
  margin: 0.25rem 0 ${pixelToRem(8)}rem !important;
  font-weight: 500;
  font-size: ${pixelToRem(14)}rem;
  line-height: ${16 / 14};
`;

const ValueParagraph = styled.p`
  margin: 0 !important;
  font-weight: 700;
  font-size: ${pixelToRem(20)}rem;
  line-height: ${24 / 20};
  vertical-align: middle;
`;

const SubValueSpan = styled.span`
  margin: 0 0 0 ${pixelToRem(4)}rem !important;
  font-weight: 500;
  font-size: ${pixelToRem(12)}rem;
  line-height: 1;
  vertical-align: middle;
`;

const Legends = ({ legends, direction = 'row', columns, style }: Props) => {
  return (
    <Card fluid={direction === 'row'} style={style}>
      <Card.Content style={{ padding: `${pixelToRem(24)}rem` }}>
        <GridContainer direction={direction}>
          {legends.map(({ title, value, icon, subValue }) => (
            <GridItem
              direction={direction}
              key={`${title} ${value}`}
              columns={columns}
            >
              <div>
                <Icon
                  name={icon.name}
                  bordered
                  inverted
                  color={icon.color}
                  size="small"
                  style={{
                    borderRadius: '5px',
                    margin: `0 ${pixelToRem(8)}rem 0 0`,
                  }}
                />
              </div>
              <div>
                <TitleParagraph>{title}</TitleParagraph>
                <ValueParagraph>
                  {value}
                  {subValue !== undefined && (
                    <SubValueSpan>{subValue}</SubValueSpan>
                  )}
                </ValueParagraph>
              </div>
            </GridItem>
          ))}
        </GridContainer>
      </Card.Content>
    </Card>
  );
};

export default Legends;
