import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { BotWeeklyErrors } from '@graphql/bot';
import Pagination from '@bluefox/ui/Pagination';
import {
  Form,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
} from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import PracticeFilter from '../Filters/PracticeFilter';
import RowsPerPage from '../Filters/RowsPerPage';

interface BotWeeklyErrorsListType {
  practiceId: string;
  name: string;
  month: number;
  message: string;
  errorCount: number;
  week: Date;
  year: number;
}

const HomeBotWeeklyErrors = () => {
  const [criteria, setCriteria] = useState<object>({});
  const [searchPractice, setSearchPractice] = useState<string>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);

  const [page, setPage] = useState(0);

  const { data, loading, error } = useQuery(BotWeeklyErrors, {
    variables: {
      criteria,
      limit: rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
    },
    skip: rowsPerPage === 0,
    fetchPolicy: 'network-only',
  });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / rowsPerPage);

  const handleFromSelect = useCallback((date: Date | undefined) => {
    if (!date) {
      setFromDate(undefined);
      return;
    }

    const selectedDate = new Date(date);

    const dayOfWeek = selectedDate.getDay();

    const diff =
      selectedDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const previousMonday = new Date(selectedDate.setDate(diff));
    setFromDate(previousMonday);
  }, []);

  const handleToSelect = useCallback((date: Date | undefined) => {
    if (!date) {
      setToDate(undefined);
      return;
    }
    const selectedDate = new Date(date);

    const dayOfWeek = selectedDate.getDay();

    const diff = selectedDate.getDate() + (7 - dayOfWeek);
    const nextSunday = new Date(selectedDate.setDate(diff));
    setToDate(nextSunday);
  }, []);

  useEffect(() => {
    let _criteria = {};

    _criteria = {
      _and: [
        {
          ...(searchPractice !== 'all'
            ? { practiceId: { _eq: searchPractice } }
            : {}),
        },
        {
          ...(fromDate || toDate
            ? {
                week: {
                  _gte: fromDate ? fromDate : fromDate,
                  _lte: toDate ?? new Date(),
                },
              }
            : {}),
        },
      ],
    };

    setCriteria(_criteria);
    setPage(1);
  }, [searchPractice, fromDate, toDate, rowsPerPage]);

  useEffect(() => {
    const rows = Number(localStorage.getItem('botWeeklyErrorsListRowConfig'));
    setRowsPerPage(rows >= 0 ? rows : 15);
  }, []);

  return (
    <>
      <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
        <Menu.Menu
          style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '1rem' }}
        >
          <Menu.Item>
            <RowsPerPage
              rows={rowsPerPage}
              setRows={setRowsPerPage}
              localStorageItem="botWeeklyErrorsListRowConfig"
            />
          </Menu.Item>
          {rowsPerPage > 0 && (
            <>
              <Menu.Item>
                <PracticeFilter
                  setPracticeSearch={setSearchPractice}
                  practiceSearch={searchPractice}
                  suspended="notSuspended"
                  integration="ecw"
                />
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <label>
                    <b>From:</b>
                  </label>
                  <DateTimePicker
                    placeholderText="From date"
                    selected={fromDate}
                    onChange={(value) => handleFromSelect(value as Date)}
                    onClear={() => handleFromSelect(undefined)}
                    maxDate={new Date()}
                    dateFormat={DateFormats.DATE}
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                    dropdownMode="select"
                    isClearable
                  />
                </Form>
              </Menu.Item>
              <Menu.Item>
                <Form>
                  <label>
                    <b>To:</b>
                  </label>
                  <DateTimePicker
                    placeholderText="To date"
                    selected={toDate}
                    onChange={(value) => handleToSelect(value as Date)}
                    onClear={() => setToDate(undefined)}
                    dateFormat={DateFormats.DATE}
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                    dropdownMode="select"
                    isClearable
                  />
                </Form>
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      {rowsPerPage > 0 && (
        <>
          {error ? (
            <Message error>{error.message}</Message>
          ) : (
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Practice</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                  <Table.HeaderCell>Error Count</Table.HeaderCell>
                  <Table.HeaderCell>Week</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={6}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data?.botWeeklyErrors.length ? (
                  data.botWeeklyErrors.map(
                    (errors: BotWeeklyErrorsListType, index: number) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{errors.name}</Table.Cell>
                          <Table.Cell>{errors.message}</Table.Cell>
                          <Table.Cell>{errors.errorCount}</Table.Cell>
                          <Table.Cell>
                            {formatDatetimeToMMDDYYY(errors.week)}
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  )
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={6}>
                      <Message>No Weekly Errors Found</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>

              <Pagination
                total={total}
                colSpan={6}
                position="right"
                activePage={page}
                totalPages={totalPages}
                onPageChange={(newActivePage) => setPage(newActivePage)}
              />
            </Table>
          )}
        </>
      )}
    </>
  );
};

export default HomeBotWeeklyErrors;
