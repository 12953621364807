import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { SavePracticePatientNotes } from '@graphql/patients';
import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Dropdown,
  Form,
  Card,
  Input,
  Popup,
  Message,
} from 'semantic-ui-react';
import {
  PatientNotes,
  PatientNotesTopics,
  noteTopicOptions,
} from '@bluefox/models/Patient';
import { humanizeText } from '@bluefox/lib/humanize';
import FullNoteModal from './FullNoteModal';
import EditPatientNotesModal from './EditPatientNotesModal';

const TopicSortingCriteria = {
  [PatientNotesTopics.PATIENT_INFO]: 1,
  [PatientNotesTopics.INSURANCE_COMPANY]: 2,
  [PatientNotesTopics.MEMBER_ID]: 3,
  [PatientNotesTopics.PRACTICE_ACCOUNT_NUMBER]: 4,
  [PatientNotesTopics.CLAIM_ISSUES]: 5,
  [PatientNotesTopics.PROVIDER]: 6,
} as { [key: string]: number };

type PatientNotesModalProps = {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
  practicePatientId: string;
  patientNotes: PatientNotes[];
  showEditButton?: boolean;
};

const PatientNotesModal = ({
  open,
  onClose,
  onSave,
  practicePatientId,
  patientNotes,
  showEditButton = true,
}: PatientNotesModalProps) => {
  const [note, setNote] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [topic, setTopic] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedNote, setSelectedNote] = useState('');
  const [editedNote, setEditedNote] = useState<{
    note: string;
    index: number | null;
  }>({ note: '', index: null });

  const [openFullNoteModal, setOpenFullNoteModal] = useState(false);
  const [openEditNoteModal, setOpenEditNoteModal] = useState(false);

  const [savePatientNotes] = useMutation(SavePracticePatientNotes);

  const handleSavePatientnotes = async () => {
    if (patientNotes.some((item) => item.topic === topic))
      return toast({
        title:
          'There can only be one note per topic. Please edit the existing one.',
        type: 'warning',
        time: 5000,
      });

    try {
      await savePatientNotes({
        variables: {
          practicePatientId,
          notes: [
            ...patientNotes,
            {
              topic: topic,
              note: note,
            },
          ],
        },
      });
      toast({
        title: 'Patient notes saved successfully',
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      cleanAndClose();
      if (onSave) onSave();
    }
  };

  const handleSaveEditedNote = async () => {
    try {
      const newPatientNotes = [...patientNotes];
      newPatientNotes[editedNote.index as number] = {
        ...newPatientNotes[editedNote.index as number],
        note: editedNote.note,
      };
      await savePatientNotes({
        variables: {
          practicePatientId,
          notes: newPatientNotes,
        },
      });
      toast({
        title: 'Patient notes saved successfully',
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      cleanAndClose();
      if (onSave) onSave();
    }
  };

  const handleEditNote = (note: string) => {
    setEditedNote((prev) => ({ ...prev, note }));
  };

  const cleanAndClose = () => {
    setTopic('');
    setNote('');
    setShowForm(false);
    onClose();
    setSelectedTopic('');
    setSelectedNote('');
    setOpenEditNoteModal(false);
  };

  return (
    <Modal size="small" onClose={cleanAndClose} open={open}>
      <ModalHeader>Notes</ModalHeader>
      <ModalContent>
        {showEditButton && patientNotes.length >= 6 && (
          <Message
            error
            icon="warning sign"
            content="The maximum number of allowed topics has been reached. If you want to continue addig notes please edit existing ones."
          />
        )}
        <ModalDescription style={{ paddingLeft: '0' }}>
          {showForm && (
            <Form>
              <Form.Field>
                <label>Select note's topic</label>
                <Dropdown
                  placeholder="Select topic"
                  fluid
                  selection
                  value={topic}
                  onChange={(_, { value }) => {
                    setTopic(value as string);
                  }}
                  options={noteTopicOptions}
                />
              </Form.Field>
              <Form.Field>
                <Form.TextArea
                  label="Please write your notes below"
                  placeholder="Write your notes here..."
                  value={note}
                  onChange={(_, { value }) => {
                    setNote(value as string);
                  }}
                />
              </Form.Field>
            </Form>
          )}
          {patientNotes?.length ? (
            <Card
              fluid
              style={{
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {patientNotes?.map((note, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '0.2rem',
                      justifyContent: 'space-between',
                      order: TopicSortingCriteria[note.topic],
                    }}
                  >
                    <label style={{ width: '10rem' }}>
                      <b>
                        {humanizeText(note.topic, {
                          capitalize: 'first',
                          delimiter: '_',
                        })}
                      </b>
                    </label>
                    <Input
                      value={
                        note.note?.length > 50
                          ? note.note.slice(0, 50).concat('...')
                          : note.note
                      }
                      style={{
                        width: '30rem',
                      }}
                      readOnly
                    />
                    <div>
                      <Popup
                        content="See full note"
                        trigger={
                          <Button
                            color="teal"
                            icon="eye"
                            onClick={() => {
                              setSelectedTopic(note.topic);
                              setSelectedNote(note.note);
                              setOpenFullNoteModal(true);
                            }}
                          />
                        }
                      />
                      {showEditButton && (
                        <Popup
                          content="Edit"
                          trigger={
                            <Button
                              primary
                              icon="edit"
                              onClick={() => {
                                setEditedNote({
                                  note: note.note,
                                  index: idx,
                                });
                                setOpenEditNoteModal(true);
                              }}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </Card>
          ) : (
            <Form>
              <Form.Field>
                <label>Select note's topic</label>
                <Dropdown
                  placeholder="Select topic"
                  fluid
                  selection
                  value={topic}
                  onChange={(_, { value }) => {
                    setTopic(value as string);
                  }}
                  options={noteTopicOptions}
                />
              </Form.Field>
              <Form.Field>
                <Form.TextArea
                  label="Please write your notes below"
                  placeholder="Write your notes here..."
                  value={note}
                  onChange={(_, { value }) => {
                    setNote(value as string);
                  }}
                />
              </Form.Field>
            </Form>
          )}
        </ModalDescription>
        {showEditButton && (
          <Message warning content="Only one note per topic is allowed." />
        )}
      </ModalContent>
      <ModalActions>
        <Button content="Cancel" onClick={cleanAndClose} />
        {!!patientNotes.length &&
        !showForm &&
        showEditButton &&
        patientNotes.length < 6 ? (
          <Button
            content="New Note"
            icon="save"
            onClick={() => setShowForm(true)}
            primary
          />
        ) : showEditButton ? (
          <Button
            content="Save"
            icon="save"
            onClick={handleSavePatientnotes}
            primary
            disabled={!note || !topic}
          />
        ) : null}
      </ModalActions>
      <FullNoteModal
        open={openFullNoteModal}
        onClose={() => setOpenFullNoteModal(false)}
        topic={selectedTopic}
        note={selectedNote}
      />
      <EditPatientNotesModal
        open={openEditNoteModal}
        onClose={() => setOpenEditNoteModal(false)}
        onSave={handleSaveEditedNote}
        onEditNote={handleEditNote}
        note={editedNote}
      />
    </Modal>
  );
};

export default PatientNotesModal;
