import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  Label,
  List,
  Menu,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { TicketQuery } from '@bluefox/graphql/tickets';
import {
  TicketDetailFieldType,
  TicketsResponse,
} from '@bluefox/models/Tickets';
import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters/dates';
import Pagination from '@bluefox/ui/Pagination';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import RowsPerPage from '@ui/Filters/RowsPerPage';

const HomeTickets = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [searchPractice, setSearchPractice] = useState<string>('all');

  const { data } = useQuery<TicketsResponse>(TicketQuery, {
    variables: {
      practiceFilter:
        searchPractice !== 'all'
          ? { _contains: { fields: [{ detail: searchPractice }] } }
          : {},
      status: ['open', 'in_progress'],
      type: [
        'Bug_report',
        'Feature_request',
        'Inventory_receipt_issues',
        'Wasted_doses_report',
        'Inventory_adjustment',
      ],
      limit: rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
    },
    skip: rowsPerPage === 0,
    nextFetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const rows = Number(localStorage.getItem('HomeTicketsRowConfig'));
    setRowsPerPage(rows >= 0 ? rows : 15);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchPractice, rowsPerPage]);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / rowsPerPage);

  return (
    <>
      <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item>
            <RowsPerPage
              rows={rowsPerPage}
              setRows={setRowsPerPage}
              localStorageItem="HomeTicketsRowConfig"
            />
          </Menu.Item>
          {rowsPerPage > 0 && (
            <>
              <Menu.Item>
                <PracticeFilter
                  practiceSearch={searchPractice}
                  setPracticeSearch={setSearchPractice}
                  suspended="notSuspended"
                  filterByHandler
                />
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      {rowsPerPage > 0 && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Practice</Table.HeaderCell>

              <Table.HeaderCell>Date Placed</Table.HeaderCell>
              <Table.HeaderCell>Ticket Type</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Detail</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!data?.tickets.length ? (
              data?.tickets.map((ticket: any) => (
                <Table.Row key={ticket.id}>
                  <Table.Cell>
                    {ticket.detail.fields
                      ?.filter((t: any) => {
                        return t.title === 'Practice';
                      })
                      .map((ticketFields: TicketDetailFieldType) => (
                        <List.Item key={ticketFields.title}>
                          <List.Content>{ticketFields.detail}</List.Content>
                        </List.Item>
                      ))}
                  </Table.Cell>

                  <Table.Cell>
                    {formatDateToMMDDYYYY(ticket.createdAt)}
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      basic
                      content={humanizeText(ticket.type, {
                        capitalize: 'all',
                        delimiter: '_',
                      })}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      basic
                      content={humanizeText(ticket.status, {
                        capitalize: 'all',
                        delimiter: '_',
                      })}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <List relaxed>
                      {ticket.detail.fields
                        ?.filter((t: any) => {
                          return (
                            t.title === 'Feature Title' ||
                            t.title === 'Error Short Description' ||
                            t.title === 'Reason for Wastage' ||
                            t.title === 'Issue ' ||
                            t.title === 'Adjustment reason'
                          );
                        })
                        .map((ticketFields: TicketDetailFieldType) => (
                          <List.Item key={ticketFields.title}>
                            <List.Content>
                              {ticketFields.title === 'Issue '
                                ? humanizeText(ticketFields.detail, {
                                    capitalize: 'all',
                                    delimiter: '_',
                                  })
                                : ticketFields.detail}
                            </List.Content>
                          </List.Item>
                        ))}
                    </List>
                  </Table.Cell>
                  <Table.Cell>
                    <Modal
                      closeIcon
                      trigger={<Button primary icon="eye"></Button>}
                    >
                      <Modal.Content>
                        <Table definition>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell />
                              <Table.HeaderCell>Description</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {ticket.detail.fields
                              ?.filter((t: any) => {
                                return (
                                  t.title !== 'Practice' &&
                                  t.title !== 'Handler'
                                );
                              })
                              .map((ticketFields: any, i: any) => {
                                if (
                                  ticketFields.title
                                    .toLowerCase()
                                    .includes('date')
                                ) {
                                  ticketFields.detail = formatDateToMMDDYYYY(
                                    ticketFields.detail
                                  );
                                }
                                return (
                                  <Table.Row key={i}>
                                    <Table.Cell>
                                      {ticketFields.title}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {ticketFields.detail}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                          </Table.Body>
                        </Table>
                      </Modal.Content>
                    </Modal>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <Message>No Tickets Found</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Pagination
            total={total}
            colSpan={11}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
      )}
    </>
  );
};

export default HomeTickets;
