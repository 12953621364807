import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { SaveEmrClaimNumberMutation } from '@graphql/billing';
import { Button, Form, Header, Modal } from 'semantic-ui-react';

interface EmrClaimNumberFormProps {
  onSave: () => void;
  emrClaimNumber: string | null;
  claimId: string;
  trigger: React.ReactNode;
}

const EmrClaimNumberForm = ({
  onSave,
  emrClaimNumber,
  claimId,
  trigger,
}: EmrClaimNumberFormProps) => {
  const [open, setOpen] = useState(false);
  const [claimNumber, setClaimNumber] = useState<string>('');

  const [saveEmrClaimNumber] = useMutation(SaveEmrClaimNumberMutation);

  const handleSubmit = () => {
    saveEmrClaimNumber({
      variables: {
        claimId,
        emrClaimNumber: claimNumber,
      },
    })
      .then((r) => {
        {
          toast({
            title: 'EMR claim number saved successfully',
            type: 'success',
            time: 1000,
          });
        }
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
    cleanAndClose();
  };

  const cleanAndClose = () => {
    setClaimNumber(claimNumber || '');
    setOpen(false);
  };

  useEffect(() => {
    if (!emrClaimNumber) return;

    setClaimNumber(emrClaimNumber);
  }, [emrClaimNumber]);

  return (
    <Modal
      closeIcon
      onOpen={() => setOpen(true)}
      onClose={() => {
        cleanAndClose();
      }}
      open={open}
      size="mini"
      trigger={trigger}
    >
      <Header>
        {emrClaimNumber ? 'Edit EMR claimnumber' : 'EMR claim number'}
      </Header>
      <Modal.Content>
        <Form id="emr-claim-number-form" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field width={16}>
              <Form.Input
                value={claimNumber}
                onChange={(_, { value }) => {
                  setClaimNumber(value);
                }}
                fluid
                label="EMR Claim Number"
                placeholder="EMR Claim Number"
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" content="Cancel" onClick={cleanAndClose} />
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          form="emr-claim-number-form"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EmrClaimNumberForm;
