import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { VaccinesWithSelfPayPriceQuery } from '@bluefox/graphql/vaccines';
import { VaccineSelfPayPrice } from '@bluefox/models/Vaccine';
import {
  Card,
  Icon,
  Container,
  Menu,
  Placeholder,
  Segment,
  Message,
  Button,
  Modal,
  Table,
  Label,
  Popup,
  Input,
  Pagination,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import SelfPayVaccineModal from '@screens/vaccinesPriceList/selfPayVaccineModal';

interface VaccineCriteria {
  selfPayPrice: object;
  fallback: object;
  saleNdc?: object;
  name?: object;
}

interface VaccineSelfPayPriceData {
  vaccines: VaccineSelfPayPrice[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const ENTRIES_PER_PAGE = 15;

const VaccinesPriceListScreen = () => {
  const initFilters: VaccineCriteria = {
    selfPayPrice: { _is_null: false },
    fallback: { _eq: false },
  };

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);

  const [criteria, setCriteria] = useState<VaccineCriteria>(initFilters);
  const [searchNdc, setSearchNdc] = useState('');
  const [searchName, setSearchName] = useState('');

  const { data, error, loading, refetch } = useQuery<VaccineSelfPayPriceData>(
    VaccinesWithSelfPayPriceQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : null,
      },
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  useEffect(() => {
    let _criteria: VaccineCriteria = {
      selfPayPrice: { _is_null: false },
      fallback: { _eq: false },
    };

    if (searchNdc) {
      _criteria = { ..._criteria, saleNdc: { _eq: searchNdc } };
    }

    if (searchName) {
      _criteria = { ..._criteria, name: { _ilike: `%${searchName}%` } };
    }

    setCriteria(_criteria);
    setPage(1);
  }, [searchNdc, searchName]);

  return (
    <MainLayout
      path={[
        { text: 'Billing', to: '/billing' },
        { text: 'Vaccines Price List' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="dollar" style={{ marginRight: '0.6rem' }} />
              Vaccines Price List
              <Button
                size="small"
                primary
                floated="right"
                onClick={() => setShowModal(true)}
              >
                <Icon name="plus" />
                Add Vaccine
              </Button>
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu position="right">
                  <Menu.Item>
                    <Input
                      placeholder="Search vaccine name..."
                      icon="search"
                      value={searchName}
                      onChange={(_, { value }) => setSearchName(value)}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Input
                      placeholder="Search vaccine NDC..."
                      icon="search"
                      value={searchNdc}
                      onChange={(_, { value }) => setSearchNdc(value)}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Vaccine</Table.HeaderCell>
                      <Table.HeaderCell>Self Pay Price</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={3}>
                          <Segment basic>
                            <Placeholder fluid>
                              <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Header>
                            </Placeholder>
                          </Segment>
                        </Table.Cell>
                      </Table.Row>
                    ) : !!data?.vaccines.length ? (
                      data.vaccines.map((v) => {
                        return (
                          <PriceListRow key={v.id} data={v} onSave={refetch} />
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={3}>
                          <Message>No vaccines found.</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                      <Table.HeaderCell colSpan={3} textAlign="right">
                        <Pagination
                          activePage={page || 1}
                          disabled={!total || total < ENTRIES_PER_PAGE}
                          boundaryRange={0}
                          siblingRange={1}
                          onPageChange={(e, { activePage }) =>
                            setPage(activePage as number)
                          }
                          totalPages={totalPages}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
      <Modal
        closeIcon
        open={showModal}
        onClose={() => setShowModal(false)}
        onOpen={() => setShowModal(true)}
      >
        <SelfPayVaccineModal
          onSave={() => {
            refetch();
            setShowModal(false);
          }}
          close={() => setShowModal(false)}
        />
      </Modal>
    </MainLayout>
  );
};

//------------------------------------PRICE LIST ROW------------------------------------//

interface PriceListRowProps {
  data: VaccineSelfPayPrice;
  onSave: () => void;
}

const PriceListRow = ({ data, onSave }: PriceListRowProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Table.Row>
      <Table.Cell>
        {data.name} - {data.manufacturer}
        <Label
          style={{ marginLeft: '0.5rem' }}
          basic
          size="small"
          content={data.saleNdc}
        />
        <Label
          style={{ marginLeft: '0.5rem' }}
          basic
          size="small"
          content={data.inventoryCptCodes}
        />
      </Table.Cell>
      <Table.Cell>{`$${data.selfPayPrice.toFixed(2)}`}</Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => setShowModal(true)}
            />
          }
        />
      </Table.Cell>
      <Modal
        closeIcon
        open={showModal}
        onClose={() => setShowModal(false)}
        onOpen={() => setShowModal(true)}
      >
        <SelfPayVaccineModal
          onSave={() => {
            onSave();
            setShowModal(false);
          }}
          close={() => setShowModal(false)}
          data={data}
        />
      </Modal>
    </Table.Row>
  );
};

export default VaccinesPriceListScreen;
