import InputMask from 'react-input-mask';
import { Dropdown, DropdownItemProps, Input } from 'semantic-ui-react';

const countriesOptions: DropdownItemProps[] = [
  { key: '1', text: '+1', value: '1', flag: 'us' },
];

interface PhoneInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const PhoneInput = ({ value, onChange }: PhoneInputProps) => {
  return (
    <InputMask
      mask="(999) 999 9999"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {(inputProps: Object) => (
        <Input
          {...inputProps}
          fluid
          label={
            <Dropdown disabled defaultValue="1" options={countriesOptions} />
          }
          labelPosition="left"
          placeholder="Phone number"
          type="tel"
        />
      )}
    </InputMask>
  );
};

export default PhoneInput;
