import { PracticePatient } from './Patient';

export enum VfcCriteria {
  not_applicable = 'not_applicable',
  medicaid = 'medicaid',
  uninsured = 'uninsured',
  ai_an = 'ai_an',
  underinsured = 'underinsured',
  unknown = 'unknown',
  ch_plus_b = 'ch_plus_b',
}

export const VFC_OPTIONS = [
  { key: 'medicaid', value: 'medicaid', text: 'Medicaid' },
  { key: 'uninsured', value: 'uninsured', text: 'Uninsured' },
  { key: 'ai_an', value: 'ai_an', text: 'Native American / Alaska Native' },
  {
    key: 'underinsured',
    value: 'underinsured',
    text: 'Underinsured',
  },
  { key: 'unknown', value: 'unknown', text: 'Unknown' },
];

export enum InsuranceRelationShips {
  Self = 'Self',
  Spouse = 'Spouse',
  Child = 'Child',
  Other = 'Other',
}

export enum InsuranceVerificationStatus {
  valid = 'valid',
  error = 'error',
  pending = 'pending',
  invalid = 'invalid',
  failed = 'failed',
  undetermined = 'undetermined',
  not_applicable = 'not_applicable',
}

export interface InsuranceCompany {
  id: string;
  name: string;
  cpid?: string;
  payerId?: string;
}

export interface InsuranceInput {
  practicePatientId: string;
  vfcEligible: boolean;
  vfcCriteria?: VfcCriteria;
  verificationStatus?: InsuranceVerificationStatus;
  verificationResult?: {
    errors?: {
      code: string;
      field: string;
    }[];
  };
  verifiedAt?: Date;
  insuranceCompanyId?: string;
  company?: InsuranceCompany;
  companies?: InsuranceCompany[];
  memberId?: string;
  groupId?: string;
  relationship?: InsuranceRelationShips;
  holderLastName?: string;
  holderFirstName?: string;
  holderDob?: Date;
  pcp?: string;
  active?: boolean;
  primary?: boolean;
  patient?: PracticePatient;
}

export interface VerifiableInsurance {
  id: string;
  verificationStatus?: InsuranceVerificationStatus;
  verificationResult?: {
    errors?: {
      code: string;
      field: string;
    }[];
  };
  verifiedAt?: Date;
}

export interface Insurance extends InsuranceInput {
  id: string;
}

export interface InsuranceCompany {
  id: string;
  cpid?: string;
  name: string;
  payerId?: string;
}
