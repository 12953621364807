import { gql } from '@apollo/client';

//MUTATIONS
export const InventoryAdjustmentMutation = gql`
  mutation InventoryAdjustmentMutation(
    $data: [inventories_insert_input!]!
    $inventoryAdjustment: inventory_adjustments_insert_input!
  ) {
    insert_inventories(
      objects: $data
      on_conflict: {
        constraint: inventories_pkey
        update_columns: [doses, lastAdjustmentDate, lastAdjustmentReason]
      }
    ) {
      affected_rows
    }
    insert_inventory_adjustments_one(object: $inventoryAdjustment) {
      id
    }
  }
`;

export const UpdateAdjustmentDetail = gql`
  mutation UpdateAdjustmentDetail(
    $objects: [inventory_adjustment_details_insert_input] = {}
    $id: uuid = ""
    $status: inventory_adjustment_statuses_enum = applied
    $adjustmentStatusLog: jsonb!
  ) {
    insert_inventory_adjustment_details(
      objects: $objects
      on_conflict: {
        constraint: inventory_adjustment_details_pkey
        update_columns: [statusLog, status]
      }
    ) {
      affected_rows
    }
    update_inventory_adjustments_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status, statusLog: $adjustmentStatusLog }
    ) {
      updatedAt
    }
  }
`;

// MUTATIONS

//QUERIES
export const AdjustmentStatusesQuery = gql`
  query AdjustmentStatusesQuery {
    adjustmentStatuses: inventory_adjustment_statuses {
      text: comment
      value
    }
  }
`;

export const AdjustmentDetailStatusesQuery = gql`
  query AdjustmentStatusesQuery {
    adjustmentStatuses: inventory_adjustment_statuses {
      text: comment
      value
    }
  }
`;

export const InventoryAdjustmentReasons = gql`
  query InventoryAdjustmentReasons {
    adjustmentReasons: inventory_adjustment_reasons {
      text: comment
      value
    }
  }
`;

export const InventoryAdjustmentReasonByValues = gql`
  query InventoryAdjustmentReasonByValues($values: [String!]) {
    adjustmentReasons: inventory_adjustment_reasons(
      where: { value: { _in: $values } }
    ) {
      text: comment
      value
    }
  }
`;

export const InventoryAdjustmentsQuery = gql`
  query InventoryAdjustmentsQuery(
    $practiceId: uuid!
    $status: inventory_adjustment_statuses_enum!
    $isVfc: Boolean!
  ) {
    adjustments: inventory_adjustments(
      where: {
        practiceId: { _eq: $practiceId }
        status: { _eq: $status }
        vfc: { _eq: $isVfc }
      }
      order_by: { createdAt: asc }
    ) {
      id
      createdAt
      updatedAt
      status
      statusLog
      details: inventoryAdjustmentDetails(order_by: { status: asc }) {
        id
        inventoryAdjustmentId
        status
        newDoses
        currentDoses
        statusLog
        type
        inventoryId
        reason
        comment
        inventory {
          id
          lot
          vaccine {
            name
          }
          doses
          practiceId
          vaccineId
          expiration
          status
        }
      }
    }
  }
`;

export const GetNotAppliedAdjustmentsQuery = gql`
  query GetNotAppliedAdjustmentsQuery($id: uuid!) {
    notAppliedPrivateAdjustments: inventory_adjustments(
      where: {
        practice: { id: { _eq: $id } }
        status: { _neq: applied }
        vfc: { _eq: false }
        inventoryAdjustmentDetails: { reason: { _neq: borrowing } }
      }
    ) {
      id
    }

    notAppliedVFCAdjustments: inventory_adjustments(
      where: {
        practice: { id: { _eq: $id } }
        status: { _neq: applied }
        vfc: { _eq: true }
        inventoryAdjustmentDetails: { reason: { _neq: borrowing } }
      }
    ) {
      id
    }
  }
`;

export const GetAllInventoryAdjustmentsQuery = gql`
  query GetAllInventoryAdjustmentsQuery(
    $criteria: inventory_adjustments_bool_exp
    $limit: Int
    $offset: Int
  ) {
    inventoryAdjustments: inventory_adjustments(
      where: $criteria
      order_by: { status: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      practice {
        name
      }
      vfc
      createdAt
      status
    }
    aggregating: inventory_adjustments_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

//QUERIES
