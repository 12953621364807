import { InvoicingStrategy } from '@bluefox/models/Organization';
import { Card, Grid } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import {
  GetOrganizationById,
  SubscribeToOrganizationById,
} from '@graphql/organizations';
import { useEffect } from 'react';
import { GetPracticeById, SubscribeToPracticeById } from '@graphql/practices';

export type EntityBalanceProps = {
  entityId: string;
  invoicingStrategy: InvoicingStrategy;
};

const EntityBalance = (props: EntityBalanceProps) => {
  const { entityId, invoicingStrategy } = props;

  const fields = ['balance', 'name'];

  const getQuery =
    invoicingStrategy === InvoicingStrategy.BY_ORGANIZATION
      ? GetOrganizationById
      : GetPracticeById;

  const getSubscription =
    invoicingStrategy === InvoicingStrategy.BY_ORGANIZATION
      ? SubscribeToOrganizationById
      : SubscribeToPracticeById;

  const { data, subscribeToMore } = useQuery<{
    response: { balance: number; name: string };
  }>(getQuery(fields), {
    variables: {
      id: entityId,
    },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: getSubscription(fields),
      variables: { id: entityId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });
    return () => unsubscribe();
  }, [entityId, subscribeToMore]);

  return (
    <Grid.Column width="3">
      <Card
        header={data?.response.name}
        meta={`balance: $ ${data?.response.balance}`}
      />
    </Grid.Column>
  );
};

export default EntityBalance;
