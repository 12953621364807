import { CSSProperties, Dispatch, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';
import {
  OrganizationsAndTheirPractices,
  OrganizationsAndTheirPracticesNotificationEnabled,
  OrganizationsSettingsAndTheirPractices,
} from '@bluefox/graphql/organizations';
import { Organization } from '@bluefox/models/Organization';
import { Practice } from '@bluefox/models/Practice';
import { Container, Form, Message } from 'semantic-ui-react';

export type SearchValuesProps = {
  [key: string]: any;
  selectedOrganization?: Organization;
  selectedPractices?: Practice[];
  practices?: Practice[];
  practicesIds?: string[];
};

type Props = {
  multiplePracticesSelect?: boolean;
  searchValues: Partial<SearchValuesProps>;
  setSearchValues: Dispatch<SetStateAction<Partial<SearchValuesProps>>>;
  onChange?: (values: SearchValuesProps) => void;
  organizationSettings?: boolean;
  onlyNotificationEnabled?: boolean;
  useAlternativePracticeSelectorBehavior?: boolean;
  style?: CSSProperties;
};

function buildQuery(
  organizationSettings: boolean,
  onlyNotificationEnabled: boolean
) {
  if (organizationSettings) {
    return OrganizationsSettingsAndTheirPractices;
  }
  if (onlyNotificationEnabled) {
    return OrganizationsAndTheirPracticesNotificationEnabled;
  }
  return OrganizationsAndTheirPractices;
}

const OrganizationAndPracticeSelector = ({
  multiplePracticesSelect,
  searchValues,
  setSearchValues,
  onChange,
  useAlternativePracticeSelectorBehavior = false,
  organizationSettings = false,
  onlyNotificationEnabled = false,
  style,
}: Props) => {
  const { loading, error, data } = useQuery(
    buildQuery(organizationSettings, onlyNotificationEnabled)
  );
  const organizations: Organization[] = data?.organizations || [];
  const practices: Practice[] | undefined =
    searchValues?.selectedOrganization?.practices;

  return (
    <Container style={style} fluid>
      {error && (
        <Message error>
          Failed to get Organizations and their Practices. Error:{' '}
          {error.message}
        </Message>
      )}
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Dropdown
              label="Organization"
              search
              selection
              loading={loading}
              options={organizations.map(({ id, name }) => ({
                key: id,
                text: name,
                value: id,
              }))}
              onChange={(_, { value }) => {
                const selectedOrganization = organizations.find(
                  (org) => org.id === value
                );
                const newSearchValues: SearchValuesProps = {
                  selectedOrganization,
                  selectedPractices: undefined,
                  practices: useAlternativePracticeSelectorBehavior
                    ? undefined
                    : selectedOrganization?.practices,
                  practicesIds: useAlternativePracticeSelectorBehavior
                    ? undefined
                    : selectedOrganization?.practices?.map(
                        (practice) => practice.id
                      ),
                };
                setSearchValues((prevSearchValues) => ({
                  ...prevSearchValues,
                  ...newSearchValues,
                }));
                onChange?.(newSearchValues);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              label="Practice"
              clearable
              multiple={multiplePracticesSelect}
              search
              selection
              loading={loading}
              options={
                practices?.map(({ id, name }) => ({
                  key: id,
                  text: name,
                  value: id,
                })) || []
              }
              onChange={(_, { value }) => {
                let selectedPractices: Practice[] | undefined;
                if (Array.isArray(value)) {
                  if (value.length > 0) {
                    selectedPractices = practices?.filter(
                      (p) => value?.includes(p.id)
                    );
                  } else {
                    selectedPractices = useAlternativePracticeSelectorBehavior
                      ? undefined
                      : practices;
                  }
                } else {
                  selectedPractices = practices?.filter((p) => value === p.id);
                }
                const newSearchValues: SearchValuesProps = {
                  selectedOrganization: searchValues.selectedOrganization,
                  selectedPractices,
                  practices: selectedPractices,
                  practicesIds: selectedPractices?.map(
                    (practice) => practice.id
                  ),
                };
                setSearchValues((prevSearchValues) => ({
                  ...prevSearchValues,
                  ...newSearchValues,
                }));
                onChange?.(newSearchValues);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default OrganizationAndPracticeSelector;
