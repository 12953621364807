import React, { useState } from 'react';
import { Button, Input, Grid, Header } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import { Color } from '@bluefox/models/Tags';
import Tag from '@ui/Tags/tag';
import { CreateTag } from '@graphql/tags';
import { toast } from 'react-semantic-toasts';

interface TagCreatorProp {
  selectedColor: Color | undefined;
  onSaveTag: () => void;
}
const TagCreator = (props: TagCreatorProp) => {
  const { selectedColor, onSaveTag } = props;

  //START STATES
  const [tagName, setTagName] = useState('');
  //END STATES

  // START QUERIES & MUTATIONS
  const [createTag] = useMutation(CreateTag);
  // END QUERIES & MUTATIONS

  // START LOGIC
  const onClickSaveTagButton = async () => {
    try {
      await createTag({
        variables: {
          name: tagName,
          color: selectedColor?.name ? selectedColor.name : '',
        },
      });
      onSaveTag();
      setTagName('');
      toast({
        title: 'Tag Created',
        type: 'success',
        time: 1000,
      });
    } catch (e) {
      toast({
        title: 'Error trying to create Tag',
        type: 'error',
        time: 5000,
      });
    }
  };
  // END LOGIC

  return (
    <>
      <Header as="h4" content="Create Tag" />
      <Grid padded="vertically" columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Input
              placeholder="Tag name"
              value={tagName}
              labelPosition="right"
              label={
                <Button
                  color="teal"
                  icon="save"
                  disabled={!tagName}
                  onClick={onClickSaveTagButton}
                />
              }
              onChange={(event, { value }) => setTagName(value)}
            />
          </Grid.Column>
        </Grid.Row>
        <div>
          <Grid.Column>
            {(tagName || selectedColor) && (
              <Tag
                name={tagName}
                clearable
                color={selectedColor?.name}
                onClickDeleteTag={() => setTagName('')}
              />
            )}
          </Grid.Column>
        </div>
      </Grid>
    </>
  );
};

export default TagCreator;
