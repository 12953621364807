export function getPercentageOrZero(dividend: number, divisor: number = 0) {
  if (divisor !== 0) {
    return Math.round((dividend * 100) / divisor);
  }
  return 0;
}

export function getPercentageOrZeroWithSign(
  dividend: number,
  divisor: number = 0
) {
  return `${getPercentageOrZero(dividend, divisor)} %`;
}

export function parseFloatOrZero(value: number | string) {
  const num = typeof value === 'string' ? parseFloat(value) : value;
  return !isNaN(num) ? num : 0;
}
