import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

export function createApolloClient(token?: string) {
  const API_HTTP_ADDRESS = process.env.REACT_APP_API_HTTP_URL as string;
  const API_WS_ADDRESS = process.env.REACT_APP_API_WS_URL as string;

  const authHeader = token ? { Authorization: `Bearer ${token}` } : {};

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...authHeader,
      },
    };
  });

  const httpLink = new HttpLink({
    uri: API_HTTP_ADDRESS,
  });

  const wsLink = new WebSocketLink({
    uri: API_WS_ADDRESS,
    options: {
      reconnect: true,
      lazy: true,
      ...(token
        ? {
            connectionParams: () => ({
              headers: authHeader,
            }),
          }
        : {}),
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    link: errorLink.concat(authLink.concat(splitLink)),
    cache: new InMemoryCache(),
  });
}

export function useApollo(token?: string) {
  const apolloClient = useMemo(() => createApolloClient(token), [token]);

  return apolloClient;
}
