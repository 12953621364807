import { Grid, Table } from 'semantic-ui-react';
import { useLazyQuery } from '@apollo/client';
import { GetBalanceMovements } from '@bluefox/graphql/balanceMovements';
import {
  BalanceMovementFilter,
  GetBalanceMovementsQueryResponse,
  HasInvoice,
  SelectAll,
} from '@bluefox/models/BalanceMovements';
import { useEffect, useState } from 'react';
import { BalanceMovementsTableRow } from '@screens/balanceMovements/balanceMovementsTableRow';
import { GetInvoicePDFById } from '@graphql/invoices';
import { Invoice } from '@bluefox/models/Invoice';
import InvoicePdfModal from '@bluefox/ui/Billing/InvoicePdfModal';
import { InvoiceAndBillingReportPdfProps } from '@bluefox/models/pdf/InvoiceAndBillingReportPdf';
import Pagination from '@bluefox/ui/Pagination';
import RowsPerPage from '@ui/Filters/RowsPerPage';
import styled from 'styled-components';
import {
  BillingReportPageProps,
  InvoicePageProps,
} from '@bluefox/pdf/InvoiceAndBillingReportPdf';
import logoImg from '@assets/canid-invoice-logo.png';

type BalanceMovementTableProps = {
  organizationId?: string;
  practiceIds: string[];
  advancedFilters?: BalanceMovementFilter;
};

const BalanceMovementsTable = ({
  organizationId,
  practiceIds,
  advancedFilters,
}: BalanceMovementTableProps) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [shouldShowPdfModal, setShouldShowPdfModal] = useState(false);
  const [page, setPage] = useState(0);

  const [
    getBalanceMovements,
    { loading, error, data: balanceMovementsQueryResponse },
  ] = useLazyQuery<GetBalanceMovementsQueryResponse>(GetBalanceMovements);

  const [getInvoicePDFById, { data: getInvoicePdfDataResponse }] =
    useLazyQuery<{ queryResponse: Pick<Invoice, 'pdfData'> }>(
      GetInvoicePDFById
    );

  const total =
    balanceMovementsQueryResponse?.totalBalanceMovements.aggregate.count || 0;
  const totalPages = rowsPerPage === 0 ? 0 : Math.ceil(total / rowsPerPage);

  const buildFilters = () => {
    return {
      where: {
        organizationId: organizationId ? { _eq: organizationId } : undefined,
        practiceId: practiceIds.length > 0 ? { _in: practiceIds } : undefined,
        type:
          advancedFilters?.type === SelectAll.ALL
            ? undefined
            : { _eq: advancedFilters?.type },
        reason:
          advancedFilters?.reason === SelectAll.ALL
            ? undefined
            : { _eq: advancedFilters?.reason },
        invoiceId:
          advancedFilters?.hasInvoice === SelectAll.ALL
            ? undefined
            : { _is_null: advancedFilters?.hasInvoice === HasInvoice.NO },
        date:
          !advancedFilters?.dateFrom && !advancedFilters?.dateTo
            ? undefined
            : {
                _gte: advancedFilters?.dateFrom || undefined,
                _lte: advancedFilters?.dateTo || undefined,
              },
      },
      limit: rowsPerPage === 0 ? 100 : rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
    };
  };
  const runQuery = () => {
    if (!organizationId && practiceIds.length === 0) {
      return;
    }

    const queryParams = buildFilters();
    getBalanceMovements({
      variables: queryParams,
    });
  };

  const onShowPDF = async (invoiceId: string) => {
    await getInvoicePDFById({
      variables: {
        invoiceId,
      },
    });
    setShouldShowPdfModal(true);
  };

  const addLogoImageToInvoiceData = (
    pdfData: InvoiceAndBillingReportPdfProps
  ): InvoiceAndBillingReportPdfProps => {
    const { invoice, billingReport } = pdfData;
    return {
      invoice: {
        ...invoice,
        header: { ...invoice?.header, logoImg },
      } as InvoicePageProps,
      billingReport: {
        ...billingReport,
        footer: { ...billingReport?.footer, logoImg },
      } as BillingReportPageProps,
    };
  };

  useEffect(runQuery, [
    organizationId,
    practiceIds,
    advancedFilters,
    rowsPerPage,
    page,
  ]);

  if (error) {
    return <h1>Error</h1>;
  }
  if (loading) {
    return <h1>Loading</h1>;
  }
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Organizations</Table.HeaderCell>
            <Table.HeaderCell>Practice</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Remaining Balance</Table.HeaderCell>
            <Table.HeaderCell>Invoice Status</Table.HeaderCell>
            <Table.HeaderCell>Amount to Paid</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {balanceMovementsQueryResponse?.balanceMovements.map(
            (balanceMovement) => (
              <BalanceMovementsTableRow
                onShowPDF={onShowPDF}
                key={balanceMovement.id}
                balanceMovement={balanceMovement}
              />
            )
          )}
        </Table.Body>
      </Table>

      <Grid centered>
        <Grid.Column floated="left" width={10}>
          <Pagination
            total={total}
            colSpan={5}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          <RowsPerPage
            rows={rowsPerPage}
            setRows={setRowsPerPage}
            localStorageItem="BalanceMovementRowConfig"
          />
        </Grid.Column>
      </Grid>

      <InvoicePdfModal
        invoiceProps={
          shouldShowPdfModal
            ? addLogoImageToInvoiceData(
                getInvoicePdfDataResponse?.queryResponse
                  .pdfData as InvoiceAndBillingReportPdfProps
              )
            : undefined
        }
        onClose={() => setShouldShowPdfModal(false)}
      />
    </>
  );
};
export const StyledPagination = styled.div({
  textAlign: 'center',
});

export default BalanceMovementsTable;
