import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  BillingClaim,
  BillingClaimStatus,
  ClaimRevisionStatus,
  claimRevisionStatusOptions,
} from '@bluefox/models/Billing';

import UpdateBillingClaimForm from '@ui/Billing/UpdateBillingClaimForm';

import moment from 'moment-timezone';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import {
  ClaimRevisionStatusMutation,
  ProcedureAmountsQuery,
  ReviewedClaimMutation,
} from '@graphql/billing';
import {
  Button,
  Checkbox,
  Dropdown,
  Label,
  Message,
  Placeholder,
  Segment,
  Table,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { useSession } from '@bluefox/contexts';
import { DateFormats } from '@bluefox/models/Dates';

interface BillingReportData {
  billingReport: BillingClaim[];
  totalAmountCalculated: {
    aggregate: {
      sum: {
        totalAmount: number;
      };
    };
  };
}

interface BillingReportListProps {
  invoiceId?: string;
  data?: BillingReportData;
  loading?: boolean;
  claimsToExclude?: (claimIds: string[]) => void;
  pdfActive?: boolean;
  totalAmount?: string;
  amountBillable?: string;
  amountReviewed?: string;
  scrollable?: boolean;
  onSave: () => void;
}
interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

const BillingReportList = ({
  invoiceId,
  data,
  loading,
  claimsToExclude,
  pdfActive,
  amountBillable,
  amountReviewed,
  scrollable,
  onSave,
}: BillingReportListProps) => {
  const [includedClaims, setIncludedClaims] = useState<string[]>([]);
  const [selectedClaimId, setSelectedClaimId] = useState<string | undefined>();

  const handleIncludedClaims = (claimId: string) => {
    let _includedClaims = [...includedClaims, claimId];
    if (includedClaims.includes(claimId)) {
      _includedClaims = _includedClaims.filter((id) => id !== claimId);
    }
    setIncludedClaims(_includedClaims);
  };

  const excludedFilter = data?.billingReport.filter(
    (claim) => !includedClaims.includes(claim.id)
  );

  const excludedClaims = excludedFilter?.map((claim) => claim.id);

  const { data: procedureAmountsData } = useQuery<ProcedureAmountsData>(
    ProcedureAmountsQuery,
    {
      variables: {
        type: 'Administration',
      },
    }
  );

  useEffect(() => {
    if (!includedClaims || !excludedClaims) return;
    if (claimsToExclude) claimsToExclude(excludedClaims);
  }, [includedClaims]);

  useEffect(() => {
    if (!selectedClaimId) {
      return;
    }
  }, [selectedClaimId]);

  return (
    <>
      <Table selectable style={{ marginBottom: 0 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Practice</Table.HeaderCell>
            <Table.HeaderCell width={3}>Patient</Table.HeaderCell>
            <Table.HeaderCell>Insurance</Table.HeaderCell>
            <Table.HeaderCell width={2}>Date of Service</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              CPT Codes
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Paid Amount
            </Table.HeaderCell>
            {!pdfActive && !invoiceId && (
              <Table.HeaderCell>Reference</Table.HeaderCell>
            )}
            {(!pdfActive || !!scrollable) && (
              <Table.HeaderCell width={1} textAlign="center">
                Status
              </Table.HeaderCell>
            )}

            {!pdfActive && !invoiceId && (
              <Table.HeaderCell>Edit</Table.HeaderCell>
            )}

            {!pdfActive && !invoiceId && (
              <Table.HeaderCell>Reviewed / Billable</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={10}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data?.billingReport.length ? (
            data?.billingReport.map((bc) => {
              return (
                <BilingReportRow
                  onSelectClaimToEdit={(claimId) => setSelectedClaimId(claimId)}
                  invoiceId={invoiceId}
                  onSave={onSave}
                  key={bc.id}
                  data={bc}
                  claimToInclude={handleIncludedClaims}
                  pdfActive={pdfActive}
                  scrollable={scrollable}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={10}>
                <Message>No claims found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        {scrollable && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={10} textAlign="right">
                <b>{`Total: $${
                  data?.totalAmountCalculated.aggregate.sum.totalAmount ?? 0
                }`}</b>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
      {!scrollable && (
        <StyledTableFooter height={pdfActive ? '2.5rem' : '5.5rem'}>
          <Table.Row>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <b>{`Total: $${
                data?.totalAmountCalculated.aggregate.sum.totalAmount ?? 0
              }`}</b>
              {!pdfActive && !invoiceId && (
                <>
                  <b>{`Total Billable selected: $${amountBillable ?? 0}`}</b>
                  <b>{`Total Reviewed selected: $${amountReviewed ?? 0}`}</b>
                </>
              )}
            </div>
          </Table.Row>
        </StyledTableFooter>
      )}

      {selectedClaimId && (
        <UpdateBillingClaimForm
          claimId={selectedClaimId}
          adminCptCodes={procedureAmountsData}
          onClose={() => setSelectedClaimId(undefined)}
          onSave={onSave}
        />
      )}
    </>
  );
};

interface StyledFooterProps {
  height: string;
}
const StyledTableFooter = styled.div<StyledFooterProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.8rem;
  margin-bottom: 1rem;
  background-color: #fafafa;
  height: ${(props) => props.height};
  border: 1px solid lightgrey;
  border-top-style: none;
`;

//-------------------------BilingReportRow------------------------//

interface BillingReportmRowProps {
  invoiceId?: string;
  data: BillingClaim;
  claimToInclude: (claimId: string) => void;
  pdfActive?: boolean;
  scrollable?: boolean;
  onSave: () => void;
  onSelectClaimToEdit: (claimId: string) => void;
}

const BilingReportRow = ({
  invoiceId,
  data,
  claimToInclude,
  pdfActive,
  scrollable,
  onSave,
  onSelectClaimToEdit,
}: BillingReportmRowProps) => {
  const urlBase = 'http://app.canid.io';
  const [wasReviewed, setWasReviewed] = useState(!!data.reviewedAt);
  const [revisionStatus, setRevisionStatus] = useState(
    data.revisionStatus as ClaimRevisionStatus
  );
  const [saveReviewedClaim] = useMutation(ReviewedClaimMutation);
  const [updateRevisionStatus] = useMutation(ClaimRevisionStatusMutation);

  const session = useSession();
  const updateClaim = (isChecked: boolean) => {
    const claimUpdatesMetadata = {
      ...data.updates[0]?.metadata,
      reviewedAt: isChecked,
    };
    const mutationsOptions = {
      variables: {
        claimId: data.id,
        reviewedAt: isChecked ? new Date().toISOString() : null,
        obj: {
          claimId: data.id,
          source: 'manual',
          status: data.status,
          metadata: claimUpdatesMetadata,
          practiceAccountId: session.account?.id,
        },
      },
    };

    saveReviewedClaim(mutationsOptions)
      .then(() => {
        toast({
          title: isChecked ? 'Claim Reviewed' : 'Claim Unreviewed',
          type: isChecked ? 'success' : 'warning',
          time: 1000,
        });
      })
      .then(() => onSave())
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const updateClaimRevisionStatus = (revisionStatus: ClaimRevisionStatus) => {
    const claimUpdatesMetadata = {
      ...data.updates[0]?.metadata,
      revisionStatus,
    };

    const mutationsOptions = {
      variables: {
        claimId: data.id,
        revisionStatus,
        obj: {
          claimId: data.id,
          source: 'manual',
          status: data.status,
          metadata: claimUpdatesMetadata,
          practiceAccountId: session.account?.id,
        },
      },
    };

    updateRevisionStatus(mutationsOptions)
      .then(() => {
        toast({
          title: 'Revision Status Changed',
          type: 'success',
          time: 1000,
        });
      })
      .then(() => onSave())
      .then(() => {
        if (revisionStatus !== ClaimRevisionStatus.BILLABLE) {
          setWasReviewed(false);
        }
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const totalPaidAmount = data.cptCodes.reduce(
    (accum, cpt) => accum + cpt.paidAmount,
    0
  );

  const filteredUpdates = data.updates.filter((u: any) => {
    return u.metadata.insurance.insuranceName !== '';
  });

  // used to avoid useEffect on first load
  const firstLoad = useRef(0);

  useEffect(() => {
    if (firstLoad.current < 2) {
      firstLoad.current++;
      return;
    }
    updateClaim(wasReviewed);
  }, [wasReviewed]);

  useEffect(() => {
    if (firstLoad.current < 2) {
      firstLoad.current++;
      return;
    }
    updateClaimRevisionStatus(revisionStatus);
  }, [revisionStatus]);

  return (
    <>
      {totalPaidAmount !== 0 && (
        <>
          <Table.Row key={data.id}>
            <Table.Cell style={{ fontWeight: '700' }}>
              {data.vaccinations[0].practice?.name}
            </Table.Cell>
            <Table.Cell>
              {`${data.practicePatient?.patientData.firstName} ${data.practicePatient?.patientData.lastName} (${data.practicePatient?.patientData.birthdate})`}
            </Table.Cell>
            <Table.Cell>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {filteredUpdates[0]?.metadata?.insuranceForPracticePortal
                  ? filteredUpdates[0]?.metadata?.insuranceForPracticePortal
                      .insuranceName
                  : filteredUpdates[0]?.metadata?.insurance?.insuranceName}
                <div>
                  <Label
                    basic
                    content={
                      !data.selfPay
                        ? `Member ID: ${filteredUpdates[0]?.metadata?.insurance?.memberId}`
                        : `Self Pay`
                    }
                    size="tiny"
                  />
                  {filteredUpdates[0]?.metadata?.insurance?.vfcEligible ? (
                    <Label
                      content="VFC Eligible"
                      size="tiny"
                      color="orange"
                      style={{ marginTop: '0.2rem' }}
                    />
                  ) : null}
                </div>
              </div>
            </Table.Cell>
            <Table.Cell>
              {data.vaccinations && !!data.vaccinations.length
                ? moment(data.vaccinations[0].givenAt).format(DateFormats.DATE)
                : '-'}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {!!data.cptCodes.length
                ? data.cptCodes.map((cpt) => {
                    return (
                      <Label
                        key={cpt.id}
                        style={{ marginBottom: '0.2rem' }}
                        content={cpt.cptCode}
                      />
                    );
                  })
                : data.cptCodes.map((cpt) => {
                    return (
                      <Label
                        style={{ marginBottom: '0.2rem' }}
                        key={cpt.id}
                        content={cpt.cptCode}
                      />
                    );
                  })}
            </Table.Cell>
            <Table.Cell textAlign="center" verticalAlign="middle">
              {!!data.cptCodes.length
                ? data.cptCodes.map((cpt) => {
                    return (
                      <p key={cpt.id}>
                        {cpt.paidAmount ? `$${cpt.paidAmount.toFixed(2)}` : '-'}
                      </p>
                    );
                  })
                : '-'}
            </Table.Cell>

            {!pdfActive && !invoiceId && (
              <Table.Cell>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {data.checkNumber && <p>Check Number: {data.checkNumber}</p>}
                  {data.note?.url && (
                    <div>
                      <a href={data.note.url} target="_blank">
                        EOB Link
                      </a>
                    </div>
                  )}
                </div>
              </Table.Cell>
            )}

            {(!pdfActive || !!scrollable) && (
              <Table.Cell>
                <Label
                  basic
                  content={humanizeText(data.status, {
                    capitalize: 'first',
                    delimiter: '_',
                  })}
                  size="tiny"
                  color={
                    `${data.status}` === BillingClaimStatus.PAID
                      ? 'olive'
                      : 'grey'
                  }
                  style={{ marginTop: '0.2rem' }}
                />
              </Table.Cell>
            )}

            {!pdfActive && !invoiceId && (
              <Table.Cell>
                <Button
                  primary
                  icon="edit"
                  size="mini"
                  onClick={() => onSelectClaimToEdit(data.id)}
                  color="teal"
                />
              </Table.Cell>
            )}

            {!pdfActive && !invoiceId && (
              <Table.Cell>
                <Dropdown
                  style={{ minWidth: '10rem' }}
                  fluid
                  selection
                  options={claimRevisionStatusOptions}
                  value={revisionStatus || ClaimRevisionStatus.NEW}
                  onChange={(event, props) => {
                    setRevisionStatus(props.value as ClaimRevisionStatus);
                  }}
                />
                <Checkbox
                  label="Reviewed"
                  disabled={revisionStatus !== ClaimRevisionStatus.BILLABLE}
                  checked={wasReviewed}
                  onChange={(_, { checked }) => setWasReviewed(!!checked)}
                />
              </Table.Cell>
            )}
          </Table.Row>

          {(pdfActive || invoiceId) && (
            <Table.Row>
              <Table.Cell colSpan={10}>
                {data.checkNumber && `Check Number: ${data.checkNumber}`}
                {!!data.checkNumber && !!data.note?.url && ' - '}
                {data.note?.url && (
                  <a
                    href={`${urlBase}/${data?.vaccinations[0]?.practice?.handler}/eob/${data.id}`}
                    target="_blank"
                  >
                    EOB link:{' '}
                    {`${urlBase}/${data?.vaccinations[0]?.practice?.handler}/eob/${data.id}`}
                  </a>
                )}
              </Table.Cell>
            </Table.Row>
          )}
        </>
      )}
    </>
  );
};

export default BillingReportList;
