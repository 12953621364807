import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import ChatList from './ChatList';
import { Message } from './types';
import SendChatMessage from './SendChatMessage';
import { Card } from 'semantic-ui-react';

export type ChatMessage = { message: Message; position?: 'left' | 'right' };

export type ChatHandle = {
  scrollDown: () => void;
};

type Props = {
  messages: ChatMessage[];
  onSendNewMessage?: (message: string) => void;
};

const Chat = forwardRef<ChatHandle, Props>(
  ({ messages, onSendNewMessage: onNewMessage }, ref) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollDown = useCallback(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, []);

    useEffect(() => {
      scrollDown();
    }, [messages, scrollRef]);

    useImperativeHandle(
      ref,
      () => {
        return {
          scrollDown,
        };
      },
      [scrollDown]
    );

    return (
      <Card fluid>
        <Card.Content>
          <div
            style={{ maxHeight: '40vh', overflow: 'auto', padding: '0.5rem' }}
            ref={scrollRef}
          >
            <ChatList messages={messages} />
          </div>
        </Card.Content>
        <Card.Content extra>
          <SendChatMessage onSubmit={onNewMessage} />
        </Card.Content>
      </Card>
    );
  }
);

export default Chat;
