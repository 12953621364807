import { COLORS_BY_NAME, pixelToRem } from '@bluefox/constants';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const InvoicingAmountParameter = styled.p`
  font-size: ${pixelToRem(40)}rem !important;
  font-weight: 800 !important;
  line-height: ${56 / 40} !important;
  letter-spacing: 0;
  color: ${COLORS_BY_NAME['Raisin Black']} !important;
`;

export const InvoicingTablesDate = styled.span`
  font-size: ${pixelToRem(12)}rem !important;
  font-weight: 500;
  line-height: ${16 / 12} !important;
  letter-spacing: 0.25px;
  color: ${COLORS_BY_NAME['Raisin Black']};
`;

export const InvoicingTablesAmount = styled.span`
  font-size: ${pixelToRem(14)}rem !important;
  font-weight: 700;
  line-height: ${16 / 12} !important;
  letter-spacing: 0.25px;
  color: ${COLORS_BY_NAME['Raisin Black']};
`;

export const InvoicingTableCell = styled(Table.Cell)`
  padding: ${pixelToRem(8)}rem 0 !important;
`;
