import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { MappedVaccine } from '@bluefox/models/Mappings';
import { VaccineMappingsByPracticeQuery } from '@graphql/mappings';
import {
  Table,
  Menu,
  Header,
  Button,
  Modal,
  Message,
  Popup,
  Segment,
  Placeholder,
  Label,
} from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import VaccineMappingForm from './VaccineMappingForm';

interface VaccineMappingsData {
  vaccines: MappedVaccine[];
}

const VaccinesMapping = () => {
  const practice = usePractice();

  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<MappedVaccine>();

  const { data, loading, refetch } = useQuery<VaccineMappingsData>(
    VaccineMappingsByPracticeQuery,
    {
      variables: {
        practiceId: practice.id,
      },
    }
  );

  const closeModal = () => {
    setIsSelected(false);
    setSelectedMapping(undefined);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  return (
    <>
      <Menu borderless>
        <Menu.Item>
          <Header>Vaccines</Header>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              primary
              size="small"
              content="Add Vaccine"
              icon="plus"
              onClick={() => {
                setIsSelected(false);
                setOpen(true);
              }}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Vaccine Name (EMR)</Table.HeaderCell>
            <Table.HeaderCell>Vaccine Name (Canid)</Table.HeaderCell>
            <Table.HeaderCell>Types</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data?.vaccines.length ? (
            data.vaccines.map((v) => {
              return (
                <VaccinesMappingRow
                  key={v.id}
                  data={v}
                  onChangeSelected={() => {
                    handleChangeSelected(true);
                    setSelectedMapping({
                      id: v.id,
                      vaccine: v.vaccine,
                      nameEmr: v.nameEmr,
                    });
                  }}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Message>No mappings found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Modal
        size="small"
        onClose={closeModal}
        onOpen={() => setOpen(open)}
        open={open}
        closeIcon
      >
        <Modal.Header>
          {isSelected ? 'Edit Vaccine Mapping' : 'Add Vaccine Mapping'}
        </Modal.Header>
        <Modal.Content>
          <VaccineMappingForm
            close={closeModal}
            practiceId={practice.id}
            data={selectedMapping ? selectedMapping : null}
            refetchMappings={refetch}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

//-------------------------------VaccinesMappingRow-------------------------------//

interface VaccinesMappingRowProps {
  data: MappedVaccine;
  onChangeSelected: (selected: boolean) => void;
}

const VaccinesMappingRow = ({
  data,
  onChangeSelected,
}: VaccinesMappingRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>{data.nameEmr || '-'}</Table.Cell>
      <Table.Cell>{data.vaccine ? data.vaccine.name : '-'}</Table.Cell>
      <Table.Cell>
        {data.vaccine?.types?.map((t) => (
          <Label size="small" color="orange" content={t} />
        ))}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default VaccinesMapping;
