import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertMappingsMutation,
  UpdateMappingMutation,
} from '@graphql/mappings';
import { MappedItem } from '@bluefox/models/Mappings';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import { Button, Form, Dropdown } from 'semantic-ui-react';

interface PracticeData {
  id: string;
  name: string;
}

interface EcwAdministrationRouteMappingFormProps {
  close: () => void;
  practices?: PracticeData[];
  data: MappedItem | null;
  refetchMappings: () => void;
}

const EcwAdministrationRouteMappingForm = ({
  close,
  practices,
  data,
  refetchMappings,
}: EcwAdministrationRouteMappingFormProps) => {
  const [practiceId, setPracticeId] = useState(data ? data.practice.id : '');
  const [route, setRoute] = useState<string>(data ? data.key : '');
  const [keyEmr, setkeyEmr] = useState<string>(
    data?.metadata[0].keyEmr ? data.metadata[0].keyEmr : ''
  );
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);

  const [saveVaccineMapping] = useMutation(
    data?.id ? UpdateMappingMutation : InsertMappingsMutation
  );

  const handleSubmit = () => {
    saveVaccineMapping({
      variables: {
        id: data?.id,
        practiceId,
        key: route,
        metadata: [
          {
            keyEmr,
          },
        ],
        type: 'administration_route',
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!practices || practices.length < 1) return;

    setPracticeOptions(
      practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  const administrationRouteOptions = [
    {
      key: 'IM',
      text: 'IM',
      value: 'IM',
    },
    {
      key: 'SC',
      text: 'SC',
      value: 'SC',
    },
    {
      key: 'PO',
      text: 'PO',
      value: 'PO',
    },
  ];

  return (
    <Form
      onSubmit={handleSubmit}
      data-automation-id="administration-route-mapping-form"
    >
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Dropdown
            label="Practice"
            style={{ minWidth: '15rem' }}
            placeholder="Select practice"
            fluid
            selection
            value={practiceId}
            onChange={(e, data) => {
              setPracticeId(data.value?.toString() || '');
            }}
            options={practiceOptions}
            required
          />
        </Form.Field>
        <Form.Field required>
          <label>Administration Route:</label>
          <Dropdown
            fluid
            selection
            placeholder="Select administration route..."
            options={administrationRouteOptions}
            value={route}
            onChange={(e, data) => {
              setRoute(data.value as string);
            }}
            data-automation-id="administration-route-mapping-form-route"
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={keyEmr}
            onChange={(_, { value }) => setkeyEmr(value)}
            fluid
            label="ID (EMR)"
            placeholder="ID (EMR)"
            required
            data-automation-id="administration-route-mapping-form-key-emr"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group></Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={close}
          type="button"
          data-automation-id="administration-route-mapping-form-cancel-button"
        >
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          data-automation-id="administration-route-mapping-form-save-button"
          disabled={!route}
        />
      </Form.Field>
    </Form>
  );
};

export default EcwAdministrationRouteMappingForm;
