import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { CptCodeTypeQuery } from '@graphql/billing';
import { Dropdown } from 'semantic-ui-react';

interface CptCodeOption {
  key: number;
  value: string;
  type: string;
  text: string;
}

interface CptCode {
  idx: number;
  cpt: string;
  type: string;
}

interface CptCodesData {
  cptCodes: CptCode[];
}

interface CptCodePickerProps {
  handleCptCode: (cpt: CptCode) => void;
  cptIdx: number;
}

const CptCodePicker = ({ handleCptCode, cptIdx }: CptCodePickerProps) => {
  const [searchCptCode, setSearchCptCode] = useState({});
  const [input, setInput] = useState('');
  const [options, setOptions] = useState<CptCodeOption[]>([]);

  const { data, loading } = useQuery<CptCodesData>(CptCodeTypeQuery, {
    variables: {
      cptCode: searchCptCode,
    },
    skip: input ? input.length < 3 : true,
  });

  useEffect(() => {
    if (!input) return;
    setSearchCptCode({ _ilike: `%${input}%` });
  }, [input]);

  useEffect(() => {
    if (!data) return;
    setOptions(
      data.cptCodes.map((c, idx) => {
        return {
          key: idx,
          value: c.cpt,
          type: c.type,
          text: `${c.cpt} (${c.type})`,
        };
      })
    );
  }, [data]);

  return (
    <Dropdown
      placeholder="Search CPT code..."
      fluid
      search
      selection
      onSearchChange={(_, { searchQuery }) => setInput(searchQuery as string)}
      onChange={(_, data) => {
        let cptCode = data.options ? data.options.find((c) => c.value) : {};

        handleCptCode({
          idx: cptIdx,
          cpt: cptCode?.value as string,
          type: cptCode?.type as string,
        });
      }}
      options={options}
      loading={loading}
      disabled={loading}
    />
  );
};

export default CptCodePicker;
