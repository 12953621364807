import { useQuery } from '@apollo/client';
import {
  BillingClaim,
  BillingClaimStatus,
  ClaimRevisionStatus,
} from '@bluefox/models/Billing';
import { InvoiceStatus } from '@bluefox/models/Invoice';
import { NewBillingReportQuery, ProcedureAmountsQuery } from '@graphql/billing';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import BillingReportList, { ProcedureAmountsData } from './BillingReportList';
import { Card, Container, Message } from 'semantic-ui-react';
import styled, { CSSProperties } from 'styled-components';
import BillingReportListFilter, {
  SearchValuesProps,
} from './BillingReportListFilter';
import BillingReportListPagination, {
  PaginationValuesProps,
} from './BillingReportListPagination';
import { toast } from 'react-semantic-toasts';

const pageLimit = 10;

export const StyledPagination = styled.div({
  marginTop: '1rem',
  textAlign: 'center',
});

interface BillingReportData {
  billingReport: BillingClaim[];
  billingReportCount: {
    aggregate: {
      totalClaimsNumber: number;
    };
  };
  totalBilliableSelectedAmount: {
    aggregate: {
      sum: {
        totalBilliableAmount: number;
      };
    };
  };
  totalReviewedSelectedAmount: {
    aggregate: {
      sum: {
        totalReviewedAmount: number;
      };
      totalReviewedCount: number;
    };
  };
  totalAmountCalculated: {
    aggregate: {
      sum: {
        totalAmount: number;
      };
    };
  };
}

export type BillingReportHandle = {
  callRefetch: () => void;
};

type Props = {
  practiceIds?: string[];
  style?: CSSProperties;
  onSomeReviewedClaimChange?: (someReviewdClaims: boolean) => void;
  disablePagination?: boolean;
};

const BillingReport = forwardRef<BillingReportHandle, Props>(
  (
    { practiceIds, style, onSomeReviewedClaimChange, disablePagination },
    ref
  ) => {
    const [inventoryCptCodes, setInventoryCptCodes] = useState<
      string[] | undefined
    >();
    const [searchValues, SetsearchValues] = useState<SearchValuesProps>({});
    const [paginationValues, setPaginationValues] =
      useState<PaginationValuesProps>({ activePage: 1 });

    const {
      data: procedureAmountsData,
      loading: cptCodesLoading,
      error: cptCodesError,
    } = useQuery<ProcedureAmountsData>(ProcedureAmountsQuery, {
      variables: {
        type: 'Inventory',
      },
      onCompleted: (data) => {
        setInventoryCptCodes(data?.cptCodes.map((c) => c.cpt));
      },
    });

    const getCriteria = useMemo(() => {
      let criteriaObj = {};
      criteriaObj = {
        vaccinations: {
          practiceId: { _in: practiceIds },
        },
        status: {
          _in: [
            BillingClaimStatus.PAID,
            BillingClaimStatus.PARTIALLY_PAID,
            BillingClaimStatus.PARTIALLY_BILLED,
          ],
        },
        cptCodes: {
          cptCode: { _in: inventoryCptCodes },
          paidAmount: { _gt: 0 },
          _or: [
            {
              invoice: { _is_null: true },
            },
            {
              invoices: {
                status: { _eq: InvoiceStatus.DRAFT },
              },
            },
          ],
        },
      };
      if (searchValues.billiable !== undefined) {
        criteriaObj = {
          ...criteriaObj,
          revisionStatus: { _eq: searchValues.billiable },
        };
      }
      if (searchValues.reviewed !== undefined) {
        criteriaObj = {
          ...criteriaObj,
          reviewedAt: { _is_null: !searchValues.reviewed },
        };
      }
      const criteriaCptCodesBilliableSelected = {
        cptCode: { _in: inventoryCptCodes },
        paidAmount: {
          _gt: 0,
        },
        _or: [
          {
            invoice: { _is_null: true },
          },
          {
            invoices: {
              status: { _eq: InvoiceStatus.DRAFT },
            },
          },
        ],
        claim: {
          practiceId: { _in: practiceIds },
          status: {
            _in: [
              BillingClaimStatus.PAID,
              BillingClaimStatus.PARTIALLY_PAID,
              BillingClaimStatus.PARTIALLY_BILLED,
            ],
          },
          revisionStatus: { _eq: ClaimRevisionStatus.BILLABLE },
        },
      };
      const criteriaCptCodesReviewedSelected = {
        ...criteriaCptCodesBilliableSelected,
        claim: {
          ...criteriaCptCodesBilliableSelected.claim,
          reviewedAt: { _is_null: false },
        },
      };
      return {
        criteria: criteriaObj,
        criteriaCptCodes: {
          claim: criteriaObj,
          cptCode: { _in: inventoryCptCodes },
          paidAmount: { _gt: 0 },
          _or: [
            {
              invoice: { _is_null: true },
            },
            {
              invoices: {
                status: { _eq: InvoiceStatus.DRAFT },
              },
            },
          ],
        },
        criteriaCptCodesBilliableSelected,
        criteriaCptCodesReviewedSelected,
      };
    }, [
      inventoryCptCodes,
      practiceIds,
      searchValues.billiable,
      searchValues.reviewed,
    ]);

    const {
      data: billingReportData,
      loading: billingReportLoading,
      error: billingReportDataError,
      refetch: billingReportDataRefetch,
    } = useQuery<BillingReportData>(NewBillingReportQuery, {
      variables: {
        ...getCriteria,
        ...(!disablePagination
          ? {
              limit: pageLimit,
              offset: (paginationValues.activePage - 1) * pageLimit,
            }
          : {}),
      },
      skip:
        !inventoryCptCodes ||
        !getCriteria ||
        !practiceIds ||
        practiceIds?.length === 0,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        onSomeReviewedClaimChange?.(
          data?.totalReviewedSelectedAmount?.aggregate?.totalReviewedCount > 0
        );
      },
    });

    const callRefetch = useCallback(async () => {
      try {
        await billingReportDataRefetch();
      } catch (error) {
        toast({
          title: `Failed to update Claim list: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    }, [billingReportDataRefetch]);

    useImperativeHandle(
      ref,
      () => {
        return {
          callRefetch,
        };
      },
      [callRefetch]
    );

    const loading = useMemo(
      () => cptCodesLoading || billingReportLoading,
      [billingReportLoading, cptCodesLoading]
    );
    const error = useMemo(
      () => cptCodesError || billingReportDataError,
      [billingReportDataError, cptCodesError]
    );

    return (
      <Container fluid style={style}>
        <Card fluid>
          <Card.Content>
            <Card.Header as={'h3'}>Billing Report</Card.Header>
            <Card.Description>
              {error && <Message error>{error?.message}</Message>}
              <BillingReportListFilter
                searchValues={searchValues}
                setSearchValues={SetsearchValues}
              />
              <BillingReportList
                billingReport={billingReportData?.billingReport}
                totalBilliableSelectedAmount={
                  billingReportData?.totalBilliableSelectedAmount?.aggregate
                    ?.sum?.totalBilliableAmount
                }
                totalReviewedSelectedAmount={
                  billingReportData?.totalReviewedSelectedAmount?.aggregate?.sum
                    ?.totalReviewedAmount
                }
                totalAmount={
                  billingReportData?.totalAmountCalculated?.aggregate?.sum
                    ?.totalAmount
                }
                loading={loading}
                procedureAmountsData={procedureAmountsData}
                onSave={() => {
                  callRefetch();
                }}
                style={{ marginTop: '2rem' }}
              />
              {!disablePagination && (
                <StyledPagination>
                  <BillingReportListPagination
                    totalPages={
                      billingReportData?.billingReport &&
                      billingReportData?.billingReport?.length > 0
                        ? Math.ceil(
                            billingReportData?.billingReportCount?.aggregate
                              ?.totalClaimsNumber / pageLimit
                          )
                        : 1
                    }
                    paginationValues={paginationValues}
                    setPaginationValues={setPaginationValues}
                  />
                </StyledPagination>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    );
  }
);

export default BillingReport;
