import React from 'react';
import { Card, Icon, IconProps } from 'semantic-ui-react';

type HomeSectionProps = {
  title: string;
  icon: IconProps['name'];
  children: React.ReactElement;
};

const HomeSection = ({ title, icon, children }: HomeSectionProps) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header as={'h3'}>
          <Icon name={icon} style={{ marginRight: '0.6rem' }} />
          {title}
        </Card.Header>
        <Card.Description>{children}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default HomeSection;
