class IdleTimer {
  idle: boolean;
  timeout: number;
  onIdle: Function;
  eventHandler: (this: Window, ev: any) => any;
  lastActivity: Date;
  interval?: NodeJS.Timer;

  constructor({
    timeout,
    onIdle,
    onReset,
  }: {
    timeout: number;
    onIdle: Function;
    onReset?: Function;
  }) {
    this.idle = false;
    this.timeout = timeout * 1000;
    this.onIdle = onIdle;
    this.lastActivity = new Date();

    this.eventHandler = (() => {
      this.lastActivity = new Date();
      if (this.idle) {
        if (onReset) onReset();
        this.idle = false;
        this.startInterval();
      }
    }).bind(this);

    this.tracker();
    this.startInterval();
  }

  startInterval() {
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(
      (() => {
        const now = new Date().getTime();
        this.idle = this.lastActivity.getTime() + this.timeout < now;

        if (this.idle) {
          if (this.interval) clearInterval(this.interval);
          this.onIdle();
        }
      }).bind(this),
      1000
    );
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('mouseclick', this.eventHandler);
    // window.addEventListener("scroll", this.eventHandler);
    // window.addEventListener("resize", this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    if (this.interval) clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('mouseclick', this.eventHandler);
    // window.removeEventListener("scroll", this.eventHandler);
    // window.removeEventListener("resize", this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}
export default IdleTimer;
