import { gql } from '@apollo/client';

export const MakeAdjustmentMutation = gql`
  mutation InventoryAdjustmentMutation(
    $private: inventory_adjustments_insert_input!
    $vfc: inventory_adjustments_insert_input!
  ) {
    privateAdjustment: insert_inventory_adjustments_one(object: $private) {
      id
      __typename
    }
    vfcAdjustment: insert_inventory_adjustments_one(object: $vfc) {
      id
      __typename
    }
  }
`;
export const GetClaimsVFCInconsistencies = gql`
  query GetClaimsVFCInconsistencies(
    $practiceId: uuid!
    $status: billing_claim_vfc_inconsistency_status_enum = created
    $type: billing_claim_vfc_inconsistency_types_enum!
  ) {
    vfcInconsistencies: billing_claim_vfc_inconsistencies(
      where: {
        practiceId: { _eq: $practiceId }
        status: { _eq: $status }
        type: { _eq: $type }
      }
    ) {
      createdAt
      id
      practiceId
      claimId
      inventoryId
      claimUpdatesId
      vaccinationId
      inventory {
        id
        vfc
        vaccine {
          name
          aka
          types
        }
      }
      claimUpdate {
        id
        metadata(path: "insurance")
      }
      updatedAt
      type
      status
      claim {
        id
        insuranceVfcEligible
        practicePatient {
          patientData {
            birthdate
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const getAdjustmentDetailsByInventoryQuery = gql`
  query GetAdjustmentDetailsByInventoryQuery($inventoryId: uuid!) {
    details: inventory_adjustment_details(
      where: { inventoryId: { _eq: $inventoryId } }
    ) {
      createdAt
      currentDoses
      id
      newDoses
      reason
      status
      updatedAt
      type
    }
  }
`;
