import { ApolloError, gql, useMutation, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { PracticeSettings } from '@bluefox/models/Practice';
import { useApplicationState, useSession } from './ApplicationState';
import { usePractice } from './Practice';
import moment from 'moment-timezone';
import IdleTimer from '@bluefox/lib/IdleTimer';

interface PracticeSettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

interface AuthState {
  isAuthenticated: boolean;
  actions: {
    signIn: [
      (email: string, password: string) => void,
      { data: any; loading: boolean; error: ApolloError | undefined },
    ];
    signOut: (cb?: () => void) => void;
  };
  attachListener: (evn: any, callback: Listener) => void;
}

export enum AuthEvents {
  'on-signed-in',
  'on-signed-out',
  'on-error',
}

type Listener = () => void;

const authContext = createContext<AuthState | null>(null);

const SignInMutation = gql`
  mutation SignInMutation(
    $handler: String!
    $email: String!
    $password: String!
  ) {
    SignIn(handler: $handler, email: $email, password: $password) {
      token
    }
  }
`;

const listenerInitialState = Object.keys(AuthEvents).reduce(
  (acc, curr) => ({ ...acc, [curr]: [] }),
  {} as Record<AuthEvents, Listener[]>
);

export function useAuthState(handler: string): AuthState | null {
  const { token, setToken } = useApplicationState();

  const [signInMutation, { data, loading, error }] = useMutation(
    SignInMutation,
    {
      onError(err) {
        return err;
      },
    }
  );

  const [listeners, setListeners] =
    useState<Partial<Record<AuthEvents, Listener[]>>>(listenerInitialState);

  const attachListener = useCallback(
    (evnt: AuthEvents, callback: Listener) => {
      setListeners({
        ...listeners,
        [evnt]: [...(listeners[evnt] || []), callback],
      });
    },
    [listeners]
  );

  const triggerListeners = useCallback(
    (evnt: AuthEvents) => {
      listeners[evnt]?.forEach((cb) => cb());
    },
    [listeners]
  );

  const signInAction = useCallback(
    (email: string, password: string) => {
      return signInMutation({
        variables: {
          handler,
          email,
          password,
        },
      });
    },
    [handler, signInMutation]
  );

  const signOutAction = useCallback((cb?: () => void) => {
    setToken(null);
    triggerListeners(AuthEvents['on-signed-out']);
    setListeners(listenerInitialState);
    if (cb) cb();
  }, []);

  useEffect(() => {
    if (data) {
      const _token = data.SignIn.token;
      setToken(_token);
      triggerListeners(AuthEvents['on-signed-in']);
    }
  }, [data]);

  return {
    isAuthenticated: !!token,
    actions: {
      signIn: [
        signInAction,
        {
          loading,
          data,
          error,
        },
      ],
      signOut: signOutAction,
    },
    attachListener,
  };
}

export function useAuth() {
  return useContext(authContext)!;
}

export const AuthProvider = ({
  children,
  handler,
  onLoad,
  onSignedIn,
  onSignedOut,
  onUserIdleChange,
  idleUserChangeTimeout = 180,
}: {
  children: ReactNode;
  handler: string;
  onLoad?: (isAuthenticated: boolean) => void;
  onSignedIn?: Listener;
  onSignedOut?: Listener;
  onUserIdleChange?: (isUserchangeIdle: boolean) => void;
  idleUserChangeTimeout?: number;
}) => {
  const history = useHistory();
  const auth = useAuthState(handler);
  const session = useSession();

  const practice = usePractice();

  const [isUserChangeIdle, setIsUserChangeIdle] = useState(false);

  const [getPracticeSettings, { data: practiceSettingsData }] =
    useLazyQuery<PracticeSettingsData>(PracticeSettingsQuery);

  useEffect(() => {
    if (onLoad) onLoad(auth?.isAuthenticated || false);
  }, []);

  useEffect(() => {
    if (onSignedIn) {
      auth?.attachListener(AuthEvents['on-signed-in'], onSignedIn);
    }
    if (onSignedOut) {
      auth?.attachListener(AuthEvents['on-signed-out'], onSignedOut);
    }
  }, [onSignedIn, onSignedOut]);

  useEffect(() => {
    if (!practice) {
      return;
    }
    const currentTimeZone = practice.timezone || moment.tz.guess();
    moment.tz.setDefault(currentTimeZone);
  }, [practice]);

  const handleOnUserChangeIdle = useCallback(() => {
    setIsUserChangeIdle(true);
    if (onUserIdleChange) onUserIdleChange(true);
  }, [setIsUserChangeIdle]);

  const handleOnUserChangeIdleReset = useCallback(() => {
    setIsUserChangeIdle(false);
    if (onUserIdleChange) onUserIdleChange(false);
  }, [setIsUserChangeIdle]);

  useEffect(() => {
    if (
      practiceSettingsData?.practice.settings.features?.idleUserLogout?.enabled
    ) {
      const timer = new IdleTimer({
        timeout: idleUserChangeTimeout, // idle after 20 minutes
        onIdle: handleOnUserChangeIdle,
        onReset: handleOnUserChangeIdleReset,
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [practiceSettingsData]);

  useEffect(() => {
    if (
      window.location.pathname === `/${handler}/sign-in` ||
      window.location.pathname === '/sign-in' ||
      session?.account?.role === 'staff'
    )
      return;

    if (session?.account?.email) {
      getPracticeSettings({
        variables: {
          id: practice.id,
        },
      });
    }

    if (
      isUserChangeIdle &&
      window.location.pathname !== `/${handler}/select-user`
    ) {
      auth?.actions.signOut(() =>
        window.location.replace(`/${handler}/select-user`)
      );
    }
  }, [isUserChangeIdle, session]);

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
