import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ClaimResubmitMutation } from '@graphql/billing';
import { toast } from 'react-semantic-toasts';
import { Button } from 'semantic-ui-react';

const ResubmitButton = ({
  claimId,
  onResubmitted,
  refetch,
  disabled,
}: {
  claimId: string;
  onResubmitted?: () => void;
  refetch: () => void;
  disabled?: boolean;
}) => {
  const [resubmitted, setResubmitted] = useState(false);

  const [resubmit, { loading }] = useMutation(ClaimResubmitMutation);

  const handleOnClick = useCallback(() => {
    setResubmitted(true);
    resubmit({
      variables: {
        claimId,
      },
    })
      .then((r) => {
        if (onResubmitted) onResubmitted();
        toast({
          title: 'Claim resubmitted successfully',
          type: 'success',
          time: 1000,
        });
        refetch();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
        console.error(e);
        setResubmitted(false);
      });
  }, [resubmit, claimId, onResubmitted]);

  return (
    <Button
      onClick={handleOnClick}
      loading={loading}
      disabled={loading || resubmitted || disabled}
      size="tiny"
      icon={resubmitted ? 'check' : 'sync'}
      content={resubmitted ? 'Resubmitted' : 'Resubmit'}
      color={resubmitted ? 'grey' : 'teal'}
    />
  );
};

export default ResubmitButton;
