import { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Form,
  Header,
  Card,
  Segment,
  Message,
  Popup,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import { BillingClaimStatus } from '@bluefox/models/Billing';
import CptCodePicker from '@ui/ClaimModal/CptCodePicker';
import { useBillingClaimStatusOptions } from 'store/BillingClaimStatusOptions';

interface CptCode {
  cptCode: string;
  claimAmount: number;
  paidAmount: number;
}

interface Claim {
  cptCodes: CptCode[];
  status: BillingClaimStatus;
  totalClaimAmount: number;
}

interface Cpt {
  idx: number;
  cpt: string;
  type: string;
}

interface ClaimFormProps {
  handleClaim: (claim: Claim) => void;
}

const ClaimForm = ({ handleClaim }: ClaimFormProps) => {
  const [cptCodes, setCptCodes] = useState<CptCode[]>([]);
  const [status, setStatus] = useState<BillingClaimStatus>();
  const [totalAmount, setTotalAmount] = useState(0);

  const handleAddCptCode = () => {
    setCptCodes((cptCodes) => [
      ...cptCodes,
      {
        cptCode: '',
        claimAmount: 0,
        paidAmount: 0,
      },
    ]);
  };

  const handleRemoveCptCode = useCallback(
    (idx: number) => {
      const _entries = [...cptCodes];
      _entries.splice(idx, 1);
      setCptCodes(_entries);
    },
    [cptCodes]
  );

  const handleCptCode = (cpt: Cpt) => {
    const _cptCode = {
      ...cptCodes[cpt.idx],
      cptCode: cpt.cpt,
    };

    const _cptCodes = [...cptCodes];
    _cptCodes[cpt.idx] = _cptCode;
    setCptCodes(_cptCodes);
  };

  const handleClaimAmount = (idx: number, value: number) => {
    const _cptCode = {
      ...cptCodes[idx],
      claimAmount: value,
    };

    const _cptCodes = [...cptCodes];
    _cptCodes[idx] = _cptCode;
    setCptCodes(_cptCodes);
  };

  const handlePaidAmount = (idx: number, value: number) => {
    const _cptCode = {
      ...cptCodes[idx],
      paidAmount: value,
    };

    const _cptCodes = [...cptCodes];
    _cptCodes[idx] = _cptCode;
    setCptCodes(_cptCodes);
  };

  useEffect(() => {
    if (!cptCodes) return;
    let totalClaimAmount = cptCodes
      .reduce((accum, cpt) => accum + cpt.claimAmount, 0)
      .toFixed(2);

    setTotalAmount(parseFloat(totalClaimAmount));
  }, [cptCodes]);

  useEffect(() => {
    if (!cptCodes || !status) return;
    handleClaim({
      cptCodes,
      status,
      totalClaimAmount: totalAmount,
    });
  }, [cptCodes, status]);

  const { options: billingClaimStatusOptions } = useBillingClaimStatusOptions();
  return (
    <Form>
      <Segment
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header style={{ margin: 0 }}>CPT Codes</Header>
        <Button
          primary
          type="button"
          size="mini"
          icon="plus"
          content="CPT Code"
          onClick={handleAddCptCode}
        />
      </Segment>
      <div
        style={{
          overflowY: 'auto',
          maxHeight: '30rem',
          padding: '0.5rem 0.5rem',
        }}
      >
        {!!cptCodes.length ? (
          cptCodes.map((c, idx) => {
            return (
              <CptCodeCard
                key={idx}
                cptIdx={idx}
                cptCode={c}
                onChangeClaimAmount={handleClaimAmount}
                onChangePaidAmount={handlePaidAmount}
                onChangeCptCode={handleCptCode}
                onRemove={() => handleRemoveCptCode(idx)}
              />
            );
          })
        ) : (
          <Message>
            You don't have any CPT code in your list, please add one at least.
          </Message>
        )}
      </div>
      <Form.Group widths="equal" style={{ marginTop: '1rem' }}>
        <Form.Field required>
          <label>Status</label>
          <Dropdown
            style={{ minWidth: '10rem' }}
            placeholder="Select status"
            fluid
            selection
            options={billingClaimStatusOptions}
            value={status}
            onChange={(e, data) => {
              setStatus(data.value as BillingClaimStatus);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Total Claimed Amount</label>
          <Label basic size="big">
            ${totalAmount.toString()}
          </Label>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

//----------------------------------- CPT CODE CARD -----------------------------------//

interface CptCodeType {
  idx: number;
  cpt: string;
  type: string;
}

interface CptCodeCardProps {
  cptIdx: number;
  cptCode: CptCode;
  onChangeClaimAmount: (idx: number, value: number) => void;
  onChangePaidAmount: (idx: number, value: number) => void;
  onChangeCptCode: (cpt: CptCodeType) => void;
  onRemove: () => void;
}

const CptCodeCard = ({
  onChangeClaimAmount,
  onChangePaidAmount,
  onChangeCptCode,
  onRemove,
  cptIdx,
  cptCode,
}: CptCodeCardProps) => {
  const [cpt, setCpt] = useState<CptCodeType>();
  const [claimAmount, setClaimAmount] = useState(cptCode.claimAmount);
  const [paidAmount, setPaidAmount] = useState(cptCode.paidAmount);

  const handleCptCode = (cpt: CptCodeType) => {
    setCpt(cpt);
  };

  useEffect(() => {
    if (!cpt) return;
    onChangeCptCode(cpt);
  }, [cpt]);

  useEffect(() => {
    if (!cptCode) return;
    setClaimAmount(cptCode.claimAmount);
    setPaidAmount(cptCode.paidAmount);
  }, [cptCode]);

  return (
    <Card fluid>
      <Card.Content>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Popup
            size="small"
            content="Delete"
            trigger={
              <Button
                basic
                size="tiny"
                color="orange"
                icon="trash"
                onClick={onRemove}
              />
            }
          />
        </div>
        <Form.Field>
          <label>CPT Code</label>
          <CptCodePicker handleCptCode={handleCptCode} cptIdx={cptIdx} />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              type="number"
              min="0"
              step=".01"
              value={claimAmount || undefined}
              onChange={(_, { value }) => {
                setClaimAmount(parseFloat(value));
                onChangeClaimAmount(cptIdx, parseFloat(value));
              }}
              fluid
              label="Claimed Amount"
              placeholder="Claimed Amount"
              required
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="number"
              min="0"
              step=".01"
              value={paidAmount || undefined}
              onChange={(_, { value }) => {
                setPaidAmount(parseFloat(value));
                onChangePaidAmount(cptIdx, parseFloat(value));
              }}
              fluid
              label="Paid Amount"
              placeholder="Paid Amount"
              required
            />
          </Form.Field>
        </Form.Group>
      </Card.Content>
    </Card>
  );
};

export default ClaimForm;
