import { gql } from '@apollo/client';

export const BotReportsQuery = gql`
  query BotReportsQuery(
    $criteria: bot_jobs_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    aggregating: bot_jobs_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    botJobs: bot_jobs(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      id
      practiceId
      practicePatientId
      status
      type
      entityRef
      error
      metadata
      note
      createdAt
      source
      practice {
        id
        name
      }
    }
    allPractices: practices(
      distinct_on: id
      where: { settings: { _contains: { bot: { enabled: true } } } }
    ) {
      id
      name
    }
  }
`;

export const SaveJobMutation = gql`
  mutation SaveJobMutation(
    $id: uuid!
    $note: jsonb
    $status: bot_bot_statuses_enum!
    $source: String
  ) {
    update_bot_jobs_by_pk(
      pk_columns: { id: $id }
      _set: { note: $note, status: $status, source: $source }
    ) {
      id
      note
      status
      source
    }
  }
`;

export const InsertVaccinationMutation = gql`
  mutation InsertVaccinationMutation($jobId: uuid!) {
    TriggerVaccinationInsertJob(jobId: $jobId) {
      code
      message
    }
  }
`;

export const RemoveVaccinationMutation = gql`
  mutation RemoveVaccinationMutation($jobId: uuid!) {
    TriggerVaccinationRemoveJob(jobId: $jobId)
  }
`;

export const JobStatusSubscription = gql`
  subscription JobStatusSubscription($criteria: bot_jobs_bool_exp!) {
    botJobs: bot_jobs(where: $criteria) {
      id
      practiceId
      status
      type
      entityRef
      error
      metadata
      note
      createdAt
      practice {
        id
        name
      }
    }
  }
`;

export const BotWeeklyErrors = gql`
  query BotWeeklyErrors(
    $limit: Int = 10
    $offset: Int = 10
    $criteria: bot_weekly_errors_bool_exp = {}
  ) {
    botWeeklyErrors: bot_weekly_errors(
      limit: $limit
      offset: $offset
      where: $criteria
    ) {
      practiceId
      name
      message
      errorCount
      week
    }
    aggregating: bot_weekly_errors_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const AllPracticesWithEcw = gql`
  query AllPracticesWithEcw {
    allPractices: practices(
      order_by: { name: asc }
      where: {
        settings: { _contains: { bot: { enabled: true } } }
        suspended: { _eq: false }
      }
    ) {
      id
      name
      timezone
    }
  }
`;

export const EcwAppointmentsQuery = gql`
  query EcwAppointmentsQuery(
    $criteria: bot_raw_appointments_bool_exp
    $limit: Int
    $offset: Int
  ) {
    appointments: bot_raw_appointments(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { practice: { name: asc }, createdAt: desc }
    ) {
      id
      appointmentId
      practice {
        id
        name
        timezone
      }
      status
      mrn
      patient
      insurance
      encounterDate
      appointmentTime
      appointmentStatus
      error
      createdAt
      holder
    }
    aggregating: bot_raw_appointments_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const UpdateEcwAppointmentStatusMutation = gql`
  mutation UpdateEcwAppointmentStatusMutation($id: uuid!, $status: String!) {
    updatedStatus: update_bot_raw_appointments_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

export const EcwRawHistoricalVaccinationsQuery = gql`
  query GetRawHistoricalVaccinations(
    $criteria: bot_raw_historical_vaccinations_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    vaccinations: bot_raw_historical_vaccinations(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { practice: { name: asc }, createdAt: desc }
    ) {
      id
      givenDate
      mrn
      practiceId
      status
      name
      jobId
      createdAt
      statusReason
      error
      immId
      practice {
        id
        timezone
        settings
        name
      }
    }
    aggregating: bot_raw_historical_vaccinations_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const UpdateEcwRawHistoricalVaccinationStatusMutation = gql`
  mutation UpdateEcwRawHistoricalVaccinationStatusMutation(
    $id: uuid!
    $status: String!
  ) {
    updatedStatus: update_bot_raw_historical_vaccinations_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

export const GetHistoricalVaccinationsStatusReasonOptions = gql`
  query GetHistoricalVaccinationsStatusReasonOptions {
    bot_historical_vaccinations_status_reason {
      value
      comment
    }
  }
`;
