import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-semantic-toasts';
import { Button, Form, Header, Icon, Modal, Popup } from 'semantic-ui-react';
import { UpdateBillingClaimVfcInconsistenciesByPk } from '@bluefox/graphql/billing';

interface BorrowingNotesProps {
  note: string | undefined;
  mutationId: string | undefined;
}

export const BorrowingNotes = ({ note, mutationId }: BorrowingNotesProps) => {
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState(note || '');

  const [saveNotes] = useMutation(UpdateBillingClaimVfcInconsistenciesByPk);

  const handleNotesSubmit = () => {
    saveNotes({
      variables: {
        id: mutationId,
        notes: notes,
      },
    })
      .then(() => {
        toast({
          title: 'Notes saved successfully',
          type: 'success',
          time: 1000,
        });
        setOpenNotes(false);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Modal
        closeIcon
        onClose={() => setOpenNotes(false)}
        onOpen={() => setOpenNotes(true)}
        open={openNotes}
        trigger={<Button primary content="Notes" size="mini" />}
      >
        <Modal.Content>
          <Header>Notes</Header>
          <Form id="notes-form" onSubmit={handleNotesSubmit}>
            <Form.TextArea
              placeholder="There are no notes. You can add one and save it."
              value={notes}
              onChange={(_, { value }) => {
                setNotes(value as string);
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Close"
            onClick={() => setOpenNotes(false)}
          />
          <Button
            primary
            type="submit"
            icon="save"
            content="Save note"
            form="notes-form"
          />
        </Modal.Actions>
      </Modal>
      {notes && (
        <Popup
          content={notes}
          trigger={<Icon name="info circle" size="large" color="teal" />}
        />
      )}
    </div>
  );
};
