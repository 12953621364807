import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { AllPracticesWithDevelo } from '@bluefox/graphql/integrations/develo';
import {
  DeveloAppointmentsQuery,
  ProcessDeveloAppointmentManuallyMutation,
  UpdateDeveloStatusMutation,
} from '@bluefox/graphql/integrations/develo';
import {
  Appointment,
  appointmentsStatusOptions,
} from '@bluefox/models/integrations/Develo';
import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Button,
  Container,
  Label,
  Popup,
  Dropdown,
  Pagination,
  Confirm,
  List,
  Input,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import {
  setDropdownBackgroundColor,
  setDropdownBorderColor,
} from '@bluefox/lib/commonStyles';
import { DateFormats } from '@bluefox/models/Dates';
import { debounce } from '@bluefox/lib/debounce';
import {
  formatDateToMMDDYYYY,
  formatDatetimeToMMDDYYY,
  getTimeWithTimezoneOffset,
} from '@bluefox/lib/formatters';
import { whereLikeInput } from '@bluefox/graphql/utils';

interface PracticeOption {
  text: string;
  value: string;
}

interface AppointmentsData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
  appointments: Appointment[];
}

interface Practice {
  id: string;
  name: string;
}

const ENTRIES_PER_PAGE = 15;

const DeveloAppointments = () => {
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [practiceId, setPracticeId] = useState<string>();
  const [searchPractice, setSearchPractice] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchType, setSearchType] = useState<string>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [searchPatientMrn, setSearchPatientMrn] = useState<string>('');
  const [criteria, setCriteria] = useState<object>({});
  const [page, setPage] = useState(0);

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [DeveloAppointmentsLazyQuery, { data, loading, refetch }] =
    useLazyQuery<AppointmentsData>(DeveloAppointmentsQuery, {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
    });

  const { data: practicesData } = useQuery(AllPracticesWithDevelo);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handleStatusValue = (value: string) => {
    const statusValue =
      !!value && value !== 'allStatus' ? value?.toString() : undefined;
    setSearchStatus(statusValue || '');
  };

  const handlePracticeValue = (value: string) => {
    const practiceValue = !!value ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeId(practiceValue);
  };

  useEffect(() => {
    if (!practicesData?.allPractices || practicesData.allPractices.length < 1)
      return;

    setPracticeOptions(
      practicesData.allPractices.map((p: Practice) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [practicesData]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;
    let _searchStatus = undefined;
    let _searchType = undefined;
    let _searchDateRange = undefined;
    let _searchPatientMrn = undefined;

    if (searchPractice) {
      _practiceId = { _eq: practiceId };
    }

    if (searchStatus) {
      _searchStatus = { _eq: searchStatus };
    }

    if (searchType) {
      _searchType = { _eq: searchType };
    }

    if (searchFromDate || searchToDate) {
      _searchDateRange = {
        _gte: searchFromDate,
        _lte: searchToDate ?? new Date(),
      };
    }

    if (searchPatientMrn) {
      _searchPatientMrn = {
        _ilike: whereLikeInput(searchPatientMrn),
      };
    }

    _criteria = {
      practiceId: _practiceId,
      status: _searchStatus,
      type: _searchType,
      createdAt: _searchDateRange,
      mrn: _searchPatientMrn,
    };
    setCriteria(_criteria);
  }, [
    searchPractice,
    searchStatus,
    searchType,
    searchFromDate,
    searchPatientMrn,
    searchToDate,
  ]);

  useEffect(() => {
    if (!!searchPractice) {
      DeveloAppointmentsLazyQuery();
    }
  }, [criteria]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <MainLayout
      path={[
        { text: 'Develo Integration', to: '/integration-develo' },
        { text: 'Appointments' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="list" style={{ marginRight: '0.6rem' }} />
              Appointments
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[...practiceOptions]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        { text: 'Completed', value: 'completed' },
                        { text: 'Pending', value: 'pending' },
                        { text: 'Error', value: 'error' },
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Input
                      icon="search"
                      placeholder="Search Patient MRN..."
                      onChange={(_, { value }) => {
                        debouncedRef.current?.cancel();
                        debouncedRef.current = debounce(() => {
                          setSearchPatientMrn(value);
                        }, 500);

                        debouncedRef.current();
                      }}
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label style={{ marginRight: '0.5rem', width: '8rem' }}>
                      <b>Search by date range:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From..."
                      selected={searchFromDate}
                      onChange={(d) => {
                        setSearchFromDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      placeholderText="To..."
                      selected={searchToDate}
                      onChange={(d) => {
                        setSearchToDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchToDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Practice</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Patient</Table.HeaderCell>
                    <Table.HeaderCell>Insurance</Table.HeaderCell>
                    <Table.HeaderCell>Appointment</Table.HeaderCell>
                    <Table.HeaderCell>Error</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Segment basic>
                          <Placeholder fluid>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  ) : !!data?.appointments.length ? (
                    data.appointments.map((s) => {
                      return (
                        <AppointmentsRow key={s.id} data={s} onSave={refetch} />
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Message>No Appointments Found</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={8} textAlign="right">
                      <Pagination
                        disabled={!total || total < ENTRIES_PER_PAGE}
                        defaultActivePage={1}
                        boundaryRange={0}
                        siblingRange={1}
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage as number)
                        }
                        totalPages={totalPages}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//-----------------------------------SubscriptionsRow-----------------------------------//

interface AppointmentsRowProps {
  data: Appointment;
  onSave: () => void;
}

const AppointmentsRow = ({ data, onSave }: AppointmentsRowProps) => {
  const [showConfirmRunSub, setShowConfirmRunSub] = useState(false);

  const [runDeveloAppointments, { data: appointmentResponse }] = useMutation(
    ProcessDeveloAppointmentManuallyMutation,
    {
      variables: {
        appointmentId: data.id,
      },
      refetchQueries: [
        {
          query: DeveloAppointmentsQuery,
        },
      ],
    }
  );

  const [updateAppointmentStatus] = useMutation(UpdateDeveloStatusMutation);

  const handleRunAppointment = () => {
    runDeveloAppointments()
      .then((r) => {
        console.log('res', r);
        if (r.data.ProcessDeveloAppointmentManually.code === 400) {
          toast({
            title: 'There was an error trying to run appointment',
            type: 'error',
            time: 5000,
          });
          return;
        }

        toast({
          title: 'Action ran successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleAppointmentStatusUpdate = (status: string) => {
    updateAppointmentStatus({
      variables: {
        id: data.id,
        status,
      },
    })
      .then((r) => {
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const time = getTimeWithTimezoneOffset(data.startDate, 0);

  const insuranceIndex = data.metadata.patient.insuranceCoverages.findIndex(
    (i: any) => i.coordinationOfBenefitsType === 'PRIMARY'
  );

  return (
    <Table.Row>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor: setDropdownBackgroundColor(data?.status),
            borderColor: setDropdownBorderColor(data?.status),
            maxWidth: '8rem',
          }}
          fluid
          selection
          options={appointmentsStatusOptions}
          value={data?.status}
          onChange={(e, data) => {
            handleAppointmentStatusUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <StyledDivCol>
          {`${data.metadata.patient.givenName} ${data.metadata.patient.familyName}`}
          <p
            style={{ margin: '0' }}
          >{`(${data.metadata.patient.birthDate})`}</p>
          <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
            MRN: {data.mrn}
          </p>
          <Popup
            on="click"
            trigger={<a>More info...</a>}
            content={
              <List>
                <List.Item>
                  <List.Header>MRN</List.Header>
                  {data.mrn || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>First Name</List.Header>
                  {data.metadata.patient.givenName || '_'}
                </List.Item>
                {data.metadata.middleName && (
                  <List.Item>
                    <List.Header>Middle Name</List.Header>
                    {data.metadata.patient.middleName}
                  </List.Item>
                )}
                <List.Item>
                  <List.Header>Last Name</List.Header>
                  {data.metadata.patient.familyName || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Birthdate</List.Header>
                  {data.metadata.patient.birthDate || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Sex</List.Header>
                  {data.metadata.patient.sexAtBirth || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Ethnicity</List.Header>
                  {data.metadata.patient.ethnicity || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Race</List.Header>
                  {data.metadata.patient.race || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Email</List.Header>
                  {data.metadata.patient.email || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Phone Number</List.Header>
                  {data.metadata.patient.phone || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Address</List.Header>
                  {`${data.metadata.patient.address.line1 || '_'}, ${
                    data.metadata.patient.address.city || '_'
                  }, ${data.metadata.patient.address.state || '_'} - ${
                    data.metadata.patient.address.postalCode || '_'
                  }`}
                </List.Item>
              </List>
            }
          />
        </StyledDivCol>
      </Table.Cell>
      <Table.Cell>
        {data.metadata.patient.insuranceCoverages &&
        data.metadata.patient.insuranceCoverages.length ? (
          <StyledDivCol>
            {
              data.metadata.patient.insuranceCoverages[insuranceIndex]?.payer
                .name
            }

            <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
              Member Id:{' '}
              {
                data.metadata.patient.insuranceCoverages[insuranceIndex]
                  ?.planMemberIdentifier
              }
            </p>
            <p style={{ margin: '0' }}>
              <Label
                size="small"
                color="blue"
                content={
                  data.metadata.patient.insuranceCoverages[insuranceIndex]
                    ?.relationshipToSubscriber
                }
              />
            </p>
            <Popup
              on="click"
              trigger={<a>More info...</a>}
              content={
                <List>
                  <List.Item>
                    <List.Header>Company Name</List.Header>
                    {`${data.metadata.patient.insuranceCoverages[insuranceIndex]?.payer.name} (${data.metadata.patient.insuranceCoverages[insuranceIndex]?.payer.id})` ||
                      '_'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Member ID</List.Header>
                    {data.metadata.patient.insuranceCoverages[insuranceIndex]
                      ?.planMemberIdentifier || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Group ID</List.Header>
                    {data.metadata.patient.insuranceCoverages[insuranceIndex]
                      ?.planGroupIdentifier || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Holder</List.Header>
                    {`${
                      data.metadata.patient.insuranceCoverages[insuranceIndex]
                        ?.subscriber.givenName || '-'
                    } ${
                      data.metadata.patient.insuranceCoverages[insuranceIndex]
                        ?.subscriber.familyName || '-'
                    }`}
                  </List.Item>
                  <List.Item>
                    <List.Header>Holder Birthdate</List.Header>
                    {data.metadata.patient.insuranceCoverages[insuranceIndex]
                      ?.subscriber?.birthDate || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Relationship</List.Header>
                    {data.metadata.patient.insuranceCoverages[insuranceIndex]
                      ?.relationshipToSubscriber || '-'}
                  </List.Item>
                </List>
              }
            />
          </StyledDivCol>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data.startDate ? (
          <StyledDivCol>
            {formatDatetimeToMMDDYYY(data.startDate) || '-'}
            <p>
              <b>{`${time.hours}:${time.minutes} hs` || '-'}</b>
            </p>
            <p>{data.appointmentStatus}</p>
          </StyledDivCol>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data?.error ? (
          <Popup
            on="click"
            trigger={<Button size="mini" content="Show Error" />}
            position="top right"
            content={data.error.message || '-'}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>{formatDateToMMDDYYYY(data.createdAt)}</Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          trigger={
            <Button
              size="tiny"
              color="teal"
              icon="play"
              onClick={() => setShowConfirmRunSub(true)}
              disabled={data.status !== 'error'}
            />
          }
          content="Run Appointment"
        />
      </Table.Cell>
      <Confirm
        size="mini"
        content="Are you sure you want to run appointment?"
        confirmButton="Yes"
        open={showConfirmRunSub}
        onCancel={() => setShowConfirmRunSub(false)}
        onConfirm={() => {
          handleRunAppointment();
          setShowConfirmRunSub(false);
        }}
      />
    </Table.Row>
  );
};

const StyledDivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export default DeveloAppointments;
