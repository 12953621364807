import { useEffect, useState } from 'react';
import MainLayout from '@ui/MainLayout';
import {
  VFCInconsistency,
  VFCInconsistencyStatuses,
  VFCInconsistencyTypes,
} from '@bluefox/models/VFCInconsistency';
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Icon,
  Modal,
} from 'semantic-ui-react';
import { Enum } from '@bluefox/models/Generics';

import { useHistory } from 'react-router-dom';
import {
  AdjustmentReasons,
  InventoryAdjustmentDetailStatuses,
  InventoryAdjustmentStatuses,
  InventoryAdjustmentTypes,
} from '@bluefox/models/InventoryAdjustment';

import InventoryAdjustment from './InventoryAdjustment';
import ActionsMenu from '@screens/inventoryControl/ActionsMenu';
import Borrowing from './borrowing';
import {
  BorrowingCase,
  InventoryAdjustment as InventoryAdjustmentModel,
  InventoryAdjustmentData,
  InventoryAdjustmentDetail,
  ScanData,
  STEPS,
} from '@bluefox/models/models';
import { useLazyQuery, useMutation } from '@apollo/client';
import { InventoryStatus, VaccinesStockData } from '@bluefox/models/Inventory';
import { ReceivedInventoryQueryByPractice } from '@bluefox/graphql/inventory';
import {
  GetClaimsVFCInconsistencies,
  MakeAdjustmentMutation,
} from '@graphql/inventoryAdjustment';
import { toast } from 'react-semantic-toasts';
import moment from 'moment/moment';
import { DateFormats } from '@bluefox/models/Dates';
import { usePractice } from '@bluefox/contexts';

interface VFCInconsistenciesData {
  vfcInconsistencies: VFCInconsistency[];
}

const InventoryControl = () => {
  const history = useHistory();
  const practice = usePractice();

  const readableSteps = [
    {
      key: 'PRIVATE EXPIRED',
      content: 'Private Expired',
      active: false,
      link: false,
    },
    { key: 'PRIVATE', content: 'Private', active: false, link: false },
    { key: 'VFC EXPIRED', content: 'VFC Expired', active: false, link: false },
    { key: 'VFC', content: 'VFC', active: false, link: false },
    { key: 'BORROWING', content: 'Borrowing', active: false, link: false },
    { key: 'APPLYING', content: 'Applying', active: false, link: false },
  ];

  const [breadCrumbSections, setBreadCrumbSections] = useState(readableSteps);

  const [processStarted, setProcessStarted] = useState(false);
  const [nextButtonClicked, setNextButtonClicked] = useState<
    boolean | undefined
  >();
  const [cancelButtonClicked, setCancelButtonClicked] = useState<
    boolean | undefined
  >();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSelectPracticeModal, setShowSelectPracticeModal] = useState(true);
  const [currentStep, setCurrentStep] = useState<STEPS | undefined>();
  const [scannedData, setScannedData] = useState<ScanData | undefined>();
  const [adjustmentData, setAdjustmentData] = useState<
    InventoryAdjustmentData[]
  >([]);

  const practiceId = practice.id;

  const onFinishInventoryControl = (
    inventoryAdjustment: InventoryAdjustmentData[]
  ) => {
    setAdjustmentData(inventoryAdjustment);
    onFinishStep();
  };

  const [adjustInventory] = useMutation(MakeAdjustmentMutation);

  const [getInventoryByPractice, { data: inventoryData }] =
    useLazyQuery<VaccinesStockData>(ReceivedInventoryQueryByPractice);

  const [getVFCInconsistencies, { data: getVFCInconsistenciesResponse }] =
    useLazyQuery<VFCInconsistenciesData>(GetClaimsVFCInconsistencies);

  const onFinishStep = () => {
    if (currentStep === STEPS.PRIVATE_EXPIRED) {
      setCurrentStep(STEPS.PRIVATE);
      return;
    }
    if (currentStep === STEPS.PRIVATE) {
      setCurrentStep(STEPS.VFC_EXPIRED);
      return;
    }
    if (currentStep === STEPS.VFC_EXPIRED) {
      setCurrentStep(STEPS.VFC);
      return;
    }
    if (currentStep === STEPS.VFC) {
      setCurrentStep(STEPS.BORROWING);
      return;
    }

    if (currentStep === STEPS.BORROWING) {
      setCurrentStep(STEPS.APPLY);
      return;
    }
  };

  const onsFinishBorrowing = (borrowingCases: BorrowingCase[]): void => {
    const _adjustmentData = [...adjustmentData];

    const dataToApply = borrowingCases.reduce((accum, currentCase) => {
      const sourceInventory = { ...currentCase.alternatives[0] };
      let targetInventory = _adjustmentData.find(
        (currentAdjustment: InventoryAdjustmentData) => {
          return (
            currentAdjustment.isPrivate !== sourceInventory.isPrivate &&
            currentAdjustment.lot === sourceInventory.lot &&
            currentAdjustment.expiration === sourceInventory.expiration &&
            currentAdjustment.vaccineId === sourceInventory.vaccineId
          );
        }
      );

      let _targetInventory: InventoryAdjustmentData | undefined = {
        ...(targetInventory as InventoryAdjustmentData),
      };

      if (!targetInventory) {
        _targetInventory = { ...sourceInventory };
        _targetInventory.newDoses = 0;
        _targetInventory.id = undefined;
        _targetInventory.isPrivate = !_targetInventory.isPrivate;
      }

      const {
        // @ts-ignore
        __typename,
        claim,
        createdAt,
        updatedAt,
        initialStatus,
        initialType,
        inventory,
        ..._inconsistency
      } = currentCase.inconsistency;

      sourceInventory.newDoses = (sourceInventory.newDoses || 0) - 1;
      _targetInventory.inconsistency = _inconsistency;
      sourceInventory.inconsistency = _inconsistency;
      _targetInventory.isTarget = true;
      _targetInventory.newDoses = (_targetInventory.newDoses || 0) + 1;

      return [...accum, sourceInventory, _targetInventory];
    }, [] as InventoryAdjustmentData[]);

    applyAdjustment([...adjustmentData, ...dataToApply]);
  };

  const applyAdjustment = (adjustmentData: InventoryAdjustmentData[]) => {
    const details = adjustmentData.reduce(
      (dataToSend, inventory) => {
        if (!inventory.wasConfirmed && !inventory.wasAdjusted) {
          return dataToSend;
        }
        const type: InventoryAdjustmentTypes = (() => {
          if (inventory.isExpired) {
            return InventoryAdjustmentTypes.REMOVAL;
          }
          if (inventory.wasAdjusted) {
            return InventoryAdjustmentTypes.CONFIRMATION;
          }
          return InventoryAdjustmentTypes.ADJUSTMENT;
        })();

        let currentAdjustmentData: InventoryAdjustmentDetail = {
          sourceClaimVfcInconsistencies: { data: {}, on_conflict: {} },
          targetClaimVfcInconsistencies: { data: {}, on_conflict: {} },

          newDoses: inventory.newDoses || 0,
          currentDoses: inventory.doses,
          reason:
            inventory.adjustmentReason || AdjustmentReasons.MISSING_VACCINE,
          type,
          comment: inventory.comment,
          status: InventoryAdjustmentDetailStatuses.APPLIED,
        };

        const update_columns: string[] = [
          'doses',
          'lastAdjustmentDate',
          'lastAdjustmentReason',
        ];

        if (inventory.isExpired) {
          update_columns.push('status');
        }

        const doses = (() => {
          if (inventory.isExpired) {
            return inventory.doses;
          }
          if (typeof inventory.newDoses !== 'number') {
            return inventory.doses;
          }
          return inventory.newDoses;
        })();

        let inventoryData = {
          data: {
            id: inventory.id,
            doses,
            lastAdjustmentDate: moment(
              inventory.newAdjustmentDate || undefined
            ).format(DateFormats.DATE),
            lastAdjustmentReason:
              inventory.adjustmentReason || AdjustmentReasons.OTHER,
            lot: inventory.lot,
            practiceId,
            vfc: !inventory.isPrivate,
            vaccineId: inventory.vaccineId,
            status: inventory.isExpired
              ? InventoryStatus.retired
              : InventoryStatus.received,
            expiration: new Date(inventory.expiration),
          },
          on_conflict: {
            constraint: 'inventories_pkey',
            update_columns,
          },
        };

        if (inventory.inconsistency) {
          const _inconsistency = { ...inventory.inconsistency };
          _inconsistency.status = VFCInconsistencyStatuses.SOLVED;

          if (inventory.isTarget) {
            // @ts-ignore
            currentAdjustmentData.targetClaimVfcInconsistencies.data =
              _inconsistency;
            // @ts-ignore
            currentAdjustmentData.targetClaimVfcInconsistencies.on_conflict = {
              constraint: 'claim_vfc_inconsistencies_pkey',
              update_columns: ['status'],
            };
            currentAdjustmentData.sourceClaimVfcInconsistencies = undefined;
          } else {
            // @ts-ignore
            currentAdjustmentData.sourceClaimVfcInconsistencies.data =
              _inconsistency;
            // @ts-ignore
            currentAdjustmentData.sourceClaimVfcInconsistencies.on_conflict = {
              constraint: 'claim_vfc_inconsistencies_pkey',
              update_columns: ['status'],
            };
            currentAdjustmentData.targetClaimVfcInconsistencies = undefined;
          }
        } else {
          currentAdjustmentData.sourceClaimVfcInconsistencies = undefined;
          currentAdjustmentData.targetClaimVfcInconsistencies = undefined;
          // @ts-ignore
        }

        currentAdjustmentData.inventory = inventoryData;
        const inventoryType = inventory.isPrivate ? 'privates' : 'vfc';
        dataToSend[inventoryType] = [
          ...dataToSend[inventoryType],
          currentAdjustmentData,
        ];
        return dataToSend;
      },
      { privates: [], vfc: [] } as {
        privates: InventoryAdjustmentDetail[];
        vfc: InventoryAdjustmentDetail[];
      }
    );

    const privateAdjustment: InventoryAdjustmentModel = {
      practiceId,
      vfc: false,
      status: InventoryAdjustmentStatuses.APPLIED,
      inventoryAdjustmentDetails: { data: details.privates },
    };

    const vfcAdjustment: InventoryAdjustmentModel = {
      practiceId,
      vfc: true,
      status: InventoryAdjustmentStatuses.APPLIED,
      inventoryAdjustmentDetails: { data: details.vfc },
    };

    adjustInventory({
      variables: { private: privateAdjustment, vfc: vfcAdjustment },
    })
      .then(() => {
        setShowConfirmationModal(true);
      })
      .catch(() => {
        toast({
          title: 'Error trying to adjust inventory',
          type: 'error',
          time: 5000,
        });
      });
  };

  const updateBreadCrumbState = () => {
    if (currentStep === undefined) {
      return;
    }
    const _breadCrumbSections = [...breadCrumbSections];
    const currentActive = _breadCrumbSections.find(
      (breadCrumbSection) => breadCrumbSection.active
    );
    if (currentActive) {
      currentActive.active = false;
    }
    const nextActive = _breadCrumbSections[currentStep as number];
    nextActive.active = true;

    _breadCrumbSections.forEach((section) => {
      if (section.active) {
        section.link = false;
        return;
      }
      section.link = true;
    });

    setBreadCrumbSections(_breadCrumbSections);
  };

  useEffect(() => {
    if (currentStep !== STEPS.BORROWING) {
      return;
    }
    if (getVFCInconsistenciesResponse?.vfcInconsistencies.length !== 0) {
      return;
    }

    applyAdjustment(adjustmentData);
  }, [nextButtonClicked]);

  // use Effects
  useEffect(() => {
    updateBreadCrumbState();
    if (currentStep === STEPS.BORROWING) {
      getVFCInconsistencies({
        variables: {
          practiceId,
          status: VFCInconsistencyStatuses.PENDING,
          type: VFCInconsistencyTypes.BORROW,
        },
      });
    }
  }, [currentStep]);

  // use Effects
  return (
    <MainLayout path={[{ text: 'Inventory Control' }, { text: practice.name }]}>
      <Container data-automation-id="it-inventory-adjustment">
        <Card fluid>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="boxes" style={{ marginRight: '0.6rem' }} />
              <Breadcrumb icon="right angle" sections={breadCrumbSections} />
            </Card.Header>
            <Card.Description>
              <ActionsMenu
                currentStep={currentStep}
                onClickCancelButton={() =>
                  setCancelButtonClicked(!cancelButtonClicked)
                }
                onClickNextButton={() => {
                  setNextButtonClicked(!nextButtonClicked);
                }}
                onScan={(scanData) => setScannedData(scanData)}
              />
              {processStarted && currentStep !== undefined && (
                <>
                  {currentStep <= STEPS.VFC && (
                    <InventoryAdjustment
                      currentStep={currentStep}
                      onFinishStep={onFinishStep}
                      data={inventoryData}
                      onFinishInventoryControl={onFinishInventoryControl}
                      scannedData={scannedData}
                      onStepChange={(newStep) => {
                        if (currentStep === newStep) {
                          return;
                        }
                        setCurrentStep(newStep);
                      }}
                      nextButtonClicked={nextButtonClicked}
                      cancelButtonClicked={cancelButtonClicked}
                      practiceId={practiceId}
                    />
                  )}

                  {currentStep === STEPS.BORROWING &&
                    getVFCInconsistenciesResponse &&
                    getVFCInconsistenciesResponse.vfcInconsistencies.length <
                      0 && (
                      <Borrowing
                        currentStep={currentStep}
                        onsFinishBorrowing={onsFinishBorrowing}
                        nextButtonClicked={nextButtonClicked}
                        inconsistencies={
                          getVFCInconsistenciesResponse.vfcInconsistencies
                        }
                        adjustmentData={adjustmentData}
                      />
                    )}

                  {currentStep === STEPS.BORROWING &&
                    getVFCInconsistenciesResponse &&
                    getVFCInconsistenciesResponse.vfcInconsistencies.length ===
                      0 && (
                      <h2>
                        NO BORROWING CASE TO SOLVE, CLICK NEXT BUTTON TO
                        CONTINUE
                      </h2>
                    )}
                </>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>

      <Modal
        onClose={() => {
          setShowSelectPracticeModal(false);
        }}
        open={showSelectPracticeModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>Want to start a Inventory Control?</Modal.Header>

        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => {
              history.push(`/practices/${practice.handler}/inventory`);
            }}
          />
          <Button
            type="button"
            content="Start Process"
            onClick={() => {
              setCurrentStep(STEPS.PRIVATE_EXPIRED);
              setProcessStarted(true);
              setShowSelectPracticeModal(false);
              getInventoryByPractice({
                variables: {
                  likeQuery: `%%`,
                  typesFilter: {},
                  practiceId,
                },
              });
            }}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => {
          setShowConfirmationModal(false);
          history.push(`/practices`);
        }}
        open={showConfirmationModal}
        size="mini"
      >
        <Modal.Header>Adjustment Applied</Modal.Header>
        <Modal.Actions>
          <Button
            type="button"
            content="Continue"
            onClick={() => {
              setShowConfirmationModal(false);
              history.push(`/practices`);
            }}
          />
        </Modal.Actions>
      </Modal>
    </MainLayout>
  );
};

export default InventoryControl;
