import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { MappingsQuery } from '@bluefox/graphql/integrations/ecw';
import { MappedItem } from '@bluefox/models/Mappings';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import {
  Segment,
  Card,
  Container,
  Table,
  Placeholder,
  Message,
  Button,
  Popup,
  Modal,
  Menu,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import MainLayout from '@ui/MainLayout';
import EcwAdministrationSiteMappingForm from '@ui/EcwIntegration/mappings/EcwAdministrationSiteMappingForm';

interface EmrMappingData {
  mappings: MappedItem[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const EcwAdministrationSiteMapping = () => {
  const practice = usePractice();

  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedItemMapping, setSelectedItemMapping] = useState<MappedItem>();
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);
  const [criteria, setCriteria] = useState({});
  const [searchPractice, setSearchPractice] = useState<string>();
  const [practiceId, setPracticeId] = useState<string>();

  const { data, loading, refetch } = useQuery<EmrMappingData>(MappingsQuery, {
    variables: {
      criteria,
    },
  });

  const closeModal = () => {
    setIsSelected(false);
    setSelectedItemMapping(undefined);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  const handlePracticeValue = (value: string) => {
    const practiceValue =
      !!value && value !== 'allPractices' ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeId(practiceValue);
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  useEffect(() => {
    if (!data?.allPractices || data.allPractices.length < 1) return;

    setPracticeOptions(
      data.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;

    if (searchPractice) {
      _practiceId = { _eq: practiceId };
    }

    _criteria = {
      type: {
        _eq: 'site',
      },
      practice: {
        id: _practiceId,
      },
    };

    setCriteria(_criteria);
  }, [searchPractice]);

  return (
    <MainLayout
      path={[
        { text: 'ECW Integration', to: '/integration-ecw' },
        { text: 'Administration Site Mapping' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="map outline" style={{ marginRight: '0.6rem' }} />
                Administration Site Mapping
              </Card.Header>
            </div>
            <Menu borderless>
              <Menu.Item>
                <Dropdown
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by practice"
                  fluid
                  selection
                  onChange={(e, data) => {
                    handlePracticeValue(data.value?.toString() || '');
                  }}
                  options={[
                    { text: 'All practices', value: 'allPractices' },
                    ...practiceOptions,
                  ]}
                />
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    primary
                    size="small"
                    content="Add Administration Site"
                    icon="plus"
                    onClick={() => {
                      setIsSelected(false);
                      setOpen(true);
                    }}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Practice</Table.HeaderCell>
                  <Table.HeaderCell>
                    Administration Site (Canid)
                  </Table.HeaderCell>
                  <Table.HeaderCell>Administration Site (EMR)</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data && data.mappings.length ? (
                  data.mappings.map((item) => {
                    return (
                      <EcwAdministrationSiteMappingRow
                        key={item.id}
                        data={item}
                        onChangeSelected={() => {
                          handleChangeSelected(true);
                          setSelectedItemMapping({
                            id: item.id,
                            key: item.key,
                            metadata: item.metadata,
                            type: item.type,
                            practice: item.practice,
                          });
                        }}
                      />
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <Message>No mappings found.</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Modal
              size="small"
              onClose={closeModal}
              onOpen={() => setOpen(open)}
              open={open}
              closeIcon
            >
              <Modal.Header>
                {isSelected
                  ? 'Edit Administration Site Mapping'
                  : 'Add Administration Site Mapping'}
              </Modal.Header>
              <Modal.Content>
                <EcwAdministrationSiteMappingForm
                  close={closeModal}
                  practices={data?.allPractices}
                  data={selectedItemMapping ? selectedItemMapping : null}
                  refetchMappings={refetch}
                />
              </Modal.Content>
            </Modal>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//------------------------------EcwAdministrationSiteMappingRow------------------------------//

interface EcwAdministrationSiteMappingRowProps {
  data: MappedItem;
  onChangeSelected: (selected: boolean) => void;
}

const EcwAdministrationSiteMappingRow = ({
  data,
  onChangeSelected,
}: EcwAdministrationSiteMappingRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>{data?.practice?.name}</Table.Cell>
      <Table.Cell>{data?.key}</Table.Cell>
      <Table.Cell>{data?.metadata[0]?.keyEmr}</Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default EcwAdministrationSiteMapping;
