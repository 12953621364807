import { Dropdown } from 'semantic-ui-react';

export const stateOptions = [
  {
    key: 'AL',
    value: 'AL',
    text: 'Alabama',
    timeZone: 'America/Chicago',
  },
  {
    key: 'AK',
    value: 'AK',
    text: 'Alaska',
    timeZone: 'America/Anchorage',
  },
  {
    key: 'AZ',
    value: 'AZ',
    text: 'Arizona',
    timeZone: 'America/Phoenix',
  },
  {
    key: 'AR',
    value: 'AR',
    text: 'Arkansas',
    timeZone: 'America/Chicago',
  },
  {
    key: 'CA',
    value: 'CA',
    text: 'California',
    timeZone: 'America/Los_Angeles',
  },
  {
    key: 'CO',
    value: 'CO',
    text: 'Colorado',
    timeZone: 'America/Denver',
  },
  {
    key: 'CT',
    value: 'CT',
    text: 'Connecticut',
    timeZone: 'America/New_York',
  },
  {
    key: 'DE',
    value: 'DE',
    text: 'Delaware',
    timeZone: 'America/New_York',
  },
  {
    key: 'DC',
    value: 'DC',
    text: 'District Of Columbia',
    timeZone: 'America/New_York',
  },
  {
    key: 'FL',
    value: 'FL',
    text: 'Florida',
    timeZone: 'America/New_York',
  },
  {
    key: 'GA',
    value: 'GA',
    text: 'Georgia',
    timeZone: 'America/New_York',
  },
  {
    key: 'HI',
    value: 'HI',
    text: 'Hawaii',
    timeZone: 'Pacific/Honolulu',
  },
  {
    key: 'ID',
    value: 'ID',
    text: 'Idaho',
    timeZone: 'America/Boise',
  },
  {
    key: 'IL',
    value: 'IL',
    text: 'Illinois',
    timeZone: 'America/Chicago',
  },
  {
    key: 'IN',
    value: 'IN',
    text: 'Indiana',
    timeZone: 'America/Indianapolis',
  },
  {
    key: 'IA',
    value: 'IA',
    text: 'Iowa',
    timeZone: 'America/Chicago',
  },
  {
    key: 'KS',
    value: 'KS',
    text: 'Kansas',
    timeZone: 'America/Chicago',
  },
  {
    key: 'KY',
    value: 'KY',
    text: 'Kentucky',
    timeZone: 'America/New_York',
  },
  {
    key: 'LA',
    value: 'LA',
    text: 'Louisiana',
    timeZone: 'America/Chicago',
  },
  {
    key: 'ME',
    value: 'ME',
    text: 'Maine',
    timeZone: 'America/New_York',
  },
  {
    key: 'MD',
    value: 'MD',
    text: 'Maryland',
    timeZone: 'America/New_York',
  },
  {
    key: 'MA',
    value: 'MA',
    text: 'Massachusetts',
    timeZone: 'America/New_York',
  },
  {
    key: 'MI',
    value: 'MI',
    text: 'Michigan',
    timeZone: 'America/Detroit',
  },
  {
    key: 'MN',
    value: 'MN',
    text: 'Minnesota',
    timeZone: 'America/Chicago',
  },
  {
    key: 'MS',
    value: 'MS',
    text: 'Mississippi',
    timeZone: 'America/Chicago',
  },
  {
    key: 'MO',
    value: 'MO',
    text: 'Missouri',
    timeZone: 'America/Chicago',
  },
  {
    key: 'MT',
    value: 'MT',
    text: 'Montana',
    timeZone: 'America/Denver',
  },
  {
    key: 'NE',
    value: 'NE',
    text: 'Nebraska',
    timeZone: 'America/Chicago',
  },
  {
    key: 'NV',
    value: 'NV',
    text: 'Nevada',
    timeZone: 'America/Los_Angeles',
  },
  {
    key: 'NH',
    value: 'NH',
    text: 'New Hampshire',
    timeZone: 'America/New_York',
  },
  {
    key: 'NJ',
    value: 'NJ',
    text: 'New Jersey',
    timeZone: 'America/New_York',
  },
  {
    key: 'NM',
    value: 'NM',
    text: 'New Mexico',
    timeZone: 'America/Denver',
  },
  {
    key: 'NY',
    value: 'NY',
    text: 'New York',
    timeZone: 'America/New_York',
  },
  {
    key: 'NC',
    value: 'NC',
    text: 'North Carolina',
    timeZone: 'America/New_York',
  },
  {
    key: 'ND',
    value: 'ND',
    text: 'North Dakota',
    timeZone: 'America/Chicago',
  },
  {
    key: 'OH',
    value: 'OH',
    text: 'Ohio',
    timeZone: 'America/New_York',
  },
  {
    key: 'OK',
    value: 'OK',
    text: 'Oklahoma',
    timeZone: 'America/Chicago',
  },
  {
    key: 'OR',
    value: 'OR',
    text: 'Oregon',
    timeZone: 'America/Los_Angeles',
  },
  {
    key: 'PA',
    value: 'PA',
    text: 'Pennsylvania',
    timeZone: 'America/New_York',
  },
  {
    key: 'RI',
    value: 'RI',
    text: 'Rhode Island',
    timeZone: 'America/New_York',
  },
  {
    key: 'SC',
    value: 'SC',
    text: 'South Carolina',
    timeZone: 'America/New_York',
  },
  {
    key: 'SD',
    value: 'SD',
    text: 'South Dakota',
    timeZone: 'America/Chicago',
  },
  {
    key: 'TN',
    value: 'TN',
    text: 'Tennessee',
    timeZone: 'America/Chicago',
  },
  {
    key: 'TX',
    value: 'TX',
    text: 'Texas',
    timeZone: 'America/Chicago',
  },
  {
    key: 'UT',
    value: 'UT',
    text: 'Utah',
    timeZone: 'America/Denver',
  },
  {
    key: 'VT',
    value: 'VT',
    text: 'Vermont',
    timeZone: 'America/New_York',
  },
  {
    key: 'VA',
    value: 'VA',
    text: 'Virginia',
    timeZone: 'America/New_York',
  },
  {
    key: 'WA',
    value: 'WA',
    text: 'Washington',
    timeZone: 'America/Los_Angeles',
  },
  {
    key: 'WV',
    value: 'WV',
    text: 'West Virginia',
    timeZone: 'America/New_York',
  },
  {
    key: 'WI',
    value: 'WI',
    text: 'Wisconsin',
    timeZone: 'America/Chicago',
  },
  {
    key: 'WY',
    value: 'WY',
    text: 'Wyoming',
    timeZone: 'America/Denver',
  },
];

interface StatePickerProps {
  value: string;
  onChange: (value: string) => void;
  fluid?: boolean;
}

const StatePicker = ({ value, onChange, fluid }: StatePickerProps) => {
  return (
    <Dropdown
      fluid={fluid}
      value={value}
      onChange={(_, { value }) => onChange(value as string)}
      placeholder="State"
      search
      selection
      options={stateOptions}
    />
  );
};

export default StatePicker;
