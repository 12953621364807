import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { tsXLXS } from 'ts-xlsx-export';
import moment from 'moment-timezone';
import { Modal, Button, Progress, Icon } from 'semantic-ui-react';
import { DateFormats } from '@bluefox/models/Dates';
import { GetClaimsVFCInconsistencies } from '@bluefox/graphql/billing';
import {
  GetVFCInconsistenciesStatusesAndTypesData,
  VFCInconsistency,
} from '@bluefox/models/VFCInconsistency';

interface VFCInconsistenciesData {
  vfcInconsistencies: VFCInconsistency[];
}

interface BorrowingExcelExportData {
  'Claim ID': string;
  'Insurance Company': string;
  'Service Date': string;
  'Inventory Used': string;
  'Patient VFC Eligible': string;
  'Vax Name'?: string;
  lot?: string;
  Status?: string;
  'Solution Type'?: string;
  Patient: string;
}

interface BorrowingExcelExportModalProps {
  criteria?: any;
  VFCInconsistenciesStatusesAndTypes?: GetVFCInconsistenciesStatusesAndTypesData;
}

const BorrowingExcelExportModal = ({
  criteria,
  VFCInconsistenciesStatusesAndTypes,
}: BorrowingExcelExportModalProps) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState<
    BorrowingExcelExportData[] | undefined
  >([]);

  const [getInconsistenciesData, { loading: viewLoading, error: viewError }] =
    useLazyQuery<VFCInconsistenciesData>(GetClaimsVFCInconsistencies(false), {
      onCompleted(data) {
        const inconsistenciesList = data.vfcInconsistencies.map((i) => {
          return {
            'Claim ID': i.claimId,
            Practice: i.practice?.name ? i.practice.name : '-',
            Patient: i.claim?.practicePatient.patientData.firstName
              ? `${i.claim?.practicePatient.patientData.firstName}, ${i.claim?.practicePatient.patientData.lastName} (${i.claim?.practicePatient.patientData.birthdate})`
              : '-',
            'Insurance Company': i.claim?.insurance[0]?.insuranceCompanyName
              ? `${i.claim?.insurance[0]?.insuranceCompanyName} - ${i.claim?.insurance[0]?.memberId}`
              : '-',
            'Patient VFC Eligible': i.claim?.insurance[0]?.vfcEligible
              ? 'VFC Eligible'
              : 'Private',
            'Service Date': i.claim?.givenAt
              ? moment(i.claim?.givenAt).format(DateFormats.DATE)
              : '-',
            'Inventory Used': i.inventory?.vfc ? 'VFC' : 'Private',
            lot: i.inventory?.lot,
            'Vax Name': i.inventory?.vaccine.name,
            'Solution Type': VFCInconsistenciesStatusesAndTypes
              ? VFCInconsistenciesStatusesAndTypes.types.find(
                  (t) => t.value === i.type
                )?.text
              : i.type,
            Status: VFCInconsistenciesStatusesAndTypes
              ? VFCInconsistenciesStatusesAndTypes.statuses.find(
                  (s) => s.value === i.status
                )?.text
              : i.status,
          };
        });

        setExcelData(inconsistenciesList);
      },
    });

  const handleLoadXlsx = () => {
    if (!excelData) return;
    tsXLXS()
      .exportAsExcelFile(excelData)
      .saveAsExcelFile(
        `Canid-Billing-Claim-List-${moment().format(
          DateFormats.DATE_WITH_TIME_SECONDS
        )}`
      );
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={
        <Button
          inverted
          size="small"
          color="blue"
          floated="right"
          onClick={() => {
            getInconsistenciesData({
              variables: {
                criteria,
              },
            });
          }}
        >
          Export EXCEL
        </Button>
      }
    >
      <Modal.Header>Export as Excel file?</Modal.Header>
      <Modal.Content>
        {viewLoading ? (
          <>
            Loading Excel data...
            <Progress size="small" color="teal" indicating percent={100} />
          </>
        ) : viewError ? (
          <>
            <Icon name="warning sign" color="red" />
            There was an error trying to load Excel data
          </>
        ) : (
          <>
            Excel data is ready to be exported{' '}
            <Icon name="check" color="olive" />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <Button
          primary
          content="Yes"
          onClick={() => handleLoadXlsx()}
          disabled={viewLoading || !excelData}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default BorrowingExcelExportModal;
