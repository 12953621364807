import { Insurance, VfcCriteria } from '@bluefox/models/Insurances';

interface InsuranceDataShortDetail {
  name: string;
  memberId: string;
  vfcEligible: boolean | undefined;
  vfcCriteria?: VfcCriteria;
}

export const isVfcUninsured = (
  insurance: Insurance | InsuranceDataShortDetail
) => {
  return (
    insurance?.vfcEligible && insurance.vfcCriteria === VfcCriteria.uninsured
  );
};
