import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Dimmer,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';
import {
  MainContextHightlitedStyle,
  MainContextParagraphStyle,
} from '../SharedStyles';
import { InventoryOrders } from '@bluefox/models/InventoryOrders';
import {
  Notification,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  NotificationType,
} from '@bluefox/models/Notification';
import { useApplicationState } from '@bluefox/contexts';
import { useLazyQuery } from '@apollo/client';
import { NotificationsQuery } from '@bluefox/graphql/notifications';
import OrderNotificationModalRenderMessage from './OrderNotificationModalRenderMessage';

type Props = {
  order?: InventoryOrders;
  trigger?: React.ReactNode;
  onCreate?: (notification: Notification) => {};
  onCancel?: () => {};
};

const OrderNotificationModal = ({
  order,
  trigger,
  onCancel,
  onCreate,
}: Props) => {
  const { session } = useApplicationState();
  const [open, setOpen] = useState(false);
  const [enableCreateNewNotification, setEnableCreateNewNotification] =
    useState(false);
  const [viewPreviousNotifications, setViewPreviousNotifications] =
    useState(false);

  const [getNotifications, { data, loading, error }] =
    useLazyQuery<NotificationResponseType>(NotificationsQuery);

  const onOpen = useCallback(async () => {
    setOpen(true);
    setEnableCreateNewNotification(false);
    setViewPreviousNotifications(false);
    try {
      await getNotifications({
        variables: {
          where: {
            entityRefId: { _eq: order?.id },
          },
        },
      });
    } catch (error) {}
  }, [getNotifications, order?.id]);

  const [
    notificationExist,
    notificationDoesNotExist,
    notification,
    notifications,
    areMultipleNotifications,
  ] = useMemo(() => {
    const notificationExist =
      data &&
      data.communication_notifications_aggregate.aggregate.count > 0 &&
      !enableCreateNewNotification;
    const notificationDoesNotExist =
      (data &&
        data.communication_notifications_aggregate.aggregate.count === 0) ||
      enableCreateNewNotification;

    const notifications = [...(data?.communication_notifications || [])].sort(
      (a, b) => {
        return (
          new Date(b.createdAt || 0).getTime() -
          new Date(a.createdAt || 0).getTime()
        );
      }
    );
    const areMultipleNotifications =
      data && data.communication_notifications_aggregate.aggregate.count > 1;
    const notification = notifications?.[0];
    return [
      notificationExist,
      notificationDoesNotExist,
      notification,
      notifications,
      areMultipleNotifications,
    ];
  }, [data, enableCreateNewNotification]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={onOpen}
      open={open}
      size="small"
      closeIcon
      trigger={trigger}
    >
      <Modal.Header>Order Notification</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error.message}</Message>}
        {loading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        {notificationExist && (
          <>
            {viewPreviousNotifications && (
              <div
                style={{
                  maxHeight: '60vh',
                  overflow: 'auto',
                  padding: '1rem',
                  marginBottom: '2rem',
                }}
              >
                {notifications.map((n) => (
                  <Card key={n.id} fluid>
                    <Card.Content>
                      <Card.Description>
                        <OrderNotificationModalRenderMessage
                          notification={n as Notification}
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
              </div>
            )}
            {!viewPreviousNotifications && (
              <OrderNotificationModalRenderMessage
                notification={notification as Notification}
              />
            )}
          </>
        )}
        {notificationDoesNotExist && (
          <>
            <MainContextParagraphStyle>
              Based on our records, the order{' '}
              <MainContextHightlitedStyle>
                {order?.orderNumber}
              </MainContextHightlitedStyle>{' '}
              for{' '}
              <MainContextHightlitedStyle>
                {order?.doses}
              </MainContextHightlitedStyle>{' '}
              doses of{' '}
              <MainContextHightlitedStyle>
                {order?.vaccine?.name} | {order?.vaccine?.manufacturer} |{' '}
                {order?.vaccine?.saleNdc}
              </MainContextHightlitedStyle>{' '}
              was received. Please confirm the order reception
            </MainContextParagraphStyle>
          </>
        )}
        {areMultipleNotifications && !enableCreateNewNotification && (
          <Checkbox
            label="View previous notifications"
            checked={viewPreviousNotifications}
            onChange={(_, { checked }) => {
              setViewPreviousNotifications(!!checked);
            }}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          primary
          onClick={() => {
            onCancel?.();
            setOpen(false);
          }}
        >
          Close
        </Button>
        {notificationDoesNotExist && (
          <Button
            type="submit"
            secondary
            onClick={() => {
              const notification: Notification = {
                practiceId: order?.practiceId,
                practicePatientId: undefined, // There aren't any patients for an order.
                createdBy: session?.account?.id,
                content: {
                  type: NotificationType.order,
                  orderNumber: order?.orderNumber,
                  doses: order?.doses,
                  fullVaccineName: `${order?.vaccine?.name} | ${order?.vaccine?.manufacturer} | ${order?.vaccine?.saleNdc}`,
                  vfc: order?.vfc,
                  purchaseDate: order?.date,
                },
                type: NotificationType.order,
                status: NotificationStatus.pending_approval,
                statusLog: [
                  {
                    action: NotificationStatusLogAction.creation,
                    account: session?.account,
                    updatedAt: new Date(),
                    status: NotificationStatus.pending_approval,
                  },
                ],
                entityRefId: order?.id,
              };
              onCreate?.(notification);
              setOpen(false);
            }}
          >
            Send
          </Button>
        )}
        {(notification?.status === NotificationStatus.resolved ||
          notification?.status === NotificationStatus.rejected ||
          notification?.status === NotificationStatus.dismissed) &&
          !enableCreateNewNotification && (
            <Button
              secondary
              onClick={() => setEnableCreateNewNotification(true)}
            >
              Send a new notification
            </Button>
          )}
      </Modal.Actions>
    </Modal>
  );
};

export default OrderNotificationModal;
