import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { usDollarCurrency } from '@bluefox/lib/formatters';

const headerSectionStyles = StyleSheet.create({
  section: {
    marginTop: '-40', // Move to the top of the page (which has a margin of 40)
  },
  body: {
    height: 204,
    backgroundColor: '#F5FAFE',
    color: '#16324F',
    padding: 30,
  },
  row: {
    flexDirection: 'row',
  },
  col1: {
    width: '70%',
  },
  col2: {
    width: '30%',
    textAlign: 'right',
  },
});

const invoiceDataSectionStyles = StyleSheet.create({
  section: {
    marginTop: 24,
  },
  grid: { flexDirection: 'row', marginTop: 16 },
  col: { width: '50%' },
  headerText: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16 / 14,
    color: '#16324F',
  },
  subHeaderText: {
    fontSize: 8,
    fontWeight: 500,
    lineHeight: 12 / 8,
    letterSpacing: 1,
    color: '#4E657E',
  },
  text: {
    marginTop: 4,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 12 / 10,
    letterSpacing: 0,
    color: '#16324F',
    paddingRight: 5,
  },
});

const canIdDataSectionStyles = StyleSheet.create({
  headerText: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 14,
    color: '#16324F',
    marginBottom: 4,
  },
  text: {
    fontSize: 8,
    fontWeight: 400,
    lineHeight: 16 / 8,
    color: '#16324F',
  },
});

const headerBumbleStyle = StyleSheet.create({
  section: {
    marginTop: (-56 / 3) * 2,
    marginLeft: 422,
    padding: '12 24',
    backgroundColor: '#C5E0FB',
    borderRadius: 6,
    width: 150,
    marginBottom: 10,
  },
  header: {
    fontSize: 8,
    fontWeight: 500,
    lineHeight: 12 / 8,
    letterSpacing: 1,
    color: '#4E657E',
    marginBottom: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16 / 14,
    letterSpacing: 0,
    color: '#16324F',
  },
});

export type HeaderProps = {
  logoImg?: string;
  clientLine1: string;
  clientLine2?: string;
  date: string;
  amountDue: number;
};

const Header = ({
  logoImg,
  clientLine1,
  clientLine2,
  date,
  amountDue,
}: HeaderProps) => {
  return (
    <View style={headerSectionStyles.section}>
      <View style={headerSectionStyles.body}>
        <View style={headerSectionStyles.row}>
          <View style={headerSectionStyles.col1}>
            {logoImg && (
              <Image src={logoImg} style={{ width: 146, height: 32 }} />
            )}
            <View style={invoiceDataSectionStyles.section}>
              <Text style={invoiceDataSectionStyles.headerText}>Invoice</Text>
              <View style={invoiceDataSectionStyles.grid}>
                <View style={invoiceDataSectionStyles.col}>
                  <Text style={invoiceDataSectionStyles.subHeaderText}>
                    CLIENT
                  </Text>
                  <Text style={invoiceDataSectionStyles.text}>
                    {clientLine1}
                  </Text>
                  {clientLine2 && (
                    <Text style={invoiceDataSectionStyles.text}>
                      {clientLine2}
                    </Text>
                  )}
                </View>
                <View style={invoiceDataSectionStyles.col}>
                  <Text style={invoiceDataSectionStyles.subHeaderText}>
                    DATE
                  </Text>
                  <Text style={invoiceDataSectionStyles.text}>{date}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={headerSectionStyles.col2}>
            <Text style={canIdDataSectionStyles.headerText}>Canid Inc.</Text>
            <Text style={canIdDataSectionStyles.text}>
              19 W 24th St, New York, NY 10010
            </Text>
            <Text style={canIdDataSectionStyles.text}>+1 (917) 694 6005</Text>
            <Text style={canIdDataSectionStyles.text}>info@canid.io</Text>
          </View>
        </View>
      </View>
      <View style={headerBumbleStyle.section}>
        <Text style={headerBumbleStyle.header}>AMOUNT DUE</Text>
        <Text style={headerBumbleStyle.text}>
          {usDollarCurrency(amountDue)}
        </Text>
      </View>
    </View>
  );
};

export default Header;
