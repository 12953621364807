import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import {
  InventoryThresholdsQuery,
  SaveThresholdMutation,
} from '@graphql/inventory';
import { RoutineTypes, NonRoutineTypes } from '@bluefox/store/vaccines';
import {
  Card,
  Icon,
  Menu,
  Table,
  Button,
  Container,
  Popup,
  Label,
  Modal,
  Form,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { usePractice } from '@bluefox/contexts/Practice';
import { Vaccine } from '@bluefox/models/Vaccine';

interface Threshold {
  thresholdId: string;
  vaccine: Vaccine;
  name: string;
  type: string;
  countVfcThreshold: number;
  countNoVfcThreshold: number;
  countNoVfcInventory: number;
  countVfcInventory: number;
  notes: string;
}

interface InventorySummaryQueryData {
  routine: Threshold[];
  nonRoutine: Threshold[];
}

const InventoryThresholds = () => {
  const practice = usePractice();

  const [editingThreshold, setEditingThreshold] = useState<Threshold>();
  const [openThresholdModal, setOpenThresholModal] = useState(false);

  const { data, refetch } = useQuery<InventorySummaryQueryData>(
    InventoryThresholdsQuery,
    {
      variables: {
        practiceId: practice.id,
        routineTypes: RoutineTypes,
        nonRoutineTypes: NonRoutineTypes,
      },
    }
  );

  const routineTypes = data?.routine?.reduce<{ [key: string]: Threshold[] }>(
    (acc, curr) => {
      const thresholds = acc[curr.type] ? [...acc[curr.type], curr] : [curr];
      return {
        ...acc,
        [curr.type]: thresholds,
      };
    },
    {}
  );

  const nonRoutineTypes = data?.nonRoutine?.reduce<{
    [key: string]: Threshold[];
  }>((acc, curr) => {
    const thresholds = acc[curr.type] ? [...acc[curr.type], curr] : [curr];
    return {
      ...acc,
      [curr.type]: thresholds,
    };
  }, {});

  useEffect(() => {
    if (!data) return;

    refetch();
  }, [data]);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Inventory', to: `/practices/${practice.handler}/inventory` },
        { text: 'Thresholds' },
      ]}
    >
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="list" style={{ marginRight: '0.6rem' }} />
              Inventory Thresholds
            </Card.Header>
            <Card.Description>
              <Menu>
                <Menu.Menu position="right">
                  <Menu.Item>
                    <Button
                      primary
                      content="Add Threshold"
                      onClick={() => setOpenThresholModal(true)}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <StyledColorsRefContainer>
                <StyledColorRef color="#ffffff" />
                <span style={{ marginLeft: '0.3rem' }}>
                  <b>Routine</b>
                </span>
                <StyledColorRef color="#fff7e3" />
                <span style={{ marginLeft: '0.3rem' }}>
                  <b>Non-routine</b>
                </span>
              </StyledColorsRefContainer>
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>Vaccine Type</Table.HeaderCell>
                    <Table.HeaderCell width={7}>Vaccine Name</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Commercial</Table.HeaderCell>
                    <Table.HeaderCell width={3}>VFC</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {routineTypes &&
                    nonRoutineTypes &&
                    [
                      ...Object.entries(routineTypes),
                      ...Object.entries(nonRoutineTypes),
                    ].map(([key, thresholds]) => {
                      return (
                        <React.Fragment key={key}>
                          <Table.Row
                            style={{
                              backgroundColor: RoutineTypes.includes(key)
                                ? '#ffffff'
                                : '#fff7e3',
                            }}
                          >
                            <Table.Cell
                              rowSpan={thresholds.length + 1}
                              style={{ borderBottom: '2px solid #2224261a' }}
                            >
                              <b>{key}</b>
                            </Table.Cell>
                          </Table.Row>
                          {thresholds.map((i, idx, { length }) => (
                            <Table.Row key={idx}>
                              <Table.Cell
                                selectable
                                style={{
                                  borderLeft: '1px solid #2224261a',
                                  padding: '0.5rem 0',
                                  backgroundColor: RoutineTypes.includes(key)
                                    ? '#ffffff'
                                    : '#fff7e3',
                                  borderBottom:
                                    length - 1 === idx
                                      ? '2px solid #2224261a'
                                      : null,
                                }}
                              >
                                <StyledCell>
                                  <div>
                                    {i.name}{' '}
                                    <Label
                                      basic
                                      size="tiny"
                                      content={`NDC: ${i.vaccine.saleNdc}`}
                                    />
                                  </div>
                                  <div>
                                    {!!i.notes && i?.notes?.length > 0 && (
                                      <Popup
                                        content={i.notes}
                                        on="click"
                                        trigger={
                                          <Label
                                            color="teal"
                                            size="tiny"
                                            content="Notes"
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                          />
                                        }
                                      />
                                    )}
                                    <Icon
                                      onClick={() => {
                                        setOpenThresholModal(true);
                                        setEditingThreshold(i);
                                      }}
                                      style={{
                                        marginLeft: '0.5rem',
                                        cursor: 'pointer',
                                      }}
                                      name="edit"
                                    />
                                  </div>
                                </StyledCell>
                              </Table.Cell>
                              <Table.Cell
                                selectable
                                style={{
                                  padding: '0.5rem 0',
                                  backgroundColor: RoutineTypes.includes(key)
                                    ? '#ffffff'
                                    : '#fff7e3',
                                  borderBottom:
                                    length - 1 === idx
                                      ? '2px solid #2224261a'
                                      : null,
                                }}
                              >
                                <StyledCell>
                                  <Label
                                    basic
                                    size="small"
                                    color={
                                      i.countNoVfcInventory >
                                      i.countNoVfcThreshold
                                        ? 'olive'
                                        : 'red'
                                    }
                                  >
                                    {`Doses ${
                                      i.countNoVfcInventory || 0
                                    } - Threshold ${
                                      i.countNoVfcThreshold === null
                                        ? 0
                                        : i.countNoVfcThreshold
                                    }`}
                                  </Label>
                                </StyledCell>
                              </Table.Cell>
                              <Table.Cell
                                selectable
                                style={{
                                  borderLeft: '1px solid #2224261a',
                                  padding: '0.5rem 0',
                                  backgroundColor: RoutineTypes.includes(key)
                                    ? '#ffffff'
                                    : '#fff7e3',
                                  borderBottom:
                                    length - 1 === idx
                                      ? '2px solid #2224261a'
                                      : null,
                                }}
                              >
                                <StyledCell>
                                  <Label
                                    basic
                                    size="small"
                                    color={
                                      i.countVfcInventory > i.countVfcThreshold
                                        ? 'olive'
                                        : 'red'
                                    }
                                  >
                                    {`Doses ${
                                      i.countVfcInventory || 0
                                    } - Threshold ${
                                      i.countVfcThreshold === null
                                        ? 0
                                        : i.countVfcThreshold
                                    }`}
                                  </Label>
                                </StyledCell>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </React.Fragment>
                      );
                    })}
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
        <NotesModal
          data={editingThreshold}
          open={openThresholdModal}
          onClose={() => {
            setOpenThresholModal(false);
            setEditingThreshold(undefined);
          }}
          onSave={() => refetch()}
        />
      </Container>
    </MainLayout>
  );
};

interface NotesModalProps {
  data?: Threshold;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

const NotesModal = ({ data, open, onClose, onSave }: NotesModalProps) => {
  const practice = usePractice();

  const [selectedVaccineId, setSelectedVaccineId] = useState<string>(
    data?.vaccine.id || ''
  );
  const [commercialThreshold, setCommercialThreshold] = useState<number>(
    data?.countNoVfcThreshold || 0
  );
  const [vfcThreshold, setVfcThreshold] = useState<number>(
    data?.countVfcThreshold || 0
  );
  const [notes, setNotes] = useState<string>(data?.notes || '');

  const [saveThreshold] = useMutation(SaveThresholdMutation);

  const handleUpdateSubmit = () => {
    saveThreshold({
      variables: {
        practiceId: practice.id,
        vaccineId: selectedVaccineId,
        noVfc: commercialThreshold,
        vfc: vfcThreshold,
        notes,
      },
    })
      .then(() => {
        toast({
          title: 'Threshold saved successfully',
          type: 'success',
          time: 1000,
        });
        handleOnClose();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleOnClose = () => {
    if (onSave) onSave();
    setSelectedVaccineId('');
    setCommercialThreshold(0);
    setVfcThreshold(0);
    setNotes('');
    onClose();
  };

  useEffect(() => {
    if (!data) return;
    setSelectedVaccineId(data.vaccine.id);
    setCommercialThreshold(data.countNoVfcThreshold || 0);
    setVfcThreshold(data.countVfcThreshold || 0);
    setNotes(data.notes);
  }, [data]);

  return (
    <Modal closeIcon onClose={handleOnClose} open={open}>
      <Modal.Header>{data ? 'Edit Threshold' : 'Add Threshold'}</Modal.Header>
      <Modal.Content>
        <Form id="notes-form" onSubmit={handleUpdateSubmit}>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Vaccine</label>
              <VaccinePicker
                dropdownProps={{
                  fluid: true,
                  placeholder: 'Search Vaccine',
                }}
                onChange={({ value }) => setSelectedVaccineId(value || '')}
                value={selectedVaccineId}
                fallback={false}
                disabled={!!data}
              />
            </Form.Field>
            <Form.Input
              value={commercialThreshold ?? 0}
              onChange={(_, { value }) =>
                setCommercialThreshold(parseInt(value))
              }
              fluid
              label="Commercial Threshold"
              placeholder="Commercial Threshold"
              type="number"
              min="0"
            />
            <Form.Input
              value={vfcThreshold ?? 0}
              onChange={(_, { value }) => setVfcThreshold(parseInt(value))}
              fluid
              label="VFC Threshold"
              placeholder="VFC Threshold"
              type="number"
              min="0"
            />
          </Form.Group>
          <Form.TextArea
            label="Please write your notes below"
            placeholder="Write your notes here..."
            value={notes}
            onChange={(_, { value }) => {
              setNotes(value as string);
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" type="button" onClick={handleOnClose} />
        <Button
          primary
          type="submit"
          content="Save"
          form="notes-form"
          disabled={!selectedVaccineId}
        />
      </Modal.Actions>
    </Modal>
  );
};

const StyledColorsRefContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledColorRef = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 1rem;
  border: 1px solid lightgrey;
  background-color: ${(props) => props.color};
`;

const StyledCell = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.8rem;
`;

export default InventoryThresholds;
