import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import ContractPlansListRow from './ContractPlansListTableRow';
import { VaccinePricingData } from '@screens/vaccines/VaccinesPriceList';
import { ContractPlan } from '@bluefox/models/ContractPlan';

type ContractPlansListProps = {
  contractPlans?: ContractPlan[];
  onOpenContractPlanForm: (open: boolean, contractPlan: ContractPlan) => void;
};

const ContractPlansList = ({
  contractPlans,
  onOpenContractPlanForm,
}: ContractPlansListProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
          <Table.HeaderCell>Discounts</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {contractPlans && contractPlans.length > 0 ? (
          contractPlans?.map((cp) => (
            <ContractPlansListRow
              key={cp.id}
              contractPlan={cp}
              onOpenContractPlanForm={onOpenContractPlanForm}
            />
          ))
        ) : (
          <Table.Row>
            <Table.Cell>No data</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default ContractPlansList;
