import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import { toast } from 'react-semantic-toasts';
import {
  SaveInvoicePaymentDateMutation,
  SaveInvoiceStatusMutation,
} from '@graphql/billing';
import { BillingInvoice } from '@bluefox/models/Billing';
import { Modal, Form, Button, Confirm, Header, Icon } from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';

interface InvoicePaymentDateModalProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  invoice?: BillingInvoice;
  onSave: () => void;
  invoiceStatus?: string;
}

const InvoicePaymentDateModal = ({
  open,
  onOpen,
  onClose,
  invoice,
  onSave,
  invoiceStatus,
}: InvoicePaymentDateModalProps) => {
  const [showConfirmPaymentDate, setShowConfirmPaymentDate] = useState(false);
  const [paymentDate, setPaymentDate] = useState<Date | null | undefined>(
    invoice?.paymentDate ? invoice.paymentDate : null
  );

  const [saveInvoiceStatus] = useMutation(SaveInvoiceStatusMutation);
  const [saveInvoicePaymentDate] = useMutation(SaveInvoicePaymentDateMutation);

  const handleSaveInvoicePaymentDate = () => {
    if (!invoice) return;
    saveInvoicePaymentDate({
      variables: {
        invoiceId: invoice.id,
        paymentDate: paymentDate,
      },
    })
      .then((r) => {
        toast({
          title: 'Payment date saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
        onClose();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });

    if (invoiceStatus) {
      saveInvoiceStatus({
        variables: {
          invoiceId: invoice.id,
          status: invoiceStatus,
        },
      })
        .then((r) => {
          toast({
            title: 'Status saved successfully',
            type: 'success',
            time: 1000,
          });
          onSave();
        })
        .catch((e) => {
          toast({
            title: `Callback error: ${e}`,
            type: 'error',
            time: 5000,
          });
        });
    }
  };

  function isForToday(_paymentDate?: Date | null) {
    if (!_paymentDate) return false;
    const [todayYYYYMMDD] = new Date().toISOString().split('T');
    const [paymentDate] = new Date(_paymentDate).toISOString().split('T');
    return todayYYYYMMDD === paymentDate;
  }

  useEffect(() => {
    if (!invoice) return;
    setPaymentDate(
      invoice.paymentDate
        ? new Date(moment(invoice.paymentDate).format(DateFormats.DATE))
        : undefined
    );
  }, [invoice]);

  return (
    <>
      <Modal
        size="tiny"
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        closeIcon
      >
        <Modal.Header>
          {invoice && !paymentDate ? 'Set Payment Date' : 'Edit Payment Date'}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label style={{ width: '15rem' }}>
                <b>Payment date:</b>
              </label>
              <DateTimePicker
                forFilter
                minDate={
                  invoice?.billMethod === 'auto'
                    ? moment().toDate()
                    : moment().subtract(1, 'years').toDate()
                }
                placeholderText="Payment date"
                selected={paymentDate}
                onChange={(d) => {
                  setPaymentDate(d as Date);
                }}
                dateFormat={DateFormats.DATE}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            primary
            content="Save"
            labelPosition="right"
            icon="save"
            onClick={() => {
              if (isForToday(paymentDate) && invoice?.billMethod === 'auto') {
                setShowConfirmPaymentDate(true);
                return;
              }
              handleSaveInvoicePaymentDate();
            }}
          />
        </Modal.Actions>
      </Modal>

      {invoice?.billMethod === 'auto' && (
        <Confirm
          size="tiny"
          open={showConfirmPaymentDate}
          onCancel={() => setShowConfirmPaymentDate(false)}
          onConfirm={() => {
            handleSaveInvoicePaymentDate();
            setShowConfirmPaymentDate(false);
          }}
          confirmButton={<Button negative content="Ok" />}
          content={
            <Header style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="warning sign" color="red" />
              You're setting the invoice payment date for today. This action
              will charge the customer automatically in one hour. After that,
              the invoice will no longer be editable. Press ok to confirm.
            </Header>
          }
        />
      )}
    </>
  );
};

export default InvoicePaymentDateModal;
