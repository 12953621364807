import React from 'react';
import { Tag as TagInterface } from '@bluefox/models/Tags';
import Tag from '@ui/Tags/tag';
import { Header } from 'semantic-ui-react';

interface TagsListProps {
  tags?: TagInterface[];
}
const TagsList = (props: TagsListProps) => {
  const { tags } = props;
  // Start Queries & Mutations

  // End Queries & Mutations

  return (
    <>
      <Header as="h4" content="Tags List" />
      {tags?.map((tag) => {
        return (
          <Tag name={tag.name} color={tag.color} key={`tag-${tag.name}`} />
        );
      })}
    </>
  );
};

export default TagsList;
