import { useMutation } from '@apollo/client';
import { getDaysInMonth } from '@bluefox/lib/formatters';
import {
  AddCreditCardMutation,
  CreditCardsQuery,
  UpdateCreditCardByPK,
} from '@graphql/creditCards';
import React, { useState } from 'react';
import { Button, Form, FormField, FormGroup, Modal } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { CreditCard } from '@bluefox/models/CreditCard';

interface CreditCardFormProps {
  creditCard?: CreditCard;
}

export const CreditCardForm = ({ creditCard }: CreditCardFormProps) => {
  const cleanDefaultDaysRange = creditCard?.defaultDaysRange
    ?.match(/\d+/g)
    ?.map(Number);
  const expirationDate = creditCard && new Date(creditCard?.expiration);
  const [values, setValues] = useState({
    open: false,
    start: '',
    finish: '',
    holder: '',
    firstNumber: '',
    lastFourNumbers: '',
    expirationMonth: '',
    expirationYear: '',
  });

  const today = new Date();
  const thisYear = today.getFullYear();

  const fieldsCompleted =
    !values.holder ||
    !values.firstNumber ||
    values.lastFourNumbers.length !== 4 ||
    values.expirationMonth.length !== 2 ||
    values.expirationYear.length !== 2 ||
    values.start.length !== 2 ||
    values.finish.length !== 2 ||
    Number(values.expirationYear) < Number(thisYear.toString().substr(2));

  const [addCreditCard] = useMutation(AddCreditCardMutation, {
    refetchQueries: [CreditCardsQuery],
  });

  const [saveEditedCreditCard] = useMutation(UpdateCreditCardByPK, {
    refetchQueries: [CreditCardsQuery],
  });

  const handleValues = (key: string, value: any, length?: number) => {
    const reg = new RegExp('^[0-9]+$|^$');
    const prependZeroKeys = ['start', 'finish', 'expirationMonth'];
    if (value.length === 1 && prependZeroKeys.includes(key)) {
      value = prependZeroIfNeeded(value);
    } else if (
      value.length > 2 &&
      prependZeroKeys.includes(key) &&
      value[0] === '0'
    ) {
      value = value.substr(1);
    }
    if (value === '00') {
      value = '0';
    }
    if ((!!length && value.length > length) || (!!length && !reg.test(value)))
      return;
    if (
      (key === 'expirationMonth' && Number(value) > 12) ||
      (key === 'expirationMonth' && value === '00')
    )
      return;
    if (
      (key === 'start' && Number(value) > 31) ||
      (key === 'start' && value === '00')
    )
      return;
    if (
      (key === 'finish' && Number(value) > 31) ||
      (key === 'finish' && value === '00')
    )
      return;
    setValues({ ...values, [key]: value });
  };

  const cleanAndClose = () => {
    setValues({
      open: false,
      start: '',
      finish: '',
      holder: '',
      firstNumber: '',
      lastFourNumbers: '',
      expirationMonth: '',
      expirationYear: '',
    });
  };

  const handleSubmit = () => {
    const firstTwoDigitsOfThisYear = new Date()
      .getFullYear()
      .toString()
      .substr(0, 2);
    const lastDayOfThisMonth = getDaysInMonth(
      Number(values.expirationMonth),
      Number(`${firstTwoDigitsOfThisYear}${values.expirationYear}`)
    );
    creditCard
      ? saveEditedCreditCard({
          variables: {
            object: {
              defaultDaysRange: `[${values.start},${values.finish})`,
              expiration: `${values.expirationMonth}/${lastDayOfThisMonth}/${firstTwoDigitsOfThisYear}${values.expirationYear}`,
              firstDigit: values.firstNumber,
              lastDigits: values.lastFourNumbers,
              userName: values.holder,
            },
            id: creditCard?.id,
          },
        })
          .then(() => {
            toast({
              title: 'Credit Card Saved Successfully',
              type: 'success',
              time: 1000,
            });
          })
          .catch((e) => {
            toast({
              title: `Callback error: ${e}`,
              type: 'error',
              time: 5000,
            });
          })
      : addCreditCard({
          variables: {
            object: {
              defaultDaysRange: `[${values.start},${values.finish})`,
              expiration: `${values.expirationMonth}/${lastDayOfThisMonth}/${firstTwoDigitsOfThisYear}${values.expirationYear}`,
              firstDigit: values.firstNumber,
              lastDigits: values.lastFourNumbers,
              userName: values.holder,
            },
          },
        })
          .then(() => {
            toast({
              title: 'Credit Card Added Successfully',
              type: 'success',
              time: 1000,
            });
          })
          .catch((e) => {
            toast({
              title: `Callback error: ${e}`,
              type: 'error',
              time: 5000,
            });
          });
    cleanAndClose();
  };

  const prependZeroIfNeeded = (str: string) => {
    if (str.length === 1) {
      return '0' + str;
    }
    return str;
  };

  return (
    <>
      <Modal
        open={values.open}
        onOpen={() => handleValues('open', true)}
        onClose={cleanAndClose}
        closeIcon
        size="large"
      >
        <Modal.Header>Add Credit Card</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <FormGroup widths={2}>
                <Form.Input
                  label="Start Day: "
                  type="text"
                  placeholder="Enter the day of the month to start using this card"
                  value={values.start}
                  onChange={(_, { value }) => handleValues('start', value, 2)}
                  required
                />
                <Form.Input
                  label="Finish Day: "
                  type="text"
                  placeholder="Enter the last day of the month to use this card"
                  value={values.finish}
                  onChange={(_, { value }) => handleValues('finish', value, 2)}
                  required
                />
              </FormGroup>
              <FormField>
                <Form.Input
                  label="Holder: "
                  type="text"
                  placeholder="Enter Holder of the card as it appears in it"
                  value={values.holder}
                  onChange={(_, { value }) => handleValues('holder', value)}
                  required
                />
              </FormField>
              <FormGroup widths={2}>
                <Form.Input
                  label="First Number: "
                  type="text"
                  placeholder="Enter only the first of the 16 card numbers"
                  value={values.firstNumber}
                  onChange={(_, { value }) =>
                    handleValues('firstNumber', value, 1)
                  }
                  required
                />
                <Form.Input
                  label="Last Four Numbers: "
                  type="text"
                  placeholder="Enter the last 4 digits of the 16 card numbers"
                  value={values.lastFourNumbers}
                  onChange={(_, { value }) =>
                    handleValues('lastFourNumbers', value, 4)
                  }
                  required
                />
              </FormGroup>
              <FormGroup widths={2}>
                <Form.Input
                  label="Expiration Month: "
                  type="text"
                  placeholder="Enter the month of expiration using 2 digits"
                  value={values.expirationMonth}
                  onChange={(_, { value }) =>
                    handleValues('expirationMonth', value, 2)
                  }
                  error={Number(values.expirationMonth) > 12}
                  required
                />
                <Form.Input
                  label="Expiration Year: "
                  type="text"
                  placeholder="Enter the year of expiration using 2 digits"
                  value={values.expirationYear}
                  onChange={(_, { value }) =>
                    handleValues('expirationYear', value, 2)
                  }
                  required
                />
              </FormGroup>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Close"
            onClick={() => cleanAndClose()}
          />
          <Button
            primary
            onClick={() => handleSubmit()}
            icon="save"
            content="Save Credit Card"
            disabled={fieldsCompleted}
          />
        </Modal.Actions>
      </Modal>
      <Button
        primary
        content={creditCard ? 'Edit Credit Card' : 'Add Credit Card'}
        onClick={() =>
          setValues({
            open: true,
            start: cleanDefaultDaysRange
              ? prependZeroIfNeeded(cleanDefaultDaysRange[0].toString())
              : '',
            finish: cleanDefaultDaysRange
              ? prependZeroIfNeeded(cleanDefaultDaysRange[1].toString())
              : '',
            holder: creditCard ? creditCard.userName : '',
            firstNumber: creditCard ? creditCard.firstDigit.toString() : '',
            lastFourNumbers: creditCard ? creditCard.lastDigits.toString() : '',
            expirationMonth: expirationDate
              ? prependZeroIfNeeded((expirationDate.getMonth() + 1).toString())
              : '',
            expirationYear: expirationDate
              ? expirationDate.getFullYear().toString().substr(2)
              : '',
          })
        }
      />
    </>
  );
};
