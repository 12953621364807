import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { SaveJobMutation } from '@graphql/bot';
import {
  BotReportType,
  BotNoteType,
  BotReportStatusOptions,
  BotReportAddedByOptions,
} from '@bluefox/models/Bot';
import { Button, Header, Modal, Form, Dropdown } from 'semantic-ui-react';

interface BotReportFormProps {
  data: BotReportType;
  onSave: () => void;
  onClose: () => void;
}

const BotReportForm = ({ data, onClose, onSave }: BotReportFormProps) => {
  const [note, setNote] = useState<string>(data.note.message);
  const [status, setStatus] = useState<string>(data.status);
  const [addedBy, setAddedBy] = useState<string>(data.source || '');

  const [saveJob] = useMutation<BotNoteType>(SaveJobMutation);

  const cleanAndClose = () => {
    if (onSave) onSave();
    onClose();
  };

  const handleSubmit = () => {
    saveJob({
      variables: {
        id: data.id,
        note: {
          key: data.type === 'vaccination' ? 'vaccination_note' : '',
          message: note,
        },
        status,
        source: addedBy,
      },
    })
      .then(() => {
        if (note !== undefined && note !== '') {
          toast({
            title: 'Note saved successfully',
            type: 'success',
            time: 1000,
          });
        }
        cleanAndClose();
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Modal closeIcon onClose={cleanAndClose} open>
      <Header>Edit Job</Header>
      <Modal.Content>
        <Form id="bot-report-form" onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem',
                maxWidth: '16rem',
              }}
            >
              <label>Status:</label>
              <Dropdown
                style={{ marginLeft: '0.5rem' }}
                placeholder="Select status"
                fluid
                selection
                value={status as string}
                onChange={(_, { value }) => {
                  setStatus(value as string);
                }}
                options={BotReportStatusOptions}
              />
            </Form.Field>
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem',
                maxWidth: '16rem',
              }}
            >
              <label style={{ width: '8rem' }}>Added by:</label>
              <Dropdown
                style={{ marginLeft: '0.5rem' }}
                placeholder="Select option"
                fluid
                selection
                value={addedBy as string}
                onChange={(_, { value }) => {
                  setAddedBy(value as string);
                }}
                options={BotReportAddedByOptions}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Form.TextArea
              value={note}
              onChange={(_, { value }) => setNote(value as string)}
              label="Note"
              placeholder="Note..."
              style={{ minHeight: 100 }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" content="Cancel" onClick={cleanAndClose} />
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          form="bot-report-form"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default BotReportForm;
