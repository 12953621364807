import { Redirect, Route, RouteProps } from 'react-router-dom';

interface ConditionalRouteProps extends RouteProps {
  predicate: () => boolean;
  fallbackTo: string;
}

const ConditionalRoute: React.FC<ConditionalRouteProps> = ({
  predicate,
  fallbackTo,
  children,
  ...rest
}) => {
  if (predicate()) {
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (
          <Redirect
            to={{
              pathname: fallbackTo,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default ConditionalRoute;
