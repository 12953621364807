import { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Container,
  Table,
  Header,
  Loader,
  Message,
  Button,
  Segment,
  Dropdown,
  Menu,
  Icon,
  Label,
  Popup,
  Pagination,
  Input,
  List,
  Modal,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import CustomModal from '@bluefox/ui/CustomModal';
import InsurancesForm from '@bluefox/ui/InsurancesForm';
import { usePractice } from '@bluefox/contexts/Practice';
import { InsurancesByPracticeQuery } from '@bluefox/graphql/insurances';
import { InsuranceVerificationStatus } from '@bluefox/models/Insurances';
import { PatientData } from '@bluefox/models/Patient';

interface PracticeType {
  id: string;
}

interface PatientType {
  id: string;
  practice: PracticeType;
  patientData: PatientData;
}

interface ErrorsType {
  code?: string;
  field?: string;
  description?: string;
}

interface InsuranceType {
  id: string;
  company: {
    id: string;
    name: string;
  };
  memberId: string;
  groupId: string;
  holderFirstName: string;
  holderLastName: string;
  patient: PatientType;
  primary: boolean;
  verificationResult: {
    errors?: ErrorsType[];
  };
  verificationStatus: InsuranceVerificationStatus;
  relationship: string;
}

interface InsurancesDataType {
  insurances: InsuranceType[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const statusOptions = [
  { key: 'valid', text: 'Valid', value: InsuranceVerificationStatus.valid },
  {
    key: 'invalid',
    text: 'Invalid',
    value: InsuranceVerificationStatus.invalid,
  },
  { key: 'error', text: 'Error', value: InsuranceVerificationStatus.error },
  {
    key: 'pending',
    text: 'Pending',
    value: InsuranceVerificationStatus.pending,
  },
  { key: 'failed', text: 'Failed', value: InsuranceVerificationStatus.failed },
  {
    key: 'undetermined',
    text: 'Undetermined',
    value: InsuranceVerificationStatus.undetermined,
  },
  {
    key: 'not_applicable',
    text: 'Non applicable',
    value: InsuranceVerificationStatus.not_applicable,
  },
];

const ENTRIES_PER_PAGE = 15;

const InsurancesScreen = () => {
  const practice = usePractice();

  const [status, setStatus] = useState<InsuranceVerificationStatus>(
    InsuranceVerificationStatus.error
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [criteria, setCriteria] = useState({});
  const [page, setPage] = useState(0);

  const { data, loading, error } = useQuery<InsurancesDataType>(
    InsurancesByPracticeQuery,
    {
      variables: {
        status,
        criteria,
        practiceId: practice.id,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      skip: !!searchQuery && searchQuery.length < 3,
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  useEffect(() => {
    let _criteria = {};

    if (searchQuery) {
      _criteria = {
        lookup: { _ilike: `%${searchQuery}%` },
      };
    }

    setCriteria(_criteria);
  }, [searchQuery]);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Insurances' },
      ]}
      loading={loading}
    >
      <Container fluid data-automation-id="it-claims">
        <Segment
          basic
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Header as="h1">
            <Icon name="dollar" size="huge" />
            Insurances
          </Header>
          <Menu fluid>
            <Menu.Menu position="right">
              <Menu.Item>
                <Input
                  placeholder="Search patient..."
                  icon="search"
                  value={searchQuery}
                  onChange={(_, { value }) => setSearchQuery(value)}
                />
              </Menu.Item>
              <Menu.Item>
                <Dropdown
                  onChange={(_, { value }) => {
                    setStatus(value as InsuranceVerificationStatus);
                  }}
                  placeholder="Select Status"
                  selection
                  options={statusOptions}
                  defaultValue={status}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Segment>
        {loading && <Loader />}
        {error && <Message error>{error.message}</Message>}
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Full Name</Table.HeaderCell>
              <Table.HeaderCell>Date of Birth</Table.HeaderCell>
              <Table.HeaderCell>Address</Table.HeaderCell>
              <Table.HeaderCell>Zip Code</Table.HeaderCell>
              <Table.HeaderCell>Company Name</Table.HeaderCell>
              <Table.HeaderCell>Member ID</Table.HeaderCell>
              <Table.HeaderCell>Holder</Table.HeaderCell>
              <Table.HeaderCell>Response</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.insurances?.map((i: InsuranceType) => {
              return (
                <Table.Row key={i.id}>
                  <Table.Cell>{i.patient.patientData.fullName}</Table.Cell>
                  <Table.Cell>{i.patient.patientData.birthdate}</Table.Cell>
                  <Table.Cell>
                    {!i.patient.patientData.address.street ||
                    !i.patient.patientData.address.city ||
                    !i.patient.patientData.address.state
                      ? null
                      : `${i.patient.patientData.address.street}, ${i.patient.patientData.address.city}, ${i.patient.patientData.address.state}`}
                  </Table.Cell>
                  <Table.Cell>{i.patient.patientData.address.zip}</Table.Cell>
                  <Table.Cell>{i.company?.name}</Table.Cell>
                  <Table.Cell>{i.memberId}</Table.Cell>
                  <Table.Cell>{i.relationship}</Table.Cell>
                  <Table.Cell>
                    <ResponseCellContent data={i.verificationResult.errors} />
                  </Table.Cell>
                  <Table.Cell>
                    {i.primary ? (
                      <Label
                        basic
                        content="Primary"
                        color="olive"
                        size="small"
                      />
                    ) : (
                      <Label
                        basic
                        content="Secondary"
                        color="teal"
                        size="small"
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {i.verificationStatus ===
                    InsuranceVerificationStatus.valid ? (
                      <Label
                        icon="check"
                        content="Valid"
                        color="olive"
                        size="small"
                      />
                    ) : i.verificationStatus ===
                      InsuranceVerificationStatus.error ? (
                      <Label content="Error" color="red" size="small" />
                    ) : i.verificationStatus ===
                      InsuranceVerificationStatus.invalid ? (
                      <Label content="Ivalid" color="red" />
                    ) : (
                      <Label content="Pending" />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {i.verificationStatus ===
                      InsuranceVerificationStatus.error ||
                    i.verificationStatus ===
                      InsuranceVerificationStatus.invalid ? (
                      <Popup
                        content="Edit"
                        size="small"
                        trigger={
                          <Button
                            primary
                            as={Link}
                            icon="edit"
                            size="mini"
                            to={`/practices/${practice.handler}/insurances/patients/${i.patient.id}/insurances/${i.id}`}
                          />
                        }
                      />
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>Total: {total}</Table.HeaderCell>
              <Table.HeaderCell colSpan="10" textAlign="right">
                <Pagination
                  disabled={!total || total < ENTRIES_PER_PAGE}
                  defaultActivePage={1}
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activePage }) =>
                    setPage(activePage as number)
                  }
                  totalPages={totalPages}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>

      <Route
        exact
        path={`/practices/${practice.handler}/insurances/patients/:practicePatientId/insurances/:insuranceId`}
      >
        <InsurancesForm />
      </Route>
    </MainLayout>
  );
};

const ResponseCellContent = (errors: any) => {
  const list = (
    <List>
      {errors?.data?.map((e: any, i: number) => (
        <List.Item key={i}>
          <List.Content>
            <List.Description>{`- ${e.description}`}</List.Description>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );

  return errors?.data?.length > 3 ? (
    <Modal trigger={<Button size="tiny" content="View Response" />}>
      <Modal.Header>Response</Modal.Header>
      <Modal.Content>{list}</Modal.Content>
    </Modal>
  ) : (
    list
  );
};

export default InsurancesScreen;
