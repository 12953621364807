import { Notification, NotificationType } from '@bluefox/models/Notification';
import OrderNotificationMessage from './OrderNotificationMessage';
import BillingNotificationMessage from './BillingNotificationMessage';
import VaxSyncIntegrationNotificationMessage from './VaxSyncIntegrationNotificationMessage';

type Props = {
  notification: Notification;
};

const NotificationMessage = ({ notification }: Props) => {
  switch (notification.type) {
    case NotificationType.order:
      return OrderNotificationMessage({ notification });
    case NotificationType.claim:
      return BillingNotificationMessage({ notification });
    case NotificationType.athena:
    case NotificationType.develo:
    case NotificationType.ecw:
    case NotificationType.vax_sync:
      return VaxSyncIntegrationNotificationMessage({ notification });
    default:
      return null;
  }
};

export default NotificationMessage;
