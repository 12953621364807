import { usDollarCurrency } from '@bluefox/lib/formatters';
import { View, StyleSheet, Text, Link } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const tableTitleStyles = StyleSheet.create({
  section: {
    padding: '10 8',
    borderTop: '1px solid #BCCBDC',
    display: 'flex',
    flexDirection: 'row',
  },
  practiceTitle: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  practiceName: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
});

const tableStyles = StyleSheet.create({
  table: {
    width: 'auto',
  },
  headRow: {
    padding: '0 8',
    flexDirection: 'row',
    border: '1px solid #BCCBDC',
    borderLeft: 0,
    borderRight: 0,
    backgroundColor: '#F9FAFC',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  bodyRow: {
    padding: '0 8',
    flexDirection: 'row',
    borderBottom: '1 solid #EBEFF5',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  patientCol: {
    width: '15%',
    textAlign: 'left',
  },
  insuranceCol: {
    width: '41%',
    textAlign: 'left',
  },
  dateOfServiceCol: {
    width: '12%',
    textAlign: 'left',
  },
  cptCodesCol: {
    width: '12%',
    textAlign: 'left',
  },
  paidAmountCol: {
    width: '12%',
    textAlign: 'left',
  },
  eobCol: {
    width: '8%',
    textAlign: 'left',
  },
  cell: {
    padding: '10 4',
  },
  eobLink: {
    fontSize: 8,
    fontWeight: 300,
    lineHeight: 10 / 8,
    letterSpacing: 0.25,
    color: '#0E6CC9',
  },
  total: {
    padding: '18 7',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 12,
    letterSpacing: 0.25,
    textAlign: 'right',
    color: '#16324F',
  },
});

export type PracticeClaimsTableProps = {
  key: string;
  practice: string;
  table: TableItemProps[];
  totalAmount: number;
};

export type TableItemProps = {
  key: string;
  patient: {
    name: string;
    dateOfBirth: string;
  };
  insurance: {
    name: string;
    memberId?: string;
  };
  dateOfService: string;
  cptCodes: string[];
  paidAmount: number;
  eobLink?: string;
};

const PracticeClaimsTable = ({
  practice,
  table,
  totalAmount,
  style,
}: PracticeClaimsTableProps & { style?: Style | Style[] }) => (
  <View style={style}>
    <View style={tableTitleStyles.section}>
      <Text style={tableTitleStyles.practiceTitle}>Practice: </Text>
      <Text style={tableTitleStyles.practiceName}>{practice}</Text>
    </View>
    <View style={tableStyles.table}>
      <View style={tableStyles.headRow} wrap={false}>
        <View style={tableStyles.patientCol}>
          <Text style={tableStyles.cell}>Patient</Text>
        </View>
        <View style={tableStyles.insuranceCol}>
          <Text style={tableStyles.cell}>Insurance</Text>
        </View>
        <View style={tableStyles.dateOfServiceCol}>
          <Text style={tableStyles.cell}>Date of Service</Text>
        </View>
        <View style={tableStyles.cptCodesCol}>
          <Text style={tableStyles.cell}>CPT Codes</Text>
        </View>
        <View style={tableStyles.paidAmountCol}>
          <Text style={tableStyles.cell}>Paid Amount</Text>
        </View>
        <View style={tableStyles.eobCol}>
          <Text style={tableStyles.cell}>EOB</Text>
        </View>
      </View>
      {table.map(
        ({
          key,
          patient,
          insurance,
          dateOfService,
          cptCodes,
          paidAmount,
          eobLink,
        }) => (
          <View style={tableStyles.bodyRow} key={key} wrap={false}>
            <View style={tableStyles.patientCol}>
              <View style={tableStyles.cell}>
                <Text>{patient.name}</Text>
                <Text>({patient.dateOfBirth})</Text>
              </View>
            </View>
            <View style={tableStyles.insuranceCol}>
              <View style={tableStyles.cell}>
                <Text>{insurance.name}</Text>
                {insurance.memberId && (
                  <Text>Member ID:{insurance.memberId} </Text>
                )}
              </View>
            </View>
            <View style={tableStyles.dateOfServiceCol}>
              <Text style={tableStyles.cell}>{dateOfService}</Text>
            </View>
            <View style={tableStyles.cptCodesCol}>
              <Text style={tableStyles.cell}>{cptCodes.join(', ')}</Text>
            </View>
            <View style={tableStyles.paidAmountCol}>
              <Text style={tableStyles.cell}>
                {usDollarCurrency(paidAmount)}
              </Text>
            </View>
            <View style={tableStyles.eobCol}>
              {eobLink && (
                <Link
                  src={eobLink}
                  style={{ ...tableStyles.cell, ...tableStyles.eobLink }}
                >
                  EOB
                </Link>
              )}
            </View>
          </View>
        )
      )}
    </View>
    <View>
      <Text style={tableStyles.total} wrap={false}>
        Total: {usDollarCurrency(totalAmount)}
      </Text>
    </View>
  </View>
);

export default PracticeClaimsTable;
