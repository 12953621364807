import { usDollarCurrency } from '@bluefox/lib/formatters';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const tableStyles = StyleSheet.create({
  table: {
    width: 'auto',
    marginTop: 40,
  },
  titleSection: {
    padding: '10 8',
    borderTop: '1px solid #BCCBDC',
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  headRow: {
    padding: '0 8',
    flexDirection: 'row',
    border: '1 solid #BCCBDC',
    borderLeft: 0,
    borderRight: 0,
    backgroundColor: '#F9FAFC',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  bodyRow: {
    padding: '0 8',
    flexDirection: 'row',
    borderBottom: '1 solid #EBEFF5',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  practiceCol: {
    width: '20%',
    textAlign: 'left',
  },
  quantityCol: {
    width: '15%',
    textAlign: 'center',
  },
  descriptionCol: {
    width: '35%',
    textAlign: 'left',
  },
  unitPriceCol: {
    width: '15%',
    textAlign: 'center',
  },
  subTotalCol: {
    width: '15%',
    textAlign: 'center',
  },
  cell: {
    padding: 8,
  },
  total: {
    padding: '12 16',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 12,
    letterSpacing: 0.25,
    textAlign: 'right',
  },
});

export type ExtrasTableItemProp = {
  key: string;
  practice: string;
  quantity: number;
  description: string;
  unitPrice: number;
  subtotal: number;
};

export type ExtrasTableProps = {
  table: ExtrasTableItemProp[];
  totalAmount: number;
};

const ExtrasTable = ({
  table,
  totalAmount,
  style,
}: ExtrasTableProps & { style?: Style | Style[] }) => (
  <View style={{ ...tableStyles.table, ...style }}>
    <View style={tableStyles.titleSection}>
      <Text style={tableStyles.title}>Extras</Text>
    </View>
    <View style={tableStyles.headRow} wrap={false}>
      <View style={tableStyles.practiceCol}>
        <Text style={tableStyles.cell}>Practice</Text>
      </View>
      <View style={tableStyles.quantityCol}>
        <Text style={tableStyles.cell}>Quantity</Text>
      </View>
      <View style={tableStyles.descriptionCol}>
        <Text style={tableStyles.cell}>Description</Text>
      </View>
      <View style={tableStyles.unitPriceCol}>
        <Text style={tableStyles.cell}>Unit Price</Text>
      </View>
      <View style={tableStyles.subTotalCol}>
        <Text style={tableStyles.cell}>Subtotal</Text>
      </View>
    </View>
    {table.map(
      ({ key, practice, quantity, description, unitPrice, subtotal }) => (
        <View style={tableStyles.bodyRow} key={key} wrap={false}>
          <View style={tableStyles.practiceCol}>
            <Text style={tableStyles.cell}>{practice}</Text>
          </View>
          <View style={tableStyles.quantityCol}>
            <Text style={tableStyles.cell}>{quantity}</Text>
          </View>
          <View style={tableStyles.descriptionCol}>
            <Text style={tableStyles.cell}>{description}</Text>
          </View>
          <View style={tableStyles.unitPriceCol}>
            <Text style={tableStyles.cell}>{unitPrice}</Text>
          </View>
          <View style={tableStyles.subTotalCol}>
            <Text style={tableStyles.cell}>{usDollarCurrency(subtotal)}</Text>
          </View>
        </View>
      )
    )}
    <View>
      <Text style={tableStyles.total} wrap={false}>
        Total: {usDollarCurrency(totalAmount)}
      </Text>
    </View>
  </View>
);

export default ExtrasTable;
