import React, { useState, useCallback, ReactNode } from 'react';
import { Button, Modal } from 'semantic-ui-react';

type Props = {
  open: boolean;
  header?: string | ReactNode;
  message?: string | ReactNode;
  onYes?: () => void;
  onNo?: () => void;
};

export enum ConfirmationModalReturn {
  YES = 'yes',
  NO = 'no',
}

const ConfirmationModal = ({ open, header, message, onYes, onNo }: Props) => {
  return (
    <Modal onClose={onNo} open={open} size="tiny">
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>{message}</Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => onYes?.()}>
          YES
        </Button>
        <Button secondary onClick={() => onNo?.()}>
          NO
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const useConfirmationModalAwait = () => {
  const [confirmationModal, setConfirmationModal] = useState<Props>({
    open: false,
  });
  const render = useCallback(
    () => (
      <ConfirmationModal
        open={confirmationModal?.open || false}
        header={confirmationModal?.header || ''}
        message={confirmationModal?.message || ''}
        onYes={() => {
          setConfirmationModal({ open: false });
          confirmationModal?.onYes?.();
        }}
        onNo={() => {
          setConfirmationModal({ open: false });
          confirmationModal?.onNo?.();
        }}
      />
    ),
    [confirmationModal]
  );

  const open = useCallback(
    (message: string, header?: string) =>
      new Promise<ConfirmationModalReturn>((resolve) => {
        setConfirmationModal({
          open: true,
          header,
          message,
          onYes: () => resolve(ConfirmationModalReturn.YES),
          onNo: () => resolve(ConfirmationModalReturn.NO),
        });
      }),
    [setConfirmationModal]
  );
  return {
    render,
    open,
  };
};

export default ConfirmationModal;
