import { Table, Popup, Button } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import { useMutation } from '@apollo/client';
import { DeleteMappingMutation } from '@bluefox/graphql/integrations/develo';

interface MappingRowProps {
  onEdit: () => void;
  onRefetchMappings: () => void;
  editDisabled?: boolean;
  cellItems: (string | number | undefined)[];
  mappingId: string;
}

const MappingRow = ({
  onEdit,
  onRefetchMappings,
  editDisabled,
  cellItems,
  mappingId,
}: MappingRowProps) => {
  const [deleteMapping] = useMutation(DeleteMappingMutation);

  const handleDeleteMapping = () => {
    deleteMapping({
      variables: {
        mappingId: mappingId,
      },
    })
      .then(() => {
        toast({
          title: 'Mapping deleted successfully',
          type: 'success',
          time: 1000,
        });
        onRefetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row>
      {cellItems?.map((item, i) => <Table.Cell key={i}>{item}</Table.Cell>)}
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={onEdit}
              disabled={editDisabled}
            />
          }
        />
        <Popup
          size="small"
          content="Delete"
          trigger={
            <Button
              negative
              size="mini"
              icon="trash"
              onClick={handleDeleteMapping}
              disabled={editDisabled}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default MappingRow;
