import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Dropdown, Header, Label } from 'semantic-ui-react';

import { UsersData } from '@bluefox/models/Users';
import { UsersQuery } from '@bluefox/graphql/users';
interface PrimaryCareProviderPickerProps {
  defaultValue?: string;
  onChange?: (pcpId: string) => void;
}

const PrimaryCareProviderPicker = ({
  defaultValue,
  onChange = () => {},
}: PrimaryCareProviderPickerProps) => {
  const { data, loading } = useQuery<UsersData>(UsersQuery, {
    variables: {
      criteria: {
        pcp: { _eq: true },
        suspended: { _eq: false },
      },
    },
  });

  const [selected, setSelected] = useState(defaultValue || '');

  useEffect(() => onChange(selected), [selected]);
  useEffect(() => setSelected(defaultValue || ''), [defaultValue]);
  return (
    <Dropdown
      fluid
      selection
      loading={loading}
      disabled={
        loading || !data?.practice_accounts || !data?.practice_accounts.length
      }
      onChange={(_, { value }) => setSelected(value as string)}
      value={selected}
      placeholder={
        !data?.practice_accounts ? 'No available Providers' : 'Select PCP'
      }
      clearable
      options={
        data?.practice_accounts.map((p) => ({
          key: p.id,
          value: p.id,
          text: `${p.account.firstName} ${p.account.lastName} ${p.title}`,
          content: (
            <Header size="tiny">
              <Header.Content>
                {p.account.firstName} {p.account.lastName} {p.title}
                <Header.Subheader>
                  {p.account.npi}
                  {p.suspended && (
                    <Label size="tiny" color="orange">
                      Suspended
                    </Label>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          ),
        })) || []
      }
    />
  );
};

export default PrimaryCareProviderPicker;
