import { Button, Icon, Table } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import React, { useEffect, useState } from 'react';
import InventoryAdjustmentActions from './InventoryAdjustmentActions';
import { useQuery } from '@apollo/client';
import {
  AdjustmentReason,
  AdjustmentReasons,
  InventoryAdjustmentData,
} from '@bluefox/models/InventoryAdjustment';
import { InventoryAdjustmentReasons } from '@bluefox/graphql/inventoryAdjustment';

interface InventoryAdjustmentTableProps {
  vaccines: InventoryAdjustmentData[];
  makeAdjustment: (inventory: InventoryAdjustmentData) => void;
  expired?: boolean;
  inventorySelected?: string;
  onCloseActions: () => void;
}

interface AdjustmentReasonsData {
  adjustmentReasons: AdjustmentReason[];
}

const InventoryAdjustmentTable = (props: InventoryAdjustmentTableProps) => {
  const {
    vaccines,
    makeAdjustment,
    expired,
    inventorySelected,
    onCloseActions,
  } = props;

  //States
  const [activeInventoryRow, setActiveInventoryRow] = useState<
    string | undefined | null | boolean
  >();
  //States

  //QUERIES

  const { data: adjustmentReasonData } = useQuery<AdjustmentReasonsData>(
    InventoryAdjustmentReasons,
    {
      variables: {
        type: 'Administration',
      },
    }
  );
  //QUERIES

  //LOGIC

  const adjustmentHandler = (adjustmentData: InventoryAdjustmentData) => {
    makeAdjustment(adjustmentData);
    setActiveInventoryRow(null);
    return;
  };

  const onClickAdjustmentButton = (inventoryId: string) => {
    const newActiveInventory =
      activeInventoryRow === inventoryId ? null : inventoryId;
    setActiveInventoryRow(newActiveInventory);
  };

  const onClickConfirmButton = (inventory: InventoryAdjustmentData) => {
    inventory.wasConfirmed = !inventory.wasConfirmed;
    inventory.newDoses = inventory.wasConfirmed ? inventory.doses : 0;
    inventory.adjustmentReason = inventory.isExpired
      ? AdjustmentReasons.EXPIRED
      : AdjustmentReasons.OTHER;
    makeAdjustment(inventory);
    setActiveInventoryRow(!activeInventoryRow);
    onCloseActions();
  };

  const onClickResetButton = (inventory: InventoryAdjustmentData) => {
    inventory.wasAdjusted = false;
    inventory.newDoses = undefined;
    inventory.adjustmentReason = undefined;
    inventory.wasConfirmed = undefined;
    inventory.newAdjustmentDate = undefined;
    inventory.comment = undefined;
    makeAdjustment(inventory);
    setActiveInventoryRow(!activeInventoryRow);
    onCloseActions();
  };

  const getTableCells = (inventory: InventoryAdjustmentData) => {
    const showTotalDoses = (): string => {
      if (!inventory.isExpired) {
        return `${
          inventory.newDoses === undefined
            ? inventory.doses
            : inventory.newDoses
        }`;
      }

      return inventory.doses.toString();
    };
    return (
      <>
        <Table.Cell>{inventory.name}</Table.Cell>
        <Table.Cell>{inventory.lot}</Table.Cell>
        <Table.Cell>
          {moment(inventory.expiration).format(DateFormats.DATE)}
        </Table.Cell>
        <Table.Cell>{showTotalDoses()}</Table.Cell>
        {expired && <Table.Cell>{inventory.newDoses || 0}</Table.Cell>}
        <Table.Cell>
          {inventory.lastAdjustmentDate
            ? moment(inventory.lastAdjustmentDate).format(DateFormats.DATE)
            : '-'}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {(inventory.wasConfirmed || inventory.wasAdjusted) && (
            <Icon name="check" color="olive" />
          )}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {inventory.id !== activeInventoryRow && (
            <>
              {!inventory.wasAdjusted && (
                <Button
                  style={{ margin: '0.2rem' }}
                  onClick={() => {
                    onClickConfirmButton(inventory);
                  }}
                  icon={!inventory.wasConfirmed ? 'check' : 'redo'}
                  color={!inventory.wasConfirmed ? 'teal' : 'red'}
                  size="mini"
                />
              )}

              {inventory.wasAdjusted && (
                <Button
                  icon="redo"
                  negative
                  onClick={() => {
                    onClickResetButton(inventory);
                  }}
                  primary
                  size="mini"
                />
              )}
              <Button
                style={{ margin: '0.2rem' }}
                primary
                size="mini"
                icon="edit"
                disabled={inventory.wasConfirmed}
                onClick={() => {
                  onClickAdjustmentButton(inventory.id as string);
                }}
              />
            </>
          )}
        </Table.Cell>
      </>
    );
  };
  //LOGIC

  useEffect(() => {
    setActiveInventoryRow(inventorySelected);
  }, [inventorySelected]);
  if (!vaccines.length) return null;

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>LOT</Table.HeaderCell>
          <Table.HeaderCell>EXPIRATION</Table.HeaderCell>
          <Table.HeaderCell>Doses</Table.HeaderCell>
          {expired && <Table.HeaderCell>Recovered Doses</Table.HeaderCell>}
          <Table.HeaderCell>Last Adjustment</Table.HeaderCell>
          <Table.HeaderCell width={1}>Confirmed</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {vaccines.map((inventory, index) => {
          return (
            <>
              <Table.Row
                key={`table-row-${index}`}
                negative={inventory.isExpired}
              >
                {getTableCells(inventory)}
              </Table.Row>

              {activeInventoryRow === inventory.id && (
                <Table.Row key={`row-expanded-${index}`}>
                  <Table.Cell
                    className={
                      activeInventoryRow === inventory.id
                        ? 'highlighted-border-expanded-row-cell'
                        : ''
                    }
                    colSpan="7"
                  >
                    <InventoryAdjustmentActions
                      inventory={inventory}
                      adjustmentReasons={
                        adjustmentReasonData?.adjustmentReasons
                      }
                      isActive={activeInventoryRow === inventory.id}
                      onCancelAdjustment={() => {
                        onCloseActions();
                      }}
                      makeAdjustment={adjustmentHandler}
                      onClickAddTag={() => {}}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          );
        })}
      </Table.Body>
    </Table>
  );
};
export default InventoryAdjustmentTable;
