import { usDollarNoDigitsCurrency } from '@bluefox/lib/formatters';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

const totalInvoiceStyle = StyleSheet.create({
  section: {
    marginTop: 40,
  },
  titleSection: {
    padding: '10 8',
    border: '1px solid #BCCBDC',
    borderLeft: 0,
    borderRight: 0,
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  textSection: {
    padding: '10 8',
    textAlign: 'right',
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  textHighlighted: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
});

type Props = {
  total: number;
};

const TotalInvoice = ({ total }: Props) => (
  <View style={totalInvoiceStyle.section}>
    <View style={totalInvoiceStyle.titleSection}>
      <Text style={totalInvoiceStyle.title}>Total Invoice</Text>
    </View>
    <View style={totalInvoiceStyle.textSection}>
      <Text style={totalInvoiceStyle.text}>
        Total{' '}
        <Text style={totalInvoiceStyle.textHighlighted}>
          {usDollarNoDigitsCurrency(total)}
        </Text>
      </Text>
    </View>
  </View>
);

export default TotalInvoice;
