import { gql } from '@apollo/client';

export const VaccineFullFragment = gql`
  fragment VaccineFull on vaccines {
    id
    saleNdc
    saleNdc10
    useNdc
    useNdc10
    name
    manufacturer
    types
    routes
    cvx
    mvx
    packageType
    packageDoses
    inventoryCptCodes
    allowedAssociatedVaccinations
  }
`;

export const SearchVaccinesQuery = gql`
  query SearchVaccinesQuery(
    $queryInput: String!
    $fallback: Boolean!
    $extraFilters: vaccines_bool_exp!
  ) {
    vaccines(
      where: {
        _and: [
          { fallback: { _eq: $fallback } }
          {
            _or: [
              { manufacturer: { _ilike: $queryInput } }
              { name: { _ilike: $queryInput } }
              { cvx: { _like: $queryInput } }
              { saleNdc: { _ilike: $queryInput } }
            ]
          }
          $extraFilters
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
    }
  }

  ${VaccineFullFragment}
`;

export const SelectedVaccinesQuery = gql`
  query SelectedVaccinesQuery($ids: [uuid]!) {
    vaccines(where: { id: { _in: $ids } }) {
      ...VaccineFull
    }
  }

  ${VaccineFullFragment}
`;

export const VaccineByNDC10Query = gql`
  query VaccineByNDC10Query($criteria: vaccines_bool_exp!) {
    vaccines(where: $criteria) {
      ...VaccineFull
    }
  }

  ${VaccineFullFragment}
`;

export const VaccinesByNDC10sQuery = gql`
  query VaccinesByNDC10sQuery($criteria: vaccines_bool_exp!) {
    vaccines(where: $criteria) {
      ...VaccineFull
    }
  }

  ${VaccineFullFragment}
`;

export const VaccineSelfPayPriceMutation = gql`
  mutation VaccineSelfPayPriceMutation($id: uuid!, $selfPayPrice: numeric!) {
    update_vaccines_by_pk(
      pk_columns: { id: $id }
      _set: { selfPayPrice: $selfPayPrice }
    ) {
      id
    }
  }
`;

export const VaccinesWithSelfPayPriceQuery = gql`
  query VaccinesWithSelfPayPriceQuery(
    $criteria: vaccines_bool_exp
    $limit: Int
    $offset: Int
  ) {
    aggregating: vaccines_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    vaccines(
      where: $criteria
      order_by: { name: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      manufacturer
      saleNdc
      selfPayPrice
      types
      inventoryCptCodes
    }
  }
`;

export const VaccineTypeWarningValidationMutation = gql`
  mutation VaccineTypeWarningValidationMutation(
    $practicePatientId: uuid!
    $givenAt: String!
    $vaccines: [jsonb!]!
  ) {
    vaccineTypeWarningsValidation(
      practicePatientId: $practicePatientId
      givenAt: $givenAt
      vaccines: $vaccines
    ) {
      warning
      vaccineWarnings
    }
  }
`;

export const SearchVaccinesListQuery = gql`
  query Vaccines($limit: Int, $offset: Int, $criteria: vaccines_bool_exp) {
    vaccines(limit: $limit, offset: $offset, where: $criteria) {
      id
      name
      useNdc
      saleNdc
      types
      inventoryCptCodes
    }
    vaccines_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const InsertVaccine = gql`
  mutation InsertVaccine(
    $name: String!
    $manufacturer: String!
    $fallback: Boolean
    $cvx: String!
    $mvx: String!
    $aka: String!
    $selfPayPrice: numeric
    $saleNdc: String!
    $saleNdc10: String!
    $useNdc: jsonb!
    $useNdc10: jsonb!
    $types: jsonb!
    $routes: jsonb!
    $dosage: jsonb!
    $inventoryCptCodes: jsonb!
    $administrationCptCodesWd: jsonb!
    $administrationCptCodesWod: jsonb!
    $cvxGroup: String!
  ) {
    insert_vaccines(
      objects: {
        name: $name
        manufacturer: $manufacturer
        fallback: $fallback
        cvx: $cvx
        mvx: $mvx
        aka: $aka
        selfPayPrice: $selfPayPrice
        saleNdc: $saleNdc
        saleNdc10: $saleNdc10
        useNdc: $useNdc
        useNdc10: $useNdc10
        types: $types
        routes: $routes
        dosage: $dosage
        inventoryCptCodes: $inventoryCptCodes
        administrationCptCodesWd: $administrationCptCodesWd
        administrationCptCodesWod: $administrationCptCodesWod
        cvxGroup: $cvxGroup
      }
    ) {
      affected_rows
      returning {
        id
        name
        manufacturer
        fallback
        cvx
        mvx
        aka
        selfPayPrice
        saleNdc
        saleNdc10
        useNdc
        useNdc10
        types
        routes
        dosage
        inventoryCptCodes
        administrationCptCodesWd
        administrationCptCodesWod
        cvxGroup
      }
    }
  }
`;

export const getVaccine = gql`
  query Vaccine($id: uuid!) {
    vaccines(where: { id: { _eq: $id } }) {
      id
      name
      manufacturer
      fallback
      cvx
      mvx
      aka
      selfPayPrice
      saleNdc
      saleNdc10
      useNdc
      useNdc10
      types
      routes
      dosage
      inventoryCptCodes
      administrationCptCodesWd
      administrationCptCodesWod
      cvxGroup
      active
    }
  }
`;

export const updateVaccine = gql`
  mutation UpdateVaccine(
    $id: uuid!
    $selfPayPrice: numeric
    $routes: jsonb
    $dosage: jsonb
    $inventoryCptCodes: jsonb
    $administrationCptCodesWd: jsonb
    $administrationCptCodesWod: jsonb
    $cvxGroup: String!
    $useNdc: jsonb!
    $useNdc10: jsonb!
    $active: Boolean!
  ) {
    update_vaccines(
      where: { id: { _eq: $id } }
      _set: {
        selfPayPrice: $selfPayPrice
        routes: $routes
        dosage: $dosage
        inventoryCptCodes: $inventoryCptCodes
        administrationCptCodesWd: $administrationCptCodesWd
        administrationCptCodesWod: $administrationCptCodesWod
        cvxGroup: $cvxGroup
        useNdc: $useNdc
        useNdc10: $useNdc10
        active: $active
      }
    ) {
      returning {
        id
        selfPayPrice
        routes
        dosage
        inventoryCptCodes
        administrationCptCodesWd
        administrationCptCodesWod
        cvxGroup
      }
    }
  }
`;

export const GetVisPublication = gql`
  query GetVisPublication($where: vis_publications_bool_exp) {
    visPublications: vis_publications(where: $where) {
      id
      publicationDate
      conceptCode
      conceptName
      cvx
    }
  }
`;

export const InsertVisPublication = gql`
  mutation InsertVisPublication($insertObject: vis_publications_insert_input!) {
    insert_vis_publications_one(object: $insertObject) {
      id
    }
  }
`;

export const UpdateVisPublication = gql`
  mutation UpdateVisPublication(
    $insertObject: vis_publications_insert_input!
    $updateObject: vis_publications_set_input!
    $updateId: uuid!
  ) {
    insert_vis_publications_one(object: $insertObject) {
      id
    }
    update_vis_publications_by_pk(
      pk_columns: { id: $updateId }
      _set: $updateObject
    ) {
      id
    }
  }
`;
