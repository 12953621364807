import { Button, Form, Modal } from 'semantic-ui-react';

type EditPatientNotesModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onEditNote: (note: string) => void;
  note: {
    note: string;
    index: number | null;
  };
};

const EditPatientNotesModal = ({
  open,
  onClose,
  onSave,
  onEditNote,
  note,
}: EditPatientNotesModalProps) => {
  return (
    <Modal size="tiny" onClose={onClose} open={open}>
      <Modal.Header>Edit note</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <Form.TextArea
                value={note.note}
                onChange={(_, { value }) => {
                  onEditNote(value as string);
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={onClose} />
        <Button content="Save" primary onClick={onSave} disabled={!note.note} />
      </Modal.Actions>
    </Modal>
  );
};

export default EditPatientNotesModal;
