import { pixelToRem } from '@bluefox/constants';
import { useMemo, useState } from 'react';
import {
  Checkbox,
  Dimmer,
  Form,
  Grid,
  GridColumn,
  Loader,
} from 'semantic-ui-react';
import styled from 'styled-components';
import TotalPendingBalance from './cards/TotalPendingBalance';
import InvoicesCard from './cards/InvoicesCard';
import OvertimeCard from './cards/OvertimeCard';
import { BillingDashboardInvoices } from '@graphql/billing';
import { useQuery } from '@apollo/client';
import { addDayToDate } from '@bluefox/lib/maths';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';

const StyledLabel = styled.label`
  margin: 0 ${pixelToRem(12)}rem 0 0 !important;
  display: inline !important;
  font-size: ${pixelToRem(24)}rem !important;
  font-weight: 700 !important;
  line-height: ${32 / 42} !important;
  letter-spacing: 0.25px !important;
  vertical-align: middle;
`;

type Props = {
  practiceIds: string[];
};

const Invoicing = ({ practiceIds }: Props) => {
  const [showInvoicing, setShowInvoicing] = useState(true);
  const from = useMemo(() => {
    // Get the last 6-month-data
    return formatDateToYYYYhMMhDD(addDayToDate(new Date(), -365 / 2));
  }, []);
  const { data, loading, error } = useQuery(BillingDashboardInvoices, {
    variables: {
      practiceIds: practiceIds,
      from,
    },
    skip: !practiceIds || from === undefined || !showInvoicing,
  });
  return (
    <section>
      <Form onSubmit={(e) => e.preventDefault}>
        <Form.Field>
          <StyledLabel htmlFor="invoicing-toggle">Invocing</StyledLabel>
          <Checkbox
            id="invoicing-toggle"
            toggle
            checked={showInvoicing}
            style={{ verticalAlign: 'middle' }}
            onChange={(_, value) => setShowInvoicing(value.checked as boolean)}
          />
        </Form.Field>
      </Form>
      {showInvoicing && (
        <>
          <Grid
            columns={3}
            stackable
            style={{ margin: `${pixelToRem(32)}rem -1rem` }}
          >
            <Grid.Row>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              {error && (
                <ChartErrorMessage>
                  <p>Error: {error?.message}</p>
                </ChartErrorMessage>
              )}
              {!loading && !error && data?.paid && data?.pending && (
                <>
                  <GridColumn>
                    <TotalPendingBalance pendingInvoices={data?.pending} />
                  </GridColumn>
                  <GridColumn>
                    <InvoicesCard paidInvoices={data?.paid} />
                  </GridColumn>
                  <GridColumn>
                    <OvertimeCard paidInvoices={data?.paid} />
                  </GridColumn>
                </>
              )}
            </Grid.Row>
          </Grid>
        </>
      )}
    </section>
  );
};

export default Invoicing;
