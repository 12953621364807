import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';

export function renderGivenAt(givenAt?: string | Date) {
  return givenAt
    ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
        formatDateStringToDateTimeZero(givenAt as unknown as string)
      )
    : '-';
}
