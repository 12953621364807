import React, { useState, useRef } from 'react';
import {
  Container,
  Menu,
  Button,
  Modal,
  Header,
  Input,
  Icon,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { usePractice } from '@bluefox/contexts/Practice';
import MainLayout from '@ui/MainLayout';
import Papa from 'papaparse';
import { toast } from 'react-semantic-toasts';
import { UpdateVaccinationsCsv } from '@graphql/vaccinations';
import { useMutation } from '@apollo/client';

interface CsvUploadValues {
  PATNO: string;
  VACNAME: string;
  VACDATE: string;
  ID: string;
}

interface CsvRecord {
  patno: string;
  vaccineName: string;
  vaccineDate: string;
  practiceId: string;
}

const UpdateVaccinesCsv = () => {
  const practice = usePractice();
  const [open, setOpen] = useState(false);
  const noCsvString = 'No CSV Selected';
  const [csvName, setCsvName] = useState(noCsvString);
  const [csvRecords, setCsvRecords] = useState<CsvRecord[]>([]);
  const [handlerValidation, setHandlerValidation] = useState('');

  const inputFile = useRef<HTMLInputElement>(null);

  const [insertJob] = useMutation(UpdateVaccinationsCsv);

  const handleSelectCsvClick = () => {
    inputFile.current?.click();
  };

  const handleCsv = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    setCsvName(file.name);
    Papa.parse<CsvUploadValues>(file, {
      header: true,
      skipEmptyLines: true,
      encoding: 'UTF-8',
      complete: function (results) {
        try {
          const records: CsvRecord[] = results.data.map((row) => {
            const vaccineUpload = {
              patno: row.PATNO || '',
              vaccineName: row.VACNAME,
              vaccineDate: row.VACDATE,
              practiceId: practice.id,
              idEmr: row.ID,
            };
            return vaccineUpload;
          });
          setCsvRecords(records);
        } catch (error) {
          toast({
            title: `Callback error: ${error}`,
            type: 'error',
            time: 5000,
          });
        }
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setCsvName(noCsvString);
    setHandlerValidation('');
    if (inputFile.current) {
      inputFile.current.value = '';
      inputFile.current.type = 'text';
      inputFile.current.type = 'file';
    }
  };

  const handleUploadCsvData = () => {
    insertJob({
      variables: {
        practiceId: practice.id,
        data: csvRecords,
      },
    })
      .then((r) => {
        toast({
          title: 'CSV Data Uploaded Correctly',
          type: 'success',
          time: 1000,
        });
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
    setOpen(false);
    handleClose();
  };

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Vaccine Update CSV' },
      ]}
    >
      <Container>
        <Menu
          borderless
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex' }}>
            <Menu.Item>
              <input
                type="file"
                ref={inputFile}
                style={{ display: 'none' }}
                accept=".csv"
                onChange={handleCsv}
              />
              <Button
                icon="file excel"
                content="Select CSV"
                onClick={handleSelectCsvClick}
              />
            </Menu.Item>
            <Menu.Item>{csvName}</Menu.Item>
            <Menu.Item>
              <Button
                icon="file excel"
                content="Upload CSV Data"
                onClick={() => setOpen(true)}
                disabled={csvName === noCsvString || csvRecords.length < 1}
                primary
              />
            </Menu.Item>
          </div>
          <Menu.Item>
            <Button
              as={Link}
              to={`/practices/${practice.handler}/updateVaccinationsCsvTable`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'right',
                boxShadow: 'none',
                float: 'right',
                height: '5.3rem',
              }}
              basic
            >
              <Icon name="table" size="big" style={{ margin: '0 auto 1rem' }} />
              Uploaded Jobs Table
            </Button>
          </Menu.Item>
        </Menu>
      </Container>
      <Modal
        closeIcon
        open={open}
        onClose={handleClose}
        onOpen={() => setOpen(true)}
      >
        <Header icon="upload" content="Upload Vaccinations CSV" />
        <Modal.Content>
          <p>You are about to upload {csvRecords.length} records.</p>
          <p>
            The selected practice is <strong>{practice.handler}</strong>
          </p>
          <p>Please, write the practice handler to securely upload the file</p>
          <Input
            value={handlerValidation}
            error={handlerValidation !== practice.handler.toLowerCase()}
            onChange={(_, { value }) =>
              setHandlerValidation(value.toLowerCase())
            }
            placeholder="Write here the practice handler"
            fluid
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="green"
            onClick={handleUploadCsvData}
            disabled={handlerValidation !== practice.handler.toLowerCase()}
          >
            Upload
          </Button>
        </Modal.Actions>
      </Modal>
    </MainLayout>
  );
};

export default UpdateVaccinesCsv;
