import { Table, Checkbox, Loader, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { usDollarNoDigitsCurrency } from '@bluefox/lib/formatters';
import {
  BillingInvoiceExtraItem,
  OTHER_REASONS_ENUM,
} from '@bluefox/models/Billing';
import styled from 'styled-components';

const TableScrollWrapper = styled.div`
  margin: 1rem 0;
  max-height: 20rem;
  overflow: auto;
`;

type Props = {
  extraItems?: BillingInvoiceExtraItem[];
  loading: boolean;
  onReviewedAtChange?: (extraItemId: string, reviewed: boolean) => void;
  onExtraItemEdit?: (extraItem: BillingInvoiceExtraItem) => void;
  onExtraItemDelete?: (extraItem: BillingInvoiceExtraItem) => void;
};

const ExtraItemsTables = ({
  extraItems,
  loading,
  onReviewedAtChange,
  onExtraItemEdit,
  onExtraItemDelete,
}: Props) => {
  const total =
    extraItems?.reduce(
      (accum, item) => accum + item.quantity * item.unitPrice,
      0
    ) || 0;
  return (
    <TableScrollWrapper>
      <Segment>
        <Loader active={loading} style={{ marginTop: '2rem' }} />
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Edit</Table.HeaderCell>
              <Table.HeaderCell>Delete</Table.HeaderCell>
              <Table.HeaderCell>Practice</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Unit Price</Table.HeaderCell>
              <Table.HeaderCell>Subtotal</Table.HeaderCell>
              <Table.HeaderCell>Reviewed</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {extraItems && extraItems.length > 0 ? (
              <>
                {extraItems?.map((extraItem) => (
                  <Table.Row key={extraItem.id}>
                    <Table.Cell>
                      <Link to="#" onClick={() => onExtraItemEdit?.(extraItem)}>
                        Edit
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        to="#"
                        onClick={() => onExtraItemDelete?.(extraItem)}
                      >
                        Delete
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{extraItem.practice?.name}</Table.Cell>
                    <Table.Cell>{extraItem.quantity}</Table.Cell>
                    <Table.Cell>
                      {extraItem.reason ===
                      OTHER_REASONS_ENUM['Missing Vaccines']
                        ? `Missing Vaccine: ${extraItem.inventory?.vaccine?.name} (Lot:${extraItem.inventory?.lot})`
                        : extraItem.description}
                    </Table.Cell>
                    <Table.Cell>
                      {usDollarNoDigitsCurrency(extraItem.unitPrice)}
                    </Table.Cell>
                    <Table.Cell>
                      {usDollarNoDigitsCurrency(
                        extraItem.quantity * extraItem.unitPrice
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={!!extraItem.reviewedAt}
                        onChange={(_, { checked }) => {
                          onReviewedAtChange?.(
                            extraItem.id,
                            checked as boolean
                          );
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            ) : (
              <Table.Row>
                <Table.Cell colSpan={16} style={{ background: 'white' }}>
                  No data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          {total > 0 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell
                  colSpan={6}
                  style={{
                    backgroundColor: 'white',
                  }}
                ></Table.HeaderCell>
                <Table.HeaderCell
                  colSpan={2}
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  Total: {usDollarNoDigitsCurrency(total)}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </Segment>
    </TableScrollWrapper>
  );
};

export default ExtraItemsTables;
