import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Segment, Card } from 'semantic-ui-react';
import { GetTagsResponse, Tag as TagModel } from '@bluefox/models/Tags';
import { useQuery } from '@apollo/client';
import { GetTags } from '@graphql/tags';
import Tag from '@ui/Tags/tag';

interface TagSelectorProps {
  onClickSaveTagButton?: (value: string[]) => void;
  onChange?: (value: string[]) => void;

  claimTags?: string[];
}

interface TagOption {
  key: any;
  text: string;
  value: string;
  content: any;
}

const TagsSelector = (props: TagSelectorProps) => {
  const { onClickSaveTagButton, claimTags, onChange } = props;

  const [tagSelectorOptions, setTagSelectorOptions] = useState<TagOption[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(claimTags || []);

  const {
    data: getTagsResponse,
    refetch: refetchGetTags,
    loading: loadingGetTags,
  } = useQuery<GetTagsResponse>(GetTags);

  // Start Logic
  const renderLabel = (label: any) => {
    return {
      content: label.content,
    };
  };
  // End Logic

  // Start UseEffect
  useEffect(() => {
    if (!getTagsResponse || !getTagsResponse.tags) {
      return;
    }

    const parsedTagOptions: TagOption[] = getTagsResponse.tags.reduce(
      (accum, tag, index) => {
        const parsedTag: TagOption = {
          key: index,
          text: tag.name,
          value: tag.name,
          content: <Tag name={tag.name} key={tag.id} color={tag.color} />,
        };

        // @ts-ignore
        accum.push(parsedTag);
        return accum;
      },
      []
    );

    setTagSelectorOptions(parsedTagOptions);
  }, [getTagsResponse]);

  useEffect(() => {
    if (!onChange) {
      return;
    }

    onChange(selectedTags);
  }, [selectedTags]);

  // End UseEffect

  return (
    <Card fluid style={{ display: 'flex', flexDirection: 'row' }}>
      <Dropdown
        value={selectedTags}
        multiple
        selection
        fluid
        search
        onChange={(event, data) => {
          setSelectedTags(data.value as string[]);
        }}
        renderLabel={renderLabel}
        options={tagSelectorOptions}
        placeholder="Select Tag"
      />

      {onClickSaveTagButton && (
        <Button
          primary
          onClick={() => {
            onClickSaveTagButton(selectedTags);
          }}
          icon="save"
          style={{ width: '3rem' }}
        />
      )}
    </Card>
  );
};

export default TagsSelector;
