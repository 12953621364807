import { Page, StyleSheet } from '@react-pdf/renderer';
import { HeaderProps } from './Header';
import Body, { BodyProps } from './Body';
import { FooterProps } from './Footer';

const pageStyles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'black',
    padding: '40 0',
  },
});

export type InvoicePageProps = {
  header: HeaderProps;
  body: BodyProps;
  footer?: FooterProps;
};

const InvoicePage = ({ body, header }: InvoicePageProps) => {
  return (
    <Page size="A4" style={pageStyles.page} wrap>
      {/**
       * Replace totalInvoice from Body to Header amountDue to show the
       * same value both in the header and the invoice
       */}
      <Body {...body} totalInvoice={parseFloat(header.amountDue).toFixed(2)} />
    </Page>
  );
};

export type { HeaderProps, FooterProps, BodyProps };
export default InvoicePage;
