import { Button, Icon, Popup, Segment } from 'semantic-ui-react';
import {
  Notification,
  NotificationStatus,
  NotificationType,
} from '@bluefox/models/Notification';
import { NotificationListTableRowChild } from '@bluefox/ui/Notifications/NotificationListTable/common';
import {
  notificationStatusEnumToText,
  notificationTypeEnumToText,
} from '@bluefox/constants/notifications';

function isAcceptButtonDisabled(notification?: Notification) {
  if (notification?.type === NotificationType.order)
    return notification?.status !== NotificationStatus.pending_approval;
  return (
    notification?.status !== NotificationStatus.pending_approval &&
    notification?.status !== NotificationStatus.resolved
  );
}

type Props = NotificationListTableRowChild & {
  onAccept?: (notification?: Notification) => void;
  onCancel?: (notification?: Notification) => void;
  onOpenComments?: (notification?: Notification) => void;
  onEdit?: (notification?: Notification) => void;
  showEdit?: boolean;
};

const NotificationListRowActions = ({
  notification,
  onAccept,
  onCancel,
  onOpenComments,
  onEdit,
  showEdit,
}: Props) => {
  return (
    <div>
      <Button.Group size="mini">
        <Button
          size="mini"
          icon="check"
          color="teal"
          disabled={isAcceptButtonDisabled(notification)}
          onClick={() => onAccept?.(notification)}
        />
        <Button
          size="mini"
          icon="cancel"
          color="red"
          style={{ marginLeft: '0.2rem' }}
          disabled={
            notification?.status !== NotificationStatus.pending_approval
          }
          onClick={() => onCancel?.(notification)}
        />
      </Button.Group>
      <Button.Group size="mini" style={{ marginTop: '0.2rem' }}>
        <Popup
          trigger={<Button size="mini" color="teal" icon="file code" />}
          on="click"
          pinned
          wide="very"
          position="left center"
        >
          <Segment
            color="teal"
            raised
            style={{
              overflow: 'auto',
              maxHeight: '40vh',
              maxWidth: '40hh',
            }}
          >
            <pre>{JSON.stringify(notification?.statusLog, null, 2)}</pre>
          </Segment>
        </Popup>
        <Button
          size="mini"
          icon="chat"
          color="teal"
          style={{ marginLeft: '0.2rem' }}
          onClick={() => {
            onOpenComments?.(notification);
          }}
        />
      </Button.Group>
      {showEdit && (
        <Button.Group size="mini" style={{ marginTop: '0.2rem' }}>
          <Button
            size="mini"
            icon="edit"
            color="teal"
            style={{ marginLeft: '0.2rem' }}
            disabled={
              notification?.status !== NotificationStatus.pending_approval
            }
            onClick={() => {
              onEdit?.(notification);
            }}
          />
        </Button.Group>
      )}
      <p style={{ margin: '0.25rem 0' }}>
        <b>Type:</b>{' '}
        {notificationTypeEnumToText[notification?.type as NotificationType]}
      </p>
      <p style={{ margin: '0.25rem 0' }}>
        <b>Status:</b>{' '}
        {
          notificationStatusEnumToText[
            notification?.status as NotificationStatus
          ]
        }
      </p>
      {notification?.pendingReadBy === 'IT' && (
        <p style={{ margin: '0.25rem 0' }}>
          <Icon name="flag" color="red" /> New comment
        </p>
      )}
    </div>
  );
};

export default NotificationListRowActions;
