import { MappingTypes } from '@bluefox/models/integrations/Develo';
import MappingTable from './MappingTable';

const ProviderMapping: React.FC = () => {
  return (
    <MappingTable
      mappingTitle="Provider"
      mappingType={MappingTypes.provider}
      enablePagination={false}
      hasPractice={true}
    />
  );
};

export default ProviderMapping;
