import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import {
  Card,
  Container,
  Dropdown,
  Icon,
  Input,
  Menu,
  Message,
  Pagination,
  Placeholder,
  Segment,
  Table,
} from 'semantic-ui-react';

//GraphQl
import {
  AllPracticesWithEcw,
  EcwRawHistoricalVaccinationsQuery,
  GetHistoricalVaccinationsStatusReasonOptions,
} from '@graphql/bot';
import { whereLikeInput } from '@bluefox/graphql/utils';

// UI
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import MainLayout from '@ui/MainLayout';

// Models
import { DateFormats } from '@bluefox/models/Dates';
import { RawHistoricalVaccinations } from '@bluefox/models/integrations/Ecw';
import { Practice } from '@bluefox/models/Practice';

// Lib
import { debounce } from '@bluefox/lib/debounce';

// Components
import EcwHistoricalVaccinationsRow from './EcwHistoricalVaccinationsRow';

interface PracticeOption {
  text: string;
  value: string;
  timezone: string;
}

interface HistoricalVaccinationsData {
  vaccinations: RawHistoricalVaccinations;
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

type OperatorType<T> = {
  _eq?: T;
  _gte?: T | null;
  _lte?: T;
  _ilike?: string;
};

type CriteriaType = {
  practiceId?: OperatorType<string>;
  status?: OperatorType<string>;
  createdAt?: {
    _gte?: string | null;
    _lte?: string | null;
  };
  mrn?: OperatorType<string>;
};

type DateRange = {
  from?: Date | undefined;
  to?: Date | undefined;
};

enum OrderByDirection {
  ascending = 'ascending',
  descending = 'descending',
}

const AllStatus = { text: 'All', value: 'allStatus' };

const StatusOptions = [
  AllStatus,
  { text: 'Completed', value: 'completed' },
  { text: 'Pending', value: 'pending' },
  { text: 'Error', value: 'error' },
];

const ENTRIES_PER_PAGE = 15;

const EcwHistoricalVaccinationsList = () => {
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [practiceId, setPracticeId] = useState<string>();
  const [searchPractice, setSearchPractice] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchPatientMrn, setSearchPatientMrn] = useState<string>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null>();
  const [searchToDate, setSearchToDate] = useState<Date | null>();
  const [criteria, setCriteria] = useState<CriteriaType>({});
  const [page, setPage] = useState(0);
  const [practiceTz, setPracticeTz] = useState<string | undefined>();
  const [searchVaccinationDateRange, setSearchVaccinationDateRange] =
    useState<DateRange>({
      from: undefined,
      to: undefined,
    });

  const [statusReasonOptions, setStatusReasonOptions] = useState<
    {
      [key: string]: string;
    }[]
  >();

  const [statusReason, setStatusReason] = useState<string>();

  const [createdAtDirection, setCreatedAtDirection] = useState<
    OrderByDirection | undefined
  >();

  useQuery(GetHistoricalVaccinationsStatusReasonOptions, {
    onCompleted: (data) => {
      const options = data.bot_historical_vaccinations_status_reason.map(
        ({ value, comment }: { value: string; comment: string }) => ({
          text: comment,
          value,
        })
      );
      setStatusReasonOptions([AllStatus, ...options]);
    },
  });

  const [EcwHistoricalVaccinationsQuery, { data, loading }] =
    useLazyQuery<HistoricalVaccinationsData>(
      EcwRawHistoricalVaccinationsQuery,
      {
        variables: {
          orderBy: {
            createdAt: createdAtDirection === 'ascending' ? 'asc' : 'desc',
          },
          criteria,
          limit: ENTRIES_PER_PAGE,
          offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
        },
      }
    );

  useQuery(AllPracticesWithEcw, {
    onCompleted: (data) => {
      if (!data?.allPractices || data.allPractices.length < 1) return;

      setPracticeOptions(
        data.allPractices.map((p: Practice) => {
          return {
            text: p.name,
            value: p.id,
            timezone: p.timezone,
          };
        })
      );
    },
  });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const handlePracticeValue = useCallback(
    (value: string) => {
      const practiceValue = !!value ? value : undefined;
      setSearchPractice(practiceValue);
      setPracticeId(practiceValue);

      const practice = practiceOptions?.find((p) => p.value === practiceValue);

      setPracticeTz(practice?.timezone);
    },
    [setSearchPractice, setPracticeId, practiceOptions]
  );

  const handleStatusValue = useCallback(
    (value: string) => {
      const statusValue =
        !!value && value !== AllStatus.value ? value?.toString() : undefined;
      setSearchStatus(statusValue || '');
    },
    [setSearchStatus]
  );

  const handleStatusReason = (value: string) => {
    const statusReasonValue =
      !!value && value !== AllStatus.value ? value?.toString() : undefined;
    setStatusReason(statusReasonValue);
  };

  const handleVaccinationDateRangeFrom = (date: Date | undefined) => {
    setSearchVaccinationDateRange((prev) => ({
      ...prev,
      from: date,
    }));
  };

  const handleVaccinationDateRangeTo = (date: Date | undefined) => {
    setSearchVaccinationDateRange((prev) => ({
      ...prev,
      to: date,
    }));
  };

  useEffect(() => {
    const vaccinationDateFrom = searchVaccinationDateRange?.from;
    const vaccinationDateTO = searchVaccinationDateRange?.to;

    const fromDate =
      searchFromDate &&
      DateTime.fromJSDate(searchFromDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISO();
    const toDate = DateTime.fromJSDate(searchToDate ?? new Date())
      .set({ hour: 23, minute: 59, second: 59 })
      .toISO();

    const _criteria = {
      ...(searchPractice ? { practiceId: { _eq: practiceId } } : {}),
      ...(searchStatus ? { status: { _eq: searchStatus } } : {}),
      ...(statusReason ? { statusReason: { _eq: statusReason } } : {}),
      ...(fromDate || toDate
        ? {
            createdAt: {
              _gte: fromDate,
              _lte: toDate,
            },
          }
        : {}),
      ...(vaccinationDateFrom || vaccinationDateTO
        ? {
            givenDate: {
              _gte: vaccinationDateFrom
                ? DateTime.fromJSDate(vaccinationDateFrom).toISODate()
                : undefined,
              _lte: vaccinationDateTO
                ? DateTime.fromJSDate(vaccinationDateTO).toISODate()
                : DateTime.fromJSDate(new Date()).toISODate(),
            },
          }
        : {}),
      ...(searchPatientMrn
        ? {
            mrn: {
              _ilike: whereLikeInput(searchPatientMrn),
            },
          }
        : {}),
    };

    setCriteria(_criteria);
    setPage(1);
  }, [
    searchPractice,
    searchStatus,
    searchFromDate,
    searchPatientMrn,
    searchToDate,
    searchVaccinationDateRange?.from,
    searchVaccinationDateRange?.to,
    statusReason,
  ]);

  useEffect(() => {
    if (!!searchPractice) {
      EcwHistoricalVaccinationsQuery();
    }
  }, [criteria, createdAtDirection]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <MainLayout
      path={[
        { text: 'ECW Integration', to: '/integration-ecw' },
        { text: 'Historical Vaccinations' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="list" style={{ marginRight: '0.6rem' }} />
              Historical Vaccinations
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-end',
                  }}
                >
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[...practiceOptions]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '0.5rem',
                      }}
                    >
                      <label
                        htmlFor="status-dropdown"
                        style={{ fontWeight: 'bold' }}
                      >
                        Filter by Status:
                      </label>
                      <Dropdown
                        id="status-dropdown"
                        style={{ minWidth: '15rem' }}
                        placeholder="Filter by status"
                        fluid
                        selection
                        onChange={(_, { value }) => {
                          handleStatusValue((value as string) || '');
                        }}
                        options={StatusOptions}
                      />
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '0.5rem',
                      }}
                    >
                      <label
                        htmlFor="status-reason-dropdown"
                        style={{ fontWeight: 'bold' }}
                      >
                        Filter by Status Reason:
                      </label>
                      <Dropdown
                        id="status-reason-dropdown"
                        style={{ minWidth: '15rem' }}
                        placeholder="Filter by status reason"
                        labeled
                        fluid
                        selection
                        onChange={(_, { value }) => {
                          handleStatusReason((value as string) || '');
                        }}
                        options={statusReasonOptions}
                      />
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <Input
                      style={{ paddingBottom: '.5rem' }}
                      icon="search"
                      placeholder="Search Patient MRN..."
                      onChange={(_, { value }) => {
                        debouncedRef.current?.cancel();
                        debouncedRef.current = debounce(() => {
                          setSearchPatientMrn(value);
                        }, 500);

                        debouncedRef.current();
                      }}
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'end',
                  }}
                >
                  <Menu.Item>
                    <label
                      style={{
                        marginRight: '1.2rem',
                        width: '8rem',
                        lineHeight: '1.1rem',
                      }}
                    >
                      <b>Search by date range:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From..."
                      selected={searchFromDate}
                      tz={practiceTz}
                      onChange={(d) => {
                        setSearchFromDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      placeholderText="To..."
                      selected={searchToDate}
                      tz={practiceTz}
                      onChange={(d) => {
                        setSearchToDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchToDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label
                      style={{
                        marginRight: '1.2rem',
                        width: '8rem',
                        lineHeight: '1.1rem',
                      }}
                    >
                      <b>Search by vaccinations given dates:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From..."
                      tz={practiceTz}
                      selected={searchVaccinationDateRange?.from}
                      onChange={(d) =>
                        handleVaccinationDateRangeFrom(
                          d ? (d as Date) : undefined
                        )
                      }
                      onSelect={(value) =>
                        handleVaccinationDateRangeFrom(
                          value ? (value as Date) : undefined
                        )
                      }
                      onClear={() => handleVaccinationDateRangeFrom(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      placeholderText="To..."
                      tz={practiceTz}
                      selected={searchVaccinationDateRange.to}
                      onChange={(d) => {
                        handleVaccinationDateRangeTo(
                          d ? (d as Date) : undefined
                        );
                      }}
                      onSelect={(value) =>
                        handleVaccinationDateRangeTo(
                          value ? (value as Date) : undefined
                        )
                      }
                      onClear={() => handleVaccinationDateRangeTo(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Table sortable celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Practice</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Status Reason</Table.HeaderCell>
                    <Table.HeaderCell>Patient</Table.HeaderCell>
                    <Table.HeaderCell>Vaccination</Table.HeaderCell>
                    <Table.HeaderCell>Error</Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={createdAtDirection}
                      onClick={() =>
                        setCreatedAtDirection(
                          createdAtDirection === OrderByDirection.ascending
                            ? OrderByDirection.descending
                            : OrderByDirection.ascending
                        )
                      }
                    >
                      Created At
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Segment basic>
                          <Placeholder fluid>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  ) : !!data?.vaccinations.length ? (
                    data.vaccinations.map((vaccination) => {
                      return (
                        <EcwHistoricalVaccinationsRow
                          key={vaccination.id}
                          data={vaccination}
                          onSave={EcwHistoricalVaccinationsQuery}
                        />
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Message>No Historical Vaccinations Found</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={8} textAlign="right">
                      <Pagination
                        disabled={!total || total < ENTRIES_PER_PAGE}
                        defaultActivePage={1}
                        boundaryRange={0}
                        siblingRange={1}
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage as number)
                        }
                        totalPages={totalPages}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default EcwHistoricalVaccinationsList;
