export default class GS1 {
  code: string;
  gtin: string;
  ndc?: string;
  lot?: string;
  exp?: Date;
  sn?: string;

  constructor(code: string) {
    code = code
      .trim()
      .replace(/\(01\)/, '01')
      .replace(/\\/g, '');

    let leftOver = code.toUpperCase();
    this.code = leftOver;

    [this.gtin, leftOver] = GS1.extractGtin(leftOver);
    this.ndc = GS1.extractNdc10(this.gtin);

    let spins = 0;

    while (!!leftOver) {
      if (leftOver.startsWith('(')) {
        leftOver = leftOver.slice(1, 3) + leftOver.substring(4);
      }

      const sep = leftOver.slice(0, 2);

      switch (sep) {
        case '10': {
          [this.lot, leftOver] = GS1.extractLot(leftOver, !!this.sn);
          break;
        }
        case '17': {
          [this.exp, leftOver] = GS1.extractExp(leftOver);
          break;
        }
        case '21': {
          [this.sn, leftOver] = GS1.extractSn(leftOver);
          break;
        }

        default: {
          if (leftOver.trim().slice(0, 4) === '0029') {
            leftOver = leftOver.trim().slice(4);
            break;
          }
          console.error(`Unknown delimiter "${sep}" (${leftOver})`);
          this.throwError();
        }
      }

      ++spins;

      if (spins > 3) {
        this.throwError();
      }
    }

    if (leftOver.trim().length) {
      this.throwError();
    }
  }

  private throwError() {
    throw Error(`Unable to properly parse ${this.code}. Extracted values {
      gtin: ${this.gtin}
      ndc: ${this.ndc}
      lot: ${this.lot}
      exp: ${this.exp}
      sn: ${this.sn}
    }`);
  }

  private static extractGtin(code: string) {
    const pattern = /01(\d{14})/;
    return GS1.extract(pattern, code);
  }

  private static extractNdc10(gtin: string) {
    const pattern = /03(\d{10})/;
    const match = pattern.exec(gtin);
    const [, ndc] = match ?? [];
    return ndc;
  }

  private static extractExp(code: string): [Date | undefined, string] {
    const pattern =
      /17((?:[0-9]){2}(?:0[1-9]|1[0-2]){1}(?:0[0-9]|1[0-9]|2[0-9]|3[0-1]){1})/;
    const [exp, leftOver] = GS1.extract(pattern, code);

    const expDate = GS1.parseDate(exp);

    return [expDate || undefined, leftOver];
  }

  private static parseDate(date: string): Date | null {
    let expDate: Date | null = null;

    const [yy, mm, dd] = date.match(/(\d{2})/g) ?? [];

    if (dd === '00') {
      expDate = new Date(`20${yy}-${mm}-01 00:00:00`); // create a date for that month
      expDate = new Date(expDate.getFullYear(), expDate.getMonth() + 1, 0); // get day 0 of next month (last day of previous)
    } else if (yy && mm && dd) {
      expDate = new Date(`20${yy}-${mm}-${dd} 00:00:00`);
    }

    return expDate;
  }

  private static extractLot(code: string, ignoreSn = true) {
    const pattern = ignoreSn
      ? /10([^\||\(]{5,20})/
      : /10([\w]{5,20}?)(?=\||\(|0029\w{6,20}|21\w{6,20}|\s|$)/;
    let [extracted, leftOver] = GS1.extract(pattern, code);
    if (leftOver.startsWith('|')) leftOver = leftOver.slice(1);
    return [extracted, leftOver];
  }

  private static extractSn(code: string) {
    const pattern = /21([^\||\(]{6,20})/;
    let [extracted, leftOver] = GS1.extract(pattern, code);
    if (leftOver.startsWith('|')) leftOver = leftOver.slice(1);
    return [extracted, leftOver];
  }

  private static extract(pattern: RegExp, whole: string): [string, string] {
    const match = pattern.exec(whole);
    if (!match) return ['', whole];

    const [, extracted] = match;

    // I added & character in the middle so no new fake are accidentally composed
    const leftOver =
      whole.slice(0, match.index) +
      ' ' +
      whole.slice(match.index + extracted.length + 2);

    return [extracted, leftOver.trim()];
  }

  getNdc() {
    return this.ndc;
  }

  getFormattedNdc10() {
    return this.ndc
      ? `${this.ndc?.slice(0, 5)}-${this.ndc?.slice(5, 8)}-${this.ndc?.slice(
          8,
          10
        )}`
      : undefined;
  }

  getExp() {
    return this.exp;
  }

  getLot() {
    return this.lot;
  }

  getSn() {
    return this.sn;
  }
}
