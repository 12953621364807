import { CSSProperties, useCallback, useState } from 'react';
import { Card, Dimmer, Grid, Loader } from 'semantic-ui-react';
import Legends from '@bluefox/ui/Dashboard/Legends';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';
import { usDollarNoDigitsCurrency } from '@bluefox/lib/formatters';
import { CardContent, CardHeader } from '@bluefox/ui/Dashboard/CommonStyles';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { SemanticColorToString } from '@bluefox/constants';
import ClaimDropdown, {
  DropdownOptionsToStatusQuery,
} from '@bluefox/ui/Dashboard/ClaimDropdown';
import {
  BillingDashboardClaimStatusEnum,
  DropdonwOptionsValuesType,
} from '@bluefox/models/Dashboard';
import { useQuery } from '@apollo/client';
import { BillingDashboardClaimsStatues } from '@graphql/billing';
import { ClaimStatusAmountDataType } from '../charts/ClaimStatusAmountDataType';
import ClaimsStatuesBarChart from '../charts/ClaimsStatuesBarChart';
import ClaimsStatuesPieChart from '../charts/ClaimsStatuesPieBar';
import { getPercentageOrZeroWithSign } from '@bluefox/lib/maths';

type Props = {
  practiceIds: string[];
  month: number | undefined;
  year: number | undefined;
  style?: CSSProperties;
};

type DataProps = {
  status: string;
  paidAmount: number;
  vfcEligible: boolean;
};

const legendsColorsStrings: SemanticCOLORS[] = [
  'green',
  'teal',
  'blue',
  'grey',
  'olive',
  'brown',
  'violet',
  'pink',
];

const legendsColors = legendsColorsStrings.map((color) =>
  SemanticColorToString(color)
);

type ClaimStatusesObjectDataType = ClaimStatusAmountDataType & {
  totalAmount: number;
};

type ClaimStatusesDataType = {
  private: ClaimStatusesObjectDataType;
  vfc: ClaimStatusesObjectDataType;
};

function sumClaimStatusAmounts(data: DataProps[]) {
  return data.reduce(
    (acc, { status, paidAmount }) => {
      switch (status) {
        case BillingDashboardClaimStatusEnum.PAID:
          acc.paidAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        case BillingDashboardClaimStatusEnum.PENDING_APPEALS:
          acc.pendingAppealsAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        case BillingDashboardClaimStatusEnum.IN_PROGRESS:
          acc.inProgressAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        case BillingDashboardClaimStatusEnum.IN_MEDIATION:
          acc.inMediationAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        case BillingDashboardClaimStatusEnum.ERROR_AND_REJECTED:
          acc.errorAndRejectedAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        case BillingDashboardClaimStatusEnum.RESUBMITION:
          acc.resubmitionAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        case BillingDashboardClaimStatusEnum.UNCLAIMED:
          acc.unclaimedAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        case BillingDashboardClaimStatusEnum.BORROWING:
          acc.borrowingAmount += paidAmount;
          acc.totalAmount = acc.totalAmount + paidAmount;
          break;
        default:
      }
      return acc;
    },
    {
      paidAmount: 0,
      pendingAppealsAmount: 0,
      inProgressAmount: 0,
      inMediationAmount: 0,
      errorAndRejectedAmount: 0,
      resubmitionAmount: 0,
      totalAmount: 0,
      unclaimedAmount: 0,
      borrowingAmount: 0,
    } as ClaimStatusesObjectDataType
  );
}

function getValueAndSubvalue(amount: number, totalAmount: number) {
  return {
    value: getPercentageOrZeroWithSign(amount, totalAmount),
    subValue: `(${usDollarNoDigitsCurrency(Math.round(amount))})`,
  };
}

const ClaimStatuses = ({ practiceIds, month, year, style }: Props) => {
  const [selectedClaims, setSelectedClaims] =
    useState<DropdonwOptionsValuesType>('all');
  const [claimStatusesData, setClaimStatusesData] = useState<
    ClaimStatusesDataType | undefined
  >();
  const { loading, error } = useQuery(BillingDashboardClaimsStatues, {
    variables: {
      practiceIds: practiceIds,
      month: month !== undefined ? month + 1 : undefined,
      year,
      vfcEligible: DropdownOptionsToStatusQuery[selectedClaims],
    },
    skip: !practiceIds || month === undefined || year === undefined,
    onCompleted: (data) => {
      const source: DataProps[] = data?.billing_dashboard_claims_statuses;
      const newData: ClaimStatusesDataType = {
        private: sumClaimStatusAmounts(
          source.filter(({ vfcEligible }) => vfcEligible === false)
        ),
        vfc: sumClaimStatusAmounts(
          source.filter(({ vfcEligible }) => vfcEligible === true)
        ),
      };
      setClaimStatusesData(newData);
    },
  });

  const renderData = useCallback(
    (data: ClaimStatusesDataType) => {
      const total: ClaimStatusesObjectDataType = {
        paidAmount: data.private.paidAmount + data.vfc.paidAmount,
        pendingAppealsAmount:
          data.private.pendingAppealsAmount + data.vfc.pendingAppealsAmount,
        inProgressAmount:
          data.private.inProgressAmount + data.vfc.inProgressAmount,
        inMediationAmount:
          data.private.inMediationAmount + data.vfc.inMediationAmount,
        errorAndRejectedAmount:
          data.private.errorAndRejectedAmount + data.vfc.errorAndRejectedAmount,
        resubmitionAmount:
          data.private.resubmitionAmount + data.vfc.resubmitionAmount,
        unclaimedAmount:
          data.private.unclaimedAmount + data.vfc.unclaimedAmount,
        borrowingAmount:
          data.private.borrowingAmount + data.vfc.borrowingAmount,
        totalAmount: data.private.totalAmount + data.vfc.totalAmount,
      };
      return (
        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column width={9}>
              <div style={{ marginTop: '3rem' }}>
                <Legends
                  legends={[
                    {
                      icon: {
                        name: 'clipboard outline',
                        color: legendsColorsStrings[0],
                      },
                      title: 'Paid',
                      ...getValueAndSubvalue(
                        total.paidAmount,
                        total.totalAmount
                      ),
                    },
                    {
                      icon: {
                        name: 'calendar alternate outline',
                        color: legendsColorsStrings[1],
                      },
                      title: 'Resubmition',
                      ...getValueAndSubvalue(
                        total.resubmitionAmount,
                        total.totalAmount
                      ),
                    },
                    {
                      icon: {
                        name: 'hourglass half',
                        color: legendsColorsStrings[2],
                      },
                      title: 'In Progress',
                      ...getValueAndSubvalue(
                        total.inProgressAmount,
                        total.totalAmount
                      ),
                    },
                    {
                      icon: {
                        name: 'hourglass half',
                        color: legendsColorsStrings[3],
                      },
                      title: 'Pending appeals',
                      ...getValueAndSubvalue(
                        total.pendingAppealsAmount,
                        total.totalAmount
                      ),
                    },
                    {
                      icon: {
                        name: 'ban',
                        color: legendsColorsStrings[4],
                      },
                      title: 'Errors and rejected',
                      ...getValueAndSubvalue(
                        total.errorAndRejectedAmount,
                        total.totalAmount
                      ),
                    },
                    {
                      icon: {
                        name: 'chat',
                        color: legendsColorsStrings[5],
                      },
                      title: 'In meditation',
                      ...getValueAndSubvalue(
                        total.inMediationAmount,
                        total.totalAmount
                      ),
                    },
                    {
                      icon: {
                        name: 'bell',
                        color: legendsColorsStrings[6],
                      },
                      title: 'Unclaimed',
                      ...getValueAndSubvalue(
                        total.unclaimedAmount,
                        total.totalAmount
                      ),
                    },
                    {
                      icon: {
                        name: 'handshake',
                        color: legendsColorsStrings[7],
                      },
                      title: 'Borrowing',
                      ...getValueAndSubvalue(
                        total.borrowingAmount,
                        total.totalAmount
                      ),
                    },
                  ]}
                  columns={2}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={6}>
              <div
                style={{
                  height: '100%',
                  minHeight: '250px',
                }}
              >
                {selectedClaims === 'all' && (
                  <ClaimsStatuesBarChart
                    colors={legendsColors}
                    privateData={data.private}
                    vfcData={data.vfc}
                  />
                )}
                {selectedClaims === 'private' && (
                  <>
                    {data.private.totalAmount === 0 && (
                      <ChartErrorMessage>
                        <p>There is not enough data to draw this chart</p>
                      </ChartErrorMessage>
                    )}
                    {data.private.totalAmount > 0 && (
                      <ClaimsStatuesPieChart
                        colors={legendsColors}
                        data={data.private}
                      />
                    )}
                  </>
                )}
                {selectedClaims === 'vfc' && (
                  <>
                    {data.vfc.totalAmount === 0 && (
                      <ChartErrorMessage>
                        <p>There is not enough data to draw this chart</p>
                      </ChartErrorMessage>
                    )}
                    {data.vfc.totalAmount > 0 && (
                      <ClaimsStatuesPieChart
                        colors={legendsColors}
                        data={data.vfc}
                      />
                    )}
                  </>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    },
    [selectedClaims]
  );

  return (
    <Card fluid style={style}>
      <CardContent>
        <Grid>
          <Grid.Row columns={2} style={{ padding: '0' }}>
            <Grid.Column>
              <CardHeader>Claims statutes</CardHeader>
            </Grid.Column>
            <Grid.Column style={{ display: 'flex', justifyContent: 'end' }}>
              <ClaimDropdown onChange={(value) => setSelectedClaims(value)} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        {error && (
          <ChartErrorMessage>
            <p>Error: {error?.message}</p>
          </ChartErrorMessage>
        )}
        {!loading &&
          !error &&
          claimStatusesData &&
          renderData(claimStatusesData)}
      </CardContent>
    </Card>
  );
};

export default ClaimStatuses;
