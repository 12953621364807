import { Practice, PracticeSettings } from '../Practice';

export enum MappingTypes {
  VACCINE = 'vaccine',
  VFC_ELIGIBILITY = 'vfc_eligibility',
  ADMINISTRATION_ROUTE = 'administration_route',
  ADMINISTRATION_SITE = 'administration_site',
  ADMINISTRATED_BY = 'givenby',
}

export const mappingOptions = [
  {
    key: 'vaccine',
    text: 'Vaccine',
    value: MappingTypes.VACCINE,
  },
  {
    key: 'vfcEligibility',
    text: 'VFC eligibility',
    value: MappingTypes.VFC_ELIGIBILITY,
  },
  {
    key: 'administrationRoute',
    text: 'Administration route',
    value: MappingTypes.ADMINISTRATION_ROUTE,
  },
  {
    key: 'administrationSite',
    text: 'Administration Site',
    value: MappingTypes.ADMINISTRATION_SITE,
  },
  {
    key: 'givenBy',
    text: 'Given By',
    value: MappingTypes.ADMINISTRATED_BY,
  },
];

export enum RawAppointmentStatus {
  pending = 'pending',
  completed = 'completed',
  error = 'error',
}

export const appointmentsStatusOptions = [
  {
    key: RawAppointmentStatus.pending,
    text: 'Pending',
    value: 'pending',
  },
  {
    key: RawAppointmentStatus.error,
    text: 'Error',
    value: 'error',
  },
  {
    key: RawAppointmentStatus.completed,
    text: 'Completed',
    value: 'completed',
  },
];

export enum RawHistoricalVaccinationStatus {
  pending = 'pending',
  completed = 'completed',
  error = 'error',
}

export const historicalVaccinationStatusOptions = [
  {
    key: RawHistoricalVaccinationStatus.pending,
    text: 'Pending',
    value: 'pending',
  },
  {
    key: RawHistoricalVaccinationStatus.error,
    text: 'Error',
    value: 'error',
  },
  {
    key: RawHistoricalVaccinationStatus.completed,
    text: 'Completed',
    value: 'completed',
  },
];

export interface RawInsurance {
  groupNo: string;
  suscriberNo: string;
  patientInsuranceId: string;
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  city: string;
  state: string;
  insuranceEligibilityStatus: string;
  sequenceNumber: string;
  grId: string;
  grName: string;
  grRel: string;
  active: string;
}

export interface RawPatient {
  mrn: string;
  empStatusCode: string;
  firstName: string;
  namewithsuffix: string;
  middleName: string;
  lastName: string;
  sex: string;
  email: string;
  dob: Date;
  ssn: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  doctorId: string;
  controlNo: string;
  rxConsent: string;
  selfPay: string;
  race: string;
  vfc: string;
  consent: string;
  ssnReason: string;
  receivedConsent: string;
  insuranceName: string;
  phone: string;
  mobileNumber: string;
  ethnicity: string;
}

export interface RawAppointment {
  id: string;
  encounterId: number;
  mrn: number;
  encounterDate: string;
  appointmentStatus: string;
  appointmentReason: string;
  appointmentTime: string;
  providerCode: string;
  arrivedTime: string;
  doctorId: number;
  doctorName: string;
  facilityTimeZone: string;
  facilityId: number;
  insurance: RawInsurance;
  patient: RawPatient;
  holder?: RawPatient;
  status: string;
  practiceId: string;
  jobId: string;
  practice: Practice;
  appointmentId: number;
  createdAt: Date;
  error?: AppointmentError;
}

interface AppointmentError {
  message: string;
}

interface HistoricalVaccinationError {
  message: string;
}

export type EcwRawAppointment = Pick<
  RawAppointment,
  | 'id'
  | 'appointmentId'
  | 'practice'
  | 'patient'
  | 'insurance'
  | 'encounterDate'
  | 'appointmentTime'
  | 'appointmentStatus'
  | 'mrn'
  | 'status'
  | 'createdAt'
  | 'error'
  | 'holder'
>;

const RelationshipCodes: { [key: string]: string } = {
  '1': 'Self - patient is the insured',
  '2': 'Spouse - patient is the spouse of the insured',
  '3': 'Natural Child - Insured has Financial Responsibility',
  '4': 'Natural Child - Insured does not have Financial Responsibility (includes legally adopted child)',
  '5': 'Step Child',
  '6': 'Foster Child',
  '7': 'Ward of the Court',
  '8': 'Employee',
  '9': "Unknown - patient's relationship to the insured is other than any listed",
  '10': 'Handicapped Dependent',
  '11': 'Organ Donor',
  '12': 'Cadaver Donor',
  '13': 'Grandchild',
  '14': 'Niece/Nephew',
  '15': 'Injured Plaintiff',
  '16': 'Sponsored Dependent',
  '17': 'Minor Dependent of a Minor Dependent',
  '18': 'Parent',
  '19': 'Grandparent',
  '53': 'Life Partner',
  '99': 'Other',
};

// FIXME: We need to parse it to canid insurance relationships
export const parseEcwInsuranceRelationship = (
  relationshipCode: string
): string | undefined => {
  return RelationshipCodes[relationshipCode];
};

export interface RawHistoricalVaccination {
  id: string;
  practiceId: string;
  mrn: string;
  name: string;
  givenDate: string;
  dose: string;
  location: string;
  givenBy: string;
  lotNumber: string;
  itemId: number;
  immStatus: number;
  immStatusDetail?: string;
  immId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  jobId?: string;
  practice: {
    timezone: string;
    id: string;
    settings: PracticeSettings;
    name?: string;
  };
  error?: HistoricalVaccinationError;
  statusReason?: string;
}

export type RawHistoricalVaccinations = RawHistoricalVaccination[];
export interface RawAppointmentWithHistoricalVaccinations
  extends Pick<
    RawAppointment,
    'patient' | 'jobId' | 'practiceId' | 'status' | 'mrn' | 'id'
  > {
  historicalVaccinations: Pick<
    RawHistoricalVaccination,
    'id' | 'practiceId' | 'createdAt' | 'mrn'
  >[];
}
