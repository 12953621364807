import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertMappingsMutation,
  UpdateMappingMutation,
} from '@graphql/mappings';
import { MappedItem } from '@bluefox/models/Mappings';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import { Button, Form, Dropdown } from 'semantic-ui-react';

interface PracticeData {
  id: string;
  name: string;
}

interface EcwAdministrationSiteMappingFormProps {
  close: () => void;
  practices?: PracticeData[];
  data: MappedItem | null;
  refetchMappings: () => void;
}

const EcwAdministrationSiteMappingForm = ({
  close,
  practices,
  data,
  refetchMappings,
}: EcwAdministrationSiteMappingFormProps) => {
  const [practiceId, setPracticeId] = useState(data ? data.practice.id : '');
  const [site, setSite] = useState<string>(data ? data.key : '');
  const [keyEmr, setkeyEmr] = useState<string>(
    data?.metadata[0].keyEmr ? data.metadata[0].keyEmr : ''
  );
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);

  const [saveVaccineMapping] = useMutation(
    data?.id ? UpdateMappingMutation : InsertMappingsMutation
  );

  const handleSubmit = () => {
    saveVaccineMapping({
      variables: {
        id: data?.id,
        practiceId,
        key: site,
        metadata: [
          {
            keyEmr,
          },
        ],
        type: 'site',
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!practices || practices.length < 1) return;

    setPracticeOptions(
      practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  const administrationSiteOptions = [
    {
      key: 'leftArm',
      text: 'Left Arm',
      value: 'left_arm',
    },
    {
      key: 'rightArm',
      text: 'Right Arm',
      value: 'right_arm',
    },
    {
      key: 'leftThigh',
      text: 'Left Thigh',
      value: 'left_thigh',
    },
    {
      key: 'rightThigh',
      text: 'Right Thigh',
      value: 'right_thigh',
    },
    {
      key: 'leftArmUpper',
      text: 'Left Arm Upper',
      value: 'left_arm_upper',
    },
    {
      key: 'rightArmUpper',
      text: 'Right Arm Upper',
      value: 'right_arm_upper',
    },
    {
      key: 'leftArmDeltoid',
      text: 'Left Arm Deltoid',
      value: 'left_arm_deltoid',
    },
    {
      key: 'rightArmDeltoid',
      text: 'Right Arm Deltoid',
      value: 'right_arm_deltoid',
    },
    {
      key: 'leftThighAnterolateral',
      text: 'Left Thigh Anterolateral',
      value: 'left_thigh_anterolateral',
    },
    {
      key: 'rightThighAnterolateral',
      text: 'Right Thigh Anterolateral',
      value: 'right_thigh_anterolateral',
    },
    {
      key: 'leftThighVastusLateralis',
      text: 'Left Thigh Vastus Lateralis',
      value: 'left_thigh_vastuslateralis',
    },
    {
      key: 'rightThighVastusLateralis',
      text: 'Right Thigh Vastus Lateralis',
      value: 'right_thigh_vastuslateralis',
    },
  ];

  return (
    <Form
      onSubmit={handleSubmit}
      data-automation-id="administration-site-mapping-form"
    >
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Dropdown
            label="Practice"
            style={{ minWidth: '15rem' }}
            placeholder="Select practice"
            fluid
            selection
            value={practiceId}
            onChange={(e, data) => {
              setPracticeId(data.value?.toString() || '');
            }}
            options={practiceOptions}
            required
          />
        </Form.Field>
        <Form.Field required>
          <label>Administration Site:</label>
          <Dropdown
            fluid
            selection
            placeholder="Select administration route..."
            options={administrationSiteOptions}
            value={site}
            onChange={(e, data) => {
              setSite(data.value as string);
            }}
            data-automation-id="administration-site-mapping-form-site"
            selectOnBlur={false}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={keyEmr}
            onChange={(_, { value }) => setkeyEmr(value)}
            fluid
            label="ID (EMR)"
            placeholder="ID (EMR)"
            required
            data-automation-id="administration-site-mapping-form-key-emr"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group></Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={close}
          type="button"
          data-automation-id="administration-site-mapping-form-cancel-button"
        >
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          data-automation-id="administration-site-mapping-form-save-button"
          disabled={!site}
        />
      </Form.Field>
    </Form>
  );
};

export default EcwAdministrationSiteMappingForm;
