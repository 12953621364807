import React, { ReactNode, useContext, useMemo } from 'react';

const ROOT = 'canid';

class LSHandler {
  partition: string;

  constructor(partition: string = '') {
    this.partition = partition;
  }

  setPartition(partition: string) {
    this.partition = partition;
  }

  getItem(key: string, defaultValue: string = ''): string {
    const stored = localStorage.getItem(`${ROOT}/${this.partition}/${key}`);
    return !stored || stored === 'undefined' ? defaultValue : stored;
  }

  setItem(key: string, value: string) {
    localStorage.setItem(`${ROOT}/${this.partition}/${key}`, value);
  }

  removeItem(key: string) {
    localStorage.removeItem(`${ROOT}/${this.partition}/${key}`);
  }
}

const lsContext = React.createContext<LSHandler>(new LSHandler());

export function useLSHandler() {
  return useContext(lsContext);
}

export const LocalStorageProvider = ({
  partition,
  children,
}: {
  partition: string;
  children: ReactNode;
}) => {
  const lsHandler = useMemo(() => new LSHandler(partition), [partition]);

  return <lsContext.Provider value={lsHandler}>{children}</lsContext.Provider>;
};
