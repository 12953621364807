import { ApolloProvider as ApolloClientProvider } from '@apollo/client';
import { useApollo } from '@bluefox/lib/apollo-client';
import { useApplicationState } from './ApplicationState';

export const ApolloProvider: React.FC = ({ children }) => {
  const { token } = useApplicationState();

  const apolloClient = useApollo(token || undefined);

  return (
    <ApolloClientProvider client={apolloClient}>
      {children}
    </ApolloClientProvider>
  );
};
