import { useState } from 'react';
import { Modal, Segment, Button, Icon } from 'semantic-ui-react';
import PatientForm from '@bluefox/ui/PatientForm';

interface AddPatientModalType {
  onSave?: () => void;
}

const AddPatientModal = ({ onSave }: AddPatientModalType) => {
  const [open, setOpen] = useState(false);

  const handleOnSave = () => {
    if (onSave) onSave();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      trigger={
        <Button size="small" primary floated="right">
          <Icon name="plus" />
          Add Patient
        </Button>
      }
    >
      <Modal.Content>
        <Segment>
          <PatientForm
            internalTools
            onSave={handleOnSave}
            leftActions={[
              {
                'data-automation-id': 'patient-basic-cancel',
                type: 'button',
                content: 'Cancel',
                icon: 'cancel',
                name: 'cancel',
                onClick: () => setOpen(false),
              },
            ]}
            rightActions={[
              {
                'data-automation-id': 'patient-basic-submit',
                primary: true,
                type: 'submit',
                content: 'Save',
                icon: 'save',
                name: 'save',
              },
            ]}
          />
        </Segment>
      </Modal.Content>
    </Modal>
  );
};
export default AddPatientModal;
