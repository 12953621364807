import React, { useEffect, useRef, useState } from 'react';
import { BorrowingCase, BorrowingProps, STEPS } from '@bluefox/models/models';
import { Button, Card, Modal } from 'semantic-ui-react';
import BorrowingSelector from './borrowingSelector';
import { InventoryAdjustmentData } from '@bluefox/models/InventoryAdjustment';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { toast } from 'react-semantic-toasts';

const Borrowing = (props: BorrowingProps) => {
  const {
    adjustmentData,
    inconsistencies,
    nextButtonClicked,
    onsFinishBorrowing,
    currentStep,
  } = props;

  const [alternativesSelected, setAlternativeSelected] = useState<
    BorrowingCase[]
  >([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const containsAll = (arr1: string[], arr2: string[]): boolean =>
    arr2.every((arr2Item) => arr1.includes(arr2Item));

  const sameMembers = (arr1: string[], arr2: string[]): boolean =>
    containsAll(arr1, arr2) && containsAll(arr2, arr1);

  const alternatives = inconsistencies.reduce((accum, currentInconsistency) => {
    const alternatives = adjustmentData.filter(
      (adjustment) =>
        adjustment.isPrivate === currentInconsistency.inventory?.vfc &&
        !adjustment.isExpired &&
        (adjustment.name === currentInconsistency.inventory?.vaccine.name ||
          sameMembers(
            adjustment.types || [],
            currentInconsistency.inventory.vaccine.types || []
          ))
    );

    if (alternatives.length === 0) {
      return accum;
    }

    const borrow = {
      inconsistency: currentInconsistency,
      alternatives,
    };
    return [...accum, borrow];
  }, [] as BorrowingCase[]);

  const onSelectAlternative = (
    alternative: InventoryAdjustmentData,
    inconsistency: VFCInconsistency
  ) => {
    setAlternativeSelected((prevValue) => {
      const repeatedCaseIndex = prevValue.findIndex(
        (currentBorrowingCase) =>
          currentBorrowingCase.inconsistency.id === inconsistency.id
      );

      if (repeatedCaseIndex === -1) {
        const borrowingCase: BorrowingCase = {
          inconsistency: inconsistency,
          alternatives: [alternative],
        };
        return [...prevValue, borrowingCase];
      }

      const _prevValue = [...prevValue];
      _prevValue[repeatedCaseIndex].alternatives = [alternative];
      return _prevValue;
    });
  };
  const validateProcess = () =>
    alternatives.length === alternativesSelected.length;

  const onNextButtonClicked = () => {
    if (currentStep !== STEPS.BORROWING) {
      return;
    }
    if (!validateProcess()) {
      toast({
        title: 'Please Solve all Borrowing cases',
        type: 'error',
        time: 5000,
      });
      return;
    }
    setShowConfirmationModal(true);
  };

  const firstLoad = useRef(0);
  useEffect(() => {
    if (firstLoad.current === 0) {
      firstLoad.current++;
      return;
    }
    if (nextButtonClicked === undefined) {
      return;
    }
    onNextButtonClicked();
  }, [nextButtonClicked]);

  return (
    <Card.Group>
      <Card fluid>
        <Card.Header>
          <BorrowingSelector
            onSelectAlternative={onSelectAlternative}
            borrowingCases={alternatives}
          />
        </Card.Header>
      </Card>

      <Modal
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        open={showConfirmationModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>Apply Adjustment?</Modal.Header>
        <Modal.Content>This action cannot be reversed.</Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Apply"
            onClick={() => {
              onsFinishBorrowing(alternativesSelected);
              setShowConfirmationModal(false);
            }}
          />
          <Button
            type="button"
            content="Cancel"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
          />
        </Modal.Actions>
      </Modal>
    </Card.Group>
  );
};

export default Borrowing;
