import { useState, useEffect } from 'react';
import { Table, List, Loader, Dimmer, Segment } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import { useApplicationState } from '@bluefox/contexts/ApplicationState';
import { TicketFiles } from '@bluefox/models/Tickets';

interface FileUrls {
  fileId: string;
  url: string;
}
interface DownloadFileResponse {
  message?: string;
  status: string;
  data: FileUrls[];
}

const TicketFilesTable: React.FC<{ ticketFiles: TicketFiles[] }> = ({
  ticketFiles,
}) => {
  const { token } = useApplicationState();

  const [filesUrls, setFilesUrls] = useState<FileUrls[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchFilesUrls = async (filesIds: string[]) => {
    if (!Boolean(filesIds.length)) return;

    const commaSeparatedIds = filesIds.join(',');
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_STORAGE}/download?filesIds=${commaSeparatedIds}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          mode: 'cors',
        }
      );

      const jsonData: DownloadFileResponse = await response.json();
      setFilesUrls(jsonData.data);
    } catch (err) {
      console.error('Some error happened fetching files urls: ', err);
      toast({
        title: 'An error has occurred while downloading files.',
        type: 'error',
        time: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filesIds = ticketFiles?.map(
      ({ fileId }: { fileId: string }) => fileId
    );

    fetchFilesUrls(filesIds);
  }, []);

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> Attached Files </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Cell width={1}>
            <List>
              {loading ? (
                <Segment basic textAlign="center">
                  <Dimmer active inverted>
                    <Loader inverted />
                  </Dimmer>
                </Segment>
              ) : (
                <>
                  {filesUrls?.map(({ fileId, url }, index: number) => (
                    <List.Item key={fileId}>
                      <List.Content>
                        <List.Header
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                            e.stopPropagation()
                          }
                          as="a"
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Screenshot {index + 1}
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  ))}
                </>
              )}
            </List>
          </Table.Cell>
        </Table.Body>
      </Table>
    </>
  );
};

export default TicketFilesTable;
