import React, { useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Table,
  Label,
  Button,
  Modal,
  Form,
  Dropdown,
  Menu,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import Pagination from '@bluefox/ui/Pagination';
import { Vaccination, VaccinationRoutes } from '@bluefox/models/Vaccination';
import {
  SetVaccinationInventoryMutation,
  InconsistentVaccinationsQuery,
  InconsistentVaccinationErrorEnumQuery,
  VaccinationExistsQuery,
} from '@graphql/vaccinations';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import GS1 from '@bluefox/lib/gs1';
import { useCallback, useState } from 'react';
import InventoryPicker from '../InventoryPicker';
import { Inventory } from '@bluefox/models/Inventory';
import { DateFormats } from '@bluefox/models/Dates';
import RowsPerPage from '@ui/Filters/RowsPerPage';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import { InventoryVaccinesByTypesQuery } from '@graphql/inventory';
import { generateRangeByDate } from '@bluefox/lib/dates';
import { VaccinationRoutePicker } from '@bluefox/ui/VaccinationRoutePicker';
import { humanizeText } from '@bluefox/lib/humanize';

interface InconsistentVaccinationsData {
  vaccinations: Vaccination[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface InconsistentVaccinationsProps {
  practiceId?: string;
}

interface InconsistentVaccinationError {
  value: string;
  comment: string;
}

interface InconsistentVaccinationErrorOption {
  value: string;
  text: string;
}

interface InconsistentVaccinationErrorEnum {
  inconsistentVaccinationErrorsEnum: InconsistentVaccinationError[];
}

function getScannedData(barcode: string) {
  try {
    const gs1 = new GS1(barcode);
    return {
      lot: gs1.getLot(),
      exp: gs1.getExp(),
      ndc: gs1.getNdc(),
    };
  } catch {
    return null;
  }
}

const HomeInconsistentVaccinations = (props: InconsistentVaccinationsProps) => {
  const [searchPractice, setSearchPractice] = useState<string>('all');
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [selectedVaccination, setSelectedVaccination] = useState<Vaccination>();
  const [errorsEnumOptions, setErrorsEnumOptions] = useState<
    InconsistentVaccinationErrorOption[]
  >([]);

  const { data, refetch } = useQuery<InconsistentVaccinationsData>(
    InconsistentVaccinationsQuery,
    {
      variables: {
        criteria: {
          historic: { _eq: false },
          practiceId: searchPractice !== 'all' ? { _eq: searchPractice } : {},
          _or: [
            { inventoryId: { _is_null: true } },
            { vaccineId: { _is_null: true } },
          ],
        },
        limit: rowsPerPage,
        offset: !!page ? rowsPerPage * (page - 1) : 0,
      },
      skip: rowsPerPage === 0,
      fetchPolicy: 'network-only',
    }
  );

  const { data: errorsEnum } = useQuery<InconsistentVaccinationErrorEnum>(
    InconsistentVaccinationErrorEnumQuery,
    {
      skip: rowsPerPage === 0,
      fetchPolicy: 'network-only',
    }
  );

  const handleOnSave = () => {
    refetch();
    setSelectedVaccination(undefined);
  };

  useEffect(() => {
    if (!errorsEnum) return;

    setErrorsEnumOptions(
      errorsEnum?.inconsistentVaccinationErrorsEnum?.map((e) => {
        return {
          text: e.comment,
          value: e.value,
        };
      })
    );
  }, [errorsEnum]);

  useEffect(() => {
    const rows = Number(
      localStorage.getItem('HomeInconsistentVaccinationsRowConfig')
    );
    setRowsPerPage(rows >= 0 ? rows : 15);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchPractice, rowsPerPage]);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / rowsPerPage);

  return (
    <>
      <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item>
            <RowsPerPage
              rows={rowsPerPage}
              setRows={setRowsPerPage}
              localStorageItem="HomeInconsistentVaccinationsRowConfig"
            />
          </Menu.Item>
          {rowsPerPage > 0 && (
            <>
              <Menu.Item>
                <PracticeFilter
                  practiceSearch={searchPractice}
                  setPracticeSearch={setSearchPractice}
                  suspended="notSuspended"
                />
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      {rowsPerPage > 0 && (
        <>
          <Table style={{ height: '100%', overflowY: 'auto' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Practice</Table.HeaderCell>
                <Table.HeaderCell>Vaccine</Table.HeaderCell>
                <Table.HeaderCell>Scanned Data</Table.HeaderCell>
                <Table.HeaderCell>Given At</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data?.vaccinations.map((v) => {
                const scannedData = v.barcode
                  ? getScannedData(v.barcode)
                  : null;
                return (
                  <Table.Row key={v.id}>
                    <Table.Cell>{v.practice?.name}</Table.Cell>
                    <Table.Cell>{v.vaccine?.name} </Table.Cell>
                    <Table.Cell>
                      {scannedData ? (
                        <Label>
                          Lot: {scannedData.lot}
                          <Label.Detail>
                            Exp:{' '}
                            <Moment format="MM-DD-YYYY">
                              {scannedData.exp}
                            </Moment>
                          </Label.Detail>
                        </Label>
                      ) : (
                        v.barcode
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Moment format="MM-DD-YYYY hh:mm a">{v.givenAt}</Moment>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size="mini"
                        basic
                        icon="edit"
                        content="Set inventory"
                        onClick={() => setSelectedVaccination(v)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
            <Pagination
              total={total}
              colSpan={11}
              position="right"
              activePage={page}
              totalPages={totalPages}
              onPageChange={(newActivePage) => setPage(newActivePage)}
            />
          </Table>
          {!!selectedVaccination && (
            <SetInventoryModal
              vaccination={selectedVaccination}
              onSave={handleOnSave}
              onClose={() => setSelectedVaccination(undefined)}
              errorsEnumOptions={errorsEnumOptions}
            />
          )}
        </>
      )}
    </>
  );
};

//--------------------------------SetInventoryModal--------------------------------//

interface SetInventoryModalProps {
  vaccination: Vaccination;
  onSave?: () => void;
  onClose?: () => void;
  errorsEnumOptions: InconsistentVaccinationErrorOption[];
}

const SetInventoryModal = ({
  vaccination,
  onSave,
  onClose,
  errorsEnumOptions,
}: SetInventoryModalProps) => {
  const [inventory, setInventory] = useState<Inventory>();
  const [vaccinationRoute, setVaccinationRoute] = useState(vaccination.route);
  const [routeOptions, setRouteOptions] = useState<VaccinationRoutes[]>();
  const [
    inconsistentVaccinationErrorValue,
    setInconsistentVaccinationErrorValue,
  ] = useState<string>();
  const [notesValue, setNotesValue] = useState<string>();

  const [setVaccinationInventory, { loading }] = useMutation(
    SetVaccinationInventoryMutation
  );

  const [checkVaccinationExists] = useLazyQuery(VaccinationExistsQuery, {
    fetchPolicy: 'network-only',
  });

  const [getInventoryVaccinesByTypes] = useLazyQuery(
    InventoryVaccinesByTypesQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleSave = useCallback(async () => {
    if (!inventory) return;

    try {
      const { data: inventoriesResponse } = await getInventoryVaccinesByTypes({
        variables: {
          practiceId: vaccination?.practice?.id,
          types: inventory.vaccine.types,
        },
      });

      if (inventory.vaccine.allowedAssociatedVaccinations === 0) {
        const vaccineIds = inventoriesResponse.inventories.map(
          (inv: { vaccineId: string }) => inv.vaccineId
        );

        const { from, to } = generateRangeByDate(vaccination.givenAt);

        const params = {
          vaccinationId: vaccination.id,
          from,
          to,
          practicePatientId: vaccination.practicePatient?.id,
          vaccineIds,
        };

        const { data: vaccinationExists } = await checkVaccinationExists({
          variables: params,
        });

        if (vaccinationExists.vaccination.aggregate.count > 0) {
          toast({
            title: 'Vaccination already exists',
            type: 'warning',
            time: 1000,
          });

          return;
        }
      }

      await setVaccinationInventory({
        variables: {
          vaccinationId: vaccination.id,
          inventoryId: inventory.id,
          vaccineId: inventory.vaccineId,
          route: vaccinationRoute,
          inconsistentVaccinationError: inconsistentVaccinationErrorValue,
          ...(notesValue
            ? {
                notes: {
                  ...vaccination.notes,
                  inconsistency: notesValue,
                },
              }
            : {}),
        },
      });

      toast({
        title: 'Inventory Set Successfully',
        type: 'success',
        time: 1000,
      });

      if (onSave) onSave();
    } catch (error: unknown) {
      console.log(error);
      toast({
        type: 'error',
        title: 'Error',
        description: `There was an error setting the inventory: ${
          error instanceof Error ? error.message : String(error)
        }`,
      });
    }
  }, [
    inventory,
    vaccination,
    setVaccinationInventory,
    onSave,
    inconsistentVaccinationErrorValue,
    notesValue,
  ]);

  const scannedData = vaccination.barcode
    ? getScannedData(vaccination.barcode)
    : null;

  return (
    <>
      <Modal size="small" open={true} onClose={onClose} closeOnEscape={false}>
        <Modal.Header>Set Inventory</Modal.Header>
        <Modal.Content>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={2}>
                  Vaccination Information
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Practice
                </Table.Cell>
                <Table.Cell>{vaccination.practice?.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Patient
                </Table.Cell>
                <Table.Cell>
                  {vaccination.practicePatient?.patientData.lastName},{' '}
                  {vaccination.practicePatient?.patientData.firstName} / DOB:{' '}
                  {vaccination.practicePatient?.patientData.birthdate}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Vaccine
                </Table.Cell>
                <Table.Cell>{vaccination.vaccine?.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Given at
                </Table.Cell>
                <Table.Cell>
                  <Moment format="MM-DD-YYYY hh:mm a">
                    {vaccination.givenAt}
                  </Moment>
                </Table.Cell>
              </Table.Row>
              {!!scannedData ? (
                <>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      Lot
                    </Table.Cell>
                    <Table.Cell>{scannedData.lot}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      Expiration
                    </Table.Cell>
                    <Table.Cell>
                      {moment(scannedData.exp).format(DateFormats.DATE)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      NDC
                    </Table.Cell>
                    <Table.Cell>{scannedData.ndc}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      Site
                    </Table.Cell>
                    <Table.Cell>
                      {vaccination.site
                        ? humanizeText(vaccination.site, {
                            capitalize: 'first',
                            delimiter: '_',
                          })
                        : '-'}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      Route
                    </Table.Cell>
                    <Table.Cell>
                      {inventory ? (
                        <VaccinationRoutePicker
                          routes={routeOptions}
                          defaultValue={
                            inventory?.vaccine.routes
                              ? inventory.vaccine.routes[0]
                              : vaccination.route
                          }
                          onChange={(route: VaccinationRoutes) =>
                            setVaccinationRoute(route)
                          }
                        />
                      ) : (
                        <Button
                          size="tiny"
                          icon="edit"
                          content={vaccination.route}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                </>
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={2} warning>
                    Wasn't able to get the scanned information (
                    {vaccination.barcode})
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {!!vaccination?.practice && (
            <Form>
              <Form.Field required>
                <label>Pick an inventory entry</label>
                <InventoryPicker
                  practiceId={vaccination?.practice?.id}
                  onSelect={(inventory) => {
                    setInventory(inventory);
                    setRouteOptions(inventory?.vaccine.routes);
                    setVaccinationRoute(
                      inventory?.vaccine.routes
                        ? inventory.vaccine.routes[0]
                        : vaccination.route
                    );
                  }}
                />
              </Form.Field>
              <Form.Field required>
                <label>Select why this was an inconsistent vaccination</label>
                <Dropdown
                  labeled
                  fluid
                  selection
                  options={[
                    {
                      text: 'Select one error option',
                      value: '',
                    },
                    ...errorsEnumOptions,
                  ]}
                  value={inconsistentVaccinationErrorValue}
                  onChange={(e, data) => {
                    setInconsistentVaccinationErrorValue(
                      data.value?.toString() || ''
                    );
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Notes</label>
                <Form.TextArea
                  placeholder="There are no notes for this inconsistency. You can add one. Detail 'Other' error here."
                  value={notesValue}
                  onChange={(_, { value }) => {
                    setNotesValue(value as string);
                  }}
                />
              </Form.Field>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Save"
            icon="save"
            onClick={handleSave}
            disabled={
              !inventory || loading || !inconsistentVaccinationErrorValue
            }
            loading={loading}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default HomeInconsistentVaccinations;
