import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertGuardianMutation,
  UpdateGuardianMutation,
} from '@bluefox/graphql/guardians';
import { Button, Form } from 'semantic-ui-react';
import PhoneInput from './PhoneInput';
import DateTimePicker from './DateTimePicker';
import {
  GuardianPatient,
  GuardianRelationships,
} from '@bluefox/models/Guardian';
import { usePractice } from '@bluefox/contexts';
import moment from 'moment-timezone';
import { PracticePatientByIdQuery } from '@bluefox/graphql/patients';
import { Sex } from '@bluefox/models/Patient';
import { Address } from '@bluefox/models/Address';
import StatePicker from './StatePicker';

interface FormValues {
  firstName: string;
  lastName: string;
  birthdate: Date | undefined;
  email: string;
  phoneNumber: string;
  relationship: GuardianRelationships | undefined;
  sex: string;
  address?: Address;
}

type GuardianFormProps = {
  practicePatientId?: string;
  practicePatientTz?: string | undefined;
  patientAge: number;
  guardianPatient: GuardianPatient | null;
  patientId: string;
  onClose: () => void;
  onSave: () => void;
};

const initialFormValues = {
  firstName: '',
  lastName: '',
  birthdate: undefined,
  email: '',
  phoneNumber: '',
  relationship: undefined,
  sex: '',
  address: {} as Address,
};

const GuardianForm = ({
  practicePatientId,
  practicePatientTz,
  patientAge,
  guardianPatient,
  patientId,
  onClose,
  onSave,
}: GuardianFormProps) => {
  const practice = usePractice();
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  const [saveguardian] = useMutation(
    guardianPatient ? UpdateGuardianMutation : InsertGuardianMutation
  );

  const handleSubmit = () => {
    if (!formValues.sex) {
      toast({
        title: `Please select sex`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    if (!formValues.phoneNumber) {
      toast({
        title: `Please provide a phone number for guardian`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    if (!formValues.relationship) {
      toast({
        title: `Please select realtionship with patient`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    try {
      saveguardian({
        variables: guardianPatient
          ? {
              guardianId: guardianPatient.guardianId,
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              birthdate: formValues.birthdate,
              email: formValues.email,
              phone: formValues.phoneNumber,
              sex: formValues.sex,
              address: formValues.address,
            }
          : {
              patientId,
              relationship: formValues.relationship,
              guardianData: {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                birthdate: formValues.birthdate,
                email: formValues.email,
                phone: formValues.phoneNumber,
                sex: formValues.sex,
                address: formValues.address,
              },
            },
        refetchQueries: [
          {
            query: PracticePatientByIdQuery(false),
            variables: {
              id: practicePatientId,
            },
          },
        ],
      });

      toast({
        title: `Guardian ${guardianPatient ? 'updated' : 'saved'} successfully`,
        type: 'success',
        time: 1000,
      });

      onSave();
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });

      onSave();
    }
  };

  useEffect(() => {
    if (!guardianPatient) return;

    setFormValues({
      firstName: guardianPatient.guardian.firstName,
      lastName: guardianPatient.guardian.lastName,
      birthdate: guardianPatient.guardian.birthdate,
      sex: guardianPatient.guardian.sex,
      email: guardianPatient.guardian.email,
      phoneNumber: guardianPatient.guardian.phone,
      relationship: guardianPatient.relationship,
      address: guardianPatient.guardian.address,
    });
  }, [guardianPatient]);

  return (
    <>
      <Form id="guardian-form" onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            data-automation-id="guardian-form-firstname"
            value={formValues.firstName || ''}
            onChange={(_, { value }) =>
              setFormValues({ ...formValues, firstName: value })
            }
            label="First name"
            placeholder="Guardian first name"
            required
          />
          <Form.Input
            data-automation-id="guardian-form-lastname"
            value={formValues.lastName || ''}
            onChange={(_, { value }) =>
              setFormValues({ ...formValues, lastName: value })
            }
            label="Last name"
            placeholder="Guardian last name"
            required
          />
          <Form.Field required>
            <label>Date of Birth </label>
            <DateTimePicker
              isClearable
              tz={
                practice.timezone === '' ? practicePatientTz : practice.timezone
              }
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  birthdate: value ? (value as Date) : undefined,
                });
              }}
              selected={
                formValues.birthdate
                  ? moment(formValues.birthdate).toDate()
                  : undefined
              }
              dataAutomationId="guardian-form-date-of-birth"
              scrollableYearDropdown
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              maxDate={new Date()}
              required
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            data-automation-id={`guardian-form-sex`}
            value={formValues.sex}
            onChange={(_, { value }) =>
              setFormValues({ ...formValues, sex: value as Sex })
            }
            fluid
            label="Sex"
            options={[
              { text: 'Female', value: Sex.Female, icon: 'venus' },
              { text: 'Male', value: Sex.Male, icon: 'mars' },
              { text: 'Unknown', value: Sex.Unknown, icon: 'genderless' },
            ]}
            placeholder="Select sex..."
            required
          />
          <Form.Input
            data-automation-id="guardian-form-email"
            value={formValues.email || ''}
            onChange={(_, { value }) =>
              setFormValues({ ...formValues, email: value })
            }
            type="email"
            label="Email"
            placeholder="Email"
          />
          <Form.Field required>
            <label>Phone</label>
            <PhoneInput
              value={formValues.phoneNumber || ''}
              onChange={(value) =>
                setFormValues({ ...formValues, phoneNumber: value })
              }
              data-automation-id="guardian-form-phone-number"
            />
          </Form.Field>
          <Form.Dropdown
            data-automation-id="guardian-form-relationship"
            fluid
            selection
            placeholder="Select relationship..."
            value={formValues.relationship}
            onChange={(_, { value }) =>
              setFormValues({
                ...formValues,
                relationship: value as GuardianRelationships,
              })
            }
            label="Relationship with patient"
            options={[
              { text: 'Father', value: GuardianRelationships.FATHER },
              { text: 'Mother', value: GuardianRelationships.MOTHER },
              { text: 'Self', value: GuardianRelationships.SELF },
              { text: 'Other', value: GuardianRelationships.OTHER },
            ]}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            width={4}
            data-automation-id={`guardian-form-street`}
            value={formValues.address?.street || ''}
            onChange={(_, { value }) =>
              setFormValues({
                ...formValues,
                address: {
                  ...formValues.address,
                  street: value,
                } as Address,
              })
            }
            label="Street"
            placeholder="Street &amp; number"
          />
          <Form.Input
            width={4}
            data-automation-id={`guardian-form-city`}
            value={formValues.address?.city || ''}
            onChange={(_, { value }) =>
              setFormValues({
                ...formValues,
                address: {
                  ...formValues.address,
                  city: value,
                } as Address,
              })
            }
            label="City"
            placeholder="City"
          />
          <Form.Field width={4}>
            <label>State</label>
            <StatePicker
              fluid
              data-automation-id={`guardian-form-state`}
              value={formValues.address?.state || ''}
              onChange={(value) =>
                setFormValues({
                  ...formValues,
                  address: {
                    ...formValues.address,
                    state: value,
                  } as Address,
                })
              }
            />
          </Form.Field>
          <Form.Input
            width={4}
            data-automation-id={`guardian-form-zip`}
            value={formValues.address?.zip || ''}
            onChange={(_, { value }) =>
              setFormValues({
                ...formValues,
                address: {
                  ...formValues.address,
                  zip: value,
                } as Address,
              })
            }
            label="Zip code"
            placeholder="10010"
          />
        </Form.Group>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button content="Cancel" onClick={onClose} />
        <Button
          content="Save"
          icon="save"
          primary
          form="guardian-form"
          disabled={false}
        />
      </div>
    </>
  );
};

export default GuardianForm;
