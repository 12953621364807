import { gql } from '@apollo/client';

export const InsertGuardianMutation = gql`
  mutation InsertGuardianMutation(
    $patientId: uuid!
    $relationship: String
    $guardianData: guardians_insert_input!
  ) {
    insert_guardians_patients_one(
      object: {
        patientId: $patientId
        relationship: $relationship
        guardian: { data: $guardianData }
      }
    ) {
      id
      guardianId
      patientId
      relationship
    }
  }
`;

export const UpdateGuardianMutation = gql`
  mutation UpdateGuardianMutation(
    $guardianId: uuid!
    $firstName: String
    $lastName: String
    $birthdate: date
    $email: String
    $phone: String
    $sex: String
    $address: jsonb
  ) {
    update_guardians_by_pk(
      pk_columns: { id: $guardianId }
      _set: {
        firstName: $firstName
        lastName: $lastName
        birthdate: $birthdate
        email: $email
        phone: $phone
        sex: $sex
        address: $address
      }
    ) {
      id
      firstName
      lastName
      birthdate
      email
      phone
    }
  }
`;

export const SetActiveGuardianMutation = gql`
  mutation SetActiveGuardianMutation(
    $guardianPatientId: uuid!
    $active: Boolean
  ) {
    update_guardians_patients_by_pk(
      pk_columns: { id: $guardianPatientId }
      _set: { active: $active }
    ) {
      id
      active
    }
  }
`;
