export const setDropdownBackgroundColor = (value: any) => {
  if (value === 'completed') return '#fcfff5';
  if (value === 'pending') return '#f2f3f3';
  if (value === 'error') return '#fff6f6';
  if (value === 'finished') return '#eaf7fa';
};

export const setDropdownBorderColor = (value: any) => {
  if (value === 'completed') return '#558555';
  if (value === 'pending') return '#6d7878';
  if (value === 'error') return '#f5714b';
  if (value === 'finished') return '#32aecd';
};
