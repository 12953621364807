import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { MappedOutOfNetwork } from '@bluefox/models/Mappings';
import {
  InsertOutOfNetworkMappingsMutation,
  UpdateOutOfNetworkMappingsMutation,
} from '@graphql/mappings';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import ProviderPicker from '@bluefox/ui/ProviderPicker';
import styled from 'styled-components';
import { UsersQuery } from '@bluefox/graphql/users';

type OutOfNetworkMappingFormProps = {
  close: () => void;
  practiceId?: string;
  data: MappedOutOfNetwork | undefined;
  refetchMappings: () => void;
};

const OutOfNetworkMappingForm = ({
  close,
  practiceId,
  data,
  refetchMappings,
}: OutOfNetworkMappingFormProps) => {
  const [providerId, setProviderId] = useState(data?.practiceAccount?.id);
  const [accountId, setAccountId] = useState<string>(
    data?.practiceAccount?.account.id || ''
  );
  const [npi, setNpi] = useState<string>(data?.npi || '');
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<
    string | undefined
  >(data?.insuranceCompany ? data?.insuranceCompany.id : undefined);
  const [notes, setNotes] = useState<string>(data?.notes || '');

  const [openConfirm, setOpenConfirm] = useState(false);

  const [saveOutOfNetworkMapping] = useMutation(
    data?.id
      ? UpdateOutOfNetworkMappingsMutation
      : InsertOutOfNetworkMappingsMutation
  );

  const handleSubmit = async () => {
    try {
      await saveOutOfNetworkMapping({
        variables: {
          id: data?.id,
          practiceId,
          accountId,
          providerId,
          npi,
          insuranceCompanyId,
          notes,
        },
        refetchQueries: [
          {
            query: UsersQuery,
            variables: {
              criteria: {
                practiceId: { _eq: practiceId },
                suspended: {
                  _eq: false,
                },
              },
            },
          },
        ],
      });
      toast({
        title: 'Mapping saved successfully',
        type: 'success',
        time: 1000,
      });
    } catch (error) {
      return toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      await refetchMappings();
      close();
    }
  };

  const isSameNpi = data?.npi ? npi === data?.npi : true;

  const handleSaveBtnClick = () => {
    if (!isSameNpi) {
      setOpenConfirm(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            <b>Provider</b>
          </label>
          <ProviderPicker
            defaultValue={data?.practiceAccount?.id}
            placeholder="Select Provider"
            practiceId={practiceId}
            onChange={(providerId) => setProviderId(providerId)}
            onProviderSelected={(provider) => {
              setNpi(provider.account.npi);
              setAccountId(provider.account.id);
            }}
          />
        </Form.Field>
        <Form.Field required>
          <Form.Input
            value={npi}
            onChange={(_, { value }) => setNpi(value)}
            fluid
            label="NPI"
            placeholder="NPI"
          />
        </Form.Field>
        <Form.Field>
          <label>
            <b>Insurance</b>
          </label>
          <InsuranceCompanyPicker
            placeholder="Search insurance..."
            includePayerId
            value={insuranceCompanyId}
            onChange={(value) => {
              setInsuranceCompanyId(value as string);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            <b>Notes</b>
          </label>
          <Form.TextArea
            placeholder="Type notes here..."
            value={notes}
            onChange={(_, { value }) => {
              setNotes(value as string);
            }}
          ></Form.TextArea>
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button
          primary
          content="Save"
          icon="save"
          onClick={handleSaveBtnClick}
        />
      </Form.Field>
      <Confirm
        size="mini"
        open={openConfirm}
        onCancel={() => {
          setNpi(data?.npi || '');
          setOpenConfirm(false);
        }}
        onConfirm={handleSubmit}
        confirmButton={<Button negative content="Yes" />}
        content={
          <StyledConfirmContent
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon
              size="large"
              name="warning sign"
              color="red"
              style={{ marginRight: '1rem' }}
            />
            <p>
              Are you sure you want to save and <b>change provider NPI</b>?
            </p>
          </StyledConfirmContent>
        }
      />
    </Form>
  );
};

const StyledConfirmContent = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  font-size: 18px;
`;

export default OutOfNetworkMappingForm;
