import React, { CSSProperties } from 'react';
import { Form } from 'semantic-ui-react';

interface RowsPerPageProps {
  localStorageItem: string;
  fluid?: boolean;
  rows: number;
  setRows?: any;
  style?: CSSProperties;
}

const RowsPerPage = ({
  localStorageItem,
  fluid,
  style,
  rows,
  setRows,
}: RowsPerPageProps) => {
  return (
    <Form>
      <Form.Dropdown
        label="Rows per Page"
        style={style || { minWidth: '15rem' }}
        placeholder="Rows per Page"
        fluid={fluid}
        value={rows}
        selection
        onChange={(e, { value }) => {
          setRows(value as number);
          localStorage.setItem(localStorageItem, String(value));
        }}
        options={[
          { text: '0 Rows (deactivated)', value: 0 },
          { text: '5 Rows', value: 5 },
          { text: '10 Rows', value: 10 },
          { text: '15 Rows', value: 15 },
          { text: '20 Rows', value: 20 },
        ]}
      />
    </Form>
  );
};

export default RowsPerPage;
