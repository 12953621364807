import MainLayout from '@ui/MainLayout';
import OrganizationSettings from '@ui/Organizations';
import { Container } from 'semantic-ui-react';

const OrganizationsScreen = () => {
  return (
    <MainLayout path={[{ text: 'Organizations' }]}>
      <Container fluid>
        <OrganizationSettings style={{ width: '100%' }} />
      </Container>
    </MainLayout>
  );
};

export default OrganizationsScreen;
