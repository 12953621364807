import { Provider } from '@bluefox/models/ClaimPreview';
import {
  Divider,
  Form,
  FormGroup,
  Grid,
  Header,
  Segment,
} from 'semantic-ui-react';

const RenderingProvider: React.FC<{ provider?: Provider }> = ({ provider }) => {
  return (
    <Segment>
      <Header as="h3"> Rendering Provider Information </Header>
      <Divider />
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form>
              <FormGroup widths="equal">
                {provider?.organizationName ? (
                  <Form.Input
                    fluid
                    label="Last/Organization Name"
                    value={provider?.organizationName}
                    readOnly
                  />
                ) : (
                  <>
                    <Form.Input
                      fluid
                      label="First Name"
                      value={provider?.firstName}
                      readOnly
                    />
                    <Form.Input
                      fluid
                      label="Last Name"
                      value={provider?.lastName}
                      readOnly
                    />
                  </>
                )}
                <Form.Input fluid label="NPI" value={provider?.npi} readOnly />
              </FormGroup>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default RenderingProvider;
