import { gql } from '@apollo/client';

export const ProvidersQuery = gql`
  query ProvidersQuery($criteria: [providers_bool_exp!]) {
    providers(where: { _or: $criteria }, order_by: { firstName: asc }) {
      id
      firstName
      lastName
      title
      npi
    }
  }
`;

export const InsertProviderMutation = gql`
  mutation InsertProviderMutation(
    $firstName: String
    $lastName: String
    $title: String
    $npi: String
  ) {
    insert_providers_one(
      object: {
        firstName: $firstName
        lastName: $lastName
        title: $title
        npi: $npi
      }
    ) {
      id
      firstName
      lastName
      title
      npi
    }
  }
`;

export const UpdateProviderMutation = gql`
  mutation UpdateProviderMutation(
    $id: uuid!
    $firstName: String
    $lastName: String
    $title: String
    $npi: String
  ) {
    update_providers_by_pk(
      pk_columns: { id: $id }
      _set: {
        firstName: $firstName
        lastName: $lastName
        title: $title
        npi: $npi
      }
    ) {
      firstName
      lastName
      title
      npi
    }
  }
`;

export const PrescribersQuery = gql`
  query PrescribersQuery($practiceIds: [uuid!]) {
    providers: practice_accounts(
      where: {
        practiceId: { _in: $practiceIds }
        title: { _in: [MD, DO, NP, PA] }
      }
    ) {
      id
      account {
        firstName
        lastName
        email
      }
    }
  }
`;
