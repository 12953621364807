import { CSSProperties, useCallback } from 'react';
import { Card, Dimmer, Grid, Loader } from 'semantic-ui-react';
import {
  usDollarNoDigitsCurrency,
  usNumberNoDigits,
} from '@bluefox/lib/formatters';
import Legends from '@bluefox/ui/Dashboard/Legends';
import TotalAmount from '@bluefox/ui/Dashboard/TotalAmount';
import VaccinationOverviewChart from '../charts/VaccinationOverviewChart';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';
import { pixelToRem } from '@bluefox/constants';
import { CardContent, CardHeader } from '@bluefox/ui/Dashboard/CommonStyles';
import { useQuery } from '@apollo/client';
import { BillingDashboardVaccinationOverview } from '@graphql/billing';
import { getPercentageOrZeroWithSign } from '@bluefox/lib/maths';

type Props = {
  practiceIds: string[];
  month: number | undefined;
  year: number | undefined;
  style?: CSSProperties;
};

type DataProps = {
  countVaccinationsTotal: number;
  vaccineExpenses: number;
  countVaccinationsPrivate: number;
  countVaccinationsVfc: number;
};

const VaccinationOverviewCard = ({
  practiceIds,
  month,
  year,
  style,
}: Props) => {
  const { data, loading, error } = useQuery(
    BillingDashboardVaccinationOverview,
    {
      variables: {
        practiceIds: practiceIds,
        month: month !== undefined ? month + 1 : undefined,
        year: year,
      },
      skip: !practiceIds || month === undefined || year === undefined,
    }
  );

  const renderData = useCallback((data: DataProps[]) => {
    const {
      countVaccinationsTotal,
      vaccineExpenses,
      countVaccinationsPrivate,
      countVaccinationsVfc,
    } = data.reduce(
      (accumulator, currentValue) => {
        return {
          countVaccinationsTotal:
            accumulator.countVaccinationsTotal +
            currentValue.countVaccinationsTotal,
          vaccineExpenses:
            accumulator.vaccineExpenses + currentValue.vaccineExpenses,
          countVaccinationsPrivate:
            accumulator.countVaccinationsPrivate +
            currentValue.countVaccinationsPrivate,
          countVaccinationsVfc:
            accumulator.countVaccinationsVfc +
            currentValue.countVaccinationsVfc,
        };
      },
      {
        countVaccinationsTotal: 0,
        vaccineExpenses: 0,
        countVaccinationsPrivate: 0,
        countVaccinationsVfc: 0,
      }
    );

    return (
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column width={6} verticalAlign="middle">
            <TotalAmount
              amount={usNumberNoDigits(countVaccinationsTotal)}
              subtitle="Administered vaccine doses"
              style={{ marginBottom: `${pixelToRem(24)}rem` }}
            />
            <TotalAmount
              amount={usDollarNoDigitsCurrency(vaccineExpenses)}
              subtitle="Private vaccine expenses"
            />
          </Grid.Column>
          <Grid.Column
            width={5}
            verticalAlign="middle"
            style={{ display: 'grid', justifyContent: 'end' }}
          >
            <Legends
              legends={[
                {
                  icon: {
                    name: 'clipboard outline',
                    color: 'blue',
                  },
                  title: 'Private',
                  value: getPercentageOrZeroWithSign(
                    countVaccinationsPrivate,
                    countVaccinationsTotal
                  ),
                  subValue: `(${usNumberNoDigits(countVaccinationsPrivate)})`,
                },
                {
                  icon: {
                    name: 'dollar',
                    color: 'teal',
                  },
                  title: 'VFC',
                  value: getPercentageOrZeroWithSign(
                    countVaccinationsVfc,
                    countVaccinationsTotal
                  ),
                  subValue: `(${usNumberNoDigits(countVaccinationsVfc)})`,
                },
              ]}
              direction="column"
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <div
              style={{
                height: '120%',
                minHeight: '120px',
              }}
            >
              {countVaccinationsPrivate === 0 && countVaccinationsVfc === 0 && (
                <ChartErrorMessage>
                  <p>There is not enough data to draw this chart</p>
                </ChartErrorMessage>
              )}
              {(countVaccinationsPrivate > 0 || countVaccinationsVfc > 0) && (
                <VaccinationOverviewChart
                  privateAmount={countVaccinationsPrivate}
                  vfcAmount={countVaccinationsVfc}
                />
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }, []);

  return (
    <Card fluid style={style}>
      <CardContent>
        <CardHeader>Vaccination overview</CardHeader>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        {error && (
          <ChartErrorMessage>Error: {error?.message}</ChartErrorMessage>
        )}
        {!loading &&
          !error &&
          data?.billing_dashboard_vaccination_overview &&
          renderData(data?.billing_dashboard_vaccination_overview)}
      </CardContent>
    </Card>
  );
};

export default VaccinationOverviewCard;
