import { useMemo } from 'react';
import { Card, Table } from 'semantic-ui-react';
import {
  CardContent,
  CardHeader,
  CardSubHeader,
} from '@bluefox/ui/Dashboard/CommonStyles';
import {
  InvoicingAmountParameter,
  InvoicingTablesDate,
  InvoicingTablesAmount,
  InvoicingTableCell,
} from './CommonStyles';
import styled from 'styled-components';
import {
  formatDateMonthDDcommaYear,
  usDollarNoDigitsCurrency,
} from '@bluefox/lib/formatters';
import { COLORS_BY_NAME, pixelToRem } from '@bluefox/constants';
import { InvoiceInfoType } from '@bluefox/models/Dashboard';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';

const PendingInvoicesParagraph = styled.p`
  font-size: ${pixelToRem(16)}rem !important;
  font-weight: 700 !important;
  line-height: ${20 / 16} !important;
  letter-spacing: 0.25px;
  color: ${COLORS_BY_NAME['Raisin Black']} !important;
`;

const PendingInvoicesSpan = styled.span`
  font-size: ${pixelToRem(10)}rem !important;
  font-weight: 700 !important;
  line-height: ${12 / 10} !important;
  letter-spacing: 0.25px;
  color: ${COLORS_BY_NAME['White']} !important;
  padding: ${pixelToRem(4)}rem ${pixelToRem(8)}rem;
  border-radius: 4px;
  gap: 4px;
  background-color: ${COLORS_BY_NAME['Roman Silver']};
`;

const TotalPendingBalanceTableWrapper = styled.div`
  margin-top: ${pixelToRem(12)}rem;
  height: ${pixelToRem(145)}rem;
  overflow: auto;
`;

type Props = { pendingInvoices: InvoiceInfoType[] };

type PendingInvoiceInfoType = {
  totalAmount: number;
  pendingInvoices: number;
};

const TotalPendingBalance = ({ pendingInvoices }: Props) => {
  const pendingInvoiceInfo: PendingInvoiceInfoType = useMemo(() => {
    return pendingInvoices.reduce(
      (acc, curr) => ({
        totalAmount: acc.totalAmount + curr.amount,
        pendingInvoices: acc.pendingInvoices + 1,
      }),
      {
        totalAmount: 0,
        pendingInvoices: 0,
      } as PendingInvoiceInfoType
    );
  }, [pendingInvoices]);

  return (
    <Card fluid style={{ height: '100%' }}>
      <CardContent>
        <CardHeader>Total pending balance</CardHeader>
        <CardSubHeader>TO DATE</CardSubHeader>
        <InvoicingAmountParameter style={{ marginTop: `${pixelToRem(16)}rem` }}>
          {usDollarNoDigitsCurrency(pendingInvoiceInfo.totalAmount)}
        </InvoicingAmountParameter>
        <PendingInvoicesParagraph>
          Pending invoices{' '}
          <PendingInvoicesSpan>
            {pendingInvoiceInfo.pendingInvoices}
          </PendingInvoicesSpan>
        </PendingInvoicesParagraph>
        <TotalPendingBalanceTableWrapper>
          {(!pendingInvoices || pendingInvoices.length === 0) && (
            <ChartErrorMessage>
              <p>There is not enough data to draw this table</p>
            </ChartErrorMessage>
          )}
          <Table basic="very">
            <Table.Body>
              {pendingInvoices.map((invoice) => (
                <Table.Row key={invoice.date?.toString()}>
                  <InvoicingTableCell>
                    <InvoicingTablesDate>
                      {formatDateMonthDDcommaYear(new Date(invoice.date))}
                    </InvoicingTablesDate>
                  </InvoicingTableCell>
                  <InvoicingTableCell textAlign="right">
                    <InvoicingTablesAmount>
                      {usDollarNoDigitsCurrency(invoice.amount)}
                    </InvoicingTablesAmount>
                  </InvoicingTableCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </TotalPendingBalanceTableWrapper>
      </CardContent>
    </Card>
  );
};

export default TotalPendingBalance;
