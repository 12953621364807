import { gql } from '@apollo/client';

export const PracticeSchedulerSettingsQuery = gql`
  query PracticeSchedulerSettingsQuery($practiceId: uuid!) {
    schedulerSettings: practice_scheduler_settings_by_pk(
      practiceId: $practiceId
    ) {
      practiceId
      simultaneousAppointments
      appointmentDuration
      openingHours
      exceptions
    }
  }
`;

export const PracticeSchedulerSettingsMutation = gql`
  mutation PracticeSchedulerSettingsMutation(
    $appointmentDuration: Int!
    $simultaneousAppointments: Int!
    $openingHours: jsonb
  ) {
    insert_practice_scheduler_settings_one(
      object: {
        simultaneousAppointments: $simultaneousAppointments
        appointmentDuration: $appointmentDuration
        openingHours: $openingHours
      }
      on_conflict: {
        constraint: practice_scheduler_settings_pkey
        update_columns: [
          simultaneousAppointments
          appointmentDuration
          openingHours
        ]
      }
    ) {
      practiceId
    }
  }
`;

export const UpdateFavoriteInsuranceCompaniesMutation = gql`
  mutation updateFavoriteInsuranceCompanies(
    $practiceId: uuid!
    $favoriteInsuranceCompanies: [uuid]!
  ) {
    update_practices_by_pk(
      pk_columns: { id: $practiceId }
      _set: {
        publicSettings: {
          favoriteInsuranceCompanies: $favoriteInsuranceCompanies
        }
      }
    ) {
      id
    }
  }
`;

const InsuranceCompanyFragment = gql`
  fragment InsuranceCompanyFragment on insurance_companies {
    id
    name
    cpid
    payerId
  }
`;

export const SelectedInsuranceCompaniesQuery = gql`
  query SelectedInsuranceCompaniesQuery($ids: [uuid]!) {
    companies: insurance_companies(where: { id: { _in: $ids } }) {
      ...InsuranceCompanyFragment
    }
  }

  ${InsuranceCompanyFragment}
`;

export const InsuranceCompaniesQuery = gql`
  query InsuranceCompaniesQuery(
    $input: String!
    $favoriteCompaniesIds: [uuid]!
    $favoriteInput: String
  ) {
    favoriteCompanies: insurance_companies(
      where: {
        _and: [
          { id: { _in: $favoriteCompaniesIds } }
          { name: { _ilike: $favoriteInput } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...InsuranceCompanyFragment
    }

    companies: insurance_companies(
      where: { name: { _ilike: $input } }
      order_by: { name: asc }
    ) {
      ...InsuranceCompanyFragment
    }
  }

  ${InsuranceCompanyFragment}
`;
