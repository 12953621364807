import { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

type Props = {
  onSubmit?: (message: string) => void;
};

const SendChatMessage = ({ onSubmit }: Props) => {
  const [text, setText] = useState('');
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(text?.trim());
        setText('');
      }}
    >
      <Form.Group inline>
        <Form.Input
          required
          type="text"
          onChange={(_, { value }) => setText(value)}
          value={text}
          width={14}
        />
        <Form.Button type="submit" disabled={!isStringNotEmpty(text?.trim())}>
          Send
        </Form.Button>
      </Form.Group>
    </Form>
  );
};

export default SendChatMessage;
