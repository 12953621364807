import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Form,
} from 'semantic-ui-react';
import {
  InsertVaccinePricingMutation,
  VaccinePricingListQuery,
} from '@graphql/vaccine';
import { Vaccine, PackageTypes } from '@bluefox/models/Vaccine';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { parseFloatOrZero } from '@bluefox/lib/maths';

interface VaccineData {
  id: string;
  name: string;
  manufacturer: string;
  packageType?: string;
  packageDoses?: number;
}

interface VaccineOnConflict {
  constraint: string;
  update_columns: string[];
}

interface VaccineObject {
  data: VaccineData;
  on_conflict: VaccineOnConflict;
}

interface FormValues {
  vaccineId: string;
  manufacturer: string;
  product: string;
  package: string;
  doses: number | undefined;
  startingDate: Date | undefined;
  requestedVaccinePrice: number | undefined;
  vaccine: VaccineObject;
}

const initialFormValues = {
  vaccineId: '',
  manufacturer: '',
  product: '',
  package: '',
  doses: undefined,
  startingDate: undefined,
  requestedVaccinePrice: undefined,
  vaccine: {
    data: {
      id: '',
      name: '',
      manufacturer: '',
      packageType: '',
      packageDoses: undefined,
    },
    on_conflict: {
      constraint: 'vaccines_pkey',
      update_columns: ['packageType', 'packageDoses'],
    },
  },
};

const packageTypeOptions = [
  {
    key: 'syringes',
    text: 'Syringes',
    value: PackageTypes.SYRINGES,
  },
  {
    key: 'vials',
    text: 'Vials',
    value: PackageTypes.VIALS,
  },
  {
    key: 'tube',
    text: 'Tube',
    value: PackageTypes.TUBE,
  },
];

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  vaccinePricingListQueryVariables: {
    limit: number;
    offset: number;
    criteria: object;
    vaccinePricingCriteria?: {
      contractPlanId: {
        _eq: string;
      };
      date: {
        _eq: string;
      };
    }[];
  };
};

const VaccinePricingForm = ({
  open,
  onClose,
  onSave,
  vaccinePricingListQueryVariables,
}: Props) => {
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();

  const [saveVaccinePricing] = useMutation(InsertVaccinePricingMutation);

  const handleSubmit = async () => {
    try {
      if (!selectedVaccine) return;

      await saveVaccinePricing({
        variables: {
          object: {
            saleNdc: selectedVaccine.saleNdc,
            cptCode: selectedVaccine.inventoryCptCodes
              ? selectedVaccine.inventoryCptCodes[0]
              : null,
            doses: formValues.doses,
            date: formValues.startingDate,
            priceCents: formValues.requestedVaccinePrice
              ? parseFloat((formValues.requestedVaccinePrice * 100).toFixed(2))
              : 0,
            priceCentsPerDose:
              formValues.requestedVaccinePrice && formValues.doses
                ? parseFloat(
                    Math.round(
                      (formValues.requestedVaccinePrice / formValues.doses) *
                        100
                    ).toFixed(2)
                  )
                : 0,
            pricePerDose:
              formValues.requestedVaccinePrice && formValues.doses
                ? parseFloat(
                    (
                      formValues.requestedVaccinePrice / formValues.doses
                    ).toFixed(2)
                  )
                : 0,
            price: formValues.requestedVaccinePrice,
            vaccine: {
              data: {
                id: formValues.vaccine.data.id,
                name: formValues.vaccine.data.name,
                manufacturer: formValues.vaccine.data.manufacturer,
                packageType: formValues.vaccine.data.packageType,
                packageDoses: formValues.vaccine.data.packageDoses,
              },
              on_conflict: {
                constraint: 'vaccines_pkey',
                update_columns: ['packageType', 'packageDoses'],
              },
            },
          },
        },
        refetchQueries: [
          {
            query: VaccinePricingListQuery,
            variables: vaccinePricingListQueryVariables,
          },
        ],
      });
      toast({
        title: 'Vaccine pricing saved successfully',
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      cleanAndClose();
      if (onSave) onSave();
    }
  };

  const cleanAndClose = () => {
    setFormValues(initialFormValues);
    if (onClose) onClose();
  };

  const handleSelectedVaccine = (vaccine: Vaccine) => {
    setSelectedVaccine(vaccine);

    setFormValues((prev) => ({
      ...prev,
      manufacturer: vaccine.manufacturer,
      product: vaccine.name,
      doses: vaccine.packageDoses,
      vaccine: {
        data: {
          id: vaccine.id,
          name: vaccine.name,
          manufacturer: vaccine.manufacturer,
          packageType: vaccine.packageType,
          packageDoses: vaccine.packageDoses || 0,
        },
        on_conflict: {
          constraint: 'vaccines_pkey',
          update_columns: ['packageType', 'packageDoses'],
        },
      },
    }));
  };

  return (
    <Modal onClose={cleanAndClose} open={open}>
      <ModalHeader>Add Vaccine Pricing</ModalHeader>
      <ModalContent>
        <Modal.Description
          style={{
            marginBottom: '1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          Type a vaccine name below.
          <VaccinePicker
            placeholder="Type vaccine name"
            onChange={({ vaccine }) =>
              vaccine && handleSelectedVaccine(vaccine)
            }
          />
        </Modal.Description>
        <Form id="vaccine-pricing-form" widths="equal" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field>
              <Form.Input
                readOnly
                label="Manufacturer"
                placeholder="Manufacturer"
                value={formValues.manufacturer}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                readOnly
                label="Product"
                placeholder="Product"
                value={formValues.product}
              />
            </Form.Field>
            <Form.Field>
              <Form.Dropdown
                label="Package"
                style={{ minWidth: '15rem' }}
                placeholder="Package"
                fluid
                selection
                value={formValues.vaccine.data.packageType}
                onChange={(_, { value }) =>
                  setFormValues((prev) => ({
                    ...prev,
                    package: value as string,
                    vaccine: {
                      ...prev.vaccine,
                      data: {
                        ...prev.vaccine.data,
                        packageType: value as string,
                      },
                    },
                  }))
                }
                options={packageTypeOptions}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Doses"
                placeholder="Doses"
                value={formValues.vaccine.data.packageDoses}
                onChange={(_, { value }) =>
                  setFormValues((prev) => ({
                    ...prev,
                    doses: parseFloatOrZero(value),
                    vaccine: {
                      ...prev.vaccine,
                      data: {
                        ...prev.vaccine.data,
                        packageDoses: parseFloatOrZero(value),
                      },
                    },
                  }))
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Starting Date</label>
              <DateTimePicker
                onChange={(value) =>
                  setFormValues((prev) => ({
                    ...prev,
                    startingDate: value as Date,
                  }))
                }
                selected={formValues.startingDate}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                required
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                type="number"
                step="0.01"
                label="Requested Vaccine Price"
                placeholder="Requested Vaccine Price"
                value={formValues.requestedVaccinePrice}
                onChange={(_, { value }) =>
                  setFormValues((prev) => ({
                    ...prev,
                    requestedVaccinePrice: parseFloat(value),
                  }))
                }
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </ModalContent>
      <ModalActions>
        <Button onClick={cleanAndClose}>Cancel</Button>
        <Button
          primary
          content="Save"
          type="submit"
          form="vaccine-pricing-form"
        />
      </ModalActions>
    </Modal>
  );
};

export default VaccinePricingForm;
