import { COLORS_BY_NAME, pixelToRem } from '@bluefox/constants';
import styled from 'styled-components';

export const MainContextParagraphStyle = styled.p`
  color: ${COLORS_BY_NAME['Jet']};
  font-size: ${pixelToRem(14)}rem;
  font-style: normal;
  font-weight: 400;
  line-height: ${45 / 14};
`;

export const MainContextHightlitedStyle = styled.span`
  color: ${COLORS_BY_NAME['Midnight Green (Eagle Green)']};
  font-style: normal;
  font-weight: 700;
`;

export const DropdownWrapperStyle = styled.span`
  vertical-align: top;
`;

export const CheckboxWrapperStyle = styled.span`
  vertical-align: sub;
`;
