import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Label,
  List,
  Message,
  Popup,
  Table,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { calculatePriceWithDiscount } from '@bluefox/lib/maths/calculatePriceWithDiscount';
import { formatPackage } from '@bluefox/lib/formatters';
import styled from 'styled-components';
import { VaccinePricingData } from '@screens/vaccines/VaccinesPriceList';

type Props = {
  vaccine: VaccinePricingData;
};

const ExpandedRow = ({ vaccine }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getTotalPrecentageTypeDiscounts = (plan: any) => {
    const totalPrecentageDiscount = plan.contractPlan?.discounts.reduce(
      (acc: any, item: any) => {
        if (item.type === 'percentage') {
          return acc + item.discount;
        }

        return acc;
      },
      0
    );

    return totalPrecentageDiscount;
  };

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          {vaccine.vaccinePricingPlans.length ? (
            <Card fluid>
              <Card.Content>
                <Card.Header
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Contract Plans
                </Card.Header>
              </Card.Content>
              <Card.Description
                style={{ maxHeight: '25rem', overflowY: 'auto' }}
              >
                <Table striped>
                  <Table.Header
                    className="contract-plans-expanded-row-th"
                    fullWidth
                  >
                    <Table.Row>
                      <Table.HeaderCell width={2}>Plan</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Date</Table.HeaderCell>
                      <Table.HeaderCell width={3}>Discounts</Table.HeaderCell>
                      <Table.HeaderCell>FET</Table.HeaderCell>
                      <Table.HeaderCell>Catalogue Price</Table.HeaderCell>
                      <Table.HeaderCell>Price</Table.HeaderCell>
                      <Table.HeaderCell>Price w/tax</Table.HeaderCell>
                      <Table.HeaderCell>Price w/discounts</Table.HeaderCell>
                      <Table.HeaderCell>
                        Price w/discounts and tax
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Price per dose w/discounts and tax
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {vaccine.vaccinePricingPlans.map((plan) => {
                      const totalPrecentageDiscount =
                        getTotalPrecentageTypeDiscounts(plan);

                      return (
                        <Table.Row>
                          <Table.Cell>{plan.contractPlan?.name}</Table.Cell>
                          <Table.Cell>{plan.date}</Table.Cell>
                          <Table.Cell>
                            {plan.contractPlan?.discounts.map((discount) => {
                              if (discount.type === 'amount') {
                                return (
                                  <Popup
                                    trigger={
                                      <div
                                        style={{
                                          margin: '0.1rem 0 0.1rem 0',
                                        }}
                                      >
                                        <Label
                                          size="small"
                                          style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <p style={{ margin: '0' }}>
                                            {humanizeText(discount.reason, {
                                              capitalize: 'first',
                                              delimiter: '_',
                                            })}
                                          </p>
                                          <p style={{ margin: '0' }}>
                                            {discount.type === 'amount'
                                              ? `$${discount.discount}`
                                              : `${discount.discount}%`}
                                          </p>
                                        </Label>
                                      </div>
                                    }
                                    content="Amount type discounts are for info purposes only and won't be applyed in this table.
                                      "
                                    size="small"
                                  />
                                );
                              } else {
                                return (
                                  <div
                                    style={{
                                      margin: '0.1rem 0 0.1rem 0',
                                    }}
                                  >
                                    <Label
                                      size="small"
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <p style={{ margin: '0' }}>
                                        {humanizeText(discount.reason, {
                                          capitalize: 'first',
                                          delimiter: '_',
                                        })}
                                      </p>
                                      <p style={{ margin: '0' }}>
                                        {discount.type === 'amount'
                                          ? `$${discount.discount}`
                                          : `${discount.discount}%`}
                                      </p>
                                    </Label>
                                  </div>
                                );
                              }
                            })}
                          </Table.Cell>
                          <Table.Cell>
                            ${vaccine.federalExciseTaxes / 100}
                          </Table.Cell>
                          <Table.Cell>
                            ${vaccine.vaccinePricingDefault[0].priceCents / 100}
                          </Table.Cell>
                          <Table.Cell>{`$${plan.priceCents / 100}`}</Table.Cell>
                          <Table.Cell>{`$${
                            plan.priceCents / 100 +
                            vaccine.federalExciseTaxes / 100
                          }`}</Table.Cell>
                          <Table.Cell>{`$${
                            plan.contractPlan
                              ? calculatePriceWithDiscount(
                                  plan.priceCents / 100,
                                  totalPrecentageDiscount
                                ).toFixed(2)
                              : 0
                          }`}</Table.Cell>
                          <Table.Cell>{`$${
                            plan.contractPlan
                              ? (
                                  calculatePriceWithDiscount(
                                    plan.priceCents / 100,
                                    totalPrecentageDiscount
                                  ) +
                                  vaccine.federalExciseTaxes / 100
                                ).toFixed(2)
                              : 0
                          }`}</Table.Cell>
                          <Table.Cell>{`$${
                            plan.contractPlan
                              ? (
                                  (calculatePriceWithDiscount(
                                    plan.priceCents / 100,
                                    totalPrecentageDiscount
                                  ) +
                                    vaccine.federalExciseTaxes / 100) /
                                  vaccine.packageDoses
                                ).toFixed(2)
                              : 0
                          }`}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card>
          ) : (
            <Message>
              This vaccine does not have any contract plan associated.
            </Message>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const StyleCard = styled(Card)`
  padding: 1rem !important;
  height: 100%;
`;

export default ExpandedRow;
