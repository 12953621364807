import {
  NotificationStatus,
  NotificationType,
} from '@bluefox/models/Notification';

export enum BillingNotificationFirstOptionsEnum {
  CPT_CODE_REJECTION = 'CPT_CODE_REJECTION',
  PATIENT_WITH_NO_ACTIVE_POLICY = 'PATIENT_WITH_NO_ACTIVE_POLICY',
  INSURANCE_REQUIERES_TO_CONTACT_THEM = 'INSURANCE_REQUIERES_TO_CONTACT_THEM',
  INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY = 'INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY',
  FREE_TEXT = 'FREE_TEXT',
  THE_PROCEDURE_CODE_IS_INCONSISTENT_PATIENT_AGE = 'THE_PROCEDURE_CODE_IS_INCONSISTENT_PATIENT_AGE',
  COORDINATION_OF_BENEFITS = 'COORDINATION_OF_BENEFITS',
  NO_ENROLLMENT_PATIENT_OVER_19 = 'NO_ENROLLMENT_PATIENT_OVER_19',
  MEDICAL_NECESSITY = 'MEDICAL_NECESSITY',
  ADULT_PATIENT_WITHOUT_INSURANCE = 'ADULT_PATIENT_WITHOUT_INSURANCE',
  DUPLICATE_CLAIM = 'DUPLICATE_CLAIM',
}

export const BillingNotificationFirstOptionsEnumToText: Record<
  BillingNotificationFirstOptionsEnum,
  string
> = {
  [BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION]: `the CPT code [CPT Code] was rejected because it is inconsistent with the patient’s age`,
  [BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY]:
    'the patient does not have an active policy registered and was vaccinated using a private inventory',
  [BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM]:
    'the insurance is requesting that the subscriber contact them to update his/her personal info',
  [BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY]:
    'the patient received a booster doses and the insurance is requesting demostrate the medical necessity',
  [BillingNotificationFirstOptionsEnum.FREE_TEXT]: 'Free text',
  [BillingNotificationFirstOptionsEnum.THE_PROCEDURE_CODE_IS_INCONSISTENT_PATIENT_AGE]: `the administered vaccine CPT code [CPT Code] is not aligned with the patient's age, resulting in claim submission rejections. Please note that if a vaccine is given outside the recommended age range without a valid medical reason, the practice will be responsible for the vaccine cost. If there is a valid medical reason, kindly complete the form`,
  [BillingNotificationFirstOptionsEnum.COORDINATION_OF_BENEFITS]:
    'the insurance company is requesting that the policyholder contact them to update their information to finalize the claim. Our team will reach out to the patient to request that they communicate with the insurance company to resolve the issue. If you prefer to proceed differently, please contact us',
  [BillingNotificationFirstOptionsEnum.NO_ENROLLMENT_PATIENT_OVER_19]: `the practice or doctor is not enrolled with the patient's insurance plan, so the patient will be considered selfpay`,
  [BillingNotificationFirstOptionsEnum.MEDICAL_NECESSITY]:
    'the insurance company is requesting confirmation of medical necessity. Please complete the attached file',
  [BillingNotificationFirstOptionsEnum.ADULT_PATIENT_WITHOUT_INSURANCE]: `vaccines are not covered under the patient's benefit plan, and the patient is over 19 years old. Therefore, we should process this as self-pay`,
  [BillingNotificationFirstOptionsEnum.DUPLICATE_CLAIM]:
    'our team identified that our CPT codes were submitted in your claims with an amount lower than the cost of the vaccine. This is causing our claims to be denied due to duplication, and we are not receiving the correct payments for the vaccine CPT codes. Please resubmit the claim, removing our CPT codes, and remember not to submit codes related to immunizations',
};

export enum VaxSyncIntegrationFirstOptionsEnum {
  INMUNIZATION_NOT_REPORTED_EMR = 'INMUNIZATION_NOT_REPORTED_EMR',
  INMUNIZATION_NOT_REPORTED_IIS = 'INMUNIZATION_NOT_REPORTED_IIS',
  INMUNIZATION_INCONCISTENT_EMR_CANID = 'INMUNIZATION_INCONCISTENT_EMR_CANID',
  FREE_TEXT = 'FREE_TEXT',
  LOCKED_ENCOUNTER = 'LOCKED_ENCOUNTER',
  VACCINATION_SING_WRONG_DOS = 'VACCINATION_SING_WRONG_DOS',
  NO_SCAN_ON_CANID = 'NO_SCAN_ON_CANID',
  NO_APPOINTMENT = 'NO_APPOINTMENT',
  INCONSISTENT_DOS = 'INCONSISTENT_DOS',
  VACCINATION_PENDING_SIGNATURE_PLEASE_SIGN = 'VACCINATION_PENDING_SIGNATURE_PLEASE_SIGN',
  NO_PROFILE_IN_EMR = 'NO_PROFILE_IN_EMR',
  NO_PROFILE_IN_IIS = 'NO_PROFILE_IN_IIS',
  INCONSISTENT_VACCINE_INFORMATION = 'INCONSISTENT_VACCINE_INFORMATION',
}

export const vaxSyncIntegrationFirstOptionsEnumToText: Record<
  VaxSyncIntegrationFirstOptionsEnum,
  string
> = {
  [VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR]: `immunization could not be reported to the EMR`,
  [VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS]:
    'immunization could not be reported to the IIS',
  [VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID]:
    'Information is inconsistent between EMR and Canid',
  [VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT]: 'Free text',

  [VaxSyncIntegrationFirstOptionsEnum.LOCKED_ENCOUNTER]: `the encounter is currently locked in the EMR. Please unlock it to allow immunization synchronization.`,
  [VaxSyncIntegrationFirstOptionsEnum.VACCINATION_SING_WRONG_DOS]:
    'the vaccine was signed with an administration date different from the DOS associated with the immunization in Canid. Please sign the immunization with the correct DOS.',
  [VaxSyncIntegrationFirstOptionsEnum.NO_SCAN_ON_CANID]:
    'the vaccine [Vaccine], lot number (VFC or Private)] was not scanned in Canid on the Date of Service (DOS) [date]. Please scan the vaccination in Canid.',
  [VaxSyncIntegrationFirstOptionsEnum.NO_APPOINTMENT]:
    'the patient does not have an appointment for the Date of Service (DOS). Please create the appointment.',

  [VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_DOS]: `the Date of Service (DOS) is inconsistent between Canid and the EMR. In Canid, the vaccine was scanned on [date], while in the EMR, it was recorded with a DOS of [manually typed date]. Please confirm the correct DOS.`,
  [VaxSyncIntegrationFirstOptionsEnum.VACCINATION_PENDING_SIGNATURE_PLEASE_SIGN]:
    'vaccination is pending signature. Please sign it in the EMR to ensure proper transfer to the IIS.',
  [VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_EMR]: `the patient doesn't have a profile in the EMR. Please create the patient profile in the EMR, as we do not have the necessary information or access.`,
  [VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_IIS]: `the patient doesn't have a profile in the IIS. Please, provide the patient guardian's information to create the patient profile in the IIS.`,
  [VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_VACCINE_INFORMATION]: `the vaccine information is inconsistent between Canid and the EMR. In Canid, the scanned vaccine is [Vaccine], [lot], [(private or VFC)], while in the EMR, the recorded vaccine is [manually typed vaccine],lot number [manually typed lot number], [private or VFC]. Please confirm the correct information.`,
};

export enum VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum {
  CREATE_ENCOUNTER = 'CREATE_ENCOUNTER',
  OPEN_ENCOUNTER = 'OPEN_ENCOUNTER',
  CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE = 'CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE',
}

export const vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText: Record<
  VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
  string
> = {
  [VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER]: `create the encounter`,
  [VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER]:
    'open the encounter',
  [VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE]:
    'confirm guardian information or create the profile in the registry',
};

export enum VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum {
  ENCOUNTER_CLOSED = 'ENCOUNTER_CLOSED',
  ENCOUNTER_MISSED = 'ENCOUNTER_MISSED',
  GUARDIAN_INFO_MISSING = 'GUARDIAN_INFO_MISSING',
}

export const vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText: Record<
  VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
  string
> = {
  [VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED]: `the encounter is closed`,
  [VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED]:
    'the encounter is missed',
  [VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING]:
    'guardian information is missed',
};

export const notificationStatusEnumToText: Record<NotificationStatus, string> =
  {
    [NotificationStatus.pending_approval]: 'Pending Approval',
    [NotificationStatus.dismissed]: 'Dismissed',
    [NotificationStatus.pending]: 'Pending',
    [NotificationStatus.resolved]: 'Resolved',
    [NotificationStatus.rejected]: 'Rejected',
    [NotificationStatus.finalized]: 'Finalized',
  };

export const notificationStatusEnumToBeShownText: Record<
  NotificationStatus,
  string
> = {
  [NotificationStatus.pending_approval]: 'Waiting for internal approval',
  [NotificationStatus.dismissed]: 'Internally dismissed',
  [NotificationStatus.pending]: 'Waiting for Practice confirmation',
  [NotificationStatus.resolved]: 'Practice confirmation received',
  [NotificationStatus.rejected]: 'The practice has declined the notification',
  [NotificationStatus.finalized]: 'Finalized',
};

export const notificationTypeEnumToText: Record<NotificationType, string> = {
  [NotificationType.athena]: 'Data Sync',
  [NotificationType.claim]: 'Billing',
  [NotificationType.develo]: 'Data Sync',
  [NotificationType.ecw]: 'Data Sync',
  [NotificationType.order]: 'Order',
  [NotificationType.vax_sync]: 'Data Sync',
};

export const orderNotificationStatus_PP_ALL = [
  NotificationStatus.pending,
  NotificationStatus.resolved,
  NotificationStatus.rejected,
];

export const patientNotificationStatus_PP_ALL = [
  NotificationStatus.pending,
  NotificationStatus.resolved,
  NotificationStatus.finalized,
];

export const PendingNotificationsCountQueryDefaultWhere = {
  status: { _eq: NotificationStatus.pending },
};
