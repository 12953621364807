import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  getNotificationMoreDataQuery,
  NotificationsQuery,
  PendingNotificationsCountQuery,
  updateNotificationComments,
  updateNotificationCommentsPendingReadByMutation,
  updateNotifications,
} from '@bluefox/graphql/notifications';
import { Card, Message, Pagination } from 'semantic-ui-react';
import {
  BillingNotificationContent,
  Notification,
  NotificationComment,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  NotificationType,
  VaxSyncIntegrationNotificationContent,
  VaxSyncIntegrationNotificationType,
} from '@bluefox/models/Notification';
import PatientNotificationListTable from '@bluefox/ui/Notifications/NotificationListTable/PatientNotificationListTable';
import OrderNotificationListTable from '@bluefox/ui/Notifications/NotificationListTable/OrderNotificationListTable';
import { toast } from 'react-semantic-toasts';
import { Session, useApplicationState } from '@bluefox/contexts';
import NotificationListRowActions from './NotificationListRowActions';
import NotificationListFilter, {
  buildPatientQuery,
  initialPatientQueryIT,
  SearchValuesProps,
} from '@bluefox/ui/Notifications/PatientNotificationListFilter';
import { debounce } from '@bluefox/lib/debounce';
import { BillingClaimStatus } from '@bluefox/models/Billing';
import { ALL } from '@bluefox/lib/options';
import CommentsModal from '@bluefox/ui/Notifications/CommentsModal';
import { OrderStatuses } from '@bluefox/models/InventoryOrders';
import BillingNotificationModal from '../BillingNotification/BillingNotificationModal';
import { FormValues as BillingNotificationFormValues } from '../BillingNotification/BillingNotificationForm';
import { FormValues as VaxSyncIntegrationNotificationFormValues } from '../VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationForm';
import { useNotifications } from '../useNotifications';
import VaxSyncIntegrationNotificationModal from '../VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationModal';
import OrganizationAndPracticeSelector, {
  SearchValuesProps as OrgAndPractSearchValuesProps,
} from '@bluefox/ui/OrganizationAndPracticeSelector';
import NotificationMessage from '@bluefox/ui/Notifications/NotificationMessage';

const pageLimit = 10;

function buildStatusLog(
  notification: Notification,
  newStatus: NotificationStatus,
  session?: Session
) {
  const newStatusLog = [
    {
      action: NotificationStatusLogAction.changeStatus,
      account: session?.account,
      updatedAt: new Date(),
      status: newStatus,
      oldStatus: notification.status,
    },
    ...(notification?.statusLog ? notification?.statusLog : []),
  ];
  return newStatusLog;
}

function isNotificationValid(notification?: Notification) {
  if (!notification) {
    toast({
      title: `Failed to refresh notification status`,
      type: 'error',
      time: 5000,
    });
    return false;
  }
  if (
    notification.status !== NotificationStatus.pending_approval &&
    notification.status !== NotificationStatus.resolved
  ) {
    toast({
      title: `The notification has already changed its status. Please refresh the page.`,
      type: 'error',
      time: 5000,
    });
    return false;
  }
  switch (notification.type) {
    case NotificationType.claim: {
      const claim = notification.claim;
      if (!claim) {
        toast({
          title: `Failed to get the claim related to the notification.`,
          type: 'error',
          time: 5000,
        });
        return false;
      }
      if (claim.status !== BillingClaimStatus.DIRECT_CHARGE) {
        toast({
          title: `The claim related to the notification has changed its status.`,
          type: 'error',
          time: 5000,
        });
        return false;
      }
      break;
    }
    case NotificationType.order: {
      const inventoryOrder = notification.inventoryOrder;
      if (!inventoryOrder) {
        toast({
          title: `Failed to get the order related to the notification.`,
          type: 'error',
          time: 5000,
        });
        return false;
      }
      if (inventoryOrder.status !== OrderStatuses.ORDERED) {
        toast({
          title: `The order related to the notification has changed its status.`,
          type: 'error',
          time: 5000,
        });
        return false;
      }
      break;
    }
    default:
  }

  return true;
}

type Props = {};

const NotificationList = (props: Props) => {
  const [searchValues, setSearchValues] = useState<SearchValuesProps>(
    initialPatientQueryIT
  );
  const [queryValues, setQueryValues] = useState<SearchValuesProps>(
    initialPatientQueryIT
  );
  const [patientNotificationsActivePage, setPatientNotificationsActivePage] =
    useState(1);
  const [orderNotificationsActivePage, setOrderNotificationsActivePage] =
    useState(1);
  const [commentModal, setCommentModal] = useState<{
    notification: Notification;
  }>();
  const [notificationModalData, setNotificationModalData] = useState<{
    notification: Notification;
  }>();
  const [orgAndPractSearchValues, setOrgAndPractSearchValues] =
    useState<OrgAndPractSearchValuesProps>({});
  const [
    billingNotificationModalFormValues,
    setBillingNotificationModalFormValues,
  ] = useState<BillingNotificationFormValues>(
    {} as BillingNotificationFormValues
  );
  const [
    vaxSyncIntegrationNotificationFormValues,
    setVaxSyncIntegrationNotificationFormValues,
  ] = useState<VaxSyncIntegrationNotificationFormValues>(
    {} as VaxSyncIntegrationNotificationFormValues
  );

  const debouncedRef = useRef<ReturnType<typeof debounce>>();
  const { session } = useApplicationState();
  const {
    loading: patientNotificationsLoading,
    error: patientNotificationsError,
    data: patientNotificationsData,
    refetch: patientNotificationsRefetch,
  } = useQuery<NotificationResponseType>(NotificationsQuery, {
    variables: {
      limit: pageLimit,
      offset: (patientNotificationsActivePage - 1) * pageLimit,
      where: {
        type: { _neq: NotificationType.order },
        ...buildPatientQuery(queryValues, 'IT'),
        ...(orgAndPractSearchValues?.practicesIds
          ? { practiceId: { _in: orgAndPractSearchValues?.practicesIds } }
          : {}),
      },
      withPractice: true,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    loading: orderNotificationsLoading,
    error: orderNotificationsError,
    data: orderNotificationsData,
    refetch: orderNotificationsRefetch,
  } = useQuery<NotificationResponseType>(NotificationsQuery, {
    variables: {
      limit: pageLimit,
      offset: (orderNotificationsActivePage - 1) * pageLimit,
      where: {
        ...(searchValues.orderTableStatus &&
        searchValues.orderTableStatus !== ALL
          ? {
              status: { _eq: searchValues.orderTableStatus },
            }
          : {}),
        type: { _eq: NotificationType.order },
        ...(orgAndPractSearchValues?.practicesIds
          ? { practiceId: { _in: orgAndPractSearchValues?.practicesIds } }
          : {}),
      },
      withPractice: true,
    },
    fetchPolicy: 'no-cache',
  });
  const [updateNotification] = useMutation(updateNotifications, {
    refetchQueries: [PendingNotificationsCountQuery],
  });
  const [getNotificationMoreData] = useLazyQuery<{
    communication_notifications: Notification[];
  }>(getNotificationMoreDataQuery);
  const [updateNotificationComment] = useMutation(updateNotificationComments);
  const [updateNotificationCommentsPendingReadBy] = useMutation(
    updateNotificationCommentsPendingReadByMutation,
    {
      refetchQueries: [PendingNotificationsCountQuery],
    }
  );

  const { editNotificationHandler } = useNotifications();

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  const refreshNotificationData = useCallback(
    async (notification: Notification) => {
      try {
        const resp = await getNotificationMoreData({
          variables: {
            notificationId: notification.id,
            withClaim: notification.type === NotificationType.claim,
            withInventoryOrder: notification.type === NotificationType.order,
          },
        });
        const refreshedNotification = resp.data?.communication_notifications[0];
        return refreshedNotification;
      } catch (error) {
        toast({
          title: `Failed to refresh the notification information. Error: ${error}`,
          type: 'error',
          time: 5000,
        });
        return undefined;
      }
    },
    [getNotificationMoreData]
  );

  const changeNotificationStatus = useCallback(
    async (notification: Notification, newStatus: NotificationStatus) => {
      const refreshedNotification = await refreshNotificationData(notification);
      const isValid = isNotificationValid(refreshedNotification);
      if (!isValid) return;

      const newStatusLog = buildStatusLog(
        refreshedNotification as Notification,
        newStatus,
        session
      );
      try {
        await updateNotification({
          variables: {
            id: notification.id,
            status: newStatus,
            statusLog: newStatusLog,
          },
        });
      } catch (error) {
        toast({
          title: `Failed to update notification status. Error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }

      try {
        if (notification.type === NotificationType.order) {
          orderNotificationsRefetch();
        } else {
          patientNotificationsRefetch();
        }
      } catch (error) {
        toast({
          title: `Failed to refresh notification list. Error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [
      orderNotificationsRefetch,
      patientNotificationsRefetch,
      refreshNotificationData,
      session,
      updateNotification,
    ]
  );

  const handleOpenComments = useCallback(
    async (notification: Notification) => {
      const refreshedNotification = await refreshNotificationData(notification);
      if (!refreshedNotification) return;
      if (refreshedNotification.pendingReadBy === 'IT') {
        try {
          await updateNotificationCommentsPendingReadBy({
            variables: {
              id: refreshedNotification.id,
              pendingReadBy: null,
            },
          });
          if (notification.type === NotificationType.order) {
            await orderNotificationsRefetch();
          } else {
            await patientNotificationsRefetch();
          }
        } catch (error) {
          toast({
            title: `Failed to update notification pending read by. Error: ${error}`,
            type: 'error',
            time: 5000,
          });
        }
      }
      setCommentModal({ notification: refreshedNotification });
    },
    [
      orderNotificationsRefetch,
      patientNotificationsRefetch,
      refreshNotificationData,
      updateNotificationCommentsPendingReadBy,
    ]
  );

  const handleOnEdit = useCallback(
    async (notification: Notification) => {
      const refreshedNotification = await refreshNotificationData(notification);
      if (
        !refreshedNotification ||
        refreshedNotification.status !== NotificationStatus.pending_approval
      )
        return;
      switch (refreshedNotification.type) {
        case NotificationType.claim:
          setNotificationModalData({
            notification: refreshedNotification,
          });
          return;

        case NotificationType.athena:
        case NotificationType.develo:
        case NotificationType.ecw:
        case NotificationType.vax_sync:
          setNotificationModalData({
            notification: refreshedNotification,
          });
          return;
        default:
      }
    },
    [refreshNotificationData]
  );

  const renderBillingNotificationModal = useCallback(
    (notification: Notification) => {
      const content = notification.content as BillingNotificationContent;
      return (
        <BillingNotificationModal
          open={!!notification}
          practiceId={notification.practiceId}
          practicePatientId={notification.practicePatientId}
          fullPatienName={content.fullPatienName}
          givenAt={content.givenAt}
          mrn={content.mrn}
          formValues={billingNotificationModalFormValues}
          setFormValues={setBillingNotificationModalFormValues}
          claim={notification.claim}
          onEdit={async (
            notification: Notification,
            content: BillingNotificationContent,
            newStatusLogItem: any
          ) => {
            await editNotificationHandler(
              notification,
              content,
              newStatusLogItem
            );
            try {
              await patientNotificationsRefetch();
            } catch (error) {}
          }}
          onClose={() => setNotificationModalData(undefined)}
        />
      );
    },
    [
      billingNotificationModalFormValues,
      editNotificationHandler,
      patientNotificationsRefetch,
    ]
  );

  const renderVaxSyncIntegrationNotificationModal = useCallback(
    (notification: Notification) => {
      const content =
        notification.content as VaxSyncIntegrationNotificationContent;
      return (
        <VaxSyncIntegrationNotificationModal
          open={!!notification}
          practiceId={notification.practiceId}
          practicePatientId={notification.practicePatientId}
          fullPatienName={content?.fullPatienName}
          givenAt={content?.givenAt}
          mrn={content.mrn}
          notificationType={
            notification.type as VaxSyncIntegrationNotificationType
          }
          entityRefId={notification?.entityRefId}
          vaccinationId={notification?.vaccinationId}
          vaccineName={content.vaccineName}
          inventoryVfc={content.inventoryVfc}
          lot={content.lot}
          formValues={vaxSyncIntegrationNotificationFormValues}
          setFormValues={setVaxSyncIntegrationNotificationFormValues}
          onEdit={async (
            notification: Notification,
            content: VaxSyncIntegrationNotificationContent,
            newStatusLogItem: any
          ) => {
            await editNotificationHandler(
              notification,
              content,
              newStatusLogItem
            );
            try {
              await patientNotificationsRefetch();
            } catch (error) {}
          }}
          onClose={() => {
            setVaxSyncIntegrationNotificationFormValues({});
            setNotificationModalData(undefined);
          }}
        />
      );
    },
    [
      editNotificationHandler,
      patientNotificationsRefetch,
      vaxSyncIntegrationNotificationFormValues,
    ]
  );

  return (
    <>
      <OrganizationAndPracticeSelector
        multiplePracticesSelect
        searchValues={orgAndPractSearchValues}
        setSearchValues={setOrgAndPractSearchValues}
        onlyNotificationEnabled
        style={{ marginBottom: '5rem' }}
        onChange={() => {
          setOrderNotificationsActivePage(1);
          setPatientNotificationsActivePage(1);
        }}
      />
      <section style={{ marginBottom: '5rem', textAlign: 'center' }}>
        {orderNotificationsError ? (
          <Message error>{orderNotificationsError.message}</Message>
        ) : (
          <>
            <OrderNotificationListTable
              internalTools
              loading={orderNotificationsLoading}
              notifications={
                orderNotificationsData?.communication_notifications
              }
              notificationStatus={searchValues.orderTableStatus}
              notificationStatusOnChange={(value) => {
                setSearchValues((prevValue) => ({
                  ...prevValue,
                  orderTableStatus: value,
                }));
                setOrderNotificationsActivePage(1);
              }}
              children={
                <NotificationListRowActions
                  onAccept={async (notification) => {
                    if (!notification) return;
                    await changeNotificationStatus(
                      notification,
                      NotificationStatus.pending
                    );
                  }}
                  onCancel={async (notification) => {
                    if (!notification) return;
                    await changeNotificationStatus(
                      notification,
                      NotificationStatus.dismissed
                    );
                  }}
                  onOpenComments={async (notification) => {
                    if (!notification) return;
                    await handleOpenComments(notification);
                  }}
                />
              }
            />
            <Pagination
              activePage={orderNotificationsActivePage}
              totalPages={
                orderNotificationsData?.communication_notifications_aggregate
                  .aggregate.count
                  ? Math.ceil(
                      orderNotificationsData
                        ?.communication_notifications_aggregate.aggregate
                        .count / pageLimit
                    )
                  : 1
              }
              onPageChange={(_, { activePage }) => {
                setOrderNotificationsActivePage(activePage as number);
              }}
            />
          </>
        )}
      </section>
      <section style={{ marginBottom: '5rem', textAlign: 'center' }}>
        {patientNotificationsError ? (
          <Message error>{patientNotificationsError.message}</Message>
        ) : (
          <>
            <Card fluid>
              <Card.Content>
                <Card.Description>
                  <NotificationListFilter
                    internalTools
                    searchValues={searchValues}
                    setSearchValues={(value) => {
                      setSearchValues(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setQueryValues(value);
                        setPatientNotificationsActivePage(1);
                        setOrderNotificationsActivePage(1);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Card.Description>
              </Card.Content>
            </Card>
            <PatientNotificationListTable
              internalTools
              loading={patientNotificationsLoading}
              notifications={
                patientNotificationsData?.communication_notifications
              }
              children={
                <NotificationListRowActions
                  onAccept={async (notification) => {
                    if (!notification) return;
                    await changeNotificationStatus(
                      notification,
                      notification.status ===
                        NotificationStatus.pending_approval
                        ? NotificationStatus.pending
                        : NotificationStatus.finalized
                    );
                  }}
                  onCancel={async (notification) => {
                    if (!notification) return;
                    await changeNotificationStatus(
                      notification,
                      NotificationStatus.dismissed
                    );
                  }}
                  onOpenComments={async (notification) => {
                    if (!notification) return;
                    await handleOpenComments(notification);
                  }}
                  showEdit
                  onEdit={async (notification) => {
                    if (!notification) return;
                    await handleOnEdit(notification);
                  }}
                />
              }
            />
            <Pagination
              activePage={patientNotificationsActivePage}
              totalPages={
                patientNotificationsData?.communication_notifications_aggregate
                  .aggregate.count
                  ? Math.ceil(
                      patientNotificationsData
                        ?.communication_notifications_aggregate.aggregate
                        .count / pageLimit
                    )
                  : 1
              }
              onPageChange={(_, { activePage }) => {
                setPatientNotificationsActivePage(activePage as number);
              }}
            />
            {notificationModalData?.notification.type ===
              NotificationType.claim &&
              renderBillingNotificationModal(
                notificationModalData?.notification
              )}
            {(notificationModalData?.notification.type ===
              NotificationType.athena ||
              notificationModalData?.notification.type ===
                NotificationType.develo ||
              notificationModalData?.notification.type ===
                NotificationType.ecw ||
              notificationModalData?.notification.type ===
                NotificationType.vax_sync) &&
              renderVaxSyncIntegrationNotificationModal(
                notificationModalData?.notification
              )}
          </>
        )}
      </section>
      <CommentsModal
        open={!!commentModal}
        notification={commentModal?.notification}
        onClose={() => setCommentModal(undefined)}
        onSendNewMessage={async (message) => {
          const refreshedNotification = await refreshNotificationData(
            commentModal?.notification as Notification
          );
          if (!refreshedNotification) return;
          const account = session?.account;
          const newComment: NotificationComment = {
            id: window.crypto.randomUUID(),
            date: new Date(),
            userId: account?.id || '',
            role: account?.role || '',
            name: `${account?.firstName} ${account?.lastName}`,
            message,
            origin: 'IT',
          };
          try {
            const response = await updateNotificationComment({
              variables: {
                id: refreshedNotification.id,
                comments: newComment,
                pendingReadBy: 'PP',
              },
            });
            const newComments =
              response.data.update_communication_notifications_by_pk
                ?.comments || [];
            setCommentModal({
              notification: { ...refreshedNotification, comments: newComments },
            });
          } catch (error) {
            toast({
              title: `Failed to send the comment`,
              type: 'error',
              time: 5000,
            });
            return;
          }
        }}
        source="IT"
      >
        <Card fluid>
          <Card.Content>
            <p style={{ margin: '0 0 0.5rem' }}>
              <b>Message:</b>
            </p>
            <NotificationMessage
              notification={commentModal?.notification as Notification}
            />
          </Card.Content>
        </Card>
      </CommentsModal>
    </>
  );
};
export default NotificationList;
