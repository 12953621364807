import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertMappingsMutation,
  UpdateMappingMutation,
} from '@graphql/mappings';
import { MappedItem } from '@bluefox/models/Mappings';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import { Button, Form } from 'semantic-ui-react';

interface PracticeData {
  id: string;
  name: string;
}

interface VaccineMappingFormProps {
  close: () => void;
  practices?: PracticeData[];
  data: MappedItem | null;
  refetchMappings: () => void;
}

const VaccineMappingForm = ({
  close,
  practices,
  data,
  refetchMappings,
}: VaccineMappingFormProps) => {
  const [ndc, setNdc] = useState<string>(data ? data.key : '');
  const [practiceId, setPracticeId] = useState(data ? data.practice.id : '');
  const [vaccineIdEmr, setVaccineIdEmr] = useState<string>(
    data ? data.metadata[0].keyEmr : ''
  );
  const [vaccineName, setVaccineName] = useState<string>(
    data ? data.metadata[0].nameEmr : ''
  );
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);

  const [saveVaccineMapping] = useMutation(
    data?.id ? UpdateMappingMutation : InsertMappingsMutation
  );

  const handleSubmit = () => {
    saveVaccineMapping({
      variables: {
        id: data?.id,
        practiceId,
        key: ndc,
        metadata: [
          {
            keyEmr: vaccineIdEmr,
            nameEmr: vaccineName,
          },
        ],
        type: 'vaccine',
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!practices || practices.length < 1) return;

    setPracticeOptions(
      practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  return (
    <Form onSubmit={handleSubmit} data-automation-id="vaccine-mapping-form">
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Dropdown
            label="Practice"
            style={{ minWidth: '15rem' }}
            placeholder="Select practice"
            fluid
            selection
            value={practiceId}
            onChange={(e, data) => {
              setPracticeId(data.value?.toString() || '');
            }}
            options={practiceOptions}
            required
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={ndc}
            onChange={(_, { value }) => setNdc(value)}
            fluid
            label="NDC"
            placeholder="NDC"
            required
            data-automation-id="vaccine-mapping-form-ndc"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={vaccineIdEmr}
            onChange={(_, { value }) => setVaccineIdEmr(value)}
            fluid
            label="EMR Vaccine ID"
            placeholder="EMR Vaccine ID"
            required
            data-automation-id="vaccine-mapping-form-emr-vaccine-id"
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={vaccineName}
            onChange={(_, { value }) => setVaccineName(value)}
            fluid
            label="EMR Vaccine Name"
            placeholder="EMR Vaccine Name"
            required
            data-automation-id="vaccine-mapping-form-emr-vaccine-name"
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={close}
          type="button"
          data-automation-id="vaccine-mapping-form-cancel-button"
        >
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          data-automation-id="vaccine-mapping-form-save-button"
        />
      </Form.Field>
    </Form>
  );
};

export default VaccineMappingForm;
