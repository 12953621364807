import { gql } from '@apollo/client';

export const GetColorsQuery = gql`
  query GetColorsQuery {
    colors {
      name
    }
  }
`;

export const GetTags = gql`
  query GetTags {
    tags {
      id
      name
      color
      type
    }
  }
`;

export const CreateTag = gql`
  mutation CreateTag($name: String!, $color: String) {
    insert_tags_one(object: { color: $color, name: $name }) {
      id
      name
      type
      color
    }
  }
`;
