import { Dispatch, SetStateAction, CSSProperties } from 'react';
import { Pagination } from 'semantic-ui-react';

export type PaginationValuesProps = {
  activePage: number;
};

export type Props = {
  paginationValues: PaginationValuesProps;
  setPaginationValues: Dispatch<SetStateAction<PaginationValuesProps>>;
  totalPages: number;
  onPageChange?: (newActivePage: number) => void;
  style?: CSSProperties;
};

const BillingReportListPagination = ({
  paginationValues,
  totalPages,
  setPaginationValues,
  onPageChange,
  style,
}: Props) => {
  return (
    <Pagination
      activePage={paginationValues.activePage}
      totalPages={totalPages}
      onPageChange={(_, { activePage }) => {
        setPaginationValues((prevValues) => ({
          ...prevValues,
          activePage: activePage as number,
        }));
        onPageChange?.(activePage as number);
      }}
      style={style}
    />
  );
};

export default BillingReportListPagination;
