import { gql } from '@apollo/client';

export const OrganizationsByNameQuery = gql`
  query OrganizationsByNameQuery($name: String) {
    organizations(where: { name: { _ilike: $name } }) {
      name
      id
      handler
    }
  }
`;

export const Organizations = gql`
  query organizations {
    organizations(order_by: { name: asc }) {
      handler
      id
      name
      settings
      practices(order_by: { name: asc }) {
        handler
        name
        id
        settings
        npi
        timezone
        suspended
      }
    }
  }
`;

export const InsertOrganizationAndPractice = gql`
  mutation InsertOrganizationAndPractice(
    $handler: String
    $name: String
    $data: [practices_insert_input!] = {}
  ) {
    insertOrganizations: insert_organizations(
      objects: { handler: $handler, name: $name, practices: { data: $data } }
    ) {
      affected_rows
    }
  }
`;

export const UpdatePracticeInitialCredit = gql`
  mutation UpdatePracticeInitialCredit($id: uuid!, $initialCredit: numeric!) {
    updatePracticeInitialCredit: update_practices_by_pk(
      pk_columns: { id: $id }
      _inc: { initialCredit: $initialCredit }
    ) {
      initialCredit
      id
    }
  }
`;

export const OrganizationsSettings = gql`
  query OrganizationsSettings {
    organizations(order_by: { name: asc }) {
      id
      name
      settings
    }
  }
`;

export const UpdateOrganizationSettings = gql`
  mutation MyMutation(
    $organizationId: uuid!
    $settings: jsonb = {}
    $practices: [practices_insert_input!]!
  ) {
    update_organizations_by_pk(
      pk_columns: { id: $organizationId }
      _set: { settings: $settings }
    ) {
      id
      settings
    }
    insert_practices(
      objects: $practices
      on_conflict: {
        constraint: practices_handler_key
        update_columns: settings
      }
    ) {
      affected_rows
    }
  }
`;

export const GetBillingInvoicingStrategies = gql`
  query GetBillingInvoicingStrategies {
    strategies: billing_invoicing_strategies {
      comment
      value
    }
  }
`;

export const GetOrganizationById = (fields: string[]) => gql`
    query GetOrganizationById($id: uuid!) {
      response: organizations_by_pk(id: $id) {
        ${fields.join('\n')}
      }
    }
  `;

export const SubscribeToOrganizationById = (fields: string[]) => gql`
  subscription SubscribeToOrganizationById($id: uuid!) {
    response: organizations_by_pk(id: $id) {
      ${fields.join('\n')}
    }
  }
`;
