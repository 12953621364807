import { ReactNode } from 'react';
import { Button, Popup } from 'semantic-ui-react';

interface FilterPopupProps {
  children: ReactNode;
  header?: string;
  active?: boolean;
}

const FilterPopup = ({ children, header, active }: FilterPopupProps) => {
  return (
    <Popup
      flowing
      hoverable
      on="click"
      closeOnPortalMouseLeave={false}
      trigger={
        <Button
          icon="filter"
          size="tiny"
          basic
          color={active ? 'orange' : undefined}
        />
      }
      header={header}
      content={children}
    />
  );
};

export default FilterPopup;
