import { compactNumber } from '@bluefox/lib/formatters';
import { SemanticColorToString } from '@bluefox/constants';
import EchartsContainer from '@bluefox/ui/EchartsContainer';

type Props = {
  stack1Name: string;
  stack2Name?: string;
  stack1Data: [number, number];
  stack2Data?: [number, number];
  serie1Name: string;
  serie2Name: string;
};

const RecoveredFeesChart = ({
  stack1Name,
  stack2Name,
  stack1Data,
  stack2Data,
  serie1Name,
  serie2Name,
}: Props) => {
  const stacksName = [stack1Name, ...(stack2Name ? [stack2Name] : [])];

  return (
    <EchartsContainer
      options={{
        tooltip: {},
        xAxis: {
          data: stacksName,
          axisLabel: {
            fontWeight: 'bold',
          },
        },
        yAxis: {
          axisLabel: {
            formatter: (value: number) => compactNumber(value),
          },
        },
        grid: {
          top: '10%',
          bottom: '15%',
        },
        series: [
          {
            name: serie1Name,
            data: [
              stack1Data[0],
              ...(stack2Data?.[0] !== undefined ? [stack2Data[0]] : []),
            ],
            itemStyle: {
              color: SemanticColorToString('blue'),
            },
            type: 'bar',
            stack: 'claims',
          },
          {
            name: serie2Name,
            data: [
              stack1Data[1],
              ...(stack2Data?.[1] !== undefined ? [stack2Data[1]] : []),
            ],
            itemStyle: {
              color: SemanticColorToString('teal'),
              borderRadius: [5, 5, 0, 0],
            },
            type: 'bar',
            stack: 'claims',
          },
        ],
      }}
    />
  );
};

export default RecoveredFeesChart;
