import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import { ProcessEcwHistoricalVaccinationManually } from '@bluefox/graphql/integrations/ecw';
import {
  RawHistoricalVaccination,
  historicalVaccinationStatusOptions,
} from '@bluefox/models/integrations/Ecw';

import {
  EcwRawHistoricalVaccinationsQuery,
  UpdateEcwRawHistoricalVaccinationStatusMutation,
} from '@graphql/bot';

import {
  Button,
  Confirm,
  Dropdown,
  Label,
  List,
  Popup,
  Table,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import {
  formatDateStrToMMDDYYYY,
  formatToMMDDYYYY_HHMM,
} from '@bluefox/lib/formatters';
import {
  setDropdownBackgroundColor,
  setDropdownBorderColor,
} from '@bluefox/lib/commonStyles';

type EcwHistoricalVaccinationRowProps = {
  data: RawHistoricalVaccination;
  onSave: () => void;
};

const EcwHistoricalVaccinationsRow = ({
  onSave,
  data,
}: EcwHistoricalVaccinationRowProps) => {
  const [updateVaccinationStatus] = useMutation(
    UpdateEcwRawHistoricalVaccinationStatusMutation
  );

  const [showConfirmRunSub, setShowConfirmRunSub] = useState(false);

  const [runEcwHistoricalVaccination] = useMutation(
    ProcessEcwHistoricalVaccinationManually,
    {
      variables: {
        historicalVaccinationId: data.id,
      },
      onCompleted: () => onSave(),
    }
  );

  const handleVaccinationStatusUpdate = useCallback(
    async (status: string) => {
      try {
        await updateVaccinationStatus({
          variables: {
            id: data.id,
            status,
          },
        });
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      } catch (error) {
        toast({
          title: `Callback error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [updateVaccinationStatus]
  );

  const handleRunHistoricalVaccination = useCallback(async () => {
    try {
      const response = await runEcwHistoricalVaccination();

      if (response.data.processedVaccination.code === 400) {
        toast({
          title: 'There was an error trying to run historical vaccination',
          type: 'error',
          time: 5000,
        });
        return;
      }

      toast({
        title: 'Action ran successfully',
        type: 'success',
        time: 1000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [runEcwHistoricalVaccination, onSave]);

  return (
    <Table.Row>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor: setDropdownBackgroundColor(data?.status),
            borderColor: setDropdownBorderColor(data?.status),
            maxWidth: '8rem',
          }}
          fluid
          selection
          options={historicalVaccinationStatusOptions}
          value={data?.status}
          onChange={(e, data) => {
            handleVaccinationStatusUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>{data.statusReason ? data.statusReason : '-'}</Table.Cell>
      <Table.Cell>
        <div>
          <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
            MRN: {data.mrn}
          </p>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div>
          {data.name}
          <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
            Given Date: {formatDateStrToMMDDYYYY(data.givenDate) || '-'}
          </p>
          <p>Immunization ID: {data.immId}</p>
        </div>
      </Table.Cell>
      <Table.Cell>
        {data.error ? (
          <Popup
            on="click"
            trigger={<Button size="tiny" content="Show Error" />}
            position="top right"
            content={data.error.message || '-'}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {formatToMMDDYYYY_HHMM(data.createdAt.toString())}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          trigger={
            <Button
              size="tiny"
              color="teal"
              icon="play"
              onClick={() => setShowConfirmRunSub(true)}
              disabled={data.status !== 'error'}
            />
          }
          content="Run Historical Vaccination"
        />
      </Table.Cell>
      <Confirm
        size="mini"
        content="Are you sure you want to run historical vaccination?"
        confirmButton="Yes"
        open={showConfirmRunSub}
        onCancel={() => setShowConfirmRunSub(false)}
        onConfirm={() => {
          handleRunHistoricalVaccination();
          setShowConfirmRunSub(false);
        }}
      />
    </Table.Row>
  );
};

export default EcwHistoricalVaccinationsRow;
