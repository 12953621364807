import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Container, Form, Menu, Message, Table } from 'semantic-ui-react';
import {
  GetOnboardingJobDetails,
  GetOnboardingStatuses,
} from '@graphql/vaccinations';
import { useParams } from 'react-router-dom';

import { usePractice } from '@bluefox/contexts/Practice';

import MainLayout from '@ui/MainLayout';

import Pagination from '@bluefox/ui/Pagination';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';
import { Enum } from '@bluefox/models/Generics';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { debounce } from '@bluefox/lib/debounce';

interface Immunization {
  id: string;
  patno: string;
  idEmr: string;
  status: string;
  statusReason: string;
  vaccineName: string;
  vaccineDate: Date;
}

export interface Option {
  value: string;
  comment: string;
}

enum Status {
  COMPLETED = 'completed',
  ERROR = 'error',
  PARTIALLY_COMPLETED = 'partially_completed',
  PENDING = 'pending',
  REMOVED = 'removed',
  RUNNING = 'running',
}

enum StatusReasons {
  DUPLICATED_PATIENT = 'duplicated_patient',
  DUPLICATED_VACCINATION_FROM_IMPORT = 'duplicated_vaccination_from_import',
  INSERTED = 'inserted',
  MISSING_MAPPING = 'missing_mapping',
  MISSING_PATIENT = 'missing_patient',
  VACCINE_TYPE_ALREADY_EXISTS = 'vaccine_type_already_exists',
}

const UpdateVaccinesCsvTableJobDetails = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const practice = usePractice();
  const [page, setPage] = useState(1);
  const [statusOptions, setStatusOptions] = useState<Enum[]>([]);
  const [statusReasonOptions, setStatusReasonOptions] = useState<Enum[]>([]);
  const [statusSearch, setStatusSearch] = useState<string>('allStatus');
  const [statusReasonSearch, setStatusReasonSearch] =
    useState<string>('allStatus');
  const [patientValue, setPatientValue] = useState<string>('');
  const [patientSearch, setPatientSearch] = useState<string>('');
  const ENTRIES_PER_PAGE = 20;

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const { data } = useQuery(GetOnboardingJobDetails, {
    variables: {
      criteria: {
        id: { _eq: jobId },
      },
      immunizationsCriteria: {
        status: statusSearch !== 'allStatus' ? { _eq: statusSearch } : {},
        statusReason:
          statusReasonSearch !== 'allStatus' ? { _eq: statusReasonSearch } : {},
        patno: patientSearch ? { _ilike: whereLikeInput(patientSearch) } : {},
      },
      limit: ENTRIES_PER_PAGE,
      offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
    },
  });

  const { data: statusData } = useQuery(GetOnboardingStatuses, {
    onCompleted(data) {
      setStatusOptions(
        data.statusEnum.map((status: Option) => {
          return {
            value: status.value,
            text: status.comment,
          };
        })
      );
      setStatusReasonOptions(
        data.statusReasonEnum.map((status: Option) => {
          return {
            value: status.value,
            text: status.comment,
          };
        })
      );
    },
  });

  useEffect(() => {
    if (!statusData || !statusOptions) return;
    if (statusSearch === 'allStatus') {
      setStatusReasonOptions(
        statusData.statusReasonEnum.map((status: Option) => {
          return {
            value: status.value,
            text: status.comment,
          };
        })
      );
    } else if (statusSearch === Status.ERROR) {
      //error
      const reasons = [
        {
          value: StatusReasons.DUPLICATED_PATIENT,
          comment: humanizeText(StatusReasons.DUPLICATED_PATIENT, {
            delimiter: '_',
            capitalize: 'all',
          }),
        },
        {
          value: StatusReasons.MISSING_MAPPING,
          comment: humanizeText(StatusReasons.MISSING_MAPPING, {
            delimiter: '_',
            capitalize: 'all',
          }),
        },
        {
          value: StatusReasons.MISSING_PATIENT,
          comment: humanizeText(StatusReasons.MISSING_PATIENT, {
            delimiter: '_',
            capitalize: 'all',
          }),
        },
      ];
      setStatusReasonOptions(
        reasons.map((status: Option) => {
          return {
            value: status.value,
            text: status.comment,
          };
        })
      );
    } else if (statusSearch === Status.COMPLETED) {
      //completed
      const reasons = [
        {
          value: StatusReasons.DUPLICATED_VACCINATION_FROM_IMPORT,
          comment: humanizeText(
            StatusReasons.DUPLICATED_VACCINATION_FROM_IMPORT,
            {
              delimiter: '_',
              capitalize: 'all',
            }
          ),
        },
        {
          value: StatusReasons.INSERTED,
          comment: humanizeText(StatusReasons.INSERTED, {
            delimiter: '_',
            capitalize: 'all',
          }),
        },
        {
          value: StatusReasons.VACCINE_TYPE_ALREADY_EXISTS,
          comment: humanizeText(StatusReasons.VACCINE_TYPE_ALREADY_EXISTS, {
            delimiter: '_',
            capitalize: 'all',
          }),
        },
      ];
      setStatusReasonOptions(
        reasons.map((status: Option) => {
          return {
            value: status.value,
            text: status.comment,
          };
        })
      );
    } else {
      //other statuses
      setStatusReasonOptions([]);
    }
    setStatusReasonSearch('allStatus');
  }, [statusData, statusOptions, statusSearch]);

  const total = data?.job[0]?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        {
          text: 'Vaccine Update Table',
          to: `/practices/${practice.handler}/updateVaccinationsCsvTable`,
        },
        { text: 'Job Details' },
      ]}
    >
      <Container>
        <Table celled structured selectable>
          {data?.job[0] ? (
            <Table.Row textAlign="center">
              <Table.HeaderCell>
                Upload Number: {data?.job[0]?.uploadNumber}
              </Table.HeaderCell>
              <Table.HeaderCell>
                Upload Date: {formatDatetimeToMMDDYYY(data?.job[0]?.createdAt)}
              </Table.HeaderCell>
              <Table.HeaderCell>
                Status:{' '}
                {humanizeText(data?.job[0]?.status, {
                  capitalize: 'all',
                })}
              </Table.HeaderCell>
            </Table.Row>
          ) : null}
        </Table>

        <Menu borderless style={{ display: 'flex' }}>
          <Menu.Menu>
            <Menu.Item>
              <Form>
                <Form.Input
                  label="Filter by PATNO/MRN"
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by PATNO/MRN"
                  value={patientValue}
                  onChange={(e, { value }) => {
                    setPatientValue(value as string);
                    debouncedRef.current?.cancel();
                    debouncedRef.current = debounce(() => {
                      setPatientSearch(value as string);
                      setPage(1);
                    }, 500);
                    debouncedRef.current();
                  }}
                />
              </Form>
            </Menu.Item>
            <Menu.Item>
              <Form>
                <Form.Dropdown
                  label="Filter by Status"
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by Status"
                  selection
                  value={statusSearch}
                  onChange={(e, { value }) => {
                    setStatusSearch(value as string);
                    setPage(1);
                  }}
                  options={[
                    { text: 'All Status', value: 'allStatus' },
                    ...statusOptions.sort((a, b) => {
                      const valueA = a.value.toUpperCase();
                      const valueB = b.value.toUpperCase();
                      if (valueA < valueB) {
                        return -1;
                      }
                      if (valueA > valueB) {
                        return 1;
                      }
                      return 0;
                    }),
                  ]}
                />
              </Form>
            </Menu.Item>
            <Menu.Item>
              <Form>
                <Form.Dropdown
                  label="Filter by Status Reason"
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by Status Reason"
                  selection
                  value={statusReasonSearch}
                  onChange={(e, { value }) => {
                    setStatusReasonSearch(value as string);
                    setPage(1);
                  }}
                  options={[
                    { text: 'All Status', value: 'allStatus' },
                    ...statusReasonOptions.sort((a, b) => {
                      const valueA = a.value.toUpperCase();
                      const valueB = b.value.toUpperCase();
                      if (valueA < valueB) {
                        return -1;
                      }
                      if (valueA > valueB) {
                        return 1;
                      }
                      return 0;
                    }),
                  ]}
                  disabled={!statusReasonOptions.length}
                />
              </Form>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Table celled structured selectable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>PATNO/MRN</Table.HeaderCell>
              <Table.HeaderCell>EMR ID</Table.HeaderCell>
              <Table.HeaderCell>Vaccine Name</Table.HeaderCell>
              <Table.HeaderCell>Vaccine Date</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Reason</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.job[0]?.immunizations ? (
              data?.job[0]?.immunizations.map((i: Immunization) => (
                <Table.Row key={i.id} textAlign="center">
                  <Table.Cell>{i.patno}</Table.Cell>
                  <Table.Cell>{i.idEmr}</Table.Cell>
                  <Table.Cell>{i.vaccineName}</Table.Cell>
                  <Table.Cell>
                    {formatDatetimeToMMDDYYY(i.vaccineDate)}
                  </Table.Cell>
                  <Table.Cell>
                    {i.status
                      ? humanizeText(i.status, {
                          delimiter: '_',
                          capitalize: 'all',
                        })
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {i.statusReason
                      ? humanizeText(i.statusReason, {
                          delimiter: '_',
                          capitalize: 'all',
                        })
                      : '-'}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Cell colSpan={5}>
                <Message>No immunizations found for this job</Message>
              </Table.Cell>
            )}
          </Table.Body>
          <Pagination
            total={total}
            colSpan={5}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
      </Container>
    </MainLayout>
  );
};

export default UpdateVaccinesCsvTableJobDetails;
