import GS1 from '@bluefox/lib/gs1';

import GunScanner, { GunScannerProps } from './GunScanner';

interface GS1GunScannerProps extends GunScannerProps {
  onGS1Scan?: (gs1: GS1, code: string) => void;
  onError?: () => void;
  testValue?: string;
}

const GS1GunScanner = ({
  onGS1Scan,
  onError,
  testValue,
  ...rest
}: GS1GunScannerProps) => {
  const handleOnScan = (value: string) => {
    try {
      const newValue = testValue && testValue !== '' ? testValue : value;
      const gs1 = new GS1(newValue);
      if (onGS1Scan) onGS1Scan(gs1, newValue);
    } catch (e) {
      console.error(e);
      if (onError) onError();
    }
  };

  return <GunScanner onScan={handleOnScan} testValue={testValue} {...rest} />;
};

export default GS1GunScanner;
