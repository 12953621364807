import { usDollarCurrency } from '@bluefox/lib/formatters';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

const footerStyle = StyleSheet.create({
  section: {
    position: 'absolute',
    bottom: 0,
    padding: '32 40',
    backgroundColor: '#F5FAFE',
  },
  message: {},
  messageHeader: {
    margin: 'auto',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16 / 14,
    letterSpacing: 0,
    textAlign: 'center',
  },
  messageText: {
    margin: '4 auto 0',
    padding: '0 95',
    fontSize: 8,
    fontWeight: 400,
    lineHeight: 16 / 8,
    letterSpacing: 0,
    textAlign: 'center',
  },
  messageBoldText: {
    fontWeight: 700,
  },
});

const messageStyle = StyleSheet.create({
  section: {},
  header: {
    margin: 'auto',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16 / 14,
    letterSpacing: 0,
    textAlign: 'center',
  },
  text: {
    margin: '4 auto 0',
    padding: '0 95',
    fontSize: 8,
    fontWeight: 400,
    lineHeight: 16 / 8,
    letterSpacing: 0,
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 700,
  },
});

const boxesStyle = StyleSheet.create({
  section: {
    width: 'auto',
    margin: '16 32 16 0',
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  box: {
    width: '33.3333%',
    border: '1 solid #D7E0EA',
    borderRadius: 8,
    padding: '12 16',
    textAlign: 'center',
  },
  amountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 'auto',
  },
  amount: {
    fontSize: 8,
    fontWeight: 500,
    lineHeight: 12 / 8,
    letterSpacing: 1,
    color: '#4E657E',
    alignItems: 'center',
  },
  amountBold: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 24 / 18,
    letterSpacing: 0,
    color: '#16324F',
    alignItems: 'center',
  },
  description: {
    marginTop: 4,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 14 / 10,
    letterSpacing: 0,
    color: '#16324F',
    minHeight: 32, // Needed to make the text inside each bubble equal
  },
});

export type FooterProps = {
  pcAdminFeeClaimed?: number;
  vcfAdminFeeClaimed?: number;
  timeRestored?: number;
};

const Footer = ({
  pcAdminFeeClaimed,
  vcfAdminFeeClaimed,
  timeRestored,
}: FooterProps) => {
  return (
    <View style={footerStyle.section}>
      <View style={messageStyle.section}>
        <Text style={messageStyle.header}>Optimizing your resources</Text>
        <Text style={messageStyle.text}>
          Partnering with <Text style={messageStyle.boldText}>Canid</Text>{' '}
          ensures you have the time and financial resources to provide the best
          care possible. Our commitment is to continually empower your practice.
        </Text>
      </View>
      <View style={boxesStyle.section}>
        <View style={boxesStyle.box}>
          <View style={boxesStyle.amountWrapper}>
            <Text style={boxesStyle.amountBold}>
              {usDollarCurrency(pcAdminFeeClaimed || 0)}
            </Text>
            <Text style={boxesStyle.amount}> USD</Text>
          </View>
          <Text style={boxesStyle.description}>
            Private vaccine admin fee claimed
          </Text>
        </View>
        <View style={boxesStyle.box}>
          <View style={boxesStyle.amountWrapper}>
            <Text style={boxesStyle.amountBold}>
              {usDollarCurrency(vcfAdminFeeClaimed || 0)}
            </Text>
            <Text style={boxesStyle.amount}> USD</Text>
          </View>
          <Text style={boxesStyle.description}>VFC admin fee claimed</Text>
        </View>
        <View style={boxesStyle.box}>
          <View style={boxesStyle.amountWrapper}>
            <Text style={boxesStyle.amountBold}>{timeRestored || 0}</Text>
            <Text style={boxesStyle.amount}> HOURS</Text>
          </View>
          <Text style={boxesStyle.description}>Your time restored</Text>
        </View>
      </View>
    </View>
  );
};

export default Footer;
