import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { Card, Button, Input, Popup } from 'semantic-ui-react';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';

interface InfoBoxProps {
  firstName: string;
  lastName: string;
  memberId: string | number;
  birthdate: Date | undefined;
  serviceDate: Date | undefined;
  vaxName: string;
  lot: string;
}

const BorrowingInfoBox = ({
  firstName,
  lastName,
  memberId,
  birthdate,
  serviceDate,
  vaxName,
  lot,
}: InfoBoxProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCopyPass = (e: any) => {
    const input =
      e.target.nodeName === 'I'
        ? e.target.parentElement.parentElement.firstChild.value
        : e.target.parentElement.firstChild.value;
    navigator.clipboard.writeText(input);

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  return (
    <Popup
      on="click"
      pinned
      position="left center"
      content={
        <Card>
          <Card.Content>
            <Card.Header
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              Useful Information
              <Button basic size="mini" icon="close" onClick={handleClose} />
            </Card.Header>
            <Card.Description style={{ height: '25rem', overflowY: 'scroll' }}>
              <div>
                <label>
                  <b>First Name</b>
                </label>
                <StyledInput
                  fluid
                  value={firstName}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Last Name</b>
                </label>
                <StyledInput
                  fluid
                  value={lastName}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Birthdate</b>
                </label>
                <StyledInput
                  fluid
                  value={
                    birthdate
                      ? formatDatetimeToMMDDYYY(birthdate).replaceAll('-', '/')
                      : '-'
                  }
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Member ID</b>
                </label>
                <StyledInput
                  fluid
                  value={memberId}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Service Date</b>
                </label>
                <StyledInput
                  fluid
                  value={
                    serviceDate
                      ? formatDatetimeToMMDDYYY(serviceDate).replaceAll(
                          '-',
                          '/'
                        )
                      : '-'
                  }
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Vax Name</b>
                </label>
                <StyledInput
                  fluid
                  value={vaxName}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Lot</b>
                </label>
                <StyledInput
                  fluid
                  value={lot}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
            </Card.Description>
          </Card.Content>
        </Card>
      }
      trigger={
        <Button
          className="min-component-vertical-margin"
          icon="copy"
          size="tiny"
        />
      }
      open={isOpen}
      onOpen={handleOpen}
    />
  );
};

export default BorrowingInfoBox;

const StyledInput = styled(Input)`
  margin: 0rem 0rem 1rem 0rem;
`;
