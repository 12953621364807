import React, { useState } from 'react';
import { GetTags } from '@graphql/tags';
import { useMutation, useQuery } from '@apollo/client';
import { Color, GetTagsResponse } from '@bluefox/models/Tags';
import { Grid, Loader, Segment } from 'semantic-ui-react';
import ColorsSelector from '@ui/Tags/colorsSelector';
import TagCreator from '@ui/Tags/tagCreator';
import TagsList from '@ui/Tags/tagsList';

const TagsForm = () => {
  // Start States
  const [selectedColor, setSelectedColor] = useState<Color | undefined>();
  // End States

  // Start Queries & Mutations

  const {
    data: getTagsResponse,
    refetch: refetchGetTags,
    loading: loadingGetTags,
  } = useQuery<GetTagsResponse>(GetTags);

  // End Queries & Mutations

  // START LOGIC

  // END LOGIC

  return (
    <Grid padded celled="internally" columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <TagCreator
              onSaveTag={() => {
                refetchGetTags();
                setSelectedColor(undefined);
              }}
              selectedColor={selectedColor}
            />
          </Segment>
          <Segment>
            <ColorsSelector
              onSelectColor={(color) => setSelectedColor(color)}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <TagsList tags={getTagsResponse?.tags} />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TagsForm;
