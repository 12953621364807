export const vaccineBorrowingReportCodes = {
  DEFAULT: {
    vfc: {
      1: 'Private vaccine shipment delay (vaccine order placed on time/delay in shipping)',
      2: 'Private vaccine not useable on arrival (vials broken, temperature monitor out of range)',
      3: 'Ran out of private vaccine between orders (not due to shipping delays)',
      4: 'Short-dated private dose was exchanged with VFC dose',
      5: 'Accidental use of VFC dose for a private patient',
      6: 'Replacement of Private dose with VFC when insurance plan did not cover vaccine',
      7: 'Other – Describe:',
    },
    private: {
      8: 'VFC vaccine shipment delay (order placed on time/delay in shipping)',
      9: 'VFC vaccine not useable on arrival (vials broken, temperature monitor out of range)',
      10: 'Ran out of VFC vaccine between orders (not due to shipping delays)',
      11: 'Short-dated VFC dose was exchanged with private dose',
      12: 'Accidental use of a Private dose for a VFC eligible patient',
      13: 'Other – Describe:',
    },
  },
  CO: {
    vfc: {
      1: 'Private vaccine shipment delay',
      2: 'Private vaccine not usable on arrival',
      3: 'Ran out of private vaccine between orders (not due to shipment delay)',
      4: 'Expiring private dose exchanged with VFC dose',
      5: 'Accidental use of VFC dose for private patient',
      6: 'Replacement of private dose with VFC when insurance did not cover vaccine',
      7: 'Other (Description required)',
    },
    private: {
      8: 'VFC vaccine shipment delay',
      9: 'VFC vaccine not usable on arrival',
      10: 'Ran out of VFC vaccine between orders (not due to shipment delay)',
      11: 'Expiring VFC dose exchanged with private dose',
      12: 'Accidental use of private dose for VFC patient',
      13: 'Other (Description required)',
      14: '317 Usage (describe reason for using 317 stock on back)',
    },
  },
  MN: {
    vfc: {
      1: 'Private vaccine shipment delay (vaccine order placed on time/delay in shipping).',
      2: 'Private vaccine not useable on arrival (vials broken, temperature monitor out of range).',
      3: 'Ran out of private vaccine between orders (not due to shipping delays).',
      4: 'Short-dated private dose was exchanged with MnVFC dose.',
      5: 'Accidental use of a MnVFC dose for a privately insured patient.',
      6: 'Replacement of private dose with MnVFC when insurance plan did not cover vaccine.',
      7: 'Other – Describe:',
    },
    private: {
      8: 'MnVFC vaccine shipment delay (order placed on time/delay in shipping).',
      9: 'MnVFC vaccine not useable on arrival (vials broken, temperature monitor out of range).',
      10: 'Ran out of MnVFC vaccine between orders (not due to shipping delays).',
      11: 'Short-dated MnVFC dose was exchanged with private dose.',
      12: 'Accidental use of private dose for MnVFC-eligible child.',
      13: 'Other – Describe:',
    },
  },
  NY: {
    vfc: {
      1: 'Private vaccine shipment delay (vaccine order placed on time/delay in shipping)',
      2: 'Private vaccine not useable on arrival (vials broken, temperature monitor out of range)',
      3: 'Ran out of private vaccine between orders (not due to shipping delays)',
      4: 'Short-dated private dose was exchanged with VFC dose',
      5: 'Accidental use of VFC dose for a private patient',
      6: 'Replacement of Private dose with VFC when insurance plan did not cover vaccine',
      7: 'Other – Describe:',
    },
    private: {
      8: 'VFC vaccine shipment delay (order placed on time/delay in shipping)',
      9: 'VFC vaccine not useable on arrival (vials broken, temperature monitor out of range)',
      10: 'Ran out of VFC vaccine between orders (not due to shipping delays)',
      11: 'Short-dated VFC dose was exchanged with private dose',
      12: 'Accidental use of a Private dose for a VFC eligible patient',
      13: 'Other – Describe:',
    },
  },
  TX: {
    vfc: {
      1: 'Private vaccine shipment delay (vaccine order placed on time/delay in shipping)',
      2: 'Private vaccine not useable on arrival (vials broken, temperature monitor out of range)',
      3: 'Ran out of private vaccine between orders (not due to shipping delays)',
      4: 'Short-dated private dose was exchanged with TVFC/ASN dose',
      5: 'Accidental use of TVFC/ASN dose for a private patient',
      6: 'Accidental use of ASN dose for a TVFC patient.',
      7: 'Accidental use of TVFC dose for an ASN patient.',
      8: 'Replacement of Private dose with TVF/ASN when insurance plan did not cover vaccine',
      9: 'Other – Describe:',
    },
    private: {
      10: 'TVFC/ASN vaccine shipment delay (order placed on time/delay in shipping)',
      11: 'TVFC/ASN vaccine not useable on arrival (vials broken, temperature monitor out of range)',
      12: 'Ran out of TVFC/ASN vaccine between orders (not due to shipping delays)',
      13: 'Short-dated TVFC/ASN dose was exchanged with private dose',
      14: 'Accidental use of a Private dose for a TVFC-eligible patient',
      15: 'Accidental use of a Private dose for an ASN-eligible patient.',
      16: 'Other – Describe:',
    },
  },
} as const;

export type StateKey = keyof typeof vaccineBorrowingReportCodes;

function getVaccineBorrowingReportCodes(state: string) {
  if (Object.keys(vaccineBorrowingReportCodes).includes(state)) {
    return vaccineBorrowingReportCodes[state as StateKey];
  } else {
    return vaccineBorrowingReportCodes['DEFAULT'];
  }
}

const isObjectEmpty = (objectName: {}) => {
  return Object.keys(objectName).length === 0;
};

export function VaccineBorrowingReportReasonCodes(
  state: string,
  vfc_private: boolean
) {
  const stateCodes = getVaccineBorrowingReportCodes(state);
  const verified = isObjectEmpty(stateCodes);
  const vfc = vfc_private ? 'vfc' : 'private';
  if (!verified) {
    const options = Object.entries(stateCodes[vfc]).map(([code, text]) => ({
      value: code,
      text: `${code}: ${text}`,
    }));
    return options;
  }
}
