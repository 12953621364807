import React, { useMemo } from 'react';
import {
  Notification,
  OrderNotificationContent,
} from '@bluefox/models/Notification';

type Props = {
  notification: Notification;
};

const OrderNotificationMessage = ({ notification }: Props) => {
  const content = useMemo(
    () => notification.content as OrderNotificationContent,
    [notification.content]
  );

  return (
    <>
      <p>
        Based on our records, the order <b> {content.orderNumber} </b> for{' '}
        <b>{content.doses}</b> doses of <b>{content.fullVaccineName}</b> was
        received. Please confirm the order reception
      </p>
      {notification.note && (
        <p>
          <b>Rejected message:</b> {notification.note}
        </p>
      )}
    </>
  );
};

export default OrderNotificationMessage;
