import { purchasePlatforms } from './InventoryOrders';

export enum DiscountTypeEnum {
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}

export enum DiscountReasonEnum {
  ONLINE_PURCHASE = 'online_purchase',
  PAYMENT_ON_TIME = 'payment_on_time',
}

export interface ContractPlan {
  id: string;
  type: string;
  name: string;
  discounts: ContractPlanDiscount[];
  startDate?: Date;
  endDate?: Date;
  vaccinePricings: any;
}

export interface ContractPlanDiscount {
  type: string;
  reason: string;
  discount: number;
}
