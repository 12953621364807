import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  AthenaMappingsQuery,
  DeleteMappingMutation,
} from '@bluefox/graphql/integrations/athena';
import { MappedItem } from '@bluefox/models/integrations/Athena';
import {
  Segment,
  Card,
  Container,
  Table,
  Placeholder,
  Message,
  Button,
  Popup,
  Modal,
  Menu,
  Icon,
  Pagination,
  Input,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import AthenaInsuranceCompanyMappingForm from './AthenaInsuranceCompanyMappingForm';

interface AthenaMappingData {
  athenaMappings: MappedItem[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const ENTRIES_PER_PAGE = 15;

const AthenaInsuranceCompanyMapping = () => {
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<MappedItem>();
  const [criteria, setCriteria] = useState({});
  const [searchPractice, setSearchPractice] = useState<string>('');
  const [page, setPage] = useState(0);

  const { data, loading, refetch } = useQuery<AthenaMappingData>(
    AthenaMappingsQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const closeModal = () => {
    setIsSelected(false);
    setSelectedMapping(undefined);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  useEffect(() => {
    let _criteria = {};

    _criteria = {
      type: { _eq: 'insuranceCompany' },
      ...(searchPractice && searchPractice.length >= 3
        ? { key: { _ilike: `%${searchPractice}%` } }
        : {}),
    };

    setCriteria(_criteria);
  }, [searchPractice]);

  return (
    <MainLayout
      path={[
        { text: 'Athena Integration', to: '/integration-athena' },
        { text: 'Insurance Company Mapping' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="map outline" style={{ marginRight: '0.6rem' }} />
                Insurance Company Mapping
              </Card.Header>
            </div>
            <Menu borderless>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Input
                    placeholder="Search Insurance Company..."
                    icon="search"
                    value={searchPractice}
                    onChange={(_, { value }) => setSearchPractice(value)}
                  />
                </Menu.Item>
                <Menu.Item>
                  <Button
                    primary
                    size="small"
                    content="Add Insurance Company"
                    icon="plus"
                    onClick={() => {
                      setIsSelected(false);
                      setOpen(true);
                    }}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Insurance Company (Athena)
                  </Table.HeaderCell>
                  <Table.HeaderCell>Insurance Company (Canid)</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data && data.athenaMappings.length ? (
                  data.athenaMappings.map((item) => {
                    return (
                      <AthenaInsuranceCompanyMappingRow
                        key={item.id}
                        data={item}
                        onChangeSelected={() => {
                          handleChangeSelected(true);
                          setSelectedMapping({
                            id: item.id,
                            key: item.key,
                            type: item.type,
                            value: item.value,
                          });
                        }}
                        onDelete={refetch}
                      />
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <Message>No mappings found.</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                  <Table.HeaderCell colSpan={3} textAlign="right">
                    <Pagination
                      disabled={!total || total < ENTRIES_PER_PAGE}
                      defaultActivePage={1}
                      boundaryRange={0}
                      siblingRange={1}
                      onPageChange={(e, { activePage }) =>
                        setPage(activePage as number)
                      }
                      totalPages={totalPages}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Modal
              size="small"
              onClose={closeModal}
              onOpen={() => setOpen(open)}
              open={open}
              closeIcon
            >
              <Modal.Header>
                {isSelected
                  ? 'Edit Insurance Company Mapping'
                  : 'Add Insurance Company Mapping'}
              </Modal.Header>
              <Modal.Content>
                <AthenaInsuranceCompanyMappingForm
                  close={closeModal}
                  data={selectedMapping ? selectedMapping : null}
                  refetchMappings={refetch}
                  onSave={refetch}
                />
              </Modal.Content>
            </Modal>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//------------------------------AthenaInsuranceCompanyMappingRow------------------------------//

interface AthenaInsuranceCompanyMappingRowProps {
  data: MappedItem;
  onChangeSelected: (selected: boolean) => void;
  onDelete: () => void;
}

const AthenaInsuranceCompanyMappingRow = ({
  data,
  onChangeSelected,
  onDelete,
}: AthenaInsuranceCompanyMappingRowProps) => {
  const [deleteMapping] = useMutation(DeleteMappingMutation);

  const handleDeleteMapping = () => {
    deleteMapping({
      variables: {
        mappingId: data.id,
      },
    })
      .then(() => {
        toast({
          title: 'Mapping deleted successfully',
          type: 'success',
          time: 1000,
        });
        onDelete();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row>
      <Table.Cell>{data.key}</Table.Cell>
      <Table.Cell>{data.value.name}</Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
        <Popup
          size="small"
          content="Delete"
          trigger={
            <Button
              negative
              size="mini"
              icon="trash"
              onClick={() => handleDeleteMapping()}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default AthenaInsuranceCompanyMapping;
