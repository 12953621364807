import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { toast } from 'react-semantic-toasts';

import ProviderPicker from '@bluefox/ui/ProviderPicker';

import { PracticeTimezoneQuery } from '@bluefox/graphql/practices';
import {
  BillingClaimFormQuery,
  ClaimUpdatesQuery,
  GetBorrowingByClaimId,
  SaveClaimUpdateMutation,
  SaveClaimUpdateWithInsertMutation,
} from '@graphql/billing';
import {
  BillingClaimStatus,
  claimDismissedOptions,
  CptCode,
  eobStatusOptions,
  EobStatus,
  toAppealOptions,
  toCorrectOptions,
  ClaimUpdate,
  borrowingStatusOptions,
  directChargeStatusOptions,
  BillingClaim,
} from '@bluefox/models/Billing';
import { VFC_OPTIONS, VfcCriteria } from '@bluefox/models/Insurances';
import {
  Button,
  Card,
  Dropdown,
  Form,
  Header,
  Label,
  Modal,
} from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import CptCodeForm from '@ui/Billing/CptCodeForm';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import { useSession } from '@bluefox/contexts';
import ClaimStatusLog from './ClaimStatusLog';
import { DateFormats } from '@bluefox/models/Dates';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import { useBillingClaimStatusOptions } from 'store/BillingClaimStatusOptions';

interface UpdateNote {
  createdAt: Date;
  url: string;
}

interface Link {
  createdAt: string;
  url: string;
}

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface ClaimByPkData {
  claim: BillingClaim;
}
interface UpdateBillingClaimFormProps {
  claimId: string;
  adminCptCodes?: ProcedureAmountsData;
  onClose: () => void;
  onSave: () => void;
  readonly?: boolean;
}

interface ClaimUpdates {
  claimUpdates: ClaimUpdate[];
}

const UpdateBillingClaimForm = ({
  claimId,
  adminCptCodes,
  onClose,
  onSave,
  readonly = false,
}: UpdateBillingClaimFormProps) => {
  const practiceAccount = useSession();
  const [notes, setNotes] = useState('');
  const [link, setLink] = useState('');
  const [claimControlNumber, setClaimControlNumber] = useState('');
  const [rhcClaimNumber, setRhcClaimNumber] = useState('');
  const [inMediation, setInMediation] = useState<Date | undefined | null>(null);
  const [checkDate, setCheckDate] = useState<Date | undefined | null>(null);
  const [checkNumber, setCheckNumber] = useState('');
  const [claimStatus, setClaimStatus] = useState<BillingClaimStatus>(
    BillingClaimStatus.PENDING
  );
  const [dismissedReason, setDismissedReason] = useState<string | null>(null);
  const [eobStatus, setEobStatus] = useState<string | null>(null);
  const [appealMethod, setAppealMethod] = useState<string | null>(null);
  const [fieldToCorrect, setFieldToCorrect] = useState<string | null>(null);
  const [borrowingStatus, setBorrowingStatus] = useState<string | null>(null);
  const [directChargeStatus, setDirectChargeStatus] = useState<string | null>(
    null
  );
  const [directChargeConfirmationReason, setDirectChargeConfirmationReason] =
    useState<string | null>(null);

  const [cptCodes, setCptCodes] = useState<CptCode[]>([]);

  const [insuranceName, setInsuranceName] = useState<string>('');
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<string>('');

  const [payerId, setPayerId] = useState<string>('');
  const [memberId, setMemberId] = useState<string>('');
  const [isVfcEligible, setIsVfcEligible] = useState<boolean>();

  const [vfcCriteria, setVfcCriteria] = useState<string>('');

  const [switchMutation, setSwitchMutation] = useState(false);

  const [eobStatusToDownloaded, setEobStatusToDownloaded] = useState(false);

  const [eobLinkUrlValid, setEobLinkUrlValid] = useState(true);
  const [prescriberId, setPrescriberId] = useState<string>('');

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const [hasBorrowingAndIsUpdatable, setHasBorrowingAndIsUpdatable] =
    useState(false);

  const handleCptCodeFormChange = (value: boolean) => {
    setSaveButtonDisabled(value);
  };

  const {
    data: claimUpdatesData,
    loading: claimUpdatesLoading,
    refetch: claimUpdatesRefetch,
  } = useQuery<ClaimUpdates>(ClaimUpdatesQuery, {
    variables: { claimId },
  });

  const validateUrl = (value: string): boolean => {
    if (value === null || value === '') return true;
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const { data, refetch } = useQuery<ClaimByPkData>(BillingClaimFormQuery, {
    variables: {
      claimId,
    },
  });

  const [getPracticeTimezone, { data: practiceTimezone }] = useLazyQuery(
    PracticeTimezoneQuery
  );

  const [saveClaimUpdate] = useMutation(
    switchMutation
      ? SaveClaimUpdateWithInsertMutation(!!data?.claim.vaccinations.length)
      : SaveClaimUpdateMutation
  );

  const [
    getBorrowing,
    {
      data: borrowingData,
      loading: borrowingLoading,
      error: borrowingError,
      refetch: viewRefetch,
    },
  ] = useLazyQuery(GetBorrowingByClaimId, {
    onCompleted: (data) => {
      //when OK, response has borrowings array.  At least empty
      //when ERROR,response hasn't got it.
      setHasBorrowingAndIsUpdatable(
        !!data?.getBorrowingByClaimId?.body?.borrowings
      );
    },
    onError: (err) => {
      setHasBorrowingAndIsUpdatable(false);
    },
  });

  const canUpdateVfc = useCallback(
    (status: BillingClaimStatus) => {
      if (status === BillingClaimStatus.CORRECTED) {
        getBorrowing({
          variables: {
            claimId: data?.claim.id,
          },
        });
      }
    },
    [getBorrowing, data?.claim.id]
  );

  const renderEobLinks = () => {
    let eobLinks = new Map<string, Link>();

    data?.claim.updateNotes.forEach((cu: UpdateNote) => {
      const createdAt = moment(cu.createdAt).format(DateFormats.DATE);

      const alreadyExists = eobLinks.get(cu.url);
      if (
        !alreadyExists ||
        moment(createdAt).isBefore(
          moment(alreadyExists.createdAt).format(DateFormats.DATE)
        )
      ) {
        eobLinks.set(`${cu.url}`, {
          url: cu.url,
          createdAt: createdAt,
        });
      }
    });

    return Array.from(eobLinks.values()).map((link: Link, index) => {
      if (link.url) {
        return (
          <div style={{ margin: '0.5rem 0' }}>
            <a href={link.url} target="_blank">
              {link.url}
            </a>
            <span style={{ marginLeft: '0.5rem' }}>({link.createdAt})</span>
            {data?.claim.note?.url === link.url && index === 0 && (
              <Label
                style={{ marginLeft: '0.5rem' }}
                basic
                size="mini"
                color="olive"
                content="Newest link"
              />
            )}
          </div>
        );
      }
    });
  };

  const changeEobStatusToDownloaded = (value: string) => {
    setLink(value);
    setEobLinkUrlValid(validateUrl(value));
    setEobStatusToDownloaded(value != null && value !== '');
  };

  const getEobStatusToSend = () => {
    if (eobStatusToDownloaded) {
      return EobStatus.DOWNLOADED;
    }

    if (
      data?.claim.status === BillingClaimStatus.ACCEPTED ||
      data?.claim.status === BillingClaimStatus.ACKNOWLEDGED
    ) {
      return eobStatus;
    }

    if (
      claimStatus === BillingClaimStatus.ACCEPTED ||
      claimStatus === BillingClaimStatus.ACKNOWLEDGED
    ) {
      return EobStatus.NEW_SUBMITTED;
    }

    if (
      claimStatus === BillingClaimStatus.TO_RESUBMIT ||
      claimStatus === BillingClaimStatus.CORRECTED
    ) {
      return EobStatus.ARCHIVED;
    }

    return eobStatus;
  };

  const getEobLink = useCallback(() => {
    if (link) {
      return link.trim();
    } else {
      return data?.claim.note?.url;
    }
  }, [data?.claim, link]);

  const handleSubmit = useCallback(() => {
    if (!eobLinkUrlValid) {
      return;
    }

    if (!prescriberId && !!data?.claim.vaccinations.length) {
      return toast({
        title:
          "Vaccination is missing prescriber. Change claim status to 'Error' to select a prescriber.",
        type: 'error',
        time: 5000,
      });
    }

    saveClaimUpdate({
      variables: {
        claimId,
        note: {
          note: notes,
          url: getEobLink(),
        },
        claimControlNumber: claimControlNumber
          ? claimControlNumber.trim()
          : claimControlNumber,
        rhcClaimNumber: rhcClaimNumber ? rhcClaimNumber.trim() : rhcClaimNumber,
        inMediation: inMediation
          ? moment(inMediation).format(DateFormats.DATE)
          : null,
        data: cptCodes,
        checkDate: checkDate
          ? moment(checkDate).format(DateFormats.DATE)
          : null,
        status: claimStatus,
        checkNumber,
        dismissedReason:
          claimStatus !== BillingClaimStatus.DISMISSED ? null : dismissedReason,
        eobStatus: getEobStatusToSend(),
        appealMethod,
        fieldToCorrect,
        obj: {
          claimId,
          status: claimStatus,
          source: 'manual',
          metadata: {
            note: {
              note: notes,
              url: getEobLink(),
            },
            insurance: {
              insuranceCompanyId: insuranceCompanyId
                ? insuranceCompanyId.trim()
                : insuranceCompanyId,
              insuranceName: insuranceName
                ? insuranceName.trim()
                : insuranceName,
              memberId: memberId ? memberId.trim() : memberId,
              payerID: payerId ? payerId.trim() : payerId,
              vfcEligible: isVfcEligible,
              vfcCriteria: vfcCriteria,
            },
            ...(data?.claim.updates[0]?.insuranceForPracticePortal
              ? {
                  insuranceForPracticePortal:
                    data?.claim.updates[0]?.insuranceForPracticePortal,
                }
              : {}),
            alreadySubmittedData: data?.claim.updates[0]?.alreadySubmittedData,
            dismissedReason:
              claimStatus === BillingClaimStatus.DISMISSED
                ? dismissedReason
                : '',
          },
          practiceAccountId: practiceAccount.account?.id,
        },
        prescriberId,
        borrowingStatus,
        directChargeStatus,
        directChargeReason: directChargeConfirmationReason,
      },
    })
      .then(() => {
        setSwitchMutation(false);
        toast({
          title: 'Claim updated successfully',
          type: 'success',
          time: 1000,
        });
        if (onSave) onSave();
        if (onClose) onClose();
        claimUpdatesRefetch();
        refetch();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  }, [
    cptCodes,
    notes,
    link,
    claimControlNumber,
    rhcClaimNumber,
    inMediation,
    checkDate,
    claimStatus,
    checkNumber,
    dismissedReason,
    insuranceCompanyId,
    memberId,
    payerId,
    isVfcEligible,
    vfcCriteria,
    eobStatus,
    appealMethod,
    fieldToCorrect,
    prescriberId,
    borrowingStatus,
    directChargeStatus,
    directChargeConfirmationReason,
  ]);

  const cleanForm = () => {
    setClaimControlNumber(claimControlNumber || '');
    setRhcClaimNumber(data?.claim.rhcClaimNumber || '');
    setNotes(data?.claim.note?.note || '');
    setInMediation(
      data?.claim.inMediationDueDate
        ? moment(data?.claim.inMediationDueDate)
            .add(new Date().getTimezoneOffset() + 60, 'minutes')
            .toDate()
        : null
    );
    setCheckDate(
      data?.claim.checkDate
        ? moment(data?.claim.checkDate)
            .add(new Date().getTimezoneOffset() + 60, 'minutes')
            .toDate()
        : null
    );
    setClaimStatus(BillingClaimStatus.PENDING);
    setCheckNumber(data?.claim.checkNumber || '');
  };

  useEffect(() => {
    if (!data) return;

    getPracticeTimezone({
      variables: { practiceId: data?.claim.practice.id },
    });

    cleanForm();

    setCptCodes(
      data?.claim.cptCodes
        ? data?.claim.cptCodes.map((c) => {
            return {
              id: c.id,
              claimId: c.claimId,
              cptCode: c.cptCode,
              claimAmount: c.claimAmount,
              paidAmount: c.paidAmount,
              units: c.units,
              note: {
                message: c.note?.message,
                unpaidReason: {
                  value: c.note?.unpaidReason?.value || '',
                  text: c.note?.unpaidReason?.text || '',
                },
              },
              status: c.status,
              compensationAmount: c.compensationAmount,
              resolution: c.resolution,
              inMediationDueDate: c.inMediationDueDate,
              dismissedReason: c.dismissedReason,
              invoice: c.invoiceId,
            };
          })
        : []
    );

    setNotes(data.claim.note?.note || '');
    setClaimControlNumber(data.claim.claimControlNumber || '');
    setRhcClaimNumber(data.claim.rhcClaimNumber || '');
    setInMediation(
      data.claim.inMediationDueDate
        ? new Date(data.claim.inMediationDueDate)
        : undefined
    );
    setCheckDate(
      data.claim.checkDate && practiceTimezone
        ? moment
            .tz(data.claim.checkDate, practiceTimezone.practice.timezone)
            .toDate()
        : undefined
    );
    setCheckNumber(data.claim.checkNumber || '');
    setClaimStatus(data.claim?.status);
    setDismissedReason(data.claim.dismissedReason || null);
    setEobStatus(data.claim.eobStatus || null);
    setAppealMethod(data.claim.appealMethod || null);
    setFieldToCorrect(data.claim.fieldToCorrect || null);
    setInsuranceName(data.claim.updates[0]?.insuranceName || '');
    setInsuranceCompanyId(data.claim.updates[0]?.insuranceCompanyId || '');
    setPayerId(data.claim.updates[0]?.payerId || '');
    setMemberId(data.claim.updates[0]?.memberId || '');
    setIsVfcEligible(data.claim.updates[0]?.vfcEligible || false);
    setVfcCriteria(data.claim.updates[0]?.vfcCriteria);
    setBorrowingStatus(data.claim.borrowingStatus || null);
    setDirectChargeStatus(data.claim.directChargeStatus || null);
    setDirectChargeConfirmationReason(data.claim.directChargeReason || null);
    canUpdateVfc(data.claim.status);
    refetch();
  }, [data, practiceTimezone]);

  useEffect(() => {
    setSwitchMutation(true);
  }, [
    insuranceName,
    insuranceCompanyId,
    memberId,
    payerId,
    vfcCriteria,
    isVfcEligible,
    claimStatus,
  ]);

  const isNewClaim = () =>
    claimStatus === BillingClaimStatus.ACCEPTED ||
    claimStatus === BillingClaimStatus.ACKNOWLEDGED;

  const { options: billingClaimStatusOptions } = useBillingClaimStatusOptions(
    data?.claim.status
  );

  return (
    <Modal
      closeIcon
      onClose={() => {
        cleanForm();
        if (onClose) onClose();
      }}
      open
      size="small"
    >
      <Header>
        Update Claim
        <Header.Subheader>
          <Label basic size="large" style={{ marginLeft: '0' }}>
            <b>{`Control Number: ${data?.claim.controlNumber}`}</b>
          </Label>
        </Header.Subheader>
        <Header.Subheader>{`Patient: ${
          data?.claim.practicePatient.patientData.firstName
        } ${data?.claim.practicePatient.patientData.lastName} (${moment(
          data?.claim.practicePatient.patientData.birthdate
        ).format(DateFormats.DATE)})`}</Header.Subheader>
        <Header.Subheader>{`Insurance: ${
          data?.claim.updates[0]?.insuranceName || '-'
        } (Member ID: ${
          data?.claim.updates[0]?.memberId || '-'
        })`}</Header.Subheader>
        <Header.Subheader>
          {`Service date: ${
            data?.claim.givenAt
              ? formatDatetimeToMMDDYYY(data?.claim.givenAt)
              : '-'
          }`}
        </Header.Subheader>
        <Header.Subheader>
          Provider:
          {data?.claim.vaccinations[0]?.provider?.account.firstName
            ? ` ${data?.claim.vaccinations[0]?.provider?.account.firstName} ${data?.claim.vaccinations[0]?.provider?.account.lastName} `
            : ' -'}
        </Header.Subheader>
        <Header.Subheader>{`NPI: ${
          data?.claim.lastUpdateFromApi.npi
            ? data?.claim.lastUpdateFromApi.npi
            : data?.claim.practice.npi
        }`}</Header.Subheader>
        <Header.Subheader>{`Tax ID: ${
          data?.claim.lastUpdateFromApi.employerId
            ? data?.claim.lastUpdateFromApi.employerId
            : data?.claim.practice.profile.federalTaxId
        }`}</Header.Subheader>
        <Header.Subheader>
          Claim amount:
          {data?.claim.totalClaimAmount
            ? ` $${data?.claim.totalClaimAmount}`
            : ' -'}
        </Header.Subheader>
      </Header>
      <Modal.Content>
        <Form id="update-claim-form" onSubmit={handleSubmit}>
          {!!cptCodes && data?.claim.cptCodes
            ? data?.claim.cptCodes.map((c, idx) => {
                const cptCodeData: CptCode = {
                  id: c.id,
                  claimId: c.claimId,
                  cptCode: c.cptCode,
                  claimAmount: c.claimAmount,
                  paidAmount: c.paidAmount,
                  units: c.units,
                  count: c.count,
                  note: {
                    message: c.note?.message,
                    unpaidReason: {
                      value: c.note?.unpaidReason?.value as string,
                      text: c.note?.unpaidReason?.text as string,
                    },
                  },
                  status: c.status,
                  compensationAmount: c.compensationAmount,
                  inMediationAmount: c.inMediationAmount,
                  inMediationDueDate: c.inMediationDueDate,
                  resolution: c.resolution,
                  dismissedReason: c.dismissedReason,
                  invoice: c.invoice,
                  ...(c.invoices
                    ? {
                        invoices: {
                          invoiceNo: c.invoices.invoiceNo,
                        },
                      }
                    : {}),
                };

                return (
                  <CptCodeForm
                    key={cptCodeData.id}
                    cptCodeData={cptCodeData}
                    status={data?.claim.status}
                    adminCptCodes={adminCptCodes}
                    onChangeStatus={claimStatus}
                    onChangeCpt={(cptCode) => {
                      const _cptCodes = [...cptCodes];
                      _cptCodes[idx] = cptCode;

                      setCptCodes(_cptCodes);
                    }}
                    disableSave={handleCptCodeFormChange}
                  />
                );
              })
            : null}
          <Form.Group
            style={{
              display: 'flex',
            }}
          >
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '15rem',
              }}
            >
              <label>Status:</label>
              <Dropdown
                style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                fluid
                selection
                options={billingClaimStatusOptions}
                value={claimStatus}
                onChange={(_, { value }) => {
                  setClaimStatus(value as BillingClaimStatus);
                  setSaveButtonDisabled(false);
                  canUpdateVfc(value as BillingClaimStatus);
                }}
                disabled={data?.claim.status === BillingClaimStatus.BILLED}
              />
            </Form.Field>
            {claimStatus === BillingClaimStatus.DISMISSED && (
              <Form.Field
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <label>Dismissed Reason:</label>
                <Dropdown
                  style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                  fluid
                  selection
                  options={claimDismissedOptions}
                  value={dismissedReason as string}
                  placeholder="Select reason"
                  onChange={(e, data) => {
                    setDismissedReason(data.value as string);
                    setSaveButtonDisabled(false);
                  }}
                />
              </Form.Field>
            )}
            {isNewClaim() && (
              <Form.Field
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <label>EOB status:</label>
                <Dropdown
                  style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                  fluid
                  selection
                  options={eobStatusOptions}
                  value={getEobStatusToSend() as string}
                  placeholder="Select EOB status"
                  onChange={(e, data) => {
                    setEobStatus(data.value as string);
                    setSaveButtonDisabled(false);
                  }}
                />
              </Form.Field>
            )}
            {claimStatus === BillingClaimStatus.TO_APPEAL && (
              <Form.Field
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <label>Appeal Method:</label>
                <Dropdown
                  style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                  fluid
                  selection
                  options={toAppealOptions}
                  value={appealMethod as string}
                  placeholder="Select appeal method"
                  onChange={(e, data) => {
                    setAppealMethod(data.value as string);
                    setSaveButtonDisabled(false);
                  }}
                />
              </Form.Field>
            )}

            {claimStatus === BillingClaimStatus.TO_CORRECT && (
              <Form.Field
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <label>Field to correct:</label>
                <Dropdown
                  style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                  fluid
                  selection
                  options={toCorrectOptions}
                  value={fieldToCorrect as string}
                  placeholder="Select field to correct"
                  onChange={(e, data) => {
                    setFieldToCorrect(data.value as string);
                    setSaveButtonDisabled(false);
                  }}
                />
              </Form.Field>
            )}

            {claimStatus === BillingClaimStatus.BORROWING && (
              <Form.Field
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <label>Borrowing Status:</label>
                <Dropdown
                  style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                  fluid
                  selection
                  options={borrowingStatusOptions}
                  value={borrowingStatus as string}
                  placeholder="Select status"
                  onChange={(e, data) => {
                    setBorrowingStatus(data.value as string);
                    setSaveButtonDisabled(false);
                  }}
                />
              </Form.Field>
            )}

            {claimStatus === BillingClaimStatus.DIRECT_CHARGE && (
              <Form.Field
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <label>Direct Charge Status:</label>
                <Dropdown
                  style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                  fluid
                  selection
                  options={directChargeStatusOptions}
                  value={directChargeStatus as string}
                  placeholder="Select status"
                  onChange={(e, data) => {
                    setDirectChargeStatus(data.value as string);
                    setSaveButtonDisabled(false);
                  }}
                />
              </Form.Field>
            )}
          </Form.Group>
          {claimStatus === BillingClaimStatus.DIRECT_CHARGE &&
            directChargeStatus === 'confirmed' && (
              <Form.Input
                fluid
                required
                label="Direct charge confirmation reason"
                placeholder="Direct charge confirmation reason"
                value={directChargeConfirmationReason}
                onChange={(_, { value }) => {
                  setDirectChargeConfirmationReason(value);
                  setSaveButtonDisabled(false);
                }}
              />
            )}
          {claimStatus === BillingClaimStatus.DIRECT_CHARGE &&
            directChargeStatus === 'charged' && (
              <Form.Input
                fluid
                label="Direct charge confirmation reason"
                placeholder="Direct charge confirmation reason"
                value={directChargeConfirmationReason}
                readOnly
              />
            )}

          {claimStatus === BillingClaimStatus.CORRECTED && (
            <Card fluid>
              <Card.Content>
                <Card.Header style={{ marginBottom: '1rem' }}>
                  <label>
                    <b>Insurance Information</b>
                  </label>
                </Card.Header>
                <Card.Description>
                  <Form.Group widths="equal">
                    <Form.Field required>
                      <label>Company Name</label>
                      <InsuranceCompanyPicker
                        placeholder="Insurance Name"
                        value={insuranceCompanyId}
                        onCompanySelected={(company) => {
                          setInsuranceName(company.name);
                          setPayerId(company.payerId || '');
                          setInsuranceCompanyId(company.id);
                        }}
                        includePayerId
                      />
                    </Form.Field>
                    <Form.Input
                      value={payerId}
                      onChange={(_, { value }) => {
                        setPayerId(value);
                      }}
                      fluid
                      label="Payer Id"
                      placeholder="Payer Id"
                      readOnly
                    />
                    <Form.Input
                      value={memberId}
                      onChange={(_, { value }) => {
                        setMemberId(value);
                        setSaveButtonDisabled(false);
                      }}
                      fluid
                      label="Member Id"
                      placeholder="Member Id"
                      required
                    />
                  </Form.Group>

                  {hasBorrowingAndIsUpdatable ? (
                    <Form.Group
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Form.Checkbox
                        width={5}
                        checked={isVfcEligible}
                        onChange={(_, { checked }) => {
                          setIsVfcEligible(!!checked);
                          setSaveButtonDisabled(false);
                        }}
                        label="VFC eligible"
                        required
                      />
                      <Form.Select
                        width={11}
                        value={vfcCriteria}
                        placeholder="Select VFC criteria"
                        onChange={(_, { value }) => {
                          setVfcCriteria(value as VfcCriteria);
                          setSaveButtonDisabled(false);
                        }}
                        disabled={!isVfcEligible}
                        options={VFC_OPTIONS}
                        required
                      />
                    </Form.Group>
                  ) : (
                    <Label basic color="red" pointing>
                      <b>{`Can't update VFC Condition.  There exists Borrowing cases that must be manually resolved.`}</b>
                    </Label>
                  )}
                </Card.Description>
              </Card.Content>
            </Card>
          )}
          {data?.claim.practice.id && (
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '15rem',
                maxWidth: '20rem',
              }}
            >
              <label style={{ paddingRight: '0.5rem' }}>Prescriber:</label>

              <ProviderPicker
                disabled={
                  claimStatus !== BillingClaimStatus.ERROR &&
                  claimStatus !== BillingClaimStatus.TO_RESUBMIT
                }
                prescriber
                placeholder="Select Prescriber"
                defaultValue={
                  data.claim.vaccinations[0]
                    ? data.claim.vaccinations[0].prescriber?.id
                    : ''
                }
                practiceId={data.claim.practice.id}
                onChange={(newPrescriberId) => {
                  if (newPrescriberId) {
                    setPrescriberId(newPrescriberId);
                    setSaveButtonDisabled(false);
                  }
                }}
                isClereable={false}
              />
            </Form.Field>
          )}
          <Form.Input
            value={checkNumber}
            onChange={(_, { value }) => {
              setCheckNumber(value);
              setSaveButtonDisabled(false);
            }}
            fluid
            label="Check Number"
            placeholder="Check Number"
          />
          {claimStatus !== BillingClaimStatus.PENDING &&
          claimStatus !== BillingClaimStatus.SUBMITTED &&
          claimStatus !== BillingClaimStatus.ERROR ? (
            <Form.Input
              value={claimControlNumber}
              onChange={(_, { value }) => {
                setClaimControlNumber(value);
                setSaveButtonDisabled(false);
              }}
              fluid
              label="Claim Control Number (EOB / ERA)"
              placeholder="Claim Control Number (EOB / ERA)"
            />
          ) : null}
          <Form.Input
            value={rhcClaimNumber}
            onChange={(_, { value }) => {
              setRhcClaimNumber(value);
              setSaveButtonDisabled(false);
            }}
            fluid
            label="RHC Claim Number"
            placeholder="RHC Claim Number"
          />
          {claimStatus === BillingClaimStatus.IN_MEDIATION && (
            <Form.Field>
              <label>Mediation due date</label>
              <DateTimePicker
                placeholderText="Mediation due date"
                selected={inMediation}
                onChange={(d) => {
                  setInMediation(d ? (d as Date) : null);
                  setSaveButtonDisabled(false);
                }}
                onSelect={(value) =>
                  setInMediation(value ? (value as Date) : null)
                }
                onClear={() => setInMediation(null)}
                dateFormat={DateFormats.DATE}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                isClearable
              />
            </Form.Field>
          )}
          <Form.Field>
            <label>Check date</label>
            <DateTimePicker
              placeholderText="Check date"
              selected={checkDate}
              onChange={(d) => {
                setCheckDate(d ? (d as Date) : null);
                setSaveButtonDisabled(false);
              }}
              onSelect={(value) => setCheckDate(value ? (value as Date) : null)}
              onClear={() => setCheckDate(null)}
              dateFormat={DateFormats.DATE}
              scrollableYearDropdown
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              isClearable
              tz={practiceTimezone?.practice.timezone}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Add EOB Link"
              placeholder="EOB Link"
              value={link}
              onChange={(_, { value }) => {
                changeEobStatusToDownloaded(value);
                setSaveButtonDisabled(false);
              }}
            />
            {eobStatusToDownloaded &&
              claimStatus !== BillingClaimStatus.READY_TO_REPORT && (
                <Label basic color="red" pointing>
                  When setting Link, Claim Status should change to "Ready To
                  Report"
                </Label>
              )}
            {!eobLinkUrlValid && (
              <Label basic color="red" pointing>
                EOB Link is an invalid url
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>EOB Links</label>
            {renderEobLinks()}
          </Form.Field>
          <Form.Field>
            <label>Notes</label>
            <Form.TextArea
              placeholder="There are no notes for this claim. You can add one and save it."
              value={notes}
              onChange={(_, { value }) => {
                setSaveButtonDisabled(false);
                setNotes(value as string);
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <ClaimStatusLog
          data={claimUpdatesData}
          loading={claimUpdatesLoading}
          position="top right"
        />
        <Button
          type="button"
          content="Cancel"
          onClick={() => {
            cleanForm();
            if (onClose) onClose();
          }}
        />
        <Button
          primary
          disabled={
            readonly ||
            saveButtonDisabled ||
            data?.claim.status === BillingClaimStatus.GENERATED_837p
          }
          type="submit"
          content="Save"
          icon="save"
          form="update-claim-form"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UpdateBillingClaimForm;
