import { InvoicingStrategy, Organization } from './Organization';
import { Invoice } from '@bluefox/models/Invoice';
import { Practice } from './Practice';
import { BalanceMovement } from '@canid/services/repositories';

export enum MovementType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum MovementReason {
  INVOICE_PAYMENT = 'invoice_payment',
  INVENTORY_AT_LAUNCH = 'inventory_at_launch',
  OTHER = 'other',
  ADJUSTMENT = 'adjustment', // this type do no send data to stripe
}

export enum HasInvoice {
  YES = 'yes',
  NO = 'no',
}

// TODO: Move this to a generics types
export enum SelectAll {
  ALL = 'all',
}

export const balanceMovementReasonsOptions = [
  {
    text: 'Invoice Payment',
    value: MovementReason.INVOICE_PAYMENT,
  },
  {
    text: 'Inventory at Launch',
    value: MovementReason.INVENTORY_AT_LAUNCH,
  },
  {
    text: 'Other',
    value: MovementReason.OTHER,
  },
  {
    text: 'Adjustment',
    value: MovementReason.ADJUSTMENT,
  },
];

export const balanceMovementTypesOptions = [
  {
    text: 'Credit',
    value: MovementType.CREDIT,
  },
  {
    text: 'Debit',
    value: MovementType.DEBIT,
  },
];

export const selectAllOption = [
  {
    text: 'All',
    value: SelectAll.ALL,
  },
];

export const hasInvoiceOptions = [
  {
    text: 'Yes',
    value: HasInvoice.YES,
  },
  {
    text: 'No',
    value: HasInvoice.NO,
  },
];

export interface BalanceMovements {
  id?: string;
  amount?: number;
  type: MovementType;
  reason: MovementReason;
  organizationId: string;
  date?: string;
  createdAt?: Date;
  updatedAt?: Date;
  invoiceId?: string;
  practiceId?: string;
  externalId?: string;
  externalInvoiceId?: string;
  currentBalance?: number;
  comment?: string;
  invoicingStrategy: InvoicingStrategy;
  invoice?: Invoice;
  practice?: Practice;
  organization?: Organization;
  amountToPay?: number;
}

export type CreateBalanceInput = Pick<
  BalanceMovements,
  'amount' | 'type' | 'reason' | 'organizationId' | 'practiceId' | 'comment'
>;

export type BalanceMovementFilter = {
  organizationId?: string;
  practiceId?: string;
  type?: MovementType | SelectAll;
  reason?: MovementReason | SelectAll;
  dateFrom?: Date;
  dateTo?: Date;
  hasInvoice?: HasInvoice | SelectAll;
};

export type InvoiceDebitMovementInput = {
  invoiceId: string;
  invoiceAmount: number;
  organizationId: string;
  practiceId?: string;
  invoicingStrategy: InvoicingStrategy;
};

export type GetBalanceMovementsQueryResponse = {
  balanceMovements: BalanceMovements[];
  totalBalanceMovements: {
    aggregate: { count: number };
  };
};
