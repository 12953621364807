import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertMappingsMutation,
  UpdateMappingMutation,
} from '@graphql/mappings';
import { VfcCriteriaQuery } from '@bluefox/graphql/insurances';
import { MappedItem, vfcEligibleOptions } from '@bluefox/models/Mappings';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import { Button, Form, Dropdown } from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';

interface VfcCriteria {
  value: string;
}

interface VfcCriteriaData {
  vfcCriterias: VfcCriteria[];
}

interface PracticeData {
  id: string;
  name: string;
}

interface EcwVfcEligibilityMappingFormProps {
  close: () => void;
  practices?: PracticeData[];
  data: MappedItem | null;
  refetchMappings: () => void;
}

const EcwVfcEligibilityMappingForm = ({
  close,
  practices,
  data,
  refetchMappings,
}: EcwVfcEligibilityMappingFormProps) => {
  const [vfcEmr, setVfcEmr] = useState<string>(
    data ? data.metadata[0].keyEmr : ''
  );
  const [practiceId, setPracticeId] = useState(data ? data.practice.id : '');
  const [vfcCriteria, setVfcCriteria] = useState<string>(data ? data.key : '');
  const [vfcEligible, setVfcEligible] = useState(data?.metadata[0].vfcEligible);
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);

  const { data: vfcCriteriaData } = useQuery<VfcCriteriaData>(VfcCriteriaQuery);

  const options = vfcCriteriaData?.vfcCriterias.map((item) => {
    return {
      key: item.value,
      text: humanizeText(item.value, { delimiter: '_', capitalize: 'all' }),
      value: item.value,
    };
  });

  const vfcCriteriaOptions = [
    ...(options || []),
    {
      key: 'default',
      text: 'Default',
      value: 'default',
    },
  ];

  const [saveVfcEligibilityMapping] = useMutation(
    data?.id ? UpdateMappingMutation : InsertMappingsMutation
  );

  const handleSubmit = () => {
    saveVfcEligibilityMapping({
      variables: {
        id: data?.id,
        practiceId,
        key: vfcCriteria,
        type: 'vfc_eligibility',
        metadata: [
          {
            keyEmr: vfcEmr,
            vfcEligible,
          },
        ],
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!practices || practices.length < 1) return;

    setPracticeOptions(
      practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  return (
    <Form
      onSubmit={handleSubmit}
      data-automation-id="vfc-eligibility-mapping-form"
    >
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Dropdown
            label="Practice"
            style={{ minWidth: '15rem' }}
            placeholder="Select practice"
            fluid
            selection
            value={practiceId}
            onChange={(e, data) => {
              setPracticeId(data.value?.toString() || '');
            }}
            options={practiceOptions}
            required
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={vfcEmr}
            onChange={(_, { value }) => setVfcEmr(value)}
            fluid
            label="VFC Eligibility (EMR)"
            placeholder="VFC Eligibility (EMR)"
            required
            data-automation-id="vfc-eligibility-mapping-form-vfc-eligibility-emr"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required>
          <label>VFC Criteria:</label>
          <Dropdown
            fluid
            selection
            placeholder="Select VFC criteria..."
            options={vfcCriteriaOptions}
            value={vfcCriteria}
            onChange={(e, data) => {
              setVfcCriteria(data.value as string);
            }}
            data-automation-id="vfc-eligibility-mapping-form-vfc-criteria"
          />
        </Form.Field>
        <Form.Field required>
          <label>VFC Eligibile:</label>
          <Dropdown
            fluid
            selection
            placeholder="Select VFC eligibility..."
            options={vfcEligibleOptions}
            value={vfcEligible}
            onChange={(e, data) => {
              setVfcEligible(data.value as string);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={close}
          type="button"
          data-automation-id="vfc-eligibility-mapping-form-cancel-button"
        >
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          data-automation-id="vfc-eligibility-mapping-form-save-button"
          disabled={vfcEligible === undefined || !vfcEmr || !vfcCriteria}
        />
      </Form.Field>
    </Form>
  );
};

export default EcwVfcEligibilityMappingForm;
