import { Inventory } from '@bluefox/models/Inventory';
import { Vaccine } from '@bluefox/models/Vaccine';
import { Practice } from './Practice';

export enum OrderStatuses {
  ALL = 'all',
  CLOSED = 'closed',
  RECEIVED = 'received',
  ORDERED = 'ordered',
  CANCELLED = 'cancelled',
}

export enum purchasePlatforms {
  MERCK = 'Merck',
  VACCINESHOPPE = 'Vaccine Shoppe',
  GSK = 'GSK',
  PFIZER = 'Pfizer',
  IIS = 'IIS',
  MCKESSON = 'McKesson',
  MEDICOMART = 'medicomart',
}

export interface StatusLog {
  status: OrderStatuses;
  date: Date | string;
}

export interface InventoryOrders {
  id: string;
  practiceId: string;
  practice?: Practice;
  vaccineId: string;
  date?: Date;
  purchasePlatform?: string;
  lot: string;
  inventoryExpiration?: Date;
  doses: number;
  packagesAmount?: number;
  amount?: number;
  invoiceNumber: string;
  invoiceDue?: Date;
  invoiceLink: string;
  paidAt?: Date;
  paid?: boolean;
  status: OrderStatuses;
  statusLog?: StatusLog;
  inventoryId: string;
  inventory?: Inventory;
  vfc?: boolean;
  vaccine?: Vaccine;
  orderNumber?: string;
  trackingNumber?: string;
  estimatedShippingDate?: Date;
  statementDate?: Date;
  notes?: string;
  creditCardId?: string;
}

export enum SelectAll {
  ALL = 'all',
}

export enum IsVFC {
  YES = 'yes',
  NO = 'no',
}

export const inventoryOrderStatusesOptions: {
  text: string;
  value: OrderStatuses;
}[] = [
  { text: 'Received', value: OrderStatuses.RECEIVED },
  { text: 'Ordered', value: OrderStatuses.ORDERED },
  { text: 'Cancelled', value: OrderStatuses.CANCELLED },
];

export const selectAllOption = [
  {
    text: 'All',
    value: SelectAll.ALL,
  },
];
export type InventoryOrdersFilter = {
  practiceId?: string;
  status?: OrderStatuses | SelectAll;
  vaccine?: string;
  dateFrom?: Date;
  dateTo?: Date;
  isFVC?: IsVFC | SelectAll;
  orderNumber?: string;
};
