import { PracticePatient } from '../Patient';
import { Practice } from '../Practice';

export interface Subscription {
  id: string;
  practiceId: string;
  type: string;
  status: string;
  error: string;
  metadata: any;
  practice: Practice;
}

export interface OutboundAction {
  id: string;
  entityRef: string;
  error: any;
  metadata: any;
  response: any;
  status: string;
  type: string;
  iisStatus: string;
  practiceId: string;
  practice: Practice;
  firstName?: string;
  lastName?: string;
  title?: string;
  practicePatient?: PracticePatient;
}

export interface MappedItem {
  id: string;
  key: string;
  type: string;
  value: {
    data: string;
    name?: string;
    vaccineId?: string;
    insuranceCompanyId?: string;
  };
  practice?: Practice;
}

export const athenaEthnicityMapper = (code: string) => {
  switch (code) {
    case '2155-0':
      return 'Hispanic or Latino';
    case '2182-4':
      return 'Hispanic or Latino';
    case '2184-0':
      return 'Hispanic or Latino';
    case '2135-2':
      return 'Hispanic or Latino';
    case '2178-2':
      return 'Hispanic or Latino';
    case '2148-5':
      return 'Hispanic or Latino';
    case '2180-8':
      return 'Hispanic or Latino';
    case '2165-9':
      return 'Hispanic or Latino';
    case '2137-8':
      return 'Hispanic or Latino';
    case '2186-5':
      return 'Not hispanic or Latino';
    case 'declined':
      return 'Prefer not to answer';
    default:
      break;
  }
};

export const subscriptionStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'error',
    text: 'Error',
    value: 'error',
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
  },
];

export const outboundActionsStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'error',
    text: 'Error',
    value: 'error',
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
  },
  {
    key: 'finished',
    text: 'Finished',
    value: 'finished',
  },
];

export const iisStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'auto',
    text: 'Auto',
    value: 'auto',
  },
  {
    key: 'canid',
    text: 'Canid',
    value: 'canid',
  },
  {
    key: 'practice',
    text: 'Practice',
    value: 'practice',
  },
];
