import { addDays, formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import InvoiceAndBillingReportPdf, {
  InvoiceAndBillingReportPdfProps,
} from '@bluefox/pdf/InvoiceAndBillingReportPdf';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Loader,
  Modal,
  Segment,
} from 'semantic-ui-react';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '80vh',
  },
});

export type FormValues = {
  emailList?: string[];
  hour?: string;
  date?: string;
  dueDate?: string;
  emailBody?: string;
};

export function addDayToDateStringYYYhMMhDD(
  dateYYYYhMMdDD: string,
  days: number
) {
  return formatDateToYYYYhMMhDD(
    addDays(new Date(`${dateYYYYhMMdDD}T00:00`), days)
  );
}

type Props = {
  loading: boolean;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  open: boolean;
  invoicePdf?: InvoiceAndBillingReportPdfProps;
  onClose?: () => void;
  onSubmit?: (values: FormValues) => void;
  daysUntilDue?: number;
};

const PreviewInvoiceModal = ({
  loading,
  formValues,
  setFormValues,
  open,
  invoicePdf,
  onClose,
  onSubmit,
  daysUntilDue,
}: Props) => {
  return (
    <Modal closeIcon onClose={onClose} open={open}>
      <Modal.Header>Preview Invoice</Modal.Header>
      <Modal.Content>
        <Segment>
          <Dimmer active={loading}>
            <Loader active />
          </Dimmer>
          {invoicePdf && (
            <PDFViewer style={styles.viewer}>
              <InvoiceAndBillingReportPdf {...invoicePdf} />
            </PDFViewer>
          )}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit?.(formValues);
            }}
          >
            <Header>Send Invoice</Header>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Input
                    label="Email addresses"
                    type="email"
                    multiple
                    required
                    value={
                      (formValues.emailList &&
                        formValues.emailList.join(',')) ||
                      ''
                    }
                    onChange={(_, { value }) => {
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        emailList: value
                          ? (value as string).toLocaleLowerCase().split(',')
                          : undefined,
                      }));
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Input
                    label="Hour (New York time)"
                    type="time"
                    required
                    value={formValues.hour}
                    onChange={(_, { value }) =>
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        hour: value as string,
                      }))
                    }
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Input
                    label="Date (New York time)"
                    type="date"
                    required
                    value={formValues.date}
                    onChange={(_, { value }) =>
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        date: value as string,
                        dueDate: addDayToDateStringYYYhMMhDD(
                          value as string,
                          daysUntilDue || 0
                        ),
                      }))
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.TextArea
                    rows={5}
                    value={formValues.emailBody}
                    onChange={(_, { value }) =>
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        emailBody: value as string,
                      }))
                    }
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Input
                    label="Due Date (New York time)"
                    type="date"
                    required
                    value={formValues.dueDate}
                    onChange={(_, { value }) =>
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        dueDate: value as string,
                      }))
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign="right">
                  <Form.Button type="submit" primary>
                    Send
                  </Form.Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          secondary
          onClick={() => {
            onClose?.();
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PreviewInvoiceModal;
