import MainLayout from '@ui/MainLayout';
import { Container, Header, Icon } from 'semantic-ui-react';
import NotificationsList from 'ui/Notifications/NotificationList';

const NotificationList = () => {
  return (
    <MainLayout path={[{ text: 'Notifications' }]}>
      <Container fluid>
        <Header as="h1" style={{ margin: '2rem 0' }}>
          <Icon name="bell" size="huge" />
          Notifications
        </Header>
        <NotificationsList />
      </Container>
    </MainLayout>
  );
};

export default NotificationList;
