import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Header,
  Label,
  Message,
  Table,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { usePractice } from '@bluefox/contexts/Practice';
import MainLayout from '@ui/MainLayout';
import { BorrowingInventoryAdjustmentsDetails } from '@graphql/inventory';
import { addDays } from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';
import { Vaccine } from '@bluefox/models/Vaccine';
import AdjustmentDetails from '@screens/inventory/AdjustmentDetails';

export interface Option {
  value: string;
  comment: string;
}

interface AdjustmentDetail {
  currentDoses: number;
  id: string;
  inventory: {
    lot: string;
    vfc: boolean;
    vaccine: Vaccine;
  };
  newDoses: number;
  type: string;
}

const BorrowingSummaryDetails = () => {
  const { date } = useParams<{ date: string }>();
  const practice = usePractice();
  const [details, setDetails] = useState<AdjustmentDetail[]>([]);

  useQuery(BorrowingInventoryAdjustmentsDetails, {
    variables: {
      criteria: {
        inventory_adjustment: {
          _and: [
            { createdAt: { _gte: new Date(date) } },
            {
              createdAt: {
                _lte: addDays(new Date(date), 1),
              },
            },
          ],
          status: { _eq: 'applied' },
          practiceId: { _eq: practice.id },
        },
        reason: { _eq: 'borrowing' },
      },
    },
    onCompleted(datax) {
      const sortedArr = [...datax.details].sort((a, b) => {
        if (a.inventory.vfc !== b.inventory.vfc) {
          // Sort by vfc (true comes before false)
          return a.inventory.vfc ? -1 : 1;
        } else {
          // If vfc values are the same, sort by vaccine name
          return a.inventory.vaccine.name.localeCompare(
            b.inventory.vaccine.name
          );
        }
      });
      setDetails(sortedArr);
    },
  });

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        {
          text: 'Borrowing Summary',
          to: `/practices/${practice.handler}/borrowing-summary`,
        },
        { text: `Borrowing Summary Details (${date.replaceAll('-', '/')})` },
      ]}
    >
      <Container>
        <Table celled structured selectable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Inventory</Table.HeaderCell>
              <Table.HeaderCell>Current Doses</Table.HeaderCell>
              <Table.HeaderCell>New Doses</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Claims</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {details ? (
              details.map((detail: any) => (
                <Table.Row key={detail.id} textAlign="center">
                  <Table.Cell>
                    <Header>
                      {detail.inventory?.vaccine?.name}
                      <Label
                        content={detail.inventory.vfc ? 'VFC' : 'Private'}
                        size="tiny"
                        color={detail.inventory.vfc ? 'orange' : 'teal'}
                      />
                      <Header.Subheader>
                        {detail.inventory.vaccine.saleNdc} <br />
                        {detail.inventory.vaccine?.types?.map((type: any) => (
                          <Label key={type} color="black" size="mini">
                            {type}
                          </Label>
                        ))}
                      </Header.Subheader>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{detail.currentDoses}</Table.Cell>
                  <Table.Cell>{detail.newDoses}</Table.Cell>
                  <Table.Cell>
                    {humanizeText(detail.type, { capitalize: 'all' })}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      primary
                      className="min-component-vertical-margin"
                      icon="eye"
                      size="tiny"
                      as={Link}
                      to={`/practices/${practice.handler}/borrowing-summary/detail/${detail.id}`}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Cell colSpan={5}>
                <Message>No details found for this adjustment.</Message>
              </Table.Cell>
            )}
          </Table.Body>
        </Table>
      </Container>
    </MainLayout>
  );
};

export default BorrowingSummaryDetails;
