const compactFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});

export function compactNumber(number: number) {
  return compactFormatter.format(number);
}

const usDollarNoDigitsCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export function usDollarNoDigitsCurrency(number: number) {
  return usDollarNoDigitsCurrencyFormatter.format(number);
}

const usNumberNoDigitsFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
});

export function usNumberNoDigits(number: number) {
  return usNumberNoDigitsFormatter.format(number);
}

const newUsDollarNoDigitsCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const newUsDollarTwoDigitsCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export function usDollarCurrency(number: number) {
  if (number % 1 == 0) {
    return newUsDollarNoDigitsCurrencyFormatter.format(number);
  }
  return newUsDollarTwoDigitsCurrencyFormatter.format(number);
}
