import { Vaccination } from './Vaccination';
import { Practice } from './Practice';
import { Account } from './Account';

interface BotErrorType {
  message: string;
}

export interface BotNoteType {
  key: string;
  message: string;
}

export enum MetadataAction {
  create = 'create',
  remove = 'remove',
  update = 'update',
}
export interface MetadataInsertVaccination extends Vaccination {
  action?: MetadataAction;
}

export interface MetadataRemoveVaccination {
  inventory: {
    lot: string;
  };
  practicePatient: {
    mrn: string;
  };
  givenAt: string;
  practiceId: string;
  action?: MetadataAction;
  practice: Practice;
}
export interface BotReportType {
  id: string;
  practiceId: string;
  practicePatientId: string;
  practice: Practice;
  status: string;
  type: string;
  entityRef: string;
  error: BotErrorType;
  metadata: MetadataInsertVaccination;
  note: BotNoteType;
  createdAt: Date;
  firstName?: string;
  lastName?: string;
  title?: string;
  source?: string;
}

export enum BotReportStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  COMPLETED_IIS = 'completed_iis',
  ERROR = 'error',
  RUNNING = 'running',
}

export const BotReportFilterStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: BotReportStatus.PENDING,
  },
  {
    key: 'running',
    text: 'Running',
    value: BotReportStatus.RUNNING,
  },
  {
    key: 'completed',
    text: 'Completed',
    value: BotReportStatus.COMPLETED,
  },
  {
    key: 'completedIis',
    text: 'Completed IIS',
    value: BotReportStatus.COMPLETED_IIS,
  },
  {
    key: 'error',
    text: 'Error',
    value: BotReportStatus.ERROR,
  },
];

export const BotReportStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: BotReportStatus.PENDING,
  },
  {
    key: 'completed',
    text: 'Completed',
    value: BotReportStatus.COMPLETED,
  },
  {
    key: 'completedIis',
    text: 'Completed IIS',
    value: BotReportStatus.COMPLETED_IIS,
  },
  {
    key: 'error',
    text: 'Error',
    value: BotReportStatus.ERROR,
  },
];

export enum BotReportAddedBy {
  BOT = 'bot',
  PRACTICE = 'practice',
  MANUAL = 'manual',
  UNDETERMINED = 'undetermined',
}

export const BotReportAddedByOptions = [
  {
    key: 'bot',
    text: 'Bot',
    value: BotReportAddedBy.BOT,
    disabled: true,
  },
  {
    key: 'practice',
    text: 'Practice',
    value: BotReportAddedBy.PRACTICE,
  },
  {
    key: 'manual',
    text: 'Manual',
    value: BotReportAddedBy.MANUAL,
  },
  {
    key: 'undetermined',
    text: 'Undetermined',
    value: BotReportAddedBy.UNDETERMINED,
    disabled: true,
  },
];
