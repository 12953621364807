import { humanizeText } from '@bluefox/lib/humanize';
import { Button, Modal } from 'semantic-ui-react';

type FullNoteModalProps = {
  open: boolean;
  onClose: () => void;
  topic: string;
  note: string;
};

const FullNoteModal = ({ open, onClose, topic, note }: FullNoteModalProps) => {
  return (
    <Modal size="tiny" onClose={onClose} open={open}>
      <Modal.Header>
        {humanizeText(topic, { capitalize: 'first', delimiter: '_' })} note
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p style={{ overflowWrap: 'break-word' }}>{note}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default FullNoteModal;
