import { vaccineTypeNames, VACCINE_TYPES } from '@bluefox/store/vaccines';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

interface VaccineTypesPickerProps {
  value?: string | string[];
  multiple?: boolean;
  onChange: (value: string | string[]) => void;
}

const VaccineTypesPicker = ({
  value,
  multiple = true,
  onChange = () => {},
  ...rest
}: VaccineTypesPickerProps & DropdownProps) => {
  const options = VACCINE_TYPES.map((o) => ({
    value: o,
    key: o,
    text: vaccineTypeNames[o],
  }));

  const [selectedValues, setSelectedValues] = useState<string[]>(
    value
      ? Array.isArray(value)
        ? (value as string[])
        : [value as string]
      : []
  );

  useEffect(() => {
    onChange(multiple ? selectedValues : selectedValues[0]);
  }, [selectedValues]);

  return (
    <Dropdown
      selection
      search
      multiple={multiple}
      value={selectedValues}
      clearable
      onChange={(_, { value }) => {
        const formattedValue = Array.isArray(value)
          ? (value as string[])
          : [value as string];
        setSelectedValues(formattedValue || []);
      }}
      options={options}
      {...rest}
    />
  );
};

export default VaccineTypesPicker;
