import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Form } from 'semantic-ui-react';

export type SearchValuesProps = {
  [key: string]: any;
  name: string;
  saleNdc: string;
  useNdc: string;
  types: string[];
};

type Props = {
  searchValues: Partial<SearchValuesProps>;
  setSearchValues: Dispatch<SetStateAction<Partial<SearchValuesProps>>>;
  types: string[];
  onSubmit: () => void;
};

const VaccineSearch = ({
  searchValues,
  setSearchValues,
  types,
  onSubmit,
}: Props) => {
  const renderStringInput = useCallback(
    (label: string, objKey: string) => (
      <Form.Input
        label={label}
        type="text"
        value={searchValues[objKey] || ''}
        onChange={(e) =>
          setSearchValues((prevSearchValues) => ({
            ...prevSearchValues,
            [objKey]: e.target.value,
          }))
        }
      />
    ),
    [searchValues, setSearchValues]
  );

  const renderMultiSelectStringArray = useCallback(
    (label: string, objKey: string, source: string[]) => {
      return (
        <Form.Dropdown
          label={label}
          fluid
          multiple
          search
          selection
          clearable
          defaultValue={searchValues[objKey]}
          options={source.map((val) => ({
            key: val,
            text: val,
            value: val,
          }))}
          onChange={(e, { value }) =>
            setSearchValues((prevSearchValues) => ({
              ...prevSearchValues,
              [objKey]: value as string[],
            }))
          }
        />
      );
    },
    [searchValues, setSearchValues]
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Form.Group widths={2}>
        {renderStringInput('Name', 'name')}
        {renderMultiSelectStringArray('Types', 'types', types)}
      </Form.Group>
      <Form.Group widths={2}>
        {renderStringInput('Use Ndc', 'useNdc')}
        {renderStringInput('Sale Ndc', 'saleNdc')}
      </Form.Group>
    </Form>
  );
};

export default VaccineSearch;
