import { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { MappingTypes, mappingOptions } from '@bluefox/models/Mappings';
import { Container, Icon, Card, Dropdown } from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import MainLayout from '@ui/MainLayout';
import InsurancesMapping from '@ui/Mappings/InsuranceMapping/InsurancesMapping';
import HolderMapping from '@ui/Mappings/HolderMapping/HolderMapping';
import BillingStrategyMapping from '@ui/Mappings/BillingStrategy/BillingStrategyMapping';
import VaccinesMapping from '@ui/Mappings/VaccineMapping/VaccinesMapping';
import VfcMapping from '@ui/Mappings/VfcMapping/VfcMapping';
import OutOfNetworkMapping from '@ui/Mappings/OutOfNetworkMapping/OutOfNetworkMapping';

const MappingsScreen = () => {
  const history = useHistory();
  const practice = usePractice();
  const [mappingType, setMappingType] = useState('');

  const handleMappingTypeValue = (value: string) => {
    setMappingType(value);
    history.push(
      `/practices/${practice.handler}/mappings/${value.split('_').join('-')}`
    );
  };

  //TODO: fix render of component based on mappingType state so the right component is shown when refreshing url
  const typesLists = (mappingType: string) => {
    switch (mappingType) {
      case MappingTypes.VACCINES:
        return (
          <Route
            path={`/practices/${practice.handler}/mappings/vaccines`}
            exact
          >
            <VaccinesMapping />
          </Route>
        );
      case MappingTypes.INSURANCES:
        return (
          <Route
            path={`/practices/${practice.handler}/mappings/insurances`}
            exact
          >
            <InsurancesMapping />
          </Route>
        );
      case MappingTypes.HOLDER:
        return (
          <Route path={`/practices/${practice.handler}/mappings/holder`} exact>
            <HolderMapping />
          </Route>
        );
      case MappingTypes.VFC:
        return (
          <Route path={`/practices/${practice.handler}/mappings/vfc`} exact>
            <VfcMapping />
          </Route>
        );
      case MappingTypes.BILLING_STRATEGY:
        return (
          <Route
            path={`/practices/${practice.handler}/mappings/billing-strategy`}
            exact
          >
            <BillingStrategyMapping />
          </Route>
        );
      case MappingTypes.OON:
        return (
          <Route
            path={`/practices/${practice.handler}/mappings/out-of-network`}
            exact
          >
            <OutOfNetworkMapping />
          </Route>
        );
      default:
        return <VaccinesMapping />;
    }
  };

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Mappings' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="map outline" style={{ marginRight: '0.6rem' }} />
                Mappings
              </Card.Header>
              <Dropdown
                style={{ maxWidth: '20rem' }}
                placeholder="Select mapping type"
                fluid
                selection
                onChange={(e, data) => {
                  handleMappingTypeValue(data.value?.toString() || '');
                }}
                options={mappingOptions}
              />
            </div>
            {typesLists(mappingType)}
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default MappingsScreen;
