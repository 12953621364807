import { gql } from '@apollo/client';

// Poll inteval: every 30 minutes
export const PendingNotificationsCountDefaultPollInterval = 30 * 60 * 1000;

export type PendingNotificationsCountResponse = {
  pendingNotifications: {
    aggregate: {
      count: number;
    };
  };
};

export const PendingNotificationsCountQuery = gql`
  query PendingNotificationsCount(
    $where: communication_notifications_bool_exp = { status: { _eq: pending } }
  ) {
    pendingNotifications: communication_notifications_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const NotificationsQuery = gql`
  query Notifications(
    $limit: Int
    $offset: Int
    $where: communication_notifications_bool_exp = {}
    $withPractice: Boolean! = false
  ) {
    communication_notifications(
      limit: $limit
      offset: $offset
      order_by: { updatedAt: desc }
      where: $where
    ) {
      createdAt
      updatedAt
      id
      content
      status
      type
      statusLog
      note
      entityRefId
      pendingReadBy
      givenAt
      practice @include(if: $withPractice) {
        id
        name
      }
    }
    communication_notifications_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const updateNotifications = gql`
  mutation UpdateNotifications(
    $id: uuid!
    $status: communication_notification_statuses_enum!
    $statusLog: jsonb!
    $updatedAt: timestamptz = null
    $viewedBy: uuid = null
    $note: String = null
  ) {
    update_communication_notifications_by_pk(
      pk_columns: { id: $id }
      _set: {
        status: $status
        statusLog: $statusLog
        note: $note
        viewedBy: $viewedBy
      }
    ) {
      id
    }
  }
`;

export const getNotificationMoreDataQuery = gql`
  query getNotificationMoreData(
    $notificationId: uuid!
    $withClaim: Boolean! = false
    $withInventoryOrder: Boolean! = false
  ) {
    communication_notifications(where: { id: { _eq: $notificationId } }) {
      id
      status
      statusLog
      comments
      pendingReadBy
      type
      content
      entityRefId
      claim @include(if: $withClaim) {
        id
        status
        updates(order_by: { createdAt: desc }, limit: 1) {
          metadata
        }
        cptCodes {
          cptCode
        }
      }
      inventoryOrder @include(if: $withInventoryOrder) {
        id
        lot
        inventoryExpiration
        status
      }
    }
  }
`;

export const updateNotificationAndClaimMutation = gql`
  mutation updateNotificationAndClaimMutation(
    $notificationId: uuid!
    $notificationStatus: communication_notification_statuses_enum!
    $notificationStatusLog: jsonb!
    $claimId: uuid!
    $claimStatus: billing_claim_statuses_enum!
    $metadata: jsonb
    $practiceAccountId: uuid!
    $source: String = "manual"
  ) {
    update_communication_notifications_by_pk(
      pk_columns: { id: $notificationId }
      _set: { status: $notificationStatus, statusLog: $notificationStatusLog }
    ) {
      id
      status
      statusLog
    }
    update_billing_claims_by_pk(
      pk_columns: { id: $claimId }
      _set: { status: $claimStatus }
    ) {
      id
      status
    }
    insert_billing_claim_updates_one(
      object: {
        claimId: $claimId
        metadata: $metadata
        practiceAccountId: $practiceAccountId
        source: $source
        status: $claimStatus
      }
    ) {
      id
      claimId
      metadata
      practiceAccountId
      source
      status
    }
  }
`;

export const updateNotificationComments = gql`
  mutation updateNotificationComments(
    $id: uuid = ""
    $comments: jsonb = ""
    $pendingReadBy: String = ""
  ) {
    update_communication_notifications_by_pk(
      pk_columns: { id: $id }
      _set: { pendingReadBy: $pendingReadBy }
      _append: { comments: $comments }
    ) {
      comments
      pendingReadBy
    }
  }
`;

export const updateNotificationCommentsPendingReadByMutation = gql`
  mutation updateNotificationCommentsPendingReadByMutation(
    $id: uuid!
    $pendingReadBy: String = ""
  ) {
    update_communication_notifications_by_pk(
      pk_columns: { id: $id }
      _set: { pendingReadBy: $pendingReadBy }
    ) {
      pendingReadBy
    }
  }
`;

export const confirmOrderMutation = gql`
  mutation confirmOrderMutation(
    $orderId: uuid!
    $lotNumber: String
    $inventoryExpiration: date
  ) {
    confirmOrder(
      orderId: $orderId
      lotNumber: $lotNumber
      inventoryExpiration: $inventoryExpiration
    ) {
      code
      message
    }
  }
`;
