import styled from 'styled-components';
import PercentageIcon from './PercentageIcon';
import { COLORS_BY_NAME, pixelToRem } from '@bluefox/constants';
import { CSSProperties } from 'react';

type Props = {
  amount: number | string;
  percentage?: number;
  subtitle?: string;
  style?: CSSProperties;
};

const MoneyParagraph = styled.p`
  margin: 0 !important;
  font-size: ${pixelToRem(40)}rem;
  line-height ${56 / 40};
  font-weight: 700;
  vertical-align: middle;
  color: ${COLORS_BY_NAME['Raisin Black']}
`;

export const TotalAmountSubtitleParagraph = styled.p`
  font-size: ${pixelToRem(16)}rem;
  line-height: ${24 / 16};
  font-weight: 400;
  letter-spacing: 0.25px;
  color: ${COLORS_BY_NAME['Granite Gray']};
`;

const TotalAmount = ({ amount, percentage, subtitle, style }: Props) => {
  return (
    <div style={style}>
      <MoneyParagraph>
        {amount}{' '}
        {percentage !== undefined && <PercentageIcon percentage={percentage} />}
      </MoneyParagraph>
      {subtitle && (
        <TotalAmountSubtitleParagraph>{subtitle}</TotalAmountSubtitleParagraph>
      )}
    </div>
  );
};

export default TotalAmount;
