import { notificationStatusEnumToText } from '@bluefox/constants/notifications';
import { ALL, allOption } from '@bluefox/lib/options';
import { NotificationStatus } from '@bluefox/models/Notification';
import { CSSProperties } from 'react';
import { Form } from 'semantic-ui-react';

function renderStatusOption(notificationStatus: NotificationStatus) {
  return {
    key: notificationStatus,
    text: notificationStatusEnumToText[notificationStatus],
    value: notificationStatus,
  };
}

const statusOptionsIT = [
  ...allOption,
  renderStatusOption(NotificationStatus.pending_approval),
  renderStatusOption(NotificationStatus.dismissed),
  renderStatusOption(NotificationStatus.pending),
  renderStatusOption(NotificationStatus.resolved),
  renderStatusOption(NotificationStatus.rejected),
  renderStatusOption(NotificationStatus.finalized),
];

const statusOptionsPP = [
  ...allOption,
  renderStatusOption(NotificationStatus.pending),
  renderStatusOption(NotificationStatus.resolved),
];

const statusOptionsOrdersPP = [
  ...statusOptionsPP,
  renderStatusOption(NotificationStatus.rejected),
];

type Props = {
  internalTools?: boolean;
  value?: NotificationStatus | typeof ALL;
  orderNotifications?: boolean;
  onChange?: (value: NotificationStatus | typeof ALL) => void;
  style?: CSSProperties;
};

const NotificationStatusDropdown = ({
  internalTools,
  value,
  orderNotifications,
  onChange,
  style,
}: Props) => {
  return (
    <Form.Dropdown
      style={style}
      id="notification_status_dropdown_filter"
      search
      selection
      options={
        internalTools
          ? statusOptionsIT
          : orderNotifications
          ? statusOptionsOrdersPP
          : statusOptionsPP
      }
      value={value}
      onChange={(_, { value }) => {
        onChange?.(value as NotificationStatus | typeof ALL);
      }}
    />
  );
};

export default NotificationStatusDropdown;
