import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import {
  InsertInventoryOrderMutation,
  UpdateInventoryOrderMutation,
  PurchasePlatformsQuery,
} from '@bluefox/graphql/inventoryOrders';
import {
  OrderStatuses,
  purchasePlatforms,
} from '@bluefox/models/InventoryOrders';
import { Vaccine } from '@bluefox/models/Vaccine';
import { toast } from 'react-semantic-toasts';
import {
  Button,
  Form,
  Dropdown,
  Radio,
  Icon,
  Segment,
  Header,
  Message,
  MessageHeader,
} from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import VaccinePicker from './VaccinePicker';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import { CreditCard, formatCreditCard } from '@bluefox/models/CreditCard';
import { formatDateToMMhDDhYYYY } from '@bluefox/lib/formatters';
import { CreditCardsQuery } from '@graphql/creditCards';

const orderStatusOptions = [
  { key: 'ordered', text: 'Ordered', value: OrderStatuses.ORDERED },
  { key: 'received', text: 'Received', value: OrderStatuses.RECEIVED },
  { key: 'cancelled', text: 'Cancelled', value: OrderStatuses.CANCELLED },
  { key: 'closed', text: 'Closed', value: OrderStatuses.CLOSED },
];

interface InventoryOrderFormProps {
  close: () => void;
  orderData: any;
  refetchOrders: () => void;
}

interface PurchasePlatformOption {
  value: string;
  comment: string;
}

interface PurchasePlatformOptions {
  purchasePlatforms: PurchasePlatformOption[];
}

interface CreditCardOptions {
  creditCards: CreditCard[];
}

const InventoryOrderForm = ({
  close,
  orderData,
  refetchOrders,
}: InventoryOrderFormProps) => {
  const practice = usePractice();
  const { session } = useApplicationState();
  const orderId = orderData ? orderData.id : null;
  const tz =
    practice.timezone === '' ? orderData?.practice.timezone : practice.timezone;

  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>(
    orderData?.vaccine || ''
  );
  const [purchaseDate, setPurchaseDate] = useState<Date | undefined>(undefined);
  const [inventoryExpiration, setInventoryExpiration] = useState<
    Date | undefined | null
  >(undefined);
  const [lot, setLot] = useState<string>(orderData?.lot || '');
  const [purchasePlatform, setPurchasePlatform] = useState<string>(
    orderData?.purchasePlatform || ''
  );
  const [vialsQuantity, setVialsQuantity] = useState<string>(
    orderData?.packagesAmount || ''
  );
  const [doses, setDoses] = useState<number | undefined>(
    orderData?.doses || undefined
  );
  const [amount, setAmount] = useState<number | undefined>(
    orderData?.amount || undefined
  );
  const [vfc, setVfc] = useState(
    orderData?.vfc === true ? 'vfc' : orderData?.vfc === false ? 'notVfc' : ''
  );
  const [orderNumber, setOrderNumber] = useState<string>(
    orderData?.orderNumber || ''
  );
  const [trackingNumber, setTrackingNumber] = useState<string>(
    orderData?.trackingNumber || ''
  );
  const [invoiceDue, setInvoiceDue] = useState<Date | undefined | null>(
    undefined
  );
  const [invoiceNumber, setInvoiceNumber] = useState<string>(
    orderData?.invoiceNumber || ''
  );
  const [invoiceLink, setInvoiceLink] = useState<string>(
    orderData?.invoiceLink || ''
  );
  const [status, setStatus] = useState<OrderStatuses>(
    orderData?.status || OrderStatuses.ORDERED
  );
  const [paymentDay, setPaymentDay] = useState<Date | undefined>(undefined);
  const [estShippingDate, setEstShippingDate] = useState<Date | undefined>(
    undefined
  );
  const [statementDate, setStatementDate] = useState<Date | undefined>(
    undefined
  );

  const [statusChange, setStatusChange] = useState(false);
  const [statusLog, setStatusLog] = useState<any>();
  const [notes, setNotes] = useState<string>('');
  const [purchasePlatformOptions, setPurchasePlatformOptions] = useState<any>(
    []
  );

  const [creditCard, setCreditCard] = useState<string | undefined>(
    orderData?.creditCardId || ''
  );
  const [creditCardOptions, setCreditCardOptions] = useState<any>([]);

  const { data: purchasePlatformsData, error } =
    useQuery<PurchasePlatformOptions>(PurchasePlatformsQuery);

  const today = formatDateToMMhDDhYYYY(new Date());

  const { data: creditCardData } = useQuery<CreditCardOptions>(
    CreditCardsQuery,
    {
      variables: {
        filter: {
          expiration: { _gte: today },
        },
        limit: 50,
        offset: 0,
      },
      onCompleted: (data) => {
        const creditCardsMap = data.creditCards.map((creditCard) => {
          return {
            key: creditCard.id,
            text: formatCreditCard(creditCard, true),
            value: creditCard.id,
          };
        });
        setCreditCardOptions(creditCardsMap);
      },
    }
  );

  const [saveOrder, { called, loading }] = useMutation(
    orderId ? UpdateInventoryOrderMutation : InsertInventoryOrderMutation
  );

  const invoiceDetailsEmpty = !invoiceNumber && !invoiceDue && !invoiceLink;
  const invoiceDetailsFull = invoiceNumber && invoiceDue && invoiceLink;

  const handleStatusLog = () => {
    const selectedCreditCard = creditCardData?.creditCards.find(
      (cc) => cc.id === creditCard
    );
    if (!orderId) {
      return setStatusLog([
        {
          status,
          date: new Date(),
          practiceAccount: {
            id: session?.account?.id,
            firstName: session?.account?.firstName,
            LastName: session?.account?.lastName,
            email: session?.account?.email,
          },
          creditCard: {
            id: selectedCreditCard ? selectedCreditCard.id : '',
            number: selectedCreditCard
              ? formatCreditCard(selectedCreditCard, false)
              : '',
            holder: selectedCreditCard ? selectedCreditCard.userName : '',
            expiration: selectedCreditCard ? selectedCreditCard.expiration : '',
            type: selectedCreditCard ? selectedCreditCard.type : '',
          },
        },
      ]);
    }

    setStatusLog({
      date: new Date(),
      status: status,
      practiceAccount: {
        id: session?.account?.id,
        firstName: session?.account?.firstName,
        LastName: session?.account?.lastName,
        email: session?.account?.email,
      },
      creditCard: {
        id: selectedCreditCard ? selectedCreditCard.id : '',
        number: selectedCreditCard
          ? formatCreditCard(selectedCreditCard, false)
          : '',
        holder: selectedCreditCard ? selectedCreditCard.userName : '',
        expiration: selectedCreditCard ? selectedCreditCard.expiration : '',
        type: selectedCreditCard ? selectedCreditCard.type : '',
      },
    });
  };

  const handleSubmit = () => {
    saveOrder({
      variables: {
        orderId,
        practiceId: practice.id,
        amount,
        date: purchaseDate,
        doses,
        inventoryExpiration,
        invoiceDue,
        orderNumber,
        trackingNumber,
        lot: lot.trim(),
        packagesAmount: vialsQuantity,
        paid: paymentDay ? true : false,
        paidAt: paymentDay,
        purchasePlatform,
        status,
        statusLog: statusLog,
        vaccineId: selectedVaccine?.id,
        vfc: vfc === 'vfc' ? true : vfc === 'notVfc' ? false : null,
        estimatedShippingDate: estShippingDate,
        invoiceNumber,
        invoiceLink,
        statementDate,
        notes,
        ...(creditCard ? { creditCardId: creditCard } : {}),
      },
    })
      .then(() => {
        close();
        toast({
          title: `Order has been ${
            orderId ? 'updated' : 'created'
          } successfully`,
          type: 'success',
          time: 1000,
        });
        refetchOrders();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleVfcChange = (_: any, { value }: any) => setVfc(value);

  useEffect(() => {
    if (!orderData) return;

    setSelectedVaccine(orderData.vaccine || '');
    setPurchaseDate(
      orderData.date ? moment.tz(orderData.date, tz).toDate() : new Date()
    );
    setInventoryExpiration(
      orderData.inventoryExpiration
        ? moment.tz(orderData.inventoryExpiration, tz).toDate()
        : undefined
    );
    setLot(orderData.lot || '');
    setPurchasePlatform(orderData.purchasePlatform || '');
    setVialsQuantity(orderData.packagesAmount || '');
    setDoses(orderData.doses || '');
    setAmount(orderData.amount || '');
    setVfc(
      orderData.vfc === true ? 'vfc' : orderData.vfc === false ? 'notVfc' : ''
    );
    setOrderNumber(orderData.orderNumber || '');
    setInvoiceDue(
      orderData.invoiceDue
        ? moment.tz(orderData.invoiceDue, tz).toDate()
        : undefined
    );
    setStatus(orderData.status || OrderStatuses.ORDERED);
    setPaymentDay(
      orderData.paidAt ? moment.tz(orderData.paidAt, tz).toDate() : undefined
    );
    setEstShippingDate(
      orderData.estimatedShippingDate
        ? moment.tz(orderData.estimatedShippingDate, tz).toDate()
        : undefined
    );
    setStatementDate(
      orderData.statementDate
        ? moment.tz(orderData.statementDate, tz).toDate()
        : undefined
    );
    setNotes(orderData.notes || '');
  }, [orderData]);

  useEffect(() => {
    handleStatusLog();
  }, [statusChange, creditCard]);

  useEffect(() => {
    if (!purchasePlatformsData) return;

    const purchasePMap = purchasePlatformsData?.purchasePlatforms?.map(
      (purchasePlatform) => {
        return {
          key: purchasePlatform.value,
          text: purchasePlatform.comment,
          value: purchasePlatform.value,
        };
      }
    );
    setPurchasePlatformOptions(purchasePMap);
  }, [purchasePlatformsData]);

  return (
    <>
      <Form onSubmit={handleSubmit} data-automation-id="order-form">
        <Form.Group>
          <div
            style={{
              display: 'flex',
              width: '12rem',
              justifyContent: 'space-between',
              padding: '0.4rem',
              borderRadius: '8px',
              border:
                vfc === 'vfc'
                  ? '2px solid #F44E40'
                  : vfc === 'notVfc'
                  ? '2px solid #28C4FF'
                  : '2px solid lightgrey',
              backgroundColor:
                vfc === 'vfc'
                  ? '#FE9A76'
                  : vfc === 'notVfc'
                  ? '#98E3F7'
                  : '#ffffff',
            }}
          >
            <Form.Field>
              <Radio
                label="VFC"
                name="vfc"
                value="vfc"
                checked={vfc === 'vfc'}
                onChange={handleVfcChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="Private"
                name="notVfc"
                value="notVfc"
                checked={vfc === 'notVfc'}
                onChange={handleVfcChange}
              />
            </Form.Field>
          </div>
          <Icon name="asterisk" corner="top right" color="red" size="mini" />
        </Form.Group>
        <Segment>
          <Header>Order details</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Vaccine</label>
              <VaccinePicker
                disabled={!!orderData?.vaccine.id}
                dropdownProps={{
                  fluid: true,
                  placeholder: 'Search Vaccine',
                }}
                onChange={({ vaccine }) =>
                  setSelectedVaccine(vaccine as Vaccine)
                }
                value={orderData?.vaccine.id}
                extraFilters={{ active: { _eq: true } }}
              />
            </Form.Field>
            <Form.Input
              value={lot}
              onChange={(_, { value }) => setLot(value)}
              fluid
              label="Lot #"
              placeholder="Lot #"
              data-automation-id="order-form-lot"
            />
            <Form.Input
              value={orderNumber}
              onChange={(_, { value }) => setOrderNumber(value)}
              fluid
              label="Order #"
              placeholder="Order #"
              data-automation-id="order-form-order-number"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Purchase Date</label>
              <DateTimePicker
                tz={tz}
                selected={purchaseDate}
                onChange={(d) => setPurchaseDate(d ? (d as Date) : undefined)}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                isClearable
                required
                data-automation-id="order-form-purchase-date"
              />
            </Form.Field>
            <Form.Field required>
              <label>Purchase Platform</label>
              <Dropdown
                placeholder="Purchase Platform"
                value={purchasePlatform}
                onChange={(_, { value }) => {
                  setPurchasePlatform(value as purchasePlatforms);
                }}
                clearable
                selection
                options={purchasePlatformOptions ? purchasePlatformOptions : []}
                data-automation-id="order-form-purchase-platform"
              />
            </Form.Field>
            <Form.Field>
              <label>Inventory Expiration</label>
              <DateTimePicker
                tz={tz}
                minDate={purchaseDate}
                selected={inventoryExpiration}
                onChange={(d) => {
                  setInventoryExpiration(d ? (d as Date) : undefined);
                }}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                isClearable
                data-automation-id="order-form-inventory-expiration"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              value={vialsQuantity}
              onChange={(_, { value }) => setVialsQuantity(value)}
              fluid
              label="Vials Quantity"
              placeholder="Vials Quantity"
              required
              data-automation-id="order-form-vials-quantity"
            />
            <Form.Input
              type="number"
              min="1"
              max="1000"
              value={doses}
              onChange={(_, { value }) => setDoses(parseInt(value))}
              fluid
              label="Doses"
              placeholder="Doses"
              required
              data-automation-id="order-form-doses"
            />
            <Form.Input
              type="number"
              min="0"
              step=".01"
              value={amount}
              onChange={(_, { value }) => setAmount(parseFloat(value))}
              fluid
              label="Amount $"
              placeholder="Amount $"
              required
              data-automation-id="order-form-amount"
            />
          </Form.Group>

          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>Estimated Shipping Date</label>
              <DateTimePicker
                tz={tz}
                minDate={new Date()}
                selected={estShippingDate}
                onChange={(d) =>
                  setEstShippingDate(d ? (d as Date) : undefined)
                }
                onSelect={(value) =>
                  setEstShippingDate(value ? (value as Date) : undefined)
                }
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                isClearable
                data-automation-id="order-form-estimated-shipping-date"
              />
            </Form.Field>
            <Form.Field>
              <label>CC Statement Date</label>
              <DateTimePicker
                tz={tz}
                minDate={new Date()}
                selected={statementDate}
                onChange={(d) => setStatementDate(d ? (d as Date) : undefined)}
                onSelect={(value) =>
                  setStatementDate(value ? (value as Date) : undefined)
                }
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                isClearable
                data-automation-id="order-form-statement-date"
              />
            </Form.Field>
            <Form.Field required>
              <label>Status</label>
              <Dropdown
                data-automation-id="order-form-status"
                placeholder="Status"
                value={status}
                onChange={(_, { value }) => {
                  setStatus(value as OrderStatuses);
                  setStatusChange(true);
                }}
                search
                selection
                options={orderStatusOptions}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                value={trackingNumber}
                onChange={(_, { value }) => setTrackingNumber(value)}
                fluid
                label="Tracking Number"
                placeholder="Tracking Number"
                data-automation-id="Tracking Number"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field>
              <label>Credit Card</label>
              <Dropdown
                data-automation-id="order-form-credit-card"
                placeholder="Credit Card"
                value={creditCard}
                onChange={(_, { value }) => {
                  setCreditCard(value as string);
                }}
                search
                selection
                clearable
                options={creditCardOptions}
              />
            </Form.Field>
          </Form.Group>
        </Segment>
        <Segment>
          <Header>Invoice details</Header>
          <Form.Group widths={'equal'}>
            <Form.Field>
              <Form.Input
                value={invoiceNumber}
                onChange={(_, { value }) => setInvoiceNumber(value)}
                fluid
                label="Invoice Number"
                error={!invoiceDetailsEmpty && !invoiceNumber}
                placeholder="Invoice Number"
                data-automation-id="Invoice Number"
              />
            </Form.Field>
            <Form.Field>
              <label>Invoice Due</label>
              <DateTimePicker
                tz={tz}
                selected={invoiceDue}
                onChange={(d) => setInvoiceDue(d ? (d as Date) : undefined)}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                // error={!invoiceDetailsEmpty && !invoiceDue}
                dropdownMode="select"
                isClearable
                data-automation-id="order-form-invoice-due"
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                value={invoiceLink}
                onChange={(_, { value }) => setInvoiceLink(value)}
                fluid
                label="Invoice Link"
                error={!invoiceDetailsEmpty && !invoiceLink}
                placeholder="Invoice Link"
                data-automation-id="Invoice Link"
              />
            </Form.Field>
            <Form.Field>
              <label>Paid At</label>
              <DateTimePicker
                tz={tz}
                selected={paymentDay}
                onChange={(d) => setPaymentDay(d ? (d as Date) : undefined)}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                isClearable
                data-automation-id="order-form-paid-at"
              />
            </Form.Field>
          </Form.Group>

          {!invoiceDetailsEmpty && !invoiceDetailsFull && (
            <Message color="yellow">
              <MessageHeader>Warning</MessageHeader>
              <p>
                The Invoice Number, Invoice Due date, and Invoice Link are
                required fields if any one of them is filled.
              </p>
            </Message>
          )}
        </Segment>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Form.TextArea
              placeholder="Add note here."
              value={notes}
              label="Notes"
              onChange={(_, { value }) => {
                setNotes(value as string);
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button
            onClick={close}
            type="button"
            data-automation-id="order-form-cancel-button"
          >
            Cancel
          </Button>
          <Button
            primary
            type="submit"
            content="Save"
            icon="save"
            disabled={
              !selectedVaccine ||
              !status ||
              !vfc ||
              !purchasePlatform ||
              (called && loading) ||
              (!invoiceDetailsEmpty && !invoiceDetailsFull)
            }
            data-automation-id="order-form-save-button"
          />
        </Form.Field>
      </Form>
    </>
  );
};

export default InventoryOrderForm;
