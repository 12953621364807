import React, { useEffect, useMemo, useRef, useState } from 'react';
import MainLayout from '@ui/MainLayout';
import VaccineSearch, { SearchValuesProps } from '@ui/Vaccines/VaccineSearch';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Dimmer,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';
import { debounce } from '@bluefox/lib/debounce';
import { VACCINE_TYPES } from '@bluefox/store/vaccines';
import { useQuery } from '@apollo/client';
import { SearchVaccinesListQuery } from '@bluefox/graphql/vaccines';
import { whereLikeInput } from '@bluefox/graphql/utils';
import VaccinesTable from '@ui/Vaccines/VaccineTable';
import VaccinePagination from '@ui/Vaccines/VaccinePagination';
import styled from 'styled-components';
import { Vaccine } from '@bluefox/models/Vaccine';

const pageLimit = 20;

export const StyledPagination = styled.div({
  textAlign: 'center',
});

export const StyledCardHeader = styled(Card.Header)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const VaccinesScreen = () => {
  const history = useHistory();

  const debouncedRef = useRef<ReturnType<typeof debounce>>();
  const [searchValues, setSearchValues] = useState<Partial<SearchValuesProps>>(
    {}
  );
  const [queryValues, setQueryValues] = useState<Partial<SearchValuesProps>>(
    {}
  );
  const [activePage, setActivePage] = useState(1);
  const [tableData, setTableData] = useState<Vaccine[]>();

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  const vaccineTypes = useMemo(() => (VACCINE_TYPES as string[]).sort(), []);

  const { loading, error, data } = useQuery(SearchVaccinesListQuery, {
    variables: {
      limit: pageLimit,
      offset: (activePage - 1) * pageLimit,
      criteria: {
        ...(queryValues?.name
          ? { name: { _ilike: whereLikeInput(queryValues.name) } }
          : {}),
        ...(queryValues?.saleNdc
          ? { saleNdc: { _ilike: whereLikeInput(queryValues.saleNdc) } }
          : {}),
        ...(queryValues?.useNdc
          ? { useNdc: { _contains: queryValues.useNdc } }
          : {}),
        ...(queryValues?.types
          ? { types: { _has_keys_all: queryValues.types } }
          : {}),
      },
    },
    onCompleted: (data) => {
      setTableData(data?.vaccines);
    },
    onError: () => {
      setTableData(undefined);
    },
    fetchPolicy: 'network-only',
  });

  return (
    <MainLayout
      path={[{ text: 'Vaccines', to: '/vaccines' }]}
      loading={loading}
    >
      <Container data-automation-id="it-vaccines" fluid>
        <Card fluid>
          <Card.Content>
            <StyledCardHeader as={'h3'}>
              <div>
                <Icon name="syringe" style={{ marginRight: '0.6rem' }} />
                Vaccines
              </div>
              <div>
                <Button primary onClick={() => history.push('/vaccines/new')}>
                  Add Vaccine
                </Button>
                <Button
                  primary
                  onClick={() => history.push('/vaccines/pricing')}
                >
                  Vaccines Pricing
                </Button>
              </div>
            </StyledCardHeader>
            <Card.Description>
              <VaccineSearch
                searchValues={searchValues}
                setSearchValues={(value) => {
                  setSearchValues(value);
                  debouncedRef.current?.cancel();
                  debouncedRef.current = debounce(() => {
                    setQueryValues(value);
                    setActivePage(1);
                  }, 500);
                  debouncedRef.current();
                }}
                onSubmit={() => {
                  setQueryValues(searchValues);
                  setActivePage(1);
                }}
                types={vaccineTypes}
              />
              {error && <Message error>{error.message}</Message>}
              <Dimmer.Dimmable blurring dimmed={loading}>
                <Dimmer active={loading}>
                  <Loader size="large" />
                </Dimmer>
                <VaccinesTable vaccines={tableData} />
                <StyledPagination>
                  <VaccinePagination
                    activePage={activePage}
                    totalPages={
                      data?.vaccines?.length > 0
                        ? Math.ceil(
                            data.vaccines_aggregate?.aggregate?.count /
                              pageLimit
                          )
                        : 1
                    }
                    onPageChange={(newActivePage) =>
                      setActivePage(newActivePage)
                    }
                  />
                </StyledPagination>
              </Dimmer.Dimmable>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default VaccinesScreen;
