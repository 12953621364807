import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  AthenaMappingsQuery,
  DeleteMappingMutation,
} from '@bluefox/graphql/integrations/athena';
import { MappedItem } from '@bluefox/models/integrations/Athena';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import {
  Segment,
  Card,
  Container,
  Table,
  Placeholder,
  Message,
  Button,
  Popup,
  Modal,
  Menu,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import AthenaProviderMappingForm from './AthenaProviderMappingForm';

interface AthenaMappingData {
  athenaMappings: MappedItem[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const AthenaProviderMapping = () => {
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedProviderMapping, setSelectedProviderMapping] =
    useState<MappedItem>();
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);
  const [criteria, setCriteria] = useState({});
  const [practiceId, setPracticeId] = useState<string>();

  const { data, loading, refetch } = useQuery<AthenaMappingData>(
    AthenaMappingsQuery,
    {
      variables: {
        criteria,
        limit: null,
        offset: null,
      },
    }
  );

  const mappedKeys = data?.athenaMappings.map((m) => m.key);

  const closeModal = () => {
    setIsSelected(false);
    setSelectedProviderMapping(undefined);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  const handlePracticeValue = (value: string) => {
    setPracticeId(value !== 'allPractices' ? value : undefined);
    if (value === 'allPractices') refetch();
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  useEffect(() => {
    if (!data?.allPractices || data.allPractices.length < 1) return;

    setPracticeOptions(
      data.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;

    if (practiceId) {
      _practiceId = { _eq: practiceId };
    }

    _criteria = {
      practiceId: _practiceId,
      type: { _eq: 'provider' },
    };

    setCriteria(_criteria);
  }, [practiceId]);

  return (
    <MainLayout
      path={[
        { text: 'Athena Integration', to: '/integration-athena' },
        { text: 'Provider Mapping' },
      ]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="map outline" style={{ marginRight: '0.6rem' }} />
                Provider Mapping
              </Card.Header>
            </div>
            <Menu borderless>
              <Menu.Item>
                <Dropdown
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by practice"
                  fluid
                  selection
                  onChange={(e, data) => {
                    handlePracticeValue(data.value?.toString() || '');
                  }}
                  options={[
                    { text: 'All practices', value: 'allPractices' },
                    ...practiceOptions,
                  ]}
                />
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    primary
                    size="small"
                    content="Add Provider"
                    icon="plus"
                    onClick={() => {
                      setIsSelected(false);
                      setOpen(true);
                    }}
                    disabled={!practiceId}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Practice</Table.HeaderCell>
                  <Table.HeaderCell>Provider ID (Athena)</Table.HeaderCell>
                  <Table.HeaderCell>Provider (Canid)</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data && data.athenaMappings.length ? (
                  data.athenaMappings.map((item) => {
                    return (
                      <AthenaProviderMappingRow
                        key={item.id}
                        data={item}
                        onChangeSelected={() => {
                          handleChangeSelected(true);
                          setSelectedProviderMapping({
                            id: item.id,
                            key: item.key,
                            type: item.type,
                            value: item.value,
                            practice: item.practice,
                          });
                        }}
                        onDelete={refetch}
                        editDisabled={!practiceId}
                      />
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Message>No mappings found.</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Modal
              size="small"
              onClose={closeModal}
              onOpen={() => setOpen(open)}
              open={open}
              closeIcon
            >
              <Modal.Header>
                {isSelected ? 'Edit Provider Mapping' : 'Add Provider Mapping'}
              </Modal.Header>
              <Modal.Content>
                <AthenaProviderMappingForm
                  close={closeModal}
                  practiceId={practiceId}
                  data={
                    selectedProviderMapping ? selectedProviderMapping : null
                  }
                  refetchMappings={refetch}
                  mappedKeys={mappedKeys || []}
                />
              </Modal.Content>
            </Modal>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//------------------------------AthenaProviderMappingRow------------------------------//

interface AthenaProviderMappingRowProps {
  data: MappedItem;
  onChangeSelected: (selected: boolean) => void;
  editDisabled: boolean;
  onDelete: () => void;
}

const AthenaProviderMappingRow = ({
  data,
  onChangeSelected,
  editDisabled,
  onDelete,
}: AthenaProviderMappingRowProps) => {
  const [deleteMapping] = useMutation(DeleteMappingMutation);

  const handleDeleteMapping = () => {
    deleteMapping({
      variables: {
        mappingId: data.id,
      },
    })
      .then(() => {
        toast({
          title: 'Mapping deleted successfully',
          type: 'success',
          time: 1000,
        });
        onDelete();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row>
      <Table.Cell>{data.practice?.name}</Table.Cell>
      <Table.Cell>{data.key}</Table.Cell>
      <Table.Cell>{data.value.name}</Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
              disabled={editDisabled}
            />
          }
        />
        <Popup
          size="small"
          content="Delete"
          trigger={
            <Button
              negative
              size="mini"
              icon="trash"
              onClick={() => handleDeleteMapping()}
              disabled={editDisabled}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default AthenaProviderMapping;
