import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { Card, Button, Input, Popup } from 'semantic-ui-react';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';

interface InfoBoxProps {
  ndc: string;
  manufacturer: string;
  types: string | string[];
  dosage: string | string[];
  inventoryUsed: string;
  lot: string;
  expiration: Date;
}

const VaccineInfoBox = ({
  ndc,
  manufacturer,
  types,
  dosage,
  inventoryUsed,
  lot,
  expiration,
}: InfoBoxProps) => {
  const handleCopyPass = (e: any) => {
    const input =
      e.target.nodeName === 'I'
        ? e.target.parentElement.parentElement.firstChild.value
        : e.target.parentElement.firstChild.value;
    navigator.clipboard.writeText(input);

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  return (
    <Popup
      on="click"
      content={
        <Card>
          <Card.Content>
            <Card.Header
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              Vaccine Information
            </Card.Header>
            <Card.Description style={{ height: '25rem', overflowY: 'scroll' }}>
              <div>
                <label>
                  <b>NDC</b>
                </label>
                <StyledInput
                  fluid
                  value={ndc}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Manufacturer</b>
                </label>
                <StyledInput
                  fluid
                  value={manufacturer}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Types</b>
                </label>
                <StyledInput
                  fluid
                  value={types}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Dosage</b>
                </label>
                <StyledInput
                  fluid
                  value={dosage}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Inventory Used</b>
                </label>
                <StyledInput
                  fluid
                  value={inventoryUsed}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Lot</b>
                </label>
                <StyledInput
                  fluid
                  value={lot}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Expiration</b>
                </label>
                <StyledInput
                  fluid
                  value={expiration ? formatDatetimeToMMDDYYY(expiration) : '-'}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
            </Card.Description>
          </Card.Content>
        </Card>
      }
      trigger={
        <Button
          className="min-component-vertical-margin"
          icon="copy"
          size="tiny"
          content="More info..."
        />
      }
    />
  );
};

export default VaccineInfoBox;

const StyledInput = styled(Input)`
  margin: 0rem 0rem 1rem 0rem;
`;
