import { MappingTypes } from '@bluefox/models/integrations/Develo';
import MappingTable from './MappingTable';

const PrescriberMapping: React.FC = () => {
  return (
    <MappingTable
      mappingTitle="Prescriber"
      mappingType={MappingTypes.prescriber}
      enablePagination={false}
      hasPractice={true}
      invertedMapping={true}
    />
  );
};

export default PrescriberMapping;
