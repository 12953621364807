import { gql } from '@apollo/client';

export const AnnouncementsQuery = gql`
  query AnnouncementsQuery {
    announcements: announcements(order_by: { createdAt: desc }) {
      id
      title
      description
      createdAt
      category
      active
    }
  }
`;

export const InsertAnnouncementMutation = gql`
  mutation MyMutation($title: String!, $description: String) {
    insertAnnouncement: insert_announcements_one(
      object: { title: $title, description: $description }
    ) {
      id
    }
  }
`;

export const UpdateAnnouncementMutation = gql`
  mutation UpdateAnnouncementMutation(
    $id: uuid!
    $title: String
    $description: String
  ) {
    updateAnnouncement: update_announcements_by_pk(
      pk_columns: { id: $id }
      _set: { title: $title, description: $description }
    ) {
      id
    }
  }
`;

export const SetActiveAnnouncementMutation = gql`
  mutation SetActiveAnnouncementMutation($id: uuid!, $active: Boolean!) {
    announcement: update_announcements_by_pk(
      pk_columns: { id: $id }
      _set: { active: $active }
    ) {
      id
      active
    }
  }
`;
