import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Label, Menu, Message, Table } from 'semantic-ui-react';
import Pagination from '@bluefox/ui/Pagination';
import { humanizeText } from '@bluefox/lib/humanize';
import { GetAllInventoryAdjustmentsQuery } from '@bluefox/graphql/inventoryAdjustment';
import { InventoryAdjustment } from '@bluefox/models/InventoryAdjustment';
import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters/dates';
import RowsPerPage from '@ui/Filters/RowsPerPage';
import PracticeFilter from '@ui/Filters/PracticeFilter';

interface InventoryAdjustmentsData {
  inventoryAdjustments: InventoryAdjustment[];
  aggregating: { aggregate: { count: number } };
}

const HomeInventoryAdjustments = () => {
  const [searchPractice, setSearchPractice] = useState<string>('all');
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);
  const [page, setPage] = useState(1);

  const { data } = useQuery<InventoryAdjustmentsData>(
    GetAllInventoryAdjustmentsQuery,
    {
      variables: {
        criteria: {
          status: { _neq: 'applied' },
          practice:
            searchPractice !== 'all' ? { id: { _eq: searchPractice } } : {},
        },
        limit: rowsPerPage,
        offset: !!page ? rowsPerPage * (page - 1) : 0,
      },
      skip: rowsPerPage === 0,
      nextFetchPolicy: 'no-cache',
      fetchPolicy: 'no-cache',
    }
  );

  const typeLabelText = useCallback((value: boolean | undefined) => {
    if (value === undefined) return '-';
    return value ? 'VFC' : 'Private';
  }, []);

  const typeLabelcolor = useCallback((value: boolean | undefined) => {
    if (value === undefined) return 'grey';
    return value ? 'orange' : 'teal';
  }, []);

  useEffect(() => {
    const rows = Number(
      localStorage.getItem('HomeInventoryAdjustmentsRowConfig')
    );
    setRowsPerPage(rows >= 0 ? rows : 15);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchPractice, rowsPerPage]);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / rowsPerPage);

  return (
    <>
      <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item>
            <RowsPerPage
              rows={rowsPerPage}
              setRows={setRowsPerPage}
              localStorageItem="HomeInventoryAdjustmentsRowConfig"
            />
          </Menu.Item>
          {rowsPerPage > 0 && (
            <>
              <Menu.Item>
                <PracticeFilter
                  practiceSearch={searchPractice}
                  setPracticeSearch={setSearchPractice}
                  suspended="notSuspended"
                />
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      {rowsPerPage > 0 && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Practice</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Adjustment Date</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!data?.inventoryAdjustments.length ? (
              data?.inventoryAdjustments.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>{item.practice?.name}</Table.Cell>
                    <Table.Cell>
                      <Label
                        size="small"
                        color={typeLabelcolor(item.vfc)}
                        content={typeLabelText(item.vfc)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {item.createdAt
                        ? formatDateToMMDDYYYY(
                            new Date(item.createdAt).toDateString()
                          )
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      <Label
                        basic
                        content={
                          item.status
                            ? humanizeText(item.status, {
                                capitalize: 'first',
                                delimiter: '_',
                              })
                            : '-'
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <Message>No inventory adjustments found</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Pagination
            total={total}
            colSpan={11}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
      )}
    </>
  );
};

export default HomeInventoryAdjustments;
