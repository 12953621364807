import { gql } from '@apollo/client';

export const ContractPlansQuery = gql`
  query ContractPlansQuery(
    $limit: Int
    $offset: Int
    $criteria: contract_plans_bool_exp
    $vaccinePricingCriteria: [vaccine_pricing_bool_exp!]
  ) {
    contractPlans: contract_plans(
      limit: $limit
      offset: $offset
      order_by: { name: asc }
      where: $criteria
    ) {
      id
      type
      name
      discounts
      startDate
      endDate
      endDate
      vaccinePricings(
        distinct_on: [vaccineId, createdAt]
        order_by: { vaccineId: asc, createdAt: desc }
        where: { status: { _eq: active } }
      ) {
        id
        status
        price
        priceCents
        pricePerDose
        priceCentsPerDose
        vaccine {
          id
          name
          saleNdc
          inventoryCptCodes
          manufacturer
          packageType
          packageDoses
        }
      }
    }
    aggregating: contract_plans_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const InsertContractPlan = gql`
  mutation InsertContractPlan($object: contract_plans_insert_input!) {
    insert_contract_plans_one(
      object: $object
      on_conflict: {
        constraint: contract_plans_pkey
        update_columns: [name, type, startDate, discounts]
      }
    ) {
      id
      name
    }
  }
`;

export const ContractPlansForFilterQuery = gql`
  query ContractPlansForFilterQuery(
    $limit: Int
    $offset: Int
    $criteria: contract_plans_bool_exp
  ) {
    contractPlans: contract_plans(
      limit: $limit
      offset: $offset
      where: $criteria
    ) {
      id
      startDate
    }
  }
`;

export const SetActiveContractPlanMutation = gql`
  mutation SetActiveContractPlanMutation(
    $practiceId: uuid!
    $contractPlanId: uuid!
    $endDate: date
  ) {
    update_practice_contract_plans(
      where: { practiceId: { _eq: $practiceId }, endDate: { _is_null: true } }
      _set: { endDate: $endDate }
    ) {
      affected_rows
    }
    insert_practice_contract_plans_one(
      object: { practiceId: $practiceId, contractPlanId: $contractPlanId }
    ) {
      id
    }
  }
`;

export const PracticeContractPlanQuery = gql`
  query PrtacticeContractPlanQuery($practiceId: uuid!) {
    practice: practices_by_pk(id: $practiceId) {
      practiceContractPlans(order_by: { created_at: desc }) {
        id
        startDate
        endDate
        contractPlan {
          id
          name
          type
          vaccinePricings {
            id
            doses
            packageType
            priceCents
            vaccine {
              id
              name
              saleNdc
              packageType
              packageDoses
            }
          }
        }
      }
    }
  }
`;
