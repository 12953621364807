import React, { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  Card,
  Icon,
  Menu,
  Button,
  Container,
  Dropdown,
  Confirm,
  Table,
  Label,
  Message,
  Header,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import { usePractice } from '@bluefox/contexts/Practice';
import {
  ContractPlansQuery,
  SetActiveContractPlanMutation,
  PracticeContractPlanQuery,
} from '@graphql/contratPlans';
import { ContractPlansData } from './Vaccines/ContractPlans/ContractPlansList';
import { Practice } from '@bluefox/models/Practice';
import { formatPackage } from '@bluefox/lib/formatters/vaccines';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';

export interface PracticeContractPlanData {
  practice: Practice;
}

const InventoryContractPlans = () => {
  const practice = usePractice();

  const [selectedContractPlanId, setSelectedContractPlanId] = useState('');
  const [openConfirmContractPlan, setOpenConrimContractPlan] = useState(false);

  const { data: allContractPlansData } =
    useQuery<ContractPlansData>(ContractPlansQuery);

  const contractPlansOptions = useMemo(() => {
    return allContractPlansData?.contractPlans.map((cp) => {
      return {
        key: cp.id,
        value: cp.id,
        text: cp.name,
      };
    });
  }, [allContractPlansData?.contractPlans]);

  const { data: practiceContractPlanData, refetch } =
    useQuery<PracticeContractPlanData>(PracticeContractPlanQuery, {
      variables: {
        practiceId: practice.id,
      },
      onCompleted: (data) => {
        if (!data) return;
        setSelectedContractPlanId(
          data.practice.practiceContractPlans
            ? data.practice.practiceContractPlans[0]?.contractPlan.id
            : ''
        );
      },
    });

  const [setActiveContractPlan] = useMutation(SetActiveContractPlanMutation);

  const handleSetActiveContractPlan = async () => {
    if (
      practiceContractPlanData?.practice.practiceContractPlans &&
      practiceContractPlanData?.practice.practiceContractPlans.length &&
      selectedContractPlanId ===
        practiceContractPlanData?.practice.practiceContractPlans[0].contractPlan
          .id
    ) {
      toast({
        title: `Selected contract plan is already active.`,
        type: 'error',
        time: 5000,
      });

      setOpenConrimContractPlan(false);
      return;
    }

    try {
      await setActiveContractPlan({
        variables: {
          practiceId: practice.id,
          contractPlanId: selectedContractPlanId,
          endDate: new Date(),
        },
      });

      toast({
        title: 'Contract plan activated successfully',
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      setOpenConrimContractPlan(false);
      refetch();
    }
  };

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Inventory', to: `/practices/${practice.handler}/inventory` },
        { text: 'Contract Plans' },
      ]}
    >
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="dollar" style={{ marginRight: '0.6rem' }} />
              Contract Plans
            </Card.Header>
            <Card.Description>
              <Menu borderless>
                <Menu.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <label style={{ width: '20rem' }}>
                      <b>Practice Contract Plan:</b>
                    </label>
                    <Dropdown
                      style={{ minWidth: '16rem' }}
                      placeholder="Select Contract Plan"
                      fluid
                      search
                      selection
                      value={selectedContractPlanId}
                      onChange={(_, { value }) => {
                        setSelectedContractPlanId(value as string);
                      }}
                      options={contractPlansOptions}
                    />
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    content="Confirm active Contract Plan"
                    icon="check"
                    color="olive"
                    onClick={() => setOpenConrimContractPlan(true)}
                  />
                </Menu.Item>
              </Menu>
              {practiceContractPlanData?.practice.practiceContractPlans &&
                practiceContractPlanData.practice.practiceContractPlans.length >
                  0 && (
                  <Menu>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Vaccine</Table.HeaderCell>
                          <Table.HeaderCell>Package</Table.HeaderCell>
                          <Table.HeaderCell>Price</Table.HeaderCell>
                          <Table.HeaderCell>Price per dose</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {practiceContractPlanData.practice.practiceContractPlans[0].contractPlan?.vaccinePricings.map(
                          (vaccinePricing) => {
                            return (
                              <Table.Row key={vaccinePricing.id}>
                                <Table.Cell>
                                  <div>{vaccinePricing.vaccine.name}</div>
                                  <Label
                                    basic
                                    size="small"
                                    content={`NDC: ${vaccinePricing.vaccine.saleNdc}`}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {vaccinePricing.vaccine.packageType ? (
                                    <Label
                                      basic
                                      content={formatPackage(
                                        vaccinePricing.vaccine.packageType,
                                        vaccinePricing.vaccine.packageDoses
                                      )}
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  $
                                  {(vaccinePricing.priceCents / 100).toFixed(2)}
                                </Table.Cell>
                                <Table.Cell width={3}>
                                  {vaccinePricing.vaccine.packageDoses &&
                                    `$ ${(
                                      vaccinePricing.priceCents /
                                      100 /
                                      vaccinePricing.vaccine.packageDoses
                                    ).toFixed(2)}
                                  `}
                                </Table.Cell>
                              </Table.Row>
                            );
                          }
                        )}
                      </Table.Body>
                    </Table>
                  </Menu>
                )}
              <Header as={'h4'}>Previous contract plans</Header>
              {practiceContractPlanData?.practice.practiceContractPlans &&
                practiceContractPlanData.practice.practiceContractPlans.length >
                  1 &&
                practiceContractPlanData.practice.practiceContractPlans
                  .slice(1)
                  .map((item) => {
                    return (
                      <Message
                        style={{ margin: '0.2rem 0 0.2rem 0' }}
                        content={
                          <>
                            <b>{item.contractPlan.name}</b>
                            {` (valid from ${formatDateToMMDDYYYYV2(
                              item.startDate
                            )} to ${formatDateToMMDDYYYYV2(item.endDate)})`}
                          </>
                        }
                      />
                    );
                  })}
            </Card.Description>
          </Card.Content>
        </Card>
        <Confirm
          size="tiny"
          content={`Are you sure you want to activate selected contract plan for ${practice.name} practice?`}
          open={openConfirmContractPlan}
          onCancel={() => setOpenConrimContractPlan(false)}
          onConfirm={handleSetActiveContractPlan}
        />
      </Container>
    </MainLayout>
  );
};

export default InventoryContractPlans;
