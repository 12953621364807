import {
  Table,
  Segment,
  Placeholder,
  Message,
  Pagination,
} from 'semantic-ui-react';

import CreditCardsRow from './CreditCardsRow';

interface CreditCard {
  id: string;
  firstDigit: number;
  lastDigits: number;
  type: string;
  userName: string;
  expiration: Date;
  default: boolean;
}

interface CreditCardsData {
  creditCards: CreditCard[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface CreditCardsTableProps {
  data: CreditCardsData | undefined;
  loading: boolean;
  refetch: () => void;
  total: number;
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
}

const ENTRIES_PER_PAGE = 15;

const CreditCardsTable = ({
  data,
  loading,
  refetch,
  total,
  totalPages,
  page,
  setPage,
}: CreditCardsTableProps) => {
  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Number</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>User Name</Table.HeaderCell>
          <Table.HeaderCell>Expiration</Table.HeaderCell>
          <Table.HeaderCell>Default</Table.HeaderCell>
          <Table.HeaderCell>Edit</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell colSpan={9}>
              <Segment basic>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Segment>
            </Table.Cell>
          </Table.Row>
        ) : !!data && data.creditCards.length ? (
          data.creditCards.map((card) => {
            return <CreditCardsRow key={card.id} data={card} />;
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan={9}>
              <Message>No Credit Cards found.</Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell>Total: {total}</Table.HeaderCell>
          <Table.HeaderCell colSpan={11} textAlign="right">
            <Pagination
              activePage={page || 1}
              disabled={!total || total < ENTRIES_PER_PAGE}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) =>
                setPage(activePage as number)
              }
              totalPages={totalPages}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default CreditCardsTable;
