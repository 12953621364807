import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  AthenaMappingsDataQuery,
  InsertAthenaMappingMutation,
  UpdateAthenaMappingMutation,
} from '@bluefox/graphql/integrations/athena';
import { MappedItem } from '@bluefox/models/integrations/Athena';
import { Button, Form } from 'semantic-ui-react';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
const INSURANCE_COMPANY_TYPE = 'insuranceCompany';
interface AthenaInsuranceCompanyMappingFormProps {
  close: () => void;
  data: MappedItem | null;
  refetchMappings: () => void;
  onSave: () => void;
}

const AthenaInsuranceCompanyMappingForm = ({
  close,
  data,
  refetchMappings,
}: AthenaInsuranceCompanyMappingFormProps) => {
  const [insuranceName, setInsuranceName] = useState<string>('');
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<string>('');
  const [athenaKey, setAthenaKey] = useState<string>(data?.key ? data.key : '');
  const [value, setValue] = useState({
    data: data?.value.data || '',
    name: data?.value.name || '',
  });

  const [saveInsuranceCompanyMapping] = useMutation(
    data?.id ? UpdateAthenaMappingMutation : InsertAthenaMappingMutation
  );

  const [validateExistingMapping] = useLazyQuery(AthenaMappingsDataQuery);

  const handleSubmit = async () => {
    const criteria = {
      type: {
        _eq: INSURANCE_COMPANY_TYPE,
      },
      key: {
        _ilike: athenaKey.trim(),
      },
    };

    try {
      const response = await validateExistingMapping({
        variables: {
          criteria,
        },
      });

      if (response.data.mapping.length > 0) {
        toast({
          title: 'Insurance company is already mapped.',
          type: 'error',
          time: 5000,
        });
        return;
      }

      await saveInsuranceCompanyMapping({
        variables: {
          id: data?.id,
          key: athenaKey.trim(),
          type: 'insuranceCompany',
          value: value,
        },
      });

      toast({
        title: 'Mapping saved successfully',
        type: 'success',
        time: 1000,
      });

      close();
      refetchMappings();
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  useEffect(() => {
    const vaccineMappingValue = {
      data: insuranceCompanyId,
      name: insuranceName,
    };

    setValue(vaccineMappingValue);
  }, [insuranceName, insuranceCompanyId]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={athenaKey}
            onChange={(_, { value }) => setAthenaKey(value)}
            fluid
            label="Athena Insurance Company"
            placeholder="Athena Insurance Company"
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Select Insurance</label>
          <InsuranceCompanyPicker
            placeholder="Insurance Name"
            value={data?.value.data || insuranceCompanyId}
            onCompanySelected={(company) => {
              setInsuranceName(company.name);
              setInsuranceCompanyId(company.id);
            }}
            includePayerId
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button primary type="submit" content="Save" icon="save" />
      </Form.Field>
    </Form>
  );
};

export default AthenaInsuranceCompanyMappingForm;
