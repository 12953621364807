import React from 'react';
import moment from 'moment-timezone';
import { ApolloProvider } from '@bluefox/contexts/Apollo';
import {
  ApplicationStateProvider,
  useApplicationState,
} from '@bluefox/contexts/ApplicationState';
import { AuthProvider } from '@bluefox/contexts/Auth';
import { PracticeContextProvider } from '@bluefox/contexts/Practice';
import { LocalStorageProvider } from '@bluefox/contexts/LocalStorage';
import {
  SignInScreen,
  PracticesScreen,
  InventoryScreen,
  PracticeProfileScreen,
  HomeScreen,
  ClaimsScreen,
  ClaimsToUploadToEmrScreen,
  TicketsScreen,
  InsurancesScreen,
  InventoryOrdersScreen,
  MappingsScreen,
  VaxSyncScreen,
  InvoicesListScreen,
  InconsistencyList,
  PatientsClaims,
  BillingScreen,
  Billing2Screen,
  NewInvoicesListScreen,
  BalanceMovementsScreen,
} from '@screens/index';
import {
  BrowserRouter as Router,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { SemanticToastContainer } from 'react-semantic-toasts';
import ConditionalRoute from '@bluefox/ui/ConditionalRoute';
import CustomModal from '@bluefox/ui/CustomModal';
import InsurancesList from '@bluefox/ui/InsurancesList';
import InsurancesForm from '@bluefox/ui/InsurancesForm';
import PatientsScreen from '@screens/patients';
//import AnnouncementsScreen from '@screens/announcements';
import InventoryThresholds from '@ui/InventoryThresholds';
import BillingReportScreen from '@ui/BillingReport/BillingReport';
import VaccinationsReviewScreen from '@ui/VaccinationsReview';
import AnalyticsScreen from '@screens/AnalyticsScreen';
import VaccinesPriceListScreen from '@screens/vaccinesPriceList/vaccinesPriceList';
import SelfPayListScreen from '@ui/Billing/SelfPayList';
import IisErrorsScreen from '@screens/iisErrors';
import BotReportsScreen from '@ui/EcwIntegration/botReports';
import IncompletePatientsScreen from '@screens/incompletePatients';
import AthenaIntegrationScreen from '@screens/athenaIntegration';
import EcwIntegrationScreen from '@screens/ecwIntegration';
import AthenaSubscriptions from '@ui/AthenaIntegration/Subscriptions';
import AthenaOutboundActions from '@ui/AthenaIntegration/OutboundActions';
import EcwVaccineMapping from '@ui/EcwIntegration/mappings/EcwVaccineMapping';
import EcwVfcEligibilityMapping from '@ui/EcwIntegration/mappings/EcwVfcEligibilityMapping';
import EcwAdministrationRouteMapping from '@ui/EcwIntegration/mappings/EcwAdministrationRouteMapping';
import EcwAdministrationSiteMapping from '@ui/EcwIntegration/mappings/EcwAdministrationSiteMapping';
import EcwAdministratedByMapping from '@ui/EcwIntegration/mappings/EcwAdministratedByMapping';
import AthenaProviderMapping from '@ui/AthenaIntegration/AthenaProviderMapping';
import AthenaVaccineSearchCriteriaMapping from '@ui/AthenaIntegration/AthenaVaccineSearchCriteriaMapping';
import AthenaInsuranceCompanyMapping from '@ui/AthenaIntegration/AthenaInsuranceCompanyMapping';
import AthenaVfcCriteriaMapping from '@ui/AthenaIntegration/AthenaVfcCriteriaMapping';
import { UsersScreen } from '@screens/users';
import InventoryAdjustment from '@screens/inventoryAdjustment/InventoryAdjustment';
import InventoryControl from '@screens/inventoryControl/inventoryControl';
import VaccinesQRGenerator from '@screens/helpers/vaccines-qr-generator';
import VaccinesScreen from '@screens/vaccines/VaccinesScreen';
import VaccineNew from '@ui/Vaccines/VaccineNew';
import Vaccine from '@ui/Vaccines/Vaccine';
import VaccineEdit from '@ui/Vaccines/VaccineEdit';
import VaccinesPriceList from '@screens/vaccines/VaccinesPriceList';
import { InconsistentVaccinationsHistoryScreen } from '@screens/practiceInconsistentVaccinationHistory';
import DeveloIntegrationsScreen from '@screens/develoIntegrations';
import DeveloProviderMapping from '@ui/DeveloIntegration/ProviderMapping';
import DeveloVfcCriteriaMapping from '@ui/DeveloIntegration/VfcCriteriaMapping';
import DeveloInsuranceCompanyMapping from '@ui/DeveloIntegration/InsuranceCompanyMapping';
import DeveloPrescriberMapping from '@ui/DeveloIntegration/PrescriberMapping';
import ReportingScreen from '@screens/billing//reporting/reportingScreen';
import GeneralScreen from '@screens/billing/general/generalScreen';
import DeveloAppointments from '@ui/DeveloIntegration/Appointments';
import EcwAppointmentsList from '@ui/EcwIntegration/appointments';
import EcwHistoricalVaccinationsList from '@ui/EcwIntegration/EcwHistoricalVaccinationsList';
import VaccinationsToReport from '@ui/DeveloIntegration/VaccinationsToReport';
import Control2Screen from '@screens/billing/billing2/control/control2Screen';
import Reporting2Screen from '@screens/billing/billing2/reporting/reporting2Screen';
import General2Screen from '@screens/billing/billing2/general/general2Screen';
import NewBillingScreen from '@screens/NewBilling';
import OrganizationsScreen from '@screens/organizations/OrganizationScreen';
import CompareInventoryScreen from '@screens/inventory/CompareInventory';
import UpdateVaccinesCsv from '@screens/vaccines/UpdateVaccinesCsv';
import UpdateVaccinesCsvTable from '@screens/vaccines/UpdateVaccinesCsvTable';
import UpdateVaccinesCsvTableJobDetails from '@screens/vaccines/UpdateVaccinesCsvTableJobDetails';
import BorrowingSummary from '@screens/borrowing/BorrowingSummary';
import BorrowingSummaryDetails from '@screens/borrowing/BorrowingSummaryDetails';
import ScrollToTop from '@bluefox/lib/ScrollToTop';
import SolvePendingBorrowsScreen from '@screens/borrowing/SolvePendingBorrowsScreen';
import BorrowingSummaryDetailClaims from '@screens/borrowing/BorrowingSummaryDetailClaims';
import CreditCardsScreen from '@screens/creditCards/CreditCards';
import ContractPlansList from '@ui/Vaccines/ContractPlans/ContractPlansList';
import InventoryContractPlans from '@ui/InventoryContractPlans';
import NotificationList from '@screens/notifications';
import BorrowingRequestSolvingScreen from '@screens/borrowing/BorrowingRequestSolvingScreen';

moment.updateLocale('en', {
  relativeTime: {
    d: '1 day',
    M: '1 month',
    y: '1 year',
  },
});

const CANID_HANDLER = 'canid';

function App() {
  return (
    <Router>
      <LocalStorageProvider partition={'canid-internal-tools'}>
        <ApplicationStateProvider isEmbedded={false}>
          <ApolloProvider>
            <AuthProvider
              handler={CANID_HANDLER}
              onSignedIn={() => {
                console.debug('on-signed-in !!!!');
              }}
            >
              <Switch>
                <Route path={`/sign-in`}>
                  <SignInScreen />
                </Route>
                <PrivateRoute path={`/`} exact>
                  <ScrollToTop />
                  <HomeScreen />
                </PrivateRoute>
                <PrivateRoute path={`/helpers`} exact>
                  <VaccinesQRGenerator />
                </PrivateRoute>
                <PrivateRoute path={`/practices`} exact>
                  <PracticesScreen />
                </PrivateRoute>
                <PrivateRoute path={`/tickets`} exact>
                  <TicketsScreen />
                </PrivateRoute>
                {/* This route may be used in the future */}
                {/* <PrivateRoute path={`/announcements`} exact>
                  <AnnouncementsScreen />
                </PrivateRoute> */}

                <PrivateRoute
                  path={[
                    `/billing/control/practice/:practiceId/report`,
                    `/billing/general/practice/:practiceId/report`,
                    `/billing2/control/practice/:practiceId/report`,
                    `/billing2/general/practice/:practiceId/report`,
                  ]}
                  exact
                >
                  <BillingReportScreen />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `/billing/practice-patient/:id`,
                    `/billing/practice-patient/:id/claim/:claimId`,
                  ]}
                  exact
                >
                  <PatientsClaims />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `/billing/control/borrowing`,
                    `/billing/general/borrowing`,
                    `/billing2/control/borrowing`,
                    `/billing2/general/borrowing`,
                  ]}
                  exact
                >
                  <InconsistencyList />
                </PrivateRoute>
                <PrivateRoute path={`/solvePendingBorrows`} exact>
                  <ScrollToTop />
                  <SolvePendingBorrowsScreen />
                </PrivateRoute>
                <PrivateRoute path={`/solvePossibleBorrowingCases`} exact>
                  <ScrollToTop />
                  <BorrowingRequestSolvingScreen />
                </PrivateRoute>
                <PrivateRoute path={`/billing/reporting`} exact>
                  <ReportingScreen />
                </PrivateRoute>
                <PrivateRoute path={`/billing/general`} exact>
                  <GeneralScreen />
                </PrivateRoute>

                <PrivateRoute path={`/billing2/control`} exact>
                  <Control2Screen />
                </PrivateRoute>
                <PrivateRoute path={`/billing2/reporting`} exact>
                  <Reporting2Screen />
                </PrivateRoute>
                <PrivateRoute path={`/billing2/general`} exact>
                  <General2Screen />
                </PrivateRoute>

                <PrivateRoute path={`/billing`} exact>
                  <BillingScreen />
                </PrivateRoute>
                <PrivateRoute path={'/billing/balance-movements'} exact>
                  <BalanceMovementsScreen />
                </PrivateRoute>
                <PrivateRoute path={`/billing2`} exact>
                  <Billing2Screen />
                </PrivateRoute>
                <PrivateRoute path={`/new-billing`} exact>
                  <NewBillingScreen />
                </PrivateRoute>
                <PrivateRoute path={`/new-billing/invoice`} exact>
                  <ScrollToTop />
                  <NewBillingScreen />
                </PrivateRoute>
                <PrivateRoute path={`/new-billing/invoices`} exact>
                  <ScrollToTop />
                  <NewInvoicesListScreen />
                </PrivateRoute>
                <PrivateRoute path={`/iis-errors`} exact>
                  <IisErrorsScreen />
                </PrivateRoute>
                <PrivateRoute path={`/incomplete-patients`} exact>
                  <IncompletePatientsScreen />
                </PrivateRoute>
                <PrivateRoute path={`/integration-athena`} exact>
                  <AthenaIntegrationScreen />
                </PrivateRoute>
                <PrivateRoute path={`/integration-ecw`} exact>
                  <EcwIntegrationScreen />
                </PrivateRoute>
                <PrivateRoute path={`/integration-develo`} exact>
                  <DeveloIntegrationsScreen />
                </PrivateRoute>
                <PrivateRoute path={`/integration-ecw/bot-reports`} exact>
                  <BotReportsScreen />
                </PrivateRoute>

                {/* -----------------------ECW MAPPINGS----------------------- */}
                <PrivateRoute path={`/integration-ecw/vaccine-mapping`} exact>
                  <EcwVaccineMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-ecw/vfc-eligibility-mapping`}
                  exact
                >
                  <EcwVfcEligibilityMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-ecw/administration-route-mapping`}
                  exact
                >
                  <EcwAdministrationRouteMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-ecw/administration-site-mapping`}
                  exact
                >
                  <EcwAdministrationSiteMapping />
                </PrivateRoute>
                <PrivateRoute path={`/integration-ecw/given-by-mapping`} exact>
                  <EcwAdministratedByMapping />
                </PrivateRoute>

                <PrivateRoute path={`/integration-ecw/appointments`} exact>
                  <EcwAppointmentsList />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-ecw/historical-vaccinations`}
                  exact
                >
                  <EcwHistoricalVaccinationsList />
                </PrivateRoute>
                {/* ---------------------------------------------------------- */}

                <PrivateRoute path={`/integration-athena/subscriptions`} exact>
                  <AthenaSubscriptions />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-athena/outbound-actions`}
                  exact
                >
                  <AthenaOutboundActions />
                </PrivateRoute>

                {/* -----------------------ATHENA MAPPINGS----------------------- */}

                <PrivateRoute
                  path={`/integration-athena/provider-mapping`}
                  exact
                >
                  <AthenaProviderMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-athena/vaccine-search-criteria-mapping`}
                  exact
                >
                  <AthenaVaccineSearchCriteriaMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-athena/insurance-company-mapping`}
                  exact
                >
                  <AthenaInsuranceCompanyMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-athena/vfc-criteria-mapping`}
                  exact
                >
                  <AthenaVfcCriteriaMapping />
                </PrivateRoute>

                {/* -----------------------DEVELO MAPPINGS----------------------- */}

                <PrivateRoute
                  path={`/integration-develo/insurance-company-mapping`}
                  exact
                >
                  <DeveloInsuranceCompanyMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-develo/prescriber-mapping`}
                  exact
                >
                  <DeveloPrescriberMapping />
                </PrivateRoute>
                <PrivateRoute
                  path={`/integration-develo/provider-mapping`}
                  exact
                >
                  <DeveloProviderMapping />
                </PrivateRoute>

                <PrivateRoute
                  path={`/integration-develo/vfc-criteria-mapping`}
                  exact
                >
                  <DeveloVfcCriteriaMapping />
                </PrivateRoute>

                <PrivateRoute path={`/integration-develo/appointments`} exact>
                  <DeveloAppointments />
                </PrivateRoute>

                <PrivateRoute
                  path={`/integration-develo/vaccinations-to-report`}
                  exact
                >
                  <VaccinationsToReport />
                </PrivateRoute>

                {/* ------------------------------------------------------------- */}

                <PrivateRoute
                  path={[
                    `/billing/control/analytics`,
                    `/billing/general/analytics`,
                    `/billing2/control/analytics`,
                    `/billing2/general/analytics`,
                  ]}
                  exact
                >
                  <AnalyticsScreen />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `/billing/control/vaccines-price-list`,
                    `/billing/general/vaccines-price-list`,
                    `/billing2/control/vaccines-price-list`,
                    `/billing2/general/vaccines-price-list`,
                  ]}
                  exact
                >
                  <VaccinesPriceListScreen />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `/billing/control/review`,
                    `/billing/general/review`,
                    `/billing2/control/review`,
                    `/billing2/general/review`,
                  ]}
                >
                  <VaccinationsReviewScreen />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `/billing/control/self-pay-list`,
                    `/billing/general/self-pay-list`,
                    `/billing2/control/self-pay-list`,
                    `/billing2/general/self-pay-list`,
                  ]}
                >
                  <SelfPayListScreen />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `/billing/control/invoices`,
                    `/billing/general/invoices`,
                    `/billing2/control/invoices`,
                    `/billing2/general/invoices`,
                  ]}
                  exact
                >
                  <InvoicesListScreen />
                </PrivateRoute>

                <PrivateRoute path={[`/billing`, `/billing/:claimId`]} exact>
                  <BillingScreen />
                </PrivateRoute>
                <PrivateRoute
                  path={[`/billing`, `/billing/general/:claimId`]}
                  exact
                >
                  <GeneralScreen />
                </PrivateRoute>
                <PrivateRoute
                  path={[`/billing`, `/billing/reporting/:claimId`]}
                  exact
                >
                  <ReportingScreen />
                </PrivateRoute>

                <PrivateRoute path={[`/billing2`, `/billing2/:claimId`]} exact>
                  <Billing2Screen />
                </PrivateRoute>
                <PrivateRoute
                  path={[`/billing2`, `/billing2/control/:claimId`]}
                  exact
                >
                  <Control2Screen />
                </PrivateRoute>
                <PrivateRoute
                  path={[`/billing2`, `/billing2/general/:claimId`]}
                  exact
                >
                  <General2Screen />
                </PrivateRoute>
                <PrivateRoute
                  path={[`/billing2`, `/billing2/reporting/:claimId`]}
                  exact
                >
                  <Reporting2Screen />
                </PrivateRoute>
                <PrivateRoute
                  path="/practices/:handler"
                  render={({ match }) => {
                    const { handler } = match.params;
                    return (
                      <PracticeContextProvider handler={handler!}>
                        <Switch>
                          <Route
                            path={`/practices/${handler}/patients/:patientId?`}
                          >
                            <PatientsScreen />
                            <Route
                              path={`/practices/${handler}/patients/:practicePatientId/insurances`}
                            >
                              <CustomModal modalHeader="Insurance List">
                                <InsurancesList />
                              </CustomModal>
                            </Route>
                            <Route
                              exact
                              path={`/practices/${handler}/patients/:practicePatientId/insurances/:insuranceId`}
                            >
                              <InsurancesForm />
                            </Route>
                            <Route
                              exact
                              path={`/practices/${handler}/patients/:practicePatientId/insurances/_new`}
                            >
                              <InsurancesForm />
                            </Route>
                          </Route>
                          <Route
                            path={`/practices/${handler}/inventory/thresholds`}
                          >
                            <InventoryThresholds />
                          </Route>
                          <Route
                            path={`/practices/${handler}/inventory/compare`}
                          >
                            <CompareInventoryScreen />
                          </Route>
                          <Route
                            path={`/practices/${handler}/inventory/adjustments`}
                          >
                            <InventoryAdjustment />
                          </Route>
                          <Route
                            path={`/practices/${handler}/inventory/contract-plans`}
                          >
                            <InventoryContractPlans />
                          </Route>
                          <PrivateRoute
                            path={`/practices/${handler}/inventory/control`}
                          >
                            <InventoryControl />
                          </PrivateRoute>

                          <Route
                            path={`/practices/${handler}/inventory/:inventoryId?`}
                          >
                            <InventoryScreen />
                          </Route>

                          <Route path={`/practices/${handler}/claims`}>
                            <ClaimsScreen />
                          </Route>

                          <Route path={`/practices/${handler}/users`}>
                            <UsersScreen />
                          </Route>

                          <Route
                            path={`/practices/${handler}/inconsistentVaccinationsHistory`}
                          >
                            <InconsistentVaccinationsHistoryScreen />
                          </Route>

                          <Route
                            path={[
                              `/practices/${handler}/claims-to-upload-to-emr`,
                              `/practices/${handler}/claims-to-upload-to-emr/:claimId`,
                            ]}
                            exact
                          >
                            <ClaimsToUploadToEmrScreen />
                          </Route>

                          <Route path={`/practices/${handler}/insurances`}>
                            <InsurancesScreen />
                          </Route>

                          <Route
                            path={`/practices/${handler}/inventory-orders`}
                          >
                            <InventoryOrdersScreen />
                          </Route>
                          <Route path={`/practices/${handler}/mappings`}>
                            <MappingsScreen />
                          </Route>
                          <Route path={`/practices/${handler}/vax-sync`}>
                            <VaxSyncScreen />
                          </Route>
                          <Route
                            path={`/practices/${handler}/updateVaccinationsCsv`}
                          >
                            <UpdateVaccinesCsv />
                          </Route>
                          <Route
                            path={`/practices/${handler}/updateVaccinationsCsvTable/:jobId`}
                          >
                            <UpdateVaccinesCsvTableJobDetails />
                          </Route>
                          <Route
                            path={`/practices/${handler}/updateVaccinationsCsvTable`}
                          >
                            <UpdateVaccinesCsvTable />
                          </Route>
                          <Route
                            path={`/practices/${handler}/borrowing-summary/detail/:id`}
                          >
                            <BorrowingSummaryDetailClaims />
                          </Route>
                          <Route
                            path={`/practices/${handler}/borrowing-summary/:date`}
                          >
                            <BorrowingSummaryDetails />
                          </Route>
                          <Route
                            path={`/practices/${handler}/borrowing-summary`}
                          >
                            <BorrowingSummary />
                          </Route>

                          <Route path={`/practices/${handler}/creditCards`}>
                            <CreditCardsScreen />
                          </Route>

                          <Route path={`/practices/${handler}`}>
                            <ScrollToTop />
                            <PracticeProfileScreen />
                          </Route>
                        </Switch>
                      </PracticeContextProvider>
                    );
                  }}
                />
                <PrivateRoute path={`/vaccines`} exact>
                  <VaccinesScreen />
                </PrivateRoute>
                <PrivateRoute path={`/vaccines/new`} exact>
                  <CustomModal modalHeader="New Vaccines">
                    <VaccineNew />
                  </CustomModal>
                </PrivateRoute>
                <PrivateRoute path={`/vaccines/pricing`} exact>
                  <VaccinesPriceList />
                </PrivateRoute>
                <PrivateRoute path={`/vaccines/contract-plans`} exact>
                  <ContractPlansList />
                </PrivateRoute>
                <PrivateRoute path={`/vaccines/:id`} exact>
                  <CustomModal modalHeader="Vaccine">
                    <Vaccine />
                  </CustomModal>
                </PrivateRoute>
              </Switch>
              <PrivateRoute path={`/vaccines/:id/edit`} exact>
                <CustomModal modalHeader="Edit Vaccine">
                  <VaccineEdit />
                </CustomModal>
              </PrivateRoute>
              <PrivateRoute path={`/organizations`} exact>
                <ScrollToTop />
                <OrganizationsScreen />
              </PrivateRoute>
              <PrivateRoute path={`/notifications`} exact>
                <NotificationList />
              </PrivateRoute>
              <SemanticToastContainer position="top-left" />
            </AuthProvider>
          </ApolloProvider>
        </ApplicationStateProvider>
      </LocalStorageProvider>
    </Router>
  );
}

interface PrivateRouteProps extends RouteProps {
  adminOnly?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  adminOnly = false,
  ...rest
}) => {
  const { token } = useApplicationState();
  return (
    <ConditionalRoute
      predicate={() => !!token}
      fallbackTo={`/sign-in`}
      {...rest}
    />
  );
};

export default App;
