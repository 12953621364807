import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { Vaccine } from '@bluefox/models/Vaccine';
import { MappedVaccine } from '@bluefox/models/Mappings';
import {
  InsertVaccineMappingMutation,
  UpdateVaccineMappingMutation,
  GetMappedVaccines,
} from '@graphql/mappings';
import { Button, Form, Header, Label } from 'semantic-ui-react';
import VaccinePicker from '@bluefox/ui/VaccinePicker';

interface VaccineMappingProps {
  close: () => void;
  practiceId?: string;
  data: MappedVaccine | null;
  refetchMappings: () => void;
}

interface VaccineData {
  nameEmr: string;
  vaccine: {
    id: number;
    cvx: number;
    name: string;
    types: string[];
    manufacturer: string;
    saleNdc: string;
  };
}

interface VaccineCount {
  vaccines: VaccineData | undefined;
  count: number;
  nameEmr: string;
}

type Value = Record<string, number>;

const VaccineMapping = ({
  close,
  practiceId,
  data,
  refetchMappings,
}: VaccineMappingProps) => {
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();
  const [vaccineEmr, setVaccineEmr] = useState<string>(data?.nameEmr || '');
  const [vaccineOptions, setVaccineOptions] = useState<
    VaccineCount[] | undefined
  >([]);

  const { data: vaccinesData, refetch } = useQuery(GetMappedVaccines);

  const [saveVaccineMapping] = useMutation(
    data?.id ? UpdateVaccineMappingMutation : InsertVaccineMappingMutation
  );

  const handleSubmit = () => {
    saveVaccineMapping({
      variables: {
        id: data?.id,
        practiceId,
        vaccineId: selectedVaccine?.id,
        nameEmr: vaccineEmr,
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetch();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!vaccinesData) return;

    const vaccines = vaccinesData?.vaccines?.filter(
      (e: VaccineData) =>
        e.nameEmr.toLowerCase() === data?.nameEmr.toLowerCase() &&
        e.vaccine !== null
    );

    const objectCount = vaccines?.reduce(
      (value: Value, current: VaccineData) => {
        value[`${current?.nameEmr?.toLowerCase()}_${current?.vaccine?.id}`] =
          value[`${current?.nameEmr?.toLowerCase()}_${current?.vaccine?.id}`]
            ? value[
                `${current?.nameEmr?.toLowerCase()}_${current?.vaccine?.id}`
              ] + 1
            : 1;
        return value;
      },
      {}
    );

    setVaccineOptions(
      Object.keys(objectCount)
        .map((vaccine: string) => {
          return {
            vaccines: vaccinesData?.vaccines?.find((e: VaccineData) => {
              return (
                (e.vaccine?.id as unknown as String) === vaccine?.split('_')[1]
              );
            }),

            count: objectCount[vaccine],
            nameEmr: vaccinesData?.vaccines
              ?.find(
                (e: VaccineData) =>
                  (e.vaccine?.id as unknown as String) ===
                  vaccine?.split('_')[1]
              )
              ?.nameEmr.toLowerCase(),
          };
        })
        .sort((a, b) => b.count - a.count)
    );
  }, [data?.nameEmr, vaccinesData]);

  return (
    <Form onSubmit={handleSubmit}>
      {!data?.vaccine?.id && data?.nameEmr && (
        <Form.Group>
          <Form.Field>
            <Label>Most Used Vaccines</Label>
          </Form.Field>
          <Form.Field
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
          >
            {vaccineOptions?.map((vaccine: VaccineCount, i: number) => {
              return (
                <Form.Field key={i}>
                  <Header as="h4">
                    <Header.Content>
                      {vaccine?.vaccines?.vaccine?.name} ({vaccine?.count}{' '}
                      time/s used)
                      {vaccine?.vaccines?.vaccine?.types?.map((t: string) => (
                        <Label key={t} color="orange" size="mini">
                          {t}
                        </Label>
                      ))}
                    </Header.Content>
                    <Header.Subheader>
                      CVX: {vaccine?.vaccines?.vaccine?.cvx} -{' '}
                      {vaccine?.vaccines?.vaccine?.manufacturer} -{' '}
                      {vaccine?.vaccines?.vaccine?.saleNdc}
                    </Header.Subheader>
                  </Header>
                </Form.Field>
              );
            })}
          </Form.Field>
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            <b>Vaccine (Canid)</b>
          </label>
          <VaccinePicker
            dropdownProps={{
              placeholder: 'Search vaccine...',
              options: [],
            }}
            fallback
            value={data?.vaccine?.id ?? selectedVaccine?.id}
            onChange={({ vaccine }) => {
              setSelectedVaccine(vaccine);
            }}
          />
        </Form.Field>
        <Form.Field required>
          <Form.Input
            value={vaccineEmr}
            onChange={(_, { value }) => setVaccineEmr(value)}
            fluid
            label="Vaccine (EMR)"
            placeholder="Vaccine (EMR)"
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          disabled={!vaccineEmr}
        />
      </Form.Field>
    </Form>
  );
};

export default VaccineMapping;
