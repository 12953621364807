import { PackageTypes } from '@bluefox/models/Vaccine';

import { AdministrationCptCodes, Dosage } from '@bluefox/models/Vaccine';

export function formatVaccineTypes(types: string[] | undefined) {
  return types?.join(', ');
}

export function formatRoutes(routes: string[] | undefined) {
  return routes?.join(', ');
}

export function formatVaccineInventoryCptCodes(
  inventoryCptCodes: string[] | undefined
) {
  return inventoryCptCodes?.join(', ');
}

export function formatDosages(dosages?: Dosage[] | undefined) {
  return dosages?.map((dosage) => `${dosage?.dose} ${dosage?.unit}`).join(', ');
}

export function formatAdministrationCptCodes(
  administrationCptCodes: AdministrationCptCodes | undefined
) {
  return administrationCptCodes?.default?.join(', ');
}

export const formatPackage = (
  type: string | undefined,
  doses: number | undefined
) => {
  let _package = '';

  switch (type?.toLowerCase()) {
    case PackageTypes.SYRINGES:
      _package = `${doses} x 1 Dose Syringe`;
      break;
    case PackageTypes.VIALS:
      _package = `${doses} x 1 Dose Vials`;
      break;
    case PackageTypes.TUBE:
      _package = `${doses} x 1 Dose Tube`;
      break;
  }

  return _package;
};
