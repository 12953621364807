import { VaccinationRoutes } from './Vaccination';

export type AdministrationCptCodes = {
  default: string[];
  prior: string[];
  post: string[];
  [dose: string]: string[];
};

export type Dosage = {
  dose: number;
  measure: string;
  unit: string;
};
export interface Vaccine {
  id: string;
  saleNdc?: string;
  useNdc?: string[];
  saleNdc10?: string;
  useNdc10?: string[];
  types?: string[];
  name: string;
  type: string;
  aka: string;
  manufacturer: string;
  recurrent: boolean;
  inventoryCptCodes?: string[];
  administrationCptCodesWd?: AdministrationCptCodes;
  administrationCptCodesWod?: AdministrationCptCodes;
  routes?: VaccinationRoutes[];
  cvx: string;
  mvx: string;
  selfPayPrice?: number;
  dosage?: Dosage[];
  stock?: number;
  fallback?: boolean;
  visPublications?: VisPublications[];
  packageType?: string;
  packageDoses?: number;
  federalExciseTaxes: number;
  vaccinePricings: VaccinePricing[];
  cvxGroup?: string;
  allowedAssociatedVaccinations?: number;
  active?: boolean;
}

export interface VisPublications {
  publicationDate: Date;
  cvx?: string[];
}
export interface InventoryOrderVaccine {
  id: string;
  saleNdc: string;
  name: string;
  manufacturer: string;
}

export interface VaccineSelfPayPrice {
  id: string;
  name: string;
  manufacturer: string;
  saleNdc: string;
  selfPayPrice: number;
  types: string[];
  inventoryCptCodes: string[];
}

export interface PracticeContractPlan {
  id: string;
  startDate: string;
  endDate: string;
  contractPlan: ContractPlan;
}

export interface ContractPlanDiscount {
  type: string;
  reason: string;
  discount: number;
}

export interface ContractPlan {
  id: string;
  name: string;
  type: string;
  discounts: ContractPlanDiscount[];
  vaccinePricings: VaccinePricing[];
}

export interface VaccinePricing {
  id: string;
  date: Date;
  doses: number;
  packageType: string;
  price: number;
  priceCents: number;
  pricePerDose: number;
  priceCentsPerDose: number;
  vaccine: Vaccine;
  contractPlan?: ContractPlan;
}

export enum Manufacturer {
  GSK = 'GSK Vaccines GmbH',
  MCKESSON = 'McKesson',
  MERCK = 'Merck Sharp & Dohme Corp.',
  PFIZER = 'Pfizer Laboratories Div Pfizer Inc',
  BIONTECH = 'BioNTech',
  NOVARTIS = 'Novartis Vaccines and Diagnostics',
  VALNEVA = 'Valneva Scotland Ltd.',
  EMERGENT = 'Emergent Travel Health Inc.',
  DYNAVAX = 'Dynavax Technologies Corporation',
  SEQIRUS = 'Seqirus',
  NOVAVAX = 'Novavax',
  BIOCSL = 'bioCSL Pty Ltd.',
  A_S_MEDICATION = 'A-S Medication Solutions',
  MEDIMMUNE = 'MedImmune, LLC',
  JANSSEN = 'Janssen Products, LP',
  VBI = 'VBI Vaccines (Delaware) Inc.',
  PHYSICIANS_TOTAL_CARE = 'PHYSICIANS TOTAL CARE, INC',
}

export enum PackageTypes {
  SYRINGES = 'syringes',
  VIALS = 'vials',
  TUBE = 'tube',
}

export const manufacturerOptions = [
  {
    key: 'asMedication',
    text: 'A-S Medication Solutions',
    value: 'asMedication',
  },
  {
    key: 'biocsl',
    text: 'bioCSL Pty Ltd.',
    value: 'biocsl',
  },
  {
    key: 'bioNTech',
    text: 'BioNTech',
    value: 'bioNTech',
  },
  {
    key: 'dynavax',
    text: 'Dynavax Technologies Corporation',
    value: 'dynavax',
  },
  {
    key: 'emergent',
    text: 'Emergent Travel Health Inc.',
    value: 'emergent',
  },
  {
    key: 'gsk',
    text: 'GSK Vaccines GmbH',
    value: 'gsk',
  },
  {
    key: 'janssen',
    text: 'Janssen Products, LP',
    value: 'janssen',
  },
  {
    key: 'mcKesson',
    text: 'McKesson',
    value: 'mcKesson',
  },
  {
    key: 'medImmune',
    text: 'MedImmune, LLC',
    value: 'medImmune',
  },
  {
    key: 'merck',
    text: 'Merck Sharp & Dohme Corp.',
    value: 'merck',
  },

  {
    key: 'novartis',
    text: 'Novartis Vaccines and Diagnostics',
    value: 'novartis',
  },
  {
    key: 'novavax',
    text: 'Novavax',
    value: 'novavax',
  },
  {
    key: 'physiciansTotalCare',
    text: 'PHYSICIANS TOTAL CARE, INC',
    value: 'physiciansTotalCare',
  },
  {
    key: 'seqirus',
    text: 'Seqirus',
    value: 'seqirus',
  },
  {
    key: 'valneva',
    text: 'Valneva Scotland Ltd.',
    value: 'valenva',
  },
  {
    key: 'vbi',
    text: 'VBI Vaccines (Delaware) Inc.',
    value: 'vbi',
  },
];
export interface VisPublication {
  id: string;
  vaccineTypes: string[];
  conceptName: string;
  publicationDate: Date | undefined;
  stringEncoded: string;
  conceptCode: string;
  status: 'current' | 'historic';
  cvx: string[];
}
