import React, {
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-semantic-toasts';
import { Button } from 'semantic-ui-react';

export interface GunScannerProps {
  component: React.ElementType;
  onScan?: (value: string) => void;
  renderOn: ReactNode;
  renderOff: ReactNode;
  renderReading?: ReactNode;
  onScanningChange?: (scanning: boolean) => void;
  isScanning?: boolean;
  testValue?: string;
  [k: string]: any;
}

const GunScanner = ({
  component: Component,
  renderOn,
  renderOff,
  renderReading,
  onScan,
  onScanningChange: onChange = () => {},
  isScanning = false,
  testValue,
  ...rest
}: GunScannerProps) => {
  const [scanning, setScanning] = useState(isScanning);
  const [reading, setReading] = useState(false);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (!scanning) {
      return;
    }

    setReading(true);

    const key = e.key;
    if (key === 'F8') {
      e.currentTarget.value += '|';
    }

    if (key === 'Enter' || (key === '\\' && e.currentTarget.value !== '')) {
      if (onScan) onScan(e.currentTarget.value);
      setReading(false);
      e.currentTarget.value = '';
    }
  };

  const handleOnClick = useCallback(
    (e) => {
      // hacky way to differenciate between click and Enter, this is Enter
      if (e.clientX !== 0 && e.clientY !== 0) {
        setReading(false);
        setScanning(!scanning);
      }
    },
    [scanning, onScan]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (e.relatedTarget?.id === '__scanButton') return;
      if (scanning) {
        setReading(false);
        setScanning(false);
        toast({
          type: 'warning',
          title: 'Scanning has stopped.',
          description:
            'The scanning process has stopped, click Scan button to resume.',
        });
      }
    },
    [scanning, setScanning]
  );

  useEffect(() => onChange(scanning), [onChange, scanning]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (scanning) inputRef.current?.focus();
  }, [scanning]);

  useEffect(() => {
    setScanning(isScanning);
  }, [isScanning]);

  // useEffect just for automatization testing purposes
  useEffect(() => {
    if (!testValue) return;
    if (onScan) onScan(testValue as string);
  }, [testValue]);

  return (
    <>
      <Component
        {...rest}
        id="__scanButton"
        as={Button}
        onClick={handleOnClick}
        content={
          scanning
            ? !!renderReading && reading
              ? renderReading
              : renderOn
            : renderOff
        }
      />
      <input
        ref={inputRef}
        // onKeyPress={handleKeyDown}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        type="text"
        style={{
          height: 0,
          padding: 0,
          margin: 0,
          border: 0,
          position: 'absolute',
        }}
      />
    </>
  );
};

export default GunScanner;
