import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Modal } from 'semantic-ui-react';

import PatientForm from '@bluefox/ui/PatientForm';

import { PracticePatientByIdQuery } from '@bluefox/graphql/patients';
import { PracticePatient } from '@bluefox/models/Patient';
import { PatientDataProvider } from '@bluefox/contexts/PatientData';
import { PracticePatientProvider } from '@bluefox/contexts/PracticePatientProvider';
interface RouterParamsType {
  practicePatientId?: string;
}

interface PracticePatientData {
  practicePatient: PracticePatient;
}

interface PatientFormModalProps {
  onSave?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  practPatientId?: string;
  handler?: string;
  goBackOnClose?: boolean;
  goBackPathSlices?: number;
}

const PatientFormModal = ({
  onSave,
  onCancel,
  onClose,
  practPatientId,
  handler,
  goBackOnClose = true,
  goBackPathSlices = -1,
}: PatientFormModalProps) => {
  const history = useHistory();
  const auxPacticePortalId = useParams<RouterParamsType>().practicePatientId;
  const practicePatientId = practPatientId || auxPacticePortalId;
  const currentRouteArr = history.location.pathname.split('/');
  const goBackRouteArr = currentRouteArr.slice(0, goBackPathSlices);

  const { data } = useQuery<PracticePatientData>(
    PracticePatientByIdQuery(true),
    {
      variables: {
        id: practicePatientId,
      },
      skip: !practicePatientId,
    }
  );

  const [open, setOpen] = useState(!!practicePatientId);

  useEffect(() => {
    setOpen(!!practicePatientId);
  }, [practicePatientId]);

  const handleSubmit = () => {
    cleanAndClose();
    if (onSave) onSave();
  };

  const cleanAndClose = () => {
    setOpen(false);
    if (onClose) onClose();
    if (practPatientId) {
      return;
    }

    if (goBackOnClose) {
      history.push(`${goBackRouteArr.join('/')}`);
    }
  };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={cleanAndClose}
      closeIcon
      size="large"
    >
      <Modal.Content>
        <Modal.Description>
          {data ? (
            <PracticePatientProvider practicePatient={data.practicePatient}>
              <PatientDataProvider
                patientData={data.practicePatient.patientData}
              >
                {
                  <PatientForm
                    internalTools
                    practPatientId={practicePatientId}
                    onSave={handleSubmit}
                    leftActions={[
                      {
                        'data-automation-id': 'patient-basic-cancel',
                        type: 'button',
                        content: 'Cancel',
                        icon: 'cancel',
                        name: 'cancel',
                        onClick: () => cleanAndClose(),
                      },
                    ]}
                    rightActions={[
                      {
                        'data-automation-id': 'patient-basic-submit',
                        primary: true,
                        type: 'submit',
                        content: 'Save',
                        icon: 'save',
                        name: 'save',
                      },
                    ]}
                  />
                }
              </PatientDataProvider>
            </PracticePatientProvider>
          ) : (
            <PatientForm
              internalTools
              onSave={handleSubmit}
              leftActions={[
                {
                  'data-automation-id': 'patient-basic-cancel',
                  type: 'button',
                  content: 'Cancel',
                  icon: 'cancel',
                  name: 'cancel',
                  onClick: () => {
                    cleanAndClose();
                    if (onCancel) onCancel();
                  },
                },
              ]}
              rightActions={[
                {
                  'data-automation-id': 'patient-basic-submit',
                  primary: true,
                  type: 'submit',
                  content: 'Save',
                  icon: 'save',
                  name: 'save',
                },
              ]}
              practPatientId={practPatientId}
              handler={handler}
            />
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default PatientFormModal;
