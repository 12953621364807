import { useQuery, useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { usePatientData } from '@bluefox/contexts/PatientData';
import { PracticePatientProfileQuery } from '@bluefox/graphql/patients';
import {
  InsurancesQuery,
  PrimaryInsuranceQuery,
  SetPrimaryInsuranceMutation,
} from '@bluefox/graphql/insurances';
import { Insurance } from '@bluefox/models/Insurances';
import {
  Button,
  Icon,
  Label,
  Container,
  Table,
  Segment,
  Message,
} from 'semantic-ui-react';
import LoadingBox from '@bluefox/ui/LoadingBox';
import InsurancesForm from './InsurancesForm';
import React, { useEffect, useState } from 'react';
import { useSession } from '@bluefox/contexts';
import { isVfcUninsured } from '@bluefox/lib/validations/isVfcUninsured';

interface InsurancesData {
  insurancesData: {
    insurances: Insurance[];
  };
}

interface PatientIdType {
  patientId?: string;
  practicePatientId?: string;
}

export interface InsuranceListProp {
  propPatientId?: string;
  propPracticePatientId?: string;
  propPracticeId?: string;
}

const InsurancesList = ({
  propPatientId,
  propPracticePatientId,
  propPracticeId,
}: InsuranceListProp) => {
  const session = useSession();
  const history = useHistory();
  const patientId = useParams<PatientIdType>().patientId
    ? useParams<PatientIdType>().patientId
    : propPatientId;

  const practicePatientId = useParams<PatientIdType>().practicePatientId
    ? useParams<PatientIdType>().practicePatientId
    : propPracticePatientId;
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<
    string | undefined
  >(undefined);

  const patientData = usePatientData();
  const pPatientId =
    patientData?.practicePatientId ?? patientId ?? practicePatientId;

  const { data, loading, refetch } = useQuery<InsurancesData>(InsurancesQuery, {
    variables: {
      practicePatientId: pPatientId,
    },
  });

  const [setPrimaryInsurance, { loading: loadingSetPrimary }] = useMutation(
    SetPrimaryInsuranceMutation
  );

  if (loading) {
    return <LoadingBox />;
  }

  const setAsPrimary = (insuranceId: string) => {
    setPrimaryInsurance({
      variables: {
        practicePatientId: pPatientId,
        primaryInsuranceId: insuranceId,
      },
      refetchQueries: [
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: pPatientId,
          },
        },
        {
          query: PrimaryInsuranceQuery,
          variables: {
            practicePatientId: pPatientId,
          },
        },
      ],
    })
      .then((r) => {
        toast({
          title: 'Primary insurance set succesfully',
          type: 'success',
          time: 1000,
        });
        refetch();
      })
      .catch((e) => {
        toast({
          title: 'There was an error setting primary insurance',
          description: e,
          type: 'error',
          time: 5000,
        });
      });
  };

  const insurances = data ? data.insurancesData.insurances : [];

  const onAddInsuranceButtonClick = () => {
    setSelectedInsuranceId('_new');
  };

  const selectAddActionButton = () => {
    if (propPracticePatientId) {
      return (
        <Button
          icon="plus"
          content="Add Insurance"
          primary
          onClick={onAddInsuranceButtonClick}
        />
      );
    }
    return (
      <Button
        as={Link}
        icon="plus"
        content="Add Insurance"
        primary
        to={`${history.location.pathname}/_new`}
      />
    );
  };

  const selectEditActionButton = (insuranceId?: string) => {
    if (propPracticePatientId) {
      return (
        <Button
          icon="edit"
          content="Edit"
          disabled={loadingSetPrimary}
          onClick={() => setSelectedInsuranceId(insuranceId)}
        />
      );
    }
    return (
      <Button
        as={Link}
        to={`${history.location.pathname}/${insuranceId}`}
        icon="edit"
        content="Edit"
        disabled={loadingSetPrimary}
      />
    );
  };

  const onFormSaveHandler = () => {
    setSelectedInsuranceId(undefined);
    refetch();
  };

  const onModalCloseHandler = () => {
    setSelectedInsuranceId(undefined);
  };

  return (
    <Container fluid>
      <StyledSegment>{selectAddActionButton()}</StyledSegment>
      <StyledTableContent>
        {!!insurances.length ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}></Table.HeaderCell>
                <Table.HeaderCell width={4}>Company Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>Member ID</Table.HeaderCell>
                <Table.HeaderCell>Holder Relationship</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width={2}>Active</Table.HeaderCell>
                <Table.HeaderCell width={2}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {insurances.map((insurance) => {
                return (
                  <Table.Row key={insurance.id}>
                    <StyledPrimaryCell>
                      {insurance.primary && (
                        <StyledPrimaryLabel color="teal">
                          <Icon name="star" />
                          Primary
                        </StyledPrimaryLabel>
                      )}
                      {!insurance.primary && insurance.active && (
                        <StyledButton
                          loading={loadingSetPrimary}
                          color="teal"
                          basic
                          onClick={() => setAsPrimary(insurance.id)}
                        >
                          <Icon name="star" />
                          <span>Set as primary</span>
                        </StyledButton>
                      )}
                    </StyledPrimaryCell>
                    <Table.Cell>
                      {isVfcUninsured(insurance) ? '' : insurance.company?.name}
                    </Table.Cell>
                    <Table.Cell>
                      {isVfcUninsured(insurance) ? '' : insurance.memberId}
                    </Table.Cell>
                    <Table.Cell>
                      {isVfcUninsured(insurance) ? '' : insurance.relationship}
                    </Table.Cell>
                    <Table.Cell>
                      {insurance.vfcEligible && (
                        <Label color="orange">VFC</Label>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {insurance.active && (
                        <Label color="olive" basic>
                          <Icon name="check" />
                          Active
                        </Label>
                      )}
                      {!insurance.active && (
                        <Label color="grey" basic>
                          Inactive
                        </Label>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {selectEditActionButton(insurance.id)}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        ) : (
          <Message>No insurances found</Message>
        )}
      </StyledTableContent>

      <InsurancesForm
        onSave={onFormSaveHandler}
        onModalClose={onModalCloseHandler}
        propPracticePatientId={practicePatientId}
        propPracticeId={propPracticeId}
        propInsuranceId={selectedInsuranceId}
      />
    </Container>
  );
};

export default InsurancesList;

const StyledSegment = styled(Segment)`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;
const StyledTableContent = styled.div`
  padding: 0 1rem 1rem 0;
`;
const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0.5rem !important;
  span {
    display: block;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 0.4rem;
    width: 8rem;
  }
`;
const StyledPrimaryCell = styled(Table.Cell)`
  display: flex;
`;
const StyledPrimaryLabel = styled(Label)`
  margin: auto !important;
`;
