import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { toast } from 'react-semantic-toasts';
import { Card, Button, Input, Popup, Icon } from 'semantic-ui-react';
import { DateFormats } from '@bluefox/models/Dates';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';

interface InfoBoxProps {
  firstName: string;
  lastName: string;
  memberId: string;
  birthdate: string;
  serviceDate: Date;
  claimControlnumber: string;
  providerName: string;
  taxId: string;
}

const InfoBox = ({
  firstName,
  lastName,
  memberId,
  birthdate,
  serviceDate,
  claimControlnumber,
  providerName,
  taxId,
}: InfoBoxProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCopyPass = (e: any) => {
    const input =
      e.target.nodeName === 'I'
        ? e.target.parentElement.parentElement.firstChild.value
        : e.target.parentElement.firstChild.value;
    navigator.clipboard.writeText(input);

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  return (
    <Popup
      on="click"
      pinned
      position="left center"
      content={
        <Card>
          <Card.Content>
            <Card.Header
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              Useful Information
              <Button basic size="mini" icon="close" onClick={handleClose} />
            </Card.Header>
            <Card.Description style={{ height: '25rem', overflowY: 'scroll' }}>
              <div>
                <label>
                  <b>First Name</b>
                </label>
                <StyledInput
                  fluid
                  value={firstName}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Last Name</b>
                </label>
                <StyledInput
                  fluid
                  value={lastName}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Birthdate</b>
                </label>
                <StyledInput
                  fluid
                  value={moment(birthdate).format(DateFormats.DATE)}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Member ID</b>
                </label>
                <StyledInput
                  fluid
                  value={memberId}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Service Date</b>
                </label>
                <StyledInput
                  fluid
                  value={
                    serviceDate ? formatDatetimeToMMDDYYY(serviceDate) : '-'
                  }
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Claim Control Number</b>
                </label>
                <StyledInput
                  fluid
                  value={claimControlnumber ?? '-'}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Provider Name</b>
                </label>
                <StyledInput
                  fluid
                  value={providerName ?? '-'}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <label>
                  <b>Tax ID</b>
                </label>
                <StyledInput
                  fluid
                  value={taxId ?? '-'}
                  action={
                    <Button
                      icon="copy"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyPass(e);
                      }}
                    />
                  }
                />
              </div>
            </Card.Description>
          </Card.Content>
        </Card>
      }
      trigger={
        <Button
          className="min-component-vertical-margin"
          color="teal"
          icon="copy"
          size="tiny"
        />
      }
      open={isOpen}
      onOpen={handleOpen}
    />
  );
};

export default InfoBox;

const StyledInput = styled(Input)`
  margin: 0rem 0rem 1rem 0rem;
`;
