import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Color,
  GetColorsResponse,
  GetTagsResponse,
} from '@bluefox/models/Tags';
import { GetColorsQuery, GetTags } from '@graphql/tags';
import { Button, Grid, Header, SemanticCOLORS } from 'semantic-ui-react';
import styled from 'styled-components';

interface ColorSelectorProps {
  onSelectColor: (color?: Color) => void;
}

const ColorsSelector = (props: ColorSelectorProps) => {
  const { onSelectColor } = props;
  // Start States

  // End States

  // Start Queries & Mutations
  const { data: getColorsResponse } =
    useQuery<GetColorsResponse>(GetColorsQuery);

  // End Queries & Mutations

  // Start Logic

  // End Logic
  return (
    <>
      <Header as="h4" content="Select Color" />
      <Grid.Column>
        <StyledColorButton onClick={() => onSelectColor()} basic size="mini" />
        {getColorsResponse?.colors?.map((color) => {
          return (
            <StyledColorButton
              size="mini"
              onClick={() => onSelectColor(color)}
              key={`color-${color.name}`}
              color={color.name}
            />
          );
        })}
      </Grid.Column>
    </>
  );
};

const StyledColorButton = styled(Button)`
  margin: 0.2rem !important;
  height: 32px !important;
  border-radius: 50% !important;
  background-color: ${(props) => props.color} !important;
`;

export default ColorsSelector;
