import { ReactNode } from 'react';
import { Label } from 'semantic-ui-react';

interface PracticeProfileInfoFieldProps {
  title: string | number;
  value: string | number | ReactNode;
  titleWidth: number;
}

const PracticeProfileInfoField = ({
  title,
  value,
  titleWidth,
}: PracticeProfileInfoFieldProps) => {
  const valueWidth = 100 - titleWidth;

  return (
    <div style={{ display: 'flex' }}>
      <Label style={{ width: `${titleWidth}%` }}>{title}</Label>
      <Label style={{ width: `${valueWidth}%` }}>{value}</Label>
    </div>
  );
};

export default PracticeProfileInfoField;
