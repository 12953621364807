import { useMutation } from '@apollo/client';
import { useSession } from '@bluefox/contexts';
import {
  formatDateToMMhDDhYYYY,
  usDollarNoDigitsCurrency,
} from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';
import {
  BillingClaimStatus,
  ClaimRevisionStatus,
  CptCode,
  claimRevisionStatusOptions,
} from '@bluefox/models/Billing';
import { Insurance } from '@bluefox/models/Insurances';
import { PatientData } from '@bluefox/models/Patient';
import { Practice } from '@bluefox/models/Practice';
import { Vaccination } from '@bluefox/models/Vaccination';
import {
  ClaimRevisionStatusMutation,
  ReviewedClaimMutation,
} from '@graphql/billing';
import { Button, Checkbox, Dropdown, Label, Table } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { useCallback } from 'react';

type Props = {
  id: string;
  practice?: Practice;
  patientData: PatientData;
  insurance?: Insurance;
  selfPay?: boolean;
  vaccinations: Vaccination[];
  cptCodes: CptCode[];
  checkNumber?: string;
  note?: {
    url: string;
  };
  status: BillingClaimStatus;
  revisionStatus?: ClaimRevisionStatus;
  reviewedAt?: Date;
  metadata?: any;
  onSelectClaimToEdit?: (claimId: string) => void;
  onSave?: () => void;
};

const BillingReportRow = ({
  id,
  practice,
  patientData,
  insurance,
  selfPay,
  vaccinations,
  cptCodes,
  checkNumber,
  note,
  status,
  revisionStatus,
  reviewedAt,
  metadata,
  onSelectClaimToEdit,
  onSave,
}: Props) => {
  const session = useSession();
  const [saveReviewedClaim] = useMutation(ReviewedClaimMutation);
  const [updateRevisionStatus] = useMutation(ClaimRevisionStatusMutation);

  const firstVaccination = vaccinations?.[0];

  const setReviewed = useCallback(
    async (isChecked: boolean) => {
      const claimUpdatesMetadata = {
        ...metadata,
        reviewedAt: isChecked,
      };
      const mutationsOptions = {
        variables: {
          claimId: id,
          reviewedAt: isChecked ? new Date().toISOString() : null,
          obj: {
            claimId: id,
            source: 'manual',
            status: status,
            metadata: claimUpdatesMetadata,
            practiceAccountId: session.account?.id,
          },
        },
      };
      try {
        await saveReviewedClaim(mutationsOptions);
        onSave?.();
      } catch (error) {
        toast({
          title: `Failed to update claim reviewed: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [id, metadata, onSave, saveReviewedClaim, session.account?.id, status]
  );

  const setRevisionStatus = useCallback(
    async (revisionStatus: ClaimRevisionStatus) => {
      const claimUpdatesMetadata = {
        ...metadata,
        revisionStatus,
      };

      const mutationsOptions = {
        variables: {
          claimId: id,
          revisionStatus,
          obj: {
            claimId: id,
            source: 'manual',
            status: status,
            metadata: claimUpdatesMetadata,
            practiceAccountId: session.account?.id,
          },
        },
      };
      try {
        await updateRevisionStatus(mutationsOptions);
        if (revisionStatus !== ClaimRevisionStatus.BILLABLE) {
          await setReviewed(false); // It will call onSave inside this function
        } else {
          onSave?.();
        }
      } catch (error) {
        toast({
          title: `Failed to update claim revision status: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [
      id,
      metadata,
      onSave,
      session.account?.id,
      setReviewed,
      status,
      updateRevisionStatus,
    ]
  );

  return (
    <>
      <Table.Row key={id}>
        <Table.Cell style={{ fontWeight: '700' }}>{practice?.name}</Table.Cell>
        <Table.Cell>
          {`${patientData.firstName} ${patientData.lastName} (${patientData.birthdate})`}
        </Table.Cell>
        <Table.Cell>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {metadata?.insuranceForPracticePortal
              ? metadata?.insuranceForPracticePortal?.insuranceName
              : metadata?.insurance?.insuranceName}
            <div>
              <Label
                basic
                content={
                  !selfPay ? `Member ID: ${insurance?.memberId}` : `Self Pay`
                }
                size="tiny"
              />
              {insurance?.vfcEligible ? (
                <Label
                  content="VFC Eligible"
                  size="tiny"
                  color="orange"
                  style={{ marginTop: '0.2rem' }}
                />
              ) : null}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          {firstVaccination?.givenAt
            ? formatDateToMMhDDhYYYY(new Date(firstVaccination?.givenAt))
            : '-'}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {cptCodes.map((cpt) => {
            return (
              <Label
                key={cpt.id}
                style={{ marginBottom: '0.2rem' }}
                content={cpt.cptCode}
                color={
                  cpt.vaccinePricing?.pricePerDose &&
                  cpt.paidAmount < cpt.vaccinePricing?.pricePerDose
                    ? 'red'
                    : 'grey'
                }
              />
            );
          })}
        </Table.Cell>
        <Table.Cell textAlign="center" verticalAlign="middle">
          {!!cptCodes.length
            ? cptCodes.map((cpt) => {
                return (
                  <p key={cpt.id}>
                    {cpt.paidAmount
                      ? usDollarNoDigitsCurrency(cpt.paidAmount)
                      : '-'}
                  </p>
                );
              })
            : '-'}
        </Table.Cell>

        <Table.Cell>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {checkNumber && <p>Check Number: {checkNumber}</p>}
            {note?.url && (
              <div>
                <a href={note.url} target="_blank" rel="noreferrer">
                  EOB Link
                </a>
              </div>
            )}
          </div>
        </Table.Cell>

        <Table.Cell>
          <Label
            basic
            content={humanizeText(status, {
              capitalize: 'first',
              delimiter: '_',
            })}
            size="tiny"
            color={`${status}` === BillingClaimStatus.PAID ? 'olive' : 'grey'}
            style={{ marginTop: '0.2rem' }}
          />
        </Table.Cell>

        <Table.Cell>
          <Button
            primary
            icon="edit"
            size="mini"
            onClick={() => onSelectClaimToEdit?.(id)}
            color="teal"
          />
        </Table.Cell>

        <Table.Cell>
          <>
            <Dropdown
              style={{ minWidth: '10rem' }}
              fluid
              selection
              options={claimRevisionStatusOptions}
              value={revisionStatus || ClaimRevisionStatus.NEW}
              onChange={(_, { value }) => {
                setRevisionStatus(value as ClaimRevisionStatus);
              }}
            />
            <Checkbox
              label="Reviewed"
              disabled={revisionStatus !== ClaimRevisionStatus.BILLABLE}
              checked={!!reviewedAt}
              onChange={(_, { checked }) => setReviewed(!!checked)}
            />
          </>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default BillingReportRow;
