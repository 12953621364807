import React from 'react';
import { Button, Label, Popup, Table } from 'semantic-ui-react';
import { ContractPlan } from '@bluefox/models/ContractPlan';
import { humanizeText } from '@bluefox/lib/humanize';

type Props = {
  contractPlan: ContractPlan;
  onOpenContractPlanForm: (open: boolean, contractPlan: ContractPlan) => void;
};

const renderDiscounts = (contractPlan: ContractPlan) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Reason</Table.HeaderCell>
          <Table.HeaderCell>Discount</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {contractPlan.discounts.map((discount, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>
                {humanizeText(discount.type, {
                  capitalize: 'first',
                  delimiter: '_',
                })}
              </Table.Cell>
              <Table.Cell>
                <Label
                  basic
                  size="small"
                  content={humanizeText(discount.reason, {
                    capitalize: 'first',
                    delimiter: '_',
                  })}
                />
              </Table.Cell>
              <Table.Cell>
                {discount.type === 'percentage'
                  ? `${discount.discount}%`
                  : `$${discount.discount}`}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const ContractPlansTableRow = ({
  contractPlan,
  onOpenContractPlanForm,
}: Props) => {
  return (
    <Table.Row key={contractPlan.id}>
      <Table.Cell>{contractPlan.name}</Table.Cell>
      <Table.Cell>{contractPlan.type}</Table.Cell>
      <Table.Cell>{contractPlan.startDate}</Table.Cell>
      <Table.Cell>{contractPlan.endDate || '-'}</Table.Cell>
      <Table.Cell>
        <Popup
          on="click"
          trigger={<Button size="tiny" color="teal" content="Show Discounts" />}
          content={renderDiscounts(contractPlan)}
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <Button
              size="small"
              color="teal"
              icon="edit"
              onClick={() => onOpenContractPlanForm(true, contractPlan)}
            />
          }
          content="Edit"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ContractPlansTableRow;
