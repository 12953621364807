export interface CreditCard {
  id: string;
  firstDigit: number;
  lastDigits: number;
  type: string;
  userName: string;
  expiration: Date;
  default: boolean;
  defaultDaysRange?: string;
}

/*
American Express cards always begin with the number 3, more specifically 34 or 37.
Visa cards begin with the number 4.
Mastercards start with the number 5.
Discover Cards begin with the number 6.
*/
export const creditCardTypes = [
  { key: 3, value: 'AMEX' },
  { key: 4, value: 'VISA' },
  { key: 5, value: 'MASTER' },
  { key: 6, value: 'DISCOVER' },
];

export const formatCreditCard = (
  creditCard: CreditCard,
  fullFormat: boolean
): String => {
  return `${creditCard.firstDigit}*** - **** - **** - ${
    creditCard.lastDigits
  } ${fullFormat ? `(${creditCard.type} - ${creditCard.userName})` : ''} ${
    creditCard.default ? 'Default' : ''
  }`;
};
