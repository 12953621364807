import { compactNumber } from '@bluefox/lib/formatters';
import EchartsContainer from '@bluefox/ui/EchartsContainer';
import { ClaimStatusAmountDataType } from './ClaimStatusAmountDataType';

type Props = {
  colors?: string[];
  privateData: ClaimStatusAmountDataType;
  vfcData: ClaimStatusAmountDataType;
};

const ClaimsStatuesBarChart = ({ colors, privateData, vfcData }: Props) => {
  return (
    <EchartsContainer
      options={{
        tooltip: {},
        color: colors,
        xAxis: {
          data: ['Private', 'VFC'],
          axisLabel: {
            fontWeight: 'bold',
          },
        },
        yAxis: {
          axisLabel: {
            formatter: (value: number) => compactNumber(value),
          },
        },
        grid: {
          top: '5%',
          bottom: '10%',
        },
        series: [
          {
            name: 'Paid',
            data: [privateData.paidAmount, vfcData.paidAmount],
            type: 'bar',
            stack: 'claims',
          },
          {
            name: 'Resubmition',
            data: [privateData.resubmitionAmount, vfcData.resubmitionAmount],
            type: 'bar',
            stack: 'claims',
          },
          {
            name: 'In Progress',
            data: [privateData.inProgressAmount, vfcData.inProgressAmount],
            type: 'bar',
            stack: 'claims',
          },
          {
            name: 'Pending appeals',
            data: [
              privateData.pendingAppealsAmount,
              vfcData.pendingAppealsAmount,
            ],
            type: 'bar',
            stack: 'claims',
          },
          {
            name: 'Errors and rejected',
            data: [
              privateData.errorAndRejectedAmount,
              vfcData.errorAndRejectedAmount,
            ],
            type: 'bar',
            stack: 'claims',
          },
          {
            name: 'In meditation',
            data: [privateData.inMediationAmount, vfcData.inMediationAmount],
            type: 'bar',
            stack: 'claims',
          },
          {
            name: 'Unclaimed',
            data: [privateData.unclaimedAmount, vfcData.unclaimedAmount],
            type: 'bar',
            stack: 'claims',
          },
          {
            name: 'Borrowing',
            data: [privateData.borrowingAmount, vfcData.borrowingAmount],
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
            },
            type: 'bar',
            stack: 'claims',
          },
        ],
      }}
    />
  );
};

export default ClaimsStatuesBarChart;
