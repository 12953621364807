import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { Icon, List, Modal } from 'semantic-ui-react';
import { CptCodesByInvoiceQuery } from 'graphql/billing';

interface InvoiceCptCodesModalProps {
  open: boolean;
  invoiceId?: string;
  onClose: () => void;
}

const summarizeCptCodesUnits = (cptCodes: cptCodesData[]): cptCodesData[] => {
  const summarize = cptCodes.reduce(
    (acum: any, cur: cptCodesData) => {
      if (!acum[cur.cptCode]) {
        acum[cur.cptCode] = 1;
      } else {
        acum[cur.cptCode] += 1;
      }

      return acum;
    },
    {} as { [key: string]: { units: number } }
  );

  const cptCodesResultArray: cptCodesData[] = [];

  for (const [key, value] of Object.entries(summarize)) {
    cptCodesResultArray.push({ cptCode: key, units: value as number });
  }

  return cptCodesResultArray;
};

interface cptCodesByInvoice {
  invoice: {
    claimCptCodes: {
      cptCode: string;
      units: number;
    }[];
  };
}

interface cptCodesData {
  cptCode: string;
  units: number;
}

const InvoiceCptCodesModal = ({
  open,
  invoiceId,
  onClose,
}: InvoiceCptCodesModalProps) => {
  const [summarizedCpts, setSummarizedCpts] = useState<cptCodesData[]>();
  const [loadingCpts, setLoadingCpts] = useState(true);
  const { data, loading } = useQuery<cptCodesByInvoice>(
    CptCodesByInvoiceQuery,
    {
      variables: {
        id: invoiceId,
      },
    }
  );

  useEffect(() => {
    if (!data) return;

    setSummarizedCpts(summarizeCptCodesUnits(data.invoice.claimCptCodes));
    setLoadingCpts(false);
  }, [data]);

  return (
    <>
      <Modal size="tiny" onClose={onClose} open={open} closeIcon>
        <Modal.Header>{'CPT CODES:'}</Modal.Header>
        <Modal.Content>
          {loadingCpts && <Icon loading size="large" name="spinner"></Icon>}
          {summarizedCpts?.map((cpt: cptCodesData, index: number) => {
            return (
              <List>
                <List.Item
                  key={index}
                  size="tiny"
                  content={`${cpt.cptCode} x${cpt.units}`}
                  style={{ margin: '0.1rem 0 0.1rem 0' }}
                />
              </List>
            );
          })}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default InvoiceCptCodesModal;
