import { View, StyleSheet } from '@react-pdf/renderer';
import PracticeClaimsTable, {
  PracticeClaimsTableProps,
} from './PracticeClaimsTable';

const bodyStyle = StyleSheet.create({
  section: {
    padding: 40,
  },
  tableMarginBottom: {
    marginBottom: 48,
  },
});

type BodyProps = {
  claimTables: PracticeClaimsTableProps[];
};

const Body = ({ claimTables }: BodyProps) => {
  return (
    <View style={bodyStyle.section}>
      {claimTables.map((t, i) => (
        <PracticeClaimsTable
          {...t}
          style={
            i < claimTables.length - 1 ? bodyStyle.tableMarginBottom : undefined
          }
        />
      ))}
    </View>
  );
};

export default Body;
