import React, { useMemo, useState } from 'react';
import { Button, Label, Popup, Table } from 'semantic-ui-react';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import {
  VFCInconsistency,
  VFCInconsistencyStatuses,
} from '@bluefox/models/VFCInconsistency';
import { humanizeText } from '@bluefox/lib/humanize';

interface RedundantWithBox {
  redundantWith?: VFCInconsistency;
}

const BorrowingRedundantWithBox = ({ redundantWith }: RedundantWithBox) => {
  const [isOpen, setIsOpen] = useState(false);

  const patientData = useMemo(
    () => redundantWith?.claim?.practicePatient?.patientData,
    [redundantWith?.claim?.practicePatient?.patientData]
  );
  const insurance = useMemo(
    () => redundantWith?.claim?.insurance[0],
    [redundantWith?.claim?.insurance]
  );
  const inventory = useMemo(
    () => redundantWith?.inventory,
    [redundantWith?.inventory]
  );

  return (
    <Popup
      on="click"
      pinned
      position="top right"
      content={
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Patient
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Insurance
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Service Date
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Inventory Used
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Vaccine <hr /> Lot
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key={redundantWith?.id}>
              <Table.Cell textAlign="center">
                {`${patientData?.firstName} ${patientData?.lastName} (${
                  patientData?.birthdate
                    ? formatDatetimeToMMDDYYY(patientData?.birthdate)
                    : '-'
                })`}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {insurance?.insuranceCompanyName}{' '}
                <Label
                  content={insurance?.vfcEligible ? 'VFC Eligible' : 'Private'}
                  size="tiny"
                  color={insurance?.vfcEligible ? 'orange' : 'teal'}
                />
                <Label
                  basic
                  size="tiny"
                  content={`Member ID: ${insurance?.memberId || '-'}`}
                  style={{ marginTop: '0.2rem' }}
                />
              </Table.Cell>
              <Table.Cell textAlign="center">
                {redundantWith?.claim?.givenAt
                  ? formatDatetimeToMMDDYYY(
                      redundantWith?.claim?.givenAt
                    ).replaceAll('-', '/')
                  : '-'}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {inventory?.vfc ? (
                  <>
                    <Label
                      content="VFC Eligible"
                      size="tiny"
                      color="orange"
                      style={{ marginTop: '0.2rem' }}
                    />
                  </>
                ) : (
                  <>
                    <Label
                      content={'Private'}
                      size="tiny"
                      color={'teal'}
                      style={{ marginTop: '0.2rem' }}
                    />
                  </>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {inventory?.vaccine.name}
                <hr />
                {inventory?.lot}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      }
      trigger={
        <Button
          className="min-component-vertical-margin"
          icon="caret up"
          size="tiny"
          labelPosition="right"
          content={`${humanizeText(VFCInconsistencyStatuses.REDUNDANT, {
            capitalize: 'all',
          })} with`}
        />
      }
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    />
  );
};

export default BorrowingRedundantWithBox;
