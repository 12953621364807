import React, { useEffect, useState } from 'react';
import { Button, Label, Modal, Popup, Table } from 'semantic-ui-react';
import { Inventory } from '@bluefox/models/Inventory';
import { useLazyQuery } from '@apollo/client';
import { getAdjustmentDetailsByInventoryQuery } from '@graphql/inventoryAdjustment';
import { InventoryAdjustmentDetail } from '@bluefox/models/InventoryAdjustment';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import { Vaccine } from '@bluefox/models/Vaccine';

interface InventoryData {
  vaccine: Vaccine;
  inventory: Inventory;
}
interface AdjustmentDetailsProps {
  data: InventoryData;
  onModalClose: () => void;
}

interface GetDetailsResponse {
  details: InventoryAdjustmentDetail[];
}

const AdjustmentDetails = (props: AdjustmentDetailsProps) => {
  const { data, onModalClose } = props;

  const { inventory, vaccine } = data;

  const [modalOpened, setModalOpened] = useState(false);

  const [getDetails, { data: adjustmentData }] =
    useLazyQuery<GetDetailsResponse>(getAdjustmentDetailsByInventoryQuery);

  useEffect(() => {
    if (!inventory) {
      return;
    }
    getDetails({
      variables: {
        inventoryId: inventory.id,
      },
    });
  }, [inventory]);

  useEffect(() => {
    if (adjustmentData?.details.length === 0) {
      return;
    }

    setModalOpened(true);
  }, [adjustmentData]);

  return (
    <>
      <Modal
        size="fullscreen"
        open={modalOpened}
        onClose={() => {
          onModalClose();
          setModalOpened(false);
        }}
      >
        <Modal.Content scrolling>
          <h3>Inventory:</h3>
          <Table columns={4}>
            <Table.Header>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Lot</Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{inventory?.id}</Table.Cell>
                <Table.Cell>
                  {`${vaccine.name} `}
                  <Label
                    content={inventory?.vfc ? 'VFC' : 'Private'}
                    size="tiny"
                    color={inventory.vfc ? 'orange' : 'teal'}
                    style={{ marginTop: '0.2rem' }}
                  />
                </Table.Cell>
                <Table.Cell>{inventory?.lot}</Table.Cell>
                <Table.Cell>{inventory?.doses}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <h3>Adjustment Details:</h3>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Adjustment Date</Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
              <Table.HeaderCell>New Doses</Table.HeaderCell>
              <Table.HeaderCell>Reason</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Comment</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {adjustmentData?.details.map(
                (currentDetail: InventoryAdjustmentDetail) => {
                  return (
                    <Table.Row key={`${currentDetail.id}-detail-row`}>
                      <Table.Cell>{currentDetail.id}</Table.Cell>
                      <Table.Cell>{`${moment(currentDetail.createdAt).format(
                        DateFormats.DATE
                      )}`}</Table.Cell>
                      <Table.Cell>{currentDetail.currentDoses}</Table.Cell>
                      <Table.Cell>{currentDetail.newDoses}</Table.Cell>
                      <Table.Cell>{currentDetail.reason}</Table.Cell>
                      <Table.Cell>{currentDetail.type}</Table.Cell>
                      <Table.Cell>
                        {
                          <Popup
                            trigger={
                              <Button
                                size="mini"
                                color="teal"
                                icon="comment alternate"
                              />
                            }
                            content={currentDetail.comment || 'No Comment'}
                            on="click"
                            pinned
                          />
                        }
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={() => {
              onModalClose();
              setModalOpened(false);
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AdjustmentDetails;
