import { Message } from './types';
import { Card } from 'semantic-ui-react';

type Props = {
  message: Message;
  position?: 'left' | 'right';
};

function formatDateTime(date: string | Date | undefined) {
  if (!date) return '';
  const dateAsDate = typeof date === 'string' ? new Date(date) : date;
  return dateAsDate.toLocaleDateString();
}

const ChatMessage = ({ message, position }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
        marginBottom: '1rem',
      }}
    >
      <Card>
        <Card.Header>
          <b>{message.title}</b>
        </Card.Header>
        <Card.Meta>{formatDateTime(message.date)}</Card.Meta>
        <Card.Description style={{ wordBreak: 'break-word' }}>
          {message.message}
        </Card.Description>
      </Card>
    </div>
  );
};

export default ChatMessage;
