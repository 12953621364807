import { COLORS_BY_NAME, pixelToRem } from '@bluefox/constants';
import { Card } from 'semantic-ui-react';
import styled from 'styled-components';

export const CardHeader = styled(Card.Header)`
  font-size: ${pixelToRem(20)}rem !important;
  font-weight: 500 !important;
  line-height: ${24 / 20} !important;
  color: ${COLORS_BY_NAME['Raisin Black']} !important;
`;

export const CardSubHeader = styled.p`
  margin: ${pixelToRem(4)}rem 0 0 !important;
  font-size: ${pixelToRem(10)}rem !important;
  font-weight: 500 !important;
  line-height: ${12 / 10} !important;
  letter-spacing: 0;
  color: ${COLORS_BY_NAME['Granite Gray']} !important;
`;

export const CardContent = styled(Card.Content)`
  padding: ${pixelToRem(32)}rem !important;
`;
