import { CSSProperties } from 'react';
import { ReactElement } from 'react';
import {
  Notification,
  NotificationStatus,
  NotificationType,
} from '@bluefox/models/Notification';
import { Placeholder, Segment, Table } from 'semantic-ui-react';
import OrderNotificationListTableRow from './OrderNotificationListTableRow';
import { NotificationListTableRowChild } from '../common';
import NotificationStatusDropdown from '../../NotificationStatusDropdown';
import { ALL } from '@bluefox/lib/options';

type Props = {
  loading: boolean;
  internalTools?: boolean;
  notifications?: Notification[];
  children?: ReactElement<NotificationListTableRowChild>;
  style?: CSSProperties;
  notificationStatus?: NotificationStatus | typeof ALL;
  notificationStatusOnChange?: (value: NotificationStatus | typeof ALL) => void;
};

const OrderNotificationListTable = ({
  internalTools,
  loading,
  notifications,
  children,
  style,
  notificationStatus,
  notificationStatusOnChange,
}: Props) => {
  return (
    <Table striped style={style}>
      <Table.Header>
        <Table.Row>
          {internalTools && <Table.HeaderCell>Practice</Table.HeaderCell>}
          <Table.HeaderCell>Order #</Table.HeaderCell>
          <Table.HeaderCell>Purchase Date</Table.HeaderCell>
          <Table.HeaderCell>Vaccine Info</Table.HeaderCell>
          <Table.HeaderCell>Doses</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          <Table.HeaderCell>
            Action
            <NotificationStatusDropdown
              internalTools={internalTools}
              value={notificationStatus}
              orderNotifications
              onChange={notificationStatusOnChange}
              style={{ fontWeight: 'normal' }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && (
          <Table.Row>
            <Table.Cell colSpan={6}>
              <Segment basic>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Segment>
            </Table.Cell>
          </Table.Row>
        )}
        {notifications?.map((notification) => {
          switch (notification.content?.type) {
            case NotificationType.order:
              return (
                <OrderNotificationListTableRow
                  internalTools={internalTools}
                  key={notification.id}
                  notification={notification}
                  children={children}
                />
              );
            default:
              return null;
          }
        })}
      </Table.Body>
    </Table>
  );
};

export default OrderNotificationListTable;
