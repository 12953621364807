import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { tsXLXS } from 'ts-xlsx-export';
import moment from 'moment-timezone';
import { BillingInvoicesQuery } from '@graphql/billing';
import { BillingInvoice } from '@bluefox/models/Billing';
import { Modal, Button, Progress, Icon } from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';

interface InvoicesData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  invoices: BillingInvoice[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

interface InvoicesExcelExportModalProps {
  criteria?: any;
}

const InvoicesExcelExportModal = ({
  criteria,
}: InvoicesExcelExportModalProps) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState<any[] | undefined>([]);

  const [getInvoices, { data, loading, error }] =
    useLazyQuery<InvoicesData>(BillingInvoicesQuery);

  const handleLoadXlsx = () => {
    if (!excelData) return;
    tsXLXS()
      .exportAsExcelFile(excelData)
      .saveAsExcelFile(
        `Canid-Invoices-List-${moment().format(
          DateFormats.DATE_WITH_TIME_SECONDS
        )}`
      );
    setOpen(false);
  };

  useEffect(() => {
    if (!data) return;

    const invoicesList = data.invoices.map((invoice) => {
      return {
        Practice:
          (invoice?.practicesData
            ? invoice?.practicesData
                ?.map((p) => p.name)
                .sort()
                .join(', ')
            : invoice?.practice?.name) || '-',
        Date: moment(invoice.date).format(DateFormats.DATE) || '-',
        Amount: `$${invoice.amount}` || '-',
        Status: humanizeText(invoice.status, { capitalize: 'first' }) || '-',
        'Billing Method':
          humanizeText(invoice.billMethod, { capitalize: 'first' }) || '-',
        'Payment Date': invoice.paymentDate
          ? formatDatetimeToMMDDYYY(invoice.paymentDate)
          : '-',
      };
    });

    setExcelData(invoicesList);
  }, [data]);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={
        <Button
          inverted
          size="small"
          color="blue"
          floated="right"
          onClick={() => {
            getInvoices({
              variables: {
                criteria,
              },
            });
          }}
        >
          Export EXCEL
        </Button>
      }
    >
      <Modal.Header>Export as Excel file?</Modal.Header>
      <Modal.Content>
        {loading ? (
          <div>
            Loading Excel data...
            <Progress size="small" color="teal" indicating percent={100} />
          </div>
        ) : error ? (
          <div>
            <Icon name="warning sign" color="red" />
            There was an error trying to load Excel data
          </div>
        ) : (
          <div>
            Excel data is ready to be exported{' '}
            <Icon name="check" color="olive" />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <Button
          primary
          content="Yes"
          onClick={() => handleLoadXlsx()}
          disabled={loading || !excelData}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default InvoicesExcelExportModal;
