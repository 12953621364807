import { CSSProperties, ReactElement, ReactNode } from 'react';
import { Notification, NotificationType } from '@bluefox/models/Notification';
import { Placeholder, Segment, Table } from 'semantic-ui-react';
import BillingNotificationListTableRow from './BillingNotificationListTableRow';
import VaxSyncIntegrationNotificationListTableRow from './VaxSyncIntegrationNotificationListTableRow';
import { NotificationListTableRowChild } from '../common';

type Props = {
  loading: boolean;
  notifications?: Notification[];
  children?: ReactElement<NotificationListTableRowChild>;
  internalTools?: boolean;
  style?: CSSProperties;
};

const PatientNotificationListTable = ({
  loading,
  notifications,
  children,
  internalTools,
  style,
}: Props) => {
  return (
    <Table striped style={style}>
      <Table.Header>
        <Table.Row>
          {internalTools && <Table.HeaderCell>Practice</Table.HeaderCell>}
          <Table.HeaderCell>Last Update</Table.HeaderCell>
          <Table.HeaderCell>Patient</Table.HeaderCell>
          <Table.HeaderCell>MRN</Table.HeaderCell>
          <Table.HeaderCell>Service Date</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && (
          <Table.Row>
            <Table.Cell colSpan={6}>
              <Segment basic>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Segment>
            </Table.Cell>
          </Table.Row>
        )}
        {notifications?.map((notification) => {
          switch (notification.content?.type) {
            case NotificationType.claim:
              return (
                <BillingNotificationListTableRow
                  internalTools={internalTools}
                  key={notification.id}
                  notification={notification}
                  children={children}
                />
              );
            case NotificationType.athena:
            case NotificationType.develo:
            case NotificationType.ecw:
            case NotificationType.vax_sync:
              return (
                <VaxSyncIntegrationNotificationListTableRow
                  internalTools={internalTools}
                  key={notification.id}
                  notification={notification}
                  children={children}
                />
              );
            default:
              return null;
          }
        })}
      </Table.Body>
    </Table>
  );
};

export default PatientNotificationListTable;
