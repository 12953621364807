import { CSSProperties, useCallback, useState } from 'react';
import { Card, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { usDollarNoDigitsCurrency } from '@bluefox/lib/formatters';
import Legends from '@bluefox/ui/Dashboard/Legends';
import TotalAmount, {
  TotalAmountSubtitleParagraph,
} from '@bluefox/ui/Dashboard/TotalAmount';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';
import RecoveredFeesChart from '../charts/RecoveredFeesChart';
import { CardContent, CardHeader } from '@bluefox/ui/Dashboard/CommonStyles';
import { SemanticColorToString, pixelToRem } from '@bluefox/constants';
import ClaimDropdown, {
  DropdownOptionsToStatusQuery,
} from '@bluefox/ui/Dashboard/ClaimDropdown';
import { DropdonwOptionsValuesType } from '@bluefox/models/Dashboard';
import { useQuery } from '@apollo/client';
import { BillingDashboardRecoveredFees } from '@graphql/billing';
import { CPTType } from '@bluefox/models/Billing';
import PieBarTwoSeriesChart from '../charts/PieBarTwoSeriesChart';
import { getPercentageOrZeroWithSign } from '@bluefox/lib/maths';

type Props = {
  practiceIds: string[];
  month: number | undefined;
  year: number | undefined;
  style?: CSSProperties;
};

type DataProps = {
  pendingFees: number;
  paidAmount: number;
  type: string;
  vfcEligible: boolean;
};

type PaidAndPendingAmountsType = {
  paidAmount: number;
  pendingFees: number;
};
type TotalPaidAndPendingAMountType = {
  totalAmmount: number;
};

function sumPaidAndPendingAmounts(source: DataProps[]) {
  return source?.reduce(
    (acc: PaidAndPendingAmountsType, curr: DataProps) => ({
      paidAmount: acc.paidAmount + curr.paidAmount,
      pendingFees: acc.pendingFees + curr.pendingFees,
    }),
    { paidAmount: 0, pendingFees: 0 } as PaidAndPendingAmountsType
  );
}

function calculateTotal(source: PaidAndPendingAmountsType) {
  return (source?.paidAmount | 0) + (source.pendingFees | 0);
}

type RecoveredFeesDataType = PaidAndPendingAmountsType &
  Partial<TotalPaidAndPendingAMountType> & {
    private?: PaidAndPendingAmountsType;
    vfc?: PaidAndPendingAmountsType;
  } & {
    administration?: PaidAndPendingAmountsType;
    inventory?: PaidAndPendingAmountsType;
  };

const RecoveredFeesCard = ({ practiceIds, month, year, style }: Props) => {
  const [selectedClaims, setSelectedClaims] =
    useState<DropdonwOptionsValuesType>('all');
  const [recoveredFeesData, setRecoveredFeesData] = useState<
    RecoveredFeesDataType | undefined
  >();
  const { loading, error } = useQuery(BillingDashboardRecoveredFees, {
    variables: {
      practiceIds: practiceIds,
      month: month !== undefined ? month + 1 : undefined,
      year,
      vfcEligible: DropdownOptionsToStatusQuery[selectedClaims],
    },
    skip: !practiceIds || month === undefined || year === undefined,
    onCompleted: (data) => {
      const source: DataProps[] = data?.billing_dashboard_recovered_fees;
      switch (selectedClaims) {
        case 'all': {
          const newData: RecoveredFeesDataType =
            sumPaidAndPendingAmounts(source);
          newData.totalAmmount = calculateTotal(newData);
          newData.private = sumPaidAndPendingAmounts(
            source.filter((i) => !i.vfcEligible)
          );
          newData.vfc = sumPaidAndPendingAmounts(
            source.filter((i) => i.vfcEligible)
          );
          setRecoveredFeesData(newData);
          break;
        }
        case 'private': {
          const newData: RecoveredFeesDataType =
            sumPaidAndPendingAmounts(source);
          newData.totalAmmount = calculateTotal(newData);
          newData.administration = sumPaidAndPendingAmounts(
            source.filter((i) => i.type === CPTType.ADMINISTRATION)
          );
          newData.inventory = sumPaidAndPendingAmounts(
            source.filter((i) => i.type === CPTType.INVENTORY)
          );
          setRecoveredFeesData(newData);
          break;
        }
        case 'vfc': {
          const newData: RecoveredFeesDataType =
            sumPaidAndPendingAmounts(source);
          newData.totalAmmount = calculateTotal(newData);
          newData.vfc = sumPaidAndPendingAmounts(
            source.filter((i) => i.vfcEligible)
          );
          setRecoveredFeesData(newData);
          break;
        }
        default:
      }
    },
  });

  const renderData = useCallback(
    (data: RecoveredFeesDataType) => {
      return (
        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column width={9}>
              <TotalAmount
                amount={usDollarNoDigitsCurrency(data?.paidAmount || 0)}
              />
              {data?.totalAmmount && (
                <TotalAmountSubtitleParagraph>
                  This amount represents{' '}
                  <b>
                    {getPercentageOrZeroWithSign(
                      data?.paidAmount,
                      data?.totalAmmount
                    )}
                  </b>{' '}
                  of the total claims
                </TotalAmountSubtitleParagraph>
              )}

              <Legends
                legends={[
                  {
                    icon: {
                      name: 'dollar',
                      color: 'blue',
                    },
                    title: 'Total fees',
                    value: usDollarNoDigitsCurrency(data?.totalAmmount || 0),
                  },
                  {
                    icon: {
                      name: 'hourglass half',
                      color: 'teal',
                    },
                    title: 'Pending fees',
                    value: usDollarNoDigitsCurrency(data?.pendingFees || 0),
                    subValue: `(${getPercentageOrZeroWithSign(
                      data?.pendingFees,
                      data?.totalAmmount
                    )})`,
                  },
                ]}
                style={{ marginTop: `${pixelToRem(24)}` }}
              />
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={6}>
              <div
                style={{
                  height: '100%',
                  minHeight: '200px',
                }}
              >
                {selectedClaims === 'all' && (
                  <RecoveredFeesChart
                    stack1Name="Private"
                    stack2Name="VFC"
                    stack1Data={[
                      data.private?.paidAmount || 0,
                      data.private?.pendingFees || 0,
                    ]}
                    stack2Data={[
                      data.vfc?.paidAmount || 0,
                      data.vfc?.pendingFees || 0,
                    ]}
                    serie1Name="Recovered Fees (paid)"
                    serie2Name="Pending Fees"
                  />
                )}
                {selectedClaims === 'private' && (
                  <RecoveredFeesChart
                    stack1Name="Administration"
                    stack2Name="Inventory"
                    stack1Data={[
                      data.administration?.paidAmount || 0,
                      data.administration?.pendingFees || 0,
                    ]}
                    stack2Data={[
                      data.inventory?.paidAmount || 0,
                      data.inventory?.pendingFees || 0,
                    ]}
                    serie1Name="Recovered Fees (paid)"
                    serie2Name="Pending Fees"
                  />
                )}
                {selectedClaims === 'vfc' && (
                  <>
                    {!data.vfc?.pendingFees && !data.vfc?.paidAmount && (
                      <ChartErrorMessage>
                        <p>There is not enough data to draw this chart</p>
                      </ChartErrorMessage>
                    )}
                    {!(!data.vfc?.pendingFees && !data.vfc?.paidAmount) && (
                      <PieBarTwoSeriesChart
                        chartName="Recovered fees"
                        colors={[
                          SemanticColorToString('teal'),
                          SemanticColorToString('blue'),
                        ]}
                        series={[
                          {
                            value: data.vfc?.pendingFees || 0,
                            name: 'Pending Fees',
                            color: SemanticColorToString('teal'),
                          },
                          {
                            value: data.vfc?.paidAmount || 0,
                            name: 'Recovered Fees (paid',
                            color: SemanticColorToString('blue'),
                          },
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    },
    [selectedClaims]
  );

  return (
    <Card fluid style={style}>
      <CardContent>
        <Grid>
          <Grid.Row columns={2} style={{ padding: '0' }}>
            <Grid.Column>
              <CardHeader>Recovered fees</CardHeader>
            </Grid.Column>
            <Grid.Column style={{ display: 'flex', justifyContent: 'end' }}>
              <ClaimDropdown onChange={(value) => setSelectedClaims(value)} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        {error && (
          <ChartErrorMessage>
            <p>Error: {error?.message}</p>
          </ChartErrorMessage>
        )}
        {!loading &&
          !error &&
          recoveredFeesData &&
          renderData(recoveredFeesData)}
      </CardContent>
    </Card>
  );
};

export default RecoveredFeesCard;
