import { View, StyleSheet } from '@react-pdf/renderer';
import SummaryTable, { SummaryTableProps } from './SummaryTable';

const bodyStyle = StyleSheet.create({
  section: {
    padding: 40,
  },
});

export type BodyProps = {
  summary: SummaryTableProps;
};

const Body = ({ summary }: BodyProps) => {
  return (
    <View style={bodyStyle.section}>
      <SummaryTable {...summary} />
    </View>
  );
};

export default Body;
