import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { PracticePatientPickerQuery } from '@bluefox/graphql/patients';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

interface PatientOption {
  key: string;
  value: string;
  text: string;
}

interface Patient {
  id: string;
  patientData: {
    birthdate: Date;
    fullName: string;
  };
}

interface PatientsData {
  patients: Patient[];
}

interface PatientPickerProps {
  handlePatient: (patient: PatientOption) => void;
  practiceId: string;
}

const PatientPicker = ({
  handlePatient,
  practiceId,
  ...rest
}: PatientPickerProps & DropdownProps) => {
  const [criteria, setCriteria] = useState<object>({});
  const [input, setInput] = useState('');
  const [options, setOptions] = useState<PatientOption[]>([]);

  const { data, loading } = useQuery<PatientsData>(PracticePatientPickerQuery, {
    variables: {
      criteria,
    },
    skip: input ? input.length < 3 : true,
  });

  useEffect(() => {
    let _criteria = {};

    if (input) {
      _criteria = {
        lookup: {
          _ilike: `%${input}%`,
        },
        practices: { practice: { id: { _eq: practiceId } } },
      };
    }

    setCriteria(_criteria);
  }, [input]);

  useEffect(() => {
    if (!data) return;
    setOptions(
      data.patients.map((p) => {
        return {
          key: p.id,
          value: p.id,
          text: `${p.patientData.fullName} (${p.patientData.birthdate})`,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    if (!practiceId) return;
    setInput('');
    setOptions([]);
  }, [practiceId]);

  return (
    <Dropdown
      placeholder="Search patient..."
      fluid
      search
      selection
      onSearchChange={(_, { searchQuery }) => setInput(searchQuery as string)}
      onChange={(_, data) => {
        const patient = data.options ? data.options.find((p) => p.value) : {};

        handlePatient({
          key: patient?.key,
          value: patient?.value as string,
          text: patient?.text as string,
        });
      }}
      options={options}
      loading={loading}
      {...rest}
    />
  );
};

export default PatientPicker;
