import { gql } from '@apollo/client';

export const AlertsQuery = gql`
  query AlertsQuery(
    $criteria: alerting_alerts_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    aggregating: alerting_alerts_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    alerts: alerting_alerts(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { practice: { name: asc } }
    ) {
      id
      practice {
        id
        name
      }
      type
      message
      severity
      status
      note
    }
  }
`;

export const UpdateAlertStatusMutation = gql`
  mutation UpdateAlertStatusMutation(
    $id: uuid!
    $status: alerting_alert_statuses_enum!
    $completedAt: timestamptz
  ) {
    updateAlertStatus: update_alerting_alerts(
      where: { id: { _eq: $id } }
      _set: { status: $status, completedAt: $completedAt }
    ) {
      returning {
        id
        status
      }
    }
  }
`;

export const SaveNotesMutation = gql`
  mutation SaveNotesMutation($id: uuid!, $note: jsonb) {
    saveNotes: update_alerting_alerts_by_pk(
      pk_columns: { id: $id }
      _set: { note: $note }
    ) {
      id
      note
    }
  }
`;

export const InsertAlertMutation = gql`
  mutation insertAlertMutation($alertObject: alerting_alerts_insert_input!) {
    insert_alerting_alerts_one(
      object: $alertObject
      on_conflict: { constraint: alerts_entity_ref_key, update_columns: [] }
    ) {
      id
      message
    }
  }
`;

export const DeleteAlertMutation = gql`
  mutation DeleteAlertMutation($id: uuid!) {
    delete_alerting_alerts_by_pk(id: $id) {
      id
    }
  }
`;
