import moment from 'moment-timezone';
import {
  Popup,
  List,
  Card,
  Button,
  Placeholder,
  Segment,
  PopupProps,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';
import { StatusLog } from '@bluefox/models/Inventory';

interface InventoryStatusLogProps {
  data: StatusLog[] | undefined;
  onClick?: () => void;
  loading: boolean;
  position: PopupProps['position'];
}

export const InventoryStatusLog = ({
  data,
  onClick,
  loading,
  position,
}: InventoryStatusLogProps) => {
  return (
    <Popup
      size="small"
      position={position}
      on="click"
      content={
        <List
          style={{
            maxHeight: '50rem',
            overflowY: 'auto',
            padding: '1rem',
          }}
        >
          <List.Item>
            <List.Header>{`Logs (${
              loading ? '-' : data?.length
            })`}</List.Header>
          </List.Item>
          {loading ? (
            <Card>
              <Segment basic>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Segment>
            </Card>
          ) : (
            data?.map((l, index) => {
              return (
                <Card key={index}>
                  <List>
                    <List.Item>
                      <List.Header>Lot</List.Header>
                      {l.lot}
                    </List.Item>
                    <List.Item>
                      <List.Header>Date</List.Header>
                      {moment.utc(l.date).format(DateFormats.DATE)}
                    </List.Item>
                    <List.Item>
                      <List.Header>VFC</List.Header>
                      {l.vfc ? 'Yes' : 'No'}
                    </List.Item>
                    <List.Item>
                      <List.Header>Doses</List.Header>
                      {l.doses}
                    </List.Item>
                    <List.Item>
                      <List.Header>Status</List.Header>
                      {humanizeText(l.status, {
                        capitalize: 'first',
                        delimiter: '_',
                      })}
                    </List.Item>
                    <List.Item>
                      <List.Header>Retired</List.Header>
                      {l.retired ? 'Yes' : 'No'}
                    </List.Item>
                    <List.Item>
                      <List.Header>Expiration</List.Header>
                      {moment.utc(l.expiration).format(DateFormats.DATE)}
                    </List.Item>
                  </List>
                </Card>
              );
            })
          )}
        </List>
      }
      trigger={
        <Button
          className="min-component-vertical-margin"
          color="teal"
          size="tiny"
          icon="eye"
          onClick={onClick}
        />
      }
    />
  );
};
